import React, { Component } from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap';
import { getAllUSers } from '../../api/Form';
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import "react-datepicker/dist/react-datepicker.css";
import DataTableExtensions from 'react-data-table-component-extensions';
import { ClipLoader } from 'react-spinners';
import DropMenu from './DropMenu';

export default class AllUsers extends Component {
    state = {
        userId: '',
        deviceADcode: '',
        loading: false,
        data: [],
        showData: [],
        columns: [],
        userType: ""
    }

    getAllUSersHandler = () => {
        this.setState({ loading: true });
        getAllUSers({
            accountType: this.state.userType
        }).then((response) => {
            this.setState({ loading: false, showData: response.data.data });
        }).catch(err => {
            this.setState({ loading: false });
        });
    }

    async componentDidMount() {
        const data = await localStorage.getItem('auth');
        const alldata = JSON.parse(data);
        this.setState({ userId: alldata.id, deviceADcode: alldata.ADCode, userType: alldata.accounttypeUsers });

        let columns = [
            {
                name: 'Ad Code',
                selector: row => row.uuid,
                sortable: false
            },
            {
                name: 'Username',
                selector: row => row.uidUsers,
                sortable: false
            },
            {
                name: 'Firstname',
                selector: row => row.firstnameUsers,
                sortable: false
            },
            {
                name: 'Lastname',
                selector: row => row.lastnameUsers,
                sortable: false
            },
            {
                name: 'Email',
                selector: row => row.emailUsers,
                sortable: false

            },
            {
                name: 'Phone No.',
                selector: row => row.phoneUsers,
                sortable: false

            },
            {
                name: 'Address',
                selector: row => row.addressUsers,
                sortable: false
            },
            {
                name: 'Account Type',
                selector: row => row.accounttypeUsers,
                sortable: false
            },
            {
                name: 'Credit',
                selector: row => row.credit,
                sortable: false
            },
            {
                name: 'Credit Status',
                selector: row => row.creditStatus,
                sortable: false,
            },
            {
                name: 'Verified',
                selector: row => row.verified,
                cell: row => <div data-tag="allowRowEvents" style={{ color: "#28a745" }}>{row.verified === "1" ? `Yes` : ""}</div>,
                sortable: false,
            },
            {
                name: "Action",
                cell: row => <DropMenu size="small" row={row} getAllUSersHandler={this.getAllUSersHandler} />,
                allowOverflow: true
            }
        ];
        this.getAllUSersHandler()
        this.setState({ columns });
    }

    render() {
        const { loading, data, columns, showData } = this.state;

        const tableData = {
            columns,
            exportHeaders: true,
            export: false,
            print: false,
            data: showData,
        };
        return (
            <div className={"mt-2 style"}>
                <Card>
                    <CardHeader className="c-header">Users</CardHeader>
                    <CardBody className='inputTable-padding'>
                        {
                            loading ?
                                <div className="d-flex justify-content-center scan-parcel-loader mt-5 pt-5">
                                    <ClipLoader
                                        size={50}
                                        color={"#123abc"}
                                        loading={loading}
                                    />
                                </div>
                                :
                                <DataTableExtensions  {...tableData}>
                                    <DataTable
                                        className="dataTables_wrapper"
                                        pagination
                                        columns={columns}
                                        export={true}
                                        responsive={true}
                                        data={data}
                                    />
                                </DataTableExtensions>
                        }
                    </CardBody>
                </Card>
            </div>
        )
    }
}

