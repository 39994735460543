import PaypalExpressBtn from "react-paypal-express-checkout";
import React, { Component } from 'react'
import { createPayment, getCreditUnits } from '../../api/Form';
import { image } from '../../api/URLS';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CardHeader, CardBody, FormGroup, Label, Input, Card, Button, FormFeedback } from 'reactstrap';
import { callActivityApi } from '../../utils/saveUserActivity';
import { Link } from 'react-router-dom';
import {
    paypal_secret_code,
    paypal_client_id,
    paypal_sandbox_enable,
    paypal_sandbox_secret_code,
    paypal_sandbox_client_id
} from "../../api/URLS";
import { phoneNoVerifyCheck } from "../../utils/utils";

const client = {
    sandbox: paypal_sandbox_client_id,
    production: paypal_client_id,
}

export default class PayPal extends Component {
    state = {
        credits: "",
        paymentDetails: "",
        singleUnit: 0.01,
    };
    handleChange = (e) => {
        this.setState({ credits: e.target.value });
    };
    submitHandler = (e) => {
    };

     

    componentDidMount() {
        callActivityApi({ 'pageName': 'Purchase Credits', 'description': 'User has visited Purchase Credits' });
        const historyPush = this.props.history.push
        phoneNoVerifyCheck(historyPush)
        getCreditUnits().then((response) => {
            this.setState({singleUnit: response.data.data.single_unit})
        }).catch(err => {

        })
    }

    purchase = async (payment) => {
        const deviceADcode = await localStorage.getItem('adCodes');
        const userId = await localStorage.getItem('userId');
        createPayment({
            userId,
            deviceADcode,
            paid: payment.paid,
            cancelled: payment.cancelled,
            payerID: payment.payerID,
            paymentID: payment.paymentID,
            paymentToken: payment.paymentToken,
            email: payment.email,
            amount: parseInt(parseInt(this.state.credits * this.state.singleUnit)),
            credits: this.state.credits,
        }).then((response) => {
            // console.log('response', response);
            toast.success('Credits has been added successfully', {
                position: toast.POSITION.TOP_RIGHT
            })
            this.props.history.push('/admin/index');
        }).catch(err => {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
    }

    render() {

        const onSuccess = (payment) => {
            console.log("Payment successful", payment);
            this.purchase(payment);
        };
        const onCancel = (data) => {
            console.log("Payment cancelled!", data);
        };
        const onError = (err) => {
            toast.error(err, {
                position: toast.POSITION.TOP_RIGHT
            })
        };
        // console.log(
        //     "Payment paymentDetails!========",
        //     this.state.paymentDetails.paymentID
        // );


        return (
            <div className="mt-2 style">
                <Card>
                    <CardHeader className="c-header">
                        <div className="row ">
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-8">
                                <span>Purchase Credits</span>
                                
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody>
                    <div className="d-flex justify-content-end">
                        <button className={'btn btn-submit'} onClick={() => this.props.history.push('/admin/purchaseHistory')}>View Purchase History</button>
					</div>
                        <FormGroup>
                            <p>Per credit price $ {this.state.singleUnit}</p>
                            <Label>Enter Credits</Label>
                            <Input
                                type="number"
                                name="amount"
                                onChange={(e) => this.handleChange(e)}
                                variant="outlined"
                                placeholder="Enter Credits"
                                value={this.state.credits}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label>Total Amount</Label>
                            <p>$ {(this.state.credits * this.state.singleUnit)}</p>
                        </FormGroup>

                        <div style={{ marginTop: "10px" }}>
                            {this.state.credits > 0 ? <PaypalExpressBtn
                                env={paypal_sandbox_enable ? 'sandbox' : 'production'}
                                client={client}
                                currency={"USD"}
                                total={parseInt(this.state.credits * this.state.singleUnit)}
                                onClick={this.submitHandler}
                                onSuccess={onSuccess}
                                onError={onError}
                                shipping={1}
                                onCancel={onCancel}
                                style={{
                                    size: "medium",
                                    color: "gold",
                                    tagline: false,
                                    layout: "horizontal",
                                    label: "paypal",
                                }}
                            /> : null}
                        </div>

                        {this.state.paymentDetails.paid === false && (
                            <div className="rules5">
                                <h4>Payment Successful</h4>
                            </div>
                        )}
                    </CardBody>
                </Card>
            </div>
        );
    }
}
