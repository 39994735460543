export const seaPort = [
    {
        "label": "AEAJM",
        "value": "AEAJM",
        "country": "United Arab Emirates"
    },
    {
        "label": "AEAUH",
        "value": "AEAUH",
        "country": "United Arab Emirates"
    },
    {
        "label": "AEDXB",
        "value": "AEDXB",
        "country": "United Arab Emirates"
    },
    {
        "label": "AEFJR",
        "value": "AEFJR",
        "country": "United Arab Emirates"
    },
    {
        "label": "AEJEA",
        "value": "AEJEA",
        "country": "United Arab Emirates"
    },
    {
        "label": "AEJED",
        "value": "AEJED",
        "country": "United Arab Emirates"
    },
    {
        "label": "AEKLF",
        "value": "AEKLF",
        "country": "United Arab Emirates"
    },
    {
        "label": "AEPRA",
        "value": "AEPRA",
        "country": "United Arab Emirates"
    },
    {
        "label": "AEQIW",
        "value": "AEQIW",
        "country": "United Arab Emirates"
    },
    {
        "label": "AERKT",
        "value": "AERKT",
        "country": "United Arab Emirates"
    },
    {
        "label": "AERUW",
        "value": "AERUW",
        "country": "United Arab Emirates"
    },
    {
        "label": "AESHJ",
        "value": "AESHJ",
        "country": "United Arab Emirates"
    },
    {
        "label": "AGANU",
        "value": "AGANU",
        "country": "Antigua and Barbuda"
    },
    {
        "label": "AGSJO",
        "value": "AGSJO",
        "country": "Antigua and Barbuda"
    },
    {
        "label": "AIBLP",
        "value": "AIBLP",
        "country": "Anguilla"
    },
    {
        "label": "AIRBY",
        "value": "AIRBY",
        "country": "Anguilla"
    },
    {
        "label": "ALDRZ",
        "value": "ALDRZ",
        "country": "Albania"
    },
    {
        "label": "ANBON",
        "value": "ANBON",
        "country": "Netherlands Antilles"
    },
    {
        "label": "ANCUR",
        "value": "ANCUR",
        "country": "Netherlands Antilles"
    },
    {
        "label": "ANEUX",
        "value": "ANEUX",
        "country": "Netherlands"
    },
    {
        "label": "ANGSB",
        "value": "ANGSB",
        "country": "Netherlands Antilles"
    },
    {
        "label": "ANPHI",
        "value": "ANPHI",
        "country": "Netherlands Antilles"
    },
    {
        "label": "ANSXM",
        "value": "ANSXM",
        "country": "Netherlands Antilles"
    },
    {
        "label": "ANWIL",
        "value": "ANWIL",
        "country": "Netherlands Antilles"
    },
    {
        "label": "AOCAB",
        "value": "AOCAB",
        "country": "Angola"
    },
    {
        "label": "AOLAD",
        "value": "AOLAD",
        "country": "Angola"
    },
    {
        "label": "AOLOB",
        "value": "AOLOB",
        "country": "Angola"
    },
    {
        "label": "AOMSZ",
        "value": "AOMSZ",
        "country": "Angola"
    },
    {
        "label": "AOSZA",
        "value": "AOSZA",
        "country": "Angola"
    },
    {
        "label": "ARBHI",
        "value": "ARBHI",
        "country": "Argentina"
    },
    {
        "label": "ARBUE",
        "value": "ARBUE",
        "country": "Argentina"
    },
    {
        "label": "ARCMP",
        "value": "ARCMP",
        "country": "Argentina"
    },
    {
        "label": "ARCNQ",
        "value": "ARCNQ",
        "country": "Argentina"
    },
    {
        "label": "ARCRD",
        "value": "ARCRD",
        "country": "Argentina"
    },
    {
        "label": "ARCVI",
        "value": "ARCVI",
        "country": "Argentina"
    },
    {
        "label": "ARDDC",
        "value": "ARDDC",
        "country": "Argentina"
    },
    {
        "label": "ARLPG",
        "value": "ARLPG",
        "country": "Argentina"
    },
    {
        "label": "ARMDQ",
        "value": "ARMDQ",
        "country": "Argentina"
    },
    {
        "label": "ARNEC",
        "value": "ARNEC",
        "country": "Argentina"
    },
    {
        "label": "AROYA",
        "value": "AROYA",
        "country": "Argentina"
    },
    {
        "label": "ARPMY",
        "value": "ARPMY",
        "country": "Argentina"
    },
    {
        "label": "ARPUD",
        "value": "ARPUD",
        "country": "Argentina"
    },
    {
        "label": "ARRGA",
        "value": "ARRGA",
        "country": "Argentina"
    },
    {
        "label": "ARRGL",
        "value": "ARRGL",
        "country": "Argentina"
    },
    {
        "label": "ARRIC",
        "value": "ARRIC",
        "country": "Argentina"
    },
    {
        "label": "ARROS",
        "value": "ARROS",
        "country": "Argentina"
    },
    {
        "label": "ARSAE",
        "value": "ARSAE",
        "country": "Argentina"
    },
    {
        "label": "ARSFN",
        "value": "ARSFN",
        "country": "Argentina"
    },
    {
        "label": "ARSLO",
        "value": "ARSLO",
        "country": "Argentina"
    },
    {
        "label": "ARSNS",
        "value": "ARSNS",
        "country": "Argentina"
    },
    {
        "label": "ARSPD",
        "value": "ARSPD",
        "country": "Argentina"
    },
    {
        "label": "ARUSH",
        "value": "ARUSH",
        "country": "Argentina"
    },
    {
        "label": "ARZAE",
        "value": "ARZAE",
        "country": "Argentina"
    },
    {
        "label": "ASPPG",
        "value": "ASPPG",
        "country": "American Samoa"
    },
    {
        "label": "ATLTX",
        "value": "ATLTX",
        "country": "Austria"
    },
    {
        "label": "AUABP",
        "value": "AUABP",
        "country": "Australia"
    },
    {
        "label": "AUADL",
        "value": "AUADL",
        "country": "Australia"
    },
    {
        "label": "AUARD",
        "value": "AUARD",
        "country": "Australia"
    },
    {
        "label": "AUBBY",
        "value": "AUBBY",
        "country": "Australia"
    },
    {
        "label": "AUBEL",
        "value": "AUBEL",
        "country": "Australia"
    },
    {
        "label": "AUBNE",
        "value": "AUBNE",
        "country": "Australia"
    },
    {
        "label": "AUBOO",
        "value": "AUBOO",
        "country": "Australia"
    },
    {
        "label": "AUBWT",
        "value": "AUBWT",
        "country": "Australia"
    },
    {
        "label": "AUCHT",
        "value": "AUCHT",
        "country": "Australia"
    },
    {
        "label": "AUCNS",
        "value": "AUCNS",
        "country": "Australia"
    },
    {
        "label": "AUDPO",
        "value": "AUDPO",
        "country": "Australia"
    },
    {
        "label": "AUDRW",
        "value": "AUDRW",
        "country": "Australia"
    },
    {
        "label": "AUFRE",
        "value": "AUFRE",
        "country": "Australia"
    },
    {
        "label": "AUGLT",
        "value": "AUGLT",
        "country": "Australia"
    },
    {
        "label": "AUGOV",
        "value": "AUGOV",
        "country": "Australia"
    },
    {
        "label": "AUHBA",
        "value": "AUHBA",
        "country": "Australia"
    },
    {
        "label": "AUHPT",
        "value": "AUHPT",
        "country": "Australia"
    },
    {
        "label": "AULST",
        "value": "AULST",
        "country": "Australia"
    },
    {
        "label": "AUMEL",
        "value": "AUMEL",
        "country": "Australia"
    },
    {
        "label": "AUNLK",
        "value": "AUNLK",
        "country": "Australia"
    },
    {
        "label": "AUNTL",
        "value": "AUNTL",
        "country": "Australia"
    },
    {
        "label": "AUPAE",
        "value": "AUPAE",
        "country": "Australia"
    },
    {
        "label": "AUPHE",
        "value": "AUPHE",
        "country": "Australia"
    },
    {
        "label": "AUPKL",
        "value": "AUPKL",
        "country": "Australia"
    },
    {
        "label": "AUSYD",
        "value": "AUSYD",
        "country": "Australia"
    },
    {
        "label": "AUTSV",
        "value": "AUTSV",
        "country": "Australia"
    },
    {
        "label": "AUWEI",
        "value": "AUWEI",
        "country": "Australia"
    },
    {
        "label": "AWAUA",
        "value": "AWAUA",
        "country": "Aruba"
    },
    {
        "label": "AWORJ",
        "value": "AWORJ",
        "country": "Aruba"
    },
    {
        "label": "AZBAK",
        "value": "AZBAK",
        "country": "Azerbaijan"
    },
    {
        "label": "BBBGI",
        "value": "BBBGI",
        "country": "Barbados"
    },
    {
        "label": "BDCGP",
        "value": "BDCGP",
        "country": "Bangladesh"
    },
    {
        "label": "BDCHL",
        "value": "BDCHL",
        "country": "Bangladesh"
    },
    {
        "label": "BDDAC",
        "value": "BDDAC",
        "country": "Bangladesh"
    },
    {
        "label": "BDMGL",
        "value": "BDMGL",
        "country": "Bangladesh"
    },
    {
        "label": "BEANR",
        "value": "BEANR",
        "country": "Belgium"
    },
    {
        "label": "BEBSU",
        "value": "BEBSU",
        "country": "Belgium"
    },
    {
        "label": "BEDEL",
        "value": "BEDEL",
        "country": "Belgium"
    },
    {
        "label": "BEGNE",
        "value": "BEGNE",
        "country": "Belgium"
    },
    {
        "label": "BEOST",
        "value": "BEOST",
        "country": "Belgium"
    },
    {
        "label": "BEZEE",
        "value": "BEZEE",
        "country": "Belgium"
    },
    {
        "label": "BFOUA",
        "value": "BFOUA",
        "country": "Burkina Faso"
    },
    {
        "label": "BGBOJ",
        "value": "BGBOJ",
        "country": "Bulgaria"
    },
    {
        "label": "BGVAR",
        "value": "BGVAR",
        "country": "Bulgaria"
    },
    {
        "label": "BHAHD",
        "value": "BHAHD",
        "country": "Bahrain"
    },
    {
        "label": "BHBAH",
        "value": "BHBAH",
        "country": "Bahrain"
    },
    {
        "label": "BHKBS",
        "value": "BHKBS",
        "country": "Bahrain"
    },
    {
        "label": "BHMAN",
        "value": "BHMAN",
        "country": "Bahrain"
    },
    {
        "label": "BHSIT",
        "value": "BHSIT",
        "country": "Bahrain"
    },
    {
        "label": "BJCOO",
        "value": "BJCOO",
        "country": "Benin"
    },
    {
        "label": "BMBDA",
        "value": "BMBDA",
        "country": "Bermuda"
    },
    {
        "label": "BMSOU",
        "value": "BMSOU",
        "country": "Bermuda"
    },
    {
        "label": "BNBWN",
        "value": "BNBWN",
        "country": "Brunei Darussalam"
    },
    {
        "label": "BNKUB",
        "value": "BNKUB",
        "country": "Brunei Darussalam"
    },
    {
        "label": "BNMUA",
        "value": "BNMUA",
        "country": "Brunei Darussalam"
    },
    {
        "label": "BOPBU",
        "value": "BOPBU",
        "country": "Bolivia, Plurinational State of"
    },
    {
        "label": "BOSRZ",
        "value": "BOSRZ",
        "country": "Bolivia, Plurinational State of"
    },
    {
        "label": "BRARB",
        "value": "BRARB",
        "country": "Brazil"
    },
    {
        "label": "BRARE",
        "value": "BRARE",
        "country": "Brazil"
    },
    {
        "label": "BRBEL",
        "value": "BRBEL",
        "country": "Brazil"
    },
    {
        "label": "BRCDO",
        "value": "BRCDO",
        "country": "Brazil"
    },
    {
        "label": "BRFNO",
        "value": "BRFNO",
        "country": "Brazil"
    },
    {
        "label": "BRFOR",
        "value": "BRFOR",
        "country": "Brazil"
    },
    {
        "label": "BRIBB",
        "value": "BRIBB",
        "country": "Brazil"
    },
    {
        "label": "BRIGI",
        "value": "BRIGI",
        "country": "Brazil"
    },
    {
        "label": "BRIOA",
        "value": "BRIOA",
        "country": "Brazil"
    },
    {
        "label": "BRIOS",
        "value": "BRIOS",
        "country": "Brazil"
    },
    {
        "label": "BRIRO",
        "value": "BRIRO",
        "country": "Brazil"
    },
    {
        "label": "BRITJ",
        "value": "BRITJ",
        "country": "Brazil"
    },
    {
        "label": "BRITQ",
        "value": "BRITQ",
        "country": "Brazil"
    },
    {
        "label": "BRITZ",
        "value": "BRITZ",
        "country": "Brazil"
    },
    {
        "label": "BRMAO",
        "value": "BRMAO",
        "country": "Brazil"
    },
    {
        "label": "BRMCZ",
        "value": "BRMCZ",
        "country": "Brazil"
    },
    {
        "label": "BRNAT",
        "value": "BRNAT",
        "country": "Brazil"
    },
    {
        "label": "BRNVT",
        "value": "BRNVT",
        "country": "Brazil"
    },
    {
        "label": "BRPCL",
        "value": "BRPCL",
        "country": "Brazil"
    },
    {
        "label": "BRPEC",
        "value": "BRPEC",
        "country": "Brazil"
    },
    {
        "label": "BRPNG",
        "value": "BRPNG",
        "country": "Brazil"
    },
    {
        "label": "BRPNN",
        "value": "BRPNN",
        "country": "Brazil"
    },
    {
        "label": "BRPOA",
        "value": "BRPOA",
        "country": "Brazil"
    },
    {
        "label": "BRREC",
        "value": "BRREC",
        "country": "Brazil"
    },
    {
        "label": "BRRIG",
        "value": "BRRIG",
        "country": "Brazil"
    },
    {
        "label": "BRRIO",
        "value": "BRRIO",
        "country": "Brazil"
    },
    {
        "label": "BRSAL",
        "value": "BRSAL",
        "country": "Brazil"
    },
    {
        "label": "BRSFS",
        "value": "BRSFS",
        "country": "Brazil"
    },
    {
        "label": "BRSPB",
        "value": "BRSPB",
        "country": "Brazil"
    },
    {
        "label": "BRSSA",
        "value": "BRSSA",
        "country": "Brazil"
    },
    {
        "label": "BRSSO",
        "value": "BRSSO",
        "country": "Brazil"
    },
    {
        "label": "BRSSZ",
        "value": "BRSSZ",
        "country": "Brazil"
    },
    {
        "label": "BRSTM",
        "value": "BRSTM",
        "country": "Brazil"
    },
    {
        "label": "BRSUA",
        "value": "BRSUA",
        "country": "Brazil"
    },
    {
        "label": "BRVDC",
        "value": "BRVDC",
        "country": "Brazil"
    },
    {
        "label": "BRVIC",
        "value": "BRVIC",
        "country": "Brazil"
    },
    {
        "label": "BRVIX",
        "value": "BRVIX",
        "country": "Brazil"
    },
    {
        "label": "BRVLC",
        "value": "BRVLC",
        "country": "Brazil"
    },
    {
        "label": "BSFPO",
        "value": "BSFPO",
        "country": "Bahamas"
    },
    {
        "label": "BSNAS",
        "value": "BSNAS",
        "country": "Bahamas"
    },
    {
        "label": "BYMSQ",
        "value": "BYMSQ",
        "country": "Belarus"
    },
    {
        "label": "BZBZE",
        "value": "BZBZE",
        "country": "Belize"
    },
    {
        "label": "CABAT",
        "value": "CABAT",
        "country": "Canada"
    },
    {
        "label": "CABCK",
        "value": "CABCK",
        "country": "Canada"
    },
    {
        "label": "CABDU",
        "value": "CABDU",
        "country": "Canada"
    },
    {
        "label": "CABEC",
        "value": "CABEC",
        "country": "Canada"
    },
    {
        "label": "CABJO",
        "value": "CABJO",
        "country": "Canada"
    },
    {
        "label": "CABRL",
        "value": "CABRL",
        "country": "Canada"
    },
    {
        "label": "CACAL",
        "value": "CACAL",
        "country": "Canada"
    },
    {
        "label": "CACBK",
        "value": "CACBK",
        "country": "Canada"
    },
    {
        "label": "CACCB",
        "value": "CACCB",
        "country": "Canada"
    },
    {
        "label": "CACOL",
        "value": "CACOL",
        "country": "Canada"
    },
    {
        "label": "CACRO",
        "value": "CACRO",
        "country": "Canada"
    },
    {
        "label": "CACWL",
        "value": "CACWL",
        "country": "Canada"
    },
    {
        "label": "CADOR",
        "value": "CADOR",
        "country": "Canada"
    },
    {
        "label": "CADRU",
        "value": "CADRU",
        "country": "Canada"
    },
    {
        "label": "CAEDM",
        "value": "CAEDM",
        "country": "Canada"
    },
    {
        "label": "CAHAL",
        "value": "CAHAL",
        "country": "Canada"
    },
    {
        "label": "CAHAM",
        "value": "CAHAM",
        "country": "Canada"
    },
    {
        "label": "CAHMC",
        "value": "CAHMC",
        "country": "Canada"
    },
    {
        "label": "CAKVL",
        "value": "CAKVL",
        "country": "Canada"
    },
    {
        "label": "CAMAG",
        "value": "CAMAG",
        "country": "Canada"
    },
    {
        "label": "CAMNT",
        "value": "CAMNT",
        "country": "Canada"
    },
    {
        "label": "CAMTR",
        "value": "CAMTR",
        "country": "Canada"
    },
    {
        "label": "CANIA",
        "value": "CANIA",
        "country": "Canada"
    },
    {
        "label": "CANNO",
        "value": "CANNO",
        "country": "Canada"
    },
    {
        "label": "CANOR",
        "value": "CANOR",
        "country": "Canada"
    },
    {
        "label": "CANWE",
        "value": "CANWE",
        "country": "Canada"
    },
    {
        "label": "CAOAK",
        "value": "CAOAK",
        "country": "Canada"
    },
    {
        "label": "CAODE",
        "value": "CAODE",
        "country": "Canada"
    },
    {
        "label": "CAOSH",
        "value": "CAOSH",
        "country": "Canada"
    },
    {
        "label": "CAPCR",
        "value": "CAPCR",
        "country": "Canada"
    },
    {
        "label": "CAPDV",
        "value": "CAPDV",
        "country": "Canada"
    },
    {
        "label": "CAPML",
        "value": "CAPML",
        "country": "Canada"
    },
    {
        "label": "CAPOI",
        "value": "CAPOI",
        "country": "Canada"
    },
    {
        "label": "CAPRR",
        "value": "CAPRR",
        "country": "Canada"
    },
    {
        "label": "CAQUE",
        "value": "CAQUE",
        "country": "Canada"
    },
    {
        "label": "CASAK",
        "value": "CASAK",
        "country": "Canada"
    },
    {
        "label": "CASCA",
        "value": "CASCA",
        "country": "Canada"
    },
    {
        "label": "CASJB",
        "value": "CASJB",
        "country": "Canada"
    },
    {
        "label": "CASJF",
        "value": "CASJF",
        "country": "Canada"
    },
    {
        "label": "CASNI",
        "value": "CASNI",
        "country": "Canada"
    },
    {
        "label": "CASOR",
        "value": "CASOR",
        "country": "Canada"
    },
    {
        "label": "CASSM",
        "value": "CASSM",
        "country": "Canada"
    },
    {
        "label": "CASTC",
        "value": "CASTC",
        "country": "Canada"
    },
    {
        "label": "CASUM",
        "value": "CASUM",
        "country": "Canada"
    },
    {
        "label": "CATEX",
        "value": "CATEX",
        "country": "Canada"
    },
    {
        "label": "CATHU",
        "value": "CATHU",
        "country": "Canada"
    },
    {
        "label": "CATOR",
        "value": "CATOR",
        "country": "Canada"
    },
    {
        "label": "CATRR",
        "value": "CATRR",
        "country": "Canada"
    },
    {
        "label": "CATTC",
        "value": "CATTC",
        "country": "Canada"
    },
    {
        "label": "CAVAN",
        "value": "CAVAN",
        "country": "Canada"
    },
    {
        "label": "CAVLF",
        "value": "CAVLF",
        "country": "Canada"
    },
    {
        "label": "CAWIN",
        "value": "CAWIN",
        "country": "Canada"
    },
    {
        "label": "CAWNP",
        "value": "CAWNP",
        "country": "Canada"
    },
    {
        "label": "CDBOA",
        "value": "CDBOA",
        "country": "Congo, The Democratic Republic of the"
    },
    {
        "label": "CDMAT",
        "value": "CDMAT",
        "country": "Congo, The Democratic Republic of the"
    },
    {
        "label": "CGPNR",
        "value": "CGPNR",
        "country": "Congo"
    },
    {
        "label": "CIABJ",
        "value": "CIABJ",
        "country": "C\u00f4te d'Ivoire"
    },
    {
        "label": "CISPY",
        "value": "CISPY",
        "country": "C\u00f4te d'Ivoire"
    },
    {
        "label": "CLANF",
        "value": "CLANF",
        "country": "Chile"
    },
    {
        "label": "CLARI",
        "value": "CLARI",
        "country": "Chile"
    },
    {
        "label": "CLBAG",
        "value": "CLBAG",
        "country": "Chile"
    },
    {
        "label": "CLBAR",
        "value": "CLBAR",
        "country": "Chile"
    },
    {
        "label": "CLCAP",
        "value": "CLCAP",
        "country": "Chile"
    },
    {
        "label": "CLCBC",
        "value": "CLCBC",
        "country": "Chile"
    },
    {
        "label": "CLCCL",
        "value": "CLCCL",
        "country": "Chile"
    },
    {
        "label": "CLCHB",
        "value": "CLCHB",
        "country": "Chile"
    },
    {
        "label": "CLCLD",
        "value": "CLCLD",
        "country": "Chile"
    },
    {
        "label": "CLCNL",
        "value": "CLCNL",
        "country": "Chile"
    },
    {
        "label": "CLCNR",
        "value": "CLCNR",
        "country": "Chile"
    },
    {
        "label": "CLCNX",
        "value": "CLCNX",
        "country": "Chile"
    },
    {
        "label": "CLCQQ",
        "value": "CLCQQ",
        "country": "Chile"
    },
    {
        "label": "CLCRR",
        "value": "CLCRR",
        "country": "Chile"
    },
    {
        "label": "CLDCH",
        "value": "CLDCH",
        "country": "Chile"
    },
    {
        "label": "CLGYC",
        "value": "CLGYC",
        "country": "Chile"
    },
    {
        "label": "CLHSO",
        "value": "CLHSO",
        "country": "Chile"
    },
    {
        "label": "CLIPC",
        "value": "CLIPC",
        "country": "Chile"
    },
    {
        "label": "CLIQQ",
        "value": "CLIQQ",
        "country": "Chile"
    },
    {
        "label": "CLJRL",
        "value": "CLJRL",
        "country": "Chile"
    },
    {
        "label": "CLLOS",
        "value": "CLLOS",
        "country": "Chile"
    },
    {
        "label": "CLLQN",
        "value": "CLLQN",
        "country": "Chile"
    },
    {
        "label": "CLLVS",
        "value": "CLLVS",
        "country": "Chile"
    },
    {
        "label": "CLMCH",
        "value": "CLMCH",
        "country": "Chile"
    },
    {
        "label": "CLMJS",
        "value": "CLMJS",
        "country": "Chile"
    },
    {
        "label": "CLPAG",
        "value": "CLPAG",
        "country": "Chile"
    },
    {
        "label": "CLPCH",
        "value": "CLPCH",
        "country": "Chile"
    },
    {
        "label": "CLPEO",
        "value": "CLPEO",
        "country": "Chile"
    },
    {
        "label": "CLPGO",
        "value": "CLPGO",
        "country": "Chile"
    },
    {
        "label": "CLPMC",
        "value": "CLPMC",
        "country": "Chile"
    },
    {
        "label": "CLPNT",
        "value": "CLPNT",
        "country": "Chile"
    },
    {
        "label": "CLPPC",
        "value": "CLPPC",
        "country": "Chile"
    },
    {
        "label": "CLPPD",
        "value": "CLPPD",
        "country": "Chile"
    },
    {
        "label": "CLPTE",
        "value": "CLPTE",
        "country": "Chile"
    },
    {
        "label": "CLPTI",
        "value": "CLPTI",
        "country": "Chile"
    },
    {
        "label": "CLPUQ",
        "value": "CLPUQ",
        "country": "Chile"
    },
    {
        "label": "CLQMC",
        "value": "CLQMC",
        "country": "Chile"
    },
    {
        "label": "CLQTV",
        "value": "CLQTV",
        "country": "Chile"
    },
    {
        "label": "CLSAI",
        "value": "CLSAI",
        "country": "Chile"
    },
    {
        "label": "CLSVE",
        "value": "CLSVE",
        "country": "Chile"
    },
    {
        "label": "CLTAL",
        "value": "CLTAL",
        "country": "Chile"
    },
    {
        "label": "CLTHO",
        "value": "CLTHO",
        "country": "Chile"
    },
    {
        "label": "CLTOM",
        "value": "CLTOM",
        "country": "Chile"
    },
    {
        "label": "CLTOQ",
        "value": "CLTOQ",
        "country": "Chile"
    },
    {
        "label": "CLTTC",
        "value": "CLTTC",
        "country": "Chile"
    },
    {
        "label": "CLVAP",
        "value": "CLVAP",
        "country": "Chile"
    },
    {
        "label": "CLWCA",
        "value": "CLWCA",
        "country": "Chile"
    },
    {
        "label": "CLWCH",
        "value": "CLWCH",
        "country": "Chile"
    },
    {
        "label": "CLWPA",
        "value": "CLWPA",
        "country": "Chile"
    },
    {
        "label": "CLWPU",
        "value": "CLWPU",
        "country": "Chile"
    },
    {
        "label": "CLZUD",
        "value": "CLZUD",
        "country": "Chile"
    },
    {
        "label": "CMDLA",
        "value": "CMDLA",
        "country": "Cameroon"
    },
    {
        "label": "CNBAY",
        "value": "CNBAY",
        "country": "China"
    },
    {
        "label": "CNBHY",
        "value": "CNBHY",
        "country": "China"
    },
    {
        "label": "CNBJO",
        "value": "CNBJO",
        "country": "China"
    },
    {
        "label": "CNCAN",
        "value": "CNCAN",
        "country": "China"
    },
    {
        "label": "CNCGU",
        "value": "CNCGU",
        "country": "China"
    },
    {
        "label": "CNCKG",
        "value": "CNCKG",
        "country": "China"
    },
    {
        "label": "CNCOZ",
        "value": "CNCOZ",
        "country": "China"
    },
    {
        "label": "CNCSX",
        "value": "CNCSX",
        "country": "China"
    },
    {
        "label": "CNCTU",
        "value": "CNCTU",
        "country": "China"
    },
    {
        "label": "CNCWN",
        "value": "CNCWN",
        "country": "China"
    },
    {
        "label": "CNDAA",
        "value": "CNDAA",
        "country": "China"
    },
    {
        "label": "CNDAL",
        "value": "CNDAL",
        "country": "China"
    },
    {
        "label": "CNDCB",
        "value": "CNDCB",
        "country": "China"
    },
    {
        "label": "CNDGG",
        "value": "CNDGG",
        "country": "China"
    },
    {
        "label": "CNDLC",
        "value": "CNDLC",
        "country": "China"
    },
    {
        "label": "CNDOU",
        "value": "CNDOU",
        "country": "China"
    },
    {
        "label": "CNFAN",
        "value": "CNFAN",
        "country": "China"
    },
    {
        "label": "CNFNG",
        "value": "CNFNG",
        "country": "China"
    },
    {
        "label": "CNFOC",
        "value": "CNFOC",
        "country": "China"
    },
    {
        "label": "CNFOS",
        "value": "CNFOS",
        "country": "China"
    },
    {
        "label": "CNFUG",
        "value": "CNFUG",
        "country": "China"
    },
    {
        "label": "CNGGY",
        "value": "CNGGY",
        "country": "China"
    },
    {
        "label": "CNGOG",
        "value": "CNGOG",
        "country": "China"
    },
    {
        "label": "CNGOM",
        "value": "CNGOM",
        "country": "China"
    },
    {
        "label": "CNGUG",
        "value": "CNGUG",
        "country": "China"
    },
    {
        "label": "CNHAK",
        "value": "CNHAK",
        "country": "China"
    },
    {
        "label": "CNHDU",
        "value": "CNHDU",
        "country": "China"
    },
    {
        "label": "CNHEB",
        "value": "CNHEB",
        "country": "China"
    },
    {
        "label": "CNHGH",
        "value": "CNHGH",
        "country": "China"
    },
    {
        "label": "CNHME",
        "value": "CNHME",
        "country": "China"
    },
    {
        "label": "CNHMN",
        "value": "CNHMN",
        "country": "China"
    },
    {
        "label": "CNHSN",
        "value": "CNHSN",
        "country": "China"
    },
    {
        "label": "CNHUA",
        "value": "CNHUA",
        "country": "China"
    },
    {
        "label": "CNHUD",
        "value": "CNHUD",
        "country": "China"
    },
    {
        "label": "CNHUI",
        "value": "CNHUI",
        "country": "China"
    },
    {
        "label": "CNJGZ",
        "value": "CNJGZ",
        "country": "China"
    },
    {
        "label": "CNJIA",
        "value": "CNJIA",
        "country": "China"
    },
    {
        "label": "CNJIN",
        "value": "CNJIN",
        "country": "China"
    },
    {
        "label": "CNJIU",
        "value": "CNJIU",
        "country": "China"
    },
    {
        "label": "CNJMN",
        "value": "CNJMN",
        "country": "China"
    },
    {
        "label": "CNJNZ",
        "value": "CNJNZ",
        "country": "China"
    },
    {
        "label": "CNJXN",
        "value": "CNJXN",
        "country": "China"
    },
    {
        "label": "CNJZU",
        "value": "CNJZU",
        "country": "China"
    },
    {
        "label": "CNKHN",
        "value": "CNKHN",
        "country": "China"
    },
    {
        "label": "CNKMG",
        "value": "CNKMG",
        "country": "China"
    },
    {
        "label": "CNKPN",
        "value": "CNKPN",
        "country": "China"
    },
    {
        "label": "CNLAN",
        "value": "CNLAN",
        "country": "China"
    },
    {
        "label": "CNLGH",
        "value": "CNLGH",
        "country": "China"
    },
    {
        "label": "CNLIH",
        "value": "CNLIH",
        "country": "China"
    },
    {
        "label": "CNLKU",
        "value": "CNLKU",
        "country": "China"
    },
    {
        "label": "CNLSI",
        "value": "CNLSI",
        "country": "China"
    },
    {
        "label": "CNLYG",
        "value": "CNLYG",
        "country": "China"
    },
    {
        "label": "CNLZH",
        "value": "CNLZH",
        "country": "China"
    },
    {
        "label": "CNMAW",
        "value": "CNMAW",
        "country": "China"
    },
    {
        "label": "CNMMI",
        "value": "CNMMI",
        "country": "China"
    },
    {
        "label": "CNMWN",
        "value": "CNMWN",
        "country": "China"
    },
    {
        "label": "CNMYG",
        "value": "CNMYG",
        "country": "China"
    },
    {
        "label": "CNNAH",
        "value": "CNNAH",
        "country": "China"
    },
    {
        "label": "CNNBO",
        "value": "CNNBO",
        "country": "China"
    },
    {
        "label": "CNNGB",
        "value": "CNNGB",
        "country": "China"
    },
    {
        "label": "CNNGG",
        "value": "CNNGG",
        "country": "China"
    },
    {
        "label": "CNNKG",
        "value": "CNNKG",
        "country": "China"
    },
    {
        "label": "CNNNG",
        "value": "CNNNG",
        "country": "China"
    },
    {
        "label": "CNNSA",
        "value": "CNNSA",
        "country": "China"
    },
    {
        "label": "CNNTG",
        "value": "CNNTG",
        "country": "China"
    },
    {
        "label": "CNPNY",
        "value": "CNPNY",
        "country": "China"
    },
    {
        "label": "CNPUT",
        "value": "CNPUT",
        "country": "China"
    },
    {
        "label": "CNQAW",
        "value": "CNQAW",
        "country": "China"
    },
    {
        "label": "CNQIN",
        "value": "CNQIN",
        "country": "China"
    },
    {
        "label": "CNQYN",
        "value": "CNQYN",
        "country": "China"
    },
    {
        "label": "CNQZJ",
        "value": "CNQZJ",
        "country": "China"
    },
    {
        "label": "CNROQ",
        "value": "CNROQ",
        "country": "China"
    },
    {
        "label": "CNRZH",
        "value": "CNRZH",
        "country": "China"
    },
    {
        "label": "CNSBU",
        "value": "CNSBU",
        "country": "China"
    },
    {
        "label": "CNSDG",
        "value": "CNSDG",
        "country": "China"
    },
    {
        "label": "CNSGH",
        "value": "CNSGH",
        "country": "China"
    },
    {
        "label": "CNSHA",
        "value": "CNSHA",
        "country": "China"
    },
    {
        "label": "CNSHK",
        "value": "CNSHK",
        "country": "China"
    },
    {
        "label": "CNSHP",
        "value": "CNSHP",
        "country": "China"
    },
    {
        "label": "CNSIN",
        "value": "CNSIN",
        "country": "China"
    },
    {
        "label": "CNSJQ",
        "value": "CNSJQ",
        "country": "China"
    },
    {
        "label": "CNSNW",
        "value": "CNSNW",
        "country": "China"
    },
    {
        "label": "CNSSH",
        "value": "CNSSH",
        "country": "China"
    },
    {
        "label": "CNSTG",
        "value": "CNSTG",
        "country": "China"
    },
    {
        "label": "CNSUD",
        "value": "CNSUD",
        "country": "China"
    },
    {
        "label": "CNSWA",
        "value": "CNSWA",
        "country": "China"
    },
    {
        "label": "CNSWE",
        "value": "CNSWE",
        "country": "China"
    },
    {
        "label": "CNSZX",
        "value": "CNSZX",
        "country": "China"
    },
    {
        "label": "CNTAG",
        "value": "CNTAG",
        "country": "China"
    },
    {
        "label": "CNTAO",
        "value": "CNTAO",
        "country": "China"
    },
    {
        "label": "CNTAP",
        "value": "CNTAP",
        "country": "China"
    },
    {
        "label": "CNTHG",
        "value": "CNTHG",
        "country": "China"
    },
    {
        "label": "CNTSN",
        "value": "CNTSN",
        "country": "China"
    },
    {
        "label": "CNTXG",
        "value": "CNTXG",
        "country": "China"
    },
    {
        "label": "CNTZO",
        "value": "CNTZO",
        "country": "China"
    },
    {
        "label": "CNWAZ",
        "value": "CNWAZ",
        "country": "China"
    },
    {
        "label": "CNWEI",
        "value": "CNWEI",
        "country": "China"
    },
    {
        "label": "CNWHI",
        "value": "CNWHI",
        "country": "China"
    },
    {
        "label": "CNWNZ",
        "value": "CNWNZ",
        "country": "China"
    },
    {
        "label": "CNWUH",
        "value": "CNWUH",
        "country": "China"
    },
    {
        "label": "CNXAB",
        "value": "CNXAB",
        "country": "China"
    },
    {
        "label": "CNXAM",
        "value": "CNXAM",
        "country": "China"
    },
    {
        "label": "CNXGA",
        "value": "CNXGA",
        "country": "China"
    },
    {
        "label": "CNXGG",
        "value": "CNXGG",
        "country": "China"
    },
    {
        "label": "CNXIL",
        "value": "CNXIL",
        "country": "China"
    },
    {
        "label": "CNXIN",
        "value": "CNXIN",
        "country": "China"
    },
    {
        "label": "CNXIT",
        "value": "CNXIT",
        "country": "China"
    },
    {
        "label": "CNXMN",
        "value": "CNXMN",
        "country": "China"
    },
    {
        "label": "CNYAN",
        "value": "CNYAN",
        "country": "China"
    },
    {
        "label": "CNYIC",
        "value": "CNYIC",
        "country": "China"
    },
    {
        "label": "CNYIK",
        "value": "CNYIK",
        "country": "China"
    },
    {
        "label": "CNYJI",
        "value": "CNYJI",
        "country": "China"
    },
    {
        "label": "CNYNF",
        "value": "CNYNF",
        "country": "China"
    },
    {
        "label": "CNYNT",
        "value": "CNYNT",
        "country": "China"
    },
    {
        "label": "CNYPG",
        "value": "CNYPG",
        "country": "China"
    },
    {
        "label": "CNYQS",
        "value": "CNYQS",
        "country": "China"
    },
    {
        "label": "CNYTG",
        "value": "CNYTG",
        "country": "China"
    },
    {
        "label": "CNYTN",
        "value": "CNYTN",
        "country": "China"
    },
    {
        "label": "CNYZH",
        "value": "CNYZH",
        "country": "China"
    },
    {
        "label": "CNZAP",
        "value": "CNZAP",
        "country": "China"
    },
    {
        "label": "CNZHA",
        "value": "CNZHA",
        "country": "China"
    },
    {
        "label": "CNZHE",
        "value": "CNZHE",
        "country": "China"
    },
    {
        "label": "CNZJG",
        "value": "CNZJG",
        "country": "China"
    },
    {
        "label": "CNZOS",
        "value": "CNZOS",
        "country": "China"
    },
    {
        "label": "CNZQG",
        "value": "CNZQG",
        "country": "China"
    },
    {
        "label": "CNZSN",
        "value": "CNZSN",
        "country": "China"
    },
    {
        "label": "CNZUH",
        "value": "CNZUH",
        "country": "China"
    },
    {
        "label": "COBAQ",
        "value": "COBAQ",
        "country": "Colombia"
    },
    {
        "label": "COBUN",
        "value": "COBUN",
        "country": "Colombia"
    },
    {
        "label": "COCTG",
        "value": "COCTG",
        "country": "Colombia"
    },
    {
        "label": "COPBO",
        "value": "COPBO",
        "country": "Colombia"
    },
    {
        "label": "COSMR",
        "value": "COSMR",
        "country": "Colombia"
    },
    {
        "label": "COTCO",
        "value": "COTCO",
        "country": "Colombia"
    },
    {
        "label": "CRCAL",
        "value": "CRCAL",
        "country": "Costa Rica"
    },
    {
        "label": "CRGLF",
        "value": "CRGLF",
        "country": "Costa Rica"
    },
    {
        "label": "CRLIO",
        "value": "CRLIO",
        "country": "Costa Rica"
    },
    {
        "label": "CRMOB",
        "value": "CRMOB",
        "country": "Costa Rica"
    },
    {
        "label": "CRPAS",
        "value": "CRPAS",
        "country": "Costa Rica"
    },
    {
        "label": "CRPCA",
        "value": "CRPCA",
        "country": "Costa Rica"
    },
    {
        "label": "CRSJO",
        "value": "CRSJO",
        "country": "Costa Rica"
    },
    {
        "label": "CUHAV",
        "value": "CUHAV",
        "country": "Cuba"
    },
    {
        "label": "CUMAR",
        "value": "CUMAR",
        "country": "Cuba"
    },
    {
        "label": "CUQMA",
        "value": "CUQMA",
        "country": "Cuba"
    },
    {
        "label": "CUSCU",
        "value": "CUSCU",
        "country": "Cuba"
    },
    {
        "label": "CVMIN",
        "value": "CVMIN",
        "country": "Cape Verde"
    },
    {
        "label": "CVPAL",
        "value": "CVPAL",
        "country": "Cape Verde"
    },
    {
        "label": "CVRAI",
        "value": "CVRAI",
        "country": "Cape Verde"
    },
    {
        "label": "CYLMS",
        "value": "CYLMS",
        "country": "Cyprus"
    },
    {
        "label": "CYNIC",
        "value": "CYNIC",
        "country": "Cyprus"
    },
    {
        "label": "CYPFO",
        "value": "CYPFO",
        "country": "Cyprus"
    },
    {
        "label": "DEBEZ",
        "value": "DEBEZ",
        "country": "Germany"
    },
    {
        "label": "DEBIN",
        "value": "DEBIN",
        "country": "Germany"
    },
    {
        "label": "DEBKE",
        "value": "DEBKE",
        "country": "Germany"
    },
    {
        "label": "DEBOY",
        "value": "DEBOY",
        "country": "Germany"
    },
    {
        "label": "DEBRA",
        "value": "DEBRA",
        "country": "Germany"
    },
    {
        "label": "DEBRB",
        "value": "DEBRB",
        "country": "Germany"
    },
    {
        "label": "DEBRE",
        "value": "DEBRE",
        "country": "Germany"
    },
    {
        "label": "DEBRV",
        "value": "DEBRV",
        "country": "Germany"
    },
    {
        "label": "DEBSK",
        "value": "DEBSK",
        "country": "Germany"
    },
    {
        "label": "DECGN",
        "value": "DECGN",
        "country": "Germany"
    },
    {
        "label": "DECUX",
        "value": "DECUX",
        "country": "Germany"
    },
    {
        "label": "DEDUS",
        "value": "DEDUS",
        "country": "Germany"
    },
    {
        "label": "DEELS",
        "value": "DEELS",
        "country": "Germany"
    },
    {
        "label": "DEEME",
        "value": "DEEME",
        "country": "Germany"
    },
    {
        "label": "DEFLF",
        "value": "DEFLF",
        "country": "Germany"
    },
    {
        "label": "DEHAM",
        "value": "DEHAM",
        "country": "Germany"
    },
    {
        "label": "DEHBU",
        "value": "DEHBU",
        "country": "Germany"
    },
    {
        "label": "DEHMM",
        "value": "DEHMM",
        "country": "Germany"
    },
    {
        "label": "DEKEL",
        "value": "DEKEL",
        "country": "Germany"
    },
    {
        "label": "DEKGU",
        "value": "DEKGU",
        "country": "Germany"
    },
    {
        "label": "DEKHO",
        "value": "DEKHO",
        "country": "Germany"
    },
    {
        "label": "DELBC",
        "value": "DELBC",
        "country": "Germany"
    },
    {
        "label": "DELEE",
        "value": "DELEE",
        "country": "Germany"
    },
    {
        "label": "DEMHG",
        "value": "DEMHG",
        "country": "Germany"
    },
    {
        "label": "DENHA",
        "value": "DENHA",
        "country": "Germany"
    },
    {
        "label": "DENOR",
        "value": "DENOR",
        "country": "Germany"
    },
    {
        "label": "DEPFR",
        "value": "DEPFR",
        "country": "Germany"
    },
    {
        "label": "DERSK",
        "value": "DERSK",
        "country": "Germany"
    },
    {
        "label": "DEWED",
        "value": "DEWED",
        "country": "Germany"
    },
    {
        "label": "DEWOR",
        "value": "DEWOR",
        "country": "Germany"
    },
    {
        "label": "DEWVN",
        "value": "DEWVN",
        "country": "Germany"
    },
    {
        "label": "DJJIB",
        "value": "DJJIB",
        "country": "Djibouti"
    },
    {
        "label": "DJPOD",
        "value": "DJPOD",
        "country": "Djibouti"
    },
    {
        "label": "DKAAL",
        "value": "DKAAL",
        "country": "Denmark"
    },
    {
        "label": "DKAAR",
        "value": "DKAAR",
        "country": "Denmark"
    },
    {
        "label": "DKASN",
        "value": "DKASN",
        "country": "Denmark"
    },
    {
        "label": "DKCPH",
        "value": "DKCPH",
        "country": "Denmark"
    },
    {
        "label": "DKEBJ",
        "value": "DKEBJ",
        "country": "Denmark"
    },
    {
        "label": "DKFRC",
        "value": "DKFRC",
        "country": "Denmark"
    },
    {
        "label": "DKHIR",
        "value": "DKHIR",
        "country": "Denmark"
    },
    {
        "label": "DKHLS",
        "value": "DKHLS",
        "country": "Denmark"
    },
    {
        "label": "DKHSB",
        "value": "DKHSB",
        "country": "Denmark"
    },
    {
        "label": "DKKAL",
        "value": "DKKAL",
        "country": "Denmark"
    },
    {
        "label": "DKKTD",
        "value": "DKKTD",
        "country": "Denmark"
    },
    {
        "label": "DKNBG",
        "value": "DKNBG",
        "country": "Denmark"
    },
    {
        "label": "DKODE",
        "value": "DKODE",
        "country": "Denmark"
    },
    {
        "label": "DKRUG",
        "value": "DKRUG",
        "country": "Denmark"
    },
    {
        "label": "DKSKA",
        "value": "DKSKA",
        "country": "Denmark"
    },
    {
        "label": "DKSVE",
        "value": "DKSVE",
        "country": "Denmark"
    },
    {
        "label": "DMRSU",
        "value": "DMRSU",
        "country": "Dominica"
    },
    {
        "label": "DOCAU",
        "value": "DOCAU",
        "country": "Dominican Republic"
    },
    {
        "label": "DOHAI",
        "value": "DOHAI",
        "country": "Dominican Republic"
    },
    {
        "label": "DOMAN",
        "value": "DOMAN",
        "country": "Dominican Republic"
    },
    {
        "label": "DOPOP",
        "value": "DOPOP",
        "country": "Dominican Republic"
    },
    {
        "label": "DOSDQ",
        "value": "DOSDQ",
        "country": "Dominican Republic"
    },
    {
        "label": "DOSPM",
        "value": "DOSPM",
        "country": "Dominican Republic"
    },
    {
        "label": "DZAAE",
        "value": "DZAAE",
        "country": "Algeria"
    },
    {
        "label": "DZALG",
        "value": "DZALG",
        "country": "Algeria"
    },
    {
        "label": "DZBJA",
        "value": "DZBJA",
        "country": "Algeria"
    },
    {
        "label": "DZGHA",
        "value": "DZGHA",
        "country": "Algeria"
    },
    {
        "label": "DZMOS",
        "value": "DZMOS",
        "country": "Algeria"
    },
    {
        "label": "DZORN",
        "value": "DZORN",
        "country": "Algeria"
    },
    {
        "label": "DZSKI",
        "value": "DZSKI",
        "country": "Algeria"
    },
    {
        "label": "ECBHA",
        "value": "ECBHA",
        "country": "Ecuador"
    },
    {
        "label": "ECCHA",
        "value": "ECCHA",
        "country": "Ecuador"
    },
    {
        "label": "ECESM",
        "value": "ECESM",
        "country": "Ecuador"
    },
    {
        "label": "ECGYE",
        "value": "ECGYE",
        "country": "Ecuador"
    },
    {
        "label": "ECLLD",
        "value": "ECLLD",
        "country": "Ecuador"
    },
    {
        "label": "ECMEC",
        "value": "ECMEC",
        "country": "Ecuador"
    },
    {
        "label": "ECMTV",
        "value": "ECMTV",
        "country": "Ecuador"
    },
    {
        "label": "ECPBO",
        "value": "ECPBO",
        "country": "Ecuador"
    },
    {
        "label": "EEHLD",
        "value": "EEHLD",
        "country": "Estonia"
    },
    {
        "label": "EEMUG",
        "value": "EEMUG",
        "country": "Estonia"
    },
    {
        "label": "EETLL",
        "value": "EETLL",
        "country": "Estonia"
    },
    {
        "label": "EGAIS",
        "value": "EGAIS",
        "country": "Egypt"
    },
    {
        "label": "EGALY",
        "value": "EGALY",
        "country": "Egypt"
    },
    {
        "label": "EGDAM",
        "value": "EGDAM",
        "country": "Egypt"
    },
    {
        "label": "EGEDK",
        "value": "EGEDK",
        "country": "Egypt"
    },
    {
        "label": "EGPSD",
        "value": "EGPSD",
        "country": "Egypt"
    },
    {
        "label": "EGSOK",
        "value": "EGSOK",
        "country": "Egypt"
    },
    {
        "label": "EGSUZ",
        "value": "EGSUZ",
        "country": "Egypt"
    },
    {
        "label": "ERASA",
        "value": "ERASA",
        "country": "Eritrea"
    },
    {
        "label": "ERMSW",
        "value": "ERMSW",
        "country": "Eritrea"
    },
    {
        "label": "ESACE",
        "value": "ESACE",
        "country": "Spain"
    },
    {
        "label": "ESAGP",
        "value": "ESAGP",
        "country": "Spain"
    },
    {
        "label": "ESALC",
        "value": "ESALC",
        "country": "Spain"
    },
    {
        "label": "ESALG",
        "value": "ESALG",
        "country": "Spain"
    },
    {
        "label": "ESAVS",
        "value": "ESAVS",
        "country": "Spain"
    },
    {
        "label": "ESBCN",
        "value": "ESBCN",
        "country": "Spain"
    },
    {
        "label": "ESBIO",
        "value": "ESBIO",
        "country": "Spain"
    },
    {
        "label": "ESBRM",
        "value": "ESBRM",
        "country": "Spain"
    },
    {
        "label": "ESCAD",
        "value": "ESCAD",
        "country": "Spain"
    },
    {
        "label": "ESCAR",
        "value": "ESCAR",
        "country": "Spain"
    },
    {
        "label": "ESCAS",
        "value": "ESCAS",
        "country": "Spain"
    },
    {
        "label": "ESCEU",
        "value": "ESCEU",
        "country": "Spain"
    },
    {
        "label": "ESEBO",
        "value": "ESEBO",
        "country": "Spain"
    },
    {
        "label": "ESFER",
        "value": "ESFER",
        "country": "Spain"
    },
    {
        "label": "ESFUE",
        "value": "ESFUE",
        "country": "Spain"
    },
    {
        "label": "ESGIJ",
        "value": "ESGIJ",
        "country": "Spain"
    },
    {
        "label": "ESHUV",
        "value": "ESHUV",
        "country": "Spain"
    },
    {
        "label": "ESIBZ",
        "value": "ESIBZ",
        "country": "Spain"
    },
    {
        "label": "ESLCG",
        "value": "ESLCG",
        "country": "Spain"
    },
    {
        "label": "ESLEI",
        "value": "ESLEI",
        "country": "Spain"
    },
    {
        "label": "ESLPA",
        "value": "ESLPA",
        "country": "Spain"
    },
    {
        "label": "ESMAD",
        "value": "ESMAD",
        "country": "Spain"
    },
    {
        "label": "ESMAH",
        "value": "ESMAH",
        "country": "Spain"
    },
    {
        "label": "ESMLN",
        "value": "ESMLN",
        "country": "Spain"
    },
    {
        "label": "ESMOT",
        "value": "ESMOT",
        "country": "Spain"
    },
    {
        "label": "ESMPG",
        "value": "ESMPG",
        "country": "Spain"
    },
    {
        "label": "ESPAL",
        "value": "ESPAL",
        "country": "Spain"
    },
    {
        "label": "ESPAS",
        "value": "ESPAS",
        "country": "Spain"
    },
    {
        "label": "ESPMI",
        "value": "ESPMI",
        "country": "Spain"
    },
    {
        "label": "ESSCT",
        "value": "ESSCT",
        "country": "Spain"
    },
    {
        "label": "ESSDR",
        "value": "ESSDR",
        "country": "Spain"
    },
    {
        "label": "ESSNR",
        "value": "ESSNR",
        "country": "Spain"
    },
    {
        "label": "ESSPC",
        "value": "ESSPC",
        "country": "Spain"
    },
    {
        "label": "ESSVQ",
        "value": "ESSVQ",
        "country": "Spain"
    },
    {
        "label": "ESTAR",
        "value": "ESTAR",
        "country": "Spain"
    },
    {
        "label": "ESTBZ",
        "value": "ESTBZ",
        "country": "Spain"
    },
    {
        "label": "ESTCI",
        "value": "ESTCI",
        "country": "Spain"
    },
    {
        "label": "ESTOT",
        "value": "ESTOT",
        "country": "Spain"
    },
    {
        "label": "ESVGO",
        "value": "ESVGO",
        "country": "Spain"
    },
    {
        "label": "ESVLC",
        "value": "ESVLC",
        "country": "Spain"
    },
    {
        "label": "ESZAZ",
        "value": "ESZAZ",
        "country": "Spain"
    },
    {
        "label": "FIHEL",
        "value": "FIHEL",
        "country": "Finland"
    },
    {
        "label": "FIHKO",
        "value": "FIHKO",
        "country": "Finland"
    },
    {
        "label": "FIHMN",
        "value": "FIHMN",
        "country": "Finland"
    },
    {
        "label": "FIKEM",
        "value": "FIKEM",
        "country": "Finland"
    },
    {
        "label": "FIKOK",
        "value": "FIKOK",
        "country": "Finland"
    },
    {
        "label": "FIKTK",
        "value": "FIKTK",
        "country": "Finland"
    },
    {
        "label": "FIMTL",
        "value": "FIMTL",
        "country": "Finland"
    },
    {
        "label": "FIOLU",
        "value": "FIOLU",
        "country": "Finland"
    },
    {
        "label": "FIOUL",
        "value": "FIOUL",
        "country": "Finland"
    },
    {
        "label": "FIPOR",
        "value": "FIPOR",
        "country": "Finland"
    },
    {
        "label": "FIRAU",
        "value": "FIRAU",
        "country": "Finland"
    },
    {
        "label": "FITKU",
        "value": "FITKU",
        "country": "Finland"
    },
    {
        "label": "FITOR",
        "value": "FITOR",
        "country": "Finland"
    },
    {
        "label": "FJLTK",
        "value": "FJLTK",
        "country": "Fiji"
    },
    {
        "label": "FJSUV",
        "value": "FJSUV",
        "country": "Fiji"
    },
    {
        "label": "FKPSY",
        "value": "FKPSY",
        "country": "Falkland Islands (Malvinas)"
    },
    {
        "label": "FMPNI",
        "value": "FMPNI",
        "country": "Micronesia, Federated States of"
    },
    {
        "label": "FOTHO",
        "value": "FOTHO",
        "country": "Faroe Islands"
    },
    {
        "label": "FRBAS",
        "value": "FRBAS",
        "country": "France"
    },
    {
        "label": "FRBAY",
        "value": "FRBAY",
        "country": "France"
    },
    {
        "label": "FRBES",
        "value": "FRBES",
        "country": "France"
    },
    {
        "label": "FRBOD",
        "value": "FRBOD",
        "country": "France"
    },
    {
        "label": "FRBOL",
        "value": "FRBOL",
        "country": "France"
    },
    {
        "label": "FRCQF",
        "value": "FRCQF",
        "country": "France"
    },
    {
        "label": "FRDKK",
        "value": "FRDKK",
        "country": "France"
    },
    {
        "label": "FRDPE",
        "value": "FRDPE",
        "country": "France"
    },
    {
        "label": "FRFOS",
        "value": "FRFOS",
        "country": "France"
    },
    {
        "label": "FRHGT",
        "value": "FRHGT",
        "country": "France"
    },
    {
        "label": "FRHON",
        "value": "FRHON",
        "country": "France"
    },
    {
        "label": "FRJAV",
        "value": "FRJAV",
        "country": "France"
    },
    {
        "label": "FRLCT",
        "value": "FRLCT",
        "country": "France"
    },
    {
        "label": "FRLEH",
        "value": "FRLEH",
        "country": "France"
    },
    {
        "label": "FRLPC",
        "value": "FRLPC",
        "country": "France"
    },
    {
        "label": "FRLPE",
        "value": "FRLPE",
        "country": "France"
    },
    {
        "label": "FRLRH",
        "value": "FRLRH",
        "country": "France"
    },
    {
        "label": "FRLRT",
        "value": "FRLRT",
        "country": "France"
    },
    {
        "label": "FRLTR",
        "value": "FRLTR",
        "country": "France"
    },
    {
        "label": "FRLVE",
        "value": "FRLVE",
        "country": "France"
    },
    {
        "label": "FRMDH",
        "value": "FRMDH",
        "country": "France"
    },
    {
        "label": "FRMRS",
        "value": "FRMRS",
        "country": "France"
    },
    {
        "label": "FRMT7",
        "value": "FRMT7",
        "country": "France"
    },
    {
        "label": "FRMTX",
        "value": "FRMTX",
        "country": "France"
    },
    {
        "label": "FRNTE",
        "value": "FRNTE",
        "country": "France"
    },
    {
        "label": "FROR6",
        "value": "FROR6",
        "country": "France"
    },
    {
        "label": "FRPAR",
        "value": "FRPAR",
        "country": "France"
    },
    {
        "label": "FRPIR",
        "value": "FRPIR",
        "country": "France"
    },
    {
        "label": "FRPOV",
        "value": "FRPOV",
        "country": "France"
    },
    {
        "label": "FRSET",
        "value": "FRSET",
        "country": "France"
    },
    {
        "label": "FRSNR",
        "value": "FRSNR",
        "country": "France"
    },
    {
        "label": "FRTLN",
        "value": "FRTLN",
        "country": "France"
    },
    {
        "label": "FRURO",
        "value": "FRURO",
        "country": "France"
    },
    {
        "label": "FRUSJ",
        "value": "FRUSJ",
        "country": "France"
    },
    {
        "label": "GACLZ",
        "value": "GACLZ",
        "country": "Gabon"
    },
    {
        "label": "GALBV",
        "value": "GALBV",
        "country": "Gabon"
    },
    {
        "label": "GAOWE",
        "value": "GAOWE",
        "country": "Gabon"
    },
    {
        "label": "GAPOG",
        "value": "GAPOG",
        "country": "Gabon"
    },
    {
        "label": "GBABD",
        "value": "GBABD",
        "country": "United Kingdom"
    },
    {
        "label": "GBAVO",
        "value": "GBAVO",
        "country": "United Kingdom"
    },
    {
        "label": "GBBAD",
        "value": "GBBAD",
        "country": "United Kingdom"
    },
    {
        "label": "GBBDT",
        "value": "GBBDT",
        "country": "United Kingdom"
    },
    {
        "label": "GBBEL",
        "value": "GBBEL",
        "country": "United Kingdom"
    },
    {
        "label": "GBBOC",
        "value": "GBBOC",
        "country": "United Kingdom"
    },
    {
        "label": "GBBOE",
        "value": "GBBOE",
        "country": "United Kingdom"
    },
    {
        "label": "GBBOS",
        "value": "GBBOS",
        "country": "United Kingdom"
    },
    {
        "label": "GBBRK",
        "value": "GBBRK",
        "country": "United Kingdom"
    },
    {
        "label": "GBBRS",
        "value": "GBBRS",
        "country": "United Kingdom"
    },
    {
        "label": "GBBRT",
        "value": "GBBRT",
        "country": "United Kingdom"
    },
    {
        "label": "GBBRW",
        "value": "GBBRW",
        "country": "United Kingdom"
    },
    {
        "label": "GBCDF",
        "value": "GBCDF",
        "country": "United Kingdom"
    },
    {
        "label": "GBCFG",
        "value": "GBCFG",
        "country": "United Kingdom"
    },
    {
        "label": "GBCHD",
        "value": "GBCHD",
        "country": "United Kingdom"
    },
    {
        "label": "GBCYN",
        "value": "GBCYN",
        "country": "United Kingdom"
    },
    {
        "label": "GBDUN",
        "value": "GBDUN",
        "country": "United Kingdom"
    },
    {
        "label": "GBDVR",
        "value": "GBDVR",
        "country": "United Kingdom"
    },
    {
        "label": "GBEDI",
        "value": "GBEDI",
        "country": "United Kingdom"
    },
    {
        "label": "GBEIL",
        "value": "GBEIL",
        "country": "United Kingdom"
    },
    {
        "label": "GBELL",
        "value": "GBELL",
        "country": "United Kingdom"
    },
    {
        "label": "GBEON",
        "value": "GBEON",
        "country": "United Kingdom"
    },
    {
        "label": "GBFAL",
        "value": "GBFAL",
        "country": "United Kingdom"
    },
    {
        "label": "GBFAW",
        "value": "GBFAW",
        "country": "United Kingdom"
    },
    {
        "label": "GBFRB",
        "value": "GBFRB",
        "country": "United Kingdom"
    },
    {
        "label": "GBFXT",
        "value": "GBFXT",
        "country": "United Kingdom"
    },
    {
        "label": "GBGCI",
        "value": "GBGCI",
        "country": "United Kingdom"
    },
    {
        "label": "GBGLW",
        "value": "GBGLW",
        "country": "United Kingdom"
    },
    {
        "label": "GBGOO",
        "value": "GBGOO",
        "country": "United Kingdom"
    },
    {
        "label": "GBGRG",
        "value": "GBGRG",
        "country": "United Kingdom"
    },
    {
        "label": "GBGRK",
        "value": "GBGRK",
        "country": "United Kingdom"
    },
    {
        "label": "GBGSY",
        "value": "GBGSY",
        "country": "United Kingdom"
    },
    {
        "label": "GBGTY",
        "value": "GBGTY",
        "country": "United Kingdom"
    },
    {
        "label": "GBGVS",
        "value": "GBGVS",
        "country": "United Kingdom"
    },
    {
        "label": "GBHRW",
        "value": "GBHRW",
        "country": "United Kingdom"
    },
    {
        "label": "GBHUL",
        "value": "GBHUL",
        "country": "United Kingdom"
    },
    {
        "label": "GBIMM",
        "value": "GBIMM",
        "country": "United Kingdom"
    },
    {
        "label": "GBINK",
        "value": "GBINK",
        "country": "United Kingdom"
    },
    {
        "label": "GBINV",
        "value": "GBINV",
        "country": "United Kingdom"
    },
    {
        "label": "GBIPS",
        "value": "GBIPS",
        "country": "United Kingdom"
    },
    {
        "label": "GBIRL",
        "value": "GBIRL",
        "country": "United Kingdom"
    },
    {
        "label": "GBKLN",
        "value": "GBKLN",
        "country": "United Kingdom"
    },
    {
        "label": "GBLAN",
        "value": "GBLAN",
        "country": "United Kingdom"
    },
    {
        "label": "GBLGP",
        "value": "GBLGP",
        "country": "United Kingdom"
    },
    {
        "label": "GBLIV",
        "value": "GBLIV",
        "country": "United Kingdom"
    },
    {
        "label": "GBLON",
        "value": "GBLON",
        "country": "United Kingdom"
    },
    {
        "label": "GBMAL",
        "value": "GBMAL",
        "country": "United Kingdom"
    },
    {
        "label": "GBMCD",
        "value": "GBMCD",
        "country": "United Kingdom"
    },
    {
        "label": "GBMID",
        "value": "GBMID",
        "country": "United Kingdom"
    },
    {
        "label": "GBMNS",
        "value": "GBMNS",
        "country": "United Kingdom"
    },
    {
        "label": "GBMON",
        "value": "GBMON",
        "country": "United Kingdom"
    },
    {
        "label": "GBMRY",
        "value": "GBMRY",
        "country": "United Kingdom"
    },
    {
        "label": "GBNCL",
        "value": "GBNCL",
        "country": "United Kingdom"
    },
    {
        "label": "GBNFT",
        "value": "GBNFT",
        "country": "United Kingdom"
    },
    {
        "label": "GBNHV",
        "value": "GBNHV",
        "country": "United Kingdom"
    },
    {
        "label": "GBNLN",
        "value": "GBNLN",
        "country": "United Kingdom"
    },
    {
        "label": "GBNPT",
        "value": "GBNPT",
        "country": "United Kingdom"
    },
    {
        "label": "GBPDD",
        "value": "GBPDD",
        "country": "United Kingdom"
    },
    {
        "label": "GBPFT",
        "value": "GBPFT",
        "country": "United Kingdom"
    },
    {
        "label": "GBPLY",
        "value": "GBPLY",
        "country": "United Kingdom"
    },
    {
        "label": "GBPME",
        "value": "GBPME",
        "country": "United Kingdom"
    },
    {
        "label": "GBPOO",
        "value": "GBPOO",
        "country": "United Kingdom"
    },
    {
        "label": "GBPRE",
        "value": "GBPRE",
        "country": "United Kingdom"
    },
    {
        "label": "GBPRU",
        "value": "GBPRU",
        "country": "United Kingdom"
    },
    {
        "label": "GBPST",
        "value": "GBPST",
        "country": "United Kingdom"
    },
    {
        "label": "GBQUB",
        "value": "GBQUB",
        "country": "United Kingdom"
    },
    {
        "label": "GBRYE",
        "value": "GBRYE",
        "country": "United Kingdom"
    },
    {
        "label": "GBSCA",
        "value": "GBSCA",
        "country": "United Kingdom"
    },
    {
        "label": "GBSHS",
        "value": "GBSHS",
        "country": "United Kingdom"
    },
    {
        "label": "GBSOU",
        "value": "GBSOU",
        "country": "United Kingdom"
    },
    {
        "label": "GBSOW",
        "value": "GBSOW",
        "country": "United Kingdom"
    },
    {
        "label": "GBSSH",
        "value": "GBSSH",
        "country": "United Kingdom"
    },
    {
        "label": "GBSUN",
        "value": "GBSUN",
        "country": "United Kingdom"
    },
    {
        "label": "GBSVT",
        "value": "GBSVT",
        "country": "United Kingdom"
    },
    {
        "label": "GBSWA",
        "value": "GBSWA",
        "country": "United Kingdom"
    },
    {
        "label": "GBTEE",
        "value": "GBTEE",
        "country": "United Kingdom"
    },
    {
        "label": "GBTHP",
        "value": "GBTHP",
        "country": "United Kingdom"
    },
    {
        "label": "GBTIL",
        "value": "GBTIL",
        "country": "United Kingdom"
    },
    {
        "label": "GBTYN",
        "value": "GBTYN",
        "country": "United Kingdom"
    },
    {
        "label": "GBWDN",
        "value": "GBWDN",
        "country": "United Kingdom"
    },
    {
        "label": "GBWOR",
        "value": "GBWOR",
        "country": "United Kingdom"
    },
    {
        "label": "GBYVZ",
        "value": "GBYVZ",
        "country": "United Kingdom"
    },
    {
        "label": "GDSTG",
        "value": "GDSTG",
        "country": "Grenada"
    },
    {
        "label": "GEBUS",
        "value": "GEBUS",
        "country": "Georgia"
    },
    {
        "label": "GEPTI",
        "value": "GEPTI",
        "country": "Georgia"
    },
    {
        "label": "GETBS",
        "value": "GETBS",
        "country": "Georgia"
    },
    {
        "label": "GFCAY",
        "value": "GFCAY",
        "country": "French Guiana"
    },
    {
        "label": "GFDDC",
        "value": "GFDDC",
        "country": "French Guiana"
    },
    {
        "label": "GHTEM",
        "value": "GHTEM",
        "country": "Ghana"
    },
    {
        "label": "GHTKD",
        "value": "GHTKD",
        "country": "Ghana"
    },
    {
        "label": "GIGIB",
        "value": "GIGIB",
        "country": "Gibraltar"
    },
    {
        "label": "GMBJL",
        "value": "GMBJL",
        "country": "Gambia"
    },
    {
        "label": "GNCKY",
        "value": "GNCKY",
        "country": "Guinea"
    },
    {
        "label": "GPBBR",
        "value": "GPBBR",
        "country": "Guadeloupe"
    },
    {
        "label": "GPGUS",
        "value": "GPGUS",
        "country": "Guadeloupe"
    },
    {
        "label": "GPPTP",
        "value": "GPPTP",
        "country": "Guadeloupe"
    },
    {
        "label": "GPSBH",
        "value": "GPSBH",
        "country": "Guadeloupe"
    },
    {
        "label": "GQBSG",
        "value": "GQBSG",
        "country": "Equatorial Guinea"
    },
    {
        "label": "GQSSG",
        "value": "GQSSG",
        "country": "Equatorial Guinea"
    },
    {
        "label": "GRAST",
        "value": "GRAST",
        "country": "Greece"
    },
    {
        "label": "GREEU",
        "value": "GREEU",
        "country": "Greece"
    },
    {
        "label": "GRHER",
        "value": "GRHER",
        "country": "Greece"
    },
    {
        "label": "GRKAP",
        "value": "GRKAP",
        "country": "Greece"
    },
    {
        "label": "GRPIR",
        "value": "GRPIR",
        "country": "Greece"
    },
    {
        "label": "GRRHO",
        "value": "GRRHO",
        "country": "Greece"
    },
    {
        "label": "GRSKG",
        "value": "GRSKG",
        "country": "Greece"
    },
    {
        "label": "GRVOL",
        "value": "GRVOL",
        "country": "Greece"
    },
    {
        "label": "GTCHR",
        "value": "GTCHR",
        "country": "Guatemala"
    },
    {
        "label": "GTGUA",
        "value": "GTGUA",
        "country": "Guatemala"
    },
    {
        "label": "GTPBR",
        "value": "GTPBR",
        "country": "Guatemala"
    },
    {
        "label": "GTPRQ",
        "value": "GTPRQ",
        "country": "Guatemala"
    },
    {
        "label": "GTSTC",
        "value": "GTSTC",
        "country": "Guatemala"
    },
    {
        "label": "GUGUM",
        "value": "GUGUM",
        "country": "Guam"
    },
    {
        "label": "GWOXB",
        "value": "GWOXB",
        "country": "Guinea-Bissau"
    },
    {
        "label": "GYGEO",
        "value": "GYGEO",
        "country": "Guyana"
    },
    {
        "label": "HKHKG",
        "value": "HKHKG",
        "country": "Hong Kong"
    },
    {
        "label": "HNAMP",
        "value": "HNAMP",
        "country": "Honduras"
    },
    {
        "label": "HNLCE",
        "value": "HNLCE",
        "country": "Honduras"
    },
    {
        "label": "HNPCA",
        "value": "HNPCA",
        "country": "Honduras"
    },
    {
        "label": "HNPCR",
        "value": "HNPCR",
        "country": "Honduras"
    },
    {
        "label": "HNRTB",
        "value": "HNRTB",
        "country": "Honduras"
    },
    {
        "label": "HNSLO",
        "value": "HNSLO",
        "country": "Honduras"
    },
    {
        "label": "HNTEA",
        "value": "HNTEA",
        "country": "Honduras"
    },
    {
        "label": "HNTGU",
        "value": "HNTGU",
        "country": "Honduras"
    },
    {
        "label": "HRDBV",
        "value": "HRDBV",
        "country": "Croatia"
    },
    {
        "label": "HRLST",
        "value": "HRLST",
        "country": "Croatia"
    },
    {
        "label": "HRPLE",
        "value": "HRPLE",
        "country": "Croatia"
    },
    {
        "label": "HRRJK",
        "value": "HRRJK",
        "country": "Croatia"
    },
    {
        "label": "HRSPU",
        "value": "HRSPU",
        "country": "Croatia"
    },
    {
        "label": "HTCAP",
        "value": "HTCAP",
        "country": "Haiti"
    },
    {
        "label": "HTPAP",
        "value": "HTPAP",
        "country": "Haiti"
    },
    {
        "label": "HUBUD",
        "value": "HUBUD",
        "country": "Hungary"
    },
    {
        "label": "IDAMA",
        "value": "IDAMA",
        "country": "Indonesia"
    },
    {
        "label": "IDBDJ",
        "value": "IDBDJ",
        "country": "Indonesia"
    },
    {
        "label": "IDBIT",
        "value": "IDBIT",
        "country": "Indonesia"
    },
    {
        "label": "IDBLW",
        "value": "IDBLW",
        "country": "Indonesia"
    },
    {
        "label": "IDBNT",
        "value": "IDBNT",
        "country": "Indonesia"
    },
    {
        "label": "IDBPN",
        "value": "IDBPN",
        "country": "Indonesia"
    },
    {
        "label": "IDBTM",
        "value": "IDBTM",
        "country": "Indonesia"
    },
    {
        "label": "IDBUN",
        "value": "IDBUN",
        "country": "Indonesia"
    },
    {
        "label": "IDBUR",
        "value": "IDBUR",
        "country": "Indonesia"
    },
    {
        "label": "IDCIG",
        "value": "IDCIG",
        "country": "Indonesia"
    },
    {
        "label": "IDDJB",
        "value": "IDDJB",
        "country": "Indonesia"
    },
    {
        "label": "IDDJJ",
        "value": "IDDJJ",
        "country": "Indonesia"
    },
    {
        "label": "IDJKT",
        "value": "IDJKT",
        "country": "Indonesia"
    },
    {
        "label": "IDKAB",
        "value": "IDKAB",
        "country": "Indonesia"
    },
    {
        "label": "IDLBM",
        "value": "IDLBM",
        "country": "Indonesia"
    },
    {
        "label": "IDMAK",
        "value": "IDMAK",
        "country": "Indonesia"
    },
    {
        "label": "IDMES",
        "value": "IDMES",
        "country": "Indonesia"
    },
    {
        "label": "IDMRK",
        "value": "IDMRK",
        "country": "Indonesia"
    },
    {
        "label": "IDPDG",
        "value": "IDPDG",
        "country": "Indonesia"
    },
    {
        "label": "IDPJG",
        "value": "IDPJG",
        "country": "Indonesia"
    },
    {
        "label": "IDPKU",
        "value": "IDPKU",
        "country": "Indonesia"
    },
    {
        "label": "IDPLM",
        "value": "IDPLM",
        "country": "Indonesia"
    },
    {
        "label": "IDPNJ",
        "value": "IDPNJ",
        "country": "Indonesia"
    },
    {
        "label": "IDPNK",
        "value": "IDPNK",
        "country": "Indonesia"
    },
    {
        "label": "IDPWG",
        "value": "IDPWG",
        "country": "Indonesia"
    },
    {
        "label": "IDSEQ",
        "value": "IDSEQ",
        "country": "Indonesia"
    },
    {
        "label": "IDSRG",
        "value": "IDSRG",
        "country": "Indonesia"
    },
    {
        "label": "IDSRI",
        "value": "IDSRI",
        "country": "Indonesia"
    },
    {
        "label": "IDSUB",
        "value": "IDSUB",
        "country": "Indonesia"
    },
    {
        "label": "IDTAB",
        "value": "IDTAB",
        "country": "Indonesia"
    },
    {
        "label": "IDTBG",
        "value": "IDTBG",
        "country": "Indonesia"
    },
    {
        "label": "IDTPP",
        "value": "IDTPP",
        "country": "Indonesia"
    },
    {
        "label": "IDTTI",
        "value": "IDTTI",
        "country": "Indonesia"
    },
    {
        "label": "IDUPG",
        "value": "IDUPG",
        "country": "Indonesia"
    },
    {
        "label": "IEDRO",
        "value": "IEDRO",
        "country": "Ireland"
    },
    {
        "label": "IEDUB",
        "value": "IEDUB",
        "country": "Ireland"
    },
    {
        "label": "IEFOV",
        "value": "IEFOV",
        "country": "Ireland"
    },
    {
        "label": "IEGWY",
        "value": "IEGWY",
        "country": "Ireland"
    },
    {
        "label": "IEKLN",
        "value": "IEKLN",
        "country": "Ireland"
    },
    {
        "label": "IEORK",
        "value": "IEORK",
        "country": "Ireland"
    },
    {
        "label": "IEWAT",
        "value": "IEWAT",
        "country": "Ireland"
    },
    {
        "label": "ILASH",
        "value": "ILASH",
        "country": "Israel"
    },
    {
        "label": "ILHAD",
        "value": "ILHAD",
        "country": "Israel"
    },
    {
        "label": "ILHFA",
        "value": "ILHFA",
        "country": "Israel"
    },
    {
        "label": "ILNAT",
        "value": "ILNAT",
        "country": "Israel"
    },
    {
        "label": "ILRMG",
        "value": "ILRMG",
        "country": "Israel"
    },
    {
        "label": "ILTLV",
        "value": "ILTLV",
        "country": "Israel"
    },
    {
        "label": "INAGR",
        "value": "INAGR",
        "country": "India"
    },
    {
        "label": "INKAK",
        "value": "INKAK",
        "country": "India"
    },
    {
        "label": "INAMD",
        "value": "INAMD",
        "country": "India"
    },
    {
        "label": "INBLR",
        "value": "INBLR",
        "country": "India"
    },
    {
        "label": "INBOM",
        "value": "INBOM",
        "country": "India"
    },
    {
        "label": "INCCU",
        "value": "INCCU",
        "country": "India"
    },
    {
        "label": "INCDL",
        "value": "INCDL",
        "country": "India"
    },
    {
        "label": "INCJB",
        "value": "INCJB",
        "country": "India"
    },
    {
        "label": "INCOK",
        "value": "INCOK",
        "country": "India"
    },
    {
        "label": "INDAM",
        "value": "INDAM",
        "country": "India"
    },
    {
        "label": "INDRI",
        "value": "INDRI",
        "country": "India"
    },
    {
        "label": "INGOI",
        "value": "INGOI",
        "country": "India"
    },
    {
        "label": "INHAL",
        "value": "INHAL",
        "country": "India"
    },
    {
        "label": "INHYD",
        "value": "INHYD",
        "country": "India"
    },
    {
        "label": "INICD",
        "value": "INICD",
        "country": "India"
    },
    {
        "label": "INIXE",
        "value": "INIXE",
        "country": "India"
    },
    {
        "label": "INIXY",
        "value": "INIXY",
        "country": "India"
    },
    {
        "label": "INJAI",
        "value": "INJAI",
        "country": "India"
    },
    {
        "label": "INJDH",
        "value": "INJDH",
        "country": "India"
    },
    {
        "label": "INKNU",
        "value": "INKNU",
        "country": "India"
    },
    {
        "label": "INKRI",
        "value": "INKRI",
        "country": "India"
    },
    {
        "label": "INLUH",
        "value": "INLUH",
        "country": "India"
    },
    {
        "label": "INMAA",
        "value": "INMAA",
        "country": "India"
    },
    {
        "label": "INMOR",
        "value": "INMOR",
        "country": "India"
    },
    {
        "label": "INMRM",
        "value": "INMRM",
        "country": "India"
    },
    {
        "label": "INMUN",
        "value": "INMUN",
        "country": "India"
    },
    {
        "label": "INNAG",
        "value": "INNAG",
        "country": "India"
    },
    {
        "label": "INNSA",
        "value": "INNSA",
        "country": "India"
    },
    {
        "label": "INPAP",
        "value": "INPAP",
        "country": "India"
    },
    {
        "label": "INPAV",
        "value": "INPAV",
        "country": "India"
    },
    {
        "label": "INPIR",
        "value": "INPIR",
        "country": "India"
    },
    {
        "label": "INPNQ",
        "value": "INPNQ",
        "country": "India"
    },
    {
        "label": "INSTV",
        "value": "INSTV",
        "country": "India"
    },
    {
        "label": "INTUT",
        "value": "INTUT",
        "country": "India"
    },
    {
        "label": "INVTZ",
        "value": "INVTZ",
        "country": "India"
    },
    {
        "label": "INVVA",
        "value": "INVVA",
        "country": "India"
    },
    {
        "label": "IQUQR",
        "value": "IQUQR",
        "country": "Iraq"
    },
    {
        "label": "IRBKM",
        "value": "IRBKM",
        "country": "Iran, Islamic Republic of"
    },
    {
        "label": "IRBND",
        "value": "IRBND",
        "country": "Iran, Islamic Republic of"
    },
    {
        "label": "IRBUZ",
        "value": "IRBUZ",
        "country": "Iran, Islamic Republic of"
    },
    {
        "label": "IRKHO",
        "value": "IRKHO",
        "country": "Iran, Islamic Republic of"
    },
    {
        "label": "IRZBR",
        "value": "IRZBR",
        "country": "Iran, Islamic Republic of"
    },
    {
        "label": "ISESK",
        "value": "ISESK",
        "country": "Iceland"
    },
    {
        "label": "ISGRT",
        "value": "ISGRT",
        "country": "Iceland"
    },
    {
        "label": "ISREY",
        "value": "ISREY",
        "country": "Iceland"
    },
    {
        "label": "ITAOI",
        "value": "ITAOI",
        "country": "Italy"
    },
    {
        "label": "ITAUG",
        "value": "ITAUG",
        "country": "Italy"
    },
    {
        "label": "ITBRI",
        "value": "ITBRI",
        "country": "Italy"
    },
    {
        "label": "ITCAG",
        "value": "ITCAG",
        "country": "Italy"
    },
    {
        "label": "ITCRV",
        "value": "ITCRV",
        "country": "Italy"
    },
    {
        "label": "ITCTA",
        "value": "ITCTA",
        "country": "Italy"
    },
    {
        "label": "ITCVV",
        "value": "ITCVV",
        "country": "Italy"
    },
    {
        "label": "ITFAE",
        "value": "ITFAE",
        "country": "Italy"
    },
    {
        "label": "ITGAE",
        "value": "ITGAE",
        "country": "Italy"
    },
    {
        "label": "ITGIT",
        "value": "ITGIT",
        "country": "Italy"
    },
    {
        "label": "ITGOA",
        "value": "ITGOA",
        "country": "Italy"
    },
    {
        "label": "ITLIV",
        "value": "ITLIV",
        "country": "Italy"
    },
    {
        "label": "ITMIL",
        "value": "ITMIL",
        "country": "Italy"
    },
    {
        "label": "ITMNF",
        "value": "ITMNF",
        "country": "Italy"
    },
    {
        "label": "ITMOD",
        "value": "ITMOD",
        "country": "Italy"
    },
    {
        "label": "ITNAP",
        "value": "ITNAP",
        "country": "Italy"
    },
    {
        "label": "ITPDA",
        "value": "ITPDA",
        "country": "Italy"
    },
    {
        "label": "ITPES",
        "value": "ITPES",
        "country": "Italy"
    },
    {
        "label": "ITPGA",
        "value": "ITPGA",
        "country": "Italy"
    },
    {
        "label": "ITPMO",
        "value": "ITPMO",
        "country": "Italy"
    },
    {
        "label": "ITPRJ",
        "value": "ITPRJ",
        "country": "Italy"
    },
    {
        "label": "ITPSR",
        "value": "ITPSR",
        "country": "Italy"
    },
    {
        "label": "ITPVE",
        "value": "ITPVE",
        "country": "Italy"
    },
    {
        "label": "ITRAN",
        "value": "ITRAN",
        "country": "Italy"
    },
    {
        "label": "ITSAL",
        "value": "ITSAL",
        "country": "Italy"
    },
    {
        "label": "ITSPE",
        "value": "ITSPE",
        "country": "Italy"
    },
    {
        "label": "ITSVN",
        "value": "ITSVN",
        "country": "Italy"
    },
    {
        "label": "ITTAR",
        "value": "ITTAR",
        "country": "Italy"
    },
    {
        "label": "ITTPS",
        "value": "ITTPS",
        "country": "Italy"
    },
    {
        "label": "ITTRN",
        "value": "ITTRN",
        "country": "Italy"
    },
    {
        "label": "ITTRS",
        "value": "ITTRS",
        "country": "Italy"
    },
    {
        "label": "ITVCE",
        "value": "ITVCE",
        "country": "Italy"
    },
    {
        "label": "ITVDL",
        "value": "ITVDL",
        "country": "Italy"
    },
    {
        "label": "ITVIA",
        "value": "ITVIA",
        "country": "Italy"
    },
    {
        "label": "JMKIN",
        "value": "JMKIN",
        "country": "Jamaica"
    },
    {
        "label": "JMMBJ",
        "value": "JMMBJ",
        "country": "Jamaica"
    },
    {
        "label": "JMOCJ",
        "value": "JMOCJ",
        "country": "Jamaica"
    },
    {
        "label": "JMPKS",
        "value": "JMPKS",
        "country": "Jamaica"
    },
    {
        "label": "JOAQJ",
        "value": "JOAQJ",
        "country": "Jordan"
    },
    {
        "label": "JPABU",
        "value": "JPABU",
        "country": "Japan"
    },
    {
        "label": "JPAOJ",
        "value": "JPAOJ",
        "country": "Japan"
    },
    {
        "label": "JPAXT",
        "value": "JPAXT",
        "country": "Japan"
    },
    {
        "label": "JPCHB",
        "value": "JPCHB",
        "country": "Japan"
    },
    {
        "label": "JPFKY",
        "value": "JPFKY",
        "country": "Japan"
    },
    {
        "label": "JPHHE",
        "value": "JPHHE",
        "country": "Japan"
    },
    {
        "label": "JPHIB",
        "value": "JPHIB",
        "country": "Japan"
    },
    {
        "label": "JPHIC",
        "value": "JPHIC",
        "country": "Japan"
    },
    {
        "label": "JPHIJ",
        "value": "JPHIJ",
        "country": "Japan"
    },
    {
        "label": "JPHIM",
        "value": "JPHIM",
        "country": "Japan"
    },
    {
        "label": "JPHIS",
        "value": "JPHIS",
        "country": "Japan"
    },
    {
        "label": "JPHKD",
        "value": "JPHKD",
        "country": "Japan"
    },
    {
        "label": "JPHKT",
        "value": "JPHKT",
        "country": "Japan"
    },
    {
        "label": "JPHMD",
        "value": "JPHMD",
        "country": "Japan"
    },
    {
        "label": "JPHMM",
        "value": "JPHMM",
        "country": "Japan"
    },
    {
        "label": "JPHSM",
        "value": "JPHSM",
        "country": "Japan"
    },
    {
        "label": "JPHTC",
        "value": "JPHTC",
        "country": "Japan"
    },
    {
        "label": "JPIHA",
        "value": "JPIHA",
        "country": "Japan"
    },
    {
        "label": "JPIMB",
        "value": "JPIMB",
        "country": "Japan"
    },
    {
        "label": "JPIMI",
        "value": "JPIMI",
        "country": "Japan"
    },
    {
        "label": "JPISG",
        "value": "JPISG",
        "country": "Japan"
    },
    {
        "label": "JPISI",
        "value": "JPISI",
        "country": "Japan"
    },
    {
        "label": "JPISM",
        "value": "JPISM",
        "country": "Japan"
    },
    {
        "label": "JPISS",
        "value": "JPISS",
        "country": "Japan"
    },
    {
        "label": "JPIWK",
        "value": "JPIWK",
        "country": "Japan"
    },
    {
        "label": "JPIYM",
        "value": "JPIYM",
        "country": "Japan"
    },
    {
        "label": "JPKCZ",
        "value": "JPKCZ",
        "country": "Japan"
    },
    {
        "label": "JPKGA",
        "value": "JPKGA",
        "country": "Japan"
    },
    {
        "label": "JPKIJ",
        "value": "JPKIJ",
        "country": "Japan"
    },
    {
        "label": "JPKMJ",
        "value": "JPKMJ",
        "country": "Japan"
    },
    {
        "label": "JPKND",
        "value": "JPKND",
        "country": "Japan"
    },
    {
        "label": "JPKNU",
        "value": "JPKNU",
        "country": "Japan"
    },
    {
        "label": "JPKNZ",
        "value": "JPKNZ",
        "country": "Japan"
    },
    {
        "label": "JPKOJ",
        "value": "JPKOJ",
        "country": "Japan"
    },
    {
        "label": "JPKRE",
        "value": "JPKRE",
        "country": "Japan"
    },
    {
        "label": "JPKSM",
        "value": "JPKSM",
        "country": "Japan"
    },
    {
        "label": "JPKUH",
        "value": "JPKUH",
        "country": "Japan"
    },
    {
        "label": "JPKWS",
        "value": "JPKWS",
        "country": "Japan"
    },
    {
        "label": "JPKZU",
        "value": "JPKZU",
        "country": "Japan"
    },
    {
        "label": "JPMAI",
        "value": "JPMAI",
        "country": "Japan"
    },
    {
        "label": "JPMII",
        "value": "JPMII",
        "country": "Japan"
    },
    {
        "label": "JPMOJ",
        "value": "JPMOJ",
        "country": "Japan"
    },
    {
        "label": "JPMTR",
        "value": "JPMTR",
        "country": "Japan"
    },
    {
        "label": "JPMUR",
        "value": "JPMUR",
        "country": "Japan"
    },
    {
        "label": "JPMYJ",
        "value": "JPMYJ",
        "country": "Japan"
    },
    {
        "label": "JPNAH",
        "value": "JPNAH",
        "country": "Japan"
    },
    {
        "label": "JPNAN",
        "value": "JPNAN",
        "country": "Japan"
    },
    {
        "label": "JPNAO",
        "value": "JPNAO",
        "country": "Japan"
    },
    {
        "label": "JPNAS",
        "value": "JPNAS",
        "country": "Japan"
    },
    {
        "label": "JPNGO",
        "value": "JPNGO",
        "country": "Japan"
    },
    {
        "label": "JPNGS",
        "value": "JPNGS",
        "country": "Japan"
    },
    {
        "label": "JPOIT",
        "value": "JPOIT",
        "country": "Japan"
    },
    {
        "label": "JPOKA",
        "value": "JPOKA",
        "country": "Japan"
    },
    {
        "label": "JPOMZ",
        "value": "JPOMZ",
        "country": "Japan"
    },
    {
        "label": "JPONA",
        "value": "JPONA",
        "country": "Japan"
    },
    {
        "label": "JPONO",
        "value": "JPONO",
        "country": "Japan"
    },
    {
        "label": "JPOSA",
        "value": "JPOSA",
        "country": "Japan"
    },
    {
        "label": "JPOTK",
        "value": "JPOTK",
        "country": "Japan"
    },
    {
        "label": "JPSAG",
        "value": "JPSAG",
        "country": "Japan"
    },
    {
        "label": "JPSBS",
        "value": "JPSBS",
        "country": "Japan"
    },
    {
        "label": "JPSDJ",
        "value": "JPSDJ",
        "country": "Japan"
    },
    {
        "label": "JPSDS",
        "value": "JPSDS",
        "country": "Japan"
    },
    {
        "label": "JPSDU",
        "value": "JPSDU",
        "country": "Japan"
    },
    {
        "label": "JPSEN",
        "value": "JPSEN",
        "country": "Japan"
    },
    {
        "label": "JPSHS",
        "value": "JPSHS",
        "country": "Japan"
    },
    {
        "label": "JPSKD",
        "value": "JPSKD",
        "country": "Japan"
    },
    {
        "label": "JPSKM",
        "value": "JPSKM",
        "country": "Japan"
    },
    {
        "label": "JPSKT",
        "value": "JPSKT",
        "country": "Japan"
    },
    {
        "label": "JPSMA",
        "value": "JPSMA",
        "country": "Japan"
    },
    {
        "label": "JPSMN",
        "value": "JPSMN",
        "country": "Japan"
    },
    {
        "label": "JPSMZ",
        "value": "JPSMZ",
        "country": "Japan"
    },
    {
        "label": "JPTAK",
        "value": "JPTAK",
        "country": "Japan"
    },
    {
        "label": "JPTBT",
        "value": "JPTBT",
        "country": "Japan"
    },
    {
        "label": "JPTGO",
        "value": "JPTGO",
        "country": "Japan"
    },
    {
        "label": "JPTHS",
        "value": "JPTHS",
        "country": "Japan"
    },
    {
        "label": "JPTKS",
        "value": "JPTKS",
        "country": "Japan"
    },
    {
        "label": "JPTKY",
        "value": "JPTKY",
        "country": "Japan"
    },
    {
        "label": "JPTMI",
        "value": "JPTMI",
        "country": "Japan"
    },
    {
        "label": "JPTMK",
        "value": "JPTMK",
        "country": "Japan"
    },
    {
        "label": "JPTND",
        "value": "JPTND",
        "country": "Japan"
    },
    {
        "label": "JPTOK",
        "value": "JPTOK",
        "country": "Japan"
    },
    {
        "label": "JPTOS",
        "value": "JPTOS",
        "country": "Japan"
    },
    {
        "label": "JPTOY",
        "value": "JPTOY",
        "country": "Japan"
    },
    {
        "label": "JPTRG",
        "value": "JPTRG",
        "country": "Japan"
    },
    {
        "label": "JPTYO",
        "value": "JPTYO",
        "country": "Japan"
    },
    {
        "label": "JPUBJ",
        "value": "JPUBJ",
        "country": "Japan"
    },
    {
        "label": "JPUKB",
        "value": "JPUKB",
        "country": "Japan"
    },
    {
        "label": "JPUNT",
        "value": "JPUNT",
        "country": "Japan"
    },
    {
        "label": "JPWAK",
        "value": "JPWAK",
        "country": "Japan"
    },
    {
        "label": "JPYAT",
        "value": "JPYAT",
        "country": "Japan"
    },
    {
        "label": "JPYKK",
        "value": "JPYKK",
        "country": "Japan"
    },
    {
        "label": "JPYOK",
        "value": "JPYOK",
        "country": "Japan"
    },
    {
        "label": "JPYOS",
        "value": "JPYOS",
        "country": "Japan"
    },
    {
        "label": "JPYWT",
        "value": "JPYWT",
        "country": "Japan"
    },
    {
        "label": "KEMBA",
        "value": "KEMBA",
        "country": "Kenya"
    },
    {
        "label": "KENBO",
        "value": "KENBO",
        "country": "Kenya"
    },
    {
        "label": "KHKOS",
        "value": "KHKOS",
        "country": "Cambodia"
    },
    {
        "label": "KHPNH",
        "value": "KHPNH",
        "country": "Cambodia"
    },
    {
        "label": "KITRW",
        "value": "KITRW",
        "country": "Kiribati"
    },
    {
        "label": "KMMUT",
        "value": "KMMUT",
        "country": "Comoros"
    },
    {
        "label": "KMYVA",
        "value": "KMYVA",
        "country": "Comoros"
    },
    {
        "label": "KNBAS",
        "value": "KNBAS",
        "country": "Saint Kitts and Nevis"
    },
    {
        "label": "KNNEV",
        "value": "KNNEV",
        "country": "Saint Kitts and Nevis"
    },
    {
        "label": "KPCHO",
        "value": "KPCHO",
        "country": "Korea, Democratic People's Republic of"
    },
    {
        "label": "KPWON",
        "value": "KPWON",
        "country": "Korea, Democratic People's Republic of"
    },
    {
        "label": "KRCHA",
        "value": "KRCHA",
        "country": "South Korea"
    },
    {
        "label": "KRCHF",
        "value": "KRCHF",
        "country": "South Korea"
    },
    {
        "label": "KRCHG",
        "value": "KRCHG",
        "country": "South Korea"
    },
    {
        "label": "KRCHO",
        "value": "KRCHO",
        "country": "South Korea"
    },
    {
        "label": "KRCHU",
        "value": "KRCHU",
        "country": "South Korea"
    },
    {
        "label": "KRCHW",
        "value": "KRCHW",
        "country": "South Korea"
    },
    {
        "label": "KRCWO",
        "value": "KRCWO",
        "country": "South Korea"
    },
    {
        "label": "KRGYG",
        "value": "KRGYG",
        "country": "South Korea"
    },
    {
        "label": "KRHIN",
        "value": "KRHIN",
        "country": "South Korea"
    },
    {
        "label": "KRHYG",
        "value": "KRHYG",
        "country": "South Korea"
    },
    {
        "label": "KRHYP",
        "value": "KRHYP",
        "country": "South Korea"
    },
    {
        "label": "KRICH",
        "value": "KRICH",
        "country": "South Korea"
    },
    {
        "label": "KRINC",
        "value": "KRINC",
        "country": "South Korea"
    },
    {
        "label": "KRJEO",
        "value": "KRJEO",
        "country": "South Korea"
    },
    {
        "label": "KRJGE",
        "value": "KRJGE",
        "country": "South Korea"
    },
    {
        "label": "KRJHG",
        "value": "KRJHG",
        "country": "South Korea"
    },
    {
        "label": "KRKAN",
        "value": "KRKAN",
        "country": "South Korea"
    },
    {
        "label": "KRKJE",
        "value": "KRKJE",
        "country": "South Korea"
    },
    {
        "label": "KRKMC",
        "value": "KRKMC",
        "country": "South Korea"
    },
    {
        "label": "KRKPO",
        "value": "KRKPO",
        "country": "South Korea"
    },
    {
        "label": "KRKUM",
        "value": "KRKUM",
        "country": "South Korea"
    },
    {
        "label": "KRKUV",
        "value": "KRKUV",
        "country": "South Korea"
    },
    {
        "label": "KRKWJ",
        "value": "KRKWJ",
        "country": "South Korea"
    },
    {
        "label": "KRKWU",
        "value": "KRKWU",
        "country": "South Korea"
    },
    {
        "label": "KRMAS",
        "value": "KRMAS",
        "country": "South Korea"
    },
    {
        "label": "KRMOK",
        "value": "KRMOK",
        "country": "South Korea"
    },
    {
        "label": "KRNYJ",
        "value": "KRNYJ",
        "country": "South Korea"
    },
    {
        "label": "KRNYU",
        "value": "KRNYU",
        "country": "South Korea"
    },
    {
        "label": "KROKP",
        "value": "KROKP",
        "country": "South Korea"
    },
    {
        "label": "KRONS",
        "value": "KRONS",
        "country": "South Korea"
    },
    {
        "label": "KRPUS",
        "value": "KRPUS",
        "country": "South Korea"
    },
    {
        "label": "KRSCP",
        "value": "KRSCP",
        "country": "South Korea"
    },
    {
        "label": "KRSEL",
        "value": "KRSEL",
        "country": "South Korea"
    },
    {
        "label": "KRSSA",
        "value": "KRSSA",
        "country": "South Korea"
    },
    {
        "label": "KRTAE",
        "value": "KRTAE",
        "country": "South Korea"
    },
    {
        "label": "KRTJN",
        "value": "KRTJN",
        "country": "South Korea"
    },
    {
        "label": "KRTYG",
        "value": "KRTYG",
        "country": "South Korea"
    },
    {
        "label": "KRUSN",
        "value": "KRUSN",
        "country": "South Korea"
    },
    {
        "label": "KRYES",
        "value": "KRYES",
        "country": "South Korea"
    },
    {
        "label": "KRYOS",
        "value": "KRYOS",
        "country": "South Korea"
    },
    {
        "label": "KWKWI",
        "value": "KWKWI",
        "country": "Kuwait"
    },
    {
        "label": "KWSAA",
        "value": "KWSAA",
        "country": "Kuwait"
    },
    {
        "label": "KWSWK",
        "value": "KWSWK",
        "country": "Kuwait"
    },
    {
        "label": "KYGCM",
        "value": "KYGCM",
        "country": "Cayman Islands"
    },
    {
        "label": "KYGEC",
        "value": "KYGEC",
        "country": "Cayman Islands"
    },
    {
        "label": "LBBEY",
        "value": "LBBEY",
        "country": "Lebanon"
    },
    {
        "label": "LBKYE",
        "value": "LBKYE",
        "country": "Lebanon"
    },
    {
        "label": "LCCAS",
        "value": "LCCAS",
        "country": "Saint Lucia"
    },
    {
        "label": "LCSLU",
        "value": "LCSLU",
        "country": "Saint Lucia"
    },
    {
        "label": "LCVIF",
        "value": "LCVIF",
        "country": "Saint Lucia"
    },
    {
        "label": "LKCMB",
        "value": "LKCMB",
        "country": "Sri Lanka"
    },
    {
        "label": "LRMLW",
        "value": "LRMLW",
        "country": "Liberia"
    },
    {
        "label": "LTKLJ",
        "value": "LTKLJ",
        "country": "Lithuania"
    },
    {
        "label": "LVAIZ",
        "value": "LVAIZ",
        "country": "Latvia"
    },
    {
        "label": "LVRIX",
        "value": "LVRIX",
        "country": "Latvia"
    },
    {
        "label": "LVSAL",
        "value": "LVSAL",
        "country": "Latvia"
    },
    {
        "label": "LVVNT",
        "value": "LVVNT",
        "country": "Latvia"
    },
    {
        "label": "LYBEN",
        "value": "LYBEN",
        "country": "Libya"
    },
    {
        "label": "LYKHO",
        "value": "LYKHO",
        "country": "Libya"
    },
    {
        "label": "LYMRA",
        "value": "LYMRA",
        "country": "Libya"
    },
    {
        "label": "LYTIP",
        "value": "LYTIP",
        "country": "Libya"
    },
    {
        "label": "MAAGA",
        "value": "MAAGA",
        "country": "Morocco"
    },
    {
        "label": "MACAS",
        "value": "MACAS",
        "country": "Morocco"
    },
    {
        "label": "MAELJ",
        "value": "MAELJ",
        "country": "Morocco"
    },
    {
        "label": "MAMOH",
        "value": "MAMOH",
        "country": "Morocco"
    },
    {
        "label": "MANNA",
        "value": "MANNA",
        "country": "Morocco"
    },
    {
        "label": "MAPTM",
        "value": "MAPTM",
        "country": "Morocco"
    },
    {
        "label": "MASFI",
        "value": "MASFI",
        "country": "Morocco"
    },
    {
        "label": "MATNG",
        "value": "MATNG",
        "country": "Morocco"
    },
    {
        "label": "MCMON",
        "value": "MCMON",
        "country": "Monaco"
    },
    {
        "label": "MDGIU",
        "value": "MDGIU",
        "country": "Moldova, Republic of"
    },
    {
        "label": "MEBAR",
        "value": "MEBAR",
        "country": "Montenegro"
    },
    {
        "label": "MGDIE",
        "value": "MGDIE",
        "country": "Madagascar"
    },
    {
        "label": "MGEHL",
        "value": "MGEHL",
        "country": "Madagascar"
    },
    {
        "label": "MGMJN",
        "value": "MGMJN",
        "country": "Madagascar"
    },
    {
        "label": "MGNOS",
        "value": "MGNOS",
        "country": "Madagascar"
    },
    {
        "label": "MGTLE",
        "value": "MGTLE",
        "country": "Madagascar"
    },
    {
        "label": "MGTMM",
        "value": "MGTMM",
        "country": "Madagascar"
    },
    {
        "label": "MGTOA",
        "value": "MGTOA",
        "country": "Madagascar"
    },
    {
        "label": "MHMAJ",
        "value": "MHMAJ",
        "country": "Marshall Islands"
    },
    {
        "label": "MLBKO",
        "value": "MLBKO",
        "country": "Mali"
    },
    {
        "label": "MMRGN",
        "value": "MMRGN",
        "country": "Myanmar"
    },
    {
        "label": "MOMFM",
        "value": "MOMFM",
        "country": "Macao"
    },
    {
        "label": "MPSPN",
        "value": "MPSPN",
        "country": "Northern Mariana Islands"
    },
    {
        "label": "MQFDF",
        "value": "MQFDF",
        "country": "Martinique"
    },
    {
        "label": "MRNDB",
        "value": "MRNDB",
        "country": "Mauritania"
    },
    {
        "label": "MRNKC",
        "value": "MRNKC",
        "country": "Mauritania"
    },
    {
        "label": "MTMAR",
        "value": "MTMAR",
        "country": "Malta"
    },
    {
        "label": "MTMGZ",
        "value": "MTMGZ",
        "country": "Malta"
    },
    {
        "label": "MTMLA",
        "value": "MTMLA",
        "country": "Malta"
    },
    {
        "label": "MUPLU",
        "value": "MUPLU",
        "country": "Mauritius"
    },
    {
        "label": "MVMLE",
        "value": "MVMLE",
        "country": "Maldives"
    },
    {
        "label": "MWBLZ",
        "value": "MWBLZ",
        "country": "Malawi"
    },
    {
        "label": "MXACA",
        "value": "MXACA",
        "country": "Mexico"
    },
    {
        "label": "MXATM",
        "value": "MXATM",
        "country": "Mexico"
    },
    {
        "label": "MXCOA",
        "value": "MXCOA",
        "country": "Mexico"
    },
    {
        "label": "MXESE",
        "value": "MXESE",
        "country": "Mexico"
    },
    {
        "label": "MXGYM",
        "value": "MXGYM",
        "country": "Mexico"
    },
    {
        "label": "MXMEX",
        "value": "MXMEX",
        "country": "Mexico"
    },
    {
        "label": "MXPGO",
        "value": "MXPGO",
        "country": "Mexico"
    },
    {
        "label": "MXPMS",
        "value": "MXPMS",
        "country": "Mexico"
    },
    {
        "label": "MXREX",
        "value": "MXREX",
        "country": "Mexico"
    },
    {
        "label": "MXSCX",
        "value": "MXSCX",
        "country": "Mexico"
    },
    {
        "label": "MXTAM",
        "value": "MXTAM",
        "country": "Mexico"
    },
    {
        "label": "MXTPB",
        "value": "MXTPB",
        "country": "Mexico"
    },
    {
        "label": "MXVER",
        "value": "MXVER",
        "country": "Mexico"
    },
    {
        "label": "MXZLO",
        "value": "MXZLO",
        "country": "Mexico"
    },
    {
        "label": "MYBKI",
        "value": "MYBKI",
        "country": "Malaysia"
    },
    {
        "label": "MYBTU",
        "value": "MYBTU",
        "country": "Malaysia"
    },
    {
        "label": "MYBWH",
        "value": "MYBWH",
        "country": "Malaysia"
    },
    {
        "label": "MYJHB",
        "value": "MYJHB",
        "country": "Malaysia"
    },
    {
        "label": "MYKCH",
        "value": "MYKCH",
        "country": "Malaysia"
    },
    {
        "label": "MYKUA",
        "value": "MYKUA",
        "country": "Malaysia"
    },
    {
        "label": "MYLBU",
        "value": "MYLBU",
        "country": "Malaysia"
    },
    {
        "label": "MYMEP",
        "value": "MYMEP",
        "country": "Malaysia"
    },
    {
        "label": "MYMKZ",
        "value": "MYMKZ",
        "country": "Malaysia"
    },
    {
        "label": "MYMUA",
        "value": "MYMUA",
        "country": "Malaysia"
    },
    {
        "label": "MYMYY",
        "value": "MYMYY",
        "country": "Malaysia"
    },
    {
        "label": "MYPEN",
        "value": "MYPEN",
        "country": "Malaysia"
    },
    {
        "label": "MYPGU",
        "value": "MYPGU",
        "country": "Malaysia"
    },
    {
        "label": "MYPKG",
        "value": "MYPKG",
        "country": "Malaysia"
    },
    {
        "label": "MYSBW",
        "value": "MYSBW",
        "country": "Malaysia"
    },
    {
        "label": "MYSDK",
        "value": "MYSDK",
        "country": "Malaysia"
    },
    {
        "label": "MYTBA",
        "value": "MYTBA",
        "country": "Malaysia"
    },
    {
        "label": "MYTGG",
        "value": "MYTGG",
        "country": "Malaysia"
    },
    {
        "label": "MYTPP",
        "value": "MYTPP",
        "country": "Malaysia"
    },
    {
        "label": "MYTWU",
        "value": "MYTWU",
        "country": "Malaysia"
    },
    {
        "label": "MYWSP",
        "value": "MYWSP",
        "country": "Malaysia"
    },
    {
        "label": "MZBEW",
        "value": "MZBEW",
        "country": "Mozambique"
    },
    {
        "label": "MZMNC",
        "value": "MZMNC",
        "country": "Mozambique"
    },
    {
        "label": "MZMPM",
        "value": "MZMPM",
        "country": "Mozambique"
    },
    {
        "label": "MZPOL",
        "value": "MZPOL",
        "country": "Mozambique"
    },
    {
        "label": "MZUEL",
        "value": "MZUEL",
        "country": "Mozambique"
    },
    {
        "label": "NALUD",
        "value": "NALUD",
        "country": "Namibia"
    },
    {
        "label": "NAWVB",
        "value": "NAWVB",
        "country": "Namibia"
    },
    {
        "label": "NCNOU",
        "value": "NCNOU",
        "country": "New Caledonia"
    },
    {
        "label": "NGAPP",
        "value": "NGAPP",
        "country": "Nigeria"
    },
    {
        "label": "NGCBQ",
        "value": "NGCBQ",
        "country": "Nigeria"
    },
    {
        "label": "NGLOS",
        "value": "NGLOS",
        "country": "Nigeria"
    },
    {
        "label": "NGONN",
        "value": "NGONN",
        "country": "Nigeria"
    },
    {
        "label": "NGPHC",
        "value": "NGPHC",
        "country": "Nigeria"
    },
    {
        "label": "NGTIN",
        "value": "NGTIN",
        "country": "Nigeria"
    },
    {
        "label": "NGWAR",
        "value": "NGWAR",
        "country": "Nigeria"
    },
    {
        "label": "NICIO",
        "value": "NICIO",
        "country": "Nicaragua"
    },
    {
        "label": "NIMGA",
        "value": "NIMGA",
        "country": "Nicaragua"
    },
    {
        "label": "NLALK",
        "value": "NLALK",
        "country": "Netherlands"
    },
    {
        "label": "NLAMS",
        "value": "NLAMS",
        "country": "Netherlands"
    },
    {
        "label": "NLBZM",
        "value": "NLBZM",
        "country": "Netherlands"
    },
    {
        "label": "NLDOR",
        "value": "NLDOR",
        "country": "Netherlands"
    },
    {
        "label": "NLEEM",
        "value": "NLEEM",
        "country": "Netherlands"
    },
    {
        "label": "NLGRQ",
        "value": "NLGRQ",
        "country": "Netherlands"
    },
    {
        "label": "NLHVH",
        "value": "NLHVH",
        "country": "Netherlands"
    },
    {
        "label": "NLIJM",
        "value": "NLIJM",
        "country": "Netherlands"
    },
    {
        "label": "NLMOE",
        "value": "NLMOE",
        "country": "Netherlands"
    },
    {
        "label": "NLRTM",
        "value": "NLRTM",
        "country": "Netherlands"
    },
    {
        "label": "NLTNZ",
        "value": "NLTNZ",
        "country": "Netherlands"
    },
    {
        "label": "NLVEL",
        "value": "NLVEL",
        "country": "Netherlands"
    },
    {
        "label": "NLVLA",
        "value": "NLVLA",
        "country": "Netherlands"
    },
    {
        "label": "NLVLI",
        "value": "NLVLI",
        "country": "Netherlands"
    },
    {
        "label": "NLZAA",
        "value": "NLZAA",
        "country": "Netherlands"
    },
    {
        "label": "NLZEW",
        "value": "NLZEW",
        "country": "Netherlands"
    },
    {
        "label": "NOAAV",
        "value": "NOAAV",
        "country": "Norway"
    },
    {
        "label": "NOAES",
        "value": "NOAES",
        "country": "Norway"
    },
    {
        "label": "NOAGO",
        "value": "NOAGO",
        "country": "Norway"
    },
    {
        "label": "NOAND",
        "value": "NOAND",
        "country": "Norway"
    },
    {
        "label": "NOASV",
        "value": "NOASV",
        "country": "Norway"
    },
    {
        "label": "NOBGO",
        "value": "NOBGO",
        "country": "Norway"
    },
    {
        "label": "NOBOO",
        "value": "NOBOO",
        "country": "Norway"
    },
    {
        "label": "NOBVK",
        "value": "NOBVK",
        "country": "Norway"
    },
    {
        "label": "NODRM",
        "value": "NODRM",
        "country": "Norway"
    },
    {
        "label": "NOEGD",
        "value": "NOEGD",
        "country": "Norway"
    },
    {
        "label": "NOFKG",
        "value": "NOFKG",
        "country": "Norway"
    },
    {
        "label": "NOFRK",
        "value": "NOFRK",
        "country": "Norway"
    },
    {
        "label": "NOFRO",
        "value": "NOFRO",
        "country": "Norway"
    },
    {
        "label": "NOFUS",
        "value": "NOFUS",
        "country": "Norway"
    },
    {
        "label": "NOGJM",
        "value": "NOGJM",
        "country": "Norway"
    },
    {
        "label": "NOHAL",
        "value": "NOHAL",
        "country": "Norway"
    },
    {
        "label": "NOHAU",
        "value": "NOHAU",
        "country": "Norway"
    },
    {
        "label": "NOHES",
        "value": "NOHES",
        "country": "Norway"
    },
    {
        "label": "NOHLA",
        "value": "NOHLA",
        "country": "Norway"
    },
    {
        "label": "NOHOG",
        "value": "NOHOG",
        "country": "Norway"
    },
    {
        "label": "NOHOY",
        "value": "NOHOY",
        "country": "Norway"
    },
    {
        "label": "NOHRI",
        "value": "NOHRI",
        "country": "Norway"
    },
    {
        "label": "NOHRY",
        "value": "NOHRY",
        "country": "Norway"
    },
    {
        "label": "NOHUS",
        "value": "NOHUS",
        "country": "Norway"
    },
    {
        "label": "NOHVI",
        "value": "NOHVI",
        "country": "Norway"
    },
    {
        "label": "NOIKR",
        "value": "NOIKR",
        "country": "Norway"
    },
    {
        "label": "NOKMY",
        "value": "NOKMY",
        "country": "Norway"
    },
    {
        "label": "NOKRS",
        "value": "NOKRS",
        "country": "Norway"
    },
    {
        "label": "NOKRV",
        "value": "NOKRV",
        "country": "Norway"
    },
    {
        "label": "NOKSU",
        "value": "NOKSU",
        "country": "Norway"
    },
    {
        "label": "NOLAR",
        "value": "NOLAR",
        "country": "Norway"
    },
    {
        "label": "NOLND",
        "value": "NOLND",
        "country": "Norway"
    },
    {
        "label": "NOMAY",
        "value": "NOMAY",
        "country": "Norway"
    },
    {
        "label": "NOMOL",
        "value": "NOMOL",
        "country": "Norway"
    },
    {
        "label": "NOMON",
        "value": "NOMON",
        "country": "Norway"
    },
    {
        "label": "NOMQN",
        "value": "NOMQN",
        "country": "Norway"
    },
    {
        "label": "NOMSS",
        "value": "NOMSS",
        "country": "Norway"
    },
    {
        "label": "NOODD",
        "value": "NOODD",
        "country": "Norway"
    },
    {
        "label": "NOORK",
        "value": "NOORK",
        "country": "Norway"
    },
    {
        "label": "NOOSL",
        "value": "NOOSL",
        "country": "Norway"
    },
    {
        "label": "NOPOR",
        "value": "NOPOR",
        "country": "Norway"
    },
    {
        "label": "NOSAD",
        "value": "NOSAD",
        "country": "Norway"
    },
    {
        "label": "NOSAU",
        "value": "NOSAU",
        "country": "Norway"
    },
    {
        "label": "NOSKE",
        "value": "NOSKE",
        "country": "Norway"
    },
    {
        "label": "NOSKX",
        "value": "NOSKX",
        "country": "Norway"
    },
    {
        "label": "NOSLX",
        "value": "NOSLX",
        "country": "Norway"
    },
    {
        "label": "NOSPG",
        "value": "NOSPG",
        "country": "Norway"
    },
    {
        "label": "NOSRP",
        "value": "NOSRP",
        "country": "Norway"
    },
    {
        "label": "NOSTM",
        "value": "NOSTM",
        "country": "Norway"
    },
    {
        "label": "NOSUN",
        "value": "NOSUN",
        "country": "Norway"
    },
    {
        "label": "NOSVE",
        "value": "NOSVE",
        "country": "Norway"
    },
    {
        "label": "NOSVG",
        "value": "NOSVG",
        "country": "Norway"
    },
    {
        "label": "NOTAE",
        "value": "NOTAE",
        "country": "Norway"
    },
    {
        "label": "NOTON",
        "value": "NOTON",
        "country": "Norway"
    },
    {
        "label": "NOTOS",
        "value": "NOTOS",
        "country": "Norway"
    },
    {
        "label": "NOTRD",
        "value": "NOTRD",
        "country": "Norway"
    },
    {
        "label": "NZAKL",
        "value": "NZAKL",
        "country": "New Zealand"
    },
    {
        "label": "NZBLU",
        "value": "NZBLU",
        "country": "New Zealand"
    },
    {
        "label": "NZCHC",
        "value": "NZCHC",
        "country": "New Zealand"
    },
    {
        "label": "NZDUD",
        "value": "NZDUD",
        "country": "New Zealand"
    },
    {
        "label": "NZHLZ",
        "value": "NZHLZ",
        "country": "New Zealand"
    },
    {
        "label": "NZLYT",
        "value": "NZLYT",
        "country": "New Zealand"
    },
    {
        "label": "NZMNR",
        "value": "NZMNR",
        "country": "New Zealand"
    },
    {
        "label": "NZNPE",
        "value": "NZNPE",
        "country": "New Zealand"
    },
    {
        "label": "NZNPL",
        "value": "NZNPL",
        "country": "New Zealand"
    },
    {
        "label": "NZNSN",
        "value": "NZNSN",
        "country": "New Zealand"
    },
    {
        "label": "NZPMR",
        "value": "NZPMR",
        "country": "New Zealand"
    },
    {
        "label": "NZPOE",
        "value": "NZPOE",
        "country": "New Zealand"
    },
    {
        "label": "NZTIU",
        "value": "NZTIU",
        "country": "New Zealand"
    },
    {
        "label": "NZTKA",
        "value": "NZTKA",
        "country": "New Zealand"
    },
    {
        "label": "NZTRG",
        "value": "NZTRG",
        "country": "New Zealand"
    },
    {
        "label": "NZWAG",
        "value": "NZWAG",
        "country": "New Zealand"
    },
    {
        "label": "NZWLG",
        "value": "NZWLG",
        "country": "New Zealand"
    },
    {
        "label": "NZWRE",
        "value": "NZWRE",
        "country": "New Zealand"
    },
    {
        "label": "OMMCT",
        "value": "OMMCT",
        "country": "Oman"
    },
    {
        "label": "OMMNQ",
        "value": "OMMNQ",
        "country": "Oman"
    },
    {
        "label": "OMOPQ",
        "value": "OMOPQ",
        "country": "Oman"
    },
    {
        "label": "OMSLL",
        "value": "OMSLL",
        "country": "Oman"
    },
    {
        "label": "OMSOH",
        "value": "OMSOH",
        "country": "Oman"
    },
    {
        "label": "PAAML",
        "value": "PAAML",
        "country": "Panama"
    },
    {
        "label": "PABLB",
        "value": "PABLB",
        "country": "Panama"
    },
    {
        "label": "PACFZ",
        "value": "PACFZ",
        "country": "Panama"
    },
    {
        "label": "PACSO",
        "value": "PACSO",
        "country": "Panama"
    },
    {
        "label": "PACTB",
        "value": "PACTB",
        "country": "Panama"
    },
    {
        "label": "PACTD",
        "value": "PACTD",
        "country": "Panama"
    },
    {
        "label": "PACZZ",
        "value": "PACZZ",
        "country": "Panama"
    },
    {
        "label": "PAMIT",
        "value": "PAMIT",
        "country": "Panama"
    },
    {
        "label": "PAONX",
        "value": "PAONX",
        "country": "Panama"
    },
    {
        "label": "PAPSA",
        "value": "PAPSA",
        "country": "Panama"
    },
    {
        "label": "PAPTY",
        "value": "PAPTY",
        "country": "Panama"
    },
    {
        "label": "PEATI",
        "value": "PEATI",
        "country": "Peru"
    },
    {
        "label": "PECHM",
        "value": "PECHM",
        "country": "Peru"
    },
    {
        "label": "PECLL",
        "value": "PECLL",
        "country": "Peru"
    },
    {
        "label": "PEGSM",
        "value": "PEGSM",
        "country": "Peru"
    },
    {
        "label": "PEHCO",
        "value": "PEHCO",
        "country": "Peru"
    },
    {
        "label": "PEILQ",
        "value": "PEILQ",
        "country": "Peru"
    },
    {
        "label": "PEIQT",
        "value": "PEIQT",
        "country": "Peru"
    },
    {
        "label": "PELOB",
        "value": "PELOB",
        "country": "Peru"
    },
    {
        "label": "PEMRI",
        "value": "PEMRI",
        "country": "Peru"
    },
    {
        "label": "PEPAC",
        "value": "PEPAC",
        "country": "Peru"
    },
    {
        "label": "PEPAI",
        "value": "PEPAI",
        "country": "Peru"
    },
    {
        "label": "PEPCH",
        "value": "PEPCH",
        "country": "Peru"
    },
    {
        "label": "PEPIO",
        "value": "PEPIO",
        "country": "Peru"
    },
    {
        "label": "PESNX",
        "value": "PESNX",
        "country": "Peru"
    },
    {
        "label": "PESUP",
        "value": "PESUP",
        "country": "Peru"
    },
    {
        "label": "PESVY",
        "value": "PESVY",
        "country": "Peru"
    },
    {
        "label": "PETYL",
        "value": "PETYL",
        "country": "Peru"
    },
    {
        "label": "PFPPT",
        "value": "PFPPT",
        "country": "French Polynesia"
    },
    {
        "label": "PGLAE",
        "value": "PGLAE",
        "country": "Papua New Guinea"
    },
    {
        "label": "PGMAG",
        "value": "PGMAG",
        "country": "Papua New Guinea"
    },
    {
        "label": "PGPOM",
        "value": "PGPOM",
        "country": "Papua New Guinea"
    },
    {
        "label": "PGRAB",
        "value": "PGRAB",
        "country": "Papua New Guinea"
    },
    {
        "label": "PHCEB",
        "value": "PHCEB",
        "country": "Philippines"
    },
    {
        "label": "PHCGY",
        "value": "PHCGY",
        "country": "Philippines"
    },
    {
        "label": "PHDVO",
        "value": "PHDVO",
        "country": "Philippines"
    },
    {
        "label": "PHGES",
        "value": "PHGES",
        "country": "Philippines"
    },
    {
        "label": "PHIGN",
        "value": "PHIGN",
        "country": "Philippines"
    },
    {
        "label": "PHISB",
        "value": "PHISB",
        "country": "Philippines"
    },
    {
        "label": "PHMLS",
        "value": "PHMLS",
        "country": "Philippines"
    },
    {
        "label": "PHMNI",
        "value": "PHMNI",
        "country": "Philippines"
    },
    {
        "label": "PHMNL",
        "value": "PHMNL",
        "country": "Philippines"
    },
    {
        "label": "PHMNN",
        "value": "PHMNN",
        "country": "Philippines"
    },
    {
        "label": "PHMNS",
        "value": "PHMNS",
        "country": "Philippines"
    },
    {
        "label": "PHOZC",
        "value": "PHOZC",
        "country": "Philippines"
    },
    {
        "label": "PHPSL",
        "value": "PHPSL",
        "country": "Philippines"
    },
    {
        "label": "PHSFS",
        "value": "PHSFS",
        "country": "Philippines"
    },
    {
        "label": "PHZAM",
        "value": "PHZAM",
        "country": "Philippines"
    },
    {
        "label": "PKBQM",
        "value": "PKBQM",
        "country": "Pakistan"
    },
    {
        "label": "PKKHI",
        "value": "PKKHI",
        "country": "Pakistan"
    },
    {
        "label": "PLGDN",
        "value": "PLGDN",
        "country": "Poland"
    },
    {
        "label": "PLGDY",
        "value": "PLGDY",
        "country": "Poland"
    },
    {
        "label": "PLSWI",
        "value": "PLSWI",
        "country": "Poland"
    },
    {
        "label": "PLSZZ",
        "value": "PLSZZ",
        "country": "Poland"
    },
    {
        "label": "PRBQN",
        "value": "PRBQN",
        "country": "Puerto Rico"
    },
    {
        "label": "PRMAZ",
        "value": "PRMAZ",
        "country": "Puerto Rico"
    },
    {
        "label": "PRPNU",
        "value": "PRPNU",
        "country": "Puerto Rico"
    },
    {
        "label": "PRPSE",
        "value": "PRPSE",
        "country": "Puerto Rico"
    },
    {
        "label": "PRSJU",
        "value": "PRSJU",
        "country": "Puerto Rico"
    },
    {
        "label": "PTADH",
        "value": "PTADH",
        "country": "Portugal"
    },
    {
        "label": "PTCNL",
        "value": "PTCNL",
        "country": "Portugal"
    },
    {
        "label": "PTENT",
        "value": "PTENT",
        "country": "Portugal"
    },
    {
        "label": "PTFDF",
        "value": "PTFDF",
        "country": "Portugal"
    },
    {
        "label": "PTFNC",
        "value": "PTFNC",
        "country": "Portugal"
    },
    {
        "label": "PTHOR",
        "value": "PTHOR",
        "country": "Portugal"
    },
    {
        "label": "PTLEI",
        "value": "PTLEI",
        "country": "Portugal"
    },
    {
        "label": "PTLIS",
        "value": "PTLIS",
        "country": "Portugal"
    },
    {
        "label": "PTOPO",
        "value": "PTOPO",
        "country": "Portugal"
    },
    {
        "label": "PTPDL",
        "value": "PTPDL",
        "country": "Portugal"
    },
    {
        "label": "PTPRV",
        "value": "PTPRV",
        "country": "Portugal"
    },
    {
        "label": "PTSET",
        "value": "PTSET",
        "country": "Portugal"
    },
    {
        "label": "PTSIE",
        "value": "PTSIE",
        "country": "Portugal"
    },
    {
        "label": "PTTER",
        "value": "PTTER",
        "country": "Portugal"
    },
    {
        "label": "PTVDC",
        "value": "PTVDC",
        "country": "Portugal"
    },
    {
        "label": "PYASU",
        "value": "PYASU",
        "country": "Paraguay"
    },
    {
        "label": "PYBCM",
        "value": "PYBCM",
        "country": "Paraguay"
    },
    {
        "label": "PYFNX",
        "value": "PYFNX",
        "country": "Paraguay"
    },
    {
        "label": "PYPIL",
        "value": "PYPIL",
        "country": "Paraguay"
    },
    {
        "label": "PYPSE",
        "value": "PYPSE",
        "country": "Paraguay"
    },
    {
        "label": "PYSAN",
        "value": "PYSAN",
        "country": "Paraguay"
    },
    {
        "label": "PYTER",
        "value": "PYTER",
        "country": "Paraguay"
    },
    {
        "label": "QADOH",
        "value": "QADOH",
        "country": "Qatar"
    },
    {
        "label": "QAMES",
        "value": "QAMES",
        "country": "Qatar"
    },
    {
        "label": "QAUMS",
        "value": "QAUMS",
        "country": "Qatar"
    },
    {
        "label": "RELPT",
        "value": "RELPT",
        "country": "R\u00e9union"
    },
    {
        "label": "REPDG",
        "value": "REPDG",
        "country": "R\u00e9union"
    },
    {
        "label": "REREU",
        "value": "REREU",
        "country": "R\u00e9union"
    },
    {
        "label": "RERUN",
        "value": "RERUN",
        "country": "R\u00e9union"
    },
    {
        "label": "ROAGI",
        "value": "ROAGI",
        "country": "Romania"
    },
    {
        "label": "ROBRA",
        "value": "ROBRA",
        "country": "Romania"
    },
    {
        "label": "ROCND",
        "value": "ROCND",
        "country": "Romania"
    },
    {
        "label": "ROGAL",
        "value": "ROGAL",
        "country": "Romania"
    },
    {
        "label": "ROMAG",
        "value": "ROMAG",
        "country": "Romania"
    },
    {
        "label": "RUARH",
        "value": "RUARH",
        "country": "Russian Federation"
    },
    {
        "label": "RUGDX",
        "value": "RUGDX",
        "country": "Russian Federation"
    },
    {
        "label": "RUKGD",
        "value": "RUKGD",
        "country": "Russian Federation"
    },
    {
        "label": "RUKHO",
        "value": "RUKHO",
        "country": "Russian Federation"
    },
    {
        "label": "RUKOR",
        "value": "RUKOR",
        "country": "Russian Federation"
    },
    {
        "label": "RUKRR",
        "value": "RUKRR",
        "country": "Russian Federation"
    },
    {
        "label": "RULED",
        "value": "RULED",
        "country": "Russian Federation"
    },
    {
        "label": "RUMMK",
        "value": "RUMMK",
        "country": "Russian Federation"
    },
    {
        "label": "RUNJK",
        "value": "RUNJK",
        "country": "Russian Federation"
    },
    {
        "label": "RUNVS",
        "value": "RUNVS",
        "country": "Russian Federation"
    },
    {
        "label": "RUPTP",
        "value": "RUPTP",
        "country": "Russian Federation"
    },
    {
        "label": "RUROV",
        "value": "RUROV",
        "country": "Russian Federation"
    },
    {
        "label": "RUTAG",
        "value": "RUTAG",
        "country": "Russian Federation"
    },
    {
        "label": "RUVVO",
        "value": "RUVVO",
        "country": "Russian Federation"
    },
    {
        "label": "RUVYG",
        "value": "RUVYG",
        "country": "Russian Federation"
    },
    {
        "label": "RUVYP",
        "value": "RUVYP",
        "country": "Russian Federation"
    },
    {
        "label": "RWKGL",
        "value": "RWKGL",
        "country": "Rwanda"
    },
    {
        "label": "SADMM",
        "value": "SADMM",
        "country": "Saudi Arabia"
    },
    {
        "label": "SADMN",
        "value": "SADMN",
        "country": "Saudi Arabia"
    },
    {
        "label": "SAJED",
        "value": "SAJED",
        "country": "Saudi Arabia"
    },
    {
        "label": "SAJUB",
        "value": "SAJUB",
        "country": "Saudi Arabia"
    },
    {
        "label": "SAKAC",
        "value": "SAKAC",
        "country": "Saudi Arabia"
    },
    {
        "label": "SARUH",
        "value": "SARUH",
        "country": "Saudi Arabia"
    },
    {
        "label": "SBHIR",
        "value": "SBHIR",
        "country": "Solomon Islands"
    },
    {
        "label": "SBNOR",
        "value": "SBNOR",
        "country": "Solomon Islands"
    },
    {
        "label": "SCMAW",
        "value": "SCMAW",
        "country": "Seychelles"
    },
    {
        "label": "SCPOV",
        "value": "SCPOV",
        "country": "Seychelles"
    },
    {
        "label": "SDPZU",
        "value": "SDPZU",
        "country": "Sudan"
    },
    {
        "label": "SEAHU",
        "value": "SEAHU",
        "country": "Sweden"
    },
    {
        "label": "SEFAG",
        "value": "SEFAG",
        "country": "Sweden"
    },
    {
        "label": "SEGOT",
        "value": "SEGOT",
        "country": "Sweden"
    },
    {
        "label": "SEGVX",
        "value": "SEGVX",
        "country": "Sweden"
    },
    {
        "label": "SEHAD",
        "value": "SEHAD",
        "country": "Sweden"
    },
    {
        "label": "SEHEL",
        "value": "SEHEL",
        "country": "Sweden"
    },
    {
        "label": "SEKAN",
        "value": "SEKAN",
        "country": "Sweden"
    },
    {
        "label": "SEKSD",
        "value": "SEKSD",
        "country": "Sweden"
    },
    {
        "label": "SELAA",
        "value": "SELAA",
        "country": "Sweden"
    },
    {
        "label": "SEMMA",
        "value": "SEMMA",
        "country": "Sweden"
    },
    {
        "label": "SENRK",
        "value": "SENRK",
        "country": "Sweden"
    },
    {
        "label": "SEOSK",
        "value": "SEOSK",
        "country": "Sweden"
    },
    {
        "label": "SEOXE",
        "value": "SEOXE",
        "country": "Sweden"
    },
    {
        "label": "SESDL",
        "value": "SESDL",
        "country": "Sweden"
    },
    {
        "label": "SESFT",
        "value": "SESFT",
        "country": "Sweden"
    },
    {
        "label": "SESKE",
        "value": "SESKE",
        "country": "Sweden"
    },
    {
        "label": "SESOE",
        "value": "SESOE",
        "country": "Sweden"
    },
    {
        "label": "SESTO",
        "value": "SESTO",
        "country": "Sweden"
    },
    {
        "label": "SETRG",
        "value": "SETRG",
        "country": "Sweden"
    },
    {
        "label": "SEUDD",
        "value": "SEUDD",
        "country": "Sweden"
    },
    {
        "label": "SEUME",
        "value": "SEUME",
        "country": "Sweden"
    },
    {
        "label": "SEVST",
        "value": "SEVST",
        "country": "Sweden"
    },
    {
        "label": "SGJUR",
        "value": "SGJUR",
        "country": "Singapore"
    },
    {
        "label": "SGSIN",
        "value": "SGSIN",
        "country": "Singapore"
    },
    {
        "label": "SIKOP",
        "value": "SIKOP",
        "country": "Slovenia"
    },
    {
        "label": "SKBTS",
        "value": "SKBTS",
        "country": "Slovakia"
    },
    {
        "label": "SLFNA",
        "value": "SLFNA",
        "country": "Sierra Leone"
    },
    {
        "label": "SNDKR",
        "value": "SNDKR",
        "country": "Senegal"
    },
    {
        "label": "SOBBO",
        "value": "SOBBO",
        "country": "Somalia"
    },
    {
        "label": "SOKMU",
        "value": "SOKMU",
        "country": "Somalia"
    },
    {
        "label": "SOMGQ",
        "value": "SOMGQ",
        "country": "Somalia"
    },
    {
        "label": "SRPBM",
        "value": "SRPBM",
        "country": "Suriname"
    },
    {
        "label": "STTMS",
        "value": "STTMS",
        "country": "Sao Tome and Principe"
    },
    {
        "label": "SVAQJ",
        "value": "SVAQJ",
        "country": "El Salvador"
    },
    {
        "label": "SVLLD",
        "value": "SVLLD",
        "country": "El Salvador"
    },
    {
        "label": "SVLUN",
        "value": "SVLUN",
        "country": "El Salvador"
    },
    {
        "label": "SVSAL",
        "value": "SVSAL",
        "country": "El Salvador"
    },
    {
        "label": "SYDAM",
        "value": "SYDAM",
        "country": "Syrian Arab Republic"
    },
    {
        "label": "SYLTK",
        "value": "SYLTK",
        "country": "Syrian Arab Republic"
    },
    {
        "label": "SYTTS",
        "value": "SYTTS",
        "country": "Syrian Arab Republic"
    },
    {
        "label": "TCPLS",
        "value": "TCPLS",
        "country": "Turks and Caicos Islands"
    },
    {
        "label": "TGLFW",
        "value": "TGLFW",
        "country": "Togo"
    },
    {
        "label": "THBGP",
        "value": "THBGP",
        "country": "Thailand"
    },
    {
        "label": "THBKK",
        "value": "THBKK",
        "country": "Thailand"
    },
    {
        "label": "THBMT",
        "value": "THBMT",
        "country": "Thailand"
    },
    {
        "label": "THKSI",
        "value": "THKSI",
        "country": "Thailand"
    },
    {
        "label": "THKTY",
        "value": "THKTY",
        "country": "Thailand"
    },
    {
        "label": "THLCH",
        "value": "THLCH",
        "country": "Thailand"
    },
    {
        "label": "THLKR",
        "value": "THLKR",
        "country": "Thailand"
    },
    {
        "label": "THMAT",
        "value": "THMAT",
        "country": "Thailand"
    },
    {
        "label": "THPAT",
        "value": "THPAT",
        "country": "Thailand"
    },
    {
        "label": "THPKN",
        "value": "THPKN",
        "country": "Thailand"
    },
    {
        "label": "THSBP",
        "value": "THSBP",
        "country": "Thailand"
    },
    {
        "label": "THSGZ",
        "value": "THSGZ",
        "country": "Thailand"
    },
    {
        "label": "THSPR",
        "value": "THSPR",
        "country": "Thailand"
    },
    {
        "label": "THSRI",
        "value": "THSRI",
        "country": "Thailand"
    },
    {
        "label": "THTPT",
        "value": "THTPT",
        "country": "Thailand"
    },
    {
        "label": "TNGAE",
        "value": "TNGAE",
        "country": "Tunisia"
    },
    {
        "label": "TNJAR",
        "value": "TNJAR",
        "country": "Tunisia"
    },
    {
        "label": "TNLGN",
        "value": "TNLGN",
        "country": "Tunisia"
    },
    {
        "label": "TNRDS",
        "value": "TNRDS",
        "country": "Tunisia"
    },
    {
        "label": "TNSFA",
        "value": "TNSFA",
        "country": "Tunisia"
    },
    {
        "label": "TNSUS",
        "value": "TNSUS",
        "country": "Tunisia"
    },
    {
        "label": "TNTUN",
        "value": "TNTUN",
        "country": "Tunisia"
    },
    {
        "label": "TOTBU",
        "value": "TOTBU",
        "country": "Tonga"
    },
    {
        "label": "TRALI",
        "value": "TRALI",
        "country": "Turkey"
    },
    {
        "label": "TRAMB",
        "value": "TRAMB",
        "country": "Turkey"
    },
    {
        "label": "TRAVC",
        "value": "TRAVC",
        "country": "Turkey"
    },
    {
        "label": "TRAYT",
        "value": "TRAYT",
        "country": "Turkey"
    },
    {
        "label": "TRBAY",
        "value": "TRBAY",
        "country": "Turkey"
    },
    {
        "label": "TRBDM",
        "value": "TRBDM",
        "country": "Turkey"
    },
    {
        "label": "TREYP",
        "value": "TREYP",
        "country": "Turkey"
    },
    {
        "label": "TRGEB",
        "value": "TRGEB",
        "country": "Turkey"
    },
    {
        "label": "TRGEM",
        "value": "TRGEM",
        "country": "Turkey"
    },
    {
        "label": "TRHAY",
        "value": "TRHAY",
        "country": "Turkey"
    },
    {
        "label": "TRHER",
        "value": "TRHER",
        "country": "Turkey"
    },
    {
        "label": "TRISK",
        "value": "TRISK",
        "country": "Turkey"
    },
    {
        "label": "TRIST",
        "value": "TRIST",
        "country": "Turkey"
    },
    {
        "label": "TRIZM",
        "value": "TRIZM",
        "country": "Turkey"
    },
    {
        "label": "TRIZT",
        "value": "TRIZT",
        "country": "Turkey"
    },
    {
        "label": "TRKMX",
        "value": "TRKMX",
        "country": "Turkey"
    },
    {
        "label": "TRMAD",
        "value": "TRMAD",
        "country": "Turkey"
    },
    {
        "label": "TRMAR",
        "value": "TRMAR",
        "country": "Turkey"
    },
    {
        "label": "TRMER",
        "value": "TRMER",
        "country": "Turkey"
    },
    {
        "label": "TRNEM",
        "value": "TRNEM",
        "country": "Turkey"
    },
    {
        "label": "TRSSX",
        "value": "TRSSX",
        "country": "Turkey"
    },
    {
        "label": "TRTEK",
        "value": "TRTEK",
        "country": "Turkey"
    },
    {
        "label": "TRTZX",
        "value": "TRTZX",
        "country": "Turkey"
    },
    {
        "label": "TRYAR",
        "value": "TRYAR",
        "country": "Turkey"
    },
    {
        "label": "TTPOS",
        "value": "TTPOS",
        "country": "Trinidad and Tobago"
    },
    {
        "label": "TTPTP",
        "value": "TTPTP",
        "country": "Trinidad and Tobago"
    },
    {
        "label": "TTPTS",
        "value": "TTPTS",
        "country": "Trinidad and Tobago"
    },
    {
        "label": "TTSFE",
        "value": "TTSFE",
        "country": "Trinidad and Tobago"
    },
    {
        "label": "TWKEL",
        "value": "TWKEL",
        "country": "Taiwan"
    },
    {
        "label": "TWKHH",
        "value": "TWKHH",
        "country": "Taiwan"
    },
    {
        "label": "TWMLI",
        "value": "TWMLI",
        "country": "Taiwan"
    },
    {
        "label": "TWSUO",
        "value": "TWSUO",
        "country": "Taiwan"
    },
    {
        "label": "TWTPE",
        "value": "TWTPE",
        "country": "Taiwan"
    },
    {
        "label": "TWTXG",
        "value": "TWTXG",
        "country": "Taiwan"
    },
    {
        "label": "TWTYN",
        "value": "TWTYN",
        "country": "Taiwan"
    },
    {
        "label": "TZDAR",
        "value": "TZDAR",
        "country": "Tanzania, United Republic of"
    },
    {
        "label": "TZTGT",
        "value": "TZTGT",
        "country": "Tanzania, United Republic of"
    },
    {
        "label": "TZZNZ",
        "value": "TZZNZ",
        "country": "Tanzania, United Republic of"
    },
    {
        "label": "UAIEV",
        "value": "UAIEV",
        "country": "Ukraine"
    },
    {
        "label": "UAILK",
        "value": "UAILK",
        "country": "Ukraine"
    },
    {
        "label": "UAKHE",
        "value": "UAKHE",
        "country": "Ukraine"
    },
    {
        "label": "UAMPW",
        "value": "UAMPW",
        "country": "Ukraine"
    },
    {
        "label": "UAODS",
        "value": "UAODS",
        "country": "Ukraine"
    },
    {
        "label": "UASVP",
        "value": "UASVP",
        "country": "Ukraine"
    },
    {
        "label": "UGJIN",
        "value": "UGJIN",
        "country": "Uganda"
    },
    {
        "label": "UGKLA",
        "value": "UGKLA",
        "country": "Uganda"
    },
    {
        "label": "USADQ",
        "value": "USADQ",
        "country": "United States"
    },
    {
        "label": "USANC",
        "value": "USANC",
        "country": "United States"
    },
    {
        "label": "USANP",
        "value": "USANP",
        "country": "United States"
    },
    {
        "label": "USAR5",
        "value": "USAR5",
        "country": "United States"
    },
    {
        "label": "USATL",
        "value": "USATL",
        "country": "United States"
    },
    {
        "label": "USBAL",
        "value": "USBAL",
        "country": "United States"
    },
    {
        "label": "USBCC",
        "value": "USBCC",
        "country": "United States"
    },
    {
        "label": "USBHM",
        "value": "USBHM",
        "country": "United States"
    },
    {
        "label": "USBNA",
        "value": "USBNA",
        "country": "United States"
    },
    {
        "label": "USBOS",
        "value": "USBOS",
        "country": "United States"
    },
    {
        "label": "USBPT",
        "value": "USBPT",
        "country": "United States"
    },
    {
        "label": "USBRO",
        "value": "USBRO",
        "country": "United States"
    },
    {
        "label": "USBUF",
        "value": "USBUF",
        "country": "United States"
    },
    {
        "label": "USCBF",
        "value": "USCBF",
        "country": "United States"
    },
    {
        "label": "USCHA",
        "value": "USCHA",
        "country": "United States"
    },
    {
        "label": "USCHI",
        "value": "USCHI",
        "country": "United States"
    },
    {
        "label": "USCHS",
        "value": "USCHS",
        "country": "United States"
    },
    {
        "label": "USCLE",
        "value": "USCLE",
        "country": "United States"
    },
    {
        "label": "USCLM",
        "value": "USCLM",
        "country": "United States"
    },
    {
        "label": "USCLT",
        "value": "USCLT",
        "country": "United States"
    },
    {
        "label": "USCMH",
        "value": "USCMH",
        "country": "United States"
    },
    {
        "label": "USCPF",
        "value": "USCPF",
        "country": "United States"
    },
    {
        "label": "USCPV",
        "value": "USCPV",
        "country": "United States"
    },
    {
        "label": "USCRP",
        "value": "USCRP",
        "country": "United States"
    },
    {
        "label": "USCVG",
        "value": "USCVG",
        "country": "United States"
    },
    {
        "label": "USCZF",
        "value": "USCZF",
        "country": "United States"
    },
    {
        "label": "USDA8",
        "value": "USDA8",
        "country": "United States"
    },
    {
        "label": "USDAL",
        "value": "USDAL",
        "country": "United States"
    },
    {
        "label": "USDEC",
        "value": "USDEC",
        "country": "United States"
    },
    {
        "label": "USDEN",
        "value": "USDEN",
        "country": "United States"
    },
    {
        "label": "USDET",
        "value": "USDET",
        "country": "United States"
    },
    {
        "label": "USDNN",
        "value": "USDNN",
        "country": "United States"
    },
    {
        "label": "USDSM",
        "value": "USDSM",
        "country": "United States"
    },
    {
        "label": "USDUT",
        "value": "USDUT",
        "country": "United States"
    },
    {
        "label": "USELP",
        "value": "USELP",
        "country": "United States"
    },
    {
        "label": "USENA",
        "value": "USENA",
        "country": "United States"
    },
    {
        "label": "USEWR",
        "value": "USEWR",
        "country": "United States"
    },
    {
        "label": "USFEB",
        "value": "USFEB",
        "country": "United States"
    },
    {
        "label": "USFLL",
        "value": "USFLL",
        "country": "United States"
    },
    {
        "label": "USFPO",
        "value": "USFPO",
        "country": "United States"
    },
    {
        "label": "USFRP",
        "value": "USFRP",
        "country": "United States"
    },
    {
        "label": "USFWT",
        "value": "USFWT",
        "country": "United States"
    },
    {
        "label": "USGBO",
        "value": "USGBO",
        "country": "United States"
    },
    {
        "label": "USGLS",
        "value": "USGLS",
        "country": "United States"
    },
    {
        "label": "USGPT",
        "value": "USGPT",
        "country": "United States"
    },
    {
        "label": "USGSP",
        "value": "USGSP",
        "country": "United States"
    },
    {
        "label": "USHNL",
        "value": "USHNL",
        "country": "United States"
    },
    {
        "label": "USHOU",
        "value": "USHOU",
        "country": "United States"
    },
    {
        "label": "USHSV",
        "value": "USHSV",
        "country": "United States"
    },
    {
        "label": "USILM",
        "value": "USILM",
        "country": "United States"
    },
    {
        "label": "USIND",
        "value": "USIND",
        "country": "United States"
    },
    {
        "label": "USJ3C",
        "value": "USJ3C",
        "country": "United States"
    },
    {
        "label": "USJAX",
        "value": "USJAX",
        "country": "United States"
    },
    {
        "label": "USJOT",
        "value": "USJOT",
        "country": "United States"
    },
    {
        "label": "USKCK",
        "value": "USKCK",
        "country": "United States"
    },
    {
        "label": "USLAS",
        "value": "USLAS",
        "country": "United States"
    },
    {
        "label": "USLAT",
        "value": "USLAT",
        "country": "United States"
    },
    {
        "label": "USLAX",
        "value": "USLAX",
        "country": "United States"
    },
    {
        "label": "USLCH",
        "value": "USLCH",
        "country": "United States"
    },
    {
        "label": "USLGB",
        "value": "USLGB",
        "country": "United States"
    },
    {
        "label": "USLRD",
        "value": "USLRD",
        "country": "United States"
    },
    {
        "label": "USLUI",
        "value": "USLUI",
        "country": "United States"
    },
    {
        "label": "USMEM",
        "value": "USMEM",
        "country": "United States"
    },
    {
        "label": "USMES",
        "value": "USMES",
        "country": "United States"
    },
    {
        "label": "USMIA",
        "value": "USMIA",
        "country": "United States"
    },
    {
        "label": "USMKC",
        "value": "USMKC",
        "country": "United States"
    },
    {
        "label": "USMKE",
        "value": "USMKE",
        "country": "United States"
    },
    {
        "label": "USMOB",
        "value": "USMOB",
        "country": "United States"
    },
    {
        "label": "USMRH",
        "value": "USMRH",
        "country": "United States"
    },
    {
        "label": "USMSP",
        "value": "USMSP",
        "country": "United States"
    },
    {
        "label": "USMSY",
        "value": "USMSY",
        "country": "United States"
    },
    {
        "label": "USNNS",
        "value": "USNNS",
        "country": "United States"
    },
    {
        "label": "USNTD",
        "value": "USNTD",
        "country": "United States"
    },
    {
        "label": "USNTS",
        "value": "USNTS",
        "country": "United States"
    },
    {
        "label": "USNYC",
        "value": "USNYC",
        "country": "United States"
    },
    {
        "label": "USOAK",
        "value": "USOAK",
        "country": "United States"
    },
    {
        "label": "USOCN",
        "value": "USOCN",
        "country": "United States"
    },
    {
        "label": "USOCW",
        "value": "USOCW",
        "country": "United States"
    },
    {
        "label": "USOLM",
        "value": "USOLM",
        "country": "United States"
    },
    {
        "label": "USOMA",
        "value": "USOMA",
        "country": "United States"
    },
    {
        "label": "USORF",
        "value": "USORF",
        "country": "United States"
    },
    {
        "label": "USORL",
        "value": "USORL",
        "country": "United States"
    },
    {
        "label": "USOYM",
        "value": "USOYM",
        "country": "United States"
    },
    {
        "label": "USPAB",
        "value": "USPAB",
        "country": "United States"
    },
    {
        "label": "USPCA",
        "value": "USPCA",
        "country": "United States"
    },
    {
        "label": "USPCV",
        "value": "USPCV",
        "country": "United States"
    },
    {
        "label": "USPDX",
        "value": "USPDX",
        "country": "United States"
    },
    {
        "label": "USPEB",
        "value": "USPEB",
        "country": "United States"
    },
    {
        "label": "USPEF",
        "value": "USPEF",
        "country": "United States"
    },
    {
        "label": "USPFN",
        "value": "USPFN",
        "country": "United States"
    },
    {
        "label": "USPGL",
        "value": "USPGL",
        "country": "United States"
    },
    {
        "label": "USPHF",
        "value": "USPHF",
        "country": "United States"
    },
    {
        "label": "USPHL",
        "value": "USPHL",
        "country": "United States"
    },
    {
        "label": "USPHX",
        "value": "USPHX",
        "country": "United States"
    },
    {
        "label": "USPIA",
        "value": "USPIA",
        "country": "United States"
    },
    {
        "label": "USPIT",
        "value": "USPIT",
        "country": "United States"
    },
    {
        "label": "USPME",
        "value": "USPME",
        "country": "United States"
    },
    {
        "label": "USPNS",
        "value": "USPNS",
        "country": "United States"
    },
    {
        "label": "USPOA",
        "value": "USPOA",
        "country": "United States"
    },
    {
        "label": "USPPS",
        "value": "USPPS",
        "country": "United States"
    },
    {
        "label": "USPS6",
        "value": "USPS6",
        "country": "United States"
    },
    {
        "label": "USPTM",
        "value": "USPTM",
        "country": "United States"
    },
    {
        "label": "USPVD",
        "value": "USPVD",
        "country": "United States"
    },
    {
        "label": "USPWM",
        "value": "USPWM",
        "country": "United States"
    },
    {
        "label": "USPWN",
        "value": "USPWN",
        "country": "United States"
    },
    {
        "label": "USPYM",
        "value": "USPYM",
        "country": "United States"
    },
    {
        "label": "USRAJ",
        "value": "USRAJ",
        "country": "United States"
    },
    {
        "label": "USRBC",
        "value": "USRBC",
        "country": "United States"
    },
    {
        "label": "USRIC",
        "value": "USRIC",
        "country": "United States"
    },
    {
        "label": "USRNO",
        "value": "USRNO",
        "country": "United States"
    },
    {
        "label": "USSAC",
        "value": "USSAC",
        "country": "United States"
    },
    {
        "label": "USSAN",
        "value": "USSAN",
        "country": "United States"
    },
    {
        "label": "USSAT",
        "value": "USSAT",
        "country": "United States"
    },
    {
        "label": "USSAV",
        "value": "USSAV",
        "country": "United States"
    },
    {
        "label": "USSCK",
        "value": "USSCK",
        "country": "United States"
    },
    {
        "label": "USSEA",
        "value": "USSEA",
        "country": "United States"
    },
    {
        "label": "USSFO",
        "value": "USSFO",
        "country": "United States"
    },
    {
        "label": "USSLC",
        "value": "USSLC",
        "country": "United States"
    },
    {
        "label": "USSPT",
        "value": "USSPT",
        "country": "United States"
    },
    {
        "label": "USSSI",
        "value": "USSSI",
        "country": "United States"
    },
    {
        "label": "USSTL",
        "value": "USSTL",
        "country": "United States"
    },
    {
        "label": "USSTP",
        "value": "USSTP",
        "country": "United States"
    },
    {
        "label": "USSUE",
        "value": "USSUE",
        "country": "United States"
    },
    {
        "label": "USSUW",
        "value": "USSUW",
        "country": "United States"
    },
    {
        "label": "USSWF",
        "value": "USSWF",
        "country": "United States"
    },
    {
        "label": "USSXT",
        "value": "USSXT",
        "country": "United States"
    },
    {
        "label": "USSXZ",
        "value": "USSXZ",
        "country": "United States"
    },
    {
        "label": "USTIW",
        "value": "USTIW",
        "country": "United States"
    },
    {
        "label": "USTMM",
        "value": "USTMM",
        "country": "United States"
    },
    {
        "label": "USTOL",
        "value": "USTOL",
        "country": "United States"
    },
    {
        "label": "USTPA",
        "value": "USTPA",
        "country": "United States"
    },
    {
        "label": "USTSA",
        "value": "USTSA",
        "country": "United States"
    },
    {
        "label": "USTUL",
        "value": "USTUL",
        "country": "United States"
    },
    {
        "label": "USUGN",
        "value": "USUGN",
        "country": "United States"
    },
    {
        "label": "USUXZ",
        "value": "USUXZ",
        "country": "United States"
    },
    {
        "label": "USUZF",
        "value": "USUZF",
        "country": "United States"
    },
    {
        "label": "USVAB",
        "value": "USVAB",
        "country": "United States"
    },
    {
        "label": "USXGO",
        "value": "USXGO",
        "country": "United States"
    },
    {
        "label": "USXHF",
        "value": "USXHF",
        "country": "United States"
    },
    {
        "label": "USXHO",
        "value": "USXHO",
        "country": "United States"
    },
    {
        "label": "USYOB",
        "value": "USYOB",
        "country": "United States"
    },
    {
        "label": "UYCYR",
        "value": "UYCYR",
        "country": "Uruguay"
    },
    {
        "label": "UYMVD",
        "value": "UYMVD",
        "country": "Uruguay"
    },
    {
        "label": "UYNVP",
        "value": "UYNVP",
        "country": "Uruguay"
    },
    {
        "label": "UYPDU",
        "value": "UYPDU",
        "country": "Uruguay"
    },
    {
        "label": "VCKTN",
        "value": "VCKTN",
        "country": "Saint Vincent and the Grenadines"
    },
    {
        "label": "VEAMY",
        "value": "VEAMY",
        "country": "Venezuela"
    },
    {
        "label": "VEBLA",
        "value": "VEBLA",
        "country": "Venezuela"
    },
    {
        "label": "VEEGU",
        "value": "VEEGU",
        "country": "Venezuela"
    },
    {
        "label": "VEETV",
        "value": "VEETV",
        "country": "Venezuela"
    },
    {
        "label": "VEGUB",
        "value": "VEGUB",
        "country": "Venezuela"
    },
    {
        "label": "VEGUT",
        "value": "VEGUT",
        "country": "Venezuela"
    },
    {
        "label": "VELAG",
        "value": "VELAG",
        "country": "Venezuela"
    },
    {
        "label": "VELSV",
        "value": "VELSV",
        "country": "Venezuela"
    },
    {
        "label": "VEMAR",
        "value": "VEMAR",
        "country": "Venezuela"
    },
    {
        "label": "VEMTV",
        "value": "VEMTV",
        "country": "Venezuela"
    },
    {
        "label": "VEPBL",
        "value": "VEPBL",
        "country": "Venezuela"
    },
    {
        "label": "VEPCN",
        "value": "VEPCN",
        "country": "Venezuela"
    },
    {
        "label": "VEPSU",
        "value": "VEPSU",
        "country": "Venezuela"
    },
    {
        "label": "VEPZO",
        "value": "VEPZO",
        "country": "Venezuela"
    },
    {
        "label": "VGRAD",
        "value": "VGRAD",
        "country": "Virgin Islands, British"
    },
    {
        "label": "VGTOV",
        "value": "VGTOV",
        "country": "Virgin Islands, British"
    },
    {
        "label": "VICHA",
        "value": "VICHA",
        "country": "Virgin Islands, U.S."
    },
    {
        "label": "VICTD",
        "value": "VICTD",
        "country": "Virgin Islands, U.S."
    },
    {
        "label": "VISTT",
        "value": "VISTT",
        "country": "Virgin Islands, U.S."
    },
    {
        "label": "VISTX",
        "value": "VISTX",
        "country": "Virgin Islands, U.S."
    },
    {
        "label": "VNCLI",
        "value": "VNCLI",
        "country": "Viet Nam"
    },
    {
        "label": "VNCLN",
        "value": "VNCLN",
        "country": "Viet Nam"
    },
    {
        "label": "VNDAD",
        "value": "VNDAD",
        "country": "Viet Nam"
    },
    {
        "label": "VNHPH",
        "value": "VNHPH",
        "country": "Viet Nam"
    },
    {
        "label": "VNNHA",
        "value": "VNNHA",
        "country": "Viet Nam"
    },
    {
        "label": "VNPHG",
        "value": "VNPHG",
        "country": "Viet Nam"
    },
    {
        "label": "VNSGN",
        "value": "VNSGN",
        "country": "Viet Nam"
    },
    {
        "label": "VNSTD",
        "value": "VNSTD",
        "country": "Viet Nam"
    },
    {
        "label": "VNTOT",
        "value": "VNTOT",
        "country": "Viet Nam"
    },
    {
        "label": "VNTTD",
        "value": "VNTTD",
        "country": "Viet Nam"
    },
    {
        "label": "VNUIH",
        "value": "VNUIH",
        "country": "Viet Nam"
    },
    {
        "label": "VNVIC",
        "value": "VNVIC",
        "country": "Viet Nam"
    },
    {
        "label": "VNVUT",
        "value": "VNVUT",
        "country": "Viet Nam"
    },
    {
        "label": "VUSON",
        "value": "VUSON",
        "country": "Vanuatu"
    },
    {
        "label": "VUVLI",
        "value": "VUVLI",
        "country": "Vanuatu"
    },
    {
        "label": "WSAPW",
        "value": "WSAPW",
        "country": "Samoa"
    },
    {
        "label": "YEADE",
        "value": "YEADE",
        "country": "Yemen"
    },
    {
        "label": "YEHOD",
        "value": "YEHOD",
        "country": "Yemen"
    },
    {
        "label": "YELAD",
        "value": "YELAD",
        "country": "Yemen"
    },
    {
        "label": "YEMKX",
        "value": "YEMKX",
        "country": "Yemen"
    },
    {
        "label": "YTLON",
        "value": "YTLON",
        "country": "Mayotte"
    },
    {
        "label": "ZABFN",
        "value": "ZABFN",
        "country": "South Africa"
    },
    {
        "label": "ZACPT",
        "value": "ZACPT",
        "country": "South Africa"
    },
    {
        "label": "ZADUR",
        "value": "ZADUR",
        "country": "South Africa"
    },
    {
        "label": "ZAELS",
        "value": "ZAELS",
        "country": "South Africa"
    },
    {
        "label": "ZAJNB",
        "value": "ZAJNB",
        "country": "South Africa"
    },
    {
        "label": "ZAPLZ",
        "value": "ZAPLZ",
        "country": "South Africa"
    },
    {
        "label": "ZAPRY",
        "value": "ZAPRY",
        "country": "South Africa"
    },
    {
        "label": "ZARCB",
        "value": "ZARCB",
        "country": "South Africa"
    },
    {
        "label": "ZAZBA",
        "value": "ZAZBA",
        "country": "South Africa"
    },
    {
        "label": "ZMLUN",
        "value": "ZMLUN",
        "country": "Zambia"
    },
    {
        "label": "ZWBUQ",
        "value": "ZWBUQ",
        "country": "Zimbabwe"
    },
    {
        "label": "ZWHRE",
        "value": "ZWHRE",
        "country": "Zimbabwe"
    },
    {
        "label": "ZWUTA",
        "value": "ZWUTA",
        "country": "Zimbabwe"
    }
]