import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export default class DeliveryExceptionPartners extends Component {
  render() {
    return (
<div className=" w-100 h-100">
        <div className="container">
          <div className="row pt-5">
            <Link class="btn btn-back" to="/"><i class="fa fa-long-arrow-left pr-3"> </i> Go Back</Link>
          </div>
          <hr />
          <div className="main-contain-style ">
            <div>
              <h1 className=" main-contain-title">Delivery Exception Partners</h1>
              <p className="main-contain-description">Every year hundreds of millions go missing lost or are extensively delayed. When this happens the recipient is required to contact the originating postal organisation at the point of shipment to make enquiries regarding the whereabout of the missing lost or delayed parcel. This process takes time and is unpleasantly lengthy. It is surprising that with so much money being invested into the postal and parcel delivery industry globally the process of handling enquiries relating to delivery exception is still based on policy implemented long time ago.</p>
              <p className="main-contain-description">The origin of the current method of dealing with delivery exception across delivery borders and partners can be traced back to the agreement drafted amongst members of the UPU Universal Postal Union industry policy at a time when Ecommerce and global trade have not scaled.</p>
              <p className="main-contain-description">The dynamic nature of current global trade and the speed of parcel delivery across borders makes it difficult to satisfy customers especially when dealing with delayed, missing, or lost orders in transit. Globally from the first mile to the last mile in the delivery process no courier service provider is 100% self-reliant. Couriers depend on or collaborate with other couriers and partners to complete a customer delivery request so when a parcel in transit goes missing a swift, efficient and effective process is necessary to avoid losing customers.</p>
              <p className="main-contain-description">To solve this problem AddressDynamic engages with postal and delivery organisations as Delivery Exception Partners. What this means is that if a customers parcel is extensively delayed, goes missing or is lost outside of our delivery partners’ boarders or jurisdiction AddressDynamic can contact the carrier handling the parcel at the point when it was lost and undertake investigation on behalf of the originating courier who is our delivery exception partner.</p>
              <p className="main-contain-description">When the investigation is completed AddressDynamic will forward the outcome of the investigation to our delivery exception partner who will in turn contact their customer and advise them of the outcome. This saves time and is inexpensive for couriers and their customers and prevent disgruntled customers.</p>
            </div>
            <div className="pt-3">
              <Link to="/signup" className="description-text "> <span>Become a Delivery Exception Partner</span></Link>
            </div>
            <div className="pt-3">
              <Link to="/signup" className="description-text "> <span>Request more information</span></Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
