import React, { Component } from 'react'
import aeroplaneImage from '../../assets/clarity_plane-line.png'
import shipLineImage from '../../assets/majesticons_ship-line.png'
import roadFreightImage from '../../assets/material-symbols-light_local-shipping-outline-rounded.png'
import errandsImage from '../../assets/errands.svg'
import { withRouter } from 'react-router-dom';

import integrationImg from '../../assets/integartion.svg'
import scalableImg from '../../assets/scable-icon.svg'
import analytics from '../../assets/analytics-icon.svg'
import automatedImg from '../../assets/automated-icon.svg'
import realtimeImg from '../../assets/real-time-icon.svg'
import transportLogisticsImg from '../../assets/transport-logistics-products.webp'
import effortlessImg from '../../assets/effortless-img.webp'
import { Link } from 'react-router-dom';
import SpecialServicesCarousel from './SpecialServicesCarousel'

class ServicesSection extends Component {

    render() {
        return (
            <>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='freight-section'>
                                <Link to={'/air-freight-services'} className='freight-area'>
                                    <img height={45} width={45} alt="aeroplane.img" src={aeroplaneImage}></img>
                                    <div>
                                        <h6>Air Freight</h6>
                                        <p>Fulfilled & Shipped Fast</p>
                                    </div>
                                </Link>
                                <Link to='/sea-freight-services' className='freight-area'>
                                    <img height={45} width={45} alt="ship.img" src={shipLineImage}></img>
                                    <div>
                                        <h6>Sea Freight</h6>
                                        <p>Delivery and Relocation</p>
                                    </div>
                                </Link>
                                <Link to='/road-freight-services' className='freight-area'>
                                    <img height={45} width={45} alt="road.img" src={roadFreightImage}></img>
                                    <div>
                                        <h6>Road Freight</h6>
                                        <p>Shipping & Delivery</p>
                                    </div>
                                </Link>
                                <Link to='/errands-services' className='freight-area'>
                                    <img height={45} width={45} alt="rail.img" src={errandsImage}></img>
                                    <div>
                                        <h6>Errands</h6>
                                        <p>Shipping & Errands</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container about-company-section">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className='manage-heading'>Manage Domestic and International Freight From Origin to Destination </h2>
                            <h2 className='for-career'>For carriers, shippers, forwarders and agents of all shapes, sizes and locations</h2>
                            <p>POSTKODES is a cutting-edge Software-as-a-Service (SaaS) company specializing in providing comprehensive logistics management solutions. Our platform is designed to optimize, streamline, and enhance every aspect of your logistics operations, ensuring efficiency, transparency, and cost-effectiveness.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-12'><h2>About Postkodes Shipment Tracking & Delivery</h2></div>
                        <div className="col-md-8">
                            <p>Streamline your logistics seamlessly across all modes and borders, from start to finish.</p>
                            <p>Experience a free and secure supply chain solution designed for forwarders, couriers, NVOCCs, shippers, and importers worldwide.</p>
                            <p>Our free SaaS platform is tailored for service providers and industry participants involved in:</p>
                            <ul>
                                <li>Freight Forwarding</li>
                                <li>Cross Border E-commerce</li>
                                <li>Last Mile Delivery</li>
                                <li>Transport</li>
                                <li>Parcel Services</li>
                            </ul>
                            <p>POSTKODES is built with powerful functionalities that increase productivity. Our extensive features improve efficiency and support growth for our customers regardless of size and location.</p>
                        </div>

                        <div className="col-md-4">
                            <img src={transportLogisticsImg} alt="Transport-Logistics" className="img-fluid" />
                        </div>
                    </div>
                </div>

                <section className="special-qualities">
                    <div className="container">
                        <div className='row'>
                            <div className='col-md-12'>
                                <h2>What Makes Us Special As Global Parcel Delivery & Tracking Company</h2>
                                <p className="text-muted">As a global parcel delivery and tracking company is our commitment to speed, reliability, and customer satisfaction. We provide real-time tracking, ensuring you always know the status of your parcels. Our extensive global network guarantees swift deliveries to any destination. With competitive pricing and exceptional customer support, we make international shipping seamless and stress-free. Choose us for a superior parcel delivery experience that you can trust.</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='special-goal-row'>
                                    <div className='spacial-goal-col'>
                                        <div className='spacial-goal'>
                                            <h6>
                                                <img src={realtimeImg} alt='Real time' />
                                                Real-Time Tracking</h6>
                                            <p>Monitor your shipments in real-time with our advanced GPS and tracking systems. Always stay informed about the exact location and status of your goods.</p>
                                        </div>
                                        <div className='spacial-goal'>
                                            <h6>
                                                <img src={automatedImg} alt='Automated' />
                                                Automated Workflow Management</h6>
                                            <p>Simplify your logistics processes with our automated workflow tools. From pickup to final delivery with automated Proof Of Delivery (POD), our platform handles it all seamlessly and paperless.</p>
                                        </div>
                                        <div className='spacial-goal'>
                                            <h6>
                                                <img src={analytics} alt='Analytics' />
                                                Analytics & Reporting</h6>
                                            <p>Make data-driven decisions with our robust analytics and reporting features. Gain insights into your logistics operations, identify bottlenecks, and optimize performance.</p>
                                        </div>
                                        <div className='spacial-goal'>
                                            <h6>
                                                <img src={scalableImg} alt='Scalable' />
                                                Scalable Solutions</h6>
                                            <p>Whether you're a small business or a large enterprise, our scalable solutions are designed to grow with your needs. Customize our platform to suit your unique requirements.</p>
                                        </div>
                                        <div className='spacial-goal'>
                                            <h6>
                                                <img src={integrationImg} alt='Integration' />
                                                Integration Capabilities</h6>
                                            <p>Easily integrate our platform with your existing systems and tools. Our flexible API ensures smooth data exchange and interoperability.</p>
                                        </div>
                                    </div>
                                    <img className='special-goal-banner' src={effortlessImg} alt="Effortless" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                <SpecialServicesCarousel />

                <section className="reach-destination-section">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-8'>
                                <h2>Innovative Technology</h2>
                                <p>Leveraging the latest technologies, we continuously innovate to stay ahead of the curve and provide our clients with state-of-the-art logistics solutions.</p>
                            </div>
                            <div className='col-md-4 d-flex align-items-center justify-content-centerd'>
                                <Link to='/verifyEmail' className="btn btn-danger">Register Now &rarr;</Link>
                            </div>
                        </div>
                    </div>
                </section></>
        )
    }
}
export default withRouter(ServicesSection)