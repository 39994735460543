import moment from "moment";
import React, { Component } from "react";
// import './PrintInvoice.css';

export default class PrintFileAdhoc extends Component {
  state = {
    userInfo: {},
  };
  async componentDidMount() {
    const data = await localStorage.getItem("auth");
    this.setState({ userInfo: JSON.parse(data) });
  }
  render() {
    const info = this.props.format;
    const Allinfo = this.props.invoice;
    const userInfo = this.state.userInfo;
    return (
      <div className="section1 row">
        <div className="col-12 margin_005">
          <div className="borderTop"></div>
          <div className="innerContent">
            <div className="margin_30">
              <div className="cls_002">
                <span className="cls_002">Smyna Pty Ltd</span>
              </div>
              <div className="cls_003">
                <span className="cls_003">Merrylands</span>
              </div>
              <div className="cls_003">
                <span className="cls_003">NSW Australia</span>
              </div>
              {/* <div className="cls_003">
                                <span className="cls_003">Tel: 0410902852</span>
                            </div> */}
            </div>
            <div className="row margin_30">
              <div className="col-6">
                <div className="cls_004">
                  <span className="cls_004">Invoice</span>
                </div>
                <div className="cls_005">
                  <span className="cls_005">
                    Created on {moment().format("L")}
                    {` `} {moment().format("LT")}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              {Allinfo.receiver_name === "receiver_name" && (
                <div div className="col-4">
                  <span className="cls_006">Invoiced To</span>
                  <span className="cls_003">{Allinfo.receiver_name}</span>
                  <span className="cls_003">{Allinfo.receiver_address}</span>
                </div>
              )}
              {Allinfo.name && (
                <div div className="col-4">
                  <span className="cls_006">Invoice name</span>
                  <span className="cls_003">{Allinfo.name}</span>
                </div>
              )}
              {Allinfo.quantity && (
                <div div className="col-4">
                  <span className="cls_006">Quantity of box items</span>
                  <span className="cls_003">{Allinfo.quantity}</span>
                </div>
              )}
            </div>
            <div className="row">
              {Allinfo.status && (
                <div div className="col-4">
                  <span className="cls_006">Status</span>
                  <span className="cls_003">{Allinfo.status}</span>
                </div>
              )}
              {Allinfo.weight && (
                <div div className="col-4">
                  <span className="cls_006">Weight</span>
                  <span className="cls_003">{Allinfo.weight}</span>
                </div>
              )}
              {Allinfo.package_type && (
                <div div className="col-4">
                  <span className="cls_006">Package Type</span>
                  <span className="cls_003">{Allinfo.package_type}</span>
                </div>
              )}
            </div>
            <div className="row">
              {Allinfo.amount_charged && (
                <div div className="col-4">
                  <span className="cls_006">Amount Charged</span>
                  <span className="cls_003">{Allinfo.amount_charged}</span>
                </div>
              )}
              {Allinfo.vat_percentage && (
                <div div className="col-4">
                  <span className="cls_006">GST / VAT Percentage</span>
                  <span className="cls_003">{Allinfo.vat_percentage}</span>
                </div>
              )}
              {Allinfo.customer_ref_no && (
                <div div className="col-4">
                  <span className="cls_006">Customer ref/order no.</span>
                  <span className="cls_003">{Allinfo.customer_ref_no}</span>
                </div>
              )}
            </div>
            <div className="row">
              {Allinfo.package_value && (
                <div div className="col-4">
                  <span className="cls_006">Package Value</span>
                  <span className="cls_003">{Allinfo.package_value}</span>
                </div>
              )}
              {Allinfo.shipper_name && (
                <div div className="col-4">
                  <span className="cls_006">Shipper Name</span>
                  <span className="cls_003">{Allinfo.shipper_name}</span>
                </div>
              )}
              {Allinfo.shipper_email && (
                <div div className="col-4">
                  <span className="cls_006">Shipper Email</span>
                  <span className="cls_003">{Allinfo.shipper_email}</span>
                </div>
              )}
            </div>
            <div className="row">
              {Allinfo.shipper_phone && (
                <div div className="col-4">
                  <span className="cls_006">Shipper Phone</span>
                  <span className="cls_003">{Allinfo.shipper_phone}</span>
                </div>
              )}
              {Allinfo.shipper_address && (
                <div div className="col-4">
                  <span className="cls_006">Shipper Address</span>
                  <span className="cls_003">{Allinfo.shipper_address}</span>
                </div>
              )}
              {Allinfo.receiver_name && (
                <div div className="col-4">
                  <span className="cls_006">Receiver Name</span>
                  <span className="cls_003">{Allinfo.receiver_name}</span>
                </div>
              )}
            </div>
            <div className="row">
              {Allinfo.receiver_business_name && (
                <div div className="col-4">
                  <span className="cls_006">Receiver Business Name</span>
                  <span className="cls_003">
                    {Allinfo.receiver_business_name}
                  </span>
                </div>
              )}
              {Allinfo.receiver_email && (
                <div div className="col-4">
                  <span className="cls_006">Receiver Email</span>
                  <span className="cls_003">{Allinfo.receiver_email}</span>
                </div>
              )}
              {Allinfo.receiver_phone && (
                <div div className="col-4">
                  <span className="cls_006">Receiver Phone Number</span>
                  <span className="cls_003">{Allinfo.receiver_phone}</span>
                </div>
              )}
            </div>
            <div className="row">
              {Allinfo.other_email && (
                <div div className="col-4">
                  <span className="cls_006">Other Email</span>
                  <span className="cls_003">{Allinfo.other_email}</span>
                </div>
              )}
              {Allinfo.other_phone && (
                <div div className="col-4">
                  <span className="cls_006">Other Phone Number</span>
                  <span className="cls_003">{Allinfo.other_phone}</span>
                </div>
              )}
              {Allinfo.shipment_description && (
                <div div className="col-4">
                  <span className="cls_006">Shipment Description</span>
                  <span className="cls_003">
                    {Allinfo.shipment_description}
                  </span>
                </div>
              )}
            </div>
            <div className="row">
              {Allinfo.origin && (
                <div div className="col-4">
                  <span className="cls_006">Origin</span>
                  <span className="cls_003">{Allinfo.origin}</span>
                </div>
              )}
              {Allinfo.destination && (
                <div div className="col-4">
                  <span className="cls_006">Destination</span>
                  <span className="cls_003">{Allinfo.destination}</span>
                </div>
              )}
              {Allinfo.origin_country && (
                <div div className="col-4">
                  <span className="cls_006">Origin Country</span>
                  <span className="cls_003">{Allinfo.origin_country}</span>
                </div>
              )}
            </div>
            <div className="row">
              {Allinfo.destination_country && (
                <div div className="col-4">
                  <span className="cls_006">Destination Country</span>
                  <span className="cls_003">{Allinfo.destination_country}</span>
                </div>
              )}
              {Allinfo.carrier_name && (
                <div div className="col-4">
                  <span className="cls_006">Carrier Name</span>
                  <span className="cls_003">{Allinfo.carrier_name}</span>
                </div>
              )}
              {Allinfo.pickup && (
                <div div className="col-4">
                  <span className="cls_006">Pick Up</span>
                  <span className="cls_003">{Allinfo.pickup}</span>
                </div>
              )}
            </div>
            <div className="row">
              {Allinfo.delivery_address && (
                <div div className="col-4">
                  <span className="cls_006">Delivery Address</span>
                  <span className="cls_003">{Allinfo.delivery_address}</span>
                </div>
              )}
              {Allinfo.delivery_instruction && (
                <div div className="col-4">
                  <span className="cls_006">Delivery Instruction</span>
                  <span className="cls_003">
                    {Allinfo.delivery_instruction}
                  </span>
                </div>
              )}
              {Allinfo.comment && (
                <div div className="col-4">
                  <span className="cls_006">Comment</span>
                  <span className="cls_003">{Allinfo.comment}</span>
                </div>
              )}
            </div>
            {/* <div className="row">
                            <div className="col-4">
                                <span className="cls_006">Quantity</span>
                                <span className="cls_003">{info.shipped_items ? info.shipped_items : Allinfo.quantity}</span>
                            </div>
                            <div className="col-4">
                                <span className="cls_006">Weight</span>
                                <span className="cls_003">{info.weight ? info.weight : Allinfo.weight}</span>
                            </div>
                        </div> */}
            <div className="border_002" />
            <div className="row">
              <table className="table_002">
                <tr>
                  <th>
                    <span class="cls_008">Item</span>
                  </th>
                  <th>
                    <span class="cls_008">Description</span>
                  </th>
                  <th style={{ textAlign: "end" }}>
                    <span class="cls_008">Qty</span>
                  </th>
                  <th style={{ textAlign: "end" }}>
                    <span class="cls_008">Unit Price</span>
                  </th>
                  <th style={{ textAlign: "end" }}>
                    <span class="cls_008">Total Price</span>
                  </th>
                </tr>
                <tr className="border_005">
                  <td>
                    <span class="cls_007">Destination charges</span>
                  </td>
                  <td>
                    <span class="cls_003">Inbound Air Frieght Fee</span>
                  </td>
                  <td style={{ textAlign: "end" }}>
                    <span class="cls_003">
                      {Allinfo.quantity ? Allinfo.quantity : 0}
                    </span>
                  </td>
                  <td style={{ textAlign: "end" }}>
                    <span class="cls_003">
                      ${Allinfo.amount_charged ? Allinfo.amount_charged : 0}
                    </span>
                  </td>
                  <td style={{ textAlign: "end" }}>
                    <span class="cls_003">
                      ${Allinfo.amount_charged ? Allinfo.amount_charged : 0}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <span class="cls_009">Notes:</span>
                    <span class="cls_010"> {Allinfo.comment} </span>
                  </td>
                  <td style={{ textAlign: "end" }}>
                    <span class="cls_011">Subtotal</span>
                  </td>
                  <td style={{ textAlign: "end" }}>
                    <span class="cls_012">
                      ${Allinfo.amount_charged ? Allinfo.amount_charged : 0}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  {/* <td style={{ textAlign: "end" }}>
                                        <span class="cls_011">GST/VAT</span>
                                    </td>
                                    <td style={{ textAlign: "end" }}>
                                        <span class="cls_013">${(Allinfo.amount_charged * Allinfo.vat_percentage) / 100}</span>
                                    </td> */}
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ textAlign: "end" }}>
                    <span class="cls_014">
                      ${Allinfo.amount_charged ? Allinfo.amount_charged : 0}
                    </span>
                  </td>
                  {/* <td style={{ textAlign: "end" }}>
                                        <span class="cls_014">${Number(Allinfo.amount_charged) + (Allinfo.amount_charged * Allinfo.vat_percentage) / 100}</span>
                                    </td> */}
                </tr>
              </table>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="cls_010">
                  <span className="cls_006">Payment details</span>
                </div>
                <div className="cls_010">
                  <span className="cls_010">
                    Bank Name: National Australia Bank
                  </span>
                </div>
                <div className="cls_010">
                  <span className="cls_010">BIC/SWIFT Code: NATAAU3303M</span>
                </div>
                <div className="cls_010">
                  <span className="cls_010">Account Name: Smyna Pty Ltd</span>
                </div>
                <div className="cls_010">
                  <span className="cls_010">BSB: 082112</span>
                </div>
                <div className="cls_010">
                  <span className="cls_010">Account No. 298888224</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
