
const organizationSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Postkodes",
    "url": "https://postkodes.com/",
    "logo": "https://postkodes.com/static/media/new_logo.f69273a4.png",
    "sameAs": [
        "https://www.facebook.com/getaddressdynamic/",
        "https://www.instagram.com/postkodes/"
    ]
};

export default organizationSchema;
