import React, { Component } from 'react'
import { Button, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import './Signup.css'
import logo from '../assets/4.png'
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipLoader } from "react-spinners";
import 'react-phone-number-input/style.css'
import { Link } from 'react-router-dom';
import { sendEmailOtp } from '../api/Form';
import { verifyEmailOtp } from '../api/Form';
import ReCAPTCHA from "react-google-recaptcha";
const { REACT_APP_RECAPTCHA_SITEKEY } = process.env;

const SignupSchema = Yup.object().shape({
	// phoneNumber: Yup.string()
	// 	.required('Required'),
	email: Yup.string()
		.email('Invalid email address')
		.required('Email is required'),
});

export default class VerifyPhone extends Component {
	state = {
		phoneNumber: '',
		otp: '',
		otpSent: false,
		otpResent: false,
		timer: 0,
		activeResend: false,
		timerdisplay: true,
		loading: false,
		captchaIsDone: true,
		hideCatchaButton: true
	}

	submitHandler = (values) => {
		this.setState({ loading: true });
		if (this.state.otpSent && values.otp && this.state.otpResent) {
			verifyEmailOtp({ email: values.email, otp: values.otp })
				.then((response) => {
					this.setState({ loading: false });
					toast.success(response.data.message, {
						position: toast.POSITION.TOP_RIGHT
					});
					this.props.history.push({ pathname: '/signupform', state: { email: values.email } })
				}).catch((err) => {
					toast.error(err.response.data.data.otp || err.response.data.message, {
						position: toast.POSITION.TOP_RIGHT
					});
					this.setState({ loading: false });
					this.setErrors({ username: err.response.data.message })
				})
		} else {
			if (!this.state.otpSent || this.state.otpResent) {
				sendEmailOtp({ email: values.email })
					.then((response) => {
						this.setState({ otpSent: true, loading: false, hideCatchaButton: false });
						toast.success(response.data.message, {
							position: toast.POSITION.TOP_RIGHT
						});
						this.resendOTP();
						this.resendEnableTimeout();
					}).catch((err) => {
						if (err.response.status === 309) {
							this.props.history.push({ pathname: '/signupform', state: { email: values.email } })
						} else {
							toast.error(err.response.data.data.email || err.response.data.message, {
								position: toast.POSITION.TOP_RIGHT
							});
							this.setState({ loading: false });
							this.setErrors({ username: err.response.data.message })
						}
					})
			} else {
				toast.error("Please enter otp", {
					position: toast.POSITION.TOP_RIGHT
				});
				this.setState({ loading: false });
			}
		}

	}

	resendOTP = () => {
		this.setState({ otpResent: true })
	}

	resendEnableTimeout = () => {
		this.setState({ timer: 60 });
		const time = setInterval(() => {
			this.setState(s => ({ timer: s.timer - 1 }), () => {
				if (this.state.timer === 0) {
					clearInterval(time);
					this.setState({ activeResend: true, timerdisplay: false });
				}
			});
		}, 1000)
	}

	captchaHandler = () => {
		console.log("change");
		this.setState({
			captchaIsDone: false
		})
	}

	render() {
		return (
			<Formik
				initialValues={{ email: this.state.phoneNumber, otp: this.state.otp }}
				validationSchema={SignupSchema}
				onSubmit={(values, { setErrors }) => {
					this.setErrors = setErrors;
					this.submitHandler(values);
				}}
			>{({
				values,
				errors,
				touched,
				handleChange,
				handleSubmit,
				handleBlur,
				setFieldValue,
			}) => (
				<div className=" w-100 h-100 overflow-hidden">
					<div className="row">
						<div className="col-4 d-flex justify-content-center align-items-center">
							<div className="d-none d-md-block d-lg-block d-xl-block login-bg-style">
								<div className="d-flex justify-content-center">
									<Link to="/">
										<img src={logo} className="img-fluid" alt="" />
									</Link>
								</div>
								<h1 className="pb-3 pt-3 text-center login-title">Postkodes</h1>
								<p className="text-justify verify-description">Revolutionary parcel and product tracking platform using
									the latest IoT technology. Giving your business and your customers improved efficiency and
									productivity at significantly reduced cost when compared to your current system.</p>
							</div>
						</div>
						<div
							className="col-12 col-md-4 col-lg-4 col-xl-4 col-offset-none offset-sm-none offset-md-2 offset-lg-2 offset-xl-2 text-area-size  ">
							<div className="container">
								<div className="d-flex justify-content-center d-block  d-md-none d-lg-none d-xl-none pb-3">
									<a href="/">
										<img src={logo} alt="" />
									</a>
								</div>
								<div className="d-none d-md-block d-lg-block d-xl-block">
									<h1 className="title-style">Verify Email</h1>
								</div>
								<div className="d-block d-sm-block d-md-none d-lg-none d-xl-none">
									<h1 className="title-style pb-0 text-left">Verify Email</h1>
									<p className="pb-2 description-text">First we need to verify your Email</p>
								</div>
								<Form>
									<FormGroup className="pb-2 has-feedback has-feedback-left ">
										<Label for="exampleEmail">Email</Label>
										<div class="input-group mb-3">
											<div class="input-group-prepend">
												<span class="input-group-text" id="basic-addon1"><i class="fa fa-envelope icon-style"
													aria-hidden="true"></i></span>
											</div>
											<Input
												type="email"
												invalid={errors.email && touched.email}
												name="email"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.email}
												placeholder="Enter Email Address "
											/>
											<FormFeedback>
												{errors.email && touched.email && errors.email}
											</FormFeedback>
										</div>
									</FormGroup>
									{this.state.otpSent &&
										<FormGroup className="pb-2 has-feedback has-feedback-left ">
											<Label for="exampleEmail">OTP</Label>
											<div class="input-group mb-3">
												<div class="input-group-prepend">
													<span class="input-group-text" id="basic-addon1"><i class="fa fa-key icon-style"
														aria-hidden="true"></i></span>
												</div>
												<Input type="number"
													invalid={errors.otp && touched.otp}
													name="otp" onChange={handleChange} onBlur={handleBlur} value={values.otp}
													placeholder="Enter OTP" />
												<FormFeedback>
													{errors.otp && touched.otp && errors.otp}
												</FormFeedback>
											</div>
										</FormGroup>
									}
									{
										this.state.hideCatchaButton &&
										<ReCAPTCHA sitekey={REACT_APP_RECAPTCHA_SITEKEY} onChange={this.captchaHandler} />
									}
									<div className="pt-3">
										<Button disabled={this.state.captchaIsDone} className="btn btn-login btn-block" onClick={handleSubmit}>
											{this.state.loading ?
												< ClipLoader
													size={15}
													color={"#123abc"}
													loading={true}
												/> : 'Verify Email'
											}</Button>
									</div>
									{this.state.otpSent && this.state.timer === 0 ?
										<div className="pt-4 text-center">
											<button type="button" class="btn btn-link" disabled={!this.state.activeResend}
												onClick={handleSubmit}>Resend
											</button>
										</div>
										: this.state.otpSent &&
										<div className="pt-4 text-center">
											<button type="button" class="btn btn-link" disabled={!this.state.activeResend}
												onClick={handleSubmit}>Resend in {this.state.timer}</button>
										</div>

									}
								</Form>
							</div>
							<div className="pt-4 d-flex justify-content-center text-dark">
								Already have an account? <Link to="/login" className="signin-style"> Sign In</Link>
							</div>
						</div>
						<div className="col-offset-none offset-sm-none offset-md-2 offset-lg-2 offset-xl-2"></div>
					</div>
				</div>
			)}
			</Formik>
		)
	}
}
