import React, { useRef } from 'react';
import './PrintManifest.css';
import Barcode from 'react-barcode';
import { useReactToPrint } from 'react-to-print';
import { calculateCBM } from '../../utils/businessLogic';


export default function PrintManifest(props) {
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const calculateCBMForManifest = (index) => {
        return calculateCBM(Number(props?.shipments[index]?.length),
            Number(props?.shipments[index]?.width),
            Number(props?.shipments[index]?.height),
            Number(props?.shipments[index]?.quantity))
    }



    return (
        <div>
            <div className="justify-content-center">
                <div className="section2 row" ref={componentRef}>
                    <div className="col-12">
                        <div className="innerContent">
                            {props.flight_details ? <table className="fullwidth-table">
                                <tr>
                                    <td><b>{props?.flight_details?.mode_of_transport === "Sea" ? "MASTER BOL" : props?.flight_details?.mode_of_transport === "Air" ? "MASTER AWB" : "Courier Manifest No."}</b> - {props.flight_details.masterairway_number}</td>
                                    <td><b>{props?.flight_details?.mode_of_transport === "Sea" ? "Voyage" : props?.flight_details?.mode_of_transport === "Air" ? "Flight No." : "Courier Manifest No."}</b> {props.flight_details.flight_number}</td>
                                    <td><b>{props?.flight_details?.mode_of_transport === "Sea" ? "Sealine" : props?.flight_details?.mode_of_transport === "Air" ? "Airline" : "Courier name"}</b> {props.flight_details.airline}</td>
                                    <td><b>ETD</b> {props.flight_details.ETD}</td>
                                    <td><b>Total No. of Shipment</b> {props.shipments.length}</td>
                                    <td><b>Total Value</b> {props.shipments.map(datum => datum.packageValue).reduce((a, b) => parseInt(a) + parseInt(b))}</td>
                                </tr>
                                <tr>
                                    <td><b>Total Pieces</b> {props.shipments.map(datum => datum.quantity).reduce((a, b) => parseInt(a) + parseInt(b))}</td>
                                    <td><b>Departure</b> {props.flight_details.origin + ',' + props.flight_details.from_country}</td>
                                    <td><b>Destination</b> {props.flight_details.destination + ',' + props.flight_details.to_country}</td>
                                    <td><b>ETA</b> {props.flight_details.ETA}</td>
                                    <td><b>Total Weight</b> {props.shipments.map(datum => datum.weight).reduce((a, b) => parseInt(a) + parseInt(b))} </td>
                                    <td><b>Remarks</b></td>
                                </tr>
                            </table> : null}

                            <table className="fullwidth-table mt-5">
                                <tr>
                                    <th>{props?.shipments[0]?.modeOfTransport === "Sea" ? "HBL" : "Tracking Number"}</th>
                                    <th>Tracking Number in Bardcode</th>
                                    <th>Pieces</th>
                                    <th>Weight</th>
                                    <th>Shipper Name & Address</th>
                                    <th>Receiver Name & Address</th>
                                    <th>CBM</th>
                                    <th>Description</th>
                                    <th>{props?.shipments[0]?.modeOfTransport === "Sea" ? "POL" : "Origin"}</th>
                                    <th>{props?.shipments[0]?.modeOfTransport === "Sea" ? "POD" : "Destination"}</th>
                                    <th>Value</th>
                                </tr>
                                {props.shipments.map((p, index) => {
                                    return (
                                        <tr>
                                            <td>{p.refno}</td>
                                            <td><Barcode width={1} value={p.refno} height={70} fontSize={13} /></td>
                                            <td>{p.quantity}</td>
                                            <td>{p.weight}</td>
                                            <td>
                                                <span><b>{p.firstnameUsers} {p.lastnameUsers}</b></span>
                                                {p.addressUsers}
                                            </td>
                                            <td>
                                                <span><b>{p.receiverName}</b></span>
                                                {p.deliveryAddress}
                                            </td>
                                            <td>
                                                {calculateCBMForManifest(index)}
                                            </td>
                                            <td>{p.shipmentDescription}</td>
                                            <td>{p.origin}</td>
                                            <td>{p.destination}</td>
                                            <td>{p.packageCurrency + ' ' + p.packageValue}</td>
                                        </tr>
                                    )
                                })}
                            </table>
                        </div>
                    </div>
                    {/* <div className="col-2" /> */}
                </div>
            </div>
            <div className="d-flex justify-content-center" id="no-print" >
                <button className="btn btn-submit ml-2 mt-3" onClick={handlePrint}>Print this out!</button>
                <button className="btn btn-submit ml-2 mt-3" onClick={props.reset}>Print another</button>
            </div>
        </div >
    );
}
