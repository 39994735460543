import React, { Component } from 'react'
import { CardHeader, CardBody, FormGroup, Label, Input, Card, FormFeedback } from 'reactstrap';
import classnames from 'classnames';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { packageHandlers } from '../../api/Form';
import moment from 'moment';
import { trim } from '../../utils/utils';
import { ClipLoader } from "react-spinners";
import { callActivityApi } from '../../utils/saveUserActivity';


export default class OutGoingPackageHandler extends Component {
  state = {
    loading: true,
    packageDetailsModal: false,
    packageDetails: []
  }

  async componentDidMount() {
    callActivityApi({ 'pageName': 'Outgoing Package Handler', 'description': 'User has visited Outgoing package Screen' });
    const deviceAdCode = await localStorage.getItem('adCodes');
    const refno = this.props.location.state
    packageHandlers({ deviceAdCode, refno })
      .then(Response => {
        console.log(Response)
        this.setState({ packageDetails: Response.data.data, loading: false, packageDetailsModal: true })
      })
      .catch(err => {
        console.log(err)
      })
  }
  render() {
    const { packageDetails } = this.state
    return (
      <div className="mt-2 style">
        <Card>
          <CardHeader className="c-header">Package Handler</CardHeader>
          <CardBody>
            {this.state.loading === true ?
              <div className="d-flex justify-content-center">
                < ClipLoader
                  size={15}
                  color={"#123abc"}
                  loading={true}
                />
              </div>
              :
              <>
                {this.state.packageDetailsModal &&
                  <div className="d-flex justify-content-center track-card">
                    <Card className="dashboard-card-style track-card-style shadow mb-4">
                      <CardBody>
                        <div className="row">
                          <div className="col-6">
                            <div className="row">
                              <div className="col-6">
                                <h1 className="title-main-head-track">Ref No</h1>
                              </div>
                              <div className="col-6">
                                <h1 className="title-description-profile-track">{packageDetails[0].refno}</h1>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="row">
                              <div className="col-6">
                                <h1 className="title-main-head-track">Quantity</h1>
                              </div>
                              <div className="col-6">
                                <h1 className="title-description-profile-track">{packageDetails[0].quantity}</h1>
                              </div>
                            </div>
                          </div>
                          {packageDetails[0]?.mot || packageDetails[0]?.modeOfTransport && <div className="col-6">
                            <div className="row">
                              <div className="col-6">
                                <h1 className="title-main-head-track">MOT</h1>
                              </div>
                              <div className="col-6">
                                <h1 className="title-description-profile-track">{packageDetails[0]?.mot || packageDetails[0]?.modeOfTransport}</h1>
                              </div>
                            </div>
                          </div>}
                          <div className="col-6">
                            <div className="row">
                              <div className="col-6">
                                <h1 className="title-main-head-track">Trip Serial ID</h1>
                              </div>
                              <div className="col-6">
                                <h1 className="title-description-profile-track">{packageDetails[0].nfcserialno}</h1>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="row">
                              <div className="col-6">
                                <h1 className="title-main-head-track">Date And Time</h1>
                              </div>
                              <div className="col-6">
                                <h1 className="title-description-profile-track">{moment(packageDetails[0].date).format('MM-DD-YYYY h:mm A')}</h1>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                }
                <VerticalTimeline layout='1-column' className="overflow-auto pb-0 vt-style" >
                  {packageDetails.map(a => {
                    return (
                      <VerticalTimelineElement
                        className="vertical-timeline-element--work "
                        date={
                          <div>
                            <div className="row">
                              <div className="col-12">
                                <span className="pl-2 vertical-time-style" style={{ color: '#0000a0' }}>{moment(a.date).format('DD-MM-yy')}</span>
                              </div>
                              <div className="col-12 ">
                                <span className="pl-2 vertical-time-style" style={{ color: '#0000a0' }}>{moment(a.date).format("h:mm A")}</span>
                              </div>
                            </div>
                          </div>

                        }


                        iconStyle={{ background: '#fff', color: '#0000a0' }}
                      // icon={icon}
                      >
                        <Card className="shadow border-0">
                          <CardBody>
                            <div className="row ">
                              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <div className="row">
                                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <Label for="exampleEmail " className="pr-5 label-style-tracking">First Name</Label>
                                  </div>
                                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <Label for="exampleEmail" className="label-description" >{a.name}</Label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <div className="row">
                                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <Label for="exampleEmail " className="pr-5 label-style-tracking">Business Name</Label>
                                  </div>
                                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <Label for="exampleEmail" className="label-description" >{a.business}</Label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <div className="row">
                                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <Label for="exampleEmail " className="pr-5 label-style-tracking">Location</Label>
                                  </div>
                                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <Label for="exampleEmail" className="label-description" >{a.location}</Label>
                                  </div>
                                </div>
                              </div>

                              <div className="col-12 col-sm-6 col-md-6 col-lg-6  col-xl-6">
                                <div className="row">
                                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
                                    <Label for="exampleEmail " className="pr-5 label-style-tracking">Status</Label>
                                  </div>
                                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <span class="badge badge-pill badge-primary px-2 font-weight-light ">{a.trackingstatus}</span>
                                  </div>
                                </div>
                              </div>

                              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <div className="row">
                                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <Label for="exampleEmail " className="pr-5 label-style-tracking">Quantity</Label>
                                  </div>
                                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <Label for="exampleEmail" className="label-description" >{a.quantity}</Label>
                                  </div>
                                </div>
                              </div>

                              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <div className="row">
                                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <Label for="exampleEmail " className="pr-5 label-style-tracking">Comment</Label>
                                  </div>
                                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <Label for="exampleEmail" className="label-description" >{a.comment}</Label>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </CardBody>
                        </Card>
                      </VerticalTimelineElement>
                    );
                  })}
                </VerticalTimeline>
              </>}
          </CardBody>
        </Card>
      </div>
    )
  }
}
