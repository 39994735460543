import React, { Component } from 'react'
import { Card, CardBody, CardHeader, FormFeedback, FormGroup, Input, Label, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { image } from '../../api/URLS';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { checkScreenAccess, requestAccess, userProfile } from '../../api/Form'
import './profile.css'
import img1 from '../../assets/new_logo.png'
import { ClipLoader } from "react-spinners";
import edit from '../../assets/pencil.png';
import { updateUserProfile } from '../../api/Form';
import { sendOTP, verifyPhone } from '../../api/auth'
import classNames from 'classnames';
import PhoneInput from 'react-intl-tel-input';
import { isValidNumber } from 'libphonenumber-js';
import 'react-phone-input-2/lib/style.css'

const Schema = Yup.object().shape({
	firstname: Yup.string()
		.matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
		.required("First name is Required"),
	lastname: Yup.string()
		.matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
		.required("Last name is Required"),
	unitno: Yup.string().min(2, 'Min 2 characters')
		.max(11, 'Max 11 characters'),
	houseno: Yup.string()
		.required("House No. is Required"),
	streetname: Yup.string()
		.matches(/^[ A-Za-z0-9_@./#&+-]*$/, "Only alphabets are allowed for this field ")
		.required("Street name is Required"),
	suburb: Yup.string()
		.matches(/^[ A-Za-z0-9_@./#&+-]*$/, "Special characters are not allowed")
		.required("Required"),
	state: Yup.string()
		.matches(/^[ A-Za-z0-9_@./#&+-]*$/, "Only alphabets are allowed for this field ")
		.required("State is Required"),
	postcode: Yup.string()
		.required("Post code is Required"),
	country: Yup.string().required("Country is Required"),
	phone: Yup.string().required("Phone number is required"),
	addressdescription: Yup.string()
		.required("Address description is Required"),
});

const options = [
	{ value: 'Afghanistan', label: 'Afghanistan' },
	{ value: 'Aland Islands', label: 'Aland Islands' },
	{ value: 'Albania', label: 'Albania' },
	{ value: 'Algeria', label: 'Algeria' },
	{ value: 'American Samoa', label: 'American Samoa' },
	{ value: 'AndorrA', label: 'AndorrA' },
	{ value: 'Angola', label: 'Angola' },
	{ value: 'Anguilla', label: 'Anguilla' },
	{ value: 'Antarctica', label: 'Antarctica' },
	{ value: 'Antigua and Barbuda', label: 'Antigua and Barbuda' },
	{ value: 'Argentina', label: 'Argentina' },
	{ value: 'Armenia', label: 'Armenia' },
	{ value: 'Aruba', label: 'Aruba' },
	{ value: 'Australia', label: 'Australia' },
	{ value: 'Austria', label: 'Australia' },
	{ value: 'Azerbaijan', label: 'Azerbaijan' },
	{ value: 'Bahamas', label: 'Bahamas' },
	{ value: 'Bahrain', label: 'Bahrain' },
	{ value: 'Bangladesh', label: 'Bangladesh' },
	{ value: 'Barbados', label: 'Barbados' },
	{ value: 'Belarus', label: 'Belarus' },
	{ value: 'Belgium', label: 'Belgium' },
	{ value: 'Belize', label: 'Belize' },
	{ value: 'Benin', label: 'Benin' },
	{ value: 'Bermuda', label: 'Bermuda' },
	{ value: 'Bhutan', label: 'Bhutan' },
	{ value: 'Bolivia', label: 'Bolivia' },
	{ value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
	{ value: 'Botswana', label: 'Botswana' },
	{ value: 'Bouvet Island', label: 'Bouvet Island' },
	{ value: 'Brazil', label: 'Brazil' },
	{ value: 'British Indian Ocean Territory', label: 'British Indian Ocean Territory' },
	{ value: 'Brunei Darussalam', label: 'Brunei Darussalam' },
	{ value: 'Bulgaria', label: 'Bulgaria' },
	{ value: 'Burkina Faso', label: 'Burkina Faso' },
	{ value: 'Burundi', label: 'Burundi' },
	{ value: 'Cambodia', label: 'Cambodia' },
	{ value: 'Cameroon', label: 'Cameroon' },
	{ value: 'Canada', label: 'Canada' },
	{ value: 'Cape Verde', label: 'Cape Verde' },
	{ value: 'Cayman Islands', label: 'Cayman Islands' },
	{ value: 'Central African Republic', label: 'Central African Republic' },
	{ value: 'Chad', label: 'Chad' },
	{ value: 'Chile', label: 'Chile' },
	{ value: 'China', label: 'China' },
	{ value: 'Christmas Island', label: 'Christmas Island' },
	{ value: 'Cocos (Keeling) Islands', label: 'Cocos (Keeling) Islands' },
	{ value: 'Colombia', label: 'Colombia' },
	{ value: 'Comoros', label: 'Comoros' },
	{ value: 'Congo', label: 'Congo' },
	{ value: 'Congo, The Democratic Republic of the', label: 'Congo, The Democratic Republic of the' },
	{ value: 'Cook Islands', label: 'Cook Islands' },
	{ value: 'Costa Rica', label: 'Costa Rica' },
	{ value: "Cote DIvoire", label: "Cote D'Ivoire" },
	{ value: 'Croatia', label: 'Croatia' },
	{ value: 'Cuba', label: 'Cuba' },
	{ value: 'Cyprus', label: 'Cyprus' },
	{ value: 'Czech Republic', label: 'Czech Republic' },
	{ value: 'Denmark', label: 'Denmark' },
	{ value: 'Djibouti', label: 'Djibouti' },
	{ value: 'Dominica', label: 'Dominica' },
	{ value: 'Dominican Republic', label: 'Dominican Republic' },
	{ value: 'Ecuador', label: 'Ecuador' },
	{ value: 'Egypt', label: 'Egypt' },
	{ value: 'El Salvador', label: 'El Salvador' },
	{ value: 'Equatorial Guinea', label: 'Equatorial Guinea' },
	{ value: 'Eritrea', label: 'Eritrea' },
	{ value: 'Estonia', label: 'Estonia' },
	{ value: 'Ethiopia', label: 'Ethiopia' },
	{ value: 'Falkland Islands (Malvinas)', label: 'Falkland Islands (Malvinas)' },
	{ value: 'Faroe Islands', label: 'Faroe Islands' },
	{ value: 'Fiji', label: 'Fiji' },
	{ value: 'Finland', label: 'Finland' },
	{ value: 'France', label: 'France' },
	{ value: 'French Guiana', label: 'French Guiana' },
	{ value: 'French Polynesia', label: 'French Polynesia' },
	{ value: 'French Southern Territories', label: 'French Southern Territories' },
	{ value: 'Gabon', label: 'Gabon' },
	{ value: 'Gambia', label: 'Gambia' },
	{ value: 'Georgia', label: 'Georgia' },
	{ value: 'Germany', label: 'Germany' },
	{ value: 'Ghana', label: 'Ghana' },
	{ value: 'Gibraltar', label: 'Gibraltar' },
	{ value: 'Greece', label: 'Greece' },
	{ value: 'Greenland', label: 'Greenland' },
	{ value: 'Grenada', label: 'Grenada' },
	{ value: 'Guadeloupe', label: 'Guadeloupe' },
	{ value: 'Guam', label: 'Guam' },
	{ value: 'Guatemala', label: 'Guatemala' },
	{ value: 'Guernsey', label: 'Guernsey' },
	{ value: 'Guinea', label: 'Guinea' },
	{ value: 'Guinea-Bissau', label: 'Guinea-Bissau' },
	{ value: 'Guyana', label: 'Guyana' },
	{ value: 'Haiti', label: 'Haiti' },
	{ value: 'Heard Island and Mcdonald Islands', label: 'Heard Island and Mcdonald Islands' },
	{ value: 'Holy See (Vatican City State)', label: 'Holy See (Vatican City State)' },
	{ value: 'Honduras', label: 'Honduras' },
	{ value: 'Hong Kong', label: 'Hong Kong' },
	{ value: 'Hungary', label: 'Hungary' },
	{ value: 'Iceland', label: 'Iceland' },
	{ value: 'India', label: 'India' },
	{ value: 'Indonesia', label: 'Indonesia' },
	{ value: 'Iran, Islamic Republic Of', label: 'Iran, Islamic Republic Of' },
	{ value: 'Iraq', label: 'Iraq' },
	{ value: 'Ireland', label: 'Ireland' },
	{ value: 'Isle of Man', label: 'Isle of Man' },
	{ value: 'Israel', label: 'Israel' },
	{ value: 'Italy', label: 'Italy' },
	{ value: 'Jamaica', label: 'Jamaica' },
	{ value: 'Japan', label: 'Japan' },
	{ value: 'Jersey', label: 'Jersey' },
	{ value: 'Jordan', label: 'Jordan' },
	{ value: 'Kazakhstan', label: 'Kazakhstan' },
	{ value: 'Kenya', label: 'Kenya' },
	{ value: 'Kiribati', label: 'Kiribati' },
	{ value: "Korea, Democratic PeopleS Republic of", label: "Korea, Democratic People'S Republic of" },
	{ value: 'Korea, Republic of', label: 'Korea, Republic of' },
	{ value: 'Kuwait', label: 'Kuwait' },
	{ value: 'Kyrgyzstan', label: 'Kyrgyzstan' },
	{ value: "Lao PeopleS Democratic Republic", label: "Lao People'S Democratic Republic" },
	{ value: 'Latvia', label: 'Latvia' },
	{ value: 'Lebanon', label: 'Lebanon' },
	{ value: 'Lesotho', label: 'Lesotho' },
	{ value: 'Liberia', label: 'Liberia' },
	{ value: 'Libyan Arab Jamahiriya', label: 'Libyan Arab Jamahiriya' },
	{ value: 'Liechtenstein', label: 'Liechtenstein' },
	{ value: 'Lithuania', label: 'Lithuania' },
	{ value: 'Luxembourg', label: 'Luxembourg' },
	{ value: 'Macao', label: 'Macao' },
	{ value: 'Macedonia, The Former Yugoslav Republic of', label: 'Macedonia, The Former Yugoslav Republic of' },
	{ value: 'Madagascar', label: 'Madagascar' },
	{ value: 'Malawi', label: 'Malawi' },
	{ value: 'Malaysia', label: 'Malaysia' },
	{ value: 'Maldives', label: 'Maldives' },
	{ value: 'Mali', label: 'Mali' },
	{ value: 'Malta', label: 'Malta' },
	{ value: 'Marshall Islands', label: 'Marshall Islands' },
	{ value: 'Martinique', label: 'Martinique' },
	{ value: 'Mauritania', label: 'Mauritania' },
	{ value: 'Mauritius', label: 'Mauritius' },
	{ value: 'Mayotte', label: 'Mayotte' },
	{ value: 'Mexico', label: 'Mexico' },
	{ value: 'Micronesia, Federated States of', label: 'Micronesia, Federated States of' },
	{ value: 'Moldova, Republic of', label: 'Moldova, Republic of' },
	{ value: 'Monaco', label: 'Monaco' },
	{ value: 'Mongolia', label: 'Mongolia' },
	{ value: 'Montserrat', label: 'Montserrat' },
	{ value: 'Morocco', label: 'Morocco' },
	{ value: 'Mozambique', label: 'Mozambique' },
	{ value: 'Myanmar', label: 'Myanmar' },
	{ value: 'Namibia', label: 'Namibia' },
	{ value: 'Nauru', label: 'Nauru' },
	{ value: 'Nepal', label: 'Nepal' },
	{ value: 'Netherlands', label: 'Netherlands' },
	{ value: 'Netherlands Antilles', label: 'Netherlands Antilles' },
	{ value: 'New Caledonia', label: 'New Caledonia' },
	{ value: 'New Zealand', label: 'New Zealand' },
	{ value: 'Nicaragua', label: 'Nicaragua' },
	{ value: 'Niger', label: 'Niger' },
	{ value: 'Nigeria', label: 'Nigeria' },
	{ value: 'Niue', label: 'Niue' },
	{ value: 'Norfolk Island', label: 'Norfolk Island' },
	{ value: 'Northern Mariana Islands', label: 'Northern Mariana Islands' },
	{ value: 'Norway', label: 'Norway' },
	{ value: 'Oman', label: 'Oman' },
	{ value: 'Pakistan', label: 'Pakistan' },
	{ value: 'Palau', label: 'Palau' },
	{ value: 'Palestinian Territory, Occupied', label: 'Palestinian Territory, Occupied' },
	{ value: 'Panama', label: 'Panama' },
	{ value: 'Papua New Guinea', label: 'Papua New Guinea' },
	{ value: 'Paraguay', label: 'Paraguay' },
	{ value: 'Peru', label: 'Peru' },
	{ value: 'Philippines', label: 'Philippines' },
	{ value: 'Pitcairn', label: 'Pitcairn' },
	{ value: 'Poland', label: 'Poland' },
	{ value: 'Portugal', label: 'Portugal' },
	{ value: 'Puerto Rico', label: 'Puerto Rico' },
	{ value: 'Qatar', label: 'Qatar' },
	{ value: 'Reunion', label: 'Reunion' },
	{ value: 'Romania', label: 'Romania' },
	{ value: 'Russian Federation', label: 'Russian Federation' },
	{ value: 'RWANDA', label: 'RWANDA' },
	{ value: 'Saint Helena', label: 'Saint Helena' },
	{ value: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis' },
	{ value: 'Saint Lucia', label: 'Saint Lucia' },
	{ value: 'Saint Pierre and Miquelon', label: 'Saint Pierre and Miquelon' },
	{ value: 'Saint Vincent and the Grenadines', label: 'Saint Vincent and the Grenadines' },
	{ value: 'Samoa', label: 'Samoa' },
	{ value: 'San Marino', label: 'San Marino' },
	{ value: 'Sao Tome and Principe', label: 'Sao Tome and Principe' },
	{ value: 'Saudi Arabia', label: 'Saudi Arabia' },
	{ value: 'Senegal', label: 'Senegal' },
	{ value: 'Serbia and Montenegro', label: 'Serbia and Montenegro' },
	{ value: 'Seychelles', label: 'Seychelles' },
	{ value: 'Sierra Leone', label: 'Sierra Leone' },
	{ value: 'Singapore', label: 'Singapore' },
	{ value: 'Slovakia', label: 'Slovakia' },
	{ value: 'Slovenia', label: 'Slovenia' },
	{ value: 'Solomon Islands', label: 'Solomon Islands' },
	{ value: 'Somalia', label: 'Somalia' },
	{ value: 'South Africa', label: 'South Africa' },
	{ value: 'South Georgia and the South Sandwich Islands', label: 'South Georgia and the South Sandwich Islands' },
	{ value: 'Spain', label: 'Spain' },
	{ value: 'Sri Lanka', label: 'Sri Lanka' },
	{ value: 'Sudan', label: 'Sudan' },
	{ value: 'Suriname', label: 'Suriname' },
	{ value: 'Svalbard and Jan Mayen', label: 'Svalbard and Jan Mayen' },
	{ value: 'Swaziland', label: 'Swaziland' },
	{ value: 'Sweden', label: 'Sweden' },
	{ value: 'Switzerland', label: 'Switzerland' },
	{ value: 'Syrian Arab Republic', label: 'Syrian Arab Republic' },
	{ value: 'Taiwan, Province of China', label: 'Taiwan, Province of China' },
	{ value: 'Tajikistan', label: 'Tajikistan' },
	{ value: 'Tanzania, United Republic of', label: 'Tanzania' },
	{ value: 'Thailand', label: 'Thailand' },
	{ value: 'Timor-Leste', label: 'Timor-Leste' },
	{ value: 'Togo', label: 'Togo' },
	{ value: 'Tokelau', label: 'Tokelau' },
	{ value: 'Tonga', label: 'Tonga' },
	{ value: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
	{ value: 'Tunisia', label: 'Tunisia' },
	{ value: 'Turkey', label: 'Turkey' },
	{ value: 'Turkmenistan', label: 'Turkmenistan' },
	{ value: 'Turks and Caicos Islands', label: 'Turks and Caicos Islands' },
	{ value: 'Tuvalu', label: 'Tuvalu' },
	{ value: 'Uganda', label: 'Uganda' },
	{ value: 'Ukraine', label: 'Ukraine' },
	{ value: 'United Arab Emirates', label: 'United Arab Emirates' },
	{ value: 'United Kingdom', label: 'United Kingdom' },
	{ value: 'United States', label: 'United States' },
	{ value: 'United States Minor Outlying Islands', label: 'United States Minor Outlying Islands' },
	{ value: 'Uruguay', label: 'Uruguay' },
	{ value: 'Uzbekistan', label: 'Uzbekistan' },
	{ value: 'Vanuatu', label: 'Vanuatu' },
	{ value: 'Venezuela', label: 'Venezuela' },
	{ value: 'Viet Nam', label: 'Viet Nam' },
	{ value: 'Virgin Islands, British', label: 'Virgin Islands, British' },
	{ value: 'Virgin Islands, U.S.', label: 'Virgin Islands, U.S.' },
	{ value: 'Wallis and Futuna', label: 'Wallis and Futuna' },
	{ value: 'Western Sahara', label: 'Western Sahara' },
	{ value: 'Yemen', label: 'Yemen' },
	{ value: 'Zambia', label: 'Zambia' },
	{ value: 'Zimbabwe', label: 'Zimbabwe' }
];

export default class Profile extends Component {
	input = React.createRef();
	phoneNumberRef = React.createRef();
	state = {
		firstName: '',
		businessname: '',
		credit: '',
		creditStatus: '',
		email: '',
		lastName: '',
		profile: [],
		imgUrl: '',
		userDetails: {},
		mobileOtp: "",
		loader: false,
		mobileNumber: "",
		userId: "",
		phoneVerify: "",
		requestAccessCall: true,
		userAllData: [],
		isValidPhoneNo: true
	}

	onSelectFile = (profile, setFieldValue) => e => {
		const files = e.target.files;
		this.setState({ imgUrl: files[0] })
	};

	imageRemoveHandler = (e) => {
		const profile = this.state.profile;
		profile.splice(e, 1);
		this.setState({ profile })
	}

	documentRemoveHandler = (e) => {
		const profile = this.state.profile;
		profile.splice(e, 1);
		this.setState({ profile })
	}

	async componentDidMount() {
		const allData = await localStorage.getItem('auth')
		const is_mobile_verified = await localStorage.getItem('is_mobile_verified')
		const { phoneNumber } = JSON.parse(allData)
		this.setState({
			mobileNumber: phoneNumber,
			phoneVerify: is_mobile_verified
		})
		const userId = await localStorage.getItem('userId');
		this.setState({ userId })
		const token = await localStorage.getItem('token');
		this.setState({ token })
		userProfile({ userId, token })
			.then(Response => {
				this.setState({
					firstName: Response.data.data.firstName,
					lastName: Response.data.data.lastName,
					businessname: Response.data.data.businessname,
					credit: Response.data.data.credit,
					creditStatus: Response.data.data.creditStatus,
					email: Response.data.data.email,
					profile: [Response.data.data.profile],
					userAllData: Response.data.data.update_profile,
					userDetails: Response.data.data.completeProfile
				})
				this.phoneNumberRef.current.setNumber(Response.data?.data?.completeProfile?.phoneUsers);
			})
			.catch(err => {
				toast.error(err.response?.data?.message, {
					position: toast.POSITION.TOP_RIGHT
				});
				if (err.response?.data?.data === 'deleted') {
					this.props.history.push('/login');
					window.localStorage.clear()
				}
			})
		var userType = await localStorage.getItem('accounttypeUsers');
		if (userType !== 'Admin') {
			await checkScreenAccess(
				{
					'userId': userId,
					'screenType': 'User Profile'
				}
			).then((response) => {
				this.setState({ loading: false, requestAccessCall: true, diableButton: true });
			}).catch(err => {
				this.setState({ loading: false, requestAccessCall: false, diableButton: false });
			});
		} else {
			this.setState({ requestAccessCall: true, loading: false, diableButton: false })
		}
	}

	updateProfile = async () => {
		const userId = await localStorage.getItem('userId');
		this.setState({ userId })
		const token = await localStorage.getItem('token');
		this.setState({ token })
		userProfile({ userId, token })
			.then(Response => {
				localStorage.setItem('firstName', Response.data.data.update_profile.firstName)
				localStorage.setItem('is_mobile_verified', Response.data.data.update_profile.is_mobile_verified)
				localStorage.setItem('auth', JSON.stringify(Response.data.data.update_profile))
				this.setState({ loading: false })
				toast.success('User profile updated successfully', {
					position: toast.POSITION.TOP_RIGHT
				})
				this.props.history.push('/admin/index');

			})
			.catch(err => {
				this.setState({ loading: false })
				toast.error(err.response.data.message, {
					position: toast.POSITION.TOP_RIGHT
				});
			})

	}

	submitHandler = (values) => {
		if (!this.state.isValidPhoneNo) {
			return toast.warn("Please check your phone number")
		}
		this.setState({ loading: true });
		updateUserProfile({
			...values,
			userId: this.state.userDetails.idUsers,
		}).then(Response => {
			this.updateProfile();
		}).catch(err => {
			this.setState({ loading: false })
			toast.error(err.response.data.message, {
				position: toast.POSITION.TOP_RIGHT
			});
		})
	}

	sendOtpVerifyMobileNumber = () => {
		sendOTP({ phoneNumber: this.state.mobileNumber, userId: this.state.userId })
			.then((response) => {
				toast.success(response.data.message, {
					position: toast.POSITION.TOP_RIGHT
				});
				this.setState({ modal: true, otpSent: true, loading: false });
			}).catch((err) => {
				toast.error(err.response?.data?.data?.phoneNumber || err?.response?.data?.message || err.response?.data?.message, {
					position: toast.POSITION.TOP_RIGHT
				});
				this.setState({ loading: false });
			})
	}

	toggle = () => {
		this.setState({ modal: !this.state.modal })
	}

	verifyMobileNumberUsingOtp = (test) => {
		const data = localStorage.getItem("auth")
		const userId = JSON.parse(data);
		if (this.state.mobileOtp.length > 4) {
			verifyPhone({ phoneNumber: this.state.mobileNumber, otp: this.state.mobileOtp, userId: userId.id })
				.then((response) => {
					toast.success(response.data.message, {
						position: toast.POSITION.TOP_RIGHT
					});
					this.setState({ loading: false, modal: false });
					this.updateProfile()
				}).catch((err) => {
					toast.error(err.response.data.data.otp || err.response.data.message, {
						position: toast.POSITION.TOP_RIGHT
					});
					this.setState({ loading: false });
				})
		} else {
			toast.error('Please enter 5 digit OTP', {
				position: toast.POSITION.TOP_RIGHT
			});
		}
	}

	sendRequestAccessHandle = async () => {
		this.setState({ loading: true });
		const info = await localStorage.getItem('auth');
		const sendInfo = JSON.parse(info);
		requestAccess({
			'username': sendInfo.username,
			'firstName': sendInfo.firstName,
			'lastName': sendInfo.lastName,
			'accounttypeUsers': sendInfo.accounttypeUsers,
			'businessname': sendInfo.businessname,
			'ADCode': sendInfo.ADCode,
			'address': sendInfo.address,
			'email': sendInfo.email,
			'phoneNumber': sendInfo.phonenumber,
			'userId': sendInfo.id,
			'screenType': 'User Profile',
		}).then(Response => {
			this.setState({ loading: false });
			toast.success(Response.data.message, {
				position: toast.POSITION.TOP_RIGHT
			});
		}).catch(err => {
			this.setState({ loading: false });
			let message = err.message;
			if (err.response && err.response.data.message) {
				message = err.response.data.message;
			}
			toast.error(message, {
				position: toast.POSITION.TOP_RIGHT
			});
		})
	};

	handlePhoneNumberChange = (isEdited, phoneNumber, selectedCountry, setFieldValue) => {
		const isValid = isValidNumber(phoneNumber, [selectedCountry.iso2.toUpperCase()])
		this.setState({ isValidPhoneNo: isValid });
		const number = phoneNumber?.length > 0
			? (
				phoneNumber.includes(`+${selectedCountry.dialCode}`)
					? phoneNumber
					: `+${selectedCountry.dialCode}${phoneNumber}`
			)
			: '';
		setFieldValue("phone", number)
	};

	render() {
		return (
			<div>
				{!this.state.requestAccessCall ?
					<div className="justify-content-center pt-2 main-contain-title">
						<>
							<p>This feature is restricted.</p>
							<Button
								onClick={() => this.sendRequestAccessHandle()}
								disabled={this.state.loading}
							>Request Access for free</Button>
						</>
					</div>
					:
					<>
						<Formik
							enableReinitialize
							initialValues={{
								phone: this.state.userDetails.phoneUsers,
								username: this.state.userDetails.uidUsers,
								firstname: this.state.userDetails.firstnameUsers,
								lastname: this.state.userDetails.lastnameUsers,
								email: this.state.userDetails.emailUsers,
								unitno: this.state.userDetails.unitnoUsers,
								houseno: this.state.userDetails.housenoUsers,
								streetname: this.state.userDetails.streetnameUsers,
								suburb: this.state.userDetails.suburbUsers,
								state: this.state.userDetails.stateUsers,
								postcode: this.state.userDetails.postcodeUsers,
								country: this.state.userDetails.countryUsers,
								accounttype: this.state.userDetails.accounttypeUsers,
								businessname: this.state.userDetails.businessnameUsers || "",
								businesstype: this.state.userDetails.businesstypeUsers || "",
								addressdescription: this.state.userDetails.addressdescriptionUsers,
								credits: this.state.userDetails.credit,
								profile: this.state.profile
							}}
							validationSchema={Schema}
							onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
								this.setErrors = setErrors;
								this.submitHandler(values);
							}}
						>{({
							values,
							errors,
							touched,
							handleChange,
							handleSubmit,
							handleBlur,
							setFieldValue,
						}) => (
							<div className="mt-2 style">
								<Card>
									<CardHeader className="c-header">Profile</CardHeader>
									<CardBody>
										<div className="col-12">
											<form onSubmit={handleSubmit}>
												<div className="row">
													<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
														<FormGroup>
															<label className="form-control-label">Profile</label>
															<FormGroup>
																<input accept=".png, .jpg, .jpeg," hidden ref={this.input}
																	type="file" multiple
																	onChange={this.onSelectFile(values.profile, setFieldValue)} />
																<FormFeedback>
																	{errors.profile && touched.profile && errors.profile}
																</FormFeedback>
																<div className='profile-image'>
																	<img alt="profile" style={{ height: 100, width: 100 }} src={this.state.imgUrl ? URL.createObjectURL(this.state.imgUrl) : values.profile[0] ? image + values.profile[0] : img1}
																		onClick={e => this.input.current && this.input.current.click()} />
																	<div className='profile-edit'>
																		<img src={edit} width="30" alt="edit" onClick={e => this.input.current && this.input.current.click()} />
																	</div>
																</div>
															</FormGroup>
														</FormGroup>
													</div>
													<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
														<FormGroup className=" has-feedback has-feedback-left ">
															<Label for="exampleEmail">User Name</Label>
															<div class="input-group mb-3">
																<div class="input-group-prepend">
																	<span class="input-group-text" id="basic-addon1"><i class="fa fa-user icon-style"
																		aria-hidden="true"></i></span>
																</div>
																<Input type="text"
																	readOnly={true}
																	invalid={errors.username && touched.username}
																	name="username" onChange={handleChange} onBlur={handleBlur} value={values.username}
																	placeholder="Enter User Name" />
																<FormFeedback>
																	{errors.username && touched.username && errors.username}
																</FormFeedback>
															</div>
														</FormGroup>
													</div>
													<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
														<FormGroup className="">
															<Label for="examplePassword">First Name</Label>
															<div class="input-group mb-3">
																<div class="input-group-prepend">
																	<span class="input-group-text" id="basic-addon1"><i class="fa fa-user icon-style"
																		aria-hidden="true"></i></span>
																</div>
																<Input type="text"
																	invalid={errors.firstname && touched.firstname}
																	name="firstname" onChange={handleChange} onBlur={handleBlur} value={values.firstname}
																	placeholder="Enter First Name" />
																<FormFeedback>
																	{errors.firstname && touched.firstname && errors.firstname}
																</FormFeedback>
															</div>
														</FormGroup>
													</div>
													<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
														<FormGroup className=" has-feedback has-feedback-left ">
															<Label for="exampleEmail">Last Name</Label>
															<div class="input-group mb-3">
																<div class="input-group-prepend">
																	<span class="input-group-text" id="basic-addon1"><i class="fa fa-user icon-style"
																		aria-hidden="true"></i></span>
																</div>
																<Input type="text"
																	invalid={errors.lastname && touched.lastname}
																	name="lastname" onChange={handleChange} onBlur={handleBlur} value={values.lastname}
																	placeholder="Enter Last Name" />
																<FormFeedback>
																	{errors.lastname && touched.lastname && errors.lastname}
																</FormFeedback>
															</div>
														</FormGroup>
													</div>
													<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
														<FormGroup className=" has-feedback has-feedback-left ">
															<Label for="exampleEmail">Unit No. (optional)</Label>
															<div class="input-group mb-3">
																<div class="input-group-prepend">
																	<span class="input-group-text" id="basic-addon1"><i class="fa fa-circle icon-style"
																		aria-hidden="true"></i></span>
																</div>
																<Input type="unitNumber"
																	invalid={errors.unitno && touched.unitno}
																	name="unitno" onChange={handleChange} onBlur={handleBlur} value={values.unitno}
																	placeholder="Enter Unit Number" />
																<FormFeedback>
																	{errors.unitno && touched.unitno && errors.unitno}
																</FormFeedback>
															</div>
														</FormGroup>
													</div>
													<div className="col-12">
														<FormGroup className=" has-feedback has-feedback-left ">
															<Label for="exampleEmail">Email</Label>
															<div class="input-group mb-3">
																<div class="input-group-prepend">
																	<span class="input-group-text" id="basic-addon1"><i class="fa fa-envelope icon-style"
																		aria-hidden="true"></i></span>
																</div>
																<Input type="email"
																	readOnly={true}
																	invalid={errors.email && touched.email}
																	name="email" onChange={handleChange} onBlur={handleBlur} value={values.email}
																	placeholder="Enter Email Address " />
																<FormFeedback>
																	{errors.email && touched.email && errors.email}
																</FormFeedback>
															</div>
														</FormGroup>
													</div>
													<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
														<FormGroup className=" has-feedback has-feedback-left ">
															<Label for="exampleEmail">House No.</Label>
															<div class="input-group mb-3">
																<div class="input-group-prepend">
																	<span class="input-group-text" id="basic-addon1"><i class="fa fa-home icon-style"
																		aria-hidden="true"></i></span>
																</div>
																<Input type="text"
																	invalid={errors.houseno && touched.houseno}
																	name="houseno" onChange={handleChange} onBlur={handleBlur} value={values.houseno}
																	placeholder="Enter House Number " />
																<FormFeedback>
																	{errors.houseno && touched.houseno && errors.houseno}
																</FormFeedback>
															</div>
														</FormGroup>
													</div>
													<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
														<FormGroup className=" has-feedback has-feedback-left ">
															<Label for="exampleEmail">Street Name</Label>
															<div class="input-group mb-3">
																<div class="input-group-prepend">
																	<span class="input-group-text" id="basic-addon1"><i class="fa fa-street-view icon-style"
																		aria-hidden="true"></i></span>
																</div>
																<Input type="text"
																	invalid={errors.streetname && touched.streetname}
																	name="streetname" onChange={handleChange} onBlur={handleBlur} value={values.streetname}
																	placeholder="Enter Street Name" />
																<FormFeedback>
																	{errors.streetname && touched.streetname && errors.streetname}
																</FormFeedback>
															</div>
														</FormGroup>
													</div>
													<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
														<FormGroup className=" has-feedback has-feedback-left ">
															<Label for="exampleEmail">Suburb OR Town</Label>
															<div class="input-group mb-3">
																<div class="input-group-prepend">
																	<span class="input-group-text" id="basic-addon1"><i class="fa fa-map icon-style"
																		aria-hidden="true"></i></span>
																</div>
																<Input type="text"
																	invalid={errors.suburb && touched.suburb}
																	name="suburb" onChange={handleChange} onBlur={handleBlur} value={values.suburb}
																	placeholder="Enter Suburb OR Town" />
																<FormFeedback>
																	{errors.suburb && touched.suburb && errors.suburb}
																</FormFeedback>
															</div>
														</FormGroup>
													</div>
													<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
														<FormGroup className=" has-feedback has-feedback-left ">
															<Label for="exampleEmail">State</Label>
															<div class="input-group mb-3">
																<div class="input-group-prepend">
																	<span class="input-group-text" id="basic-addon1"><i class="fa fa-globe icon-style"
																		aria-hidden="true"></i></span>
																</div>
																<Input type="text"
																	invalid={errors.state && touched.state}
																	name="state" onChange={handleChange} onBlur={handleBlur} value={values.state}
																	placeholder="Enter state" />
																<FormFeedback>
																	{errors.state && touched.state && errors.state}
																</FormFeedback>
															</div>
														</FormGroup>
													</div>
													<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
														<FormGroup className=" has-feedback has-feedback-left ">
															<Label for="exampleEmail">Post Code</Label>
															<div class="input-group mb-3">
																<div class="input-group-prepend">
																	<span class="input-group-text" id="basic-addon1"><i class="fa fa-envelope icon-style"
																		aria-hidden="true"></i></span>
																</div>
																<Input type="text"
																	invalid={errors.postcode && touched.postcode}
																	name="postcode" onChange={handleChange} onBlur={handleBlur} value={values.postcode}
																	placeholder="Enter Post Code" />
																<FormFeedback>
																	{errors.postcode && touched.postcode && errors.postcode}
																</FormFeedback>
															</div>
														</FormGroup>
													</div>
													<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
														<FormGroup>
															<Label>Country</Label>
															<Select
																name="country"
																onChange={(option) => setFieldValue('country', option.value)}
																onBlur={handleBlur}
																className="is-invalid"
																value={options ? options.find(option => option.value === values.country) : ''}
																options={options}
															/>
															<FormFeedback>
																{errors.country && touched.country && errors.country}
															</FormFeedback>
														</FormGroup>
													</div>
													<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
														<FormGroup className=" has-feedback has-feedback-left ">
															<Label for="exampleEmail">Phone Number</Label>
															<div class="input-group mb-3">
																<PhoneInput
																	containerClassName="intl-tel-input"
																	inputClassName="form-control"
																	ref={this.phoneNumberRef}
																	defaultValue={this.state.userDetails.phoneUsers}
																	onPhoneNumberChange={(isEdited, phoneNumber, selectedCountry) => this.handlePhoneNumberChange(isEdited, phoneNumber, selectedCountry, setFieldValue)}
																/>
																<span class="input-group-text-otp" id="">
																	{
																		this.state.phoneVerify !== 'true' ?
																			<i class="fa fa-times-circle" aria-hidden="true" style={{ color: "red", fontSize: "25px" }} title='Your Verfication is not complete please ask to admin'></i>
																			:
																			<i class="fa fa-check-circle" aria-hidden="true" style={{ color: "green", fontSize: "25px" }} title='Your Verfication is complete'></i>
																	}
																</span>

																<h5 className='error-display'>
																	{!this.state.isValidPhoneNo && "Please Enter a valid Phone Number"}
																	{errors.phone && touched.phone && errors.phone}
																</h5>
															</div>
															<div style={{ color: "#75b8f1", textAlign: "end" }}>
																{
																	this.state.userAllData.notification_request === true && this.state.phoneVerify !== 'true' &&
																	(<b role='button' style={{ color: "#75b8f1", textAlign: "end" }} onClick={this.sendOtpVerifyMobileNumber}>Click to verify phone number</b>)
																}
															</div>
														</FormGroup>
													</div>
													<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
														<FormGroup>
															<Label>Account Type</Label>
															<Input type="text"
																readOnly={true}
																invalid={errors.accounttype && touched.accounttype}
																name="accounttype" onChange={handleChange} onBlur={handleBlur} value={values.accounttype}
																placeholder="Enteraccounttype" />
															<FormFeedback>
																{errors.accounttype && touched.accounttype && errors.accounttype}
															</FormFeedback>
														</FormGroup>
													</div>
													{values.accounttype == "Business" ? <>
														<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
															<FormGroup className=" has-feedback has-feedback-left ">
																<Label for="exampleEmail">Business Name</Label>
																<div class="input-group mb-3">
																	<div class="input-group-prepend">
																		<span class="input-group-text" id="basic-addon1"><i class="fa fa-briefcase icon-style"
																			aria-hidden="true"></i></span>
																	</div>
																	<Input type="text"
																		invalid={errors.businessname && touched.businessname}
																		name="businessname" onChange={handleChange} onBlur={handleBlur}
																		value={values.businessname} placeholder="Enter Business Name" />
																	<FormFeedback>
																		{errors.businessname && touched.businessname && errors.businessname}
																	</FormFeedback>
																</div>
															</FormGroup>
														</div>
														<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
															<FormGroup className=" has-feedback has-feedback-left ">
																<Label for="exampleEmail">Business Type</Label>
																<div class="input-group mb-3">
																	<div class="input-group-prepend">
																		<span class="input-group-text" id="basic-addon1"><i class="fa fa-building icon-style"
																			aria-hidden="true"></i></span>
																	</div>
																	<Input type="text"
																		invalid={errors.businesstype && touched.businesstype}
																		name="businesstype" onChange={handleChange} onBlur={handleBlur}
																		value={values.businesstype} placeholder="Enter Business Type" />
																	<FormFeedback>
																		{errors.businesstype && touched.businesstype && errors.businesstype}
																	</FormFeedback>
																</div>
															</FormGroup>
														</div>
													</> : null}


													<div className="col-12">
														<FormGroup className=" has-feedback has-feedback-left ">
															<Label for="exampleEmail">Address Description</Label>
															<div class="input-group mb-3">
																<Input type="textarea"
																	invalid={errors.addressdescription && touched.addressdescription}
																	name="addressdescription" onChange={handleChange} onBlur={handleBlur}
																	value={values.addressdescription} placeholder="Enter address description  " />
																<FormFeedback>
																	{errors.addressdescription && touched.addressdescription && errors.addressdescription}
																</FormFeedback>
															</div>
														</FormGroup>
													</div>
												</div>


												<div className="d-flex justify-content-center pt-5">
													<button className="btn btn-submit" onClick={handleSubmit}>
														{this.state.loading ?
															< ClipLoader
																size={15}
																color={"#123abc"}
																loading={true}
															/> : 'Save'
														}
													</button>
												</div>
											</form>
										</div>
									</CardBody>
								</Card>
							</div>
						)}
						</Formik>
						<>
							<div>
								<Modal isOpen={this.state.modal} toggle={this.toggle}>
									<ModalHeader toggle={this.toggle} style={{ padding: "5px 1rem" }} className="otp-modal"></ModalHeader>
									<ModalBody>
										<div className="col-12">
											<FormGroup className=" has-feedback has-feedback-left ">
												<Label for="exampleEmail">Enter OTP</Label>
												<div class="input-group mb-3">
													<Input
														type="text"
														name="otp"
														onChange={(e) => this.setState({
															mobileOtp: e.target.value
														})}
														placeholder="Enter OTP"
													/>
												</div>
											</FormGroup>
										</div>
										<div className="d-flex justify-content-center">
											<button className="btn btn-submit" onClick={() => this.verifyMobileNumberUsingOtp(55)}>
												{this.state.loader ?
													<ClipLoader
														size={15}
														color={"#123abc"}
														loading={true}
													/>
													:
													'Submit'
												}
											</button>
										</div>
									</ModalBody>
								</Modal>
							</div >
						</>
					</>
				}
			</div>
		)
	}
}