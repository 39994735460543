import React, { Component } from 'react'
import { CardHeader, CardBody, Label, Card } from 'reactstrap';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { externalTracking } from '../../api/Form';
import moment from 'moment';
import { ClipLoader } from "react-spinners";
import ProgressBar from './components/ProgressBar';
import { toast } from 'react-toastify';
import { progreeOfTrcking } from '../../utils/utils';


export default class TrackShipmentsDetails extends Component {
  state = {
    trackDetails: [],
    loading: true,
    trackDetailsModal: false,
    showTabs: [],
    completed: 0,
    trackMore: false,
    courierName: "",
    refTrackNumber: "",
    checkRefNumber: "",
    etaShipment: "",
    completedData: "",
    classNameData: "",
    internalData: "",
    userIdNo: ""
  }

  refreshPage = async () => {
    this.setState({ loading: true });
    const deviceAdCode = await localStorage.getItem('adCodes');
    const userID = await localStorage.getItem('userId');
    this.setState({
      userIdNo: userID
    })
    const refno = this.props.location.refno;
    const nfcserialno = this.props.location.state;
    var userType = await localStorage.getItem('accounttypeUsers');
    var sendDeviceCode;
    if (userType == 'Admin') {
      sendDeviceCode = '';
    }
    else {
      sendDeviceCode = deviceAdCode;
    }
    externalTracking({ deviceAdCode: sendDeviceCode, tripSerialId: nfcserialno ? nfcserialno : refno, refno: refno, userId: this.state.userIdNo })
      .then(Response => {
        if (Response.data.data.type) {
          this.setState({ showTabs: Response.data.data.data, internalData: Response.data.ext_internal_data, etaShipment: Response.data.eta, loading: false, trackDetailsModal: false })
          console.log(this.state.showTabs);
        } else {
          this.setState({ trackDetails: Response.data.data, etaShipment: Response.data.eta, loading: false, trackDetailsModal: true })
        }
      })
  }

  goToTracking = async (refno) => {
    this.setState({ loading: true });
    const deviceAdCode = await localStorage.getItem('adCodes');
    const nfcserialno = this.props.location.state;
    var userType = await localStorage.getItem('accounttypeUsers');
    const userID = await localStorage.getItem('userId');
    this.setState({
      userIdNo: userID
    })
    var sendDeviceCode;
    if (userType == 'Admin') {
      sendDeviceCode = '';
    }
    else {
      sendDeviceCode = deviceAdCode;
    }
    externalTracking({ deviceAdCode: sendDeviceCode, tripSerialId: nfcserialno ? nfcserialno : refno, refno: refno, userId: this.state.userIdNo })
      .then(Response => {
        if (Response.data.data.type) {
          this.setState({ showTabs: Response.data.data.data, internalData: Response.data.ext_internal_data, etaShipment: Response.data.eta, loading: false, trackDetailsModal: false })
          const { completedData, classNameData } = this.props;

          const newState = progreeOfTrcking(completedData, classNameData, Response);
          this.setState(newState);
        } else {
          this.setState({ trackDetails: Response.data.data, internalData: Response.data.ext_internal_data, etaShipment: Response.data.eta, loading: false, trackDetailsModal: true })
        }
      })
  }

  async componentDidMount() {
    const deviceAdCode = await localStorage.getItem('adCodes');
    const nfcserialno = this.props.location.state;
    const refno = this.props.location.refno;
    this.setState({ refTrackNumber: refno })
    var userType = await localStorage.getItem('accounttypeUsers');
    const userID = await localStorage.getItem('userId');
    this.setState({
      userIdNo: userID
    })
    var sendDeviceCode;
    if (userType == 'Admin') {
      sendDeviceCode = '';
    }
    else {
      sendDeviceCode = deviceAdCode;
    }
    externalTracking({ deviceAdCode: sendDeviceCode, tripSerialId: nfcserialno ? nfcserialno : refno, refno: refno, userId: this.state.userIdNo })
      .then(Response => {
        if (Response.data.data.type) {
          this.setState({ showTabs: Response.data.data.data, internalData: Response.data.ext_internal_data, etaShipment: Response.data.eta, loading: false, trackDetailsModal: false, trackMore: false })
          const { completedData, classNameData } = this.props;

          const newState = progreeOfTrcking(completedData, classNameData, Response);
          this.setState(newState);
        } else {
          this.setState({ trackDetails: Response.data.data, internalData: Response.data.ext_internal_data, etaShipment: Response.data.eta, loading: false, trackDetailsModal: true, trackMore: false })
          const { completedData, classNameData } = this.props;

          const newState = progreeOfTrcking(completedData, classNameData, Response);
          this.setState(newState);
        }
      })
      .catch(err => {
        console.log(err)
        this.setState({ loading: false })
        toast.error(err.response?.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        if (err.response?.status === 400) {
          this.setState({ trackMore: true })
        }
      })
  }

  handleChangeCourier = (e) => {
    this.setState({ courierName: e.value })
  }

  trackingMore = (value) => {
    this.setState({ checkRefNumber: value })
  }

  render() {
    const { trackDetails } = this.state
    return (
      <div className="mt-2 style">
        <Card>
          <CardHeader className="c-header">Shipping History Details</CardHeader>
          <div className="d-flex justify-content-end">
            <button className="btn btn-submit m-2 mt-1 " onClick={() => this.refreshPage()}>Refresh</button>
          </div>
          <CardBody>
            {this.state.loading === true ?
              <div className="d-flex justify-content-center">
                < ClipLoader
                  size={15}
                  color={"#123abc"}
                  loading={true}
                />
              </div>
              :
              <>
                {this.state.showTabs.length > 0 &&
                  <>
                    <div className="d-flex justify-content-center track-card">
                      <Card className="dashboard-card-style track-card-style shadow mb-4">
                        <CardBody>
                          <div className="row">
                            <div className="col-12">
                              <div className="row">
                                <div className="col-6">
                                  <h1 className="title-main-head-track">Master Airway Number</h1>
                                </div>
                                <div className="col-6">
                                  <h1 className="title-description-profile-track">{this.state.showTabs[0].masterairwayno}</h1>
                                </div>
                              </div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                    <div className="scroll">
                      {this.state.showTabs.map(a => {
                        return (

                          <button className="btn btn-submit mt-3 mr-3" onClick={() => this.goToTracking(a.trackingno)}>{a.trackingno}</button>

                        )
                      })}
                    </div>
                  </>
                }
                {this.state.trackDetailsModal &&
                  <div className="d-flex justify-content-center track-card">
                    <Card className="dashboard-card-style track-card-style shadow mb-4">
                      <CardBody>
                        <div className="row">
                          <div className="col-6">
                            <div className="row">
                              <div className="col-6">
                                <h1 className="title-main-head-track">Ref No</h1>
                              </div>
                              <div className="col-6">
                                <h1 className="title-description-profile-track">{this.state?.internalData?.refno ? this.state?.internalData?.refno : this.props?.location?.refno}</h1>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="row">
                              <div className="col-6">
                                <h1 className="title-main-head-track">Trip Serial ID</h1>
                              </div>
                              <div className="col-6">
                                <h1 className="title-description-profile-track">{this.state?.internalData?.nfcserialno}</h1>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="row">
                              <div className="col-6">
                                <h1 className="title-main-head-track">Quantity</h1>
                              </div>
                              <div className="col-6">
                                <h1 className="title-description-profile-track">{this.state?.internalData?.quantity}</h1>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="row">
                              <div className="col-6">
                                <h1 className="title-main-head-track">MOT</h1>
                              </div>
                              <div className="col-6">
                                <h1 className="title-description-profile-track">{this.state?.internalData?.mot || this.state?.internalData?.modeOfTransport}</h1>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="row">
                              <div className="col-6">
                                <h1 className="title-main-head-track">Origin</h1>
                              </div>
                              <div className="col-6">
                                <h1 className="title-description-profile-track">{this.state?.internalData?.origin}</h1>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="row">
                              <div className="col-6">
                                <h1 className="title-main-head-track">Destination</h1>
                              </div>
                              <div className="col-6">
                                <h1 className="title-description-profile-track">{this.state?.internalData?.destination}</h1>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                }
                <>
                  {
                    this.state.trackDetailsModal &&
                    <>
                      {
                        trackDetails.length > 0 &&
                        <div className="timeline-progress">
                          <ProgressBar completed={this.state.completed} label={trackDetails} completedData={this.state.completedData} classNameData={this.state.classNameData} />
                        </div>
                      }
                      {
                        this.state.etaShipment &&
                        <div className='eta-progress pb-3'>
                          <Label className="pr-3">Estimated time of arrival : </Label>
                          <Label for="exampleEmail" className="label-description">{this.state.etaShipment}</Label>
                        </div>
                      }
                    </>
                  }
                </>
                {trackDetails.length > 0 &&
                  this.state.trackDetailsModal ? <VerticalTimeline layout='1-column' className="overflow-auto pb-0 vt-style" >
                  {trackDetails.map(a => {
                    return (
                      <VerticalTimelineElement
                        className="vertical-timeline-element--work "
                        date={
                          <div>
                            <div className="row">
                              <div className="col-12">
                                <span className="pl-2 vertical-time-style" style={{ color: '#0000a0' }}>{a.date ? moment(a.date).format('DD-MM-yy') : moment(a.new_date_format).format('DD-MM-yy')}</span>
                              </div>
                              <div className="col-12 ">
                                <span className="pl-2 vertical-time-style" style={{ color: '#0000a0' }}>{a.date ? moment(a.date).format("h:mm A") : moment(a.new_date_format).format("h:mm A")}</span>
                              </div>
                            </div>
                          </div>

                        }


                        iconStyle={{ background: '#fff', color: '#0000a0' }}
                      // icon={icon}
                      >
                        <Card className="shadow border-0">
                          <CardBody>
                            <table className="track-table">
                              <tr>
                                <td>
                                  <Label for="exampleEmail " className="pr-5 label-style-tracking">Status</Label>
                                </td>
                                <td>
                                  <span class="badge badge-pill badge-primary px-2 font-weight-light ">{a.trackingstatus ? a.trackingstatus : a.status ? a.status : a.track_status}</span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Label for="exampleEmail " className="pr-5 label-style-tracking">Quantity</Label>
                                </td>
                                <td>
                                  <Label for="exampleEmail" className="label-description">{a.quantity ? a.quantity : a.track_quantity}</Label>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Label for="exampleEmail " className="pr-5 label-style-tracking">Comment</Label>
                                </td>
                                <td>
                                  <Label for="exampleEmail" className="label-description">{a.comment ? a.comment : a.track_comment}</Label>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Label for="exampleEmail " className="pr-5 label-style-tracking">Location</Label>
                                </td>
                                <td>
                                  <Label for="exampleEmail" className="label-description">{a.location ? a.location : a.track_location}</Label>
                                </td>
                              </tr>
                            </table>
                          </CardBody>
                        </Card>
                      </VerticalTimelineElement>
                    );
                  })}
                </VerticalTimeline> : null}
              </>}
          </CardBody>
        </Card>
      </div>
    )
  }
}
