import React, { Component } from 'react'
import { getShipment, checkScreenAccess, requestAccess, searchShipmentInvoice, updateShipmentInvoice, sendInvoice } from '../../api/Form';
import { image } from '../../api/URLS';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Formik } from 'formik';
import 'react-toastify/dist/ReactToastify.css';
import { CardHeader, CardBody, FormGroup, Label, Input, Card, Button, FormFeedback } from 'reactstrap';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { callActivityApi } from '../../utils/saveUserActivity';
import { saveUserPreferences, userPreferences } from '../../api/Form'
import Select from 'react-select';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css'

const Schema = Yup.object().shape({
	hitsPerDay: Yup.number().test(
		'Is positive?',
		'ERROR: The number must be greater than 0!',
		(value) => value > 0).default(8)
		.required('Hits Per Day is required'),
	smsFrequency: Yup.number()
		.required('SMS frequency is required'),
	shipmentstatus: Yup
		.array()
		.when("smsFrequency", {
			is: 3,
			then: Yup.array().of(Yup.string().min(1, 'At least one box must be ticked')).required('Required')
		}),
	shipmentList: Yup
		.array()
		.when("smsFrequency", {
			is: 2,
			then: Yup.array().of(Yup.string().min(1, 'At least one box must be ticked')).required('Required')
		})

});

const categoryOption = [
	{ label: 'picked up', value: 'picked up' },
	{ label: 'intransit', value: 'intransit' },
	{ label: 'awaiting collection', value: 'awaiting collection' },
	{ label: 'available to collect', value: 'available to collect' },
	{ label: 'collected', value: 'collected' },
	{ label: 'delayed', value: 'delayed' },
	{ label: 'missed delivery', value: 'missed delivery' },
	{ label: 'delivered left in a safe place', value: 'delivered left in a safe place' },
	{ label: 'delivered', value: 'delivered' },
	{ label: 'delivery issues', value: 'delivery issues' },
	{ label: 'delivery exception', value: 'delivery exception' },
	{ label: 'Biosecurity Inspection', value: 'Biosecurity Inspection'},
	{ label: 'Cancelled', value: 'Cancelled'},
	{ label: 'Withdrawn', value: 'Withdrawn'},
	{ label: 'Airline Transfer', value: 'Airline Transfer'},
	{ label: 'Damaged', value: 'Damaged'},
	{ label: 'Lost', value: 'Lost'},
	{ label: 'Missing', value: 'Missing'},
	{ label: 'Abandoned', value: 'Abandoned'},
	{ label: 'Unclaimed', value: 'Unclaimed'},
	{ label: 'Biosecurity Destruction', value: 'Biosecurity Destruction'},
	{ label: 'Destruction', value: 'Destruction'},
	{ label: 'Partial Disposal', value: 'Partial Disposal'},
	{ label: 'Biosecurity Control', value: 'Biosecurity Control'},
	{ label: 'Re-Export', value: 'Re-Export'},
	{ label: 'Tran-Shipment', value: 'Tran-Shipment'},
];

export default class UserSettings extends Component {
	state = {
		userId: '',
		hitsPerDay: 8,
		smsFrequency: "1",
		requestAccess: false,
		shipmentstatus: [],
		shipmentList: [],
		editTags: [],
		errorTags: [],
		editActiveValue: '',
		customErr: '',
		tags: []
	}
	async componentDidMount() {

		callActivityApi({ 'pageName': 'User Settings', 'description': 'User has visited Settings screen' });
		const userId = await localStorage.getItem('userId');
		this.setState({ userId })
		const data = await localStorage.getItem('auth');
		this.setState({ userInfo: JSON.parse(data) })
		var userType = await localStorage.getItem('accounttypeUsers');
		if (userType !== 'Admin') {
			checkScreenAccess(
				{
					'userId': userId,
					'screenType': 'User Settings'
				}
			).then((response) => {
				this.getUserPreferences();
				this.setState({ loading: false, requestAccess: true });
			}).catch(err => {
				this.setState({ loading: false, requestAccess: false });
			});
		} else {
			this.getUserPreferences();
			this.setState({ requestAccess: true, loading: false })
		}
	}

	// get user prefrences

	getUserPreferences = async () => {
		this.setState({ loading: true })
		const info = await localStorage.getItem('auth');
		const sendInfo = JSON.parse(info);
		userPreferences({
			userAdCode: sendInfo.ADCode,
			userId: sendInfo.id
		}).then(Response => {
			this.setState({ loading: false })
			if (Response.data.data.length) {
				let userSettings = Response.data.data[0];
				this.setState({
					hitsPerDay: userSettings.hits_per_day,
					smsFrequency: userSettings.sms_frequency,
					shipmentList: userSettings.shipmentList ? userSettings.shipmentList.split(',') : [],
					shipmentstatus: userSettings.shipmentStatus ? userSettings.shipmentStatus.split(',') : [],
					tags: userSettings.shipmentList ? userSettings.shipmentList.split(',') : []
				})
			}
		}).catch(err => {
			this.setState({ loading: false })
		})
	}

	sendRequestAccess = async () => {
		this.setState({loading: true});

		const info = await localStorage.getItem('auth');
		const sendInfo = JSON.parse(info);

		// username, first name, last name, account type, business name, AD Code, address, email and phone number
		requestAccess({
			'username': sendInfo.username,
			'firstName': sendInfo.firstName,
			'lastName': sendInfo.lastName,
			'accounttypeUsers': sendInfo.accounttypeUsers,
			'businessname': sendInfo.businessname,
			'ADCode': sendInfo.ADCode,
			'address': sendInfo.address,
			'email': sendInfo.email,
			'phoneNumber': sendInfo.phonenumber,
			'userId': sendInfo.id,
			'screenType': 'User Settings',
		}).then(Response => {
			this.setState({loading: false});
			toast.success(Response.data.message, {
				position: toast.POSITION.TOP_RIGHT
			});
		}).catch(err => {
			this.setState({ loading: false });
			let message = err.message;
			if (err.response && err.response.data.message) {
				message = err.response.data.message;
			}
			toast.error(message, {
				position: toast.POSITION.TOP_RIGHT
			});
		})

	};

	submitHandler = async (values) => {
		this.setState({ loading: true });
		const info = await localStorage.getItem('auth');
		const userData = JSON.parse(info);
		saveUserPreferences({
			hitsPerDay: values.hitsPerDay,
			smsFrequency: values.smsFrequency,
			userAdCode: userData.ADCode,
			userId: userData.id,
			shipmentList: values.shipmentList.length ? values.shipmentList.toString(): '',
			shipmentStatus: values.shipmentstatus.length ? values.shipmentstatus.toString() : ''
		}).then(Response => {
			toast.success(Response.data.message, {
				position: toast.POSITION.TOP_RIGHT
			});
			this.props.history.push("/admin/index")
		}).catch(err => {
			this.setState({ loading: false });
			let message = err.message;
			if (err.response && err.response.data.message) {
				message = err.response.data.message;
			}
			toast.error(message, {
				position: toast.POSITION.TOP_RIGHT
			});
		})
	}

	setStatus = (option, setFieldValue) => {
		let saveVal = Array.isArray(option) ? option.map(x => x.value) : [];
		//	this.setState({ shipmentstatus: saveVal })
		setFieldValue('shipmentstatus', saveVal);
	}

	// tags input code for shipmentlist

	handleTags = (tags, setFieldValue) => {
		this.setState({ loading: false, tags: tags, customErr: '' });
		setFieldValue('shipmentList', tags);
	};

	checkEdit = (key) => {
		let editTags = [];
		editTags.push(this.state.tags[key]);
		this.setState({ editTags, editActiveValue: this.state.tags[key] });
	};

	handleEdit = (value, key) => {
		// console.log(value);
		let tags = this.state.tags;
		tags[key] = value;
		this.setState({ loading: true, tags, editActiveValue: '', editTags: [] });
		this.setState({ loading: false, tags: tags, customErr: '' });

	};

	pasteSplit = (data) => {
		const separators = [',', ';', '\\(', '\\)', '\\*', '/', ':', '\\?', '\n', '\r', 'g'];
		return data.split(new RegExp(separators.join('|'))).map(d => d.trim());
	};

	renderTags = (props) => {
		// console.log(this.state.errorTags);
		let { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props;
		let classvar = { ...other };
		if (this.state.errorTags.includes(getTagDisplayValue(tag))) {
			classvar.className = "react-tagsinput-tag error";
		}
		if (this.state.editTags.includes(getTagDisplayValue(tag))) {
			return (
				<Input
					name={'edit' + key}
					onChange={(tags) => {
						this.setState({ editActiveValue: tags.target.value });
					}}
					onKeyPress={event => {
						//console.log(event.key);
						if (event.key === 'Enter') {
							this.handleEdit(event.target.value, key);
						}
					}}
					className={'editTag'}
					value={this.state.editActiveValue}
				/>
			)
		} else {
			return (
				<span key={key} {...classvar} >
					<span onClick={() => this.checkEdit(key)}>{getTagDisplayValue(tag)}</span>
					{!disabled &&
						<a className={classNameRemove} onClick={(e) => onRemove(key)} />
					}
				</span>
			)
		}
	};

	defaultRenderInput = (props) => {
		let { onChange, value, addTag, ...other } = props
		return (
			<input type='text' onChange={(e) => { onChange(e); this.setState({ editTags: [] }) }} value={value} {...other} />
		)
	}

	setSmsPreferences = (value, setFieldValue) => {
		if (value == 1){
			this.setState({tags: []});
			setFieldValue('shipmentstatus',[]);
			setFieldValue('shipmentList',[]);
		} else if (value == 2){
			setFieldValue('shipmentstatus',[]);
		} else {
			setFieldValue('shipmentList',[]);
			this.setState({tags: []});
		}
		setFieldValue('smsFrequency',value);
	}


	render() {
		const { requestAccess } = this.state;
		return (
			<div className="mt-2 style">
				{!requestAccess ?
					<div className="justify-content-center pt-2 main-contain-title">
						<>
							<p>This feature is restricted.</p>
							<Button onClick={() => this.sendRequestAccess()}
							disabled={this.state.loading}>Request Access for free</Button>
						</>
					</div>
					:
					<Card>
						<CardHeader className="c-header">User Settings</CardHeader>
						<CardBody>
							<>
								<Formik
									isInitialValid={false}
									enableReinitialize
									initialValues={{
										hitsPerDay: this.state.hitsPerDay,
										smsFrequency: this.state.smsFrequency,
										shipmentstatus: this.state.shipmentstatus,
										shipmentList: this.state.shipmentList
									}}
									validationSchema={Schema}
									onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
										resetForm({ values: '' })
										this.setErrors = setErrors;
										this.submitHandler(values);
									}}>
									{({
										values,
										errors,
										touched,
										handleChange,
										handleSubmit,
										handleBlur,
										setFieldValue,
									}) => (
										<>
											<div className="col-12">
												<FormGroup>
													<Label>Set Preferred hits(daily) </Label>
													<div className="input-group">
														<Input
															type="text"
															name="hitsPerDay"
															invalid={errors.hitsPerDay && touched.hitsPerDay}
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.hitsPerDay}
															placeholder="Hits Per Day"
														/>
													</div>
												</FormGroup>

												<FormFeedback>
													{errors.hitsPerDay && touched.hitsPerDay && errors.hitsPerDay}
												</FormFeedback>

												<FormGroup>
													<div className="col-12 pt-3">
														<Label>Set preference for SMS frequency per shipment</Label>
														<FormGroup check>
															<Label check>
																<Input type="checkbox" checked={values.smsFrequency == "1" ? true : false} placeholder="" onChange={() => this.setSmsPreferences(1, setFieldValue)} />{' '}
																Send out SMS for every shipment whenever there is change in update from previous update.
															</Label>
														</FormGroup>
														<FormGroup check>
															<Label check>
																<Input type="checkbox" checked={values.smsFrequency == "2" ? true : false} placeholder="" onChange={() => this.setSmsPreferences(2, setFieldValue)} />{' '}
																Send out SMS for selected shipments whenever there is change in update from previous update, this option allows user to select which shipments get SMS when there is a new update.
															</Label>
															{values.smsFrequency == '2' && <FormGroup>
																<Label>Shipment List</Label>
																<TagsInput
																	name="shipmentList"
																	value={this.state.tags}
																	onChange={(tags) => this.handleTags(tags, setFieldValue)}
																	renderInput={this.defaultRenderInput}
																	pasteSplit={this.pasteSplit}
																	addOnPaste={true}
																	onlyUnique={true}
																	renderTag={this.renderTags}
																	addKeys={[9, 13, 188]}
																	inputProps={{
																		className: 'react-tagsinput-input',
																		placeholder: 'Enter shipment list'
																	}}
																/>
																<Input
																	type="hidden"
																	invalid={(errors.shipmentList && touched.shipmentList) || this.state.customErr}
																	name="shipmentList"
																	onBlur={handleBlur}
																	value={values.shipmentList}
																/>
																<FormFeedback>
																	{(errors.shipmentList && touched.shipmentList && errors.shipmentList)}
																</FormFeedback>
															</FormGroup>}
														</FormGroup>
														<FormGroup check>
															<Label check>
																<Input type="checkbox" checked={values.smsFrequency == "3" ? true : false} placeholder="" onChange={() => this.setSmsPreferences(3, setFieldValue)} />{' '}
																Send out updates for selected one or more status only: picked up, intransit, awaiting collection, available to collect, collected, delayed, missed delivery, delivered left in a safe place, delivered, delivery issues, delivery exception.
															</Label>
															{values.smsFrequency == '3' && <><Label> Status</Label>
																<Select
																	isMulti={true}
																	name="shipmentstatus"
																	onChange={(option) => this.setStatus(option, setFieldValue)}
																	onBlur={handleBlur}
																	className="is-invalid"
																	value={categoryOption.filter(obj => values.shipmentstatus.includes(obj.value))}
																	options={categoryOption}
																/>
																<FormFeedback>
																	{errors.shipmentstatus && touched.shipmentstatus && errors.shipmentstatus}
																</FormFeedback></>}
														</FormGroup>
													</div>
												</FormGroup>
												<FormFeedback>
													{errors.smsFrequency && touched.smsFrequency && errors.smsFrequency}
												</FormFeedback>
											</div>

											<div className="d-flex justify-content-center">
												<button className="btn btn-submit" onClick={handleSubmit}>
													Save
												</button>
											</div>
										</>
									)}
								</Formik >
							</>

						</CardBody>
					</Card>}
			</div >
		)
	}
}
