import React, {Component} from 'react'
import {lostParcelSuccess} from '../../api/Form'
import {ClipLoader} from "react-spinners";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class PaymentSuccess extends Component {
	state = {
		mailowner_maildescription: '',
		mailowner_courier: '',
		mailowner_trackingnumber: '',
		mailowner_name: '',
		mailowner_phone: '',
		mailowner_email: '',
		mailowner_addressonmail: '',
		mailowner_nameonmail: '',
		mailowner_edac: '',
		transactionNumber: '',
		loading: false
	}

	async componentDidMount() {
		this.setState({loading: true})
		const searchParams = new URLSearchParams(document.location.search);
		let param = searchParams.get('transactionNumber');
		console.log(param);


		const data = await localStorage.getItem('transactionNumber');
		const parcel = await localStorage.getItem('missingParcel');

		console.log(data)
		this.setState({data})
		this.setState({parcel})
		if (param === data) {
			console.log("test")
			lostParcelSuccess(this.state)
				.then(Response => {
					toast.success(Response.data.message, {
						position: toast.POSITION.TOP_RIGHT
					});
					setTimeout(() => {
						this.props.history.push({
							pathname: '/',
						})
					}, 2000)
					this.setState({loading: false})
				})
		}
	}

	render() {
		return (
			<div class=" w-100 h-100 bg-style">
				<div className="container  container-style d-flex justify-content-center align-items-center">
					{this.state.loading &&
					<div className="row ">
						<div className="col-12 d-flex justify-content-center mb-2">
							< ClipLoader
								size={50}
								color={"#123abc"}
								loading={true}
							/>
						</div>
						<div className="col-12 d-flex justify-content-center">
							<h1 className="payment-title">Please wait for submit your request</h1>
						</div>
					</div>
					}
				</div>
			</div>
		)
	}
}
