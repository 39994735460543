import React from 'react';
import { Helmet } from 'react-helmet';

const withCanonical = (WrappedComponent, canonicalUrl) => {
    return class extends React.Component {
        render() {
            return (
                <>
                    <Helmet>
                        <link rel="canonical" href={canonicalUrl} />
                    </Helmet>
                    <WrappedComponent {...this.props} />
                </>
            );
        }
    };
};

export default withCanonical;
