import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap'

export default class AddressDynamicIoTCodes extends Component {
  render() {
    return (
      <div className=" w-100 h-100">
        <div className="container">
          <div className="row pt-5">
            <Link class="btn btn-back" to="/"><i class="fa fa-long-arrow-left pr-3"> </i> Go Back</Link>
            {/* <div className="ml-auto">
            <span className="email-style"><i class="fa fa-envelope  " aria-hidden="true"></i> support@addressdynamic.com</span>
          </div> */}
          </div>
          <hr />
          <div className="main-contain-style ">
            <div>
              <h1 className=" main-contain-title">AD IoT Code</h1>
              <p className="main-contain-description">Millions of mail and parcels are lost every year. Lost parcel and mail occur mainly because receivers’ information and paperwork attached to a package fades or falls-off while in transit. This is not surprising as parcels travel through many hands before reaching its destination. Weather conditions, rough handling of package, and wrongly addressed parcel are some causes of lost and missing parcels. This is a problem not only for the shipper and receiver but for the delivery companies handling the package as they spend extra resources to find a customers package when it goes missing and pay claims if it is eventually deemed as lost.</p>
              <p className="main-contain-description">An AddressDynamic code is an IoT (Internet of Things) 12 digit numerical code which when printed or written on a parcel legibly, is used to securely identify either the shipper or the receiver. If a delivery company is unable to identify a package OR a parcel has been wrongly addressed, the 12 digit AD code on the package when entered on addressdynamic will notify the receiver through SMS that the verified parcel delivery company needs delivery details confirmed for a pending delivery. This avoids the possible loss or delay in delivering the parcel.
Use buttons below to signup for free or request a demo</p>
            </div>
            <div className="pt-3 d-flex justify-content-center pb-3">
              <Button className="btn btn-login mr-2">Request Demo</Button>
              <Button className="btn btn-login">Register</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
