import React, { Component } from 'react'
import { CardHeader, CardBody, FormGroup, Label, Input, Card, FormFeedback } from 'reactstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { getApiClientDetail, grantApiAccess } from '../../api/Form'
import { URLS } from "../../api/URLS";
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./GrantAcess.css";
import moment from 'moment';

const Schema = Yup.object().shape({
    access_token: Yup.string().required('Access Token is required!!'),
    validity: Yup.date().required(),
   });


export default class GrantApiAcess extends Component {
  state = {
    access_token : '',
    validity: moment().add(90,'days'),
    access_routes: [],
    clientDetail: {},
    routesList: [],
    access_status: 0,
  }

  async componentDidMount() {
    const  { id } = this.props.match.params; 
    getApiClientDetail(id).then(Response => {
        if(Response.data.status === true){
            this.setState({ clientDetail: Response.data.data, })
        }
    })
    // console.log('validity', this.state.validity)
  }
   generateToken() {
    var length = 25;
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

 changeStatus(){
   this.setState({ access_status : !this.state.access_status } );
 }
 
 
  submitHandler = (values) => {
    values.id = this.state.clientDetail.id;
    values.email = this.state.clientDetail.email;
    values.name = this.state.clientDetail.name;
    grantApiAccess(values)
    .then(response => {
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      this.setState({loading: false});
    })
    .catch(err => {
      console.log(err)
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      this.setState({loading: false});
    })
  }

  handlerError = (error) => {
    console.log(error)
  }

  

  render() {
    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{
             access_token: this.state.clientDetail ? this.state.clientDetail.access_token : this.state.access_token,
             validity: this.state.clientDetail ? (this.state.clientDetail.validity) ? this.state.clientDetail.validity : this.state.validity : this.state.validity,
             access_status: this.state.clientDetail ? this.state.clientDetail.access_status : this.state.access_status,
            // car_per_min: this.state.priceList.length ? this.state.priceList[2].price : 0,
            // car_per_km: this.state.priceList.length ? this.state.priceList[3].price : 0,
          }}
          validationSchema={Schema}
          onSubmit={(values, { setFieldError }) => {
            this.submitHandler(values);
          }}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue
          }) => (
              <>
                <div className="mt-2 style">
                  <Card>
                    <CardHeader className="c-header">API Acess</CardHeader>

                    <CardBody>
                      <div className="col-12">
                        <FormGroup>
                          <Label >Name</Label>
                          <Input
                          disabled
                            type="text"
                            name="name"  value={this.state.clientDetail.name}  />
                        </FormGroup>
                      </div>
                      <div className="col-12">
                        <FormGroup>
                          <Label >Email</Label>
                          <Input
                          disabled
                            type="text"
                            name="name"  value={this.state.clientDetail.email}  />
                        </FormGroup>
                      </div>
                      <div className="col-12">
                        <FormGroup>
                          <Label >Site Url</Label>
                          <Input
                          disabled
                            type="text"
                            name="name"  value={this.state.clientDetail.site_url}  />
                        </FormGroup>
                      </div>
                      <div className="col-12">
                        <FormGroup>
                          <Label >API Access Token</Label>
                          <Input type="text"
                            disabled
                            invalid={errors.access_token && touched.access_token}
                            name="access_token" onChange={handleChange} onBlur={handleBlur} value={values.access_token} placeholder="Access Token" />
                          <FormFeedback>
                            {errors.access_token && touched.access_token && errors.access_token}
                          </FormFeedback>
                        </FormGroup>
                      </div>
                      <div className="pb-2 pt-2 form-check">
											<button className="btn btn-generate"
												onClick={() => setFieldValue('access_token', this.generateToken())}>Generate</button>
										</div>
                      <div className="col-12">
                        <FormGroup>
                          <Label >Token Valid Days</Label>
                          <DatePicker
                            // {...field}
                            // {...props}
                            minDate={moment().toDate()}
                            selected={(values.validity && new Date(values.validity)) || null}
                            onChange={val => {
                              setFieldValue('validity', val);
                            }}
                          />
                          {/* <Input type="number"
                            invalid={errors.validity && touched.validity}
                            name="validity" onChange={handleChange} onBlur={handleBlur} value={values.validity} placeholder="Enter Valid Date" /> */}
                          <FormFeedback>
                            {errors.validity && touched.validity && errors.validity}
                          </FormFeedback>
                        </FormGroup>
                      </div>
                      
                      <div className="col-12">
                        <FormGroup>
                          <Label >Routes :</Label>
                          <Input
                            disabled
                            type="text"
                            name="access_routes"  value={URLS.SHIPMENT}  />
                        </FormGroup>
                      </div>

                      <div className="col-12">
                        <FormGroup check>
                          <Label check>
                            <Input defaultChecked={values.access_status} value={values.access_status} type="checkbox" name="access_status" onChange={() => setFieldValue('access_status', !values.access_status)} />{' '}
                            Grant Access
                          </Label>
                        </FormGroup>
                      </div>
                     
                      <div className="d-flex justify-content-center">
                        <button className="btn btn-submit" onClick={handleSubmit} >Submit</button>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </>
            )}
        </Formik>
      </div>
    )
  }
}
