import React, { Component, Fragment } from 'react'
import { CardHeader, CardBody, FormGroup, Label, Input, Card, FormFeedback, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ClipLoader } from "react-spinners";
import { downloadManifest, requestAccess, checkScreenAccess } from '../../api/Form';
import { toast } from 'react-toastify';
import PrintManifest from './PrintManifest';
import { callActivityApi } from '../../utils/saveUserActivity';
import { phoneNoVerifyCheck } from '../../utils/utils';

const Schema = Yup.object().shape({
  airwayBill: Yup.string()
    .min(8, 'Master Airwaybill number must be exactly 8 digits')
    .max(20, 'Master Airwaybill number must be exactly 20 digits')
    .required('Master Airwaybill number is required'),
});

export default class DownloadManifest extends Component {
  input = React.createRef();
  state = {
    deviceADcode: '',
    airwayBill: '',
    scan: [],
    userId: '',
    modal: false,
    nfcADcode: '',
    loading: true,
    customErr: '',
    requestAccess: false,
    print: false,
    shipments: [],
    flight_details: {},
    userInfo: {},
    totalWeight: 0,
  }

  addClick = (array) => {
    array.insert(1, {
      nfcADcode: ''
    })
  }

  loadCss = (stylePath) => {
    const head = document.head;
    if (this.state.link) {
      head.removeChild(this.state.link);
    }
    const link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = stylePath;
    head.appendChild(link);
    this.setState({ link })
  }

  unloadCss = () => {
    const head = document.head;
    if (this.state.link) {
      head.removeChild(this.state.link);
    }
  }

  componentWillUnmount() {
    this.unloadCss();
  }
  async componentDidMount() {
    callActivityApi({ 'pageName': 'Download Manifest', 'description': 'User has visited Download manifest Screen' });
    const historyPush = this.props.history.push
    phoneNoVerifyCheck(historyPush)
    const userData = await localStorage.getItem('auth');
    this.setState({ userInfo: JSON.parse(userData) });
    const deviceADcode = await localStorage.getItem('adCodes');
    this.setState({ deviceADcode })
    const userId = await localStorage.getItem('userId');
    this.setState({ userId })
    var userType = await localStorage.getItem('accounttypeUsers');
    if (userType !== 'Admin') {
      checkScreenAccess(
        {
          'userId': userId,
          'screenType': 'Download Manifest'
        }
      ).then((response) => {
        this.setState({ loading: false, requestAccess: true });
      }).catch(err => {
        this.setState({ loading: false, requestAccess: false });
      });
    } else {
      this.setState({ requestAccess: true, loading: false })
    }
  }

  getWeight = (arr) => {
    var val = 0;
    const unique = arr.map((v) => {
      var weight = ((v.weight != '0') ? parseInt(v.weight) * parseInt(v.quantity) : parseInt(v.weight));
      return val = parseInt(val) + parseInt(weight);
    });
    if (Array.isArray(unique)) {
      return unique[unique.length - 1];
    } else {
      return unique;
    }
  };

  getUnique = (arr, index) => {

    const unique = arr
      .map(e => e[index])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]);

    return unique;
  };

  submitHandler = (values) => {
    this.setState({ loading: true });
    const data = {
      airwayBill: values.airwayBill,
    };
    downloadManifest(data)
      .then(Response => {
        if (!Response.data.data.flight_details) {
          toast.error("Could not find flight details for this master airway number!!", {
            position: toast.POSITION.TOP_RIGHT
          });
          this.props.history.push('/admin/index');

        }
        if (Response.data.data.shipments.length > 0) {
          const returnVal = this.getUnique(Response.data.data.shipments, 'refno');
          const totalWeight = this.getWeight(returnVal);
          this.setState({
            loading: false,
            shipments: returnVal,
            flight_details: Response.data.data.flight_details,
            print: true,
            totalWeight: totalWeight,
          });
          this.loadCss('/PrintManifest.css')
        } else {
          this.setState({
            loading: false,
            shipments: Response.data.data.shipments,
            flight_details: Response.data.data.flight_details,
            print: true,
          });
        }
      }).catch(err => {
        this.setState({ loading: false });
        let message = err.message;
        if (err.response && err.response.data.message) {
          message = err.response.data.message;
        }
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT
        });
      })
  }

  sendRequestAccess = async () => {
    this.setState({ loading: true });
    const info = await localStorage.getItem('auth');
    const sendInfo = JSON.parse(info);
    requestAccess({
      'username': sendInfo.username,
      'firstName': sendInfo.firstName,
      'lastName': sendInfo.lastName,
      'accounttypeUsers': sendInfo.accounttypeUsers,
      'businessname': sendInfo.businessname,
      'ADCode': sendInfo.ADCode,
      'address': sendInfo.address,
      'email': sendInfo.email,
      'phoneNumber': sendInfo.phonenumber,
      'userId': sendInfo.id,
      'screenType': 'Download Manifest',
    }).then(Response => {
      this.setState({ loading: false });
      toast.success(Response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }).catch(err => {
      this.setState({ loading: false });
      let message = err.message;
      if (err.response && err.response.data.message) {
        message = err.response.data.message;
      }
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })
  };

  render() {
    const { scan, requestAccess, print } = this.state;
    return (
      <div>
        {!requestAccess ?
          <div className="justify-content-center pt-2 main-contain-title">
            <>
              <p>This feature is restricted.</p>
              <Button onClick={() => this.sendRequestAccess()}
                disabled={this.state.loading}>Request Access for free</Button>
            </>
          </div>
          :
          <>
            <div className="d-flex justify-content-center scan-parcel-loader">
              <ClipLoader
                size={50}
                color={"#123abc"}
                loading={this.state.loading}
              />
            </div>
            <Card>
              <CardHeader className="c-header">Download Manifest</CardHeader>
              <CardBody>
                {print ?
                  <PrintManifest totalWeight={this.state.totalWeight} userInfo={this.state.userInfo} shipments={this.state.shipments} flight_details={this.state.flight_details} size={this.state.size}
                    reset={() => this.setState({ print: false, printButton: false })} /> :
                  <Formik
                    // enableReinitialize
                    initialValues={{
                      airwayBill: this.state.airwayBill,
                    }}
                    validationSchema={Schema}
                    onSubmit={(values, { setSubmitting, setErrors }) => {
                      this.setErrors = setErrors;
                      this.submitHandler(values);
                    }}>
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      handleBlur,
                      setFieldValue,
                      status,
                      isSubmitting, }) => (
                      <Fragment>
                        <div className={this.state.loading ? "mt-2 style loadingDiv" : "mt-2 style"}>
                          <div className="col-12">
                            <FormGroup>
                              <Label>Master bill number</Label>
                              <Input
                                type="text"
                                invalid={errors.airwayBill && touched.airwayBill}
                                name="airwayBill"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.airwayBill}
                                placeholder="Enter Master bill number" />
                              <FormFeedback>
                                {errors.airwayBill && touched.airwayBill && errors.airwayBill}
                              </FormFeedback>
                            </FormGroup>
                          </div>
                          <div className="d-flex justify-content-center">
                            <button className="btn btn-submit" onClick={handleSubmit} >Generate</button>
                          </div>
                        </div>
                      </Fragment>

                    )}
                  </Formik>}
              </CardBody>
            </Card>
          </>
        }
      </div>
    )
  }
}
