import React, { Component } from 'react'
import './Chats.css'
import { chatsMessagesList, deleteChats, deleteMessage, readChats, sendChats } from '../../api/Form';
import ChatUser from './chatList'
import { BrowserView, MobileView, } from "react-device-detect";
import { Context } from '../../context';
import img2 from '../../assets/undraw_online_chat_d7ek.svg'
import Chat from './chat';
import { ClipLoader } from "react-spinners";
import { phoneNoVerifyCheck } from '../../utils/utils';

export default class Chats extends Component {
	messagesEndRef = React.createRef();
	static contextType = Context;
	state = {
		deviceAdCode: '',
		chatsList: [],
		message: [],
		callHistory: [],
		statusLoading: false,
		mobileView: true,
		contactScreen: false,
		chatScreen: '',
		userChat: {}
	}

	async componentDidMount() {
		const { match: { params } } = this.props;
		const historyPush = this.props.history.push
		phoneNoVerifyCheck(historyPush)
		const deviceAdCode = await localStorage.getItem('adCodes');
		const userId = await localStorage.getItem('userId');
		this.setState({ userId, deviceAdCode })
		this.scrollToBottom();
		this.readChats()
	}

	readChats = () => {
		readChats({ chatId: this.state.userChat.id, userId: this.state.userId })
			.then(response => {
			})
			.catch(err => {
				console.log(err)
			})
	}

	componentDidUpdate() {
		// this.scrollToBottom();
	}

	chatCloseHandler = () => {
		this.setState({ mobileView: true })
	}

	chatListDeleteHandler = (messageId) => {
		deleteMessage({ messageId })
			.then(Response => {
				chatsMessagesList({ chatId: this.state.userChat.id })
					.then(Response => {
						this.setState({ message: Response.data.data, chatScreen: 'chatScreen' })
					})
				if (this.state.status === 'accepted') {
					readChats({ chatId: this.state.userChat.id, userId: this.state.userChat.userId })
						.then(Response => {
							console.log(Response)
						})
				}
			})
			.catch(err => {
				console.log(err)
			})
	}

	deleteChatsHandler = () => {
		deleteChats({ chatId: this.state.userChat.id })
			.then(Response => {
				console.log(Response)
			})
			.catch(err => {
				console.log(err)
			})
	}

	inputHandler = (e) => {
		this.setState({ text: e.target.value })
	}

	sendChatHandler = () => {
		sendChats({ text: this.state.text, chatId: this.state.userChat.id, senderId: this.state.userChat.senderId })
			.then(Response => {
				console.log(Response)
				this.setState({ text: '' });
			})
			.catch(err => {
				console.log(err)
			})
	}

	keyPress = (e) => {
		if (e.keyCode === 13 && e.target.value) {
			this.sendChatHandler()
		}
	}
	scrollToBottom = () => {
		if (this.messagesEndRef.current) {
			this.readChats();
			this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}

	render() {

		const { chats, loading } = this.context;
		const { userChat, mobileView, callHistory } = this.state;
		return (
			<div>
				<BrowserView>
					<div className="mt-2 style web-bg">
						<div className="chat-contain-size">
							<div className="row">

								<ChatUser callHistory={callHistory} receiverId = {this.props.match.params } data={(chatScreen, userChat, mobileView) =>
									this.setState({ chatScreen, userChat, mobileView })} />

								{!loading && this.context.chatId === userChat.id && userChat.status === 'accepted' ?
									<Chat chats={chats} userChat={userChat} mobileView={mobileView} callHistorys={(callHistory) => this.setState({ callHistory })} readChats={() => this.readChats()}
										deleteChats={(id) => this.chatListDeleteHandler(id)} chatClose={() => this.chatCloseHandler()} />
									:
									<div className="col-9 col-lg-7 col-xl-9 pl-0 d-none d-lg-block d-xl-block">
										<div className="bg-start-screen d-flex justify-content-center align-items-center">
											<div>
												{loading ?
													< ClipLoader
														size={15}
														color={"#123abc"}
														loading={true}
													/>
													:
													<>
														<div className="pt-5 d-flex justify-content-center pb-5">
															<img src={img2} alt="" width="300px" heigh="300px" />
														</div>
														<h1 className="text-center start-title ">Start Conversation</h1>
														<p>chat with customers, workmates, and business partners and get live update about a parcel
															or product in transit</p>
													</>
												}
											</div>
										</div>
									</div>

								}
							</div>
						</div>
					</div>
				</BrowserView>
				<MobileView>
					<div className="mt-2 style web-bg">
						<div className="chat-contain-size">
							<div className="row">
								{mobileView ?
									<ChatUser data={(chatScreen, userChat, mobileView) =>
										this.setState({ chatScreen, userChat, mobileView })} />
									:
									<>
										{chats.length > 0 && this.state.chatScreen === 'chatScreen' && userChat.status === 'accepted' ?
											<Chat chats={chats} userChat={userChat} mobileView={mobileView}
												readChats={() => this.readChats()} deleteChats={(id) => this.chatListDeleteHandler(id)}
												chatClose={() => this.chatCloseHandler()} />
											:
											<div className="col-9 col-lg-7 col-xl-9 pl-0 d-none d-lg-block d-xl-block">
												<div className="bg-start-screen d-flex justify-content-center align-items-center">
													<div>
														<div className="pt-5 d-flex justify-content-center pb-5">
															<img src={img2} alt="" width="300px" heigh="300px" />
														</div>
														<h1 className="text-center start-title ">Start Conversation</h1>
														<p>chat with customers, workmates, and business partners and get live update about a parcel
															or product in transit</p>
													</div>
												</div>
											</div>
										}
									</>
								}

							</div>
						</div>
					</div>
				</MobileView>
			</div>
















			/* <MobileView>
				<div className="mt-2 style web-bg">
					<div className="chat-contain-size">
						<div className="row">
							{this.state.mobileView ?
								<ChatUser data={(message, chatScreen, status, chatId, senderId, name, mobileView) =>
									this.setState({
										message,
										chatScreen,
										status,
										chatId,
										senderId,
										name,
										mobileView
									})} />
								:
								<div className="col">
									<div>

										<>
											{chats.length >= 0 && chatScreen === 'chatScreen' && this.state.status === 'accepted' ?
												<div>
													<div className="header d-flex  align-items-center">
														<div className="row">
															<img src={profile ? url + profile : Logo} alt="" className="img-fluid profile-img ml-4" />
															<div className="d-flex justify-content-start align-items-center">
																<span className="pl-2 font-weight-bold chat-main-head-text">{name}</span>
															</div>
														</div>
														<div className="ml-auto pt-1">
															<i class="fa fa-times-circle-o close-icon" aria-hidden="true"
																onClick={this.chatCloseHandler}></i>
														</div>
													</div>
													<div class="row message " id="conversation">
														<div class="row message-previous ">

															<div className="row  message-previous">
																{chats.map(p => {
																	return (
																		<>
																			{p.user._id !== parseInt(this.state.userId) &&
																				<div className="col-12">
																					<>
																						<div className="chat-message" id="id_div_1">
																							<span className="chat-text-style">{p.text}</span>
																							<div>
																								<div class="remove_link">
																									<a href="#id_X_0">
																										<icon class="fa fa-times-circle icon_size16"
																											onClick={() => this.chatListDeleteHandler(p._id)}></icon>
																									</a>
																								</div>
																							</div>
																						</div>
																						<div>
																							<span className="date-style">{moment(p.createdAt).format('hh:mm')}</span>
																						</div>
																					</>
																				</div>
																			}
																			{p.user._id === parseInt(this.state.userId) &&
																				<div div className="col-12">
																					<>
																						<div className="d-flex justify-content-end">
																							<div className="chat-message-1" id="id_div_1">
																								<span className="chat-text-style">{p.text}</span>
																								<div>
																									<div class="remove_link">
																										<a href="#id_X_0">
																											<icon class="fa fa-times-circle icon_size16"
																												onClick={() => this.chatListDeleteHandler(p._id)}></icon>
																										</a>
																									</div>
																								</div>
																							</div>
																						</div>
																						<div className="d-flex justify-content-end">
																							<span className="date-style">{moment(p.createdAt).format('hh:mm')}</span>
																						</div>
																					</>
																				</div>
																			}
																		</>
																	)
																})}
															</div>
														</div>
													</div>
													<div class="row reply">
														<div className="col-10 d-flex justify-content-center align-items-center pr-0 pl-0">
															<input type="text" value={this.state.text} onKeyDown={this.keyPress}
																class="form-control mx-lg-3 mx-xl-3" rows="1" id="comment"
																placeholder="Write your message…" onChange={this.inputHandler} />
														</div>
														<div className="col-1 d-flex  align-items-center ">
															<button className="btn p-0" onClick={this.sendChatHandler}
																disabled={this.state.text === ''}>
																<i class="fa fa-paper-plane send-icon" aria-hidden="true"></i>
															</button>
														</div>
													</div>
												</div>
												:
												// loading ?
												// 	<div className="loader-style">
												// 		< ClipLoader
												// 			size={20}
												// 			color={'blue'}
												// 			loading={true}
												// 		/>
												// 	</div>
												// 	: !chats.length ?
												<div className="bg-start-screen d-flex justify-content-center">
													<div>
														<div className="pt-5 d-flex justify-content-center pb-5">
															<img src={img2} alt="" width="300px" heigh="300px" />
														</div>
														<h1 className="text-center start-title ">Start Conversation</h1>
														<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat, quam.</p>
													</div>
												</div>
											}
										</>
									</div>

								</div>
							}

						</div>
					</div>
				</div>
			</MobileView> */

		)
	}
}
