import Axios from "axios";
import { URLS } from "./URLS";


export const login = data => Axios.post(URLS.AUTH, data);
export const errandRequest = data => Axios.post(URLS.ERRAND_REQUEST, data)
export const signUp = data => Axios.post(URLS.SIGNUP, data);
export const verifyPhone = data => Axios.post(URLS.VERIFY_PHONE, data);
export const sendOTP = data => Axios.post(URLS.OTP, data);
export const restPassword = data => Axios.post(URLS.REST_PASSWORD, data);
export const createPassword = data => Axios.post(URLS.CREATE_PASSWORD, data);
