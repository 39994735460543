import React, { Component } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { getBannerSlides } from '../../api/Form';
import { toast } from 'react-toastify';

class VerticalCarousel extends Component {

    state = {
        slidesData: null
    }
    componentDidMount() {
        getBannerSlides().then((res) => {
            const response = res.data;
            const processedResponse = response.data?.map((data) => [data])
            this.setState({ slidesData: processedResponse });
        })
            .catch((err) => {
                toast.error(err.message)
            });
    }

    render() {
        const settings = {
            infinite: true,
            arrows: false,
            speed: 2000,
            slidesToShow: 2,
            slidesToScroll: 2,
            autoplay: true,
            autoplaySpeed: 4000,
            vertical: true,
            beforeChange: (oldIndex, newIndex) => {
                this.setState({ activeIndex: newIndex });
            },
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        arrows: true,
                        autoplay: true,
                        autoplaySpeed: 4000,
                        dots: false,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <div className="container vertical-carousel">
                <Slider {...settings} ref={this.slickRef}>
                    {this.state.slidesData?.map((group, index) => (
                        <div key={`VerticalSlider-${index}`} className="d-flex justify-content-between">
                            {group.map((item, idx) => (
                                <div key={`VerticalSlider-${index}-${idx}`} className="card booknow-slide">
                                    <p className='card-freight'>{item.freight}</p>
                                    <p className='card-country'>{item.country}</p>
                                    <p className='card-amount'>{item.price}</p>
                                    <Link to="/verifyEmail" className='book-now-btn'>Book Now</Link>
                                </div>
                            ))}
                        </div>
                    ))}
                </Slider>
            </div>


        );
    }
}

export default VerticalCarousel;
