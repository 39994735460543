const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
        {
            "@type": "Question",
            "name": "How can I track my international package?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "At Postkodes, use our global parcel tracking tool for accurate updates on your international package. Simply enter your tracking number, sea or air waybill or container number to track on our website or mobile app."
            }
        },
        {
            "@type": "Question",
            "name": "Do you offer cheap international shipping?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, Postkodes specializes in cheap international shipping without compromising on service quality. We offer competitive rates for all international destinations, ensuring your packages are delivered promptly and efficiently. Our goal is to provide affordable solutions for your global shipping needs."
            }
        },
        {
            "@type": "Question",
            "name": "How do I book a delivery online?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Deliveries can be booked by contacting any of our network of partners or by visiting our website, select the 'Request Errand' option, fill in the necessary details. Our user-friendly platform makes the booking process quick and convenient, ensuring a seamless experience for all customers."
            }
        },
        {
            "@type": "Question",
            "name": "What courier delivery services do you provide?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Postkodes through its partners offer a wide range of courier delivery services, including competitive local and international shipping services. Our services cater to both domestic and international needs, ensuring reliable and timely deliveries. We prioritize customer satisfaction in every service we provide."
            }
        },
        {
            "@type": "Question",
            "name": "What are the benefits of using your logistics SaaS?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "Whether you operate a local delivery business or an international shipping service provider, the benefits of using our SaaS is access to all the tools need to run a world class logistics service for as little as nothing or for a fraction of the cost. You will also gain access to our global network of partners offering increased market share, peace of mind and security."
            }
        },
        {
            "@type": "Question",
            "name": "I am a logistics service provider how do I start using your logistics SaaS?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "To join, simply register, we will do the standard validation and onboarding which is generally done with the 24 to 48 hours. There is no company wide deployment required so you can start operating once validation and onboarding has been completed. No credit card and no payment will either be required or asked to join."
            }
        },
        {
            "@type": "Question",
            "name": "How much does it cost to use your logistics SaaS?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "There is generally no cost, registration and use is free, however a small service fees will apply if you exceed the free usage quota."
            }
        }
    ]
};

export default faqSchema;
