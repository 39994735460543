import React, { Component } from 'react'
import { CardHeader, CardBody, Label, Card, FormGroup } from 'reactstrap';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { customTracking, getTrackingNimber, saveTrackingNimber, trackShipment, trackingMoreData } from '../../api/Form';
import moment from 'moment';
import { ClipLoader } from "react-spinners";
import ProgressBar from '../TrackShipments/components/ProgressBar';
import { toast } from 'react-toastify';
import { progreeOfTrcking } from '../../utils/utils';
import courierNameData from "../../components/courierData.json"
import Select from 'react-select';

export default class ShippingHistoryDetails extends Component {
  state = {
    trackDetails: [],
    loading: true,
    trackDetailsModal: false,
    completed: 0,
    etaShipment: "",
    deviceAdCode: "",
    userId: "",
    heartSave: false,
    savedList: [],
    completedData: "",
    classNameData: "",
    loaderNew: false,
    refNo: "",
    internalData: "",
    saveTrack: ""
  }

  async componentDidMount() {
    const deviceAdCode = await localStorage.getItem('adCodes');
    const nfcserialno = this.props.location.state
    this.setState({ deviceAdCode: deviceAdCode, refNo: nfcserialno })
    const userId = await localStorage.getItem('userId');
    this.setState({ userId: userId })
    this.getTrackNumber()
    trackShipment({ deviceAdCode, refno: nfcserialno, tripSerialId: nfcserialno })
      .then(Response => {
        this.setState({ saveTrack: Response.data.is_trackingno_saved, deviceAdCode: this.state.deviceAdCode, trackDetails: Response.data.data, internalData: Response.data.internal_data, etaShipment: Response.data.eta, loading: false, trackDetailsModal: true })
        const { completedData, classNameData } = this.props;
        const newState = progreeOfTrcking(completedData, classNameData, Response);
        this.setState(newState);
      })
      .catch(err => {
        console.log(err)
      })

    if (this.props.location.pathname === "/admin/trackShipmentsHome") {
      const refno = this.props.location.state;
      customTracking({ deviceAdCode: this.state.deviceAdCode, refno: refno, tripSerialId: refno })
        .then(Response => {
          this.setState({ saveTrack: Response.data.is_trackingno_saved, trackDetails: Response.data.data, internalData: Response.data.internal_data, etaShipment: Response.data.eta, tripSerialId: '', loading: false, trackDetailsModal: true })
          const { completedData, classNameData } = this.props;

          const newState = progreeOfTrcking(completedData, classNameData, Response);
          this.setState(newState);
        })
        .catch(err => {
          if (err.response?.status === 400) {
            this.setState({ trackMore: true, trackShipmentModal: false, loading: false })
          }
          toast.error(err.response?.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        })
    }
  }

  getTrackNumber = () => {
    getTrackingNimber({ user_id: this.state.userId })
      .then(Response => {
        this.setState({ savedList: Response.data.data })
      })
      .catch(err => {
        console.log(err)
      })
  }

  saveTrackingNimberApi = async () => {
    await saveTrackingNimber({
      user_id: this.state.userId,
      device_ad_code: this.state.deviceAdCode,
      ref_no: this.props.location.state
    }).then((response) => {
      this.setState({ loading: false, heartSave: true })
      toast.success('Tracking number saved successfully!!', {
        position: toast.POSITION.TOP_RIGHT
      })
    }).catch((err) => {
      this.setState({ loading: false, heartSave: false })
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      })
    })
  }

  render() {
    const { trackDetails } = this.state

    return (
      <div className="mt-4 style">
        <Card>
          <CardHeader className="c-header">
            {this.props.location.pathname === "/admin/trackShipmentsHome" ? "Track Shipments" : "Shipping History Details"}
          </CardHeader>
          <CardBody>
            {this.state.loading === true ?
              <div className="d-flex justify-content-center">
                < ClipLoader
                  size={15}
                  color={"#123abc"}
                  loading={true}
                />
              </div>
              :
              <>
                {this.state.trackDetailsModal &&
                  <div className="d-flex justify-content-center track-card">
                    <Card className="dashboard-card-style track-card-style shadow mb-4">
                      {/* <CardBody>
                        <div className='d-flex justify-content-end'>
                          {
                            this.state.heartSave === true || this.state?.internalData?.is_trackingno_saved === true || this.state?.saveTrack === true ?
                              <i class="fa fa-heart" style={{ fontSize: "28px", color: "red" }} role="button" ></i> :
                              <i class="fa fa-heart-o" style={{ fontSize: '28px' }} role="button" onClick={this.saveTrackingNimberApi} id='save-temp'></i>
                          }
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="row">
                              <div className="col-6">
                                <h1 className="title-main-head-track">Ref No</h1>
                              </div>
                              <div className="col-6">
                                <h1 className="title-description-profile-track">{trackDetails[0].refno ? trackDetails[0].refno : this.state.refNo}</h1>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="row">
                              <div className="col-6">
                                <h1 className="title-main-head-track">Trip Serial ID</h1>
                              </div>
                              <div className="col-6">
                                <h1 className="title-description-profile-track">{trackDetails[0].nfcserialno || ""}</h1>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="row">
                              <div className="col-6">
                                <h1 className="title-main-head-track">Quantity</h1>
                              </div>
                              <div className="col-6">
                                <h1 className="title-description-profile-track">{trackDetails[0].quantity || ""}</h1>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="row">
                              <div className="col-6">
                                <h1 className="title-main-head-track">Origin</h1>
                              </div>
                              <div className="col-6">
                                <h1 className="title-description-profile-track">{trackDetails[0].origin || ""}</h1>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="row">
                              <div className="col-6">
                                <h1 className="title-main-head-track">Destination</h1>
                              </div>
                              <div className="col-6">
                                <h1 className="title-description-profile-track">{trackDetails[0].destination || ""}</h1>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody> */}
                      <CardBody>
                        <div className='d-flex justify-content-end'>
                          {this.state.heartSave === true || this.state?.internalData?.is_trackingno_saved === true || this.state?.saveTrack === true ? (
                            <i className="fa fa-heart" style={{ fontSize: '28px', color: 'red' }} role="button"></i>
                          ) : (
                            <i className="fa fa-heart-o" style={{ fontSize: '28px' }} role="button" onClick={this.saveTrackingNimberApi} id='save-temp'></i>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-12 mb-3">
                            <div className="row">
                              <div className="col-6">
                                <h1 className="title-main-head-track">Ref No</h1>
                              </div>
                              <div className="col-6">
                                <h1 className="title-description-profile-track">{trackDetails[0].refno ? trackDetails[0].refno : this.state.refNo}</h1>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 mb-3">
                            <div className="row">
                              <div className="col-6">
                                <h1 className="title-main-head-track">Trip Serial ID</h1>
                              </div>
                              <div className="col-6">
                                <h1 className="title-description-profile-track">{trackDetails[0].nfcserialno || ""}</h1>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 mb-3">
                            <div className="row">
                              <div className="col-6">
                                <h1 className="title-main-head-track">Quantity</h1>
                              </div>
                              <div className="col-6">
                                <h1 className="title-description-profile-track">{trackDetails[0].quantity || ""}</h1>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 mb-3">
                            <div className="row">
                              <div className="col-6">
                                <h1 className="title-main-head-track">MOT</h1>
                              </div>
                              <div className="col-6">
                                <h1 className="title-description-profile-track">{trackDetails[0].modeOfTransport || ""}</h1>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 mb-3">
                            <div className="row">
                              <div className="col-6">
                                <h1 className="title-main-head-track">Origin</h1>
                              </div>
                              <div className="col-6">
                                <h1 className="title-description-profile-track">{trackDetails[0].origin || ""}</h1>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 mb-3">
                            <div className="row">
                              <div className="col-6">
                                <h1 className="title-main-head-track">Destination</h1>
                              </div>
                              <div className="col-6">
                                <h1 className="title-description-profile-track">{trackDetails[0].destination || ""}</h1>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>

                    </Card>
                  </div>
                }
                <>
                  {
                    this.state.trackDetailsModal &&
                    <>
                      <div className="timeline-progress">
                        <ProgressBar completed={this.state.completed} label={trackDetails} completedData={this.state.completedData} classNameData={this.state.classNameData} />
                      </div>
                      {
                        this.state.etaShipment &&
                        <div className='eta-progress pb-3'>
                          <Label className="pr-3">Estimated time of arrival : </Label>
                          <Label for="exampleEmail" className="label-description">{this.state.etaShipment}</Label>
                        </div>
                      }
                    </>
                  }
                </>
                {this.state.loaderNew === true ?
                  <div className="d-flex justify-content-center">
                    < ClipLoader
                      size={15}
                      color={"#123abc"}
                      loading={true}
                    />
                  </div>
                  :
                  <>
                    {
                      this.state.trackDetails.length > 0 &&
                      <VerticalTimeline layout='1-column' className="overflow-auto pb-0 vt-style" >
                        {trackDetails.map(a => {
                          return (
                            <VerticalTimelineElement
                              className="vertical-timeline-element--work "
                              date={
                                <div>
                                  <div className="row">
                                    <div className="col-12">
                                      <span className="pl-2 vertical-time-style"
                                        style={{ color: '#0000a0' }}>{a.date ? moment(a.date).format('DD-MM-yy') : moment(a.new_date_format).format('DD-MM-yy')}</span>
                                    </div>
                                    <div className="col-12 ">
                                      <span className="pl-2 vertical-time-style"
                                        style={{ color: '#0000a0' }}>{a.date ? moment(a.date).format("h:mm A") : moment(a.new_date_format).format("h:mm A")}</span>
                                    </div>
                                  </div>
                                </div>
                              }
                              iconStyle={{ background: '#fff', color: '#0000a0' }}
                            >
                              <Card className="shadow border-0">
                                <CardBody>
                                  <table className="track-table">
                                    <tr>
                                      <td>
                                        <Label for="exampleEmail " className="pr-5 label-style-tracking">Status</Label>
                                      </td>
                                      <td>
                                        <span class="badge badge-pill badge-primary px-2 font-weight-light ">
                                          {/* {
                                            a.trackingstatus &&
                                            <>
                                              {a.trackingstatus === "Delivered" ? "Collected" : a.trackingstatus}
                                            </>
                                          }
                                          {
                                            a.status &&
                                            <>
                                              {a.status === "delivered" ? "Collected" : a.status}
                                            </>
                                          }
                                          {
                                            a.track_status &&
                                            <>
                                              {a.track_status === "delivered" ? "Collected" : a.track_status}
                                            </>
                                          } */}
                                          {a.trackingstatus ? a.trackingstatus : a.status ? a.status : a.track_status}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <Label for="exampleEmail " className="pr-5 label-style-tracking">Quantity</Label>
                                      </td>
                                      <td>
                                        <Label for="exampleEmail" className="label-description">{a.quantity ? a.quantity : a.track_quantity}</Label>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <Label for="exampleEmail " className="pr-5 label-style-tracking">Comment</Label>
                                      </td>
                                      <td>
                                        <Label for="exampleEmail" className="label-description">{a.comment ? a.comment : a.track_comment}</Label>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <Label for="exampleEmail " className="pr-5 label-style-tracking">Location</Label>
                                      </td>
                                      <td>
                                        <Label for="exampleEmail" className="label-description">{a.location ? a.location : a.track_location}</Label>
                                      </td>
                                    </tr>
                                  </table>
                                </CardBody>
                              </Card>
                            </VerticalTimelineElement>
                          );
                        })}
                      </VerticalTimeline>
                    }
                  </>
                }
              </>}
          </CardBody>
        </Card>
      </div>
    )
  }
}
