import React, { Component } from 'react'
import { Card, CardBody, CardHeader, } from 'reactstrap'
import BarcodeScannerComponent from "react-webcam-barcode-scanner";

export default class QRcode extends Component {
  state = {
    delay: 100,
    scan: 'Scan QR Code',
  }

  barcodeHandler = (result) => {
    if (result) {
      this.setState({
        barCodeScan: result.text
      })
      this.props.scan(result);
    }
  }
  render() {
    return (
      <div>
        <div className="mt-2 style">
          <Card>
            <CardHeader className="c-header">Scan Parcel</CardHeader>
            <CardBody>
              <BarcodeScannerComponent
                width={500}
                height={500}
                onUpdate={(err, result) => this.barcodeHandler(err, result)}
              />
              <p>{this.state.barCodeScan}</p>
            </CardBody>
          </Card>
        </div>
      </div>
    )
  }
}
