import React, { Component } from 'react'
import { Card, CardBody, Label, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledTooltip } from 'reactstrap';
import classnames from 'classnames';
import './TrackShipments.css'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { incomingShipments, outgoingShipments, customTracking, saveTrackingNimber, getTrackingNimber, deleteTrackingNimber } from '../../api/Form';
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { callActivityApi } from '../../utils/saveUserActivity';
import ProgressBar from './components/ProgressBar';
import { ClipLoader } from 'react-spinners';
import { phoneNoVerifyCheck, progreeOfTrcking, trim } from '../../utils/utils';
import product from '../../assets/product.png'
export default class TrackShipments extends Component {
	state = {
		activeTab: '1',
		deviceAdCode: '',
		value: '',
		trackShipment: [],
		incomingShipments: [],
		outgoingShipments: [],
		refno: '',
		loading: true,
		trackShipmentModal: false,
		completed: 0,
		trackMore: false,
		courierName: "",
		etaShipment: "",
		saveRefNo: '',
		userId: '',
		savedList: [],
		heartSave: false,
		seeMore: false,
		completedData: "",
		classNameData: "",
		loaderNew: false,
		internalData: "",
		deleteId: ""
	}


	packageHandler = (refno) => {
		this.props.history.push({ pathname: './outGoingPackageHandler', state: refno })
	}

	incomingPackageHandler = (refno) => {
		this.props.history.push({ pathname: './incomingPackageHandler', state: refno })
	}

	async componentDidMount() {
		const { refno } = this.props.location.state || {}
		this.setState({ refno })

		callActivityApi({ 'pageName': 'Track Shipments', 'description': 'User has visited Track shipments Screen' });
		const historyPush = this.props.history.push
		phoneNoVerifyCheck(historyPush)
		const deviceAdCode = await localStorage.getItem('adCodes');
		this.setState({ deviceAdCode })
		const userId = await localStorage.getItem('userId');
		this.setState({ userId })

		incomingShipments({ deviceAdCode })
			.then(Response => {
				this.setState({ incomingShipments: Response.data.data })
			})
			.catch(err => {
				console.log(err)
			})
		outgoingShipments({ deviceAdCode })
			.then(Response => {
				this.setState({ outgoingShipments: Response.data.data, loading: false })
			})
			.catch(err => {
				console.log(err)
			})
		this.getTrackNumber()
	}

	getTrackNumber = () => {
		getTrackingNimber({ user_id: this.state.userId })
			.then(Response => {
				this.setState({ savedList: Response.data.data })
			})
			.catch(err => {
				this.setState({
					savedList: [],
				})
				console.log(err)
			})
	}

	handleChange = (e) => {
		this.setState({ refno: e.target.value })
	}

	searchTracksShipment = (refData) => {
		if (this.state.refno || refData) {
			this.setState({
				loaderNew: true,
				heartSave: false
			})
			customTracking({ deviceAdCode: this.state.deviceAdCode, refno: this.state.refno ? this.state.refno : refData, tripSerialId: this.state.refno ? this.state.refno : refData })
				.then(Response => {
					this.setState({ trackShipment: Response.data.data, internalData: Response.data.internal_data, etaShipment: Response.data.eta, tripSerialId: '', trackShipmentModal: true, trackMore: false })
					const { completedData, classNameData } = this.props;
					const newState = progreeOfTrcking(completedData, classNameData, Response);
					this.setState(newState);
					this.setState({
						loaderNew: false
					})
				})
				.catch(err => {
					if (err.response?.status === 400) {
						this.setState({ trackMore: true, trackShipmentModal: false })
					}
					toast.error(err.response?.data.message, {
						position: toast.POSITION.TOP_RIGHT
					});
					this.setState({
						loaderNew: false
					})
				})
		} else {
			toast.error(" please enter a valid ref no OR TSID to track.", {
				position: toast.POSITION.TOP_RIGHT
			});
			this.setState({ trackShipment: '', tripSerialId: '', trackShipmentModal: true })
		}
	}

	keyPress = (e) => {
		if (e.keyCode === 13) {
			this.searchTracksShipment()
		}
	}

	toggle = (tab) => {
		if (this.state.activeTab !== tab) {
			this.setState({ activeTab: tab });
		}
	}

	outgoingTrackHandler = (nfcserialno) => {
		this.props.history.push({ pathname: './trackShipmentsDetails', state: nfcserialno })
	}

	incomingTrackHandler = (nfcserialno) => {
		this.props.history.push({ pathname: './trackShipmentsDetailsIncoming', state: nfcserialno })
	}

	handleChangeCourier = (e) => {
		this.setState({ courierName: e.value })
	}

	saveTrackingNimberApi = async () => {
		await saveTrackingNimber({
			user_id: this.state.userId,
			device_ad_code: this.state.deviceAdCode,
			ref_no: this.state.refno || this.state.trackShipment[0].refno
		}).then((response) => {
			this.getTrackNumber()
			this.setState({ loading: false, heartSave: true })
			toast.success('Tracking number saved successfully!!', {
				position: toast.POSITION.TOP_RIGHT
			})
		}).catch((err) => {
			this.setState({ loading: false, heartSave: false })
			toast.error(err.response.data.message, {
				position: toast.POSITION.TOP_RIGHT
			})
		})
	}

	deleteTrackingNumberApi = async (id) => {
		await deleteTrackingNimber({
			user_id: this.state.userId,
			id: id,
		}).then((response) => {
			this.setState({ loading: false })
			this.getTrackNumber()
			toast.success('Tracking number delete successfully!!', {
				position: toast.POSITION.TOP_RIGHT
			})
		}).catch((err) => {
			this.setState({ loading: false })
			toast.error(err.response.data.message, {
				position: toast.POSITION.TOP_RIGHT
			})
		})
	}

	trackeData = (data) => {
		console.log(data, "trackeData");
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.refno && prevState.refno !== this.state.refno && this.state.refno.length === 11) {
			this.searchTracksShipment();
		}
	}

	render() {
		const incoming = this.state.incomingShipments.map(p => p)
		const outgoing = this.state.outgoingShipments.map(p => p)
		const lastIndex = this.state.trackShipment.length - 1;

		return (
			<div className="mt-2 style">
				<Card>
					<CardBody>
						{this.state.activeTab === '1' &&
							<div className='d-flex justify-content-end'>
								<div class="input-group col-md-4 p-0 pb-3 pb-sm-3 pb-md-0 pb-lg-0 pb-xl-0">
									<input class="form-control py-2 border-right-0 border" value={this.state.refno}
										onChange={this.handleChange} onKeyDown={this.keyPress}
										placeholder="Enter Ref No OR Trip Serial ID" />
									<span class="input-group-append">
										<button class="btn btn-outline-secondary border-left-0 border" type="button" style={{ 'z-index': 0 }}
											onClick={() => {
												this.searchTracksShipment()
											}}>
											<i class="fa fa-search"></i>
										</button>
									</span>
								</div>
							</div>
						}

						<Nav tabs>
							<NavItem>
								<NavLink
									className={classnames({ active: this.state.activeTab === '1' })}
									onClick={() => {
										this.toggle('1');
									}}
								>
									Track Shipments
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classnames({ active: this.state.activeTab === '2' })}
									onClick={() => {
										this.toggle('2');
									}}
								>
									Outgoing Shipments
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classnames({ active: this.state.activeTab === '3' })}
									onClick={() => {
										this.toggle('3');
									}}
								>
									Incoming Shipments
								</NavLink>
							</NavItem>
						</Nav>
						<TabContent activeTab={this.state.activeTab} className="pt-5">

							{
								this.state.loaderNew ?
									<div className='d-flex justify-content-center'>
										<ClipLoader />
									</div> :
									<TabPane tabId="1">
										<div>
											<span>Saved tracking number</span>
											{
												this.state.savedList.length ?
													<>
														{
															this.state.seeMore ? null :
																<>
																	{
																		this.state.savedList && this.state.savedList.slice(0, 3).map((ref) => {
																			return (
																				<Card className="mt-1" style={{ width: "12%", minWidth: "160px" }}>
																					<CardBody>
																						<div className='d-flex justify-content-center align-items-center tip-tool-color'>
																							<li key={ref.ref_no} role='button' onClick={() => {
																								this.searchTracksShipment(ref.ref_no)
																								this.setState({ deleteId: ref.ref_no, refno: "" })
																							}} style={{ listStyle: "none" }}>{ref.ref_no}</li>
																							<i class='fa fa-trash icons-remove' style={{ fontSize: "20px", color: "red" }} role='button' id='delete-temp' onClick={() => this.deleteTrackingNumberApi(ref.id)}></i>
																							<UncontrolledTooltip placement="top" target="delete-temp" >
																								Delete save tracking number
																							</UncontrolledTooltip>
																						</div>
																					</CardBody>
																				</Card>
																			)
																		})
																	}
																</>
														}
														{
															this.state.seeMore ?
																<>
																	{
																		this.state.savedList && this.state.savedList.map((ref) => {
																			return (
																				<Card className="mt-1" style={{ width: "12%", minWidth: "160px" }}>
																					<CardBody>
																						<div className='d-flex justify-content-center align-items-center'>
																							<li key={ref.ref_no} role='button' onClick={() => this.searchTracksShipment(ref.ref_no)} style={{ listStyle: "none" }}>{ref.ref_no}</li>
																							<i class='fa fa-trash icons-remove' style={{ fontSize: "20px", color: "red" }} role='button' id='delete-temp' onClick={() => this.deleteTrackingNumberApi(ref.id)}></i>
																							<UncontrolledTooltip placement="top" target="delete-temp">
																								Delete save tracking number
																							</UncontrolledTooltip>
																						</div>
																					</CardBody>
																				</Card>
																			)
																		})
																	}
																</>
																:
																null
														}
														{
															this.state.savedList.length > 3 &&
															<>
																{
																	this.state.seeMore ?
																		<div className='mt-2'>
																			<a href="javascript:void(0)" className='text-success p-1' onClick={() => this.setState({ seeMore: false })}>See less...</a>
																		</div>
																		:
																		<div className='mt-2'>
																			<a href="javascript:void(0)" className='text-success p-1' onClick={() => this.setState({ seeMore: true })}>See more...</a>
																		</div>
																}
															</>
														}
													</> :
													<div>
														{/* <span>Tracking number not saved</span> */}
													</div>
											}
										</div>
										{this.state.trackShipmentModal && this.state.trackShipment &&
											<div className="d-flex justify-content-center track-card">
												<Card className="dashboard-card-style track-card-style shadow mb-4">
													<CardBody>
														<div className='d-flex justify-content-end'>
															{
																this.state.heartSave === true || this.state.internalData.is_trackingno_saved ?
																	<i class="fa fa-heart" style={{ fontSize: "28px", color: "red" }} role="button" ></i> :
																	<i class="fa fa-heart-o" style={{ fontSize: '28px' }} role="button" onClick={this.saveTrackingNimberApi} id='save-temp'></i>
															}
														</div>
														<div className="row">
															<div className="col-6">
																<div className="row">
																	<div className="col-6">
																		<h1 className="title-main-head-track">Ref No</h1>
																	</div>
																	<div className="col-6">
																		<h1 className="title-description-profile-track">{this.state.refno || this.state?.internalData?.refno || this.state.deleteId}</h1>
																	</div>
																</div>
															</div>
															<div className="col-6">
																<div className="row">
																	<div className="col-6">
																		<h1 className="title-main-head-track">Trip Serial ID</h1>
																	</div>
																	<div className="col-6">
																		<h1
																			className="title-description-profile-track">{this.state?.internalData?.nfcserialno || ""}</h1>
																	</div>
																</div>
															</div>
															<div className="col-6">
																<div className="row">
																	<div className="col-6">
																		<h1 className="title-main-head-track">Quantity</h1>
																	</div>
																	<div className="col-6">
																		<h1
																			className="title-description-profile-track">{this.state?.internalData?.quantity || ""}</h1>
																	</div>
																</div>
															</div>
															<div className="col-6">
																<div className="row">
																	<div className="col-6">
																		<h1 className="title-main-head-track">MOT</h1>
																	</div>
																	<div className="col-6">
																		<h1
																			className="title-description-profile-track">{this.state?.internalData?.mot || ""}</h1>
																	</div>
																</div>
															</div>
															<div className="col-6">
																<div className="row">
																	<div className="col-6">
																		<h1 className="title-main-head-track">Origin</h1>
																	</div>
																	<div className="col-6">
																		<h1 className="title-description-profile-track">{this.state?.internalData?.origin || ""}</h1>
																	</div>
																</div>
															</div>
															<div className="col-6">
																<div className="row">
																	<div className="col-6">
																		<h1 className="title-main-head-track">Destination</h1>
																	</div>
																	<div className="col-6">
																		<h1
																			className="title-description-profile-track">{this.state?.internalData?.destination || ""}</h1>
																	</div>
																</div>
															</div>
														</div>
													</CardBody>
												</Card>
											</div>
										}
										{this.state.trackShipmentModal && this.state.trackShipment &&
											<>
												<div className="timeline-progress">
													<ProgressBar completedData={this.state.completedData} completed={this.state.completed} label={this.state.trackShipment} classNameData={this.state.classNameData} />
												</div>
												{
													this.state.etaShipment &&
													<div className='eta-progress pb-3'>
														<Label className="pr-3">Estimated time of arrival : </Label>
														<Label for="exampleEmail" className="label-description">{this.state.etaShipment}</Label>
													</div>
												}
											</>
										}
										{this.state.trackShipmentModal && this.state.trackShipment &&
											<>
												<VerticalTimeline layout='1-column' className="overflow-auto pb-0 vt-style">
													{this.state.trackShipment.map(a => {
														return (
															<VerticalTimelineElement
																className="vertical-timeline-element--work "
																date={
																	<div>
																		<div className="row">
																			<div className="col-12">
																				<span className="pl-2 vertical-time-style"
																					style={{ color: '#0000a0' }}>{a.date ? moment(a.date).format('DD-MM-yy') : moment(a.new_date_format).format('DD-MM-yy')}</span>
																			</div>
																			<div className="col-12 ">
																				<span className="pl-2 vertical-time-style"
																					style={{ color: '#0000a0' }}>{a.date ? moment(a.date).format("h:mm A") : moment(a.new_date_format).format("h:mm A")}</span>
																			</div>
																		</div>
																	</div>
																}
																iconStyle={{ background: '#fff', color: '#0000a0' }}>
																<Card className="shadow border-0">
																	<CardBody>
																		<table className="track-table">
																			<tr>
																				<td>
																					<Label for="exampleEmail " className="pr-5 label-style-tracking">Status</Label>
																				</td>
																				<td>
																					<span class="badge badge-pill badge-primary px-2 font-weight-light ">{a.trackingstatus ? a.trackingstatus : a.status ? a.status : a.track_status}</span>
																				</td>
																			</tr>
																			<tr>
																				<td>
																					<Label for="exampleEmail " className="pr-5 label-style-tracking">Quantity</Label>
																				</td>
																				<td>
																					<Label for="exampleEmail" className="label-description">{a.quantity ? a.quantity : a.track_quantity}</Label>
																				</td>
																			</tr>
																			<tr>
																				<td>
																					<Label for="exampleEmail " className="pr-5 label-style-tracking">Comment</Label>
																				</td>
																				<td>
																					<Label for="exampleEmail" className="label-description">{a.comment ? a.comment : a.track_comment}</Label>
																				</td>
																			</tr>
																			<tr>
																				<td>
																					<Label for="exampleEmail " className="pr-5 label-style-tracking">Location</Label>
																				</td>
																				<td>
																					<Label for="exampleEmail" className="label-description">{a.location ? a.location : a.track_location}</Label>
																				</td>
																			</tr>
																		</table>
																	</CardBody>
																</Card>
															</VerticalTimelineElement>
														);
													})}
												</VerticalTimeline>
											</>
										}
									</TabPane>
							}
							<TabPane tabId="2">
								<div className="row">
									{this.state.loading === true ?
										<div className="d-flex justify-content-center">
											< ClipLoader
												size={15}
												color={"#123abc"}
												loading={true}
											/>
										</div>
										:
										<>
											{outgoing.map(p => {
												return (
													<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 pb-4">
														<Card className="shadow border-0">
															<CardBody>
																<div className="row">
																	<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style"
																					style={{ whiteSpace: "nowrap" }}>Ref No </Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{trim(p.refno, 13)}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style"
																					style={{ whiteSpace: "nowrap" }}>Serial No</Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{trim(p.nfcserialno, 13)}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style">Origin</Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{trim(p.origin, 13)}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style">Destination</Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{trim(p.destination, 13)}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12">
																		<div className="row">
																			<div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3">
																				<Label for="exampleEmail " className="pr-5 label-style">Quantity</Label>
																			</div>
																			<div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3">
																				<Label for="exampleEmail"
																					className="label-description">{trim(p.quantity, 13)}</Label>
																			</div>
																		</div>
																	</div>
																	{/* <div className="col-12">
																		<div className="row">
																			<div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3">
																				<Label for="exampleEmail " className="pr-5 label-style">MOT</Label>
																			</div>
																			<div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3">
																				<Label for="exampleEmail"
																					className="label-description">{p?.modeOfTransport}</Label>
																			</div>
																		</div>
																	</div> */}
																	<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style">Status</Label>
																			</div>
																			<div className="col-6">
																				<span
																					class="badge badge-pill badge-primary font-weight-light px-2">{p.trackingstatus}</span>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="d-flex justify-content-end">
																			<div className="row d-flex justify-content-center">
																				<div className="d-flex justify-content-end pr-2">
																					<button className="btn btn-submit-track px-4"
																						onClick={() => this.outgoingTrackHandler(p.nfcserialno)}>Track
																					</button>
																				</div>
																				<div className="d-flex justify-content-end ">
																					<button className="btn btn-submit-package px-4"
																						onClick={() => this.packageHandler(p.refno)}><img src={product} alt=""
																							className="img-fluid" />
																					</button>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>

															</CardBody>
														</Card>

													</div>

												)
											})}
										</>}
								</div>
							</TabPane>
							<TabPane tabId="3">
								<div className="row">
									{incoming.map(p => {
										return (
											<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 pb-4">
												<Card className="shadow border-0">
													<CardBody>
														<div className="row">
															<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																<div className="row">
																	<div className="col-6">
																		<Label for="exampleEmail " className="pr-5 label-style"
																			style={{ whiteSpace: "nowrap" }}>Ref No </Label>
																	</div>
																	<div className="col-6">
																		<Label for="exampleEmail" className="label-description">{trim(p.refno, 13)}</Label>
																	</div>
																</div>
															</div>
															<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																<div className="row">
																	<div className="col-6">
																		<Label for="exampleEmail " className="pr-5 label-style"
																			style={{ whiteSpace: "nowrap" }}>Serial No</Label>
																	</div>
																	<div className="col-6">
																		<Label for="exampleEmail"
																			className="label-description">{trim(p.nfcserialno, 13)}</Label>
																	</div>
																</div>
															</div>
															<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																<div className="row">
																	<div className="col-6">
																		<Label for="exampleEmail " className="pr-5 label-style">Origin</Label>
																	</div>
																	<div className="col-6">
																		<Label for="exampleEmail" className="label-description">{trim(p.origin, 13)}</Label>
																	</div>
																</div>
															</div>
															<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																<div className="row">
																	<div className="col-6">
																		<Label for="exampleEmail " className="pr-5 label-style">Destination</Label>
																	</div>
																	<div className="col-6">
																		<Label for="exampleEmail"
																			className="label-description">{trim(p.destination, 13)}</Label>
																	</div>
																</div>
															</div>
															<div className="col-12">
																<div className="row">
																	<div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3">
																		<Label for="exampleEmail " className="pr-5 label-style">Quantity</Label>
																	</div>
																	<div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3">
																		<Label for="exampleEmail"
																			className="label-description">{trim(p.quantity, 13)}</Label>
																	</div>
																</div>
															</div>
															<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																<div className="row">
																	<div className="col-6">
																		<Label for="exampleEmail " className="pr-5 label-style">Status</Label>
																	</div>
																	<div className="col-6">
																		<span
																			class="badge badge-pill badge-primary font-weight-light px-2">{p.trackingstatus}</span>
																	</div>
																</div>
															</div>
															<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																<div className="d-flex justify-content-end">
																	<div className="row d-flex justify-content-center">
																		<div className="d-flex justify-content-end pr-2">
																			<button className="btn btn-submit-track px-4"
																				onClick={() => this.incomingTrackHandler(p.nfcserialno)}>Track
																			</button>
																		</div>
																		<div className="d-flex justify-content-end pr-2">
																			<button className="btn btn-submit-package px-4"
																				onClick={() => this.incomingPackageHandler(p.refno)}><img src={product}
																					alt=""
																					className="img-fluid" />
																			</button>
																		</div>
																	</div>
																</div>
															</div>
														</div>

													</CardBody>
												</Card>

											</div>

										)
									})}
								</div>
							</TabPane>
						</TabContent>
					</CardBody>
				</Card>
			</div >
		)
	}
}
