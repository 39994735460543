import React, { Component } from 'react'
import playstore from '../../assets/google-playstore.png'
import apple from '../../assets/apple.png'
import phoneImg from '../../assets/phone1.jpg'
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';


class AppSection extends Component {
    state = {
        refno: '',
        trackBtnClicked: false
    }
    goToTracking = () => {
        if (this.state.refno) {
            this.props.history.push('/trackShipment/' + this.state.refno)
        }
        this.setState({ trackBtnClicked: true })
    }
    render() {
        return (
            <>
                <section className="logistic-section">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <h2>Trusted By Logistics Firms, Marketplaces, And Sellers Globally.</h2>
                                <div className='row-register-track'>
                                    <p>Global Parcel Tracking. Index 1000+ Carriers Airlines and Sealines</p>
                                    <div className="tracking-form">
                                        <input value={this.state.refno} onChange={(e) => this.setState({ refno: e.target.value })} placeholder="Enter Tracking Number or Container Number" name="name" type="text" className="form-control mr-1 form-control" />
                                        <button onClick={() => this.goToTracking()} className="btn btn-primary">Track Now</button>
                                    </div>
                                    {this.state.trackBtnClicked && this.state.refno.trim() === '' &&
                                        <p className='track-error'>Tracking Number or Container Number required</p>}
                                </div>
                                <div className='logistic-firm-counter'>
                                    <div className='firm-counter'>
                                        <h3>34000+</h3>
                                        <span>Connected Shipments</span>
                                    </div>
                                    <div className='firm-counter'>
                                        <h3>99.9%</h3>
                                        <span>Uptime</span>
                                    </div>
                                    <div className='firm-counter'>
                                        <h3>112000+</h3>
                                        <span>Tracked Shipments</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="appSection">
                    <div className='container'>
                        <div className="row">
                            <div className="col-md-8">
                                <h2>Postkodes Mobile App</h2>
                                <p>Perfect for consumers, e-commerce businesses, and small to medium sized couriers. Postkodes is a platform designed to make parcel handling seamless. Embedded with IoT technology, Postkodes enables users to create shipment, print shipping documents & labels, scan parcels, track parcels & set-up sms & email tracking alerts for any parcel. Along with other great features, users can also exchange shipping documents, request a click & collect or courier collect by proxy (COPY) service.
                                    Our universal parcel tracking solution indexes more than 1000 carriers worldwide including all major couriers and airlines.</p>
                                <div className="app-store-links">
                                    <Link to={{ pathname: "https://play.google.com/store/apps/details?id=com.autonomously&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" }} target="_blank">
                                        <img src={playstore} alt="" className="img-fluid pr-1" width="120px" height="120px" />
                                    </Link>
                                    <Link to={{ pathname: "https://apps.apple.com/us/app/autonomously/id1510545062?mt=8" }} target="_blank">
                                        <img src={apple} alt="" className="img-fluid pl-1" width="120px" height="120px" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <img src={phoneImg} alt="phone" />
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
export default withRouter(AppSection)