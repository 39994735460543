import React, { Component } from 'react'
import { Button, Popover, PopoverBody, UncontrolledPopover, UncontrolledTooltip } from 'reactstrap';
export default class PopOverCard extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            popoverOpen: false
        };
    }
    // async componentDidMount() {
    //     var x = document.getElementsByClassName("rdt_TableRow");
    //     for (let i = 0; i < x.length; i++) {
    //         x[i].id = "ScheduleUpdateTooltip"
    //     }
    // }
    toggle() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }

    render() {
        return (
            <div className='hkczvkjzxh'>
                <div role='button' id="ScheduleUpdateTooltip" className='text-primary p-1'>
                    {this.props.refno.refno}
                </div>
                <div className="text-center">
                    <UncontrolledTooltip
                        placement="right"
                        target="ScheduleUpdateTooltip"
                        trigger="click"
                        className="hello-uncccc"
                    >
                        {/* <i class="fa fa-times cross-button" role='button' onClick={this.toggle} id="ScheduleUpdateTooltip"></i> */}

                        <a href="javascript:void(0)" onClick={() => this.props.editShipment(this.props.refno.refno)} className='p-1 pop-editdata'>
                            <span>
                                <i class="fa fa-pencil mr-2" aria-hidden="true"></i>Edit Shipment
                            </span>
                        </a>
                        <br />
                        <a href="javascript:void(0)" onClick={() => this.props.goToPrintLabel(this.props.refno.refno)} className='p-1 pop-editdata'>
                            <span>
                                <i class="fa fa-print mr-2" aria-hidden="true"></i>Print Label
                            </span>
                        </a>
                        <br />
                        <a href="javascript:void(0)" onClick={() => this.props.createInvoice(this.props.refno.refno)} className='p-1 pop-editdata'>
                            <span>
                                <i class="fa fa-plus mr-2" aria-hidden="true"></i>Create invoice
                            </span>
                        </a>
                        <br />
                        <a href="javascript:void(0)" onClick={() => this.props.deleteEntry(this.props.refno.refno, this.props.refno.id)} className='p-1 pop-editdata'>
                            <span>
                                <i class="fa fa-trash mr-2" aria-hidden="true"></i>Delete
                            </span>
                        </a><br />
                        <a href="javascript:void(0)" onClick={() => this.props.trackShipment(this.props.refno.refno)} className='p-1 pop-editdata'>
                            <span>
                                <i class="fa fa-pencil mr-2" aria-hidden="true"></i>Track Shipment
                            </span>
                        </a>
                    </UncontrolledTooltip>
                </div>
            </div>
        )
    }
}
