const webPageSchema = {
    "@context": "https://schema.org",
    "@graph": [
        {
            "@type": "Organization",
            "@id": "https://postkodes.com/#organization",
            "url": "https://postkodes.com/",
            "name": "Postkodes",
            "sameAs": []
        },
        {
            "@type": "WebSite",
            "@id": "https://postkodes.com/#website",
            "url": "https://postkodes.com/",
            "name": "Postkodes",
            "publisher": {
                "@id": "https://postkodes.com/#organization"
            }
        },
        {
            "@type": "WebPage",
            "@id": "https://postkodes.com/#webpage",
            "url": "https://postkodes.com/",
            "inLanguage": "en-US",
            "name": "Global Parcel Tracking - Shipment & Delivery | Postkodes",
            "isPartOf": {
                "@id": "https://postkodes.com/#website"
            },
            "datePublished": "2024-08-22T00:00:00-08:00",
            "dateModified": "2024-08-22T00:00:00-08:00",
            "description": "Need cheap international shipping? Postkodes provides global parcel tracking, pickup services, and worldwide delivery solutions at great prices."
        }
    ]
};

export default webPageSchema;
