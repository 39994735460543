import 'react-tagsinput/react-tagsinput.css'
import React, { Component, Fragment } from 'react'
import { CardHeader, CardBody, FormGroup, Label, Input, Card, FormFeedback, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import * as Yup from 'yup';
import { Formik } from "formik";
import { ClipLoader } from "react-spinners";
import Select from 'react-select';
import 'react-phone-number-input/style.css'
import { updateExternalRefNums, checkScreenAccess, requestAccess, getExternalTrackingDetail, validateExternalRefNum } from '../../api/Form';
import { toast } from 'react-toastify';
import classnames from 'classnames'
import PhoneInput from 'react-intl-tel-input';
import { isValidNumber } from 'libphonenumber-js';
import 'react-intl-tel-input/dist/main.css';

const Schema = Yup.object().shape({
    externalRefNo: Yup.string()
        .matches(
            /^[a-zA-Z0-9]+$/,
            "No special characters"
        )
        .required('External Tracking Number is required'),
    externalCourierName: Yup.string()
        .trim()
        .required('External Courier Name is required'),
    emailAddress1: Yup.string()
        .trim().nullable()
        .email('Invalid Email Address'),
    emailAddress2: Yup.string()
        .trim().nullable()
        .email('Invalid Email Address')
});

const websitesOption = [
    { 'label': 'https://auspost.com.au/', 'value': 'https://auspost.com.au/' },
    { 'label': 'https://www.qrcargo.com/', 'value': 'https://www.qrcargo.com/' },
    { 'label': 'https://trackingmore.com/', 'value': 'https://trackingmore.com/' }
];



export default class EditUnassignExternal extends Component {
    input = React.createRef();
    PhoneNumber1Ref = React.createRef();
    PhoneNumber2Ref = React.createRef();
    state = {
        deviceADcode: '',
        userId: '',
        nfcADcode: '',
        loading: true,
        internalRefno: '',
        externalRefNo: '',
        externalCourierName: '',
        externalCourierWebsite: '',
        emailAddress1: '',
        emailAddress2: '',
        phoneNumber1: '',
        phoneNumber2: '',
        extTrackingId: '',
        isValidPhoneNo: true,
        phoneNumber1IsValidPhoneNo: true,
        phoneNumber2IsValidPhoneNo: true,
    }

    addClick = (array) => {
        array.insert(1, {
            nfcADcode: ''
        })
    }

    async componentDidMount() {
        this.setState({ loading: true });
        const trackingId = this.props.location.state;
        getExternalTrackingDetail({
            extTrackingId: trackingId
        }).then(Response => {
            this.setState({
                extTrackingId: trackingId,
                internalRefno: Response.data.data.internalRefno,
                externalRefNo: Response.data.data.externalRefno,
                externalCourierName: Response.data.data.externalCourierName,
                externalCourierWebsite: Response.data.data.externalCourierWebsite,
                emailAddress1: Response.data.data.emailAddress1,
                emailAddress2: Response.data.data.emailAddress2,
                phoneNumber1: Response.data.data.phoneNumber1,
                phoneNumber2: Response.data.data.phoneNumber2,
                loading: false,
            })
            this.PhoneNumber1Ref.current.setNumber(Response.data.data.phoneNumber1);
            this.PhoneNumber2Ref.current.setNumber(Response.data.data.phoneNumber2);
        }).catch(err => {
            let message = err.message;
            if (err.response && err.response.data.message) {
                message = err.response.data.message;
            }
            toast.error(message, {
                position: toast.POSITION.TOP_RIGHT
            });
            this.props.history.push('/admin/index');
            this.setState({ loading: false });
        })
        const deviceADcode = await localStorage.getItem('adCodes');
        this.setState({ deviceADcode })
        const userId = await localStorage.getItem('userId');
        this.setState({ userId })
        var userType = await localStorage.getItem('accounttypeUsers');

        if (userType !== 'Admin') {
            checkScreenAccess(
                {
                    'userId': userId,
                    'screenType': 'Assign External Tracking Number'
                }
            ).then((response) => {
                this.setState({ loading: false, requestAccess: true });
            }).catch(err => {
                this.setState({ loading: false, requestAccess: false });
            });
        } else {
            this.setState({ requestAccess: true, loading: false })
        }
    }

    submitHandler = (values) => {

        this.setState({ loading: true });
        const data = {
            internalRefno: values.internalRefno,
            externalRefNo: values.externalRefNo,
            externalCourierName: values.externalCourierName,
            externalCourierWebsite: 'https://trackingmore.com/',
            emailAddress1: values.emailAddress1,
            emailAddress2: values.emailAddress2,
            phoneNumber1: values.phoneNumber1,
            phoneNumber2: values.phoneNumber2,
            trackingId: this.state.extTrackingId,
            mode: 'external'
        };

        updateExternalRefNums(data)
            .then(response => {
                toast.success('Entry updated successfully!!', {
                    position: toast.POSITION.TOP_RIGHT
                });
                this.props.history.push('/admin/externalTrackingListUnassign')
            })
            .catch(err => {
                let message = err.message;
                if (err.response && err.response.data.message) {
                    if (err.response && err.response.data.data && err.response.data.data.length !== 0) {
                        const label = this.printErrors(err.response.data.data);
                        message = label;
                    } else {
                        message = err.response.data.message;
                    }
                }
                toast.error(message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                this.setState({ loading: false });
            })

    }

    printErrors = (obj) => {
        var string = '';

        for (var k in obj) {
            if (obj[k] instanceof Object) {
                this.printErrors(obj[k]);
            } else {
                console.log(obj[k] + "<br>");
                if (string) {
                    string += ', ' + obj[k];
                } else {
                    string += obj[k];
                }

            };
        }
        return string;
    };

    goBack = () => {
        if (window.confirm("Are you sure to want back?")) {
            this.props.history.push('/admin/externalTrackingList');
        }
    }



    sendRequestAccess = async () => {
        this.setState({loading: true});
        const info = await localStorage.getItem('auth');
        const sendInfo = JSON.parse(info);
        requestAccess({
            'username': sendInfo.username,
            'firstName': sendInfo.firstName,
            'lastName': sendInfo.lastName,
            'accounttypeUsers': sendInfo.accounttypeUsers,
            'businessname': sendInfo.businessname,
            'ADCode': sendInfo.ADCode,
            'address': sendInfo.address,
            'email': sendInfo.email,
            'phoneNumber': sendInfo.phonenumber,
            'userId': sendInfo.id,
            'screenType': 'Assign External Tracking Number',
        }).then(Response => {
		  this.setState({loading: false});
            toast.success(Response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }).catch(err => {
		  this.setState({loading: false});
            let message = err.message;
            if (err.response && err.response.data.message) {
                message = err.response.data.message;
            }
            toast.error(message, {
                position: toast.POSITION.TOP_RIGHT
            });
            this.setState({ loading: false });
        })
    };

    handlePhoneNumberChange = (isEdited, phoneNumber, selectedCountry, setFieldValue, fieldName) => {
        const isValid = isValidNumber(phoneNumber, [selectedCountry.iso2.toUpperCase()])
        this.setState({ [`${fieldName}IsValidPhoneNo`]: isValid });
        const number = `+${selectedCountry.dialCode}${phoneNumber}`
        setFieldValue(fieldName, number)
    };
    render() {
        const { requestAccess } = this.state;
        return (
            <div>
                {!requestAccess ?
                    <div className="justify-content-center pt-2 main-contain-title">
                        <>
                            <p>This feature is restricted.</p>
                            <Button onClick={() => this.sendRequestAccess()}
                            disabled={this.state.loading}>Request Access for free</Button>
                        </>
                    </div>
                    :
                    <>
                        <div className="d-flex justify-content-center scan-parcel-loader">
                            <ClipLoader
                                size={50}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>
                        <Formik
                            isInitialValid={false}
                            enableReinitialize
                            initialValues={{
                                internalRefno: this.state.internalRefno,
                                externalRefNo: this.state.externalRefNo,
                                externalCourierName: this.state.externalCourierName,
                                emailAddress1: this.state.emailAddress1,
                                emailAddress2: this.state.emailAddress2,
                                phoneNumber1: this.state.phoneNumber1,
                                phoneNumber2: this.state.phoneNumber2,
                            }}
                            validationSchema={Schema}
                            onSubmit={(values, { setSubmitting, setErrors }) => {
                                this.setErrors = setErrors;
                                this.submitHandler(values);
                            }}>
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleSubmit,
                                handleBlur,
                                setFieldValue,
                                status,
                                isSubmitting, }) => (
                                <Fragment>
                                    <div className={this.state.loading ? "mt-2 style loadingDiv" : "mt-2 style"}>
                                        <Card>
                                            <CardHeader className="c-header">Edit External Tracking Number Information</CardHeader>
                                            <CardBody>

                                                <div className="row">
                                                    <div className="col-lg-12 col-xs-12 col-sm-12">
                                                        <div className="row">

                                                            <div className="col-lg-12 col-xs-12 col-sm-12">
                                                                <FormGroup>
                                                                    <Label>External Tracking Number</Label>
                                                                    <Input
                                                                        type="text"
                                                                        invalid={errors.externalRefNo && touched.externalRefNo}
                                                                        name="externalRefNo"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.externalRefNo}
                                                                        placeholder="External Tracking Number"
                                                                    />
                                                                    <FormFeedback>
                                                                        {errors.externalRefNo && touched.externalRefNo && errors.externalRefNo}
                                                                    </FormFeedback>
                                                                </FormGroup>
                                                            </div>

                                                            <div className="col-lg-12 col-xs-12 col-sm-12">
                                                                <FormGroup>
                                                                    <Label>External Courier Name</Label>
                                                                    <Input
                                                                        type="text"
                                                                        invalid={errors.externalCourierName && touched.externalCourierName}
                                                                        name="externalCourierName"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.externalCourierName}
                                                                        placeholder="External Courier Name"
                                                                    />
                                                                    <FormFeedback>
                                                                        {errors.externalCourierName && touched.externalCourierName && errors.externalCourierName}
                                                                    </FormFeedback>
                                                                </FormGroup>
                                                            </div>

                                                            <div className="col-lg-12 col-xs-12 col-sm-12">
                                                                <FormGroup>
                                                                    <Label>Email Address 1</Label>
                                                                    <Input
                                                                        type="email"
                                                                        invalid={errors.emailAddress1 && touched.emailAddress1}
                                                                        name="emailAddress1"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.emailAddress1}
                                                                        placeholder="Email Address"
                                                                    />
                                                                    <FormFeedback>
                                                                        {errors.emailAddress1 && touched.emailAddress1 && errors.emailAddress1}
                                                                    </FormFeedback>
                                                                </FormGroup>
                                                            </div>

                                                            <div className="col-lg-12 col-xs-12 col-sm-12">
                                                                <FormGroup>
                                                                    <Label>Email Address 2</Label>
                                                                    <Input
                                                                        type="email"
                                                                        invalid={errors.emailAddress2 && touched.emailAddress2}
                                                                        name="emailAddress2"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.emailAddress2}
                                                                        placeholder="Email Address"
                                                                    />
                                                                    <FormFeedback>
                                                                        {errors.emailAddress2 && touched.emailAddress2 && errors.emailAddress2}
                                                                    </FormFeedback>
                                                                </FormGroup>
                                                            </div>

                                                            <div className="col-lg-12 col-xs-12 col-sm-12">
                                                                <FormGroup>
                                                                    <Label>Phone Number 1</Label>
                                                                    <div class="input-group mb-3">
                                                                        <PhoneInput
                                                                            containerClassName="intl-tel-input"
                                                                            inputClassName="form-control"
                                                                            defaultValue={this.state.phoneNumber1}
                                                                            ref={this.PhoneNumber1Ref}
                                                                            onPhoneNumberChange={(isEdited, phoneNumber, selectedCountry) => this.handlePhoneNumberChange(isEdited, phoneNumber, selectedCountry, setFieldValue, 'phoneNumber1')}
                                                                        />
                                                                        <h5 className='error-display'>
                                                                            {!errors.phoneNumber1 && !touched.phoneNumber1 && !this.state.phoneNumber1IsValidPhoneNo && "Please enter a valid phone number"}
                                                                            {errors.phoneNumber1 && touched.phoneNumber1 && errors.phoneNumber1}
                                                                        </h5>
                                                                    </div>
                                                                </FormGroup>
                                                            </div>

                                                            <div className="col-lg-12 col-xs-12 col-sm-12">
                                                                <FormGroup>
                                                                    <Label>Phone Number 2</Label>
                                                                    <div class="input-group mb-3">
                                                                        <PhoneInput
                                                                            containerClassName="intl-tel-input"
                                                                            inputClassName="form-control"
                                                                            defaultValue={this.state.phoneNumber2}
                                                                            ref={this.PhoneNumber2Ref}
                                                                            onPhoneNumberChange={(isEdited, phoneNumber, selectedCountry) => this.handlePhoneNumberChange(isEdited, phoneNumber, selectedCountry, setFieldValue, 'phoneNumber2')}
                                                                        />
                                                                        <h5 className='error-display'>
                                                                            {!errors.phoneNumber2 && !touched.phoneNumber2 && !this.state.phoneNumber2IsValidPhoneNo && "Please enter a valid phone number"}
                                                                            {errors.phoneNumber2 && touched.phoneNumber2 && errors.phoneNumber2}
                                                                        </h5>
                                                                    </div>
                                                                </FormGroup>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="d-flex justify-content-center">
                                                    <button className="btn btn-submit mx-2" onClick={handleSubmit} >Submit</button>
                                                    <button className="btn btn-submit" onClick={() => this.goBack()} >Back</button>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Fragment>

                            )}
                        </Formik>
                    </>
                }
            </div>
        )
    }
}
