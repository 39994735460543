export const devDetails = {
    appId: "97780",
    authKey: "HAbfhXY5Ogv2x7a",
    authSecret: "h6YzMZk3ywv-wbJ",
    accountKey: "Lekt7xt6uzqwH-DK9qAA",
}

export const liveDetails = {
    appId: '97783',
    authKey: 'fsbXZ-PXSrcGWg5',
    authSecret: '5tTxs-KgU8ZBtXb',
    accountKey: 'DP-PwNKJyLxXj5uU9Z7Q',
}