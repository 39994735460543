import PaypalExpressBtn from "react-paypal-express-checkout";
import React, { Component } from 'react'
import { getShipment, checkScreenAccess, requestAccess, searchShipmentInvoice, updateShipmentInvoice, sendInvoice, createPayment } from '../../api/Form';
import { image } from '../../api/URLS';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Formik } from 'formik';
import 'react-toastify/dist/ReactToastify.css';
import { CardHeader, CardBody, FormGroup, Label, Input, Card, Button, FormFeedback } from 'reactstrap';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { callActivityApi } from '../../utils/saveUserActivity';
import { updateCreditUnits, getCreditUnits } from '../../api/Form'
import { ClipLoader } from "react-spinners";


export default class UpdateCredits extends Component {
    state = {
        singleUnit: '1'
    };
    handleChange = (e) => {
        this.setState({ singleUnit: e.target.value });
    };
    
    componentDidMount() {
        callActivityApi({ 'pageName': 'Update Credit units', 'description': 'User has visited update credit units' });
        getCreditUnits().then((response) => {
            this.setState({singleUnit: response.data.data.single_unit})
        }).catch(err => {
        })
    }

    submitHandler = async () => {
        const deviceADcode = await localStorage.getItem('adCodes');
        const userId = await localStorage.getItem('userId');
        if (this.state.singleUnit.trim()){
            if (this.state.singleUnit.trim() > 0){
                updateCreditUnits({
                    userId,
                    deviceADcode,
                    singleUnit: this.state.singleUnit
                }).then((response) => {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    this.props.history.push('/admin/index');
                }).catch(err => {
                    toast.error(err.response.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
            } else {
                toast.error('Single unit must be greater than 0.', {
                    position: toast.POSITION.TOP_RIGHT
                });
            } 
        } else {
            toast.error('Please enter value', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        
    }

    render() {
        return (
            <div className="mt-2 style">
                <Card>
                    <CardHeader className="c-header">
                        <div className="row ">
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-8">
                                <span>Update Credit Units</span>
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <FormGroup>
                            <Label>Enter price per credit unit</Label>
                            <Input
                                type="number"
                                name="singleUnit"
                                onChange={(e) => this.handleChange(e)}
                                variant="outlined"
                                placeholder="Enter single unit"
                                value={this.state.singleUnit}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label>Credit Calculation</Label>
                            <p>{"If customer want to purchase 100 credits, total amount is 100 * " + this.state.singleUnit + ".i.e $" + 100 * this.state.singleUnit} </p>
                        </FormGroup>

                        <div className="d-flex justify-content-center">
                            <button className="btn btn-submit" onClick={() => this.submitHandler()}>
                                {this.state.loading ?
                                    <ClipLoader
                                        size={15}
                                        color={"#123abc"}
                                        loading={true}
                                    /> : 'Save'
                                }
                            </button>
                        </div>

                    </CardBody>
                </Card>
            </div>
        );
    }
}
