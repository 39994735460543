import React, { Component } from 'react'
import { Card, CardBody, Label, Nav, NavItem, NavLink, TabContent, TabPane, Button, CardHeader } from 'reactstrap';
import classnames from 'classnames';
import 'react-vertical-timeline-component/style.min.css';
import { deleteExternalRefNums, getExternaTrackingList, requestAccess, checkScreenAccess, searchExternalRefNums, updateNoticationStatusForExternal } from '../../api/Form';
import { ClipLoader } from "react-spinners";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import product from '../../assets/product.png'
import { callActivityApi } from '../../utils/saveUserActivity';
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import "react-datepicker/dist/react-datepicker.css";
import DataTableExtensions from 'react-data-table-component-extensions';
import { phoneNoVerifyCheck } from '../../utils/utils';

export default class ExternalTrackingList extends Component {
	state = {
		activeTab: '1',
		deviceAdCode: '',
		value: '',
		allEntries: [],
		searchEntries: [],
		searchItems: [],
		searchItem: '',
		loading: true,
		trackShipmentModal: false,
		requestAccess: false,
		data: [],
		showData: [],
		columns: [],
	}


	async componentDidMount() {
		callActivityApi({ 'pageName': 'External Tracking List', 'description': 'User has visited External list Screen' });
		const historyPush = this.props.history.push
		phoneNoVerifyCheck(historyPush)
		const deviceAdCode = await localStorage.getItem('adCodes');
		this.setState({ deviceAdCode });
		const userId = await localStorage.getItem('userId');
		this.setState({ userId })
		var userType = await localStorage.getItem('accounttypeUsers');
		if (userType !== 'Admin') {
			checkScreenAccess(
				{
					'userId': userId,
					'screenType': 'Assign External Tracking Number'
				}
			).then((response) => {
				this.setState({ loading: false, requestAccess: true });
			}).catch(err => {
				this.setState({ loading: false, requestAccess: false });
			});
		} else {
			this.setState({ requestAccess: true, loading: false })
		}
		this.getData();
	}

	getData = async () => {
		let columns = [
			{
				name: 'Internal/Master Tracking',
				selector: 'internalRefno',
				sortable: true,
			},
			{
				name: 'External Tracking No.',
				selector: 'externalRefno',
				sortable: true,
			},
			{
				name: 'External Courier Name',
				selector: 'externalCourierName',
				sortable: true,
			},
			{
				name: 'Collected',
				// selector: 'Collected',
				cell: (row) => (
					<div>
						{Number(row.collected) === 1 ? "Yes" : "No"}
					</div>
				)
			},
			{
				name: 'Email',
				cell: (row) => (
					<div>
						{row.email_notification == 1 ? <a href="javascript:void(0)" onClick={() => this.updateNotiStatus(row.id, 0, 'email')} className='text-danger p-1'>Halt</a> : <a href="javascript:void(0)" onClick={() => this.updateNotiStatus(row.id, 1, 'email')} className='text-success p-1'>Resume</a>}
					</div>
				),
				ignoreRowClick: true,
				allowOverflow: true,
				button: true,
			},
			{
				name: 'SMS',
				cell: (row) => (
					<div>
						{row.phone_notification == 1 ? <a href="javascript:void(0)" onClick={() => this.updateNotiStatus(row.id, 0, 'sms')} className='text-danger p-1'>Halt</a> : <a href="javascript:void(0)" onClick={() => this.updateNotiStatus(row.id, 1, 'sms')} className='text-success p-1'>Resume</a>}
					</div>
				),
				ignoreRowClick: true,
				allowOverflow: true,
				button: true,
			},

			{
				name: "Action",
				cell: (row) => (
					<div className="row pl-4" style={{ width: 160 }}>
						<a href="javascript:void(0)" onClick={() => this.editEntry(row.id)} className='text-success p-1'>Edit</a>
						<a href="javascript:void(0)" onClick={() => this.goToTracking(row.externalRefno)} className='text-primary p-1'>Track</a>
						<a href="javascript:void(0)" onClick={() => this.deleteEntry(row.id)} className='text-danger p-1'>Delete</a>
					</div>
				),

				ignoreRowClick: true,
				allowOverflow: true,
				button: true,
			},
		];
		this.setState({ loading: true });
		var userType = await localStorage.getItem('accounttypeUsers');
		const userId = await localStorage.getItem('userId');
		getExternaTrackingList({
			userId: userId,
			userType: userType,
			mode: 'assigned'
		}).then(Response => {
			if (Response.data.status === true) {
				this.setState({ showData: Response.data.data, loading: false })
			}
		})
		this.setState({ columns });
	};

	updateNotiStatus = (id, status, mode) => {
		updateNoticationStatusForExternal(
			{
				'id': id,
				'status': status,
				'mode': mode
			}
		).then(Response => {
			if (status == 1) {
				toast.success('Shipment notification has been resumed', {
					position: toast.POSITION.TOP_RIGHT
				});
			} else {
				toast.success('Shipment notification has been halted', {
					position: toast.POSITION.TOP_RIGHT
				});
			}
			this.getData();
		})
			.catch(err => {
				toast.error(err.response.data.message, {
					position: toast.POSITION.TOP_RIGHT
				});
			})
	}


	handleChange = (e) => {
		this.setState({ searchItem: e.target.value, searchItems: [] })
	}

	editEntry = (id) => {
		this.props.history.push({ pathname: './editExternalTrackingNumber', state: id })
	}

	goToTracking = (refno) => {
		this.props.history.push({ pathname: './trackShipmentsDetails', refno: refno })
	}

	deleteEntry = (id) => {
		if (id) {
			if (window.confirm("Are you sure to delete this?")) {

				deleteExternalRefNums({ 'trackingId': id })
					.then(Response => {
						toast.success('Entry is deleted successfully!!', {
							position: toast.POSITION.TOP_RIGHT
						});
						this.getData();
					})
					.catch(err => {
						toast.error(err.response.data.message, {
							position: toast.POSITION.TOP_RIGHT
						});
					})
			}
		}
	};

	sendRequestAccess = async () => {
		this.setState({ loading: true });
		const info = await localStorage.getItem('auth');
		const sendInfo = JSON.parse(info);
		requestAccess({
			'username': sendInfo.username,
			'firstName': sendInfo.firstName,
			'lastName': sendInfo.lastName,
			'accounttypeUsers': sendInfo.accounttypeUsers,
			'businessname': sendInfo.businessname,
			'ADCode': sendInfo.ADCode,
			'address': sendInfo.address,
			'email': sendInfo.email,
			'phoneNumber': sendInfo.phonenumber,
			'userId': sendInfo.id,
			'screenType': 'Assign External Tracking Number',
		}).then(Response => {
			this.setState({ loading: false });
			toast.success(Response.data.message, {
				position: toast.POSITION.TOP_RIGHT
			});
		}).catch(err => {
			this.setState({ loading: false });
			let message = err.message;
			if (err.response && err.response.data.message) {
				message = err.response.data.message;
			}
			toast.error(message, {
				position: toast.POSITION.TOP_RIGHT
			});
			this.setState({ loading: false });
		})
	};

	searchRequests = async () => {
		if (this.state.searchItem) {
			this.setState({ loading: true });
			var userType = await localStorage.getItem('accounttypeUsers');
			const userId = await localStorage.getItem('userId');
			searchExternalRefNums({ searchItem: this.state.searchItem, userType: userType, userId: userId })
				.then(Response => {
					this.setState({ searchItems: Response.data.data, activeTab: '2', loading: false })
				})
				.catch(err => {
					toast.error(err.response.data.message, {
						position: toast.POSITION.TOP_RIGHT
					});
					this.setState({ loading: false })
				})
		} else {
			toast.error("Please enter a valid text for search.", {
				position: toast.POSITION.TOP_RIGHT
			});
			this.setState({ searchItems: [], searchItem: '', loading: false })
		}
	}

	keyPress = (e) => {
		if (e.keyCode === 13) {
			this.searchRequests()
		}
	}

	toggle = (tab) => {
		if (this.state.activeTab !== tab) {
			this.setState({ activeTab: tab });
		}
	}



	render() {
		const { requestAccess, data, columns, showTable, showData } = this.state;
		const allEntries = this.state.allEntries.map(p => p)
		const searchItems = this.state.searchItems.map(p => p)
		const tableData = {
			columns,
			exportHeaders: true,
			data: showData,
		};
		return (
			<div>
				{!requestAccess ?
					<div className="justify-content-center pt-2 main-contain-title">
						<>
							<p>This feature is restricted.</p>
							<Button onClick={() => this.sendRequestAccess()}
								disabled={this.state.loading}>Request Access for free</Button>
						</>
					</div>
					:
					<div className="mt-2 style">
						<Card>
							<CardHeader className="c-header">External Tracking List</CardHeader>
							<CardBody>
								<DataTableExtensions  {...tableData}>
									<DataTable
										className="dataTables_wrapper"
										pagination
										highlightOnHover
										columns={columns}
										export={true}
										responsive={true}
										data={data}
									/>
								</DataTableExtensions>
							</CardBody>
						</Card>
					</div>}
			</div>
		)
	}
}
