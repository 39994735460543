import React, {Component} from 'react'
import {Card, CardBody, CardHeader, FormFeedback, FormGroup, Input, Label} from 'reactstrap';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {help} from '../../api/Form'
import {ClipLoader} from "react-spinners";
import { callActivityApi } from '../../utils/saveUserActivity';

const Schema = Yup.object().shape({
	message: Yup.string()
		.required('message is required'),
});


export default class Help extends Component {
	state = {
		firstname: '',
		lastname: '',
		email: '',
		message: '',
		isLoading: false
	}

	async componentDidMount() {
		callActivityApi({'pageName': 'Help', 'description': 'User has visited Help Screen'});
		const data = await localStorage.getItem('auth');
		const {firstName, lastName, email} = JSON.parse(data);
		this.setState({firstname: firstName, lastname: lastName, email: email});
	}

	submitHandler = (values) => {
		this.setState({isLoading: true});
		help(values)
			.then(Response => {
				this.setState({firstname: '', lastname: '', email: '', message: ''})
				this.setState({isLoading: false});
				this.props.history.push('/admin/index')
			})
			.catch(err => {
				toast.error(err.response.data.message, {
					position: toast.POSITION.TOP_RIGHT
				});
				console.log(err.response.data.message)
				this.setState({isLoading: false});
			})
	}

	render() {
		return (
			<div>
				<Formik
					enableReinitialize
					initialValues={{
						firstname: this.state.firstname,
						lastname: this.state.lastname,
						email: this.state.email,
						message: this.state.message
					}}
					validationSchema={Schema}
					onSubmit={(values, {setSubmitting, setErrors}) => {
						this.setErrors = setErrors;
						this.submitHandler(values);
					}}>
					{({
						  values,
						  errors,
						  touched,
						  handleChange,
						  handleSubmit,
						  handleBlur,
						  setFieldValue,
						  status,
						  isSubmitting,
					  }) => (
						<div className="mt-2 style">
							<Card>
								<CardHeader className="c-header">Help</CardHeader>
								<CardBody>
									<div className="col-12">
										<FormGroup>
											<Label>First Name</Label>
											<Input type="text"
											       invalid={errors.firstname && touched.firstname}
											       name="firstname" onChange={handleChange} onBlur={handleBlur} value={values.firstname}
											       placeholder="Enter first name"/>
											<FormFeedback>
												{errors.firstname && touched.firstname && errors.firstname}
											</FormFeedback>
										</FormGroup>
									</div>

									<div className="col-12">
										<FormGroup>
											<Label>Last Name</Label>
											<Input type="text"
											       invalid={errors.lastname && touched.lastname}
											       name="lastname" onChange={handleChange} onBlur={handleBlur} value={values.lastname}
											       placeholder="Enter last name"/>
											<FormFeedback>
												{errors.lastname && touched.lastname && errors.lastname}
											</FormFeedback>
										</FormGroup>
									</div>
									<div className="col-12">
										<FormGroup>
											<Label>Email</Label>
											<Input type="text"
											       invalid={errors.email && touched.email}
											       name="email" onChange={handleChange} onBlur={handleBlur} value={values.email}
											       placeholder="Enter last name"/>
											<FormFeedback>
												{errors.email && touched.email && errors.email}
											</FormFeedback>
										</FormGroup>
									</div>

									<div className="col-12">
										<FormGroup>
											<Label>Comment</Label>
											<Input type="text"
											       invalid={errors.message && touched.message}
											       name="message" onChange={handleChange} onBlur={handleBlur} value={values.message}
											       placeholder="Enter message name"/>
											<FormFeedback>
												{errors.message && touched.message && errors.message}
											</FormFeedback>
										</FormGroup>
									</div>

									<div className="d-flex justify-content-center">
										<button className="btn btn-submit" onClick={handleSubmit}>
											{this.state.isLoading ?
												< ClipLoader
													size={15}
													color={"#123abc"}
													loading={true}
												/> : 'Send'
											}
										</button>
									</div>
								</CardBody>
							</Card>
						</div>
					)}
				</Formik>
			</div>
		)
	}
}
