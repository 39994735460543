import React from 'react';
import img from '../assets/Asset 1.svg';
import { Link } from 'react-router-dom';
import './NoPageFound.css'
import Navbars from '../Navbar/Navbar';
import Footer from '../HomePage/components/Footer';

export default class NoPageFound extends React.Component {
    render() {
        return (
            <div className='nopage-container'>
                <Navbars />
                <div className="container">
                    <div className="d-flex justify-content-center pt-10 notfound-contain">
                        <img src={img} alt="" className="img-fluid" width="450px" height="450px" />
                    </div>
                    <div>
                        <div className="row d-flex justify-content-center notfound-contain1">
                            <h1 className="notfound-title">Oops!</h1>
                            <p className="notfound-description d-flex align-items-center">We can't seem to find the page you're looking for</p>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center pt-lg-5 pt-xl-5 pt-2 pt-sm-2 pt-md-5">
                        <Link to="/"><button className='mb-4 btn-danger border px-3 py-2 rounded'>Go to Home </button></Link>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
