import React, { Component } from 'react'
import { Button, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import logo from '../assets/4.png'
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { createPassword } from '../api/auth'
import { ClipLoader } from "react-spinners";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';

const SignupSchema = Yup.object().shape({
	// password: Yup.string()
	//     .min(5, 'Too Short!')
	//     .max(10, 'Too Long!')
	//     .required('Required'),
});
export default class CreatePassword extends Component {
	state = {
		password: '',
		validator: '',
		selector: '',
		loginbtn: false,
		loading: false
	};

	componentDidMount() {
		console.log("test");
		const searchParams = new URLSearchParams(document.location.search);
		for (let p of searchParams) {
			console.log(p);
		}
		let validator = searchParams.get("validator");
		let selector = searchParams.get("selector");
		console.log(validator);
		this.setState({ validator, selector })
	}

	submitHandler = (values) => {
		this.setState({ loading: true })
		if (values.password === values.repeatPassword) {
			createPassword({ password: values.password, selector: this.state.selector, validator: this.state.validator })
				.then(Response => {
					toast.success(Response.data.message, {
						position: toast.POSITION.TOP_RIGHT
					});
					this.setState({ loading: false, loginbtn: true })
					this.props.history.push('/login');
				})
				.catch(err => {
					toast.error(err.response.data.message, {
						position: toast.POSITION.TOP_RIGHT
					});
					this.setState({ loading: false })
				})
		} else {
			toast.error("Password does not match!Try again. ", {
				position: toast.POSITION.TOP_RIGHT
			});
			this.setState({ loading: false })
		}
	};

	render() {
		return (
			<>
				<Helmet>
					<title>Reset Password - Postkodes</title>
					<meta name="description" content="Enter your email click submit then check your email and follow the instruction to reset password." />
				</Helmet>
				<div>
					<Formik
						initialValues={{ password: this.state.password, repeatPassword: this.state.repeatPassword }}
						validationSchema={SignupSchema}
						onSubmit={(values, { setErrors, resetForm }) => {
							resetForm({ values: '' });
							this.setErrors = setErrors;
							this.submitHandler(values);
						}}
					>{({
						values,
						errors,
						touched,
						handleChange,
						handleSubmit,
						handleBlur,
						setFieldValue,
					}) => (
						<div className=" w-100 h-100 overflow-hidden">
							<div className="row">
								<div className="col-4      d-flex justify-content-center align-items-center">
									<div className="d-none d-md-block d-lg-block d-xl-block login-bg-style">
										<div className="d-flex justify-content-center">
											<a href="/">
												<img src={logo} className="img-fluid" alt="" />
											</a>
										</div>
										<h1 className="pb-3 text-center login-title">Postkodes</h1>
										<p className="text-justify login-description">Revolutionary parcel and product tracking platform using
											the latest IoT technology. Giving your business and your customers improved efficiency and
											productivity at significantly reduced cost when compared to your current system.</p>
									</div>
								</div>
								<div
									className="col-12 col-md-4 col-lg-4 col-xl-4 col-offset-none offset-sm-none offset-md-2 offset-lg-2 offset-xl-2 text-area-size  ">
									<div className="container">
										<div className="d-flex justify-content-center d-block  d-md-none d-lg-none d-xl-none pb-3">
											<img src={logo} alt="" />
										</div>
										<div className="d-none d-md-block d-lg-block d-xl-block">
											<h1 className="title-style">Reset Password</h1>
										</div>
										<div className="d-block d-sm-block d-md-none d-lg-none d-xl-none">
											<h1 className="title-style pb-0 text-left">Sign in</h1>
											<p className="pb-2 description-text">Sign in to Continue</p>
										</div>
										<Form>
											<FormGroup className="">
												<Label for="examplePassword">Password</Label>
												<div class="input-group mb-3">
													<div class="input-group-prepend">
														<span class="input-group-text" id="basic-addon1"><i class="fa fa-unlock-alt icon-style"
															aria-hidden="true"></i></span>
													</div>
													<Input type="password"
														invalid={errors.password && touched.password}
														name="password" onChange={handleChange} onBlur={handleBlur} value={values.password}
														placeholder="Enter new password" />
													<FormFeedback>
														{errors.password && touched.password && errors.password}
													</FormFeedback>
												</div>
											</FormGroup>
											<FormGroup className="">
												<Label for="examplePassword">Confirm Password</Label>
												<div class="input-group mb-3">
													<div class="input-group-prepend">
														<span class="input-group-text" id="basic-addon1"><i class="fa fa-unlock-alt icon-style"
															aria-hidden="true"></i></span>
													</div>
													<Input type="password"
														invalid={errors.repeatPassword && touched.repeatPassword}
														name="repeatPassword" onChange={handleChange} onBlur={handleBlur}
														value={values.repeatPassword} placeholder="Confirm new password" />
													<FormFeedback>
														{errors.repeatPassword && touched.repeatPassword && errors.repeatPassword}
													</FormFeedback>
												</div>
											</FormGroup>
											{this.state.loginbtn ?
												<div className="pt-5">
													<Link to="/" className="btn btn-login btn-block text-white">Go to Login</Link>
												</div>
												:
												<div className="pt-5">
													<Button className="btn btn-login btn-block" onClick={handleSubmit}>
														{this.state.loading ?
															< ClipLoader
																size={15}
																color={"#123abc"}
																loading={true}
															/> : 'Reset Password'
														}
													</Button>
												</div>
											}


										</Form>
									</div>

								</div>
								<div className="col-offset-none offset-sm-none offset-md-2 offset-lg-2 offset-xl-2"></div>
							</div>
						</div>
					)}
					</Formik>
				</div>
			</>
		)
	}
}
