import React, { Component, Fragment } from "react";
import {
  CardHeader,
  CardBody,
  Card,
  Button,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { callActivityApi } from "../../utils/saveUserActivity";
import {
  checkScreenAccess,
  requestAccess,
  sendBulkMessages,
  userProfile
} from "../../api/Form";
import { getLocation, phoneNoVerifyCheck } from "../../utils/utils";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import Select from "react-select";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";

const options = [
  { value: "Afghanistan", label: "Afghanistan" },
  { value: "Aland Islands", label: "Aland Islands" },
  { value: "Albania", label: "Albania" },
  { value: "Algeria", label: "Algeria" },
  { value: "American Samoa", label: "American Samoa" },
  { value: "AndorrA", label: "AndorrA" },
  { value: "Angola", label: "Angola" },
  { value: "Anguilla", label: "Anguilla" },
  { value: "Antarctica", label: "Antarctica" },
  { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
  { value: "Argentina", label: "Argentina" },
  { value: "Armenia", label: "Armenia" },
  { value: "Aruba", label: "Aruba" },
  { value: "Australia", label: "Australia" },
  { value: "Austria", label: "Austria" },
  { value: "Azerbaijan", label: "Azerbaijan" },
  { value: "Bahamas", label: "Bahamas" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "Barbados", label: "Barbados" },
  { value: "Belarus", label: "Belarus" },
  { value: "Belgium", label: "Belgium" },
  { value: "Belize", label: "Belize" },
  { value: "Benin", label: "Benin" },
  { value: "Bermuda", label: "Bermuda" },
  { value: "Bhutan", label: "Bhutan" },
  { value: "Bolivia", label: "Bolivia" },
  { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
  { value: "Botswana", label: "Botswana" },
  { value: "Bouvet Island", label: "Bouvet Island" },
  { value: "Brazil", label: "Brazil" },
  {
    value: "British Indian Ocean Territory",
    label: "British Indian Ocean Territory",
  },
  { value: "Brunei Darussalam", label: "Brunei Darussalam" },
  { value: "Bulgaria", label: "Bulgaria" },
  { value: "Burkina Faso", label: "Burkina Faso" },
  { value: "Burundi", label: "Burundi" },
  { value: "Cambodia", label: "Cambodia" },
  { value: "Cameroon", label: "Cameroon" },
  { value: "Canada", label: "Canada" },
  { value: "Cape Verde", label: "Cape Verde" },
  { value: "Cayman Islands", label: "Cayman Islands" },
  { value: "Central African Republic", label: "Central African Republic" },
  { value: "Chad", label: "Chad" },
  { value: "Chile", label: "Chile" },
  { value: "China", label: "China" },
  { value: "Christmas Island", label: "Christmas Island" },
  { value: "Cocos (Keeling) Islands", label: "Cocos (Keeling) Islands" },
  { value: "Colombia", label: "Colombia" },
  { value: "Comoros", label: "Comoros" },
  { value: "Congo", label: "Congo" },
  {
    value: "Congo, The Democratic Republic of the",
    label: "Congo, The Democratic Republic of the",
  },
  { value: "Cook Islands", label: "Cook Islands" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Cote DIvoire", label: "Cote D'Ivoire" },
  { value: "Croatia", label: "Croatia" },
  { value: "Cuba", label: "Cuba" },
  { value: "Cyprus", label: "Cyprus" },
  { value: "Czech Republic", label: "Czech Republic" },
  { value: "Denmark", label: "Denmark" },
  { value: "Djibouti", label: "Djibouti" },
  { value: "Dominica", label: "Dominica" },
  { value: "Dominican Republic", label: "Dominican Republic" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "Egypt", label: "Egypt" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Equatorial Guinea", label: "Equatorial Guinea" },
  { value: "Eritrea", label: "Eritrea" },
  { value: "Estonia", label: "Estonia" },
  { value: "Ethiopia", label: "Ethiopia" },
  {
    value: "Falkland Islands (Malvinas)",
    label: "Falkland Islands (Malvinas)",
  },
  { value: "Faroe Islands", label: "Faroe Islands" },
  { value: "Fiji", label: "Fiji" },
  { value: "Finland", label: "Finland" },
  { value: "France", label: "France" },
  { value: "French Guiana", label: "French Guiana" },
  { value: "French Polynesia", label: "French Polynesia" },
  {
    value: "French Southern Territories",
    label: "French Southern Territories",
  },
  { value: "Gabon", label: "Gabon" },
  { value: "Gambia", label: "Gambia" },
  { value: "Georgia", label: "Georgia" },
  { value: "Germany", label: "Germany" },
  { value: "Ghana", label: "Ghana" },
  { value: "Gibraltar", label: "Gibraltar" },
  { value: "Greece", label: "Greece" },
  { value: "Greenland", label: "Greenland" },
  { value: "Grenada", label: "Grenada" },
  { value: "Guadeloupe", label: "Guadeloupe" },
  { value: "Guam", label: "Guam" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Guernsey", label: "Guernsey" },
  { value: "Guinea", label: "Guinea" },
  { value: "Guinea-Bissau", label: "Guinea-Bissau" },
  { value: "Guyana", label: "Guyana" },
  { value: "Haiti", label: "Haiti" },
  {
    value: "Heard Island and Mcdonald Islands",
    label: "Heard Island and Mcdonald Islands",
  },
  {
    value: "Holy See (Vatican City State)",
    label: "Holy See (Vatican City State)",
  },
  { value: "Honduras", label: "Honduras" },
  { value: "Hong Kong", label: "Hong Kong" },
  { value: "Hungary", label: "Hungary" },
  { value: "Iceland", label: "Iceland" },
  { value: "India", label: "India" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "Iran, Islamic Republic Of", label: "Iran, Islamic Republic Of" },
  { value: "Iraq", label: "Iraq" },
  { value: "Ireland", label: "Ireland" },
  { value: "Isle of Man", label: "Isle of Man" },
  { value: "Israel", label: "Israel" },
  { value: "Italy", label: "Italy" },
  { value: "Jamaica", label: "Jamaica" },
  { value: "Japan", label: "Japan" },
  { value: "Jersey", label: "Jersey" },
  { value: "Jordan", label: "Jordan" },
  { value: "Kazakhstan", label: "Kazakhstan" },
  { value: "Kenya", label: "Kenya" },
  { value: "Kiribati", label: "Kiribati" },
  {
    value: "Korea, Democratic PeopleS Republic of",
    label: "Korea, Democratic People'S Republic of",
  },
  { value: "Korea, Republic of", label: "Korea, Republic of" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Kyrgyzstan", label: "Kyrgyzstan" },
  {
    value: "Lao PeopleS Democratic Republic",
    label: "Lao People'S Democratic Republic",
  },
  { value: "Latvia", label: "Latvia" },
  { value: "Lebanon", label: "Lebanon" },
  { value: "Lesotho", label: "Lesotho" },
  { value: "Liberia", label: "Liberia" },
  { value: "Libyan Arab Jamahiriya", label: "Libyan Arab Jamahiriya" },
  { value: "Liechtenstein", label: "Liechtenstein" },
  { value: "Lithuania", label: "Lithuania" },
  { value: "Luxembourg", label: "Luxembourg" },
  { value: "Macao", label: "Macao" },
  {
    value: "Macedonia, The Former Yugoslav Republic of",
    label: "Macedonia, The Former Yugoslav Republic of",
  },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Malawi", label: "Malawi" },
  { value: "Malaysia", label: "Malaysia" },
  { value: "Maldives", label: "Maldives" },
  { value: "Mali", label: "Mali" },
  { value: "Malta", label: "Malta" },
  { value: "Marshall Islands", label: "Marshall Islands" },
  { value: "Martinique", label: "Martinique" },
  { value: "Mauritania", label: "Mauritania" },
  { value: "Mauritius", label: "Mauritius" },
  { value: "Mayotte", label: "Mayotte" },
  { value: "Mexico", label: "Mexico" },
  {
    value: "Micronesia, Federated States of",
    label: "Micronesia, Federated States of",
  },
  { value: "Moldova, Republic of", label: "Moldova, Republic of" },
  { value: "Monaco", label: "Monaco" },
  { value: "Mongolia", label: "Mongolia" },
  { value: "Montserrat", label: "Montserrat" },
  { value: "Morocco", label: "Morocco" },
  { value: "Mozambique", label: "Mozambique" },
  { value: "Myanmar", label: "Myanmar" },
  { value: "Namibia", label: "Namibia" },
  { value: "Nauru", label: "Nauru" },
  { value: "Nepal", label: "Nepal" },
  { value: "Netherlands", label: "Netherlands" },
  { value: "Netherlands Antilles", label: "Netherlands Antilles" },
  { value: "New Caledonia", label: "New Caledonia" },
  { value: "New Zealand", label: "New Zealand" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Niger", label: "Niger" },
  { value: "Nigeria", label: "Nigeria" },
  { value: "Niue", label: "Niue" },
  { value: "Norfolk Island", label: "Norfolk Island" },
  { value: "Northern Mariana Islands", label: "Northern Mariana Islands" },
  { value: "Norway", label: "Norway" },
  { value: "Oman", label: "Oman" },
  { value: "Pakistan", label: "Pakistan" },
  { value: "Palau", label: "Palau" },
  {
    value: "Palestinian Territory, Occupied",
    label: "Palestinian Territory, Occupied",
  },
  { value: "Panama", label: "Panama" },
  { value: "Papua New Guinea", label: "Papua New Guinea" },
  { value: "Paraguay", label: "Paraguay" },
  { value: "Peru", label: "Peru" },
  { value: "Philippines", label: "Philippines" },
  { value: "Pitcairn", label: "Pitcairn" },
  { value: "Poland", label: "Poland" },
  { value: "Portugal", label: "Portugal" },
  { value: "Puerto Rico", label: "Puerto Rico" },
  { value: "Qatar", label: "Qatar" },
  { value: "Reunion", label: "Reunion" },
  { value: "Romania", label: "Romania" },
  { value: "Russian Federation", label: "Russian Federation" },
  { value: "RWANDA", label: "RWANDA" },
  { value: "Saint Helena", label: "Saint Helena" },
  { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
  { value: "Saint Lucia", label: "Saint Lucia" },
  { value: "Saint Pierre and Miquelon", label: "Saint Pierre and Miquelon" },
  {
    value: "Saint Vincent and the Grenadines",
    label: "Saint Vincent and the Grenadines",
  },
  { value: "Samoa", label: "Samoa" },
  { value: "San Marino", label: "San Marino" },
  { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Senegal", label: "Senegal" },
  { value: "Serbia and Montenegro", label: "Serbia and Montenegro" },
  { value: "Seychelles", label: "Seychelles" },
  { value: "Sierra Leone", label: "Sierra Leone" },
  { value: "Singapore", label: "Singapore" },
  { value: "Slovakia", label: "Slovakia" },
  { value: "Slovenia", label: "Slovenia" },
  { value: "Solomon Islands", label: "Solomon Islands" },
  { value: "Somalia", label: "Somalia" },
  { value: "South Africa", label: "South Africa" },
  {
    value: "South Georgia and the South Sandwich Islands",
    label: "South Georgia and the South Sandwich Islands",
  },
  { value: "Spain", label: "Spain" },
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "Sudan", label: "Sudan" },
  { value: "Suriname", label: "Suriname" },
  { value: "Svalbard and Jan Mayen", label: "Svalbard and Jan Mayen" },
  { value: "Swaziland", label: "Swaziland" },
  { value: "Sweden", label: "Sweden" },
  { value: "Switzerland", label: "Switzerland" },
  { value: "Syrian Arab Republic", label: "Syrian Arab Republic" },
  { value: "Taiwan, Province of China", label: "Taiwan, Province of China" },
  { value: "Tajikistan", label: "Tajikistan" },
  { value: "Tanzania, United Republic of", label: "Tanzania" },
  { value: "Thailand", label: "Thailand" },
  { value: "Timor-Leste", label: "Timor-Leste" },
  { value: "Togo", label: "Togo" },
  { value: "Tokelau", label: "Tokelau" },
  { value: "Tonga", label: "Tonga" },
  { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
  { value: "Tunisia", label: "Tunisia" },
  { value: "Turkey", label: "Turkey" },
  { value: "Turkmenistan", label: "Turkmenistan" },
  { value: "Turks and Caicos Islands", label: "Turks and Caicos Islands" },
  { value: "Tuvalu", label: "Tuvalu" },
  { value: "Uganda", label: "Uganda" },
  { value: "Ukraine", label: "Ukraine" },
  { value: "United Arab Emirates", label: "United Arab Emirates" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "United States", label: "United States" },
  {
    value: "United States Minor Outlying Islands",
    label: "United States Minor Outlying Islands",
  },
  { value: "Uruguay", label: "Uruguay" },
  { value: "Uzbekistan", label: "Uzbekistan" },
  { value: "Vanuatu", label: "Vanuatu" },
  { value: "Venezuela", label: "Venezuela" },
  { value: "Viet Nam", label: "Viet Nam" },
  { value: "Virgin Islands, British", label: "Virgin Islands, British" },
  { value: "Virgin Islands, U.S.", label: "Virgin Islands, U.S." },
  { value: "Wallis and Futuna", label: "Wallis and Futuna" },
  { value: "Western Sahara", label: "Western Sahara" },
  { value: "Yemen", label: "Yemen" },
  { value: "Zambia", label: "Zambia" },
  { value: "Zimbabwe", label: "Zimbabwe" },
];

const Schema = Yup.object().shape({
  inputList: "",
});

export default class ViewShipment extends Component {
  state = {
    shippingHistory: [],
    viewBy: "ViewbyRefNo",
    loading: true,
    data: [],
    showData: [],
    columns: [],
    requestAccess: true,
    keyword: "",
    location: "",
    country: "",
    fieldValue: "",
    inputList: [
      {
        consigneeTable: false,
        allContactsTable: false,
        userTable: true,
        customNumbers: "",
        emailContent: "",
        smsContent: "",
        country: [],
        customNum: false,
        emailSubject: "",
      },
    ],
  };

  async componentDidMount() {
    callActivityApi({
      pageName: "Bulk Messages",
      description: "User has visited Bulk Messages Screen",
    });
    const historyPush = this.props.history.push;
    phoneNoVerifyCheck(historyPush);
    const data = await localStorage.getItem("auth");
    const { id, ADCode } = JSON.parse(data);
    this.setState({ userId: id, deviceADcode: ADCode });
    var userType = await localStorage.getItem("accounttypeUsers");
    if (userType !== "Admin") {
      checkScreenAccess({
        userId: id,
        screenType: "Bulk Messages",
      })
        .then((response) => {
          this.setState({ loading: false, requestAccess: true });
        })
        .catch((err) => {
          this.setState({ loading: false, requestAccess: false });
        });
    } else {
      this.setState({ requestAccess: true, loading: false });
    }
    getLocation().then((location) => {
      let locationInfo = location.split(",");
      if (locationInfo.length > 0) {
        this.setState({ location: locationInfo[locationInfo.length - 1] });
        this.setState({ country: locationInfo[locationInfo.length - 1] });
      }
    });
    this.profileDataHandler()
  }

  profileDataHandler = async () => {
    const userId = await localStorage.getItem('userId');

    userProfile({ userId })
      .then(Response => {
        this.setState(prevState => ({
          inputList: prevState.inputList.map(item => ({
            ...item,
            emailSubject: Response?.data?.data?.update_profile?.emailSubject,
          })),
        }));

      })
      .catch(err => {

      })
  }

  sendRequestAccess = async () => {
    this.setState({ loading: true });
    const info = await localStorage.getItem("auth");
    const sendInfo = JSON.parse(info);
    requestAccess({
      username: sendInfo.username,
      firstName: sendInfo.firstName,
      lastName: sendInfo.lastName,
      accounttypeUsers: sendInfo.accounttypeUsers,
      businessname: sendInfo.businessname,
      ADCode: sendInfo.ADCode,
      address: sendInfo.address,
      email: sendInfo.email,
      phoneNumber: sendInfo.phonenumber,
      userId: sendInfo.id,
      screenType: "Bulk Messages",
    })
      .then((Response) => {
        this.setState({ loading: false });
        toast.success(Response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        let message = err.message;
        if (err.response && err.response.data.message) {
          message = err.response.data.message;
        }
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  sendApiCall = async (values) => {

    await sendBulkMessages(values.inputList)
      .then((response) => {

        this.setState({ loading: false });
        this.props.history.push("/admin/index");
        toast.success("Message sent successfully!!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          err.response.data.data.country || err.response?.data?.message,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        this.setState({
          inputList: [
            {
              consigneeTable: false,
              allContactsTable: false,
              userTable: true,
              customNumbers: "",
              emailContent: "",
              smsContent: "",
              country: [],
              customNum: false,
              emailSubject: "",
            },
          ],
        });
      });
  };

  sendMessage = (values) => {

    this.setState({ loading: true });
    let isFunctionCalled = false;
    this.state.inputList.map((item) => {
      if (item.country || item.customNum) {
        if (
          item.consigneeTable ||
          item.userTable ||
          item.allContactsTable ||
          item.customNum
        ) {
          if (
            (item.userTable || item.consigneeTable || item.allContactsTable) &&
            item.customNum
          ) {
            if (
              item.country &&
              item.emailContent.trim() &&
              item.smsContent.trim()
            ) {
              if (!isFunctionCalled) {
                this.sendApiCall(values);
                isFunctionCalled = true;
              }
            } else {
              this.setState({ loading: false });
              let msg = "Please enter ";
              if (!item.country) {
                msg += "country,";
              }
              if (!item.emailContent) {
                msg += "email content,";
              }
              if (!item.smsContent) {
                msg += "sms content,";
              }
              toast.error(msg, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          } else if (
            item.userTable ||
            item.consigneeTable ||
            item.allContactsTable
          ) {
            if (
              item.country &&
              (item.emailContent.trim() || item.smsContent.trim())
            ) {
              if (!isFunctionCalled) {
                this.sendApiCall(values);
                isFunctionCalled = true;
              }
            } else {
              this.setState({ loading: false });
              let msg = "Please enter ";
              if (!item.country) {
                msg += "country,";
              }
              if (!item.emailContent || !item.smsContent) {
                msg += "message content";
              }

              toast.error(msg, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          } else if (item.customNum) {
            if (item.customNumbers.trim() && item.smsContent.trim()) {
              if (!isFunctionCalled) {
                this.sendApiCall(values);
                isFunctionCalled = true;
              }
            } else {
              this.setState({ loading: false });
              let msg = "Please enter ";
              if (!item.smsContent) {
                msg += "sms content";
              }
              toast.error(msg, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          } else {
            // nothing
          }
        } else {
          this.setState({ loading: false });
          toast.error("Please select atleast one checkbox option", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        this.setState({ loading: false });
        toast.error("Please select one of the country first", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  handleRemoveClick = (index, setFieldValue) => {
    this.setState({ errorList: [] });
    const list = [...this.state.inputList];
    list.splice(index, 1);
    this.setState({ inputList: list });
    setFieldValue("inputList", list);
    setFieldValue(`x.${index}.consigneeTable`, "");
    setFieldValue(`x.${index}.allContactsTable`, "");
    setFieldValue(`x.${index}.country`, "");
    setFieldValue(`x.${index}.customNum`, "");
    setFieldValue(`x.${index}.customNumbers`, "");
    setFieldValue(`x.${index}.emailContent`, "");
    setFieldValue(`x.${index}.smsContent`, "");
    setFieldValue(`x.${index}.userTable`, "");
    setFieldValue(`x.${index}.customTable`, "");
    setFieldValue(`x.${index}.emailSubject`, "");
  };

  addItems = (setFieldValue) => {
    var list = this.state.inputList;
    list = [
      ...list,
      {
        consigneeTable: false,
        userTable: true,
        allContactsTable: false,
        customNumbers: "",
        emailContent: "",
        smsContent: "",
        country: [],
        customNum: false,
        // customTable: false,
        emailSubject: "",
      },
    ];
    this.setState((prevState) => ({

      inputList: [
        ...prevState.inputList,
        {
          consigneeTable: false,
          userTable: true,
          allContactsTable: false,
          customNumbers: "",
          emailContent: "",
          smsContent: "",
          country: [],
          customNum: false,
          emailSubject: prevState?.inputList[0]?.emailSubject ? prevState?.inputList[0]?.emailSubject : "",
          // customTable: false,
        },
      ],
    }));
    setFieldValue("inputList", list);
  };

  handleInputChange = (index, e, name, setFieldValue) => {
    this.setState({ errorList: [] });
    var value;
    if (name == "country") {
      value = e.map(item => item.value);

    } else if (
      name === "userTable" ||
      name === "consigneeTable" ||
      name === "allContactsTable"
    ) {
      value = e;
    } else if (name === "smsContent") {
      value = e.target.value.trim();
    } else if (name === "customNum") {
      value = e;
    } else if (name === "emailContent") {
      value = e.target.value.replace(/\n/g, "<br/>");
    } else {
      value = e.target.value;
    }

    const list = [...this.state.inputList];
    list[index][name] = value;
    this.setState({ inputList: list });
    setFieldValue("inputList", list);
  };

  render() {
    const { requestAccess, loading } = this.state;

    return (
      <>
        {!requestAccess ? (
          <div className="justify-content-center pt-2 main-contain-title">
            <>
              <p>This feature is restricted.</p>
              <Button
                onClick={() => this.sendRequestAccess()}
                disabled={this.state.loading}
              >
                Request Access for free
              </Button>
            </>
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-center scan-parcel-loader">
              <ClipLoader size={50} color={"#123abc"} loading={loading} />
            </div>
            {!loading ? (
              <Fragment>
                <Formik
                  initialValues={{
                    inputList: this.state.inputList,
                  }}
                  onSubmit={(values, { setSubmitting, setErrors }) => {
                    this.setErrors = setErrors;
                    this.sendMessage(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    setFieldValue,
                    status,
                    isSubmitting,
                  }) => (
                    <div className="mt-2 style">
                      <Card>
                        <CardHeader className="c-header">
                          <div className="row ">
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-8">
                              <span>Bulk Messages</span>
                            </div>
                          </div>
                        </CardHeader>
                        <CardBody>
                          <FieldArray name="entries">
                            <div className="row ">
                              {this.state.inputList.map((x, i) => {
                                return (
                                  <div className="row mt-4 col-12">
                                    <div className="col-12">
                                      <FormGroup>
                                        <Label>User Country</Label>
                                        <Select
                                          name={`x.${i}.country`}
                                          isMulti
                                          onChange={(v) =>
                                            this.handleInputChange(
                                              i,
                                              v,
                                              "country",
                                              setFieldValue
                                            )
                                          }
                                          // value={x.country.value}
                                          // value={x.country[i]}
                                          options={options}
                                        />
                                      </FormGroup>
                                    </div>
                                    <div className="col-12 pt-3">
                                      <Label>
                                        Select Table for sending message
                                      </Label>
                                      <FormGroup check>
                                        <Label check>
                                          <Input
                                            type="checkbox"
                                            checked={x.userTable}
                                            placeholder=""
                                            name={`x.${i}.userTable`}
                                            onChange={(v) =>
                                              this.handleInputChange(
                                                i,
                                                v.target.checked,
                                                "userTable",
                                                setFieldValue
                                              )
                                            }
                                          />
                                          Users table
                                        </Label>
                                      </FormGroup>
                                      <FormGroup check>
                                        <Label check>
                                          <Input
                                            type="checkbox"
                                            checked={x.consigneeTable}
                                            placeholder=""
                                            name={`x.${i}.consigneeTable`}
                                            onChange={(v) =>
                                              this.handleInputChange(
                                                i,
                                                v.target.checked,
                                                "consigneeTable",
                                                setFieldValue
                                              )
                                            }
                                          />
                                          Consignee table
                                        </Label>
                                      </FormGroup>
                                      <FormGroup check>
                                        <Label check>
                                          <Input
                                            type="checkbox"
                                            checked={x.allContactsTable}
                                            placeholder=""
                                            name={`x.${i}.allContactsTable`}
                                            onChange={(v) =>
                                              this.handleInputChange(
                                                i,
                                                v.target.checked,
                                                "allContactsTable",
                                                setFieldValue
                                              )
                                            }
                                          />
                                          All Contacts table
                                        </Label>
                                      </FormGroup>
                                      <FormGroup check>
                                        <Label check>
                                          <Input
                                            type="checkbox"
                                            checked={x.customNum}
                                            placeholder=""
                                            onChange={(v) =>
                                              this.handleInputChange(
                                                i,
                                                v.target.checked,
                                                "customNum",
                                                setFieldValue
                                              )
                                            }
                                            name={`x.${i}.customNum`}
                                          />
                                          Custom Phone Numbers
                                        </Label>
                                      </FormGroup>
                                    </div>
                                    {x.customNum && (
                                      <div className="col-12 mt-2">
                                        <FormGroup>
                                          <Label>Custom Phone Number</Label>
                                          <Input
                                            type="text"
                                            onChange={(v) =>
                                              this.handleInputChange(
                                                i,
                                                v,
                                                "customNumbers",
                                                setFieldValue
                                              )
                                            }
                                            name={`x.${i}.customNumbers`}
                                            placeholder="Enter custom phone numbers"
                                          />
                                        </FormGroup>
                                      </div>
                                    )}

                                    <div className="col-12 mt-2">
                                      <FormGroup>
                                        <Label>Email Subject</Label>
                                        <Input
                                          name={`x.${i}.emailSubject`}
                                          value={x.emailSubject}
                                          onChange={(v) =>
                                            this.handleInputChange(
                                              i,
                                              v,
                                              "emailSubject",
                                              setFieldValue
                                            )
                                          }
                                          placeholder="Enter email subject"
                                        />
                                      </FormGroup>
                                    </div>

                                    <div className="col-12">
                                      <FormGroup>
                                        <Label>Email Content</Label>
                                        <Input
                                          type="textarea"
                                          name={`x.${i}.emailContent`}
                                          onChange={(v) =>
                                            this.handleInputChange(
                                              i,
                                              v,
                                              "emailContent",
                                              setFieldValue
                                            )
                                          }
                                          placeholder="Enter email content"
                                        />
                                      </FormGroup>
                                    </div>

                                    <div className="col-12">
                                      <FormGroup>
                                        <Label>Message Content</Label>
                                        <Input
                                          type="textarea"
                                          onChange={(v) =>
                                            this.handleInputChange(
                                              i,
                                              v,
                                              "smsContent",
                                              setFieldValue
                                            )
                                          }
                                          name={`x.${i}.smsContent`}
                                          placeholder="Enter message content"
                                        />
                                      </FormGroup>
                                    </div>
                                    <div className="col-12 d-flex justify-content-end">
                                      {this.state.inputList.length !== 1 && (
                                        <button
                                          className="btn btn-danger mr-1"
                                          onClick={() =>
                                            this.handleRemoveClick(
                                              i,
                                              setFieldValue
                                            )
                                          }
                                        >
                                          -
                                        </button>
                                      )}
                                      {this.state.inputList.length - 1 ===
                                        i && (
                                          <button
                                            className="btn btn-primary"
                                            onClick={() =>
                                              this.addItems(setFieldValue)
                                            }
                                          >
                                            +
                                          </button>
                                        )}
                                    </div>
                                  </div>
                                );
                              })}
                              <div className="col-4">
                                <button
                                  className="btn btn-submit"
                                  onClick={handleSubmit}
                                >
                                  Send Message
                                </button>
                              </div>
                            </div>
                          </FieldArray>
                        </CardBody>
                      </Card>
                    </div>
                  )}
                </Formik>
              </Fragment>
            ) : null}
          </>
        )}
      </>
    );
  }
}
