import React, { Component } from 'react'
import circleProgress from "../../../assets/circleProgress.gif"
import notMove from "../../../assets/notMove.png"
export default class ProgressBar extends Component {

    render() {
        const { completed, label } = this.props;
        const containerStyles = {
            height: 1,
            backgroundColor: "#e0e0de",
            borderRadius: 5,
            borderBottom: "3px dashed grey",
        }

        const fillerStyles = {
            position: "relative",
            height: '100%',
            width: `${this.props.completedData}%`,
            backgroundColor: "green",
            textAlign: 'right',
            border: "2px dotted green"
        }

        const labelStyles = {
            padding: 0,
            color: 'green',
            position: "relative"
        }
        return (
            <div>
                <div className="dotStyle">
                    <i class="fa fa-circle" style={{ fontSize: this.props.completedData > 0 ? "17px" : "0px" }}></i>
                    <i class="fa fa-circle" style={{ fontSize: this.props.completedData < 100 ? "17px" : "0px", color: this.props.completedData < 100 ? 'red' : 'green' }}></i>
                </div>
                <div style={containerStyles}>
                    <div style={fillerStyles}>
                        <span style={labelStyles}>
                            <span className={this.props.completedData === 100 ? "heartIcon1" : 'heartIcon'}>
                                {
                                    this.props.completedData === 100 ?
                                        <img src={notMove} alt="" class={`fa fa-heart iconHeart ${this.props.classNameData}`} style={{ height: "30px" }} />
                                        :
                                        <img src={circleProgress} alt="" class={`fa fa-heart iconHeart ${this.props.classNameData}`} style={{ height: "30px" }} />

                                }
                            </span>
                        </span>
                        <>
                            <span className='text-capitalize' style={{ position: 'absolute', top: '10px', right: '-30px', color: "green" }} >
                                {label[0]?.trackingstatus || label[0]?.status || label[0]?.track_status}
                            </span>
                        </>
                    </div>
                </div>
                <div className="deliveredSpan">
                    {
                        this.props.completedData > 1 ?
                            <span >Created</span>
                            : <span></span>
                    }
                    {
                        this.props.completedData < 100 ? <span>
                            Delivered
                        </span> : <span></span>
                    }

                </div>
            </div>
        )
    }
}
