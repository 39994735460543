import React, { Component } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

export default class AddressBook extends Component {
    state = {
        addressModal: false,
        data: [],
        searchName: '',
    }

    updateAddressHandler = (address) => {
        this.props.address(address)
        this.props.isClose();
    }

    searchClassHandler = (event) => {
        // console.log(event.target.value)
        this.setState({ searchName: event.target.value })

    }

    render() {
        const items = this.props.data.filter((data) => {
            if (this.state.searchName == null)
                return data
            else if (data.address.toLowerCase().includes(this.state.searchName.toLowerCase())) {
                return data
            }
        })
        return (
            <div>
                <Modal isOpen={this.props.isOpen} className="nfc-modal">

                    <ModalHeader toggle={this.props.isClose}>Choose Address</ModalHeader>
                    <ModalBody className="modal-style">
                        <div className="d-flex ">
                            <div className="col-lg-12 d-flex p-0">
                                <label><input
                                    type="search"
                                    className="form-control"
                                    placeholder="Search here..."
                                    aria-controls="example"
                                    value={this.state.searchName}
                                    onChange={this.searchClassHandler} /></label>
                            </div>
                        </div>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Address</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((p, index) => {
                                    return (
                                        <>
                                            <tr onClick={() => this.updateAddressHandler(p.address)}>
                                                <th>{index + 1}</th>
                                                <td>{p.address}</td>
                                            </tr>
                                        </>
                                    )
                                })}

                            </tbody>
                        </table>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}
