import React, { Component } from 'react'
import Navbar from '../Dashboard/Navbar/Navbar'
import { Redirect } from 'react-router-dom'
import { Context } from '../context'
import { chatsList, chatsMessagesList, updateCall } from '../api/Form'
import * as qb from "../utils/qbHelper";
import { toast } from "react-toastify";
import tone from '../assets/audio/phoneCall.mp3';

const audio = new Audio(tone);

export default class Admin extends Component {
	state = {
		chatsList: [],
		chats: [],
		loading: false,
		call: null,
		users: [],
		callHistory: [],
		userId: ''
	};

	async componentDidMount() {
		const deviceAdCode = await localStorage.getItem('adCodes');
		const userId = await localStorage.getItem('userId');
		this.setState({ userId });
		this.chatList = setInterval(
      () =>
        chatsList({ userId, deviceAdCode })
          .then((Response) => {
            this.setState({ chatsList: Response.data.data });
          })
          .catch((err) => {
            console.log(err);
          }),
      3e3
    );

    const auth = localStorage.getItem("auth");
    if (!auth) {
      return;
    }

    const user = JSON.parse(auth);
    try {
      qb.init();
      this.setupEvents();
      await qb.createSession();
      const result = await qb.login(user);
      try {
        await qb.connect(result.user_id);
      } catch (e) {
      }
    } catch (error) {
      console.log(error, "login err");
      await qb.createUser(user);
      const result = await qb.login(user);
      try {
        await qb.connect(result.user_id);
      } catch (e) {
      }
    }
		const users = await qb.getUsers();
		this.setState({ users: users.items });
	}

	setupEvents = () => {
		const toastId = React.createRef();
    window.QB.webrtc.onCallListener = (session, { name, id }) => {
      audio.play();
      audio.loop = true;
      this.setState({ call: "incoming", session });
      const accept = () => {
        audio.pause();
        toast.update(toastId.current, { render: <Call accepted /> });
        const mediaParams = {
          audio: true,
          video: false,
        };
        session.getUserMedia(mediaParams, function (err, stream) {
          if (err) {
          } else {
            updateCall({ id, status: "received" })
              .then((response) => {
              })
              .catch((err) => {
              });
            session.accept({});
          }
        });
      };
      const reject = () => {
        audio.pause();
        this.setState({ call: null });
        toast.dismiss(toastId.current);
        session.reject({});
      };
      const end = () => {
        audio.pause();
        this.setState({ call: null });
        toast.dismiss(toastId.current);
        session.stop({});
      };

      const Call = (props) => (
        <div className="d-flex align-items-center">
          <div>{name} is calling you.</div>
          <div className="d-flex ml-auto mr-2">
            {!props.accepted ? (
              <>
                <div>
                  <button type="button" className="btn btn-sm btn-success mr-2" onClick={accept}>
                    <i className="fa fa-phone" />
                  </button>
                </div>
                <div>
                  <button type="button" className="btn btn-sm btn-danger" onClick={reject}>
                    <i className="fa fa-phone" style={{ transform: "rotate(136deg)" }} />
                  </button>
                </div>
              </>
            ) : (
              <div>
                <button type="button" className="btn btn-sm btn-danger mr-2" onClick={end}>
                  <i className="fa fa-phone" style={{ transform: "rotate(136deg)" }} />
                </button>
              </div>
            )}
          </div>
        </div>
      );
      toastId.current = toast(<Call />, { autoClose: false, closeButton: false, closeOnClick: false });
    };

    window.QB.webrtc.onAcceptCallListener = (session, userId, extension) => {
      this.setState({ call: "accepted", session });
    };

    window.QB.webrtc.onRejectCallListener = (session, userId, extension) => {
      this.setState({ call: "rejected", session: null });
    };

    window.QB.webrtc.onStopCallListener = (session, userId, extension) => {
      toast.dismiss(toastId.current);
      audio.pause();
      this.setState({ call: "stopped", session: null });
    };

    window.QB.webrtc.onUpdateCallListener = function (session, userId, extension) {
      console.log("uc", session);
    };

    window.QB.webrtc.onInvalidEventsListener = (eventName, session, userId, userInfo) => {
      this.setState({ call: null, session: null });
      toast.dismiss(toastId.current);
      audio.pause();
    };

    window.QB.webrtc.onUserNotAnswerListener = (session, userId) => {
      this.setState({ call: "not answer", session: null });
    };

    window.QB.webrtc.onRemoteStreamListener = function (session, userId, stream) {
      session.attachMediaStream("audioCall", stream);
    };

    window.QB.webrtc.onSessionConnectionStateChangedListener = function (session, userId, connectionState) {
      console.log("scsc", session);
    };

    window.QB.webrtc.onSessionCloseListener = (session) => {
      this.setState({ call: null, session: null });
      toast.dismiss(toastId.current);
      audio.pause();
    };

		window.QB.webrtc.onCallStatsReport = function (session, userId, stats, error) {
			console.log('csr', session)
		}
	}

	componentWillUnmount() {
		// qb.disconnect();
		// qb.logout();
		// qb.release();
		if (this.chat) {
			clearInterval(this.chat);
		}
		if (this.chatList) {
			clearInterval(this.chatList);
		}
	}

	setChatId = chatId => {
		if (this.chat) {
			clearInterval(this.chat);
		}
		this.setState({ loading: true, chats: [] });
		this.chat = setInterval(() => chatsMessagesList({ chatId })
			.then(res => {
				const data = JSON.parse(res.config.data);
				if (data.chatId === chatId)
					this.setState({ chats: res.data.data.reverse(), chatId, loading: false })
			}), 3e3);
	}

	render() {
		const token = localStorage.getItem('token');
		if (!token) {
			return <Redirect from="/" to="/login" />
		}
		return (
			<Context.Provider value={{
				chatsList: this.state.chatsList,
				chats: this.state.chats,
				call: this.state.call,
				session: this.state.session,
				chatId: this.state.chatId,
				setChatId: id => this.setChatId(id),
				loading: this.state.loading
			}}>
        <div className="wrapper">
					<Navbar  {...this.props} />
				</div>
			</Context.Provider>
		)
	}
}
