import React, { Component } from 'react'
import { Card, CardBody, CardHeader, FormFeedback, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { uploadCSV, checkScreenAccess, requestAccess, saveExternalRefNums } from '../../api/Form';
import { toast } from 'react-toastify';
import { ClipLoader } from "react-spinners";
import XLSX from 'xlsx';
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import DataTableExtensions from 'react-data-table-component-extensions';
import { callActivityApi } from '../../utils/saveUserActivity';

export default class MultipleExternal extends Component {
    input = React.createRef();
    state = {
        userId: '',
        deviceADcode: '',
        nfcADcode: '',
        nfcserialno: '',
        document: null,
        loading: false,
        data: [],
        showData: [],
        columns: [],
        location: 'system_generated',
        showTable: false,
        requestAccess: true,
    }

    async componentDidMount() {
        callActivityApi({ 'pageName': 'Upload Multiple Externals', 'description': 'User has visited Upload multiple external' });
        const data = await localStorage.getItem('auth');
        const { id, ADCode } = JSON.parse(data);
        this.setState({ userId: id, deviceADcode: ADCode });
        var userType = await localStorage.getItem('accounttypeUsers');
        if (userType !== 'Admin') {
            checkScreenAccess(
                {
                    'userId': id,
                    'screenType': 'Upload Multiple External Numbers'
                }
            ).then((response) => {
                this.setState({ loading: false, requestAccess: true });
            }).catch(err => {
                this.setState({ loading: false, requestAccess: false });
            });
        } else {
            this.setState({ requestAccess: true, loading: false })
        }
    }

    nfcADcodeHandler = () => {
        this.setState({ modal: !this.state.modal })
    }


    onSelectFile = (key) => e => {
        const { name, type, size } = e.target.files[0];
        this.setState({ document: { name, type, size } });
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            //console.log('ghhg');
            reader.onload = (evt) => {
                /* Parse data */
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, { type: 'binary' });
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const dataString = XLSX.utils.sheet_to_csv(ws, { header: 1 });
                //console.log(dataString);
                //console.log(bstr);

                const dataStringLines = dataString.split(/\r\n|\n/);
                const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

                const list = [];
                for (let i = 1; i < dataStringLines.length; i++) {
                    const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
                    if (headers && row.length == headers.length) {
                        const obj = {};
                        for (let j = 0; j < headers.length; j++) {
                            let d = row[j];
                            if (d.length > 0) {
                                if (d[0] == '"')
                                    d = d.substring(1, d.length - 1);
                                if (d[d.length - 1] == '"')
                                    d = d.substring(d.length - 2, 1);
                            }
                            if (headers[j]) {
                                obj[headers[j]] = d;
                            }
                        }

                        // remove the blank rows
                        if (Object.values(obj).filter(x => x).length > 0) {
                            list.push(obj);
                        }
                    }
                }

                // prepare columns list from headers
                const columns = headers.map(c => ({
                    name: c,
                    selector: c,
                }));
                this.setState({ data: list, columns });
                console.log(list, columns);
            };
            reader.readAsBinaryString(e.target.files[0], 'UTF-8');
        }
    };
    saveInfo = async () => {
        // check acces
        var userType = await localStorage.getItem('accounttypeUsers');
        if (userType !== 'Admin') {
            checkScreenAccess(
                {
                    'userId': this.state.userId,
                    'screenType': 'Upload Multiple External Numbers'
                }
            ).then((response) => {
                if (this.state.data.length > 0) {
                    this.callApi();
                } else {
                    toast.error('Please upload file!!', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }

            }).catch(err => {
                this.setState({ loading: false, requestAccess: false });
            });
        } else {
            if (this.state.data.length > 0) {
                this.callApi();
            } else {
                toast.error('Please upload file!!', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    printErrors = (obj) => {
        var string = '';
        obj.map((val) => {
            for (var k in val) {
                if (val[k] instanceof Object) {
                    this.printErrors(val[k]);
                } else {
                    console.log(val[k] + "<br>");
                    if (string) {
                        string += ', ' + val[k];
                    } else {
                        string += val[k];
                    }

                };
            }
        })
        return string;
    };

    checkIfArrayIsUnique(myArray) {

       // Better:
        const arrKeys = myArray.map(el => el.internalRefno);
        const arrExts = myArray.map(el => el.externalRefno);
        let resInternal = [];
        for (var i = 0; i < arrKeys.length; i++) {
            let indexV = {};
            if (arrKeys.indexOf(arrKeys[i]) !== arrKeys.lastIndexOf(arrKeys[i])) {
                indexV.internalRefno = 'Already exists';
            }
            if (arrExts.indexOf(arrExts[i]) !== arrExts.lastIndexOf(arrExts[i])) {
                indexV.externalRefno = 'Already exists';
            }

            resInternal.push(indexV);
        }
        return resInternal;   // this means not unique
    }

    callApi = () => {
        this.setState({ loading: true });
        const uniqueValues = this.checkIfArrayIsUnique(this.state.data);
        let checkKeyPresenceInArray = key => uniqueValues.some(obj => Object.keys(obj).includes(key));
        const findExternal = checkKeyPresenceInArray('internalRefno');
        const findinternal = checkKeyPresenceInArray('externalRefno');
        if (uniqueValues.length <= 0 || (!findExternal && !findinternal)) {
            console.log(this.state.data);
            const data = {
                entries: this.state.data,
                deviceADcode: this.state.deviceADcode,
                userId: this.state.userId,
            };

            saveExternalRefNums(data).then(response => {
                console.log('fgdg', response.data.data)
                this.setState({ loading: false, showTable: true, showData: response.data.data });
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                //this.props.history.push('/admin/index')
            })
                .catch(err => {
                    let message = err.message;
                    if (err.response && err.response.data.message) {
                        if (err.response && err.response.data.data && err.response.data.data.length !== 0) {
                            const label = this.printErrors(err.response.data.data);
                            message = label;
                        } else {
                            message = err.response.data.message;
                        }
                    }
                    toast.error(message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    this.setState({ loading: false });
                })
        } else {
            toast.error('Duplicate values in sheet or external and internal number is empty', {
                position: toast.POSITION.TOP_RIGHT
            });
            this.setState({ loading: false });
        }
    }

    sendRequestAccess = async () => {
		  this.setState({loading: true});
        const info = await localStorage.getItem('auth');
        const sendInfo = JSON.parse(info);
        requestAccess({
            'username': sendInfo.username,
            'firstName': sendInfo.firstName,
            'lastName': sendInfo.lastName,
            'accounttypeUsers': sendInfo.accounttypeUsers,
            'businessname': sendInfo.businessname,
            'ADCode': sendInfo.ADCode,
            'address': sendInfo.address,
            'email': sendInfo.email,
            'phoneNumber': sendInfo.phonenumber,
            'userId': sendInfo.id,
            'screenType': 'Upload Multiple External Numbers',
        }).then(Response => {
		    this.setState({loading: false});
            toast.success(Response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }).catch(err => {
            this.setState({ loading: false });
            let message = err.message;
            if (err.response && err.response.data.message) {
                message = err.response.data.message;
            }
            toast.error(message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
    };

    render() {
        const { loading, data, columns, showTable, requestAccess, showData } = this.state;
        const tableData = {
            columns,
            exportHeaders: true,
            data: showData,
        };
        return (
            <div>
                {!requestAccess ? <div className="justify-content-center pt-2 main-contain-title">
                    <>
                        <p>This feature is restricted.</p>
                        <Button onClick={() => this.sendRequestAccess()}
                        disabled={this.state.loading}>Request Access for free</Button>
                    </>
                </div> :
                    <>
                        <div className="d-flex justify-content-center scan-parcel-loader">
                            <ClipLoader
                                size={50}
                                color={"#123abc"}
                                loading={loading}
                            />
                        </div>
                        {!loading ?
                            <Card>
                                <CardHeader className="c-header">Bulk Upload (Assigned)</CardHeader>
                                {!showTable ? <Formik
                                    // enableReinitialize
                                    initialValues={{
                                        SR: this.state.SR,
                                        location: this.state.location,
                                        package: this.state.package,
                                        trackingstatus: this.state.trackingstatus,
                                        comment: this.state.comment,
                                    }}

                                    onSubmit={(values, { setSubmitting, setErrors }) => {
                                        this.setErrors = setErrors;
                                        this.submitHandler(values);
                                    }}>
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleSubmit }) => (
                                        <CardBody>
                                            <div className="d-flex justify-content-end">
                                                <a href="/bulkexternalupload.csv" className="help-text-style">Download Template</a>
                                            </div>
                                            <div className="col-12">
                                                <Label>Upload File(.csv, .xlsx, .xls)</Label>
                                                <a href="/instructions.txt" target="_blank" className="help-text-style" download>View Instructions</a>
                                                <FormGroup>
                                                    <input
                                                        accept=".csv, .xlsx, .xls"
                                                        hidden ref={this.input}
                                                        type="file"
                                                        onChange={this.onSelectFile()} />
                                                    <FormFeedback>
                                                        {errors.file && touched.file && errors.file}
                                                    </FormFeedback>
                                                    <button className="btn btn-primary" onClick={e => this.input.current && this.input.current.click()}>Browse</button>
                                                </FormGroup>
                                            </div>
                                            <div className="d-flex overflow-auto">
                                                {this.state.document && (
                                                    <div className="d-flex flex-column m-2">
                                                        {/* <div style={{ position: "relative", left: "20px" }}>
																		<button className="btn btn-link" onClick={() => this.imageRemoveHandler()}
																			style={{ position: "absolute", paddingLeft: "40px", alignSelf: "center" }}><img
																				src={close} alt="" /></button>
																	</div>
																	<img alt="image" style={{ width: "100px", height: "100px", border: "2px solid #b3b3b3" }}
																		src={this.state.documentImage} /> */}
                                                        <span classes="mt-2">{this.state.document.name}</span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <button className="btn btn-submit" disabled={this.state.document ? false : true} onClick={() => this.saveInfo()}>
                                                    {loading ?
                                                        <ClipLoader
                                                            size={15}
                                                            color={"#123abc"}
                                                            loading={true}
                                                        /> : 'Submit'
                                                    }
                                                </button>
                                            </div>
                                        </CardBody>
                                    )}
                                </Formik> : null}
                            </Card>
                            : null}
                        {showTable ?
                            <DataTableExtensions  {...tableData}>
                                <DataTable
                                    pagination
                                    highlightOnHover
                                    columns={columns}
                                    export={true}
                                    responsive={true}
                                    data={data}
                                />
                            </DataTableExtensions> : null} </>
                }

            </div>
        )
    }
}
