import React, { Component } from 'react'
import { Button, Card, CardBody, CardHeader, UncontrolledTooltip } from 'reactstrap'
import "./adhocInvoice.css"
import { toast } from 'react-toastify';
import { checkScreenAccess, deleteTemplate, getTemplateLsit, requestAccess } from '../../api/Form';
import moment from 'moment';
import { phoneNoVerifyCheck } from '../../utils/utils';
export default class AdhocInvoice extends Component {
    state = {
        requestAccess: true,
        loading: true,
        userId: '',
        tempList: [],
        tempName: "",
    }

    packageHandler = (refno) => {
        this.props.history.push({ pathname: '/admin/generateinvoice', state: refno })
    }
    editPackageHandler = (refno) => {
        this.props.history.push({ pathname: '/admin/edittemplate', state: refno })
    }

    async componentDidMount() {
        const historyPush = this.props.history.push
        phoneNoVerifyCheck(historyPush)
        const userId = await localStorage.getItem('userId');
        this.setState({ userId })
        var userType = await localStorage.getItem('accounttypeUsers');
        if (userType !== 'Admin') {
            await checkScreenAccess(
                {
                    'userId': userId,
                    'screenType': 'Adhoc Invoice'
                }
            ).then((response) => {
                this.setState({ loading: false, requestAccess: true });
            }).catch(err => {
                this.setState({ loading: false, requestAccess: false });
            });
        } else {
            this.setState({ requestAccess: true, loading: false })
        }
        this.tempListApi();
    }

    tempListApi = () => {
        this.setState({ loading: true });
        getTemplateLsit(
            {
                "userId": this.state.userId
            }
        ).then((response) => {
            this.setState({ loading: false, tempList: response.data.data })
        }).catch(err => {
            this.setState({ loading: false, tempList: [] })

        })
    }

    deleteTemplate = (id) => {
        if (id) {
            if (window.confirm("Are you sure you want to delete this template?")) {
                deleteTemplate({
                    'id': id,
                    "userId": this.state.userId
                })
                    .then(Response => {
                        toast.success('Entry is deleted successfully!!', {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        this.tempListApi();
                    })
                    .catch(err => {
                        toast.error(err.response.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    })
            }
        }
    };



    sendRequestAccess = async () => {
        this.setState({ loading: true });
        const info = await localStorage.getItem('auth');
        const sendInfo = JSON.parse(info);
        requestAccess({
            'username': sendInfo.username,
            'firstName': sendInfo.firstName,
            'lastName': sendInfo.lastName,
            'accounttypeUsers': sendInfo.accounttypeUsers,
            'businessname': sendInfo.businessname,
            'ADCode': sendInfo.ADCode,
            'address': sendInfo.address,
            'email': sendInfo.email,
            'phoneNumber': sendInfo.phonenumber,
            'userId': sendInfo.id,
            'screenType': 'Adhoc Invoice',
        }).then(Response => {
            this.setState({ loading: false });
            toast.success(Response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }).catch(err => {
            this.setState({ loading: false });
            let message = err.message;
            if (err.response && err.response.data.message) {
                message = err.response.data.message;
            }
            toast.error(message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })

    };

    render() {
        const { requestAccess, tempList } = this.state;
        return (
            <div className="mt-2 style">
                {!requestAccess ?
                    <div className="justify-content-center pt-2 main-contain-title">
                        <>
                            <p>This feature is restricted.</p>
                            <Button
                                onClick={() => this.sendRequestAccess()}
                                disabled={this.state.loading}
                            >Request Access for free</Button>
                        </>
                    </div>
                    :
                    <Card>
                        <CardHeader className="c-header">Adhoc invoice/quote</CardHeader>
                        <CardBody>
                            <div className="d-flex justify-content-end mb-3">
                                <button className="btn btn-primary d-flex align-items-center" onClick={() => this.props.history.push('/admin/addtemplate')}>
                                    {/* <BsPlus className='icon-size' /> */}
                                    Add template</button>
                            </div>
                            <div>
                                <table class="table table-hover" style={{ overflow: "scroll", minWidth: "800px" }}>
                                    <thead>
                                        <tr>
                                            <th>Template Name</th>
                                            <th>Create on</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className='adhoc-table'>
                                        {
                                            tempList && tempList.map((item) => {
                                                return (
                                                    <tr>
                                                        <td>{item.name}</td>
                                                        <td>{moment(item.name.createdAt).format('L')}</td>
                                                        <td>
                                                            <span>
                                                                <i class='fa fa-edit table-icons' style={{ fontSize: "20px" }} role='button' id='edit-temp' onClick={() => this.editPackageHandler(item)}></i>
                                                                <UncontrolledTooltip placement="top" target="edit-temp">
                                                                    Edit template
                                                                </UncontrolledTooltip>
                                                            </span>
                                                            <span>
                                                                <i class='fa fa-remove table-icons' style={{ fontSize: "20px" }} role='button' id='delete-temp' onClick={() => this.deleteTemplate(item.id)}></i>
                                                                <UncontrolledTooltip placement="top" target="delete-temp">
                                                                    Delete template
                                                                </UncontrolledTooltip>
                                                            </span>
                                                            <span>
                                                                <i class='fa fa-file-text-o' style={{ fontSize: "18px" }} role='button' id='generate-temp' onClick={() => this.packageHandler(item)}></i>
                                                                <UncontrolledTooltip placement="top" target="generate-temp" >
                                                                    Generate Invoice
                                                                </UncontrolledTooltip>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                }
            </div >
        )
    }
}
