import React, { Component } from 'react'
import { CardHeader, CardBody, FormGroup, Label, Input, Card } from 'reactstrap';
import { shippingHistory } from '../../api/Form';
import { phoneNoVerifyCheck, trim } from '../../utils/utils';
import { ClipLoader } from "react-spinners";
import product from '../../assets/product.png'
import { callActivityApi } from '../../utils/saveUserActivity';
import PaginationPage from '../../components/PaginationPage';
export default class ShippingHistory extends Component {
  state = {
    shippingHistory: [],
    viewBy: 'ViewbyRefNo',
    loading: true,
    costumes: [],
  }


  async componentDidMount() {
    callActivityApi({ 'pageName': 'Shipping History', 'description': 'User has visited Shipping history Screen' });
    const historyPush = this.props.history.push
    phoneNoVerifyCheck(historyPush)
    const deviceAdCode = await localStorage.getItem('adCodes');
    //     this.setState({ deviceADcode })
    //     const userId = await localStorage.getItem('userId');
    //     console.log(userId)
    //     this.setState({ userId })
    // console.log(deviceADcode)
    shippingHistory({ deviceAdCode })
      .then(Response => {
        this.setState({ shippingHistory: Response.data.data, loading: false })
      })
      .catch(err => {
        console.log(err)
      })
  }


  handleChange = (e) => {
    this.setState({ viewBy: e.target.value })
  }

  trackHandler = (nfcserialno) => {
    this.props.history.push({ pathname: './shippingHistoryDetails', state: nfcserialno })
  }

  packageHandler = (refno) => {
    this.props.history.push({ pathname: './packageHandler', state: refno })
  }
  render() {
    const data = this.state.shippingHistory.map(p => p)
    const { costumes } = this.state;
    const totalPages = 10;
    return (
      <div className="mt-2 style">
        <Card>
          <CardHeader className="c-header">
            <div className="row ">
              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-8">
                <span>Shipping History</span>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 ml-auto ml-md-auto pr-0 pr-sm-0 pr-md-0 pr-lg-5 pr-xl-5 ">
                <div className="row">
                  <div className="col-12 col-sm-612col-md-12 col-lg-6 col-xl-6">
                    <FormGroup check className="pr-0 pr-sm-0 pr-md-4 pr-lg-4 pr-xl-4">
                      <Label check>
                        <Input type="radio" name="radio2" value="ViewbyRefNo" onChange={this.handleChange} defaultChecked />{' '}
                        <span style={{ whiteSpace: "nowrap" }}> View by Ref No</span>
                      </Label>
                    </FormGroup>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12  col-lg-6 col-xl-6">

                    <FormGroup check >
                      <Label check>
                        <Input type="radio" name="radio2" value="ViewbyTripSerialID" onChange={this.handleChange} />{' '}
                        <span style={{ whiteSpace: "nowrap" }}>View by Trip Serial ID</span>

                      </Label>
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <div className="row ">

              {this.state.loading === true ?
                <div className="loader-style">
                  < ClipLoader
                    size={15}
                    color={"#123abc"}
                    loading={true}
                  />
                </div>
                :
                <>
                  {data.map(p => {
                    return (
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 pb-4">
                        <Card className="shadow border-0">
                          <CardBody>
                            <div className="row">
                              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <div className="row">
                                  <div className="col-6">
                                    <Label for="exampleEmail " className="pr-5 label-style" style={{ whiteSpace: "nowrap" }}>Ref No </Label>
                                  </div>
                                  <div className="col-6">
                                    <Label for="exampleEmail" className="label-description" >{trim(p.refno, 13)}</Label>
                                  </div>
                                </div>
                              </div>
                              {this.state.viewBy === 'ViewbyTripSerialID' &&
                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                  <div className="row">
                                    <div className="col-6">
                                      <Label for="exampleEmail " className="pr-5 label-style" style={{ whiteSpace: "nowrap" }}>TS ID</Label>
                                    </div>
                                    <div className="col-6">
                                      <Label for="exampleEmail" className="label-description" >{trim(p.nfcserialno, 13)}</Label>
                                    </div>
                                  </div>
                                </div>
                              }
                              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <div className="row">
                                  <div className="col-6">
                                    <Label for="exampleEmail " className="pr-5 label-style" style={{ whiteSpace: "nowrap" }}>Origin</Label>
                                  </div>
                                  <div className="col-6">
                                    <Label for="exampleEmail" className="label-description" >{trim(p.origin, 13)}</Label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <div className="row">
                                  <div className="col-6">
                                    <Label for="exampleEmail " className="pr-5 label-style">Destination</Label>
                                  </div>
                                  <div className="col-6">
                                    <Label for="exampleEmail" className="label-description" >{trim(p.destination, 8)}</Label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                    <Label for="exampleEmail " className="pr-5 label-style">Quantity</Label>
                                  </div>
                                  <div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                    <Label for="exampleEmail" className="label-description" >{trim(p.quantity, 13)}</Label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <div className="row">
                                  <div className="col-6">
                                    <Label for="exampleEmail " className="pr-5 label-style">Status</Label>
                                  </div>
                                  <div className="col-6">
                                    <span class="badge badge-pill badge-primary font-weight-light px-2">{p.trackingstatus}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="row d-flex justify-content-center">
                                  <div className="d-flex justify-content-end pr-2">
                                    <button className="btn btn-submit-track px-4" onClick={() => this.trackHandler(p.refno)}>Track</button>
                                  </div>
                                  <div className="d-flex justify-content-end">
                                    <button className="btn btn-submit-package px-4" onClick={() => this.packageHandler(p.refno)}> <img src={product} alt="" className="img-fluid" /> </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </CardBody>
                        </Card>

                      </div>
                    )
                  })}
                </>}
            </div>
            {/* <div className='d-flex justify-content-end'>
              <PaginationPage totalPages={totalPages} />
            </div> */}
          </CardBody>
        </Card>
      </div>
    )
  }
}
