import React, { Component } from "react";
import "./Chats.css";
import { Modal, ModalBody, ModalHeader, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { chatsConfirmRequest, chatsList, chatsRequest, deleteChats, readChats, search, callHistory } from "../../api/Form";
import moment from "moment";
import accept from "../../assets/noun_accept_3235169.png";
import reject from "../../assets/noun_reject_2516177 (1).png";
import { ClipLoader } from "react-spinners";
import chat from "../../assets/noun_chat_2849584 (1).svg";
import { debounce } from "../../utils/utils";
import { trim } from "../../utils/utils";
import { Context } from "../../context";
import Logo2 from "../../assets/new_logo.png";
import missed from "../../assets/Group 1535.svg";
import incoming from "../../assets/Group 1534.svg";
import outgoing from "../../assets/Group 1533.svg";
import classnames from "classnames";

export default class chatList extends Component {
  static contextType = Context;
  state = {
    deviceAdCode: "",
    userId: "",
    chatsList: [],
    message: [],
    callHistory: [],
    statusLoading: false,
    text: "",
    senderId: "",
    search: "",
    userSearch: [],
    contactScreen: false,
    searchContact: "",
    contactList: [],
    chatScreen: "",
    mobileView: true,
    callHistoryScreen: false,
    activeTab: "message",
    callStatus: null,
  };
  tabToggleHandler = (e) => {
    this.setState({
      activeTab: e,
    });
  };

  async componentDidMount() {
    const deviceAdCode = await localStorage.getItem("adCodes");
    const userId = await localStorage.getItem("userId");
    this.setState({ userId, deviceAdCode });
    this.getChatList(userId, deviceAdCode);
    this.getCallHistory(userId);
    if (this.props.receiverId) {
      this.contactRequestSendHandler(this.props.receiverId.id);
      chatsList({ userId, deviceAdCode })
        .then((Response) => {
          let infoData = Response.data.data;
          let obj = infoData.find((o) => o.userId == this.props.receiverId.id);
          //console.log(obj);
          if (obj) {
            this.setState({ obj, mobileView: false });
            this.context.setChatId(obj.id);
            this.props.data("chatScreen", obj, false);
            if (obj.status === "accepted") {
              readChats({ chatId: obj.id, userId: this.state.userId }).then((Response) => {
                console.log(Response);
              });
            }
          }
          //this.showMessageHandler = (obj, 'chatScreen');
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  getChatList = (userId, deviceAdCode) => {
    chatsList({ userId, deviceAdCode })
      .then((Response) => {
        this.setState({ chatsList: Response.data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCallHistory = (userId) => {
    callHistory({ userId })
      .then((response) => {
        this.setState({ callHistory: response.data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.callStatus !== this.context.call) {
      this.setState({ callStatus: this.context.call });
      if (this.context.call === null) {
        const userId = localStorage.getItem("userId");
        this.getCallHistory(userId);
      }
    }
  }

  showMessageHandler = (userChat, chatScreen) => {
    this.setState({ userChat, mobileView: false });
    this.context.setChatId(userChat.id);
    this.props.data(chatScreen, userChat, false);
    if (userChat.status === "accepted") {
      readChats({ chatId: userChat.id, userId: this.state.userId }).then((Response) => {
        console.log(Response);
      });
    }
  };

  searchMessagesHandler = (e) => {
    if (e.target.value.length > 3) {
      this.searchContact(e.target.value);
      this.setState({ search: e.target.value });
    } else {
      this.getChatList(this.state.userId);
      this.setState({ search: e.target.value });
    }
  };

  searchContact = debounce((value) => {
    const { chatsList } = this.context;
    let chatsLists = chatsList.filter((contactName) => {
      return contactName.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    });
  }, 1000);

  newContactHandler = () => {
    this.setState({ contactScreen: !this.state.contactScreen });
  };

  callHistoryHandler = () => {
    console.log("call history handler");
    this.setState({ callHistoryScreen: !this.state.callHistoryScreen });
  };

  historyCloseHandler = () => {
    this.setState({ callHistoryScreen: !this.state.callHistoryScreen });
  };

  searchContactHandlers = (e) => {
    this.setState({ searchContact: e.target.value });
  };

  searchContactHandler = () => {
    search({ userId: +this.state.userId, name: this.state.searchContact })
      .then((Response) => {
        this.setState({ contactList: Response.data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  deleteHandler = (id) => {
    deleteChats({ chatId: id })
      .then((Response) => {
        this.props.data("", "");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  contactRequestSendHandler = (userId) => {
    chatsRequest({ userId, senderId: this.state.userId })
      .then((Response) => {
        this.setState({ contactScreen: false, contactList: [], searchContact: "" });
        // this.getChatList(this.state.userId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  requestHandler = (status, chatId, senderId) => {
    this.setState({ chatId, statusLoading: true });
    chatsConfirmRequest({ status, chatId, userId: senderId })
      .then((Response) => {
        console.log(Response);
        this.setState({ statusLoading: false });
        this.getChatList(this.state.userId);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ statusLoading: false });
      });
  };

  keyPress = (e) => {
    if (e.keyCode === 13) {
      this.searchContactHandler();
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.callHistory.length) {
      this.setState({ callHistory: nextProps.callHistory });
    }
  }
  render() {
    const { callHistory, userId, contactList } = this.state;
    const chatsList = this.context.chatsList.filter((contactName) => {
      return contactName.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
    });
    const url = "https://www.addressdynamic.com/api/";
    return (
      <>
        <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-3 pr-lg-0  pr-xl-0 ">
          <div className="header-1 pt-1 pl-1">
            <div className="">
              <Nav tabs className="d-flex justify-content-around">
                <NavItem>
                  <NavLink className={classnames({ active: this.state.activeTab === "message" })} onClick={() => this.tabToggleHandler("message")}>
                    Messages
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className={classnames({ active: this.state.activeTab === "history" })} onClick={() => this.tabToggleHandler("history")}>
                    CallHistory
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
          <div className="chat-box-list overflow-style">
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="message">
                <div className="contact-add d-flex justify-content-end  align-items-end">
                  <div className="circle-style shadow d-flex justify-content-center align-items-center">
                    <img src={chat} alt="" width="30px" height="30px" onClick={this.newContactHandler} />
                  </div>
                </div>
                <>
                  <div class="input-group py-2 px-1">
                    <input
                      class="form-control py-2 border-right-0 border"
                      type="search"
                      value={this.state.search}
                      id="example-search-input"
                      placeholder="Search"
                      onChange={this.searchMessagesHandler}
                    />
                    <span class="input-group-append">
                      <button class="btn btn-outline-secondary border-left-0 border" type="button" onClick={this.searchHandler} style={{ 'z-index': 0 }}>
                        <i class="fa fa-search"></i>
                      </button>
                    </span>
                  </div>

                  <Modal isOpen={this.state.contactScreen} toggle={this.newContactHandler}>
                    <ModalHeader toggle={this.newContactHandler}>Search Contact</ModalHeader>
                    <ModalBody>
                      <div class="input-group py-2 px-1">
                        <input
                          class="form-control py-2 border-right-0 border"
                          type="search"
                          value={this.state.searchContact}
                          id="example-search-input"
                          placeholder="Search"
                          onKeyDown={this.keyPress}
                          onChange={this.searchContactHandlers}
                        />
                        <span class="input-group-append">
                          <button class="btn btn-outline-secondary border-left-0 border" type="button" onClick={this.searchContactHandler} style={{ 'z-index': 0 }}>
                            <i class="fa fa-search"></i>
                          </button>
                        </span>
                      </div>
                      {contactList.map((p) => {
                        return (
                          <div className="row m-0 pt-3" id="id_div_1" onClick={() => this.contactRequestSendHandler(p.id)}>
                            <div className="col-2 col-sm-1  col-md-2 col-lg-3 col-xl-3 pr-0 pl-0  d-flex justify-content-center">
                              <img src={p.profile ? p.profile : Logo2} alt="" className="img-fluid userProfile" />
                            </div>
                            <div className="col-6 col-sm-9 col-lg-5 col-xl-5 col-md-8 pl-2 pl-md-3 pl-sm-3 pl-lg-0 pl-xl-0">
                              <div className="col-12 pl-0">
                                <div>
                                  <span className="chat-title">{p.name}</span>
                                </div>
                              </div>
                              <div className="col-12 pl-0">
                                <div className="row m-0">
                                  <div>
                                    <span className="chat-description pr-3">{p.city}</span>
                                  </div>
                                  <div>
                                    <span className="chat-description">{p.country}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </ModalBody>
                  </Modal>

                  {chatsList.map((p) => {
                    return (
                      <>
                        <div className="row m-0 pt-3 " id="id_div_1" onClick={() => this.showMessageHandler(p, "chatScreen")}>
                          <div className="col-2 col-sm-1  col-md-2 col-lg-3 col-xl-3 pr-0 pl-0  d-flex justify-content-center">
                            <img src={p.profile ? url + p.profile : Logo2} alt="" className="img-fluid userProfile" />
                          </div>
                          <div className="col-6 col-sm-9 col-lg-5 col-xl-5 col-md-8 pl-2 pl-md-3 pl-sm-3 pl-lg-0 pl-xl-0" role="button">
                            <div className="col-12 pl-0">
                              <span className="chat-title">{p.name}</span>
                            </div>
                            <div className="col-12 pl-0">
                              <span className="chat-description">{trim(p.lastMessage, 13)}</span>
                            </div>
                          </div>
                          {p.status === "accepted" && (
                            <>
                              <div className="col-4 col-sm-2 col-lg-4 col-xl-4 col-md-2 text-right ">
                                <span className="chat-time">{moment.utc(p.createdAt).format("HH:mm")}</span>
                                {p.unread > 0 && (
                                  <div className="d-flex justify-content-end">
                                    <span className="notification d-flex justify-content-center"> {p.unread}</span>
                                  </div>
                                )}
                              </div>
                              <div class="remove_link ">
                                <a href="#id_X_0">
                                  <icon class="fa fa-times-circle icon_size16" onClick={() => this.deleteHandler(p.id)}></icon>
                                </a>
                              </div>
                            </>
                          )}

                          {p.status === "pending" && p.senderId !== +this.state.userId ? (
                            this.state.statusLoading && this.state.chatId === p.id ? (
                              <ClipLoader size={15} color={"#123abc"} loading={true} />
                            ) : (
                              <div className="col-4 col-sm-2 col-lg-4 col-xl-4 col-md-2 text-right row d-flex justify-content-end align-items-center pr-0">
                                <img
                                  src={accept}
                                  alt=""
                                  height="20"
                                  width="20px"
                                  className="mr-2"
                                  onClick={() => this.requestHandler("accepted", p.id, p.senderId)}
                                />
                                <img
                                  src={reject}
                                  alt=""
                                  height="20"
                                  width="20px"
                                  className=""
                                  onClick={() => this.requestHandler("rejected", p.id, p.senderId)}
                                />
                              </div>
                            )
                          ) : p.status === "accepted" ? null : (
                            <div className="col-4 col-sm-2 col-lg-4 col-xl-4 col-md-2 text-right row d-flex justify-content-end align-items-center pr-0">
                              <h1 className="pending-title">Pending</h1>
                            </div>
                          )}
                        </div>
                      </>
                    );
                  })}
                </>
              </TabPane>
              <TabPane tabId="history">
                {callHistory.map((a) => {
                  return (
                    <div className="row m-0 pt-3 ">
                      <div className="col-2 col-sm-1  col-md-2 col-lg-3 col-xl-3 pr-0 pl-0  d-flex justify-content-center">
                        {a.fromUserId === +userId && <img src={a.toProfile ? url + a.toProfile : Logo2} alt="" className="img-fluid userProfile" />}
                        {a.fromUserId !== +userId && (
                          <img src={a.fromProfile ? url + a.fromProfile : Logo2} alt="" className="img-fluid userProfile" />
                        )}
                      </div>
                      <div className="col-6 col-sm-9 col-lg-5 col-xl-5 col-md-8 pl-2 pl-md-3 pl-sm-3 pl-lg-0 pl-xl-0">
                        <div className="col-12 pl-0">
                          <span className={a.fromUserId !== +userId && a.status === "missed" ? "history-title" : "history-title-1"}>
                            {a.fromUserId !== +userId ? a.fromUserName : a.toUserName}
                          </span>
                        </div>
                        <div className="col-12 pl-0">
                          <span className={a.fromUserId !== +userId && a.status === "missed" ? "history-description" : "history-description-1"}>
                            {a.fromUserId === +userId ? "outgoing" : a.status}
                            {a.status === "missed" && a.fromUserId !== +userId && <img src={missed} alt="" className="pl-2" />}
                            {a.status === "received" && a.fromUserId !== +userId && <img src={incoming} alt="" className="pl-2" />}
                            {a.fromUserId === +userId && <img src={outgoing} alt="" className="pl-2" />}
                          </span>
                        </div>
                      </div>
                      <div className="col-4 col-sm-2 col-lg-4 col-xl-4 col-md-2 text-right ">
                        <span className="history-description-1">{moment(a.createdAt).format("HH:mm")}</span>
                      </div>
                    </div>
                  );
                })}

                {/* <div className="row m-0 pt-3 ">
										<div
											className="col-2 col-sm-1  col-md-2 col-lg-3 col-xl-3 pr-0 pl-0  d-flex justify-content-center">
											<img src={user} alt="" className="img-fluid userProfile" />
										</div>
										<div className="col-6 col-sm-9 col-lg-5 col-xl-5 col-md-8 pl-2 pl-md-3 pl-sm-3 pl-lg-0 pl-xl-0">
											<div className="col-12 pl-0">
												<span className="history-title-1">Nicholas Dunn</span>
											</div>
											<div className="col-12 pl-0">
												<span className="history-description-1">Incoming Call <img src={incoming} alt="" className="pl-2" /></span>
											</div>
										</div>
										<div className="col-4 col-sm-2 col-lg-4 col-xl-4 col-md-2 text-right ">
											<span className="history-description-1">10:24</span>
										</div>
									</div>

									<div className="row m-0 pt-3 ">
										<div
											className="col-2 col-sm-1  col-md-2 col-lg-3 col-xl-3 pr-0 pl-0  d-flex justify-content-center">
											<img src={user} alt="" className="img-fluid userProfile" />
										</div>
										<div className="col-6 col-sm-9 col-lg-5 col-xl-5 col-md-8 pl-2 pl-md-3 pl-sm-3 pl-lg-0 pl-xl-0">
											<div className="col-12 pl-0">
												<span className="history-title-1">Nicholas Dunn</span>
											</div>
											<div className="col-12 pl-0">
												<span className="history-description-1">Outgoing Call <img src={outgoing} alt="" className="pl-2" /></span>
											</div>
										</div>
										<div className="col-4 col-sm-2 col-lg-4 col-xl-4 col-md-2 text-right ">
											<span className="history-description-1">10:24</span>
										</div>
									</div> */}
              </TabPane>
            </TabContent>
          </div>
        </div>
      </>
    );
  }
}
