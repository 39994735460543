import React, { Component } from 'react'

export class Faq extends Component {
    render() {
        return (
            <section className='faq-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h2>Frequently Asked Questions</h2>

                            <div id="accordion">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                How can I track my international package?
                                                <i class="fa fa-angle-down"></i>
                                            </button>
                                        </h5>
                                    </div>

                                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                        <div class="card-body">
                                            At Postkodes, use our global parcel tracking tool for accurate updates on your international package. Simply enter your tracking number, sea or air waybill or container number to track on our website or mobile app.
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header" id="headingTwo">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                Do you offer cheap international shipping?
                                                <i class="fa fa-angle-down"></i>
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                        <div class="card-body">
                                            Yes, Postkodes specializes in cheap international shipping without compromising on service quality. We offer competitive rates for all international destinations, ensuring your packages are delivered promptly and efficiently. Our goal is to provide affordable solutions for your global shipping needs.
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                How do I book a delivery online?
                                                <i class="fa fa-angle-down"></i>
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                        <div class="card-body">
                                            Deliveries can be booked by contacting any of our network of partners or by visiting our website, select the 'Request Errand' option, fill in the necessary details. Our user-friendly platform makes the booking process quick and convenient, ensuring a seamless experience for all customers.
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header" id="heading5">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                                                What courier delivery services do you provide?
                                                <i class="fa fa-angle-down"></i>
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapse5" class="collapse" aria-labelledby="heading5" data-parent="#accordion">
                                        <div class="card-body">
                                            Postkodes through its partners offer a wide range of courier delivery services, including competitive local and international shipping services. Our services cater to both domestic and international needs, ensuring reliable and timely deliveries. We prioritize customer satisfaction in every service we provide.
                                        </div>
                                    </div>
                                </div>


                                <div class="card">
                                    <div class="card-header" id="heading8">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                                                What are the benefits of using your logistics SaaS?
                                                <i class="fa fa-angle-down"></i>
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapse8" class="collapse" aria-labelledby="heading8" data-parent="#accordion">
                                        <div class="card-body">
                                            Whether you operate a local delivery business or an international shipping service provider, the benefits of using our SaaS is access to all the tools need to run a world class logistics service for as little as nothing or for a fraction of the cost. You will also gain access to our global network of partners offering increased market share, peace of mind and security.
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header" id="heading10">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                                I am a logistics service provider how do I start using your logistics SaaS?
                                                <i class="fa fa-angle-down"></i>
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapse10" class="collapse" aria-labelledby="heading10" data-parent="#accordion">
                                        <div class="card-body">
                                            To join, simply register, we will do the standard validation and onboarding which is generally done with the 24 to 48 hours. There is no company wide deployment required so you can start operating once validation and onboarding has been completed. No credit card and no payment will either be required or asked to join.
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header" id="heading11">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse11" aria-expanded="false" aria-controls="collapse11">
                                                How much does it cost to use your logistics SaaS?
                                                <i class="fa fa-angle-down"></i>
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapse11" class="collapse" aria-labelledby="heading11" data-parent="#accordion">
                                        <div class="card-body">
                                            There is generally no cost, registration and use is free, however a small service fees will apply if you exceed the free usage quota.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}