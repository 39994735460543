import React, { Component } from 'react'
import { checkScreenAccess, requestAccess, searchShipmentBill, createShipmentBill } from '../../api/Form';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Formik } from 'formik';
import 'react-toastify/dist/ReactToastify.css';
import { CardHeader, CardBody, FormGroup, Label, Input, Card, Button, FormFeedback } from 'reactstrap';
import { callActivityApi } from '../../utils/saveUserActivity';
import { getLocation, phoneNoVerifyCheck } from '../../utils/utils';
import { calculateCBM } from '../../utils/businessLogic';
import moment from 'moment';
import DatePicker from "react-datepicker";
import PrintShippingBill from './PrintShippingBill';

const phoneRegExp = /^[\d\s\-\(\)\+]+$/;

const wayBillNo = { air: "Air Waybill No.", sea: "Sea Waybill No.", road: "Courier Manifest No.", rail: "Courier Manifest No." }

const Schema = Yup.object().shape({
    refNo: Yup.string()
        .required('Tracking Number is required'),
    shipperAddress: Yup.string()
        .required('Shipper Address is required'),
    shipperZipcode: Yup.string()
        .matches(/^\d+$/, 'Shipper Zipcode must be a number')
        .required('Shipper Zipcode is required'),
    shipperCity: Yup.string()
        .required('Shipper City is required'),
    shipperCountry: Yup.string()
        .required('Shipper Country is required'),
    shipperReference: Yup.string()
        .required('Shipper Reference is required'),
    shipperDeclaredValue: Yup.string()
        .required('Shipper Declared Value is required'),
    consigneeAddress: Yup.string()
        .required('Consignee Address is required'),
    consigneeZipcode: Yup.string()
        .matches(/^\d+$/, 'Consignee Zipcode must be a number')
        .required('Consignee Zipcode is required'),
    consigneeCity: Yup.string()
        .required('Consignee City is required'),
    consigneeState: Yup.string()
        .required('Consignee State is required'),
    consigneeCountry: Yup.string()
        .required('Consignee Country is required'),
    cargoAddress: Yup.string()
        .required('Cargo Address is required'),
    cargoZipcode: Yup.string()
        .matches(/^\d+$/, 'Cargo Zipcode must be a number')
        .required('Cargo Zipcode is required'),
    cargoCity: Yup.string()
        .required('Cargo City is required'),
    cargoState: Yup.string()
        .required('Cargo State is required'),
    cargoCountry: Yup.string()
        .required('Cargo Country is required'),
    cargoPhone: Yup.string()
        .matches(phoneRegExp, 'Cargo Phone must be a valid phone number')
        .required('Cargo Phone is required'),
    cargoFax: Yup.string()
        .matches(phoneRegExp, 'Cargo Fax must be a valid phone number'),
    notifyPhone: Yup.string()
        .matches(phoneRegExp, 'Notify Phone must be a valid phone number'),
    notifyEmail: Yup.string()
        .email('Invalid email format'),
    notifyZipcode: Yup.string()
        .matches(/^\d+$/, 'Notify Zipcode must be a number'),
    deliverPhone: Yup.string()
        .matches(phoneRegExp, 'Deliver Phone must be a valid phone number'),
    deliverEmail: Yup.string()
        .email('Invalid email format'),
    deliverZipcode: Yup.string()
        .matches(/^\d+$/, 'Deliver Zipcode must be a number'),
    grossWeight: Yup.string()
        .matches(/^\d+(\.\d+)?$/, 'Gross Weight must be a number'),
    cbm: Yup.string()
        .matches(/^\d+(\.\d+)?$/, 'CBM must be a number'),
});


export default class CreateShippingBill extends Component {
    constructor() {
        super();
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    state = {
        refNo: '',
        deviceADcode: '',
        loading: false,
        showContent: false,
        referanceNo: '',
        loader: false,
        requestAccess: '',
        isValidRefNo: false,
        shipmentData: {},
        // new state for create bill sections
        billNo: '',
        shipperAddress: '',
        shipperZipcode: '',
        shipperCity: '',
        shipperCountry: '',
        shipperReference: '',
        shipperDeclaredValue: '',

        consigneeAddress: '',
        consigneeZipcode: '',
        consigneeCity: '',
        consigneeState: '',
        consigneeCountry: '',

        cargoAddress: '',
        cargoZipcode: '',
        cargoCity: '',
        cargoState: '',
        cargoCountry: '',
        cargoPhone: '',
        cargoContact: '',
        cargoFax: '',

        notifyCompany: '',
        notifyName: '',
        notifyAddress: '',
        notifyPhone: '',
        notifyEmail: '',
        notifyZipcode: '',

        deliverCompany: '',
        deliverName: '',
        deliverAddress: '',
        deliverPhone: '',
        deliverEmail: '',
        deliverZipcode: '',

        portOfReceipt: '',
        portOfLoading: '',
        vessel: '',
        voyageNo: '',
        portOfDischarge: '',
        placeOfDelivery: '',

        comments: '',
        containerNo: '',
        sealNo: '',
        marksAndNumbers: '',
        description: '',
        grossWeight: '',
        cbm: '',

        shippedOnBoardTitle: '',
        shippedOnBoardDate: '',
        shippedOnBoardProvision: '',
        freightAndCharges: '',
        prepaid: '',
        collect: '',
        placeOfIssue: '',
        dateOfIssue: '',
    }

    handleInputChange(event) {
        this.setState({
            additionalEmail: event.target.value
        });
    }



    async componentDidMount() {
        callActivityApi({ 'pageName': 'Invoice', 'description': 'User has visited Invoice Screen' });
        const historyPush = this.props.history.push
        phoneNoVerifyCheck(historyPush)
        const refNo = this.props.location.state;
        this.setState({ referanceNo: refNo })
        if (refNo) {
            this.searchAdCode()
        }
        getLocation().then((location) => this.setState({ location: location }));
        const userId = await localStorage.getItem('userId');
        this.setState({ userId })
        const data = await localStorage.getItem('auth');
        this.setState({ userInfo: JSON.parse(data) })
        var userType = await localStorage.getItem('accounttypeUsers');
        if (userType !== 'Admin') {
            checkScreenAccess(
                {
                    'userId': userId,
                    'screenType': 'Invoice'
                }
            ).then((response) => {
                this.setState({ loading: false, requestAccess: true });
            }).catch(err => {
                this.setState({ loading: false, requestAccess: false });
            });
        } else {
            this.setState({ requestAccess: true, loading: false })
        }
    }

    sendRequestAccess = async () => {
        this.setState({ loading: true });
        const info = await localStorage.getItem('auth');
        const sendInfo = JSON.parse(info);
        // username, first name, last name, account type, business name, AD Code, address, email and phone number
        requestAccess({
            'username': sendInfo.username,
            'firstName': sendInfo.firstName,
            'lastName': sendInfo.lastName,
            'accounttypeUsers': sendInfo.accounttypeUsers,
            'businessname': sendInfo.businessname,
            'ADCode': sendInfo.ADCode,
            'address': sendInfo.address,
            'email': sendInfo.email,
            'phoneNumber': sendInfo.phonenumber,
            'userId': sendInfo.id,
            'screenType': 'Invoice',
        }).then(Response => {
            this.setState({ loading: false });
            toast.success(Response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }).catch(err => {
            this.setState({ loading: false });
            let message = err.message;
            if (err.response && err.response.data.message) {
                message = err.response.data.message;
            }
            toast.error(message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
    };

    searchAdCode = async (refNo, status) => {
        const info = await localStorage.getItem('auth');
        const sendInfo = JSON.parse(info);
        this.setState({
            deviceADcode: sendInfo.ADCode,
            refNo: refNo
        })



        // call search code api
        if (refNo || this.state.referanceNo) {
            searchShipmentBill({
                'deviceADcode': sendInfo.ADCode,
                'refNo': refNo ? refNo : this.state.referanceNo
            }).then(response => {
                if (response?.status) {

                    this.editShipmentBill(response?.data?.data)
                    this.setState({
                        showContent: true,
                        isValidRefNo: true,
                        shipmentData: response?.data?.data

                    })
                }


            }).catch(err => {



                toast.error(err?.response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
        } else {
            toast.error('Tracking Number is required', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }


    editShipmentBill = async (billData) => {

        this.setState({
            refNo: billData?.ref_no || billData?.refno,
            billNo: billData?.bill_no || billData?.masterNo,
            shipperAddress: billData?.shipper_address || billData?.shipperAddress1,
            shipperZipcode: billData?.shipper_zipcode,
            shipperCity: billData?.shipper_city,
            shipperCountry: billData?.shipper_country,
            shipperReference: billData?.shipper_reference,
            shipperDeclaredValue: billData?.shipper_declared_value,
            consigneeAddress: billData?.consignee_address,
            consigneeZipcode: billData?.consignee_zipcode,
            consigneeCity: billData?.consignee_city,
            consigneeState: billData?.consignee_state,
            consigneeCountry: billData?.consignee_country,
            cargoAddress: billData?.cargo_address,
            cargoZipcode: billData?.cargo_zipcode,
            cargoCity: billData?.cargo_city,
            cargoState: billData?.cargo_state,
            cargoCountry: billData?.cargo_country,
            cargoPhone: billData?.cargo_phone,
            cargoContact: billData?.cargo_contact,
            cargoFax: billData?.cargo_fax,
            notifyCompany: billData?.notify_company,
            notifyName: billData?.notify_name,
            notifyAddress: billData?.notify_address,
            notifyPhone: billData?.notify_phone,
            notifyEmail: billData?.notify_email,
            notifyZipcode: billData?.notify_zipcode,
            deliverCompany: billData?.deliver_company,
            deliverName: billData?.deliver_name,
            deliverAddress: billData?.deliver_address,
            deliverPhone: billData?.deliver_phone,
            deliverEmail: billData?.deliver_email,
            deliverZipcode: billData?.deliver_zipcode,
            portOfReceipt: billData?.port_of_receipt,
            portOfLoading: billData?.port_of_loading,
            vessel: billData?.vessel,
            voyageNo: billData?.voyage_no,
            portOfDischarge: billData?.port_of_discharge,
            placeOfDelivery: billData?.place_of_delivery,
            comments: billData?.comments,
            containerNo: billData?.container_no,
            sealNo: billData?.seal_no,
            marksAndNumbers: billData?.marks_and_numbers,
            description: billData?.description,
            grossWeight: billData?.gross_weight || billData?.weight,
            cbm: calculateCBM(Number(billData?.length), Number(billData?.width), Number(billData?.height), Number(billData?.quantity)),
            shippedOnBoardTitle: billData?.shipped_on_board_title,
            shippedOnBoardDate: billData?.shipped_on_board_date,
            shippedOnBoardProvision: billData?.shipped_on_board_provision,
            freightAndCharges: billData?.freight_and_charges,
            prepaid: billData?.prepaid,
            collect: billData?.collect,
            placeOfIssue: billData?.place_of_issue,
            dateOfIssue: billData?.date_of_issue
        });
    }



    submitHandler = async (values) => {


        const response = await createShipmentBill({
            ...values,
            deviceADcode: this.state.deviceADcode

        });

        if (response?.status === 200) {
            toast.success(response?.data?.message)
            this.searchAdCode(values.refNo)

        }
        // createShipmentBill()
    }

    formatDate(dateString) {

        if (!dateString) {
            return
        }
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }



    render() {

        const { requestAccess, } = this.state;
        const portType = this.state.shipmentData.modeOfTransport === "Sea" ? "Port" : "Place";
        return (
            <div className="mt-2 style">
                {!requestAccess ?
                    <div className="justify-content-center pt-2 main-contain-title">
                        <>
                            <p>This feature is restricted.</p>
                            <Button onClick={() => this.sendRequestAccess()}
                                disabled={this.state.loading}>Request Access for free</Button>
                        </>
                    </div>
                    :
                    <Card>
                        <CardHeader className="c-header">Issue Bills</CardHeader>
                        <CardBody>

                            {
                                <>
                                    <Formik
                                        isInitialValid={false}
                                        enableReinitialize
                                        initialValues={{
                                            refNo: this.state.refNo,
                                            billNo: this.state.billNo,
                                            shipperAddress: this.state.shipperAddress,
                                            shipperZipcode: this.state.shipperZipcode,
                                            shipperCity: this.state.shipperCity,
                                            shipperCountry: this.state.shipperCountry,
                                            shipperReference: this.state.shipperReference,
                                            shipperDeclaredValue: this.state.shipperDeclaredValue,
                                            consigneeAddress: this.state.consigneeAddress,
                                            consigneeZipcode: this.state.consigneeZipcode,
                                            consigneeCity: this.state.consigneeCity,
                                            consigneeState: this.state.consigneeState,
                                            consigneeCountry: this.state.consigneeCountry,
                                            cargoAddress: this.state.cargoAddress,
                                            cargoZipcode: this.state.cargoZipcode,
                                            cargoCity: this.state.cargoCity,
                                            cargoState: this.state.cargoState,
                                            cargoCountry: this.state.cargoCountry,
                                            cargoPhone: this.state.cargoPhone,
                                            cargoContact: this.state.cargoContact,
                                            cargoFax: this.state.cargoFax,
                                            notifyCompany: this.state.notifyCompany,
                                            notifyName: this.state.notifyName,
                                            notifyAddress: this.state.notifyAddress,
                                            notifyPhone: this.state.notifyPhone,
                                            notifyEmail: this.state.notifyEmail,
                                            notifyZipcode: this.state.notifyZipcode,
                                            deliverCompany: this.state.deliverCompany,
                                            deliverName: this.state.deliverName,
                                            deliverAddress: this.state.deliverAddress,
                                            deliverPhone: this.state.deliverPhone,
                                            deliverEmail: this.state.deliverEmail,
                                            deliverZipcode: this.state.deliverZipcode,
                                            portOfReceipt: this.state.portOfReceipt,
                                            portOfLoading: this.state.portOfLoading,
                                            vessel: this.state.vessel,
                                            voyageNo: this.state.voyageNo,
                                            portOfDischarge: this.state.portOfDischarge,
                                            placeOfDelivery: this.state.placeOfDelivery,
                                            comments: this.state.comments,
                                            containerNo: this.state.containerNo,
                                            sealNo: this.state.sealNo,
                                            marksAndNumbers: this.state.marksAndNumbers,
                                            description: this.state.description,
                                            grossWeight: this.state.grossWeight,
                                            cbm: this.state.cbm,
                                            shippedOnBoardTitle: this.state.shippedOnBoardTitle,
                                            shippedOnBoardDate: this.state.shippedOnBoardDate,
                                            shippedOnBoardProvision: this.state.shippedOnBoardProvision,
                                            freightAndCharges: this.state.freightAndCharges,
                                            prepaid: this.state.prepaid,
                                            collect: this.state.collect,
                                            placeOfIssue: this.state.placeOfIssue,
                                            dateOfIssue: this.state.dateOfIssue
                                        }}
                                        validationSchema={Schema}
                                        onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
                                            resetForm({ values: '' })
                                            this.setErrors = setErrors;
                                            this.submitHandler(values);
                                        }}>
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleSubmit,
                                            handleBlur,
                                            setFieldValue,
                                        }) => (
                                            <>
                                                <div className="col-12">
                                                    <FormGroup>
                                                        <Label>Enter Tracking Number</Label>
                                                        <div className="input-group">
                                                            <Input
                                                                type="text"
                                                                name="refNo"
                                                                invalid={errors.refNo && touched.refNo}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.refNo ? values.refNo : this.state.shipmentData.refno}
                                                                disabled={this.state.isValidRefNo}
                                                                placeholder="Enter Tracking Number"
                                                            />
                                                            <button className="btn btn-submit mx-2" onClick={() => this.searchAdCode(values.refNo)} >Search</button>
                                                        </div>
                                                    </FormGroup>
                                                </div>
                                                {this.state.showContent && <>
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className="col-lg-12 col-xs-12 col-sm-12 mb-3 mb-sm-3">

                                                                <Label >{this.state.shipmentData?.modeOfTransport === "Air" ? wayBillNo.air :
                                                                    this.state.shipmentData?.modeOfTransport === "Sea" ? wayBillNo.sea : wayBillNo.road
                                                                }</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="billNo"
                                                                    invalid={errors.billNo && touched.billNo}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.billNo}
                                                                    disabled={true}
                                                                    placeholder={this.state.shipmentData?.modeOfTransport === "Air" ? wayBillNo.air :
                                                                        this.state.shipmentData?.modeOfTransport === "Sea" ? wayBillNo.sea : wayBillNo.road
                                                                    } />
                                                            </div>
                                                            <FormFeedback>
                                                                {errors.billNo && touched.billNo && errors.billNo}
                                                            </FormFeedback>
                                                        </div>

                                                        <div className='row'>
                                                            <div className="col-lg-12 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                <Label>Shipper</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="shipperAddress"
                                                                    invalid={errors.shipperAddress && touched.shipperAddress}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.shipperAddress}
                                                                    placeholder="Enter Address" />
                                                                <FormFeedback>
                                                                    {errors.shipperAddress && touched.shipperAddress && errors.shipperAddress}
                                                                </FormFeedback>
                                                            </div>
                                                        </div>

                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <div className='row'>
                                                                    <div className="col-lg-4 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                        <Input
                                                                            type="text"
                                                                            name="shipperZipcode"
                                                                            invalid={errors.shipperZipcode && touched.shipperZipcode}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.shipperZipcode}
                                                                            placeholder="Enter Zipcode" />
                                                                        <FormFeedback>
                                                                            {errors.shipperZipcode && touched.shipperZipcode && errors.shipperZipcode}
                                                                        </FormFeedback>
                                                                    </div>
                                                                    <div className="col-lg-4 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                        <Input
                                                                            type="text"
                                                                            name="shipperCity"
                                                                            invalid={errors.shipperCity && touched.shipperCity}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.shipperCity}
                                                                            placeholder="Enter City" />
                                                                        <FormFeedback>
                                                                            {errors.shipperCity && touched.shipperCity && errors.shipperCity}
                                                                        </FormFeedback>
                                                                    </div>
                                                                    <div className="col-lg-4 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                        <Input
                                                                            type="text"
                                                                            name="shipperCountry"
                                                                            invalid={errors.shipperCountry && touched.shipperCountry}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.shipperCountry}
                                                                            placeholder="Enter Country" />
                                                                        <FormFeedback>
                                                                            {errors.shipperCountry && touched.shipperCountry && errors.shipperCountry}
                                                                        </FormFeedback>
                                                                    </div>
                                                                </div>
                                                            </FormGroup>
                                                        </div>

                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <div className='row'>
                                                                    <div className="col-lg-6 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                        <Label>Shipper's Reference</Label>
                                                                        <Input
                                                                            type="text"
                                                                            name="shipperReference"
                                                                            invalid={errors.shipperReference && touched.shipperReference}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.shipperReference}
                                                                            placeholder="Enter Reference" />
                                                                        <FormFeedback>
                                                                            {errors.shipperReference && touched.shipperReference && errors.shipperReference}
                                                                        </FormFeedback>
                                                                    </div>
                                                                    <div className="col-lg-6 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                        <Label>Shipper Declared Value</Label>
                                                                        <Input
                                                                            type="text"
                                                                            name="shipperDeclaredValue"
                                                                            invalid={errors.shipperDeclaredValue && touched.shipperDeclaredValue}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.shipperDeclaredValue}
                                                                            placeholder="Enter Declared Value" />
                                                                        <FormFeedback>
                                                                            {errors.shipperDeclaredValue && touched.shipperDeclaredValue && errors.shipperDeclaredValue}
                                                                        </FormFeedback>
                                                                    </div>

                                                                </div>
                                                            </FormGroup>
                                                        </div>

                                                        <div className='row'>
                                                            <div className="col-lg-12 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                <Label>Consignee</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="consigneeAddress"
                                                                    invalid={errors.consigneeAddress && touched.consigneeAddress}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.consigneeAddress}
                                                                    placeholder="Enter Address" />
                                                                <FormFeedback>
                                                                    {errors.consigneeAddress && touched.consigneeAddress && errors.consigneeAddress}
                                                                </FormFeedback>
                                                            </div>
                                                        </div>

                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <div className='row'>
                                                                    <div className="col-lg-3 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                        <Input
                                                                            type="text"
                                                                            name="consigneeZipcode"
                                                                            invalid={errors.consigneeZipcode && touched.consigneeZipcode}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.consigneeZipcode}
                                                                            placeholder="Enter Zipcode" />
                                                                        <FormFeedback>
                                                                            {errors.consigneeZipcode && touched.consigneeZipcode && errors.consigneeZipcode}
                                                                        </FormFeedback>
                                                                    </div>
                                                                    <div className="col-lg-3 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                        <Input
                                                                            type="text"
                                                                            name="consigneeCity"
                                                                            invalid={errors.consigneeCity && touched.consigneeCity}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.consigneeCity}
                                                                            placeholder="Enter City" />
                                                                        <FormFeedback>
                                                                            {errors.consigneeCity && touched.consigneeCity && errors.consigneeCity}
                                                                        </FormFeedback>
                                                                    </div>
                                                                    <div className="col-lg-3 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                        <Input
                                                                            type="text"
                                                                            name="consigneeState"
                                                                            invalid={errors.consigneeState && touched.consigneeState}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.consigneeState}
                                                                            placeholder="Enter State" />
                                                                        <FormFeedback>
                                                                            {errors.consigneeState && touched.consigneeState && errors.consigneeState}
                                                                        </FormFeedback>
                                                                    </div>
                                                                    <div className="col-lg-3 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                        <Input
                                                                            type="text"
                                                                            name="consigneeCountry"
                                                                            invalid={errors.consigneeCountry && touched.consigneeCountry}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.consigneeCountry}
                                                                            placeholder="Enter Country" />
                                                                        <FormFeedback>
                                                                            {errors.consigneeCountry && touched.consigneeCountry && errors.consigneeCountry}
                                                                        </FormFeedback>
                                                                    </div>
                                                                </div>
                                                            </FormGroup>
                                                        </div>

                                                        <div className='row'>
                                                            <div className="col-lg-12 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                <Label>Contact Details For Cargo Release</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="cargoAddress"
                                                                    invalid={errors.cargoAddress && touched.cargoAddress}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.cargoAddress}
                                                                    placeholder="Enter Address" />
                                                                <FormFeedback>
                                                                    {errors.cargoAddress && touched.cargoAddress && errors.cargoAddress}
                                                                </FormFeedback>
                                                            </div>
                                                        </div>

                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <div className='row'>
                                                                    <div className="col-lg-3 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                        <Input
                                                                            type="text"
                                                                            name="cargoZipcode"
                                                                            invalid={errors.cargoZipcode && touched.cargoZipcode}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.cargoZipcode}
                                                                            placeholder="Enter Zipcode" />
                                                                        <FormFeedback>
                                                                            {errors.cargoZipcode && touched.cargoZipcode && errors.cargoZipcode}
                                                                        </FormFeedback>
                                                                    </div>
                                                                    <div className="col-lg-3 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                        <Input
                                                                            type="text"
                                                                            name="cargoCity"
                                                                            invalid={errors.cargoCity && touched.cargoCity}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.cargoCity}
                                                                            placeholder="Enter City" />
                                                                        <FormFeedback>
                                                                            {errors.cargoCity && touched.cargoCity && errors.cargoCity}
                                                                        </FormFeedback>
                                                                    </div>
                                                                    <div className="col-lg-3 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                        <Input
                                                                            type="text"
                                                                            name="cargoState"
                                                                            invalid={errors.cargoState && touched.cargoState}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.cargoState}
                                                                            placeholder="Enter State" />
                                                                        <FormFeedback>
                                                                            {errors.cargoState && touched.cargoState && errors.cargoState}
                                                                        </FormFeedback>
                                                                    </div>
                                                                    <div className="col-lg-3 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                        <Input
                                                                            type="text"
                                                                            name="cargoCountry"
                                                                            invalid={errors.cargoCountry && touched.cargoCountry}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.cargoCountry}
                                                                            placeholder="Enter Country" />
                                                                        <FormFeedback>
                                                                            {errors.cargoCountry && touched.cargoCountry && errors.cargoCountry}
                                                                        </FormFeedback>
                                                                    </div>
                                                                </div>
                                                            </FormGroup>
                                                        </div>

                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <div className='row'>
                                                                    <div className="col-lg-4 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                        <Input
                                                                            type="text"
                                                                            name="cargoPhone"
                                                                            invalid={errors.cargoPhone && touched.cargoPhone}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.cargoPhone}
                                                                            placeholder="Enter Phone" />
                                                                        <FormFeedback>
                                                                            {errors.cargoPhone && touched.cargoPhone && errors.cargoPhone}
                                                                        </FormFeedback>
                                                                    </div>

                                                                    <div className="col-lg-4 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                        <Input
                                                                            type="text"
                                                                            name="cargoFax"
                                                                            invalid={errors.cargoFax && touched.cargoFax}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.cargoFax}
                                                                            placeholder="Enter Fax" />
                                                                        <FormFeedback>
                                                                            {errors.cargoFax && touched.cargoFax && errors.cargoFax}
                                                                        </FormFeedback>
                                                                    </div>
                                                                </div>
                                                            </FormGroup>
                                                        </div>

                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <Label>Notify Party</Label>
                                                                <div className='row'>

                                                                    <div className="col-lg-6 col-xs-12 col-sm-12 mb-3 mb-sm-3">

                                                                        <Input
                                                                            type="text"
                                                                            name="notifyCompany"
                                                                            invalid={errors.notifyCompany && touched.notifyCompany}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.notifyCompany}
                                                                            placeholder="Company Title" />
                                                                        <FormFeedback>
                                                                            {errors.notifyCompany && touched.notifyCompany && errors.notifyCompany}
                                                                        </FormFeedback>
                                                                    </div>
                                                                    <div className="col-lg-6 col-xs-12 col-sm-12 mb-3 mb-sm-3 ">

                                                                        <Input
                                                                            type="text"
                                                                            name="notifyName"
                                                                            invalid={errors.notifyName && touched.notifyName}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.notifyName}
                                                                            placeholder="Person's Name" />
                                                                        <FormFeedback>
                                                                            {errors.notifyName && touched.notifyName && errors.notifyName}
                                                                        </FormFeedback>
                                                                    </div>

                                                                </div>
                                                            </FormGroup>
                                                        </div>

                                                        <div className='row'>
                                                            <div className="col-lg-12 col-xs-12 col-sm-12 mb-3 mb-sm-3">

                                                                <Input
                                                                    type="text"
                                                                    name="notifyAddress"
                                                                    invalid={errors.notifyAddress && touched.notifyAddress}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.notifyAddress}
                                                                    placeholder="Address" />
                                                                <FormFeedback>
                                                                    {errors.notifyAddress && touched.notifyAddress && errors.notifyAddress}
                                                                </FormFeedback>
                                                            </div>
                                                        </div>

                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <div className='row'>
                                                                    <div className="col-lg-4 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                        <Input
                                                                            type="text"
                                                                            name="notifyPhone"
                                                                            invalid={errors.notifyPhone && touched.notifyPhone}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.notifyPhone}
                                                                            placeholder="Enter Phone" />
                                                                        <FormFeedback>
                                                                            {errors.notifyPhone && touched.notifyPhone && errors.notifyPhone}
                                                                        </FormFeedback>
                                                                    </div>
                                                                    <div className="col-lg-4 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                        <Input
                                                                            type="email"
                                                                            name="notifyEmail"
                                                                            invalid={errors.notifyEmail && touched.notifyEmail}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.notifyEmail}
                                                                            placeholder="Enter Email" />
                                                                        <FormFeedback>
                                                                            {errors.notifyEmail && touched.notifyEmail && errors.notifyEmail}
                                                                        </FormFeedback>
                                                                    </div>
                                                                    <div className="col-lg-4 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                        <Input
                                                                            type="text"
                                                                            name="notifyZipcode"
                                                                            invalid={errors.notifyZipcode && touched.notifyZipcode}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.notifyZipcode}
                                                                            placeholder="Enter Zipcode" />
                                                                        <FormFeedback>
                                                                            {errors.notifyZipcode && touched.notifyZipcode && errors.notifyZipcode}
                                                                        </FormFeedback>
                                                                    </div>
                                                                </div>
                                                            </FormGroup>
                                                        </div>


                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <Label>Delivery Details</Label>
                                                                <div className='row'>
                                                                    <div className="col-lg-6 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                        <Input
                                                                            type="text"
                                                                            name="deliverCompany"
                                                                            invalid={errors.deliverCompany && touched.deliverCompany}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.deliverCompany}
                                                                            placeholder="Company Title" />
                                                                        <FormFeedback>
                                                                            {errors.deliverCompany && touched.deliverCompany && errors.deliverCompany}
                                                                        </FormFeedback>
                                                                    </div>
                                                                    <div className="col-lg-6 col-xs-12 col-sm-12 mb-3 mb-sm-3 ">
                                                                        <Input
                                                                            type="text"
                                                                            name="deliverName"
                                                                            invalid={errors.deliverName && touched.deliverName}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.deliverName}
                                                                            placeholder="Person's Name" />
                                                                        <FormFeedback>
                                                                            {errors.deliverName && touched.deliverName && errors.deliverName}
                                                                        </FormFeedback>
                                                                    </div>
                                                                </div>
                                                            </FormGroup>
                                                        </div>

                                                        <div className='row'>
                                                            <div className="col-lg-12 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                <Input
                                                                    type="text"
                                                                    name="deliverAddress"
                                                                    invalid={errors.deliverAddress && touched.deliverAddress}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.deliverAddress}
                                                                    placeholder="Address" />
                                                                <FormFeedback>
                                                                    {errors.deliverAddress && touched.deliverAddress && errors.deliverAddress}
                                                                </FormFeedback>
                                                            </div>
                                                        </div>

                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <div className='row'>
                                                                    <div className="col-lg-4 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                        <Input
                                                                            type="text"
                                                                            name="deliverPhone"
                                                                            invalid={errors.deliverPhone && touched.deliverPhone}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.deliverPhone}
                                                                            placeholder="Enter Phone" />
                                                                        <FormFeedback>
                                                                            {errors.deliverPhone && touched.deliverPhone && errors.deliverPhone}
                                                                        </FormFeedback>
                                                                    </div>
                                                                    <div className="col-lg-4 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                        <Input
                                                                            type="email"
                                                                            name="deliverEmail"
                                                                            invalid={errors.deliverEmail && touched.deliverEmail}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.deliverEmail}
                                                                            placeholder="Enter Email" />
                                                                        <FormFeedback>
                                                                            {errors.deliverEmail && touched.deliverEmail && errors.deliverEmail}
                                                                        </FormFeedback>
                                                                    </div>
                                                                    <div className="col-lg-4 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                        <Input
                                                                            type="text"
                                                                            name="deliverZipcode"
                                                                            invalid={errors.deliverZipcode && touched.deliverZipcode}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.deliverZipcode}
                                                                            placeholder="Enter Zipcode" />
                                                                        <FormFeedback>
                                                                            {errors.deliverZipcode && touched.deliverZipcode && errors.deliverZipcode}
                                                                        </FormFeedback>
                                                                    </div>
                                                                </div>
                                                            </FormGroup>
                                                        </div>

                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <div className='row'>

                                                                    <div className="col-lg-6 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                        <Label>Shipment Origin</Label>
                                                                        <Input
                                                                            type="text"
                                                                            name="portOfReceipt"
                                                                            invalid={errors.portOfReceipt && touched.portOfReceipt}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.portOfReceipt}
                                                                            placeholder="Shipment Origin" />
                                                                        <FormFeedback>
                                                                            {errors.portOfReceipt && touched.portOfReceipt && errors.portOfReceipt}
                                                                        </FormFeedback>
                                                                    </div>

                                                                    <div className="col-lg-6 col-xs-12 col-sm-12 mb-3 mb-sm-3 ">

                                                                        <Label>{
                                                                            `Shipment ${portType} of Loading`}</Label>
                                                                        <Input
                                                                            type="text"
                                                                            name="portOfLoading"
                                                                            invalid={errors.portOfLoading && touched.portOfLoading}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.portOfLoading}
                                                                            placeholder={`Shipment ${portType} of Loading`} />
                                                                        <FormFeedback>
                                                                            {errors.portOfLoading && touched.portOfLoading && errors.portOfLoading}
                                                                        </FormFeedback>
                                                                    </div>
                                                                </div>
                                                            </FormGroup>
                                                        </div>

                                                        {(this.state.shipmentData.modeOfTransport === "Air" || this.state.shipmentData.modeOfTransport === "Sea") &&
                                                            <div div className='col-12'>
                                                                <FormGroup>
                                                                    <div className='row'>

                                                                        <div className="col-lg-6 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                            <Label>{this.state.shipmentData.modeOfTransport === "Air" ? "Airline" : "Vessel"}</Label>
                                                                            <Input
                                                                                type="text"
                                                                                name="vessel"
                                                                                invalid={errors.vessel && touched.vessel}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.vessel}
                                                                                placeholder={this.state.shipmentData.modeOfTransport === "Air" ? "Airline" : "Vessel"} />
                                                                            <FormFeedback>
                                                                                {errors.vessel && touched.vessel && errors.vessel}
                                                                            </FormFeedback>
                                                                        </div>
                                                                        <div className="col-lg-6 col-xs-12 col-sm-12 mb-3 mb-sm-3 ">
                                                                            <Label>{this.state.shipmentData.modeOfTransport === "Air" ? "Flight Number." : "Voyage No."}</Label>
                                                                            <Input
                                                                                type="text"
                                                                                name="voyageNo"
                                                                                invalid={errors.voyageNo && touched.voyageNo}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.voyageNo}
                                                                                placeholder={this.state.shipmentData.modeOfTransport === "Air" ? "Flight Number." : "Voyage No."} />
                                                                            <FormFeedback>
                                                                                {errors.voyageNo && touched.voyageNo && errors.voyageNo}
                                                                            </FormFeedback>
                                                                        </div>
                                                                    </div>
                                                                </FormGroup>
                                                            </div>}

                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <div className='row'>

                                                                    <div className="col-lg-6 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                        <Label>{`${portType} Of Discharge`}</Label>
                                                                        <Input
                                                                            type="text"
                                                                            name="portOfDischarge"
                                                                            invalid={errors.portOfDischarge && touched.portOfDischarge}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.portOfDischarge}
                                                                            placeholder={`${portType} Of Discharge`} />
                                                                        <FormFeedback>
                                                                            {errors.portOfDischarge && touched.portOfDischarge && errors.portOfDischarge}
                                                                        </FormFeedback>
                                                                    </div>
                                                                    <div className="col-lg-6 col-xs-12 col-sm-12 mb-3 mb-sm-3 ">
                                                                        <Label>Place Of Delivery</Label>
                                                                        <Input
                                                                            type="text"
                                                                            name="placeOfDelivery"
                                                                            invalid={errors.placeOfDelivery && touched.placeOfDelivery}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.placeOfDelivery}
                                                                            placeholder="Place Of Delivery" />
                                                                        <FormFeedback>
                                                                            {errors.placeOfDelivery && touched.placeOfDelivery && errors.placeOfDelivery}
                                                                        </FormFeedback>
                                                                    </div>
                                                                </div>
                                                            </FormGroup>
                                                        </div>

                                                        <div className='row'>
                                                            <div className="col-lg-12 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                <Label>Comments</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="comments"
                                                                    invalid={errors.comments && touched.comments}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.comments}
                                                                    placeholder="comments" />
                                                                <FormFeedback>
                                                                    {errors.comments && touched.comments && errors.comments}
                                                                </FormFeedback>
                                                            </div>
                                                        </div>

                                                        {this.state.shipmentData.modeOfTransport === "Sea" && <div className='col-12'>
                                                            <FormGroup>
                                                                <div className='row'>

                                                                    <div className="col-lg-6 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                        <Label>Container No.</Label>
                                                                        <Input
                                                                            type="text"
                                                                            name="containerNo"
                                                                            invalid={errors.containerNo && touched.containerNo}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.containerNo}
                                                                            placeholder="Container No." />
                                                                        <FormFeedback>
                                                                            {errors.containerNo && touched.containerNo && errors.containerNo}
                                                                        </FormFeedback>
                                                                    </div>
                                                                    <div className="col-lg-6 col-xs-12 col-sm-12 mb-3 mb-sm-3 ">
                                                                        <Label>Seal No.</Label>
                                                                        <Input
                                                                            type="text"
                                                                            name="sealNo"
                                                                            invalid={errors.sealNo && touched.sealNo}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.sealNo}
                                                                            placeholder="Seal No." />
                                                                        <FormFeedback>
                                                                            {errors.sealNo && touched.sealNo && errors.sealNo}
                                                                        </FormFeedback>
                                                                    </div>
                                                                </div>
                                                            </FormGroup>
                                                        </div>}

                                                        <div className='row'>
                                                            <div className="col-lg-12 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                <Label>Marks and Numbers</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="marksAndNumbers"
                                                                    invalid={errors.marksAndNumbers && touched.marksAndNumbers}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.marksAndNumbers}
                                                                    placeholder="Marks and Numbers" />
                                                                <FormFeedback>
                                                                    {errors.marksAndNumbers && touched.marksAndNumbers && errors.marksAndNumbers}
                                                                </FormFeedback>
                                                            </div>
                                                        </div>

                                                        <div className='row'>
                                                            <div className="col-lg-12 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                <Label>Description of packages and goods</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="description"
                                                                    invalid={errors.description && touched.description}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.description}
                                                                    placeholder="Description" />
                                                                <FormFeedback>
                                                                    {errors.description && touched.description && errors.description}
                                                                </FormFeedback>
                                                            </div>
                                                        </div>

                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <div className='row'>

                                                                    <div className="col-lg-6 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                        <Label>Gross Weight</Label>
                                                                        <Input
                                                                            type="text"
                                                                            name="grossWeight"
                                                                            invalid={errors.grossWeight && touched.grossWeight}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.grossWeight}
                                                                            placeholder="Gross Weight" />
                                                                        <FormFeedback>
                                                                            {errors.grossWeight && touched.grossWeight && errors.grossWeight}
                                                                        </FormFeedback>
                                                                    </div>
                                                                    <div className="col-lg-6 col-xs-12 col-sm-12 mb-3 mb-sm-3 ">
                                                                        <Label>Enter CBM or <span title='CBM = [length (cm) x width (cm) x height (cm)] ÷ 1,000,000 ( x number of boxes with same dimensions)' className='text-primary' onClick={() => {
                                                                            const length = Number(this.state.shipmentData.length);
                                                                            const width = Number(this.state.shipmentData.width);
                                                                            const height = Number(this.state.shipmentData.height);
                                                                            const quantity = Number(this.state.shipmentData.quantity);
                                                                            const totalCBM = calculateCBM(length, width, height, quantity);

                                                                            setFieldValue("cbm", totalCBM);
                                                                        }}>Calculate</span> </Label>
                                                                        <Input
                                                                            type="text"
                                                                            name="cbm"
                                                                            invalid={errors.cbm && touched.cbm}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.cbm}
                                                                            placeholder="Enter CBM" />
                                                                        <FormFeedback>
                                                                            {errors.cbm && touched.cbm && errors.cbm}
                                                                        </FormFeedback>
                                                                    </div>
                                                                </div>
                                                            </FormGroup>
                                                        </div>

                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <Label>Shipped On Board</Label>
                                                                <div className='row'>
                                                                    <div className="col-lg-4 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                        <Input
                                                                            type="text"
                                                                            name="shippedOnBoardTitle"
                                                                            invalid={errors.shippedOnBoardTitle && touched.shippedOnBoardTitle}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.shippedOnBoardTitle}
                                                                            placeholder="Title" />
                                                                        <FormFeedback>
                                                                            {errors.shippedOnBoardTitle && touched.shippedOnBoardTitle && errors.shippedOnBoardTitle}
                                                                        </FormFeedback>
                                                                    </div>
                                                                    <div className="col-lg-4 col-xs-12 col-sm-12 mb-3 mb-sm-3 ">

                                                                        <FormGroup>

                                                                            <DatePicker
                                                                                name="shippedOnBoardDate"
                                                                                placeholderText="Select Date"
                                                                                className="is-invalid"
                                                                                // minDate={moment().toDate()}
                                                                                selected={values.shippedOnBoardDate ? new Date(values.shippedOnBoardDate) : ''}
                                                                                onChange={val => {
                                                                                    setFieldValue('shippedOnBoardDate', this.formatDate(val));
                                                                                }}
                                                                            />
                                                                            <Input type="hidden" name="shippedOnBoardDate" placeholder="Select Date" value={values.shippedOnBoardDate} className="is-invalid" />
                                                                            <FormFeedback>
                                                                                {errors.shippedOnBoardDate && touched.shippedOnBoardDate && errors.shippedOnBoardDate}
                                                                            </FormFeedback>
                                                                        </FormGroup>

                                                                    </div>


                                                                </div>
                                                            </FormGroup>
                                                        </div>

                                                        <div className='row'>
                                                            <div className="col-lg-12 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                <Label>Freight and Charges</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="freightAndCharges"
                                                                    invalid={errors.freightAndCharges && touched.freightAndCharges}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.freightAndCharges}
                                                                    placeholder="Freight and Charges" />
                                                                <FormFeedback>
                                                                    {errors.freightAndCharges && touched.freightAndCharges && errors.freightAndCharges}
                                                                </FormFeedback>
                                                            </div>
                                                        </div>

                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <div className='row'>

                                                                    <div className="col-lg-6 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                        <Label>Prepaid</Label>
                                                                        <Input
                                                                            type="text"
                                                                            name="prepaid"
                                                                            invalid={errors.prepaid && touched.prepaid}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.prepaid}
                                                                            placeholder="Prepaid" />
                                                                        <FormFeedback>
                                                                            {errors.prepaid && touched.prepaid && errors.prepaid}
                                                                        </FormFeedback>
                                                                    </div>
                                                                    <div className="col-lg-6 col-xs-12 col-sm-12 mb-3 mb-sm-3 ">
                                                                        <Label>Collect</Label>
                                                                        <Input
                                                                            type="text"
                                                                            name="collect"
                                                                            invalid={errors.collect && touched.collect}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.collect}
                                                                            placeholder="Collect" />
                                                                        <FormFeedback>
                                                                            {errors.collect && touched.collect && errors.collect}
                                                                        </FormFeedback>
                                                                    </div>
                                                                </div>
                                                            </FormGroup>
                                                        </div>

                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <div className='row'>

                                                                    <div className="col-lg-6 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                                                        <Label>Place of issue</Label>
                                                                        <Input
                                                                            type="text"
                                                                            name="placeOfIssue"
                                                                            invalid={errors.placeOfIssue && touched.placeOfIssue}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.placeOfIssue}
                                                                            placeholder="Enter Place" />
                                                                        <FormFeedback>
                                                                            {errors.placeOfIssue && touched.placeOfIssue && errors.placeOfIssue}
                                                                        </FormFeedback>
                                                                    </div>
                                                                    <div className="col-lg-6 col-xs-12 col-sm-12 mb-3 mb-sm-3 ">

                                                                        <FormGroup>
                                                                            <Label>Date of issue</Label>
                                                                            <DatePicker
                                                                                name="dateOfIssue"
                                                                                placeholderText="Select Date of issue"
                                                                                className="is-invalid"

                                                                                selected={(values.dateOfIssue && new Date(values.dateOfIssue)) || ''}

                                                                                onChange={val => {
                                                                                    setFieldValue('dateOfIssue', this.formatDate(val));
                                                                                }}
                                                                            />
                                                                            <Input type="hidden" name="dateOfIssue" placeholder="Date" value={values.dateOfIssue} className="is-invalid" />
                                                                            <FormFeedback>
                                                                                {errors.dateOfIssue && touched.dateOfIssue && errors.dateOfIssue}
                                                                            </FormFeedback>
                                                                        </FormGroup>
                                                                    </div>
                                                                </div>
                                                            </FormGroup>
                                                        </div>

                                                    </div>

                                                </>}



                                                {this.state.showContent && <div className="d-flex justify-content-center align-items-center">
                                                    <button className="btn btn-submit" onClick={handleSubmit}>
                                                        {this.state.shipmentData?.ref_no ? "Edit Bill" : "Generate Bill"}
                                                    </button>

                                                    {this.state.shipmentData?.ref_no && <div className='m-1'> <PrintShippingBill error={errors} shipmentData={this.state.shipmentData} isOpen={true} /></div>}
                                                </div>}
                                            </>
                                        )}
                                    </Formik >
                                </>}

                        </CardBody>
                    </Card >}
            </div >
        )
    }
}
