import React, { Component } from 'react'
import { Button, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import logo from '../assets/4.png'
import { Formik } from 'formik';
import * as Yup from 'yup';
import { restPassword } from '../api/auth'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipLoader } from "react-spinners";
import './ForgotPassword.css'

const SignupSchema = Yup.object().shape({
	// password: Yup.string()
	//     .min(5, 'Too Short!')
	//     .max(10, 'Too Long!')
	//     .required('Required'),
	// email: Yup.string()
	//     .email('Invalid email')
	//     .required('Required'),
});


export default class ForgotPassword extends Component {
	state = {
		message: '',
		loading: false
	}

	componentWillUnmount() {
		clearTimeout()
	}

	submitHandler = (values) => {
		this.setState({ loading: true })
		restPassword(values)
			.then(Response => {
				this.setState({ message: Response.data.message, loading: false, email: '' })
				toast.success(Response.data.message, {
					position: toast.POSITION.TOP_RIGHT
				});
				setTimeout(() => {
					this.props.history.push({
						pathname: '/admin/index',
					})
				}, 2000)
			})
			.catch(err => {
				toast.error(err.response.data.data.email || err.response.data.message, {
					position: toast.POSITION.TOP_RIGHT
				});
				this.setState({ loading: false })
			})

	}

	render() {
		return (
			<Formik
				initialValues={{ email: '' }}
				validationSchema={SignupSchema}
				onSubmit={(values, { setErrors }) => {
					this.setErrors = setErrors;
					this.submitHandler(values);
				}}
			>{({
				values,
				errors,
				touched,
				handleChange,
				handleSubmit,
				handleBlur,
				setFieldValue,
			}) => (
				<div className=" w-100 h-100 overflow-hidden">
					<div className="row">
						<div className="col-4      d-flex justify-content-center align-items-center">
							<div className="d-none d-md-block d-lg-block d-xl-block login-bg-style">
								<div className="d-flex justify-content-center">
									<a href="/">
										<img src={logo} className="img-fluid" alt="" />
									</a>
								</div>
								<h1 className="pb-3 pt-3 text-center login-title">Postkodes</h1>
								<p className="text-justify login-description">Revolutionary parcel and product tracking platform using
									the latest IoT technology. Giving your business and your customers improved efficiency and
									productivity at significantly reduced cost when compared to your current system.</p>

							</div>
						</div>
						<div
							className="col-12 col-md-4 col-lg-4 col-xl-4 col-offset-none offset-sm-none offset-md-2 offset-lg-2 offset-xl-2 text-area-size  ">
							<div className="container">
								<div className="d-flex justify-content-center d-block  d-md-none d-lg-none d-xl-none pb-3">
									<img src={logo} alt="" />
								</div>
								<div className="d-none d-md-block d-lg-block d-xl-block">
									<h1 className="title-style pb-2">Reset your password.</h1>
									<p className="text-center " style={{ color: "#ababab" }}>Enter your email click submit then check your
										email and follow
										the instruction to reset password.</p>

								</div>
								<div className="d-block d-sm-block d-md-none d-lg-none d-xl-none">
									<h1 className="title-style pb-0 text-left">Reset your password.</h1>
									<p className="pb-2 description-text">Enter your email click submit then check your email and follow
										the instruction to reset password.</p>
								</div>
								<Form>
									<FormGroup className="pb-2 has-feedback has-feedback-left ">
										<Label for="exampleEmail">Email</Label>
										<div class="input-group mb-3">
											<div class="input-group-prepend">
												<span class="input-group-text" id="basic-addon1"><i class="fa fa-envelope icon-style"
													aria-hidden="true"></i></span>
											</div>
											{/* <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" /> */}
											<Input type="email"
												invalid={errors.email && touched.email}
												name="email" onChange={handleChange} onBlur={handleBlur} value={values.email}
												placeholder="Enter your email address" />

											<FormFeedback>
												{errors.email && touched.email && errors.email}
											</FormFeedback>
										</div>
									</FormGroup>
									<div className="pt-3">
										<Button className="btn btn-login btn-block" onClick={handleSubmit}>
											{this.state.loading ?
												< ClipLoader
													size={15}
													color={"#123abc"}
													loading={true}
												/> : 'SUBMIT'
											}
										</Button>
									</div>
								</Form>
							</div>
						</div>
						<div className="col-offset-none offset-sm-none offset-md-2 offset-lg-2 offset-xl-2"></div>
					</div>
				</div>
			)}
			</Formik>

		)
	}
}
