export const cityList = [
    {
        "label": "UTK",
        "value": "UTK",
        "iso": "MH"
    },
    {
        "label": "FIV",
        "value": "FIV",
        "iso": "US"
    },
    {
        "label": "FAK",
        "value": "FAK",
        "iso": "US"
    },
    {
        "label": "BWS",
        "value": "BWS",
        "iso": "US"
    },
    {
        "label": "WKK",
        "value": "WKK",
        "iso": "US"
    },
    {
        "label": "TSS",
        "value": "TSS",
        "iso": "US"
    },
    {
        "label": "FOB",
        "value": "FOB",
        "iso": "US"
    },
    {
        "label": "ABP",
        "value": "ABP",
        "iso": "PG"
    },
    {
        "label": "ALV",
        "value": "ALV",
        "iso": "AD"
    },
    {
        "label": "ADC",
        "value": "ADC",
        "iso": "PG"
    },
    {
        "label": "TJP",
        "value": "TJP",
        "iso": "PR"
    },
    {
        "label": "AEE",
        "value": "AEE",
        "iso": "SS"
    },
    {
        "label": "AEI",
        "value": "AEI",
        "iso": "ES"
    },
    {
        "label": "AEK",
        "value": "AEK",
        "iso": "PG"
    },
    {
        "label": "OLR",
        "value": "OLR",
        "iso": "AF"
    },
    {
        "label": "AFR",
        "value": "AFR",
        "iso": "PG"
    },
    {
        "label": "AFT",
        "value": "AFT",
        "iso": "SB"
    },
    {
        "label": "ATD",
        "value": "ATD",
        "iso": "SB"
    },
    {
        "label": "VEV",
        "value": "VEV",
        "iso": "SB"
    },
    {
        "label": "GEF",
        "value": "GEF",
        "iso": "SB"
    },
    {
        "label": "AGG",
        "value": "AGG",
        "iso": "PG"
    },
    {
        "label": "AKS",
        "value": "AKS",
        "iso": "SB"
    },
    {
        "label": "BAS",
        "value": "BAS",
        "iso": "SB"
    },
    {
        "label": "FRE",
        "value": "FRE",
        "iso": "SB"
    },
    {
        "label": "HIR",
        "value": "HIR",
        "iso": "SB"
    },
    {
        "label": "MBU",
        "value": "MBU",
        "iso": "SB"
    },
    {
        "label": "IRA",
        "value": "IRA",
        "iso": "SB"
    },
    {
        "label": "SCZ",
        "value": "SCZ",
        "iso": "SB"
    },
    {
        "label": "MUA",
        "value": "MUA",
        "iso": "SB"
    },
    {
        "label": "GZO",
        "value": "GZO",
        "iso": "SB"
    },
    {
        "label": "MNY",
        "value": "MNY",
        "iso": "SB"
    },
    {
        "label": "PRS",
        "value": "PRS",
        "iso": "SB"
    },
    {
        "label": "OTV",
        "value": "OTV",
        "iso": "SB"
    },
    {
        "label": "RNL",
        "value": "RNL",
        "iso": "SB"
    },
    {
        "label": "EGM",
        "value": "EGM",
        "iso": "SB"
    },
    {
        "label": "RUS",
        "value": "RUS",
        "iso": "SB"
    },
    {
        "label": "VAO",
        "value": "VAO",
        "iso": "SB"
    },
    {
        "label": "AGK",
        "value": "AGK",
        "iso": "PG"
    },
    {
        "label": "KGE",
        "value": "KGE",
        "iso": "SB"
    },
    {
        "label": "AGL",
        "value": "AGL",
        "iso": "PG"
    },
    {
        "label": "RIN",
        "value": "RIN",
        "iso": "SB"
    },
    {
        "label": "RBV",
        "value": "RBV",
        "iso": "SB"
    },
    {
        "label": "AHT",
        "value": "AHT",
        "iso": "US"
    },
    {
        "label": "AHY",
        "value": "AHY",
        "iso": "MG"
    },
    {
        "label": "AIE",
        "value": "AIE",
        "iso": "PG"
    },
    {
        "label": "AIH",
        "value": "AIH",
        "iso": "PG"
    },
    {
        "label": "AIP",
        "value": "AIP",
        "iso": "MH"
    },
    {
        "label": "AOS",
        "value": "AOS",
        "iso": "US"
    },
    {
        "label": "AKM",
        "value": "AKM",
        "iso": "TD"
    },
    {
        "label": "ALZ",
        "value": "ALZ",
        "iso": "US"
    },
    {
        "label": "AMC",
        "value": "AMC",
        "iso": "MX"
    },
    {
        "label": "AME",
        "value": "AME",
        "iso": "MZ"
    },
    {
        "label": "AMF",
        "value": "AMF",
        "iso": "PG"
    },
    {
        "label": "AMU",
        "value": "AMU",
        "iso": "PG"
    },
    {
        "label": "AMY",
        "value": "AMY",
        "iso": "MG"
    },
    {
        "label": "ANH",
        "value": "ANH",
        "iso": "SB"
    },
    {
        "label": "INU",
        "value": "INU",
        "iso": "NR"
    },
    {
        "label": "ANL",
        "value": "ANL",
        "iso": "AO"
    },
    {
        "label": "CNZ",
        "value": "CNZ",
        "iso": "AO"
    },
    {
        "label": "DRC",
        "value": "DRC",
        "iso": "AO"
    },
    {
        "label": "GGC",
        "value": "GGC",
        "iso": "AO"
    },
    {
        "label": "JMB",
        "value": "JMB",
        "iso": "AO"
    },
    {
        "label": "KNP",
        "value": "KNP",
        "iso": "AO"
    },
    {
        "label": "NDF",
        "value": "NDF",
        "iso": "AO"
    },
    {
        "label": "AOB",
        "value": "AOB",
        "iso": "PG"
    },
    {
        "label": "APP",
        "value": "APP",
        "iso": "PG"
    },
    {
        "label": "APR",
        "value": "APR",
        "iso": "PG"
    },
    {
        "label": "AQY",
        "value": "AQY",
        "iso": "US"
    },
    {
        "label": "QRF",
        "value": "QRF",
        "iso": "AR"
    },
    {
        "label": "CSZ",
        "value": "CSZ",
        "iso": "AR"
    },
    {
        "label": "CVI",
        "value": "CVI",
        "iso": "AR"
    },
    {
        "label": "CNT",
        "value": "CNT",
        "iso": "AR"
    },
    {
        "label": "VGS",
        "value": "VGS",
        "iso": "AR"
    },
    {
        "label": "LMD",
        "value": "LMD",
        "iso": "AR"
    },
    {
        "label": "SZQ",
        "value": "SZQ",
        "iso": "AR"
    },
    {
        "label": "VCF",
        "value": "VCF",
        "iso": "AR"
    },
    {
        "label": "VME",
        "value": "VME",
        "iso": "AR"
    },
    {
        "label": "NCJ",
        "value": "NCJ",
        "iso": "AR"
    },
    {
        "label": "CPG",
        "value": "CPG",
        "iso": "AR"
    },
    {
        "label": "PRQ",
        "value": "PRQ",
        "iso": "AR"
    },
    {
        "label": "ARP",
        "value": "ARP",
        "iso": "PG"
    },
    {
        "label": "TAV",
        "value": "TAV",
        "iso": "AS"
    },
    {
        "label": "ASZ",
        "value": "ASZ",
        "iso": "PG"
    },
    {
        "label": "ATP",
        "value": "ATP",
        "iso": "PG"
    },
    {
        "label": "AGW",
        "value": "AGW",
        "iso": "AU"
    },
    {
        "label": "AYD",
        "value": "AYD",
        "iso": "AU"
    },
    {
        "label": "BCK",
        "value": "BCK",
        "iso": "AU"
    },
    {
        "label": "BFC",
        "value": "BFC",
        "iso": "AU"
    },
    {
        "label": "BVW",
        "value": "BVW",
        "iso": "AU"
    },
    {
        "label": "BYX",
        "value": "BYX",
        "iso": "AU"
    },
    {
        "label": "COB",
        "value": "COB",
        "iso": "AU"
    },
    {
        "label": "CQP",
        "value": "CQP",
        "iso": "AU"
    },
    {
        "label": "CRJ",
        "value": "CRJ",
        "iso": "AU"
    },
    {
        "label": "CRY",
        "value": "CRY",
        "iso": "AU"
    },
    {
        "label": "CSD",
        "value": "CSD",
        "iso": "AU"
    },
    {
        "label": "CTR",
        "value": "CTR",
        "iso": "AU"
    },
    {
        "label": "DYM",
        "value": "DYM",
        "iso": "AU"
    },
    {
        "label": "EDD",
        "value": "EDD",
        "iso": "AU"
    },
    {
        "label": "EKD",
        "value": "EKD",
        "iso": "AU"
    },
    {
        "label": "HAT",
        "value": "HAT",
        "iso": "AU"
    },
    {
        "label": "HIG",
        "value": "HIG",
        "iso": "AU"
    },
    {
        "label": "HIS",
        "value": "HIS",
        "iso": "AU"
    },
    {
        "label": "HLV",
        "value": "HLV",
        "iso": "AU"
    },
    {
        "label": "KBD",
        "value": "KBD",
        "iso": "AU"
    },
    {
        "label": "KGR",
        "value": "KGR",
        "iso": "AU"
    },
    {
        "label": "KOH",
        "value": "KOH",
        "iso": "AU"
    },
    {
        "label": "KYF",
        "value": "KYF",
        "iso": "AU"
    },
    {
        "label": "LIB",
        "value": "LIB",
        "iso": "AU"
    },
    {
        "label": "MNW",
        "value": "MNW",
        "iso": "AU"
    },
    {
        "label": "MUP",
        "value": "MUP",
        "iso": "AU"
    },
    {
        "label": "MWY",
        "value": "MWY",
        "iso": "AU"
    },
    {
        "label": "MYO",
        "value": "MYO",
        "iso": "AU"
    },
    {
        "label": "NKB",
        "value": "NKB",
        "iso": "AU"
    },
    {
        "label": "OKB",
        "value": "OKB",
        "iso": "AU"
    },
    {
        "label": "PEP",
        "value": "PEP",
        "iso": "AU"
    },
    {
        "label": "RDA",
        "value": "RDA",
        "iso": "AU"
    },
    {
        "label": "SSK",
        "value": "SSK",
        "iso": "AU"
    },
    {
        "label": "SWB",
        "value": "SWB",
        "iso": "AU"
    },
    {
        "label": "TPR",
        "value": "TPR",
        "iso": "AU"
    },
    {
        "label": "TWP",
        "value": "TWP",
        "iso": "AU"
    },
    {
        "label": "WRW",
        "value": "WRW",
        "iso": "AU"
    },
    {
        "label": "WSY",
        "value": "WSY",
        "iso": "AU"
    },
    {
        "label": "ZVG",
        "value": "ZVG",
        "iso": "AU"
    },
    {
        "label": "AUI",
        "value": "AUI",
        "iso": "PG"
    },
    {
        "label": "AUJ",
        "value": "AUJ",
        "iso": "PG"
    },
    {
        "label": "AUP",
        "value": "AUP",
        "iso": "PG"
    },
    {
        "label": "AUV",
        "value": "AUV",
        "iso": "PG"
    },
    {
        "label": "BUA",
        "value": "BUA",
        "iso": "PG"
    },
    {
        "label": "CMU",
        "value": "CMU",
        "iso": "PG"
    },
    {
        "label": "DAU",
        "value": "DAU",
        "iso": "PG"
    },
    {
        "label": "GKA",
        "value": "GKA",
        "iso": "PG"
    },
    {
        "label": "GUR",
        "value": "GUR",
        "iso": "PG"
    },
    {
        "label": "PNP",
        "value": "PNP",
        "iso": "PG"
    },
    {
        "label": "HKN",
        "value": "HKN",
        "iso": "PG"
    },
    {
        "label": "LSA",
        "value": "LSA",
        "iso": "PG"
    },
    {
        "label": "UNG",
        "value": "UNG",
        "iso": "PG"
    },
    {
        "label": "KRI",
        "value": "KRI",
        "iso": "PG"
    },
    {
        "label": "KMA",
        "value": "KMA",
        "iso": "PG"
    },
    {
        "label": "KVG",
        "value": "KVG",
        "iso": "PG"
    },
    {
        "label": "LNV",
        "value": "LNV",
        "iso": "PG"
    },
    {
        "label": "MAG",
        "value": "MAG",
        "iso": "PG"
    },
    {
        "label": "HGU",
        "value": "HGU",
        "iso": "PG"
    },
    {
        "label": "MDU",
        "value": "MDU",
        "iso": "PG"
    },
    {
        "label": "MAS",
        "value": "MAS",
        "iso": "PG"
    },
    {
        "label": "MXH",
        "value": "MXH",
        "iso": "PG"
    },
    {
        "label": "MIS",
        "value": "MIS",
        "iso": "PG"
    },
    {
        "label": "LAE",
        "value": "LAE",
        "iso": "PG"
    },
    {
        "label": "POM",
        "value": "POM",
        "iso": "PG"
    },
    {
        "label": "TIZ",
        "value": "TIZ",
        "iso": "PG"
    },
    {
        "label": "TBG",
        "value": "TBG",
        "iso": "PG"
    },
    {
        "label": "RAB",
        "value": "RAB",
        "iso": "PG"
    },
    {
        "label": "AYU",
        "value": "AYU",
        "iso": "PG"
    },
    {
        "label": "VAI",
        "value": "VAI",
        "iso": "PG"
    },
    {
        "label": "WBM",
        "value": "WBM",
        "iso": "PG"
    },
    {
        "label": "WWK",
        "value": "WWK",
        "iso": "PG"
    },
    {
        "label": "AYY",
        "value": "AYY",
        "iso": "LK"
    },
    {
        "label": "AYZ",
        "value": "AYZ",
        "iso": "US"
    },
    {
        "label": "AZB",
        "value": "AZB",
        "iso": "PG"
    },
    {
        "label": "BCW",
        "value": "BCW",
        "iso": "MZ"
    },
    {
        "label": "HKV",
        "value": "HKV",
        "iso": "BG"
    },
    {
        "label": "JAM",
        "value": "JAM",
        "iso": "BG"
    },
    {
        "label": "SZR",
        "value": "SZR",
        "iso": "BG"
    },
    {
        "label": "JEG",
        "value": "JEG",
        "iso": "GL"
    },
    {
        "label": "AAP",
        "value": "AAP",
        "iso": "GL"
    },
    {
        "label": "AGM",
        "value": "AGM",
        "iso": "GL"
    },
    {
        "label": "LLU",
        "value": "LLU",
        "iso": "GL"
    },
    {
        "label": "QUV",
        "value": "QUV",
        "iso": "GL"
    },
    {
        "label": "QUW",
        "value": "QUW",
        "iso": "GL"
    },
    {
        "label": "QGQ",
        "value": "QGQ",
        "iso": "GL"
    },
    {
        "label": "UAK",
        "value": "UAK",
        "iso": "GL"
    },
    {
        "label": "JCH",
        "value": "JCH",
        "iso": "GL"
    },
    {
        "label": "CNP",
        "value": "CNP",
        "iso": "GL"
    },
    {
        "label": "QFG",
        "value": "QFG",
        "iso": "GL"
    },
    {
        "label": "QFN",
        "value": "QFN",
        "iso": "GL"
    },
    {
        "label": "JFR",
        "value": "JFR",
        "iso": "GL"
    },
    {
        "label": "GOH",
        "value": "GOH",
        "iso": "GL"
    },
    {
        "label": "JGO",
        "value": "JGO",
        "iso": "GL"
    },
    {
        "label": "IKE",
        "value": "IKE",
        "iso": "GL"
    },
    {
        "label": "QFI",
        "value": "QFI",
        "iso": "GL"
    },
    {
        "label": "XIQ",
        "value": "XIQ",
        "iso": "GL"
    },
    {
        "label": "INN",
        "value": "INN",
        "iso": "GL"
    },
    {
        "label": "ISO",
        "value": "ISO",
        "iso": "GL"
    },
    {
        "label": "QJI",
        "value": "QJI",
        "iso": "GL"
    },
    {
        "label": "JJU",
        "value": "JJU",
        "iso": "GL"
    },
    {
        "label": "JAV",
        "value": "JAV",
        "iso": "GL"
    },
    {
        "label": "QPW",
        "value": "QPW",
        "iso": "GL"
    },
    {
        "label": "KUS",
        "value": "KUS",
        "iso": "GL"
    },
    {
        "label": "UPK",
        "value": "UPK",
        "iso": "GL"
    },
    {
        "label": "KMT",
        "value": "KMT",
        "iso": "GL"
    },
    {
        "label": "KLQ",
        "value": "KLQ",
        "iso": "GL"
    },
    {
        "label": "KAQ",
        "value": "KAQ",
        "iso": "GL"
    },
    {
        "label": "QJE",
        "value": "QJE",
        "iso": "GL"
    },
    {
        "label": "ILL",
        "value": "ILL",
        "iso": "GL"
    },
    {
        "label": "MOR",
        "value": "MOR",
        "iso": "GL"
    },
    {
        "label": "JSU",
        "value": "JSU",
        "iso": "GL"
    },
    {
        "label": "QMK",
        "value": "QMK",
        "iso": "GL"
    },
    {
        "label": "JNN",
        "value": "JNN",
        "iso": "GL"
    },
    {
        "label": "NUG",
        "value": "NUG",
        "iso": "GL"
    },
    {
        "label": "JNS",
        "value": "JNS",
        "iso": "GL"
    },
    {
        "label": "NIA",
        "value": "NIA",
        "iso": "GL"
    },
    {
        "label": "NUS",
        "value": "NUS",
        "iso": "GL"
    },
    {
        "label": "JFR",
        "value": "JFR",
        "iso": "GL"
    },
    {
        "label": "QQT",
        "value": "QQT",
        "iso": "GL"
    },
    {
        "label": "NAQ",
        "value": "NAQ",
        "iso": "GL"
    },
    {
        "label": "OBY",
        "value": "OBY",
        "iso": "GL"
    },
    {
        "label": "SFJ",
        "value": "SFJ",
        "iso": "GL"
    },
    {
        "label": "SMG",
        "value": "SMG",
        "iso": "GL"
    },
    {
        "label": "SIO",
        "value": "SIO",
        "iso": "GL"
    },
    {
        "label": "QUP",
        "value": "QUP",
        "iso": "GL"
    },
    {
        "label": "JHS",
        "value": "JHS",
        "iso": "GL"
    },
    {
        "label": "SAA",
        "value": "SAA",
        "iso": "GL"
    },
    {
        "label": "SAV",
        "value": "SAV",
        "iso": "GL"
    },
    {
        "label": "THU",
        "value": "THU",
        "iso": "GL"
    },
    {
        "label": "TNT",
        "value": "TNT",
        "iso": "GL"
    },
    {
        "label": "JUV",
        "value": "JUV",
        "iso": "GL"
    },
    {
        "label": "UMD",
        "value": "UMD",
        "iso": "GL"
    },
    {
        "label": "JQA",
        "value": "JQA",
        "iso": "GL"
    },
    {
        "label": "UKK",
        "value": "UKK",
        "iso": "GL"
    },
    {
        "label": "BHL",
        "value": "BHL",
        "iso": "MX"
    },
    {
        "label": "AEY",
        "value": "AEY",
        "iso": "IS"
    },
    {
        "label": "BIU",
        "value": "BIU",
        "iso": "IS"
    },
    {
        "label": "BGJ",
        "value": "BGJ",
        "iso": "IS"
    },
    {
        "label": "BJD",
        "value": "BJD",
        "iso": "IS"
    },
    {
        "label": "BLO",
        "value": "BLO",
        "iso": "IS"
    },
    {
        "label": "BQD",
        "value": "BQD",
        "iso": "IS"
    },
    {
        "label": "BXV",
        "value": "BXV",
        "iso": "IS"
    },
    {
        "label": "DJU",
        "value": "DJU",
        "iso": "IS"
    },
    {
        "label": "EGS",
        "value": "EGS",
        "iso": "IS"
    },
    {
        "label": "FAS",
        "value": "FAS",
        "iso": "IS"
    },
    {
        "label": "FAG",
        "value": "FAG",
        "iso": "IS"
    },
    {
        "label": "GUU",
        "value": "GUU",
        "iso": "IS"
    },
    {
        "label": "GJR",
        "value": "GJR",
        "iso": "IS"
    },
    {
        "label": "GRY",
        "value": "GRY",
        "iso": "IS"
    },
    {
        "label": "HVK",
        "value": "HVK",
        "iso": "IS"
    },
    {
        "label": "HFN",
        "value": "HFN",
        "iso": "IS"
    },
    {
        "label": "FLI",
        "value": "FLI",
        "iso": "IS"
    },
    {
        "label": "HZK",
        "value": "HZK",
        "iso": "IS"
    },
    {
        "label": "HVM",
        "value": "HVM",
        "iso": "IS"
    },
    {
        "label": "HLO",
        "value": "HLO",
        "iso": "IS"
    },
    {
        "label": "IFJ",
        "value": "IFJ",
        "iso": "IS"
    },
    {
        "label": "KEF",
        "value": "KEF",
        "iso": "IS"
    },
    {
        "label": "OPA",
        "value": "OPA",
        "iso": "IS"
    },
    {
        "label": "SAK",
        "value": "SAK",
        "iso": "IS"
    },
    {
        "label": "NOR",
        "value": "NOR",
        "iso": "IS"
    },
    {
        "label": "OFJ",
        "value": "OFJ",
        "iso": "IS"
    },
    {
        "label": "PFJ",
        "value": "PFJ",
        "iso": "IS"
    },
    {
        "label": "RHA",
        "value": "RHA",
        "iso": "IS"
    },
    {
        "label": "OLI",
        "value": "OLI",
        "iso": "IS"
    },
    {
        "label": "RFN",
        "value": "RFN",
        "iso": "IS"
    },
    {
        "label": "REK",
        "value": "REK",
        "iso": "IS"
    },
    {
        "label": "MVA",
        "value": "MVA",
        "iso": "IS"
    },
    {
        "label": "SIJ",
        "value": "SIJ",
        "iso": "IS"
    },
    {
        "label": "SYK",
        "value": "SYK",
        "iso": "IS"
    },
    {
        "label": "TEY",
        "value": "TEY",
        "iso": "IS"
    },
    {
        "label": "THO",
        "value": "THO",
        "iso": "IS"
    },
    {
        "label": "THO",
        "value": "THO",
        "iso": "IS"
    },
    {
        "label": "VEY",
        "value": "VEY",
        "iso": "IS"
    },
    {
        "label": "VPN",
        "value": "VPN",
        "iso": "IS"
    },
    {
        "label": "BJE",
        "value": "BJE",
        "iso": "SD"
    },
    {
        "label": "BJQ",
        "value": "BJQ",
        "iso": "OM"
    },
    {
        "label": "PRN",
        "value": "PRN",
        "iso": "KS"
    },
    {
        "label": "BLM",
        "value": "BLM",
        "iso": "MX"
    },
    {
        "label": "BMQ",
        "value": "BMQ",
        "iso": "KE"
    },
    {
        "label": "RBQ",
        "value": "RBQ",
        "iso": "BO"
    },
    {
        "label": "BVL",
        "value": "BVL",
        "iso": "BO"
    },
    {
        "label": "TUZ",
        "value": "TUZ",
        "iso": "BR"
    },
    {
        "label": "ALT",
        "value": "ALT",
        "iso": "BR"
    },
    {
        "label": "SWM",
        "value": "SWM",
        "iso": "BR"
    },
    {
        "label": "NSB",
        "value": "NSB",
        "iso": "BS"
    },
    {
        "label": "HUK",
        "value": "HUK",
        "iso": "BW"
    },
    {
        "label": "BYV",
        "value": "BYV",
        "iso": "LK"
    },
    {
        "label": "BCV",
        "value": "BCV",
        "iso": "BZ"
    },
    {
        "label": "BGK",
        "value": "BGK",
        "iso": "BZ"
    },
    {
        "label": "CUK",
        "value": "CUK",
        "iso": "BZ"
    },
    {
        "label": "CYC",
        "value": "CYC",
        "iso": "BZ"
    },
    {
        "label": "CZH",
        "value": "CZH",
        "iso": "BZ"
    },
    {
        "label": "DGA",
        "value": "DGA",
        "iso": "BZ"
    },
    {
        "label": "INB",
        "value": "INB",
        "iso": "BZ"
    },
    {
        "label": "MDB",
        "value": "MDB",
        "iso": "BZ"
    },
    {
        "label": "ORZ",
        "value": "ORZ",
        "iso": "BZ"
    },
    {
        "label": "PLJ",
        "value": "PLJ",
        "iso": "BZ"
    },
    {
        "label": "PND",
        "value": "PND",
        "iso": "BZ"
    },
    {
        "label": "SJX",
        "value": "SJX",
        "iso": "BZ"
    },
    {
        "label": "SPR",
        "value": "SPR",
        "iso": "BZ"
    },
    {
        "label": "SQS",
        "value": "SQS",
        "iso": "BZ"
    },
    {
        "label": "STU",
        "value": "STU",
        "iso": "BZ"
    },
    {
        "label": "SVK",
        "value": "SVK",
        "iso": "BZ"
    },
    {
        "label": "TZA",
        "value": "TZA",
        "iso": "BZ"
    },
    {
        "label": "BZB",
        "value": "BZB",
        "iso": "MZ"
    },
    {
        "label": "YUJ",
        "value": "YUJ",
        "iso": "CA"
    },
    {
        "label": "YWO",
        "value": "YWO",
        "iso": "CA"
    },
    {
        "label": "YTJ",
        "value": "YTJ",
        "iso": "CA"
    },
    {
        "label": "YMV",
        "value": "YMV",
        "iso": "CA"
    },
    {
        "label": "YBW",
        "value": "YBW",
        "iso": "CA"
    },
    {
        "label": "ZNA",
        "value": "ZNA",
        "iso": "CA"
    },
    {
        "label": "YZZ",
        "value": "YZZ",
        "iso": "CA"
    },
    {
        "label": "YWS",
        "value": "YWS",
        "iso": "CA"
    },
    {
        "label": "YPT",
        "value": "YPT",
        "iso": "CA"
    },
    {
        "label": "YAA",
        "value": "YAA",
        "iso": "CA"
    },
    {
        "label": "JHL",
        "value": "JHL",
        "iso": "CA"
    },
    {
        "label": "YMU",
        "value": "YMU",
        "iso": "CA"
    },
    {
        "label": "YWM",
        "value": "YWM",
        "iso": "CA"
    },
    {
        "label": "YFX",
        "value": "YFX",
        "iso": "CA"
    },
    {
        "label": "YHA",
        "value": "YHA",
        "iso": "CA"
    },
    {
        "label": "YRG",
        "value": "YRG",
        "iso": "CA"
    },
    {
        "label": "CDK",
        "value": "CDK",
        "iso": "US"
    },
    {
        "label": "YCK",
        "value": "YCK",
        "iso": "CA"
    },
    {
        "label": "EE7",
        "value": "EE7",
        "iso": "CA"
    },
    {
        "label": "YLE",
        "value": "YLE",
        "iso": "CA"
    },
    {
        "label": "NML",
        "value": "NML",
        "iso": "CA"
    },
    {
        "label": "DAS",
        "value": "DAS",
        "iso": "CA"
    },
    {
        "label": "YFI",
        "value": "YFI",
        "iso": "CA"
    },
    {
        "label": "OKG",
        "value": "OKG",
        "iso": "CG"
    },
    {
        "label": "CGA",
        "value": "CGA",
        "iso": "US"
    },
    {
        "label": "SUR",
        "value": "SUR",
        "iso": "CA"
    },
    {
        "label": "YAX",
        "value": "YAX",
        "iso": "CA"
    },
    {
        "label": "WNN",
        "value": "WNN",
        "iso": "CA"
    },
    {
        "label": "YNO",
        "value": "YNO",
        "iso": "CA"
    },
    {
        "label": "CLG",
        "value": "CLG",
        "iso": "US"
    },
    {
        "label": "OHE",
        "value": "OHE",
        "iso": "CN"
    },
    {
        "label": "FUO",
        "value": "FUO",
        "iso": "CN"
    },
    {
        "label": "HUZ",
        "value": "HUZ",
        "iso": "CN"
    },
    {
        "label": "JGS",
        "value": "JGS",
        "iso": "CN"
    },
    {
        "label": "AEB",
        "value": "AEB",
        "iso": "CN"
    },
    {
        "label": "AAT",
        "value": "AAT",
        "iso": "CN"
    },
    {
        "label": "YZY",
        "value": "YZY",
        "iso": "CN"
    },
    {
        "label": "DDG",
        "value": "DDG",
        "iso": "CN"
    },
    {
        "label": "NTG",
        "value": "NTG",
        "iso": "CN"
    },
    {
        "label": "XBE",
        "value": "XBE",
        "iso": "CA"
    },
    {
        "label": "KIF",
        "value": "KIF",
        "iso": "CA"
    },
    {
        "label": "YOG",
        "value": "YOG",
        "iso": "CA"
    },
    {
        "label": "PRM",
        "value": "PRM",
        "iso": "CO"
    },
    {
        "label": "ISG",
        "value": "ISG",
        "iso": "CO"
    },
    {
        "label": "API",
        "value": "API",
        "iso": "CO"
    },
    {
        "label": "APO",
        "value": "APO",
        "iso": "CO"
    },
    {
        "label": "ARQ",
        "value": "ARQ",
        "iso": "CO"
    },
    {
        "label": "LCR",
        "value": "LCR",
        "iso": "CO"
    },
    {
        "label": "SNT",
        "value": "SNT",
        "iso": "CO"
    },
    {
        "label": "TCD",
        "value": "TCD",
        "iso": "CO"
    },
    {
        "label": "YEB",
        "value": "YEB",
        "iso": "CA"
    },
    {
        "label": "YHP",
        "value": "YHP",
        "iso": "CA"
    },
    {
        "label": "YNX",
        "value": "YNX",
        "iso": "CA"
    },
    {
        "label": "YKU",
        "value": "YKU",
        "iso": "CA"
    },
    {
        "label": "ZTB",
        "value": "ZTB",
        "iso": "CA"
    },
    {
        "label": "YAU",
        "value": "YAU",
        "iso": "CA"
    },
    {
        "label": "TR7",
        "value": "TR7",
        "iso": "CA"
    },
    {
        "label": "ZLT",
        "value": "ZLT",
        "iso": "CA"
    },
    {
        "label": "PST",
        "value": "PST",
        "iso": "CU"
    },
    {
        "label": "GEC",
        "value": "GEC",
        "iso": "CY"
    },
    {
        "label": "NIC",
        "value": "NIC",
        "iso": "CY"
    },
    {
        "label": "YAC",
        "value": "YAC",
        "iso": "CA"
    },
    {
        "label": "YAG",
        "value": "YAG",
        "iso": "CA"
    },
    {
        "label": "YAH",
        "value": "YAH",
        "iso": "CA"
    },
    {
        "label": "YAL",
        "value": "YAL",
        "iso": "CA"
    },
    {
        "label": "YAM",
        "value": "YAM",
        "iso": "CA"
    },
    {
        "label": "XKS",
        "value": "XKS",
        "iso": "CA"
    },
    {
        "label": "YKG",
        "value": "YKG",
        "iso": "CA"
    },
    {
        "label": "YAT",
        "value": "YAT",
        "iso": "CA"
    },
    {
        "label": "YAV",
        "value": "YAV",
        "iso": "CA"
    },
    {
        "label": "YAW",
        "value": "YAW",
        "iso": "CA"
    },
    {
        "label": "YAX",
        "value": "YAX",
        "iso": "CA"
    },
    {
        "label": "YAY",
        "value": "YAY",
        "iso": "CA"
    },
    {
        "label": "YAZ",
        "value": "YAZ",
        "iso": "CA"
    },
    {
        "label": "YBB",
        "value": "YBB",
        "iso": "CA"
    },
    {
        "label": "YBC",
        "value": "YBC",
        "iso": "CA"
    },
    {
        "label": "QBC",
        "value": "QBC",
        "iso": "CA"
    },
    {
        "label": "YBE",
        "value": "YBE",
        "iso": "CA"
    },
    {
        "label": "YBY",
        "value": "YBY",
        "iso": "CA"
    },
    {
        "label": "YBG",
        "value": "YBG",
        "iso": "CA"
    },
    {
        "label": "YBK",
        "value": "YBK",
        "iso": "CA"
    },
    {
        "label": "YBL",
        "value": "YBL",
        "iso": "CA"
    },
    {
        "label": "YBN",
        "value": "YBN",
        "iso": "CA"
    },
    {
        "label": "XTL",
        "value": "XTL",
        "iso": "CA"
    },
    {
        "label": "YBR",
        "value": "YBR",
        "iso": "CA"
    },
    {
        "label": "YBT",
        "value": "YBT",
        "iso": "CA"
    },
    {
        "label": "YBV",
        "value": "YBV",
        "iso": "CA"
    },
    {
        "label": "YBX",
        "value": "YBX",
        "iso": "CA"
    },
    {
        "label": "YRF",
        "value": "YRF",
        "iso": "CA"
    },
    {
        "label": "YCB",
        "value": "YCB",
        "iso": "CA"
    },
    {
        "label": "YCC",
        "value": "YCC",
        "iso": "CA"
    },
    {
        "label": "YCD",
        "value": "YCD",
        "iso": "CA"
    },
    {
        "label": "YCE",
        "value": "YCE",
        "iso": "CA"
    },
    {
        "label": "YCG",
        "value": "YCG",
        "iso": "CA"
    },
    {
        "label": "YCH",
        "value": "YCH",
        "iso": "CA"
    },
    {
        "label": "YCL",
        "value": "YCL",
        "iso": "CA"
    },
    {
        "label": "YCN",
        "value": "YCN",
        "iso": "CA"
    },
    {
        "label": "YCO",
        "value": "YCO",
        "iso": "CA"
    },
    {
        "label": "YCQ",
        "value": "YCQ",
        "iso": "CA"
    },
    {
        "label": "YCR",
        "value": "YCR",
        "iso": "CA"
    },
    {
        "label": "YCS",
        "value": "YCS",
        "iso": "CA"
    },
    {
        "label": "YCV",
        "value": "YCV",
        "iso": "CA"
    },
    {
        "label": "YCX",
        "value": "YCX",
        "iso": "CA"
    },
    {
        "label": "YCY",
        "value": "YCY",
        "iso": "CA"
    },
    {
        "label": "YCZ",
        "value": "YCZ",
        "iso": "CA"
    },
    {
        "label": "YDA",
        "value": "YDA",
        "iso": "CA"
    },
    {
        "label": "YDB",
        "value": "YDB",
        "iso": "CA"
    },
    {
        "label": "YDF",
        "value": "YDF",
        "iso": "CA"
    },
    {
        "label": "YDM",
        "value": "YDM",
        "iso": "CA"
    },
    {
        "label": "YDN",
        "value": "YDN",
        "iso": "CA"
    },
    {
        "label": "YDO",
        "value": "YDO",
        "iso": "CA"
    },
    {
        "label": "YDP",
        "value": "YDP",
        "iso": "CA"
    },
    {
        "label": "YDQ",
        "value": "YDQ",
        "iso": "CA"
    },
    {
        "label": "YED",
        "value": "YED",
        "iso": "CA"
    },
    {
        "label": "YEG",
        "value": "YEG",
        "iso": "CA"
    },
    {
        "label": "YEK",
        "value": "YEK",
        "iso": "CA"
    },
    {
        "label": "YEL",
        "value": "YEL",
        "iso": "CA"
    },
    {
        "label": "YEM",
        "value": "YEM",
        "iso": "CA"
    },
    {
        "label": "YEN",
        "value": "YEN",
        "iso": "CA"
    },
    {
        "label": "YER",
        "value": "YER",
        "iso": "CA"
    },
    {
        "label": "YET",
        "value": "YET",
        "iso": "CA"
    },
    {
        "label": "YEU",
        "value": "YEU",
        "iso": "CA"
    },
    {
        "label": "YEV",
        "value": "YEV",
        "iso": "CA"
    },
    {
        "label": "YEY",
        "value": "YEY",
        "iso": "CA"
    },
    {
        "label": "YFA",
        "value": "YFA",
        "iso": "CA"
    },
    {
        "label": "YFB",
        "value": "YFB",
        "iso": "CA"
    },
    {
        "label": "YFC",
        "value": "YFC",
        "iso": "CA"
    },
    {
        "label": "YFE",
        "value": "YFE",
        "iso": "CA"
    },
    {
        "label": "YFH",
        "value": "YFH",
        "iso": "CA"
    },
    {
        "label": "YTM",
        "value": "YTM",
        "iso": "CA"
    },
    {
        "label": "YFO",
        "value": "YFO",
        "iso": "CA"
    },
    {
        "label": "YFR",
        "value": "YFR",
        "iso": "CA"
    },
    {
        "label": "YFS",
        "value": "YFS",
        "iso": "CA"
    },
    {
        "label": "YMN",
        "value": "YMN",
        "iso": "CA"
    },
    {
        "label": "YGB",
        "value": "YGB",
        "iso": "CA"
    },
    {
        "label": "YGD",
        "value": "YGD",
        "iso": "CA"
    },
    {
        "label": "YGH",
        "value": "YGH",
        "iso": "CA"
    },
    {
        "label": "YGK",
        "value": "YGK",
        "iso": "CA"
    },
    {
        "label": "YGL",
        "value": "YGL",
        "iso": "CA"
    },
    {
        "label": "YGM",
        "value": "YGM",
        "iso": "CA"
    },
    {
        "label": "YGO",
        "value": "YGO",
        "iso": "CA"
    },
    {
        "label": "YGP",
        "value": "YGP",
        "iso": "CA"
    },
    {
        "label": "YGQ",
        "value": "YGQ",
        "iso": "CA"
    },
    {
        "label": "YGR",
        "value": "YGR",
        "iso": "CA"
    },
    {
        "label": "YGT",
        "value": "YGT",
        "iso": "CA"
    },
    {
        "label": "YGV",
        "value": "YGV",
        "iso": "CA"
    },
    {
        "label": "YGW",
        "value": "YGW",
        "iso": "CA"
    },
    {
        "label": "YGX",
        "value": "YGX",
        "iso": "CA"
    },
    {
        "label": "YGZ",
        "value": "YGZ",
        "iso": "CA"
    },
    {
        "label": "YQC",
        "value": "YQC",
        "iso": "CA"
    },
    {
        "label": "CXH",
        "value": "CXH",
        "iso": "CA"
    },
    {
        "label": "YHD",
        "value": "YHD",
        "iso": "CA"
    },
    {
        "label": "YHE",
        "value": "YHE",
        "iso": "CA"
    },
    {
        "label": "YHF",
        "value": "YHF",
        "iso": "CA"
    },
    {
        "label": "YNS",
        "value": "YNS",
        "iso": "CA"
    },
    {
        "label": "YHI",
        "value": "YHI",
        "iso": "CA"
    },
    {
        "label": "YHK",
        "value": "YHK",
        "iso": "CA"
    },
    {
        "label": "YHM",
        "value": "YHM",
        "iso": "CA"
    },
    {
        "label": "YHN",
        "value": "YHN",
        "iso": "CA"
    },
    {
        "label": "YHO",
        "value": "YHO",
        "iso": "CA"
    },
    {
        "label": "YHR",
        "value": "YHR",
        "iso": "CA"
    },
    {
        "label": "YHT",
        "value": "YHT",
        "iso": "CA"
    },
    {
        "label": "YHU",
        "value": "YHU",
        "iso": "CA"
    },
    {
        "label": "YHY",
        "value": "YHY",
        "iso": "CA"
    },
    {
        "label": "YHZ",
        "value": "YHZ",
        "iso": "CA"
    },
    {
        "label": "YIB",
        "value": "YIB",
        "iso": "CA"
    },
    {
        "label": "YDG",
        "value": "YDG",
        "iso": "CA"
    },
    {
        "label": "YIF",
        "value": "YIF",
        "iso": "CA"
    },
    {
        "label": "YIK",
        "value": "YIK",
        "iso": "CA"
    },
    {
        "label": "YIO",
        "value": "YIO",
        "iso": "CA"
    },
    {
        "label": "YIV",
        "value": "YIV",
        "iso": "CA"
    },
    {
        "label": "YJF",
        "value": "YJF",
        "iso": "CA"
    },
    {
        "label": "YJN",
        "value": "YJN",
        "iso": "CA"
    },
    {
        "label": "YJT",
        "value": "YJT",
        "iso": "CA"
    },
    {
        "label": "YKA",
        "value": "YKA",
        "iso": "CA"
    },
    {
        "label": "LAK",
        "value": "LAK",
        "iso": "CA"
    },
    {
        "label": "YKF",
        "value": "YKF",
        "iso": "CA"
    },
    {
        "label": "YKJ",
        "value": "YKJ",
        "iso": "CA"
    },
    {
        "label": "YKL",
        "value": "YKL",
        "iso": "CA"
    },
    {
        "label": "AKV",
        "value": "AKV",
        "iso": "CA"
    },
    {
        "label": "YKQ",
        "value": "YKQ",
        "iso": "CA"
    },
    {
        "label": "YKX",
        "value": "YKX",
        "iso": "CA"
    },
    {
        "label": "YKY",
        "value": "YKY",
        "iso": "CA"
    },
    {
        "label": "YKZ",
        "value": "YKZ",
        "iso": "CA"
    },
    {
        "label": "YPJ",
        "value": "YPJ",
        "iso": "CA"
    },
    {
        "label": "YLC",
        "value": "YLC",
        "iso": "CA"
    },
    {
        "label": "YLD",
        "value": "YLD",
        "iso": "CA"
    },
    {
        "label": "YLH",
        "value": "YLH",
        "iso": "CA"
    },
    {
        "label": "YLJ",
        "value": "YLJ",
        "iso": "CA"
    },
    {
        "label": "YSG",
        "value": "YSG",
        "iso": "CA"
    },
    {
        "label": "YLL",
        "value": "YLL",
        "iso": "CA"
    },
    {
        "label": "YLR",
        "value": "YLR",
        "iso": "CA"
    },
    {
        "label": "YLT",
        "value": "YLT",
        "iso": "CA"
    },
    {
        "label": "XGR",
        "value": "XGR",
        "iso": "CA"
    },
    {
        "label": "YLW",
        "value": "YLW",
        "iso": "CA"
    },
    {
        "label": "YMA",
        "value": "YMA",
        "iso": "CA"
    },
    {
        "label": "YME",
        "value": "YME",
        "iso": "CA"
    },
    {
        "label": "YMG",
        "value": "YMG",
        "iso": "CA"
    },
    {
        "label": "YMH",
        "value": "YMH",
        "iso": "CA"
    },
    {
        "label": "YMJ",
        "value": "YMJ",
        "iso": "CA"
    },
    {
        "label": "YML",
        "value": "YML",
        "iso": "CA"
    },
    {
        "label": "YMM",
        "value": "YMM",
        "iso": "CA"
    },
    {
        "label": "YMO",
        "value": "YMO",
        "iso": "CA"
    },
    {
        "label": "YMT",
        "value": "YMT",
        "iso": "CA"
    },
    {
        "label": "YUD",
        "value": "YUD",
        "iso": "CA"
    },
    {
        "label": "YMX",
        "value": "YMX",
        "iso": "CA"
    },
    {
        "label": "YNA",
        "value": "YNA",
        "iso": "CA"
    },
    {
        "label": "YNC",
        "value": "YNC",
        "iso": "CA"
    },
    {
        "label": "YND",
        "value": "YND",
        "iso": "CA"
    },
    {
        "label": "YNE",
        "value": "YNE",
        "iso": "CA"
    },
    {
        "label": "YNL",
        "value": "YNL",
        "iso": "CA"
    },
    {
        "label": "YNM",
        "value": "YNM",
        "iso": "CA"
    },
    {
        "label": "YNN",
        "value": "YNN",
        "iso": "CA"
    },
    {
        "label": "HZP",
        "value": "HZP",
        "iso": "CA"
    },
    {
        "label": "YOA",
        "value": "YOA",
        "iso": "CA"
    },
    {
        "label": "YOC",
        "value": "YOC",
        "iso": "CA"
    },
    {
        "label": "YOD",
        "value": "YOD",
        "iso": "CA"
    },
    {
        "label": "YOH",
        "value": "YOH",
        "iso": "CA"
    },
    {
        "label": "YOJ",
        "value": "YOJ",
        "iso": "CA"
    },
    {
        "label": "YOO",
        "value": "YOO",
        "iso": "CA"
    },
    {
        "label": "YOP",
        "value": "YOP",
        "iso": "CA"
    },
    {
        "label": "YOS",
        "value": "YOS",
        "iso": "CA"
    },
    {
        "label": "YOW",
        "value": "YOW",
        "iso": "CA"
    },
    {
        "label": "YOY",
        "value": "YOY",
        "iso": "CA"
    },
    {
        "label": "YPA",
        "value": "YPA",
        "iso": "CA"
    },
    {
        "label": "YPC",
        "value": "YPC",
        "iso": "CA"
    },
    {
        "label": "YPS",
        "value": "YPS",
        "iso": "CA"
    },
    {
        "label": "YPE",
        "value": "YPE",
        "iso": "CA"
    },
    {
        "label": "YPG",
        "value": "YPG",
        "iso": "CA"
    },
    {
        "label": "YPH",
        "value": "YPH",
        "iso": "CA"
    },
    {
        "label": "YPL",
        "value": "YPL",
        "iso": "CA"
    },
    {
        "label": "YPM",
        "value": "YPM",
        "iso": "CA"
    },
    {
        "label": "YPN",
        "value": "YPN",
        "iso": "CA"
    },
    {
        "label": "YPO",
        "value": "YPO",
        "iso": "CA"
    },
    {
        "label": "YPQ",
        "value": "YPQ",
        "iso": "CA"
    },
    {
        "label": "YPR",
        "value": "YPR",
        "iso": "CA"
    },
    {
        "label": "YPW",
        "value": "YPW",
        "iso": "CA"
    },
    {
        "label": "YPX",
        "value": "YPX",
        "iso": "CA"
    },
    {
        "label": "YPY",
        "value": "YPY",
        "iso": "CA"
    },
    {
        "label": "YQA",
        "value": "YQA",
        "iso": "CA"
    },
    {
        "label": "YQB",
        "value": "YQB",
        "iso": "CA"
    },
    {
        "label": "YQD",
        "value": "YQD",
        "iso": "CA"
    },
    {
        "label": "YQF",
        "value": "YQF",
        "iso": "CA"
    },
    {
        "label": "YQG",
        "value": "YQG",
        "iso": "CA"
    },
    {
        "label": "YQH",
        "value": "YQH",
        "iso": "CA"
    },
    {
        "label": "YQI",
        "value": "YQI",
        "iso": "CA"
    },
    {
        "label": "YQK",
        "value": "YQK",
        "iso": "CA"
    },
    {
        "label": "YQL",
        "value": "YQL",
        "iso": "CA"
    },
    {
        "label": "YQM",
        "value": "YQM",
        "iso": "CA"
    },
    {
        "label": "YQN",
        "value": "YQN",
        "iso": "CA"
    },
    {
        "label": "YQQ",
        "value": "YQQ",
        "iso": "CA"
    },
    {
        "label": "YQR",
        "value": "YQR",
        "iso": "CA"
    },
    {
        "label": "YQS",
        "value": "YQS",
        "iso": "CA"
    },
    {
        "label": "YQT",
        "value": "YQT",
        "iso": "CA"
    },
    {
        "label": "YQU",
        "value": "YQU",
        "iso": "CA"
    },
    {
        "label": "YQV",
        "value": "YQV",
        "iso": "CA"
    },
    {
        "label": "YQW",
        "value": "YQW",
        "iso": "CA"
    },
    {
        "label": "YQX",
        "value": "YQX",
        "iso": "CA"
    },
    {
        "label": "YQY",
        "value": "YQY",
        "iso": "CA"
    },
    {
        "label": "YQZ",
        "value": "YQZ",
        "iso": "CA"
    },
    {
        "label": "YRA",
        "value": "YRA",
        "iso": "CA"
    },
    {
        "label": "YRB",
        "value": "YRB",
        "iso": "CA"
    },
    {
        "label": "YRI",
        "value": "YRI",
        "iso": "CA"
    },
    {
        "label": "YRJ",
        "value": "YRJ",
        "iso": "CA"
    },
    {
        "label": "YRL",
        "value": "YRL",
        "iso": "CA"
    },
    {
        "label": "YRO",
        "value": "YRO",
        "iso": "CA"
    },
    {
        "label": "YRP",
        "value": "YRP",
        "iso": "CA"
    },
    {
        "label": "YRQ",
        "value": "YRQ",
        "iso": "CA"
    },
    {
        "label": "YRS",
        "value": "YRS",
        "iso": "CA"
    },
    {
        "label": "YRT",
        "value": "YRT",
        "iso": "CA"
    },
    {
        "label": "YRV",
        "value": "YRV",
        "iso": "CA"
    },
    {
        "label": "YSB",
        "value": "YSB",
        "iso": "CA"
    },
    {
        "label": "YSC",
        "value": "YSC",
        "iso": "CA"
    },
    {
        "label": "YSD",
        "value": "YSD",
        "iso": "CA"
    },
    {
        "label": "YSF",
        "value": "YSF",
        "iso": "CA"
    },
    {
        "label": "YSH",
        "value": "YSH",
        "iso": "CA"
    },
    {
        "label": "YSJ",
        "value": "YSJ",
        "iso": "CA"
    },
    {
        "label": "YSK",
        "value": "YSK",
        "iso": "CA"
    },
    {
        "label": "YSL",
        "value": "YSL",
        "iso": "CA"
    },
    {
        "label": "YSM",
        "value": "YSM",
        "iso": "CA"
    },
    {
        "label": "YCM",
        "value": "YCM",
        "iso": "CA"
    },
    {
        "label": "YSP",
        "value": "YSP",
        "iso": "CA"
    },
    {
        "label": "YSR",
        "value": "YSR",
        "iso": "CA"
    },
    {
        "label": "YST",
        "value": "YST",
        "iso": "CA"
    },
    {
        "label": "YSU",
        "value": "YSU",
        "iso": "CA"
    },
    {
        "label": "YSY",
        "value": "YSY",
        "iso": "CA"
    },
    {
        "label": "YTA",
        "value": "YTA",
        "iso": "CA"
    },
    {
        "label": "YTE",
        "value": "YTE",
        "iso": "CA"
    },
    {
        "label": "YTF",
        "value": "YTF",
        "iso": "CA"
    },
    {
        "label": "YTH",
        "value": "YTH",
        "iso": "CA"
    },
    {
        "label": "YTL",
        "value": "YTL",
        "iso": "CA"
    },
    {
        "label": "YTQ",
        "value": "YTQ",
        "iso": "CA"
    },
    {
        "label": "YTR",
        "value": "YTR",
        "iso": "CA"
    },
    {
        "label": "YTS",
        "value": "YTS",
        "iso": "CA"
    },
    {
        "label": "YTZ",
        "value": "YTZ",
        "iso": "CA"
    },
    {
        "label": "YUB",
        "value": "YUB",
        "iso": "CA"
    },
    {
        "label": "YUL",
        "value": "YUL",
        "iso": "CA"
    },
    {
        "label": "YUT",
        "value": "YUT",
        "iso": "CA"
    },
    {
        "label": "YUX",
        "value": "YUX",
        "iso": "CA"
    },
    {
        "label": "YUY",
        "value": "YUY",
        "iso": "CA"
    },
    {
        "label": "YVB",
        "value": "YVB",
        "iso": "CA"
    },
    {
        "label": "YVC",
        "value": "YVC",
        "iso": "CA"
    },
    {
        "label": "YVD",
        "value": "YVD",
        "iso": "CA"
    },
    {
        "label": "YVE",
        "value": "YVE",
        "iso": "CA"
    },
    {
        "label": "YVM",
        "value": "YVM",
        "iso": "CA"
    },
    {
        "label": "YVO",
        "value": "YVO",
        "iso": "CA"
    },
    {
        "label": "YVP",
        "value": "YVP",
        "iso": "CA"
    },
    {
        "label": "YVQ",
        "value": "YVQ",
        "iso": "CA"
    },
    {
        "label": "YVR",
        "value": "YVR",
        "iso": "CA"
    },
    {
        "label": "YVT",
        "value": "YVT",
        "iso": "CA"
    },
    {
        "label": "YVV",
        "value": "YVV",
        "iso": "CA"
    },
    {
        "label": "YVZ",
        "value": "YVZ",
        "iso": "CA"
    },
    {
        "label": "YWA",
        "value": "YWA",
        "iso": "CA"
    },
    {
        "label": "YWG",
        "value": "YWG",
        "iso": "CA"
    },
    {
        "label": "YWH",
        "value": "YWH",
        "iso": "CA"
    },
    {
        "label": "YWJ",
        "value": "YWJ",
        "iso": "CA"
    },
    {
        "label": "YWK",
        "value": "YWK",
        "iso": "CA"
    },
    {
        "label": "YWL",
        "value": "YWL",
        "iso": "CA"
    },
    {
        "label": "YWP",
        "value": "YWP",
        "iso": "CA"
    },
    {
        "label": "YWY",
        "value": "YWY",
        "iso": "CA"
    },
    {
        "label": "YXC",
        "value": "YXC",
        "iso": "CA"
    },
    {
        "label": "YXD",
        "value": "YXD",
        "iso": "CA"
    },
    {
        "label": "YXE",
        "value": "YXE",
        "iso": "CA"
    },
    {
        "label": "YXH",
        "value": "YXH",
        "iso": "CA"
    },
    {
        "label": "YXI",
        "value": "YXI",
        "iso": "CA"
    },
    {
        "label": "YXJ",
        "value": "YXJ",
        "iso": "CA"
    },
    {
        "label": "YXK",
        "value": "YXK",
        "iso": "CA"
    },
    {
        "label": "YXL",
        "value": "YXL",
        "iso": "CA"
    },
    {
        "label": "YXN",
        "value": "YXN",
        "iso": "CA"
    },
    {
        "label": "YXP",
        "value": "YXP",
        "iso": "CA"
    },
    {
        "label": "YXQ",
        "value": "YXQ",
        "iso": "CA"
    },
    {
        "label": "YXR",
        "value": "YXR",
        "iso": "CA"
    },
    {
        "label": "YXS",
        "value": "YXS",
        "iso": "CA"
    },
    {
        "label": "YXT",
        "value": "YXT",
        "iso": "CA"
    },
    {
        "label": "YXU",
        "value": "YXU",
        "iso": "CA"
    },
    {
        "label": "YXX",
        "value": "YXX",
        "iso": "CA"
    },
    {
        "label": "YXY",
        "value": "YXY",
        "iso": "CA"
    },
    {
        "label": "YXZ",
        "value": "YXZ",
        "iso": "CA"
    },
    {
        "label": "YYB",
        "value": "YYB",
        "iso": "CA"
    },
    {
        "label": "YYC",
        "value": "YYC",
        "iso": "CA"
    },
    {
        "label": "YYD",
        "value": "YYD",
        "iso": "CA"
    },
    {
        "label": "YYE",
        "value": "YYE",
        "iso": "CA"
    },
    {
        "label": "YYF",
        "value": "YYF",
        "iso": "CA"
    },
    {
        "label": "YYG",
        "value": "YYG",
        "iso": "CA"
    },
    {
        "label": "YYH",
        "value": "YYH",
        "iso": "CA"
    },
    {
        "label": "YYJ",
        "value": "YYJ",
        "iso": "CA"
    },
    {
        "label": "YYL",
        "value": "YYL",
        "iso": "CA"
    },
    {
        "label": "YYN",
        "value": "YYN",
        "iso": "CA"
    },
    {
        "label": "YYQ",
        "value": "YYQ",
        "iso": "CA"
    },
    {
        "label": "YYR",
        "value": "YYR",
        "iso": "CA"
    },
    {
        "label": "YYT",
        "value": "YYT",
        "iso": "CA"
    },
    {
        "label": "YYU",
        "value": "YYU",
        "iso": "CA"
    },
    {
        "label": "YYW",
        "value": "YYW",
        "iso": "CA"
    },
    {
        "label": "YYY",
        "value": "YYY",
        "iso": "CA"
    },
    {
        "label": "YYZ",
        "value": "YYZ",
        "iso": "CA"
    },
    {
        "label": "YZD",
        "value": "YZD",
        "iso": "CA"
    },
    {
        "label": "YZE",
        "value": "YZE",
        "iso": "CA"
    },
    {
        "label": "YZF",
        "value": "YZF",
        "iso": "CA"
    },
    {
        "label": "YZG",
        "value": "YZG",
        "iso": "CA"
    },
    {
        "label": "YZH",
        "value": "YZH",
        "iso": "CA"
    },
    {
        "label": "YZP",
        "value": "YZP",
        "iso": "CA"
    },
    {
        "label": "YZR",
        "value": "YZR",
        "iso": "CA"
    },
    {
        "label": "YZS",
        "value": "YZS",
        "iso": "CA"
    },
    {
        "label": "YZT",
        "value": "YZT",
        "iso": "CA"
    },
    {
        "label": "YZU",
        "value": "YZU",
        "iso": "CA"
    },
    {
        "label": "YZV",
        "value": "YZV",
        "iso": "CA"
    },
    {
        "label": "YZW",
        "value": "YZW",
        "iso": "CA"
    },
    {
        "label": "YZX",
        "value": "YZX",
        "iso": "CA"
    },
    {
        "label": "YZY",
        "value": "YZY",
        "iso": "CA"
    },
    {
        "label": "ZAC",
        "value": "ZAC",
        "iso": "CA"
    },
    {
        "label": "YSN",
        "value": "YSN",
        "iso": "CA"
    },
    {
        "label": "YDT",
        "value": "YDT",
        "iso": "CA"
    },
    {
        "label": "ZBD",
        "value": "ZBD",
        "iso": "CA"
    },
    {
        "label": "ZBF",
        "value": "ZBF",
        "iso": "CA"
    },
    {
        "label": "ZBF",
        "value": "ZBF",
        "iso": "CA"
    },
    {
        "label": "ZBM",
        "value": "ZBM",
        "iso": "CA"
    },
    {
        "label": "ZEE",
        "value": "ZEE",
        "iso": "CA"
    },
    {
        "label": "ZEM",
        "value": "ZEM",
        "iso": "CA"
    },
    {
        "label": "ZFA",
        "value": "ZFA",
        "iso": "CA"
    },
    {
        "label": "ZFD",
        "value": "ZFD",
        "iso": "CA"
    },
    {
        "label": "ZFG",
        "value": "ZFG",
        "iso": "CA"
    },
    {
        "label": "ZFM",
        "value": "ZFM",
        "iso": "CA"
    },
    {
        "label": "ZFN",
        "value": "ZFN",
        "iso": "CA"
    },
    {
        "label": "ZGF",
        "value": "ZGF",
        "iso": "CA"
    },
    {
        "label": "ZGI",
        "value": "ZGI",
        "iso": "CA"
    },
    {
        "label": "ZGR",
        "value": "ZGR",
        "iso": "CA"
    },
    {
        "label": "ZJG",
        "value": "ZJG",
        "iso": "CA"
    },
    {
        "label": "ZJN",
        "value": "ZJN",
        "iso": "CA"
    },
    {
        "label": "ZKE",
        "value": "ZKE",
        "iso": "CA"
    },
    {
        "label": "YTD",
        "value": "YTD",
        "iso": "CA"
    },
    {
        "label": "MSA",
        "value": "MSA",
        "iso": "CA"
    },
    {
        "label": "PIW",
        "value": "PIW",
        "iso": "CA"
    },
    {
        "label": "ZMT",
        "value": "ZMT",
        "iso": "CA"
    },
    {
        "label": "ZNG",
        "value": "ZNG",
        "iso": "CA"
    },
    {
        "label": "ZPB",
        "value": "ZPB",
        "iso": "CA"
    },
    {
        "label": "ZRJ",
        "value": "ZRJ",
        "iso": "CA"
    },
    {
        "label": "ZSJ",
        "value": "ZSJ",
        "iso": "CA"
    },
    {
        "label": "ZSN",
        "value": "ZSN",
        "iso": "CA"
    },
    {
        "label": "ZSW",
        "value": "ZSW",
        "iso": "CA"
    },
    {
        "label": "ZTM",
        "value": "ZTM",
        "iso": "CA"
    },
    {
        "label": "ZUC",
        "value": "ZUC",
        "iso": "CA"
    },
    {
        "label": "ZUM",
        "value": "ZUM",
        "iso": "CA"
    },
    {
        "label": "ZWH",
        "value": "ZWH",
        "iso": "CA"
    },
    {
        "label": "ZWL",
        "value": "ZWL",
        "iso": "CA"
    },
    {
        "label": "QLD",
        "value": "QLD",
        "iso": "DZ"
    },
    {
        "label": "BUJ",
        "value": "BUJ",
        "iso": "DZ"
    },
    {
        "label": "BJA",
        "value": "BJA",
        "iso": "DZ"
    },
    {
        "label": "ALG",
        "value": "ALG",
        "iso": "DZ"
    },
    {
        "label": "DJG",
        "value": "DJG",
        "iso": "DZ"
    },
    {
        "label": "QFD",
        "value": "QFD",
        "iso": "DZ"
    },
    {
        "label": "VVZ",
        "value": "VVZ",
        "iso": "DZ"
    },
    {
        "label": "QSF",
        "value": "QSF",
        "iso": "DZ"
    },
    {
        "label": "TMR",
        "value": "TMR",
        "iso": "DZ"
    },
    {
        "label": "GJL",
        "value": "GJL",
        "iso": "DZ"
    },
    {
        "label": "MZW",
        "value": "MZW",
        "iso": "DZ"
    },
    {
        "label": "QZN",
        "value": "QZN",
        "iso": "DZ"
    },
    {
        "label": "AAE",
        "value": "AAE",
        "iso": "DZ"
    },
    {
        "label": "CZL",
        "value": "CZL",
        "iso": "DZ"
    },
    {
        "label": "TEE",
        "value": "TEE",
        "iso": "DZ"
    },
    {
        "label": "BLJ",
        "value": "BLJ",
        "iso": "DZ"
    },
    {
        "label": "HRM",
        "value": "HRM",
        "iso": "DZ"
    },
    {
        "label": "TID",
        "value": "TID",
        "iso": "DZ"
    },
    {
        "label": "TIN",
        "value": "TIN",
        "iso": "DZ"
    },
    {
        "label": "QAS",
        "value": "QAS",
        "iso": "DZ"
    },
    {
        "label": "TAF",
        "value": "TAF",
        "iso": "DZ"
    },
    {
        "label": "TLM",
        "value": "TLM",
        "iso": "DZ"
    },
    {
        "label": "ORN",
        "value": "ORN",
        "iso": "DZ"
    },
    {
        "label": "CBH",
        "value": "CBH",
        "iso": "DZ"
    },
    {
        "label": "MUW",
        "value": "MUW",
        "iso": "DZ"
    },
    {
        "label": "EBH",
        "value": "EBH",
        "iso": "DZ"
    },
    {
        "label": "INF",
        "value": "INF",
        "iso": "DZ"
    },
    {
        "label": "BMW",
        "value": "BMW",
        "iso": "DZ"
    },
    {
        "label": "AZR",
        "value": "AZR",
        "iso": "DZ"
    },
    {
        "label": "BSK",
        "value": "BSK",
        "iso": "DZ"
    },
    {
        "label": "ELG",
        "value": "ELG",
        "iso": "DZ"
    },
    {
        "label": "GHA",
        "value": "GHA",
        "iso": "DZ"
    },
    {
        "label": "HME",
        "value": "HME",
        "iso": "DZ"
    },
    {
        "label": "INZ",
        "value": "INZ",
        "iso": "DZ"
    },
    {
        "label": "TGR",
        "value": "TGR",
        "iso": "DZ"
    },
    {
        "label": "LOO",
        "value": "LOO",
        "iso": "DZ"
    },
    {
        "label": "ELU",
        "value": "ELU",
        "iso": "DZ"
    },
    {
        "label": "TMX",
        "value": "TMX",
        "iso": "DZ"
    },
    {
        "label": "OGX",
        "value": "OGX",
        "iso": "DZ"
    },
    {
        "label": "IAM",
        "value": "IAM",
        "iso": "DZ"
    },
    {
        "label": "COO",
        "value": "COO",
        "iso": "BJ"
    },
    {
        "label": "KDC",
        "value": "KDC",
        "iso": "BJ"
    },
    {
        "label": "NAE",
        "value": "NAE",
        "iso": "BJ"
    },
    {
        "label": "PKO",
        "value": "PKO",
        "iso": "BJ"
    },
    {
        "label": "SVF",
        "value": "SVF",
        "iso": "BJ"
    },
    {
        "label": "DCG",
        "value": "DCG",
        "iso": "AE"
    },
    {
        "label": "XKY",
        "value": "XKY",
        "iso": "BF"
    },
    {
        "label": "OUG",
        "value": "OUG",
        "iso": "BF"
    },
    {
        "label": "XDJ",
        "value": "XDJ",
        "iso": "BF"
    },
    {
        "label": "XLU",
        "value": "XLU",
        "iso": "BF"
    },
    {
        "label": "PUP",
        "value": "PUP",
        "iso": "BF"
    },
    {
        "label": "XBO",
        "value": "XBO",
        "iso": "BF"
    },
    {
        "label": "XBG",
        "value": "XBG",
        "iso": "BF"
    },
    {
        "label": "DIP",
        "value": "DIP",
        "iso": "BF"
    },
    {
        "label": "DOR",
        "value": "DOR",
        "iso": "BF"
    },
    {
        "label": "FNG",
        "value": "FNG",
        "iso": "BF"
    },
    {
        "label": "XGG",
        "value": "XGG",
        "iso": "BF"
    },
    {
        "label": "XKA",
        "value": "XKA",
        "iso": "BF"
    },
    {
        "label": "TMQ",
        "value": "TMQ",
        "iso": "BF"
    },
    {
        "label": "XPA",
        "value": "XPA",
        "iso": "BF"
    },
    {
        "label": "ARL",
        "value": "ARL",
        "iso": "BF"
    },
    {
        "label": "XSE",
        "value": "XSE",
        "iso": "BF"
    },
    {
        "label": "TEG",
        "value": "TEG",
        "iso": "BF"
    },
    {
        "label": "XZA",
        "value": "XZA",
        "iso": "BF"
    },
    {
        "label": "OUA",
        "value": "OUA",
        "iso": "BF"
    },
    {
        "label": "BNR",
        "value": "BNR",
        "iso": "BF"
    },
    {
        "label": "DGU",
        "value": "DGU",
        "iso": "BF"
    },
    {
        "label": "XGA",
        "value": "XGA",
        "iso": "BF"
    },
    {
        "label": "XNU",
        "value": "XNU",
        "iso": "BF"
    },
    {
        "label": "BOY",
        "value": "BOY",
        "iso": "BF"
    },
    {
        "label": "TUQ",
        "value": "TUQ",
        "iso": "BF"
    },
    {
        "label": "XDE",
        "value": "XDE",
        "iso": "BF"
    },
    {
        "label": "XAR",
        "value": "XAR",
        "iso": "BF"
    },
    {
        "label": "ACC",
        "value": "ACC",
        "iso": "GH"
    },
    {
        "label": "TML",
        "value": "TML",
        "iso": "GH"
    },
    {
        "label": "KMS",
        "value": "KMS",
        "iso": "GH"
    },
    {
        "label": "NYI",
        "value": "NYI",
        "iso": "GH"
    },
    {
        "label": "TKD",
        "value": "TKD",
        "iso": "GH"
    },
    {
        "label": "DHB",
        "value": "DHB",
        "iso": "US"
    },
    {
        "label": "ABJ",
        "value": "ABJ",
        "iso": "CI"
    },
    {
        "label": "OGO",
        "value": "OGO",
        "iso": "CI"
    },
    {
        "label": "BXI",
        "value": "BXI",
        "iso": "CI"
    },
    {
        "label": "BYK",
        "value": "BYK",
        "iso": "CI"
    },
    {
        "label": "BQO",
        "value": "BQO",
        "iso": "CI"
    },
    {
        "label": "BDK",
        "value": "BDK",
        "iso": "CI"
    },
    {
        "label": "DIM",
        "value": "DIM",
        "iso": "CI"
    },
    {
        "label": "DJO",
        "value": "DJO",
        "iso": "CI"
    },
    {
        "label": "FEK",
        "value": "FEK",
        "iso": "CI"
    },
    {
        "label": "GGN",
        "value": "GGN",
        "iso": "CI"
    },
    {
        "label": "GGO",
        "value": "GGO",
        "iso": "CI"
    },
    {
        "label": "HGO",
        "value": "HGO",
        "iso": "CI"
    },
    {
        "label": "MJC",
        "value": "MJC",
        "iso": "CI"
    },
    {
        "label": "KEO",
        "value": "KEO",
        "iso": "CI"
    },
    {
        "label": "OFI",
        "value": "OFI",
        "iso": "CI"
    },
    {
        "label": "SEO",
        "value": "SEO",
        "iso": "CI"
    },
    {
        "label": "SPY",
        "value": "SPY",
        "iso": "CI"
    },
    {
        "label": "ZSS",
        "value": "ZSS",
        "iso": "CI"
    },
    {
        "label": "TXU",
        "value": "TXU",
        "iso": "CI"
    },
    {
        "label": "ASK",
        "value": "ASK",
        "iso": "CI"
    },
    {
        "label": "DKA",
        "value": "DKA",
        "iso": "NG"
    },
    {
        "label": "ABV",
        "value": "ABV",
        "iso": "NG"
    },
    {
        "label": "AKR",
        "value": "AKR",
        "iso": "NG"
    },
    {
        "label": "ABB",
        "value": "ABB",
        "iso": "NG"
    },
    {
        "label": "BNI",
        "value": "BNI",
        "iso": "NG"
    },
    {
        "label": "CBQ",
        "value": "CBQ",
        "iso": "NG"
    },
    {
        "label": "ENU",
        "value": "ENU",
        "iso": "NG"
    },
    {
        "label": "QUS",
        "value": "QUS",
        "iso": "NG"
    },
    {
        "label": "IBA",
        "value": "IBA",
        "iso": "NG"
    },
    {
        "label": "ILR",
        "value": "ILR",
        "iso": "NG"
    },
    {
        "label": "QOW",
        "value": "QOW",
        "iso": "NG"
    },
    {
        "label": "JOS",
        "value": "JOS",
        "iso": "NG"
    },
    {
        "label": "KAD",
        "value": "KAD",
        "iso": "NG"
    },
    {
        "label": "KAN",
        "value": "KAN",
        "iso": "NG"
    },
    {
        "label": "MIU",
        "value": "MIU",
        "iso": "NG"
    },
    {
        "label": "MDI",
        "value": "MDI",
        "iso": "NG"
    },
    {
        "label": "LOS",
        "value": "LOS",
        "iso": "NG"
    },
    {
        "label": "MXJ",
        "value": "MXJ",
        "iso": "NG"
    },
    {
        "label": "PHC",
        "value": "PHC",
        "iso": "NG"
    },
    {
        "label": "SKO",
        "value": "SKO",
        "iso": "NG"
    },
    {
        "label": "YOL",
        "value": "YOL",
        "iso": "NG"
    },
    {
        "label": "ZAR",
        "value": "ZAR",
        "iso": "NG"
    },
    {
        "label": "STI",
        "value": "STI",
        "iso": "DO"
    },
    {
        "label": "DQA",
        "value": "DQA",
        "iso": "CN"
    },
    {
        "label": "MFQ",
        "value": "MFQ",
        "iso": "NE"
    },
    {
        "label": "NIM",
        "value": "NIM",
        "iso": "NE"
    },
    {
        "label": "THZ",
        "value": "THZ",
        "iso": "NE"
    },
    {
        "label": "AJY",
        "value": "AJY",
        "iso": "NE"
    },
    {
        "label": "ZND",
        "value": "ZND",
        "iso": "NE"
    },
    {
        "label": "TBJ",
        "value": "TBJ",
        "iso": "TN"
    },
    {
        "label": "MIR",
        "value": "MIR",
        "iso": "TN"
    },
    {
        "label": "TUN",
        "value": "TUN",
        "iso": "TN"
    },
    {
        "label": "OIZ",
        "value": "OIZ",
        "iso": "TN"
    },
    {
        "label": "GAF",
        "value": "GAF",
        "iso": "TN"
    },
    {
        "label": "GAE",
        "value": "GAE",
        "iso": "TN"
    },
    {
        "label": "DJE",
        "value": "DJE",
        "iso": "TN"
    },
    {
        "label": "EBM",
        "value": "EBM",
        "iso": "TN"
    },
    {
        "label": "SFA",
        "value": "SFA",
        "iso": "TN"
    },
    {
        "label": "TOE",
        "value": "TOE",
        "iso": "TN"
    },
    {
        "label": "DWR",
        "value": "DWR",
        "iso": "AF"
    },
    {
        "label": "LRL",
        "value": "LRL",
        "iso": "TG"
    },
    {
        "label": "LFW",
        "value": "LFW",
        "iso": "TG"
    },
    {
        "label": "ANR",
        "value": "ANR",
        "iso": "BE"
    },
    {
        "label": "BRU",
        "value": "BRU",
        "iso": "BE"
    },
    {
        "label": "CRL",
        "value": "CRL",
        "iso": "BE"
    },
    {
        "label": "KJK",
        "value": "KJK",
        "iso": "BE"
    },
    {
        "label": "LGG",
        "value": "LGG",
        "iso": "BE"
    },
    {
        "label": "QNM",
        "value": "QNM",
        "iso": "BE"
    },
    {
        "label": "OST",
        "value": "OST",
        "iso": "BE"
    },
    {
        "label": "QHA",
        "value": "QHA",
        "iso": "BE"
    },
    {
        "label": "OBL",
        "value": "OBL",
        "iso": "BE"
    },
    {
        "label": "MZD",
        "value": "MZD",
        "iso": "EC"
    },
    {
        "label": "AOC",
        "value": "AOC",
        "iso": "DE"
    },
    {
        "label": "HDF",
        "value": "HDF",
        "iso": "DE"
    },
    {
        "label": "BBH",
        "value": "BBH",
        "iso": "DE"
    },
    {
        "label": "ZMG",
        "value": "ZMG",
        "iso": "DE"
    },
    {
        "label": "CBU",
        "value": "CBU",
        "iso": "DE"
    },
    {
        "label": "SXF",
        "value": "SXF",
        "iso": "DE"
    },
    {
        "label": "DRS",
        "value": "DRS",
        "iso": "DE"
    },
    {
        "label": "ERF",
        "value": "ERF",
        "iso": "DE"
    },
    {
        "label": "FRA",
        "value": "FRA",
        "iso": "DE"
    },
    {
        "label": "FMO",
        "value": "FMO",
        "iso": "DE"
    },
    {
        "label": "HAM",
        "value": "HAM",
        "iso": "DE"
    },
    {
        "label": "THF",
        "value": "THF",
        "iso": "DE"
    },
    {
        "label": "CGN",
        "value": "CGN",
        "iso": "DE"
    },
    {
        "label": "DUS",
        "value": "DUS",
        "iso": "DE"
    },
    {
        "label": "MUC",
        "value": "MUC",
        "iso": "DE"
    },
    {
        "label": "NUE",
        "value": "NUE",
        "iso": "DE"
    },
    {
        "label": "LEJ",
        "value": "LEJ",
        "iso": "DE"
    },
    {
        "label": "SCN",
        "value": "SCN",
        "iso": "DE"
    },
    {
        "label": "STR",
        "value": "STR",
        "iso": "DE"
    },
    {
        "label": "TXL",
        "value": "TXL",
        "iso": "DE"
    },
    {
        "label": "HAJ",
        "value": "HAJ",
        "iso": "DE"
    },
    {
        "label": "BRE",
        "value": "BRE",
        "iso": "DE"
    },
    {
        "label": "QEF",
        "value": "QEF",
        "iso": "DE"
    },
    {
        "label": "HHN",
        "value": "HHN",
        "iso": "DE"
    },
    {
        "label": "MHG",
        "value": "MHG",
        "iso": "DE"
    },
    {
        "label": "ZQV",
        "value": "ZQV",
        "iso": "DE"
    },
    {
        "label": "QMZ",
        "value": "QMZ",
        "iso": "DE"
    },
    {
        "label": "SGE",
        "value": "SGE",
        "iso": "DE"
    },
    {
        "label": "XFW",
        "value": "XFW",
        "iso": "DE"
    },
    {
        "label": "KEL",
        "value": "KEL",
        "iso": "DE"
    },
    {
        "label": "LBC",
        "value": "LBC",
        "iso": "DE"
    },
    {
        "label": "EUM",
        "value": "EUM",
        "iso": "DE"
    },
    {
        "label": "FMM",
        "value": "FMM",
        "iso": "DE"
    },
    {
        "label": "AAH",
        "value": "AAH",
        "iso": "DE"
    },
    {
        "label": "ESS",
        "value": "ESS",
        "iso": "DE"
    },
    {
        "label": "BFE",
        "value": "BFE",
        "iso": "DE"
    },
    {
        "label": "MGL",
        "value": "MGL",
        "iso": "DE"
    },
    {
        "label": "PAD",
        "value": "PAD",
        "iso": "DE"
    },
    {
        "label": "NRN",
        "value": "NRN",
        "iso": "DE"
    },
    {
        "label": "DTM",
        "value": "DTM",
        "iso": "DE"
    },
    {
        "label": "AGB",
        "value": "AGB",
        "iso": "DE"
    },
    {
        "label": "OBF",
        "value": "OBF",
        "iso": "DE"
    },
    {
        "label": "RBM",
        "value": "RBM",
        "iso": "DE"
    },
    {
        "label": "FDH",
        "value": "FDH",
        "iso": "DE"
    },
    {
        "label": "FRF",
        "value": "FRF",
        "iso": "DE"
    },
    {
        "label": "SZW",
        "value": "SZW",
        "iso": "DE"
    },
    {
        "label": "BYU",
        "value": "BYU",
        "iso": "DE"
    },
    {
        "label": "QOB",
        "value": "QOB",
        "iso": "DE"
    },
    {
        "label": "HOQ",
        "value": "HOQ",
        "iso": "DE"
    },
    {
        "label": "BBJ",
        "value": "BBJ",
        "iso": "DE"
    },
    {
        "label": "ZQW",
        "value": "ZQW",
        "iso": "DE"
    },
    {
        "label": "FKB",
        "value": "FKB",
        "iso": "DE"
    },
    {
        "label": "ZQL",
        "value": "ZQL",
        "iso": "DE"
    },
    {
        "label": "LHA",
        "value": "LHA",
        "iso": "DE"
    },
    {
        "label": "BWE",
        "value": "BWE",
        "iso": "DE"
    },
    {
        "label": "KSF",
        "value": "KSF",
        "iso": "DE"
    },
    {
        "label": "BRV",
        "value": "BRV",
        "iso": "DE"
    },
    {
        "label": "XLW",
        "value": "XLW",
        "iso": "DE"
    },
    {
        "label": "EME",
        "value": "EME",
        "iso": "DE"
    },
    {
        "label": "AGE",
        "value": "AGE",
        "iso": "DE"
    },
    {
        "label": "WVN",
        "value": "WVN",
        "iso": "DE"
    },
    {
        "label": "JUI",
        "value": "JUI",
        "iso": "DE"
    },
    {
        "label": "LGO",
        "value": "LGO",
        "iso": "DE"
    },
    {
        "label": "ZOW",
        "value": "ZOW",
        "iso": "DE"
    },
    {
        "label": "BMK",
        "value": "BMK",
        "iso": "DE"
    },
    {
        "label": "NOD",
        "value": "NOD",
        "iso": "DE"
    },
    {
        "label": "VAC",
        "value": "VAC",
        "iso": "DE"
    },
    {
        "label": "NRD",
        "value": "NRD",
        "iso": "DE"
    },
    {
        "label": "BMR",
        "value": "BMR",
        "iso": "DE"
    },
    {
        "label": "HEI",
        "value": "HEI",
        "iso": "DE"
    },
    {
        "label": "FLF",
        "value": "FLF",
        "iso": "DE"
    },
    {
        "label": "HGL",
        "value": "HGL",
        "iso": "DE"
    },
    {
        "label": "PSH",
        "value": "PSH",
        "iso": "DE"
    },
    {
        "label": "GWT",
        "value": "GWT",
        "iso": "DE"
    },
    {
        "label": "OHR",
        "value": "OHR",
        "iso": "DE"
    },
    {
        "label": "KDL",
        "value": "KDL",
        "iso": "EE"
    },
    {
        "label": "URE",
        "value": "URE",
        "iso": "EE"
    },
    {
        "label": "EPU",
        "value": "EPU",
        "iso": "EE"
    },
    {
        "label": "TLL",
        "value": "TLL",
        "iso": "EE"
    },
    {
        "label": "TAY",
        "value": "TAY",
        "iso": "EE"
    },
    {
        "label": "ENF",
        "value": "ENF",
        "iso": "FI"
    },
    {
        "label": "QVE",
        "value": "QVE",
        "iso": "FI"
    },
    {
        "label": "KEV",
        "value": "KEV",
        "iso": "FI"
    },
    {
        "label": "HEM",
        "value": "HEM",
        "iso": "FI"
    },
    {
        "label": "HEL",
        "value": "HEL",
        "iso": "FI"
    },
    {
        "label": "HYV",
        "value": "HYV",
        "iso": "FI"
    },
    {
        "label": "KTQ",
        "value": "KTQ",
        "iso": "FI"
    },
    {
        "label": "IVL",
        "value": "IVL",
        "iso": "FI"
    },
    {
        "label": "JOE",
        "value": "JOE",
        "iso": "FI"
    },
    {
        "label": "JYV",
        "value": "JYV",
        "iso": "FI"
    },
    {
        "label": "KAU",
        "value": "KAU",
        "iso": "FI"
    },
    {
        "label": "KEM",
        "value": "KEM",
        "iso": "FI"
    },
    {
        "label": "KAJ",
        "value": "KAJ",
        "iso": "FI"
    },
    {
        "label": "KHJ",
        "value": "KHJ",
        "iso": "FI"
    },
    {
        "label": "KOK",
        "value": "KOK",
        "iso": "FI"
    },
    {
        "label": "KAO",
        "value": "KAO",
        "iso": "FI"
    },
    {
        "label": "KTT",
        "value": "KTT",
        "iso": "FI"
    },
    {
        "label": "KUO",
        "value": "KUO",
        "iso": "FI"
    },
    {
        "label": "QLF",
        "value": "QLF",
        "iso": "FI"
    },
    {
        "label": "LPP",
        "value": "LPP",
        "iso": "FI"
    },
    {
        "label": "MHQ",
        "value": "MHQ",
        "iso": "FI"
    },
    {
        "label": "MIK",
        "value": "MIK",
        "iso": "FI"
    },
    {
        "label": "OUL",
        "value": "OUL",
        "iso": "FI"
    },
    {
        "label": "POR",
        "value": "POR",
        "iso": "FI"
    },
    {
        "label": "RVN",
        "value": "RVN",
        "iso": "FI"
    },
    {
        "label": "SVL",
        "value": "SVL",
        "iso": "FI"
    },
    {
        "label": "SJY",
        "value": "SJY",
        "iso": "FI"
    },
    {
        "label": "SOT",
        "value": "SOT",
        "iso": "FI"
    },
    {
        "label": "TMP",
        "value": "TMP",
        "iso": "FI"
    },
    {
        "label": "TKU",
        "value": "TKU",
        "iso": "FI"
    },
    {
        "label": "UTI",
        "value": "UTI",
        "iso": "FI"
    },
    {
        "label": "VAA",
        "value": "VAA",
        "iso": "FI"
    },
    {
        "label": "VRK",
        "value": "VRK",
        "iso": "FI"
    },
    {
        "label": "YLI",
        "value": "YLI",
        "iso": "FI"
    },
    {
        "label": "AUE",
        "value": "AUE",
        "iso": "EG"
    },
    {
        "label": "BFS",
        "value": "BFS",
        "iso": "GB"
    },
    {
        "label": "ENK",
        "value": "ENK",
        "iso": "GB"
    },
    {
        "label": "BHD",
        "value": "BHD",
        "iso": "GB"
    },
    {
        "label": "LDY",
        "value": "LDY",
        "iso": "GB"
    },
    {
        "label": "BHX",
        "value": "BHX",
        "iso": "GB"
    },
    {
        "label": "CVT",
        "value": "CVT",
        "iso": "GB"
    },
    {
        "label": "GLO",
        "value": "GLO",
        "iso": "GB"
    },
    {
        "label": "ORM",
        "value": "ORM",
        "iso": "GB"
    },
    {
        "label": "NQT",
        "value": "NQT",
        "iso": "GB"
    },
    {
        "label": "MAN",
        "value": "MAN",
        "iso": "GB"
    },
    {
        "label": "XXB",
        "value": "XXB",
        "iso": "GB"
    },
    {
        "label": "DSA",
        "value": "DSA",
        "iso": "GB"
    },
    {
        "label": "LYE",
        "value": "LYE",
        "iso": "GB"
    },
    {
        "label": "DGX",
        "value": "DGX",
        "iso": "GB"
    },
    {
        "label": "YEO",
        "value": "YEO",
        "iso": "GB"
    },
    {
        "label": "CAL",
        "value": "CAL",
        "iso": "GB"
    },
    {
        "label": "EOI",
        "value": "EOI",
        "iso": "GB"
    },
    {
        "label": "FIE",
        "value": "FIE",
        "iso": "GB"
    },
    {
        "label": "WHS",
        "value": "WHS",
        "iso": "GB"
    },
    {
        "label": "COL",
        "value": "COL",
        "iso": "GB"
    },
    {
        "label": "NRL",
        "value": "NRL",
        "iso": "GB"
    },
    {
        "label": "OBN",
        "value": "OBN",
        "iso": "GB"
    },
    {
        "label": "PPW",
        "value": "PPW",
        "iso": "GB"
    },
    {
        "label": "SOY",
        "value": "SOY",
        "iso": "GB"
    },
    {
        "label": "NDY",
        "value": "NDY",
        "iso": "GB"
    },
    {
        "label": "LWK",
        "value": "LWK",
        "iso": "GB"
    },
    {
        "label": "WRY",
        "value": "WRY",
        "iso": "GB"
    },
    {
        "label": "CSA",
        "value": "CSA",
        "iso": "GB"
    },
    {
        "label": "HAW",
        "value": "HAW",
        "iso": "GB"
    },
    {
        "label": "CWL",
        "value": "CWL",
        "iso": "GB"
    },
    {
        "label": "SWS",
        "value": "SWS",
        "iso": "GB"
    },
    {
        "label": "BRS",
        "value": "BRS",
        "iso": "GB"
    },
    {
        "label": "LPL",
        "value": "LPL",
        "iso": "GB"
    },
    {
        "label": "LTN",
        "value": "LTN",
        "iso": "GB"
    },
    {
        "label": "LEQ",
        "value": "LEQ",
        "iso": "GB"
    },
    {
        "label": "PLH",
        "value": "PLH",
        "iso": "GB"
    },
    {
        "label": "ISC",
        "value": "ISC",
        "iso": "GB"
    },
    {
        "label": "BOH",
        "value": "BOH",
        "iso": "GB"
    },
    {
        "label": "SOU",
        "value": "SOU",
        "iso": "GB"
    },
    {
        "label": "BBP",
        "value": "BBP",
        "iso": "GB"
    },
    {
        "label": "PZE",
        "value": "PZE",
        "iso": "GB"
    },
    {
        "label": "QLA",
        "value": "QLA",
        "iso": "GB"
    },
    {
        "label": "NQY",
        "value": "NQY",
        "iso": "GB"
    },
    {
        "label": "QUG",
        "value": "QUG",
        "iso": "GB"
    },
    {
        "label": "ACI",
        "value": "ACI",
        "iso": "GG"
    },
    {
        "label": "GCI",
        "value": "GCI",
        "iso": "GG"
    },
    {
        "label": "JER",
        "value": "JER",
        "iso": "JE"
    },
    {
        "label": "ESH",
        "value": "ESH",
        "iso": "GB"
    },
    {
        "label": "BQH",
        "value": "BQH",
        "iso": "GB"
    },
    {
        "label": "LGW",
        "value": "LGW",
        "iso": "GB"
    },
    {
        "label": "KRH",
        "value": "KRH",
        "iso": "GB"
    },
    {
        "label": "LCY",
        "value": "LCY",
        "iso": "GB"
    },
    {
        "label": "FAB",
        "value": "FAB",
        "iso": "GB"
    },
    {
        "label": "BBS",
        "value": "BBS",
        "iso": "GB"
    },
    {
        "label": "LHR",
        "value": "LHR",
        "iso": "GB"
    },
    {
        "label": "SEN",
        "value": "SEN",
        "iso": "GB"
    },
    {
        "label": "LYX",
        "value": "LYX",
        "iso": "GB"
    },
    {
        "label": "MSE",
        "value": "MSE",
        "iso": "GB"
    },
    {
        "label": "CAX",
        "value": "CAX",
        "iso": "GB"
    },
    {
        "label": "BLK",
        "value": "BLK",
        "iso": "GB"
    },
    {
        "label": "HUY",
        "value": "HUY",
        "iso": "GB"
    },
    {
        "label": "BWF",
        "value": "BWF",
        "iso": "GB"
    },
    {
        "label": "LBA",
        "value": "LBA",
        "iso": "GB"
    },
    {
        "label": "CEG",
        "value": "CEG",
        "iso": "GB"
    },
    {
        "label": "IOM",
        "value": "IOM",
        "iso": "IM"
    },
    {
        "label": "NCL",
        "value": "NCL",
        "iso": "GB"
    },
    {
        "label": "MME",
        "value": "MME",
        "iso": "GB"
    },
    {
        "label": "EMA",
        "value": "EMA",
        "iso": "GB"
    },
    {
        "label": "HLY",
        "value": "HLY",
        "iso": "GB"
    },
    {
        "label": "KOI",
        "value": "KOI",
        "iso": "GB"
    },
    {
        "label": "LSI",
        "value": "LSI",
        "iso": "GB"
    },
    {
        "label": "WIC",
        "value": "WIC",
        "iso": "GB"
    },
    {
        "label": "ABZ",
        "value": "ABZ",
        "iso": "GB"
    },
    {
        "label": "INV",
        "value": "INV",
        "iso": "GB"
    },
    {
        "label": "GLA",
        "value": "GLA",
        "iso": "GB"
    },
    {
        "label": "EDI",
        "value": "EDI",
        "iso": "GB"
    },
    {
        "label": "ILY",
        "value": "ILY",
        "iso": "GB"
    },
    {
        "label": "PIK",
        "value": "PIK",
        "iso": "GB"
    },
    {
        "label": "BEB",
        "value": "BEB",
        "iso": "GB"
    },
    {
        "label": "SCS",
        "value": "SCS",
        "iso": "GB"
    },
    {
        "label": "DND",
        "value": "DND",
        "iso": "GB"
    },
    {
        "label": "SYY",
        "value": "SYY",
        "iso": "GB"
    },
    {
        "label": "BRR",
        "value": "BRR",
        "iso": "GB"
    },
    {
        "label": "PSL",
        "value": "PSL",
        "iso": "GB"
    },
    {
        "label": "TRE",
        "value": "TRE",
        "iso": "GB"
    },
    {
        "label": "UNT",
        "value": "UNT",
        "iso": "GB"
    },
    {
        "label": "BOL",
        "value": "BOL",
        "iso": "GB"
    },
    {
        "label": "FSS",
        "value": "FSS",
        "iso": "GB"
    },
    {
        "label": "ADX",
        "value": "ADX",
        "iso": "GB"
    },
    {
        "label": "LMO",
        "value": "LMO",
        "iso": "GB"
    },
    {
        "label": "CBG",
        "value": "CBG",
        "iso": "GB"
    },
    {
        "label": "NWI",
        "value": "NWI",
        "iso": "GB"
    },
    {
        "label": "STN",
        "value": "STN",
        "iso": "GB"
    },
    {
        "label": "QFO",
        "value": "QFO",
        "iso": "GB"
    },
    {
        "label": "SZE",
        "value": "SZE",
        "iso": "GB"
    },
    {
        "label": "EXT",
        "value": "EXT",
        "iso": "GB"
    },
    {
        "label": "FRK",
        "value": "FRK",
        "iso": "GB"
    },
    {
        "label": "FZO",
        "value": "FZO",
        "iso": "GB"
    },
    {
        "label": "OXF",
        "value": "OXF",
        "iso": "GB"
    },
    {
        "label": "RCS",
        "value": "RCS",
        "iso": "GB"
    },
    {
        "label": "BEX",
        "value": "BEX",
        "iso": "GB"
    },
    {
        "label": "LKZ",
        "value": "LKZ",
        "iso": "GB"
    },
    {
        "label": "MHZ",
        "value": "MHZ",
        "iso": "GB"
    },
    {
        "label": "QUY",
        "value": "QUY",
        "iso": "GB"
    },
    {
        "label": "FFD",
        "value": "FFD",
        "iso": "GB"
    },
    {
        "label": "BZZ",
        "value": "BZZ",
        "iso": "GB"
    },
    {
        "label": "ODH",
        "value": "ODH",
        "iso": "GB"
    },
    {
        "label": "WXF",
        "value": "WXF",
        "iso": "GB"
    },
    {
        "label": "ADV",
        "value": "ADV",
        "iso": "GB"
    },
    {
        "label": "NHT",
        "value": "NHT",
        "iso": "GB"
    },
    {
        "label": "GSY",
        "value": "GSY",
        "iso": "GB"
    },
    {
        "label": "QCY",
        "value": "QCY",
        "iso": "GB"
    },
    {
        "label": "BEQ",
        "value": "BEQ",
        "iso": "GB"
    },
    {
        "label": "OKH",
        "value": "OKH",
        "iso": "GB"
    },
    {
        "label": "SQZ",
        "value": "SQZ",
        "iso": "GB"
    },
    {
        "label": "HRT",
        "value": "HRT",
        "iso": "GB"
    },
    {
        "label": "WTN",
        "value": "WTN",
        "iso": "GB"
    },
    {
        "label": "MRH",
        "value": "MRH",
        "iso": "GB"
    },
    {
        "label": "MPN",
        "value": "MPN",
        "iso": "FK"
    },
    {
        "label": "AMS",
        "value": "AMS",
        "iso": "NL"
    },
    {
        "label": "MST",
        "value": "MST",
        "iso": "NL"
    },
    {
        "label": "QAR",
        "value": "QAR",
        "iso": "NL"
    },
    {
        "label": "EIN",
        "value": "EIN",
        "iso": "NL"
    },
    {
        "label": "GRQ",
        "value": "GRQ",
        "iso": "NL"
    },
    {
        "label": "GLZ",
        "value": "GLZ",
        "iso": "NL"
    },
    {
        "label": "DHR",
        "value": "DHR",
        "iso": "NL"
    },
    {
        "label": "LEY",
        "value": "LEY",
        "iso": "NL"
    },
    {
        "label": "LWR",
        "value": "LWR",
        "iso": "NL"
    },
    {
        "label": "RTM",
        "value": "RTM",
        "iso": "NL"
    },
    {
        "label": "UTC",
        "value": "UTC",
        "iso": "NL"
    },
    {
        "label": "ENS",
        "value": "ENS",
        "iso": "NL"
    },
    {
        "label": "LID",
        "value": "LID",
        "iso": "NL"
    },
    {
        "label": "UDE",
        "value": "UDE",
        "iso": "NL"
    },
    {
        "label": "WOE",
        "value": "WOE",
        "iso": "NL"
    },
    {
        "label": "BYT",
        "value": "BYT",
        "iso": "IE"
    },
    {
        "label": "BLY",
        "value": "BLY",
        "iso": "IE"
    },
    {
        "label": "NNR",
        "value": "NNR",
        "iso": "IE"
    },
    {
        "label": "CLB",
        "value": "CLB",
        "iso": "IE"
    },
    {
        "label": "WEX",
        "value": "WEX",
        "iso": "IE"
    },
    {
        "label": "ORK",
        "value": "ORK",
        "iso": "IE"
    },
    {
        "label": "GWY",
        "value": "GWY",
        "iso": "IE"
    },
    {
        "label": "CFN",
        "value": "CFN",
        "iso": "IE"
    },
    {
        "label": "DUB",
        "value": "DUB",
        "iso": "IE"
    },
    {
        "label": "IOR",
        "value": "IOR",
        "iso": "IE"
    },
    {
        "label": "INQ",
        "value": "INQ",
        "iso": "IE"
    },
    {
        "label": "KKY",
        "value": "KKY",
        "iso": "IE"
    },
    {
        "label": "NOC",
        "value": "NOC",
        "iso": "IE"
    },
    {
        "label": "KIR",
        "value": "KIR",
        "iso": "IE"
    },
    {
        "label": "LTR",
        "value": "LTR",
        "iso": "IE"
    },
    {
        "label": "IIA",
        "value": "IIA",
        "iso": "IE"
    },
    {
        "label": "SNN",
        "value": "SNN",
        "iso": "IE"
    },
    {
        "label": "SXL",
        "value": "SXL",
        "iso": "IE"
    },
    {
        "label": "WAT",
        "value": "WAT",
        "iso": "IE"
    },
    {
        "label": "AAR",
        "value": "AAR",
        "iso": "DK"
    },
    {
        "label": "BLL",
        "value": "BLL",
        "iso": "DK"
    },
    {
        "label": "CPH",
        "value": "CPH",
        "iso": "DK"
    },
    {
        "label": "EBJ",
        "value": "EBJ",
        "iso": "DK"
    },
    {
        "label": "KRP",
        "value": "KRP",
        "iso": "DK"
    },
    {
        "label": "BYR",
        "value": "BYR",
        "iso": "DK"
    },
    {
        "label": "MRW",
        "value": "MRW",
        "iso": "DK"
    },
    {
        "label": "ODE",
        "value": "ODE",
        "iso": "DK"
    },
    {
        "label": "RKE",
        "value": "RKE",
        "iso": "DK"
    },
    {
        "label": "RNN",
        "value": "RNN",
        "iso": "DK"
    },
    {
        "label": "SGD",
        "value": "SGD",
        "iso": "DK"
    },
    {
        "label": "CNL",
        "value": "CNL",
        "iso": "DK"
    },
    {
        "label": "SKS",
        "value": "SKS",
        "iso": "DK"
    },
    {
        "label": "SQW",
        "value": "SQW",
        "iso": "DK"
    },
    {
        "label": "TED",
        "value": "TED",
        "iso": "DK"
    },
    {
        "label": "VDP",
        "value": "VDP",
        "iso": "DK"
    },
    {
        "label": "FAE",
        "value": "FAE",
        "iso": "FO"
    },
    {
        "label": "STA",
        "value": "STA",
        "iso": "DK"
    },
    {
        "label": "AAL",
        "value": "AAL",
        "iso": "DK"
    },
    {
        "label": "LUX",
        "value": "LUX",
        "iso": "LU"
    },
    {
        "label": "AES",
        "value": "AES",
        "iso": "NO"
    },
    {
        "label": "ANX",
        "value": "ANX",
        "iso": "NO"
    },
    {
        "label": "ALF",
        "value": "ALF",
        "iso": "NO"
    },
    {
        "label": "FDE",
        "value": "FDE",
        "iso": "NO"
    },
    {
        "label": "BNN",
        "value": "BNN",
        "iso": "NO"
    },
    {
        "label": "BOO",
        "value": "BOO",
        "iso": "NO"
    },
    {
        "label": "BGO",
        "value": "BGO",
        "iso": "NO"
    },
    {
        "label": "BJF",
        "value": "BJF",
        "iso": "NO"
    },
    {
        "label": "BVG",
        "value": "BVG",
        "iso": "NO"
    },
    {
        "label": "KRS",
        "value": "KRS",
        "iso": "NO"
    },
    {
        "label": "DLD",
        "value": "DLD",
        "iso": "NO"
    },
    {
        "label": "BDU",
        "value": "BDU",
        "iso": "NO"
    },
    {
        "label": "EVE",
        "value": "EVE",
        "iso": "NO"
    },
    {
        "label": "FBU",
        "value": "FBU",
        "iso": "NO"
    },
    {
        "label": "VDB",
        "value": "VDB",
        "iso": "NO"
    },
    {
        "label": "FRO",
        "value": "FRO",
        "iso": "NO"
    },
    {
        "label": "OSL",
        "value": "OSL",
        "iso": "NO"
    },
    {
        "label": "HMR",
        "value": "HMR",
        "iso": "NO"
    },
    {
        "label": "HAU",
        "value": "HAU",
        "iso": "NO"
    },
    {
        "label": "HFT",
        "value": "HFT",
        "iso": "NO"
    },
    {
        "label": "HAA",
        "value": "HAA",
        "iso": "NO"
    },
    {
        "label": "HVG",
        "value": "HVG",
        "iso": "NO"
    },
    {
        "label": "QKX",
        "value": "QKX",
        "iso": "NO"
    },
    {
        "label": "KSU",
        "value": "KSU",
        "iso": "NO"
    },
    {
        "label": "GLL",
        "value": "GLL",
        "iso": "NO"
    },
    {
        "label": "KKN",
        "value": "KKN",
        "iso": "NO"
    },
    {
        "label": "FAN",
        "value": "FAN",
        "iso": "NO"
    },
    {
        "label": "LKN",
        "value": "LKN",
        "iso": "NO"
    },
    {
        "label": "MEH",
        "value": "MEH",
        "iso": "NO"
    },
    {
        "label": "MOL",
        "value": "MOL",
        "iso": "NO"
    },
    {
        "label": "MJF",
        "value": "MJF",
        "iso": "NO"
    },
    {
        "label": "LKL",
        "value": "LKL",
        "iso": "NO"
    },
    {
        "label": "NVK",
        "value": "NVK",
        "iso": "NO"
    },
    {
        "label": "OSY",
        "value": "OSY",
        "iso": "NO"
    },
    {
        "label": "NTB",
        "value": "NTB",
        "iso": "NO"
    },
    {
        "label": "OLA",
        "value": "OLA",
        "iso": "NO"
    },
    {
        "label": "HOV",
        "value": "HOV",
        "iso": "NO"
    },
    {
        "label": "MQN",
        "value": "MQN",
        "iso": "NO"
    },
    {
        "label": "RVK",
        "value": "RVK",
        "iso": "NO"
    },
    {
        "label": "RRS",
        "value": "RRS",
        "iso": "NO"
    },
    {
        "label": "RET",
        "value": "RET",
        "iso": "NO"
    },
    {
        "label": "RYG",
        "value": "RYG",
        "iso": "NO"
    },
    {
        "label": "LYR",
        "value": "LYR",
        "iso": "NO"
    },
    {
        "label": "SDN",
        "value": "SDN",
        "iso": "NO"
    },
    {
        "label": "SOG",
        "value": "SOG",
        "iso": "NO"
    },
    {
        "label": "SVJ",
        "value": "SVJ",
        "iso": "NO"
    },
    {
        "label": "SKN",
        "value": "SKN",
        "iso": "NO"
    },
    {
        "label": "SKE",
        "value": "SKE",
        "iso": "NO"
    },
    {
        "label": "SRP",
        "value": "SRP",
        "iso": "NO"
    },
    {
        "label": "SOJ",
        "value": "SOJ",
        "iso": "NO"
    },
    {
        "label": "VAW",
        "value": "VAW",
        "iso": "NO"
    },
    {
        "label": "SSJ",
        "value": "SSJ",
        "iso": "NO"
    },
    {
        "label": "TOS",
        "value": "TOS",
        "iso": "NO"
    },
    {
        "label": "TRF",
        "value": "TRF",
        "iso": "NO"
    },
    {
        "label": "TRD",
        "value": "TRD",
        "iso": "NO"
    },
    {
        "label": "VDS",
        "value": "VDS",
        "iso": "NO"
    },
    {
        "label": "VRY",
        "value": "VRY",
        "iso": "NO"
    },
    {
        "label": "SVG",
        "value": "SVG",
        "iso": "NO"
    },
    {
        "label": "QYY",
        "value": "QYY",
        "iso": "PL"
    },
    {
        "label": "BZG",
        "value": "BZG",
        "iso": "PL"
    },
    {
        "label": "CZW",
        "value": "CZW",
        "iso": "PL"
    },
    {
        "label": "GDN",
        "value": "GDN",
        "iso": "PL"
    },
    {
        "label": "QLC",
        "value": "QLC",
        "iso": "PL"
    },
    {
        "label": "KRK",
        "value": "KRK",
        "iso": "PL"
    },
    {
        "label": "OSZ",
        "value": "OSZ",
        "iso": "PL"
    },
    {
        "label": "KTW",
        "value": "KTW",
        "iso": "PL"
    },
    {
        "label": "QEO",
        "value": "QEO",
        "iso": "PL"
    },
    {
        "label": "LCJ",
        "value": "LCJ",
        "iso": "PL"
    },
    {
        "label": "QLU",
        "value": "QLU",
        "iso": "PL"
    },
    {
        "label": "QWS",
        "value": "QWS",
        "iso": "PL"
    },
    {
        "label": "QYD",
        "value": "QYD",
        "iso": "PL"
    },
    {
        "label": "QPM",
        "value": "QPM",
        "iso": "PL"
    },
    {
        "label": "POZ",
        "value": "POZ",
        "iso": "PL"
    },
    {
        "label": "RZE",
        "value": "RZE",
        "iso": "PL"
    },
    {
        "label": "SZZ",
        "value": "SZZ",
        "iso": "PL"
    },
    {
        "label": "OSP",
        "value": "OSP",
        "iso": "PL"
    },
    {
        "label": "SZY",
        "value": "SZY",
        "iso": "PL"
    },
    {
        "label": "WAW",
        "value": "WAW",
        "iso": "PL"
    },
    {
        "label": "WRO",
        "value": "WRO",
        "iso": "PL"
    },
    {
        "label": "IEG",
        "value": "IEG",
        "iso": "PL"
    },
    {
        "label": "RNB",
        "value": "RNB",
        "iso": "SE"
    },
    {
        "label": "XWP",
        "value": "XWP",
        "iso": "SE"
    },
    {
        "label": "GOT",
        "value": "GOT",
        "iso": "SE"
    },
    {
        "label": "JKG",
        "value": "JKG",
        "iso": "SE"
    },
    {
        "label": "LDK",
        "value": "LDK",
        "iso": "SE"
    },
    {
        "label": "GSE",
        "value": "GSE",
        "iso": "SE"
    },
    {
        "label": "KVB",
        "value": "KVB",
        "iso": "SE"
    },
    {
        "label": "THN",
        "value": "THN",
        "iso": "SE"
    },
    {
        "label": "KSK",
        "value": "KSK",
        "iso": "SE"
    },
    {
        "label": "MXX",
        "value": "MXX",
        "iso": "SE"
    },
    {
        "label": "NYO",
        "value": "NYO",
        "iso": "SE"
    },
    {
        "label": "KID",
        "value": "KID",
        "iso": "SE"
    },
    {
        "label": "OSK",
        "value": "OSK",
        "iso": "SE"
    },
    {
        "label": "KLR",
        "value": "KLR",
        "iso": "SE"
    },
    {
        "label": "MMX",
        "value": "MMX",
        "iso": "SE"
    },
    {
        "label": "HAD",
        "value": "HAD",
        "iso": "SE"
    },
    {
        "label": "VXO",
        "value": "VXO",
        "iso": "SE"
    },
    {
        "label": "EVG",
        "value": "EVG",
        "iso": "SE"
    },
    {
        "label": "GEV",
        "value": "GEV",
        "iso": "SE"
    },
    {
        "label": "HUV",
        "value": "HUV",
        "iso": "SE"
    },
    {
        "label": "KRF",
        "value": "KRF",
        "iso": "SE"
    },
    {
        "label": "LYC",
        "value": "LYC",
        "iso": "SE"
    },
    {
        "label": "SDL",
        "value": "SDL",
        "iso": "SE"
    },
    {
        "label": "OER",
        "value": "OER",
        "iso": "SE"
    },
    {
        "label": "KRN",
        "value": "KRN",
        "iso": "SE"
    },
    {
        "label": "SFT",
        "value": "SFT",
        "iso": "SE"
    },
    {
        "label": "UME",
        "value": "UME",
        "iso": "SE"
    },
    {
        "label": "VHM",
        "value": "VHM",
        "iso": "SE"
    },
    {
        "label": "AJR",
        "value": "AJR",
        "iso": "SE"
    },
    {
        "label": "SOO",
        "value": "SOO",
        "iso": "SE"
    },
    {
        "label": "OSD",
        "value": "OSD",
        "iso": "SE"
    },
    {
        "label": "ORB",
        "value": "ORB",
        "iso": "SE"
    },
    {
        "label": "HFS",
        "value": "HFS",
        "iso": "SE"
    },
    {
        "label": "KSD",
        "value": "KSD",
        "iso": "SE"
    },
    {
        "label": "VST",
        "value": "VST",
        "iso": "SE"
    },
    {
        "label": "LLA",
        "value": "LLA",
        "iso": "SE"
    },
    {
        "label": "ARN",
        "value": "ARN",
        "iso": "SE"
    },
    {
        "label": "BMA",
        "value": "BMA",
        "iso": "SE"
    },
    {
        "label": "BLE",
        "value": "BLE",
        "iso": "SE"
    },
    {
        "label": "HLF",
        "value": "HLF",
        "iso": "SE"
    },
    {
        "label": "GVX",
        "value": "GVX",
        "iso": "SE"
    },
    {
        "label": "LPI",
        "value": "LPI",
        "iso": "SE"
    },
    {
        "label": "NRK",
        "value": "NRK",
        "iso": "SE"
    },
    {
        "label": "TYF",
        "value": "TYF",
        "iso": "SE"
    },
    {
        "label": "EKT",
        "value": "EKT",
        "iso": "SE"
    },
    {
        "label": "VBY",
        "value": "VBY",
        "iso": "SE"
    },
    {
        "label": "VVK",
        "value": "VVK",
        "iso": "SE"
    },
    {
        "label": "AGH",
        "value": "AGH",
        "iso": "SE"
    },
    {
        "label": "SQO",
        "value": "SQO",
        "iso": "SE"
    },
    {
        "label": "IDB",
        "value": "IDB",
        "iso": "SE"
    },
    {
        "label": "PJA",
        "value": "PJA",
        "iso": "SE"
    },
    {
        "label": "HMV",
        "value": "HMV",
        "iso": "SE"
    },
    {
        "label": "GLC",
        "value": "GLC",
        "iso": "ET"
    },
    {
        "label": "SHC",
        "value": "SHC",
        "iso": "ET"
    },
    {
        "label": "SPM",
        "value": "SPM",
        "iso": "DE"
    },
    {
        "label": "RMS",
        "value": "RMS",
        "iso": "DE"
    },
    {
        "label": "ZCD",
        "value": "ZCD",
        "iso": "DE"
    },
    {
        "label": "ZCN",
        "value": "ZCN",
        "iso": "DE"
    },
    {
        "label": "ZPQ",
        "value": "ZPQ",
        "iso": "DE"
    },
    {
        "label": "FRZ",
        "value": "FRZ",
        "iso": "DE"
    },
    {
        "label": "ZNF",
        "value": "ZNF",
        "iso": "DE"
    },
    {
        "label": "QHD",
        "value": "QHD",
        "iso": "DE"
    },
    {
        "label": "NDZ",
        "value": "NDZ",
        "iso": "DE"
    },
    {
        "label": "GKE",
        "value": "GKE",
        "iso": "DE"
    },
    {
        "label": "RLG",
        "value": "RLG",
        "iso": "DE"
    },
    {
        "label": "QOE",
        "value": "QOE",
        "iso": "DE"
    },
    {
        "label": "WBG",
        "value": "WBG",
        "iso": "DE"
    },
    {
        "label": "FNB",
        "value": "FNB",
        "iso": "DE"
    },
    {
        "label": "WIE",
        "value": "WIE",
        "iso": "DE"
    },
    {
        "label": "FEL",
        "value": "FEL",
        "iso": "DE"
    },
    {
        "label": "IGS",
        "value": "IGS",
        "iso": "DE"
    },
    {
        "label": "GUT",
        "value": "GUT",
        "iso": "DE"
    },
    {
        "label": "BGN",
        "value": "BGN",
        "iso": "DE"
    },
    {
        "label": "LPX",
        "value": "LPX",
        "iso": "LV"
    },
    {
        "label": "RIX",
        "value": "RIX",
        "iso": "LV"
    },
    {
        "label": "VNT",
        "value": "VNT",
        "iso": "LV"
    },
    {
        "label": "EXI",
        "value": "EXI",
        "iso": "US"
    },
    {
        "label": "KUN",
        "value": "KUN",
        "iso": "LT"
    },
    {
        "label": "KLJ",
        "value": "KLJ",
        "iso": "LT"
    },
    {
        "label": "PLQ",
        "value": "PLQ",
        "iso": "LT"
    },
    {
        "label": "PNV",
        "value": "PNV",
        "iso": "LT"
    },
    {
        "label": "SQQ",
        "value": "SQQ",
        "iso": "LT"
    },
    {
        "label": "HLJ",
        "value": "HLJ",
        "iso": "LT"
    },
    {
        "label": "VNO",
        "value": "VNO",
        "iso": "LT"
    },
    {
        "label": "ALJ",
        "value": "ALJ",
        "iso": "ZA"
    },
    {
        "label": "AGZ",
        "value": "AGZ",
        "iso": "ZA"
    },
    {
        "label": "ADY",
        "value": "ADY",
        "iso": "ZA"
    },
    {
        "label": "BIY",
        "value": "BIY",
        "iso": "ZA"
    },
    {
        "label": "BFN",
        "value": "BFN",
        "iso": "ZA"
    },
    {
        "label": "UTE",
        "value": "UTE",
        "iso": "ZA"
    },
    {
        "label": "CDO",
        "value": "CDO",
        "iso": "ZA"
    },
    {
        "label": "CPT",
        "value": "CPT",
        "iso": "ZA"
    },
    {
        "label": "DUK",
        "value": "DUK",
        "iso": "ZA"
    },
    {
        "label": "PZL",
        "value": "PZL",
        "iso": "ZA"
    },
    {
        "label": "ELS",
        "value": "ELS",
        "iso": "ZA"
    },
    {
        "label": "EMG",
        "value": "EMG",
        "iso": "ZA"
    },
    {
        "label": "ELL",
        "value": "ELL",
        "iso": "ZA"
    },
    {
        "label": "FCB",
        "value": "FCB",
        "iso": "ZA"
    },
    {
        "label": "GCJ",
        "value": "GCJ",
        "iso": "ZA"
    },
    {
        "label": "GRJ",
        "value": "GRJ",
        "iso": "ZA"
    },
    {
        "label": "GIY",
        "value": "GIY",
        "iso": "ZA"
    },
    {
        "label": "QRA",
        "value": "QRA",
        "iso": "ZA"
    },
    {
        "label": "HLW",
        "value": "HLW",
        "iso": "ZA"
    },
    {
        "label": "HRS",
        "value": "HRS",
        "iso": "ZA"
    },
    {
        "label": "HDS",
        "value": "HDS",
        "iso": "ZA"
    },
    {
        "label": "JNB",
        "value": "JNB",
        "iso": "ZA"
    },
    {
        "label": "KXE",
        "value": "KXE",
        "iso": "ZA"
    },
    {
        "label": "KIM",
        "value": "KIM",
        "iso": "ZA"
    },
    {
        "label": "MQP",
        "value": "MQP",
        "iso": "ZA"
    },
    {
        "label": "KOF",
        "value": "KOF",
        "iso": "ZA"
    },
    {
        "label": "KMH",
        "value": "KMH",
        "iso": "ZA"
    },
    {
        "label": "KLZ",
        "value": "KLZ",
        "iso": "ZA"
    },
    {
        "label": "HLA",
        "value": "HLA",
        "iso": "ZA"
    },
    {
        "label": "LMR",
        "value": "LMR",
        "iso": "ZA"
    },
    {
        "label": "LDZ",
        "value": "LDZ",
        "iso": "ZA"
    },
    {
        "label": "DUR",
        "value": "DUR",
        "iso": "ZA"
    },
    {
        "label": "LUJ",
        "value": "LUJ",
        "iso": "ZA"
    },
    {
        "label": "LCD",
        "value": "LCD",
        "iso": "ZA"
    },
    {
        "label": "LGE",
        "value": "LGE",
        "iso": "ZA"
    },
    {
        "label": "LAY",
        "value": "LAY",
        "iso": "ZA"
    },
    {
        "label": "AAM",
        "value": "AAM",
        "iso": "ZA"
    },
    {
        "label": "MGH",
        "value": "MGH",
        "iso": "ZA"
    },
    {
        "label": "MBD",
        "value": "MBD",
        "iso": "ZA"
    },
    {
        "label": "LLE",
        "value": "LLE",
        "iso": "ZA"
    },
    {
        "label": "MZY",
        "value": "MZY",
        "iso": "ZA"
    },
    {
        "label": "MEZ",
        "value": "MEZ",
        "iso": "ZA"
    },
    {
        "label": "MZQ",
        "value": "MZQ",
        "iso": "ZA"
    },
    {
        "label": "NCS",
        "value": "NCS",
        "iso": "ZA"
    },
    {
        "label": "NGL",
        "value": "NGL",
        "iso": "ZA"
    },
    {
        "label": "NLP",
        "value": "NLP",
        "iso": "ZA"
    },
    {
        "label": "OVG",
        "value": "OVG",
        "iso": "ZA"
    },
    {
        "label": "OUH",
        "value": "OUH",
        "iso": "ZA"
    },
    {
        "label": "AFD",
        "value": "AFD",
        "iso": "ZA"
    },
    {
        "label": "PLZ",
        "value": "PLZ",
        "iso": "ZA"
    },
    {
        "label": "PBZ",
        "value": "PBZ",
        "iso": "ZA"
    },
    {
        "label": "PHW",
        "value": "PHW",
        "iso": "ZA"
    },
    {
        "label": "PTG",
        "value": "PTG",
        "iso": "ZA"
    },
    {
        "label": "JOH",
        "value": "JOH",
        "iso": "ZA"
    },
    {
        "label": "PRK",
        "value": "PRK",
        "iso": "ZA"
    },
    {
        "label": "PZB",
        "value": "PZB",
        "iso": "ZA"
    },
    {
        "label": "NTY",
        "value": "NTY",
        "iso": "ZA"
    },
    {
        "label": "PTG",
        "value": "PTG",
        "iso": "ZA"
    },
    {
        "label": "PCF",
        "value": "PCF",
        "iso": "ZA"
    },
    {
        "label": "UTW",
        "value": "UTW",
        "iso": "ZA"
    },
    {
        "label": "RCB",
        "value": "RCB",
        "iso": "ZA"
    },
    {
        "label": "RVO",
        "value": "RVO",
        "iso": "ZA"
    },
    {
        "label": "ROD",
        "value": "ROD",
        "iso": "ZA"
    },
    {
        "label": "SBU",
        "value": "SBU",
        "iso": "ZA"
    },
    {
        "label": "ZEC",
        "value": "ZEC",
        "iso": "ZA"
    },
    {
        "label": "SDB",
        "value": "SDB",
        "iso": "ZA"
    },
    {
        "label": "GSS",
        "value": "GSS",
        "iso": "ZA"
    },
    {
        "label": "SIS",
        "value": "SIS",
        "iso": "ZA"
    },
    {
        "label": "SZK",
        "value": "SZK",
        "iso": "ZA"
    },
    {
        "label": "THY",
        "value": "THY",
        "iso": "ZA"
    },
    {
        "label": "TCU",
        "value": "TCU",
        "iso": "ZA"
    },
    {
        "label": "LTA",
        "value": "LTA",
        "iso": "ZA"
    },
    {
        "label": "ULD",
        "value": "ULD",
        "iso": "ZA"
    },
    {
        "label": "UTN",
        "value": "UTN",
        "iso": "ZA"
    },
    {
        "label": "UTT",
        "value": "UTT",
        "iso": "ZA"
    },
    {
        "label": "VRU",
        "value": "VRU",
        "iso": "ZA"
    },
    {
        "label": "VIR",
        "value": "VIR",
        "iso": "ZA"
    },
    {
        "label": "VRE",
        "value": "VRE",
        "iso": "ZA"
    },
    {
        "label": "VYD",
        "value": "VYD",
        "iso": "ZA"
    },
    {
        "label": "PRY",
        "value": "PRY",
        "iso": "ZA"
    },
    {
        "label": "AFB",
        "value": "AFB",
        "iso": "ZA"
    },
    {
        "label": "WEL",
        "value": "WEL",
        "iso": "ZA"
    },
    {
        "label": "FRW",
        "value": "FRW",
        "iso": "BW"
    },
    {
        "label": "GNZ",
        "value": "GNZ",
        "iso": "BW"
    },
    {
        "label": "JWA",
        "value": "JWA",
        "iso": "BW"
    },
    {
        "label": "BBK",
        "value": "BBK",
        "iso": "BW"
    },
    {
        "label": "KHW",
        "value": "KHW",
        "iso": "BW"
    },
    {
        "label": "MUB",
        "value": "MUB",
        "iso": "BW"
    },
    {
        "label": "ORP",
        "value": "ORP",
        "iso": "BW"
    },
    {
        "label": "QPH",
        "value": "QPH",
        "iso": "BW"
    },
    {
        "label": "GBE",
        "value": "GBE",
        "iso": "BW"
    },
    {
        "label": "SXN",
        "value": "SXN",
        "iso": "BW"
    },
    {
        "label": "PKW",
        "value": "PKW",
        "iso": "BW"
    },
    {
        "label": "SWX",
        "value": "SWX",
        "iso": "BW"
    },
    {
        "label": "TLD",
        "value": "TLD",
        "iso": "BW"
    },
    {
        "label": "TBY",
        "value": "TBY",
        "iso": "BW"
    },
    {
        "label": "BZV",
        "value": "BZV",
        "iso": "CG"
    },
    {
        "label": "DJM",
        "value": "DJM",
        "iso": "CG"
    },
    {
        "label": "KNJ",
        "value": "KNJ",
        "iso": "CG"
    },
    {
        "label": "LCO",
        "value": "LCO",
        "iso": "CG"
    },
    {
        "label": "MUY",
        "value": "MUY",
        "iso": "CG"
    },
    {
        "label": "SIB",
        "value": "SIB",
        "iso": "CG"
    },
    {
        "label": "NKY",
        "value": "NKY",
        "iso": "CG"
    },
    {
        "label": "ANJ",
        "value": "ANJ",
        "iso": "CG"
    },
    {
        "label": "MSX",
        "value": "MSX",
        "iso": "CG"
    },
    {
        "label": "BOE",
        "value": "BOE",
        "iso": "CG"
    },
    {
        "label": "EWO",
        "value": "EWO",
        "iso": "CG"
    },
    {
        "label": "GMM",
        "value": "GMM",
        "iso": "CG"
    },
    {
        "label": "ION",
        "value": "ION",
        "iso": "CG"
    },
    {
        "label": "KEE",
        "value": "KEE",
        "iso": "CG"
    },
    {
        "label": "MKJ",
        "value": "MKJ",
        "iso": "CG"
    },
    {
        "label": "FTX",
        "value": "FTX",
        "iso": "CG"
    },
    {
        "label": "SOE",
        "value": "SOE",
        "iso": "CG"
    },
    {
        "label": "BTB",
        "value": "BTB",
        "iso": "CG"
    },
    {
        "label": "OUE",
        "value": "OUE",
        "iso": "CG"
    },
    {
        "label": "KMK",
        "value": "KMK",
        "iso": "CG"
    },
    {
        "label": "DIS",
        "value": "DIS",
        "iso": "CG"
    },
    {
        "label": "PNR",
        "value": "PNR",
        "iso": "CG"
    },
    {
        "label": "MTS",
        "value": "MTS",
        "iso": "SZ"
    },
    {
        "label": "FEA",
        "value": "FEA",
        "iso": "GB"
    },
    {
        "label": "CRF",
        "value": "CRF",
        "iso": "CF"
    },
    {
        "label": "BGF",
        "value": "BGF",
        "iso": "CF"
    },
    {
        "label": "BGU",
        "value": "BGU",
        "iso": "CF"
    },
    {
        "label": "IRO",
        "value": "IRO",
        "iso": "CF"
    },
    {
        "label": "BEM",
        "value": "BEM",
        "iso": "CF"
    },
    {
        "label": "BBY",
        "value": "BBY",
        "iso": "CF"
    },
    {
        "label": "NDL",
        "value": "NDL",
        "iso": "CF"
    },
    {
        "label": "BOP",
        "value": "BOP",
        "iso": "CF"
    },
    {
        "label": "BIV",
        "value": "BIV",
        "iso": "CF"
    },
    {
        "label": "BSN",
        "value": "BSN",
        "iso": "CF"
    },
    {
        "label": "BBT",
        "value": "BBT",
        "iso": "CF"
    },
    {
        "label": "ODA",
        "value": "ODA",
        "iso": "CF"
    },
    {
        "label": "AIG",
        "value": "AIG",
        "iso": "CF"
    },
    {
        "label": "IMO",
        "value": "IMO",
        "iso": "CF"
    },
    {
        "label": "MKI",
        "value": "MKI",
        "iso": "CF"
    },
    {
        "label": "BTG",
        "value": "BTG",
        "iso": "CF"
    },
    {
        "label": "GDI",
        "value": "GDI",
        "iso": "CF"
    },
    {
        "label": "BMF",
        "value": "BMF",
        "iso": "CF"
    },
    {
        "label": "ODJ",
        "value": "ODJ",
        "iso": "CF"
    },
    {
        "label": "RFA",
        "value": "RFA",
        "iso": "CF"
    },
    {
        "label": "BCF",
        "value": "BCF",
        "iso": "CF"
    },
    {
        "label": "BOZ",
        "value": "BOZ",
        "iso": "CF"
    },
    {
        "label": "FEW",
        "value": "FEW",
        "iso": "US"
    },
    {
        "label": "BSG",
        "value": "BSG",
        "iso": "GQ"
    },
    {
        "label": "SSG",
        "value": "SSG",
        "iso": "GQ"
    },
    {
        "label": "ASI",
        "value": "ASI",
        "iso": "SH"
    },
    {
        "label": "MRU",
        "value": "MRU",
        "iso": "MU"
    },
    {
        "label": "RRG",
        "value": "RRG",
        "iso": "MU"
    },
    {
        "label": "FIN",
        "value": "FIN",
        "iso": "PG"
    },
    {
        "label": "NKW",
        "value": "NKW",
        "iso": "IO"
    },
    {
        "label": "NKS",
        "value": "NKS",
        "iso": "CM"
    },
    {
        "label": "KBI",
        "value": "KBI",
        "iso": "CM"
    },
    {
        "label": "TKC",
        "value": "TKC",
        "iso": "CM"
    },
    {
        "label": "DLA",
        "value": "DLA",
        "iso": "CM"
    },
    {
        "label": "MMF",
        "value": "MMF",
        "iso": "CM"
    },
    {
        "label": "KLE",
        "value": "KLE",
        "iso": "CM"
    },
    {
        "label": "OUR",
        "value": "OUR",
        "iso": "CM"
    },
    {
        "label": "GXX",
        "value": "GXX",
        "iso": "CM"
    },
    {
        "label": "MVR",
        "value": "MVR",
        "iso": "CM"
    },
    {
        "label": "FOM",
        "value": "FOM",
        "iso": "CM"
    },
    {
        "label": "NGE",
        "value": "NGE",
        "iso": "CM"
    },
    {
        "label": "BTA",
        "value": "BTA",
        "iso": "CM"
    },
    {
        "label": "GOU",
        "value": "GOU",
        "iso": "CM"
    },
    {
        "label": "DSC",
        "value": "DSC",
        "iso": "CM"
    },
    {
        "label": "BFX",
        "value": "BFX",
        "iso": "CM"
    },
    {
        "label": "BPC",
        "value": "BPC",
        "iso": "CM"
    },
    {
        "label": "EBW",
        "value": "EBW",
        "iso": "CM"
    },
    {
        "label": "YAO",
        "value": "YAO",
        "iso": "CM"
    },
    {
        "label": "NSI",
        "value": "NSI",
        "iso": "CM"
    },
    {
        "label": "MMQ",
        "value": "MMQ",
        "iso": "ZM"
    },
    {
        "label": "CIP",
        "value": "CIP",
        "iso": "ZM"
    },
    {
        "label": "ZKP",
        "value": "ZKP",
        "iso": "ZM"
    },
    {
        "label": "KLB",
        "value": "KLB",
        "iso": "ZM"
    },
    {
        "label": "KMZ",
        "value": "KMZ",
        "iso": "ZM"
    },
    {
        "label": "KAA",
        "value": "KAA",
        "iso": "ZM"
    },
    {
        "label": "ZKB",
        "value": "ZKB",
        "iso": "ZM"
    },
    {
        "label": "LVI",
        "value": "LVI",
        "iso": "ZM"
    },
    {
        "label": "LXU",
        "value": "LXU",
        "iso": "ZM"
    },
    {
        "label": "LUN",
        "value": "LUN",
        "iso": "ZM"
    },
    {
        "label": "MNS",
        "value": "MNS",
        "iso": "ZM"
    },
    {
        "label": "MFU",
        "value": "MFU",
        "iso": "ZM"
    },
    {
        "label": "MNR",
        "value": "MNR",
        "iso": "ZM"
    },
    {
        "label": "ZGM",
        "value": "ZGM",
        "iso": "ZM"
    },
    {
        "label": "NLA",
        "value": "NLA",
        "iso": "ZM"
    },
    {
        "label": "SXG",
        "value": "SXG",
        "iso": "ZM"
    },
    {
        "label": "KIW",
        "value": "KIW",
        "iso": "ZM"
    },
    {
        "label": "SJQ",
        "value": "SJQ",
        "iso": "ZM"
    },
    {
        "label": "SLI",
        "value": "SLI",
        "iso": "ZM"
    },
    {
        "label": "BBZ",
        "value": "BBZ",
        "iso": "ZM"
    },
    {
        "label": "ULI",
        "value": "ULI",
        "iso": "FM"
    },
    {
        "label": "HAH",
        "value": "HAH",
        "iso": "KM"
    },
    {
        "label": "NWA",
        "value": "NWA",
        "iso": "KM"
    },
    {
        "label": "YVA",
        "value": "YVA",
        "iso": "KM"
    },
    {
        "label": "AJN",
        "value": "AJN",
        "iso": "KM"
    },
    {
        "label": "DZA",
        "value": "DZA",
        "iso": "YT"
    },
    {
        "label": "RUN",
        "value": "RUN",
        "iso": "RE"
    },
    {
        "label": "ZSE",
        "value": "ZSE",
        "iso": "RE"
    },
    {
        "label": "WML",
        "value": "WML",
        "iso": "MG"
    },
    {
        "label": "ATJ",
        "value": "ATJ",
        "iso": "MG"
    },
    {
        "label": "WAQ",
        "value": "WAQ",
        "iso": "MG"
    },
    {
        "label": "VVB",
        "value": "VVB",
        "iso": "MG"
    },
    {
        "label": "TNR",
        "value": "TNR",
        "iso": "MG"
    },
    {
        "label": "JVA",
        "value": "JVA",
        "iso": "MG"
    },
    {
        "label": "BMD",
        "value": "BMD",
        "iso": "MG"
    },
    {
        "label": "ZVA",
        "value": "ZVA",
        "iso": "MG"
    },
    {
        "label": "MXT",
        "value": "MXT",
        "iso": "MG"
    },
    {
        "label": "ILK",
        "value": "ILK",
        "iso": "MG"
    },
    {
        "label": "TVA",
        "value": "TVA",
        "iso": "MG"
    },
    {
        "label": "SMS",
        "value": "SMS",
        "iso": "MG"
    },
    {
        "label": "TMM",
        "value": "TMM",
        "iso": "MG"
    },
    {
        "label": "WTA",
        "value": "WTA",
        "iso": "MG"
    },
    {
        "label": "MOQ",
        "value": "MOQ",
        "iso": "MG"
    },
    {
        "label": "WTS",
        "value": "WTS",
        "iso": "MG"
    },
    {
        "label": "VAT",
        "value": "VAT",
        "iso": "MG"
    },
    {
        "label": "WAM",
        "value": "WAM",
        "iso": "MG"
    },
    {
        "label": "DIE",
        "value": "DIE",
        "iso": "MG"
    },
    {
        "label": "WMR",
        "value": "WMR",
        "iso": "MG"
    },
    {
        "label": "ZWA",
        "value": "ZWA",
        "iso": "MG"
    },
    {
        "label": "AMB",
        "value": "AMB",
        "iso": "MG"
    },
    {
        "label": "WBD",
        "value": "WBD",
        "iso": "MG"
    },
    {
        "label": "WPB",
        "value": "WPB",
        "iso": "MG"
    },
    {
        "label": "ANM",
        "value": "ANM",
        "iso": "MG"
    },
    {
        "label": "IVA",
        "value": "IVA",
        "iso": "MG"
    },
    {
        "label": "HVA",
        "value": "HVA",
        "iso": "MG"
    },
    {
        "label": "MJN",
        "value": "MJN",
        "iso": "MG"
    },
    {
        "label": "NOS",
        "value": "NOS",
        "iso": "MG"
    },
    {
        "label": "DWB",
        "value": "DWB",
        "iso": "MG"
    },
    {
        "label": "WMP",
        "value": "WMP",
        "iso": "MG"
    },
    {
        "label": "BPY",
        "value": "BPY",
        "iso": "MG"
    },
    {
        "label": "WMN",
        "value": "WMN",
        "iso": "MG"
    },
    {
        "label": "SVB",
        "value": "SVB",
        "iso": "MG"
    },
    {
        "label": "TTS",
        "value": "TTS",
        "iso": "MG"
    },
    {
        "label": "VOH",
        "value": "VOH",
        "iso": "MG"
    },
    {
        "label": "WAI",
        "value": "WAI",
        "iso": "MG"
    },
    {
        "label": "WMA",
        "value": "WMA",
        "iso": "MG"
    },
    {
        "label": "WBO",
        "value": "WBO",
        "iso": "MG"
    },
    {
        "label": "WMD",
        "value": "WMD",
        "iso": "MG"
    },
    {
        "label": "FTU",
        "value": "FTU",
        "iso": "MG"
    },
    {
        "label": "WFI",
        "value": "WFI",
        "iso": "MG"
    },
    {
        "label": "RVA",
        "value": "RVA",
        "iso": "MG"
    },
    {
        "label": "IHO",
        "value": "IHO",
        "iso": "MG"
    },
    {
        "label": "MJA",
        "value": "MJA",
        "iso": "MG"
    },
    {
        "label": "WVK",
        "value": "WVK",
        "iso": "MG"
    },
    {
        "label": "OVA",
        "value": "OVA",
        "iso": "MG"
    },
    {
        "label": "MNJ",
        "value": "MNJ",
        "iso": "MG"
    },
    {
        "label": "TDV",
        "value": "TDV",
        "iso": "MG"
    },
    {
        "label": "MXM",
        "value": "MXM",
        "iso": "MG"
    },
    {
        "label": "TLE",
        "value": "TLE",
        "iso": "MG"
    },
    {
        "label": "BKU",
        "value": "BKU",
        "iso": "MG"
    },
    {
        "label": "AMP",
        "value": "AMP",
        "iso": "MG"
    },
    {
        "label": "WAK",
        "value": "WAK",
        "iso": "MG"
    },
    {
        "label": "AZZ",
        "value": "AZZ",
        "iso": "AO"
    },
    {
        "label": "SSY",
        "value": "SSY",
        "iso": "AO"
    },
    {
        "label": "BUG",
        "value": "BUG",
        "iso": "AO"
    },
    {
        "label": "CAB",
        "value": "CAB",
        "iso": "AO"
    },
    {
        "label": "CFF",
        "value": "CFF",
        "iso": "AO"
    },
    {
        "label": "PGI",
        "value": "PGI",
        "iso": "AO"
    },
    {
        "label": "CBT",
        "value": "CBT",
        "iso": "AO"
    },
    {
        "label": "CTI",
        "value": "CTI",
        "iso": "AO"
    },
    {
        "label": "CXM",
        "value": "CXM",
        "iso": "AO"
    },
    {
        "label": "CAV",
        "value": "CAV",
        "iso": "AO"
    },
    {
        "label": "DUE",
        "value": "DUE",
        "iso": "AO"
    },
    {
        "label": "VPE",
        "value": "VPE",
        "iso": "AO"
    },
    {
        "label": "NOV",
        "value": "NOV",
        "iso": "AO"
    },
    {
        "label": "SVP",
        "value": "SVP",
        "iso": "AO"
    },
    {
        "label": "LAD",
        "value": "LAD",
        "iso": "AO"
    },
    {
        "label": "MEG",
        "value": "MEG",
        "iso": "AO"
    },
    {
        "label": "SPP",
        "value": "SPP",
        "iso": "AO"
    },
    {
        "label": "MSZ",
        "value": "MSZ",
        "iso": "AO"
    },
    {
        "label": "GXG",
        "value": "GXG",
        "iso": "AO"
    },
    {
        "label": "PBN",
        "value": "PBN",
        "iso": "AO"
    },
    {
        "label": "VHC",
        "value": "VHC",
        "iso": "AO"
    },
    {
        "label": "SZA",
        "value": "SZA",
        "iso": "AO"
    },
    {
        "label": "NDD",
        "value": "NDD",
        "iso": "AO"
    },
    {
        "label": "UAL",
        "value": "UAL",
        "iso": "AO"
    },
    {
        "label": "SDD",
        "value": "SDD",
        "iso": "AO"
    },
    {
        "label": "LUO",
        "value": "LUO",
        "iso": "AO"
    },
    {
        "label": "UGO",
        "value": "UGO",
        "iso": "AO"
    },
    {
        "label": "CEO",
        "value": "CEO",
        "iso": "AO"
    },
    {
        "label": "XGN",
        "value": "XGN",
        "iso": "AO"
    },
    {
        "label": "ARZ",
        "value": "ARZ",
        "iso": "AO"
    },
    {
        "label": "BGB",
        "value": "BGB",
        "iso": "GA"
    },
    {
        "label": "KDN",
        "value": "KDN",
        "iso": "GA"
    },
    {
        "label": "FOU",
        "value": "FOU",
        "iso": "GA"
    },
    {
        "label": "MBC",
        "value": "MBC",
        "iso": "GA"
    },
    {
        "label": "MGX",
        "value": "MGX",
        "iso": "GA"
    },
    {
        "label": "KDJ",
        "value": "KDJ",
        "iso": "GA"
    },
    {
        "label": "KOU",
        "value": "KOU",
        "iso": "GA"
    },
    {
        "label": "MJL",
        "value": "MJL",
        "iso": "GA"
    },
    {
        "label": "OYE",
        "value": "OYE",
        "iso": "GA"
    },
    {
        "label": "OKN",
        "value": "OKN",
        "iso": "GA"
    },
    {
        "label": "LBQ",
        "value": "LBQ",
        "iso": "GA"
    },
    {
        "label": "MVX",
        "value": "MVX",
        "iso": "GA"
    },
    {
        "label": "BMM",
        "value": "BMM",
        "iso": "GA"
    },
    {
        "label": "MFF",
        "value": "MFF",
        "iso": "GA"
    },
    {
        "label": "MKB",
        "value": "MKB",
        "iso": "GA"
    },
    {
        "label": "POG",
        "value": "POG",
        "iso": "GA"
    },
    {
        "label": "OMB",
        "value": "OMB",
        "iso": "GA"
    },
    {
        "label": "MKU",
        "value": "MKU",
        "iso": "GA"
    },
    {
        "label": "LBV",
        "value": "LBV",
        "iso": "GA"
    },
    {
        "label": "MZC",
        "value": "MZC",
        "iso": "GA"
    },
    {
        "label": "MVB",
        "value": "MVB",
        "iso": "GA"
    },
    {
        "label": "LTL",
        "value": "LTL",
        "iso": "GA"
    },
    {
        "label": "ZKM",
        "value": "ZKM",
        "iso": "GA"
    },
    {
        "label": "TCH",
        "value": "TCH",
        "iso": "GA"
    },
    {
        "label": "MYB",
        "value": "MYB",
        "iso": "GA"
    },
    {
        "label": "PCP",
        "value": "PCP",
        "iso": "ST"
    },
    {
        "label": "TMS",
        "value": "TMS",
        "iso": "ST"
    },
    {
        "label": "ANO",
        "value": "ANO",
        "iso": "MZ"
    },
    {
        "label": "BEW",
        "value": "BEW",
        "iso": "MZ"
    },
    {
        "label": "FXO",
        "value": "FXO",
        "iso": "MZ"
    },
    {
        "label": "VPY",
        "value": "VPY",
        "iso": "MZ"
    },
    {
        "label": "IHC",
        "value": "IHC",
        "iso": "MZ"
    },
    {
        "label": "INH",
        "value": "INH",
        "iso": "MZ"
    },
    {
        "label": "VXC",
        "value": "VXC",
        "iso": "MZ"
    },
    {
        "label": "MPM",
        "value": "MPM",
        "iso": "MZ"
    },
    {
        "label": "MUD",
        "value": "MUD",
        "iso": "MZ"
    },
    {
        "label": "MZB",
        "value": "MZB",
        "iso": "MZ"
    },
    {
        "label": "MNC",
        "value": "MNC",
        "iso": "MZ"
    },
    {
        "label": "APL",
        "value": "APL",
        "iso": "MZ"
    },
    {
        "label": "POL",
        "value": "POL",
        "iso": "MZ"
    },
    {
        "label": "UEL",
        "value": "UEL",
        "iso": "MZ"
    },
    {
        "label": "TET",
        "value": "TET",
        "iso": "MZ"
    },
    {
        "label": "VNX",
        "value": "VNX",
        "iso": "MZ"
    },
    {
        "label": "VJB",
        "value": "VJB",
        "iso": "MZ"
    },
    {
        "label": "JCA",
        "value": "JCA",
        "iso": "FR"
    },
    {
        "label": "DES",
        "value": "DES",
        "iso": "SC"
    },
    {
        "label": "SEZ",
        "value": "SEZ",
        "iso": "SC"
    },
    {
        "label": "PRI",
        "value": "PRI",
        "iso": "SC"
    },
    {
        "label": "BDI",
        "value": "BDI",
        "iso": "SC"
    },
    {
        "label": "DEI",
        "value": "DEI",
        "iso": "SC"
    },
    {
        "label": "FRK",
        "value": "FRK",
        "iso": "SC"
    },
    {
        "label": "SRH",
        "value": "SRH",
        "iso": "TD"
    },
    {
        "label": "OGR",
        "value": "OGR",
        "iso": "TD"
    },
    {
        "label": "AEH",
        "value": "AEH",
        "iso": "TD"
    },
    {
        "label": "MQQ",
        "value": "MQQ",
        "iso": "TD"
    },
    {
        "label": "LTC",
        "value": "LTC",
        "iso": "TD"
    },
    {
        "label": "ATV",
        "value": "ATV",
        "iso": "TD"
    },
    {
        "label": "NDJ",
        "value": "NDJ",
        "iso": "TD"
    },
    {
        "label": "BKR",
        "value": "BKR",
        "iso": "TD"
    },
    {
        "label": "OTC",
        "value": "OTC",
        "iso": "TD"
    },
    {
        "label": "MVO",
        "value": "MVO",
        "iso": "TD"
    },
    {
        "label": "AMC",
        "value": "AMC",
        "iso": "TD"
    },
    {
        "label": "PLF",
        "value": "PLF",
        "iso": "TD"
    },
    {
        "label": "OUT",
        "value": "OUT",
        "iso": "TD"
    },
    {
        "label": "AMO",
        "value": "AMO",
        "iso": "TD"
    },
    {
        "label": "FYT",
        "value": "FYT",
        "iso": "TD"
    },
    {
        "label": "BUQ",
        "value": "BUQ",
        "iso": "ZW"
    },
    {
        "label": "CHJ",
        "value": "CHJ",
        "iso": "ZW"
    },
    {
        "label": "BFO",
        "value": "BFO",
        "iso": "ZW"
    },
    {
        "label": "VFA",
        "value": "VFA",
        "iso": "ZW"
    },
    {
        "label": "HRE",
        "value": "HRE",
        "iso": "ZW"
    },
    {
        "label": "KAB",
        "value": "KAB",
        "iso": "ZW"
    },
    {
        "label": "UTA",
        "value": "UTA",
        "iso": "ZW"
    },
    {
        "label": "MVZ",
        "value": "MVZ",
        "iso": "ZW"
    },
    {
        "label": "GWE",
        "value": "GWE",
        "iso": "ZW"
    },
    {
        "label": "HWN",
        "value": "HWN",
        "iso": "ZW"
    },
    {
        "label": "WKI",
        "value": "WKI",
        "iso": "ZW"
    },
    {
        "label": "CEH",
        "value": "CEH",
        "iso": "MW"
    },
    {
        "label": "BLZ",
        "value": "BLZ",
        "iso": "MW"
    },
    {
        "label": "CMK",
        "value": "CMK",
        "iso": "MW"
    },
    {
        "label": "DWA",
        "value": "DWA",
        "iso": "MW"
    },
    {
        "label": "KGJ",
        "value": "KGJ",
        "iso": "MW"
    },
    {
        "label": "KBQ",
        "value": "KBQ",
        "iso": "MW"
    },
    {
        "label": "LLW",
        "value": "LLW",
        "iso": "MW"
    },
    {
        "label": "LIX",
        "value": "LIX",
        "iso": "MW"
    },
    {
        "label": "MAI",
        "value": "MAI",
        "iso": "MW"
    },
    {
        "label": "MYZ",
        "value": "MYZ",
        "iso": "MW"
    },
    {
        "label": "LMB",
        "value": "LMB",
        "iso": "MW"
    },
    {
        "label": "ZZU",
        "value": "ZZU",
        "iso": "MW"
    },
    {
        "label": "LEF",
        "value": "LEF",
        "iso": "LS"
    },
    {
        "label": "LRB",
        "value": "LRB",
        "iso": "LS"
    },
    {
        "label": "LES",
        "value": "LES",
        "iso": "LS"
    },
    {
        "label": "MFC",
        "value": "MFC",
        "iso": "LS"
    },
    {
        "label": "MKH",
        "value": "MKH",
        "iso": "LS"
    },
    {
        "label": "MSU",
        "value": "MSU",
        "iso": "LS"
    },
    {
        "label": "NKU",
        "value": "NKU",
        "iso": "LS"
    },
    {
        "label": "PEL",
        "value": "PEL",
        "iso": "LS"
    },
    {
        "label": "UTG",
        "value": "UTG",
        "iso": "LS"
    },
    {
        "label": "UNE",
        "value": "UNE",
        "iso": "LS"
    },
    {
        "label": "SHK",
        "value": "SHK",
        "iso": "LS"
    },
    {
        "label": "SKQ",
        "value": "SKQ",
        "iso": "LS"
    },
    {
        "label": "SOK",
        "value": "SOK",
        "iso": "LS"
    },
    {
        "label": "SHZ",
        "value": "SHZ",
        "iso": "LS"
    },
    {
        "label": "THB",
        "value": "THB",
        "iso": "LS"
    },
    {
        "label": "TKO",
        "value": "TKO",
        "iso": "LS"
    },
    {
        "label": "ADI",
        "value": "ADI",
        "iso": "NA"
    },
    {
        "label": "GOG",
        "value": "GOG",
        "iso": "NA"
    },
    {
        "label": "GFY",
        "value": "GFY",
        "iso": "NA"
    },
    {
        "label": "MPA",
        "value": "MPA",
        "iso": "NA"
    },
    {
        "label": "KMP",
        "value": "KMP",
        "iso": "NA"
    },
    {
        "label": "LUD",
        "value": "LUD",
        "iso": "NA"
    },
    {
        "label": "OKU",
        "value": "OKU",
        "iso": "NA"
    },
    {
        "label": "NNI",
        "value": "NNI",
        "iso": "NA"
    },
    {
        "label": "OND",
        "value": "OND",
        "iso": "NA"
    },
    {
        "label": "OMG",
        "value": "OMG",
        "iso": "NA"
    },
    {
        "label": "OMD",
        "value": "OMD",
        "iso": "NA"
    },
    {
        "label": "OKF",
        "value": "OKF",
        "iso": "NA"
    },
    {
        "label": "NDU",
        "value": "NDU",
        "iso": "NA"
    },
    {
        "label": "SWP",
        "value": "SWP",
        "iso": "NA"
    },
    {
        "label": "TSB",
        "value": "TSB",
        "iso": "NA"
    },
    {
        "label": "WVB",
        "value": "WVB",
        "iso": "NA"
    },
    {
        "label": "ERS",
        "value": "ERS",
        "iso": "NA"
    },
    {
        "label": "WDH",
        "value": "WDH",
        "iso": "NA"
    },
    {
        "label": "FIH",
        "value": "FIH",
        "iso": "CD"
    },
    {
        "label": "NLO",
        "value": "NLO",
        "iso": "CD"
    },
    {
        "label": "MNB",
        "value": "MNB",
        "iso": "CD"
    },
    {
        "label": "BOA",
        "value": "BOA",
        "iso": "CD"
    },
    {
        "label": "LZI",
        "value": "LZI",
        "iso": "CD"
    },
    {
        "label": "MAT",
        "value": "MAT",
        "iso": "CD"
    },
    {
        "label": "NKL",
        "value": "NKL",
        "iso": "CD"
    },
    {
        "label": "INO",
        "value": "INO",
        "iso": "CD"
    },
    {
        "label": "NIO",
        "value": "NIO",
        "iso": "CD"
    },
    {
        "label": "FDU",
        "value": "FDU",
        "iso": "CD"
    },
    {
        "label": "KRZ",
        "value": "KRZ",
        "iso": "CD"
    },
    {
        "label": "KKW",
        "value": "KKW",
        "iso": "CD"
    },
    {
        "label": "IDF",
        "value": "IDF",
        "iso": "CD"
    },
    {
        "label": "LUS",
        "value": "LUS",
        "iso": "CD"
    },
    {
        "label": "MSM",
        "value": "MSM",
        "iso": "CD"
    },
    {
        "label": "MDK",
        "value": "MDK",
        "iso": "CD"
    },
    {
        "label": "BSU",
        "value": "BSU",
        "iso": "CD"
    },
    {
        "label": "LIE",
        "value": "LIE",
        "iso": "CD"
    },
    {
        "label": "BDT",
        "value": "BDT",
        "iso": "CD"
    },
    {
        "label": "GMA",
        "value": "GMA",
        "iso": "CD"
    },
    {
        "label": "KLI",
        "value": "KLI",
        "iso": "CD"
    },
    {
        "label": "BMB",
        "value": "BMB",
        "iso": "CD"
    },
    {
        "label": "LIQ",
        "value": "LIQ",
        "iso": "CD"
    },
    {
        "label": "BNB",
        "value": "BNB",
        "iso": "CD"
    },
    {
        "label": "IKL",
        "value": "IKL",
        "iso": "CD"
    },
    {
        "label": "FKI",
        "value": "FKI",
        "iso": "CD"
    },
    {
        "label": "YAN",
        "value": "YAN",
        "iso": "CD"
    },
    {
        "label": "IRP",
        "value": "IRP",
        "iso": "CD"
    },
    {
        "label": "BUX",
        "value": "BUX",
        "iso": "CD"
    },
    {
        "label": "BZU",
        "value": "BZU",
        "iso": "CD"
    },
    {
        "label": "BKY",
        "value": "BKY",
        "iso": "CD"
    },
    {
        "label": "GOM",
        "value": "GOM",
        "iso": "CD"
    },
    {
        "label": "BNC",
        "value": "BNC",
        "iso": "CD"
    },
    {
        "label": "KND",
        "value": "KND",
        "iso": "CD"
    },
    {
        "label": "KLY",
        "value": "KLY",
        "iso": "CD"
    },
    {
        "label": "PUN",
        "value": "PUN",
        "iso": "CD"
    },
    {
        "label": "FBM",
        "value": "FBM",
        "iso": "CD"
    },
    {
        "label": "PWO",
        "value": "PWO",
        "iso": "CD"
    },
    {
        "label": "KEC",
        "value": "KEC",
        "iso": "CD"
    },
    {
        "label": "KWZ",
        "value": "KWZ",
        "iso": "CD"
    },
    {
        "label": "MNO",
        "value": "MNO",
        "iso": "CD"
    },
    {
        "label": "BDV",
        "value": "BDV",
        "iso": "CD"
    },
    {
        "label": "FMI",
        "value": "FMI",
        "iso": "CD"
    },
    {
        "label": "KBO",
        "value": "KBO",
        "iso": "CD"
    },
    {
        "label": "KOO",
        "value": "KOO",
        "iso": "CD"
    },
    {
        "label": "KMN",
        "value": "KMN",
        "iso": "CD"
    },
    {
        "label": "KAP",
        "value": "KAP",
        "iso": "CD"
    },
    {
        "label": "KNM",
        "value": "KNM",
        "iso": "CD"
    },
    {
        "label": "KGA",
        "value": "KGA",
        "iso": "CD"
    },
    {
        "label": "LZA",
        "value": "LZA",
        "iso": "CD"
    },
    {
        "label": "TSH",
        "value": "TSH",
        "iso": "CD"
    },
    {
        "label": "LJA",
        "value": "LJA",
        "iso": "CD"
    },
    {
        "label": "LBO",
        "value": "LBO",
        "iso": "CD"
    },
    {
        "label": "MEW",
        "value": "MEW",
        "iso": "CD"
    },
    {
        "label": "BAN",
        "value": "BAN",
        "iso": "CD"
    },
    {
        "label": "PFR",
        "value": "PFR",
        "iso": "CD"
    },
    {
        "label": "MJM",
        "value": "MJM",
        "iso": "CD"
    },
    {
        "label": "GDJ",
        "value": "GDJ",
        "iso": "CD"
    },
    {
        "label": "KBN",
        "value": "KBN",
        "iso": "CD"
    },
    {
        "label": "AKE",
        "value": "AKE",
        "iso": "GA"
    },
    {
        "label": "GAX",
        "value": "GAX",
        "iso": "GA"
    },
    {
        "label": "BKO",
        "value": "BKO",
        "iso": "ML"
    },
    {
        "label": "GUD",
        "value": "GUD",
        "iso": "ML"
    },
    {
        "label": "GAQ",
        "value": "GAQ",
        "iso": "ML"
    },
    {
        "label": "KNZ",
        "value": "KNZ",
        "iso": "ML"
    },
    {
        "label": "KTX",
        "value": "KTX",
        "iso": "ML"
    },
    {
        "label": "KYS",
        "value": "KYS",
        "iso": "ML"
    },
    {
        "label": "MZI",
        "value": "MZI",
        "iso": "ML"
    },
    {
        "label": "NRM",
        "value": "NRM",
        "iso": "ML"
    },
    {
        "label": "NIX",
        "value": "NIX",
        "iso": "ML"
    },
    {
        "label": "KSS",
        "value": "KSS",
        "iso": "ML"
    },
    {
        "label": "TOM",
        "value": "TOM",
        "iso": "ML"
    },
    {
        "label": "EYL",
        "value": "EYL",
        "iso": "ML"
    },
    {
        "label": "DOC",
        "value": "DOC",
        "iso": "GB"
    },
    {
        "label": "FLH",
        "value": "FLH",
        "iso": "GB"
    },
    {
        "label": "FOA",
        "value": "FOA",
        "iso": "GB"
    },
    {
        "label": "OUK",
        "value": "OUK",
        "iso": "GB"
    },
    {
        "label": "PSV",
        "value": "PSV",
        "iso": "GB"
    },
    {
        "label": "ULL",
        "value": "ULL",
        "iso": "GB"
    },
    {
        "label": "BJL",
        "value": "BJL",
        "iso": "GM"
    },
    {
        "label": "FUE",
        "value": "FUE",
        "iso": "ES"
    },
    {
        "label": "GMZ",
        "value": "GMZ",
        "iso": "ES"
    },
    {
        "label": "VDE",
        "value": "VDE",
        "iso": "ES"
    },
    {
        "label": "SPC",
        "value": "SPC",
        "iso": "ES"
    },
    {
        "label": "LPA",
        "value": "LPA",
        "iso": "ES"
    },
    {
        "label": "ACE",
        "value": "ACE",
        "iso": "ES"
    },
    {
        "label": "TFS",
        "value": "TFS",
        "iso": "ES"
    },
    {
        "label": "TFN",
        "value": "TFN",
        "iso": "ES"
    },
    {
        "label": "JCU",
        "value": "JCU",
        "iso": "ES"
    },
    {
        "label": "MLN",
        "value": "MLN",
        "iso": "ES"
    },
    {
        "label": "BTE",
        "value": "BTE",
        "iso": "SL"
    },
    {
        "label": "KBS",
        "value": "KBS",
        "iso": "SL"
    },
    {
        "label": "GBK",
        "value": "GBK",
        "iso": "SL"
    },
    {
        "label": "HGS",
        "value": "HGS",
        "iso": "SL"
    },
    {
        "label": "KBA",
        "value": "KBA",
        "iso": "SL"
    },
    {
        "label": "KEN",
        "value": "KEN",
        "iso": "SL"
    },
    {
        "label": "FNA",
        "value": "FNA",
        "iso": "SL"
    },
    {
        "label": "WYE",
        "value": "WYE",
        "iso": "SL"
    },
    {
        "label": "BQE",
        "value": "BQE",
        "iso": "GW"
    },
    {
        "label": "OXB",
        "value": "OXB",
        "iso": "GW"
    },
    {
        "label": "JGR",
        "value": "JGR",
        "iso": "GL"
    },
    {
        "label": "QCU",
        "value": "QCU",
        "iso": "GL"
    },
    {
        "label": "UCN",
        "value": "UCN",
        "iso": "LR"
    },
    {
        "label": "CPA",
        "value": "CPA",
        "iso": "LR"
    },
    {
        "label": "SNI",
        "value": "SNI",
        "iso": "LR"
    },
    {
        "label": "UCN",
        "value": "UCN",
        "iso": "LR"
    },
    {
        "label": "MLW",
        "value": "MLW",
        "iso": "LR"
    },
    {
        "label": "NIA",
        "value": "NIA",
        "iso": "LR"
    },
    {
        "label": "ROB",
        "value": "ROB",
        "iso": "LR"
    },
    {
        "label": "SAZ",
        "value": "SAZ",
        "iso": "LR"
    },
    {
        "label": "THC",
        "value": "THC",
        "iso": "LR"
    },
    {
        "label": "VOI",
        "value": "VOI",
        "iso": "LR"
    },
    {
        "label": "AGA",
        "value": "AGA",
        "iso": "MA"
    },
    {
        "label": "TTA",
        "value": "TTA",
        "iso": "MA"
    },
    {
        "label": "OZG",
        "value": "OZG",
        "iso": "MA"
    },
    {
        "label": "UAR",
        "value": "UAR",
        "iso": "MA"
    },
    {
        "label": "FEZ",
        "value": "FEZ",
        "iso": "MA"
    },
    {
        "label": "ERH",
        "value": "ERH",
        "iso": "MA"
    },
    {
        "label": "MEK",
        "value": "MEK",
        "iso": "MA"
    },
    {
        "label": "OUD",
        "value": "OUD",
        "iso": "MA"
    },
    {
        "label": "SMW",
        "value": "SMW",
        "iso": "EH"
    },
    {
        "label": "GMD",
        "value": "GMD",
        "iso": "MA"
    },
    {
        "label": "CAS",
        "value": "CAS",
        "iso": "MA"
    },
    {
        "label": "RBA",
        "value": "RBA",
        "iso": "MA"
    },
    {
        "label": "SII",
        "value": "SII",
        "iso": "MA"
    },
    {
        "label": "VIL",
        "value": "VIL",
        "iso": "EH"
    },
    {
        "label": "ESU",
        "value": "ESU",
        "iso": "MA"
    },
    {
        "label": "EUN",
        "value": "EUN",
        "iso": "EH"
    },
    {
        "label": "CMN",
        "value": "CMN",
        "iso": "MA"
    },
    {
        "label": "SFI",
        "value": "SFI",
        "iso": "MA"
    },
    {
        "label": "NDR",
        "value": "NDR",
        "iso": "MA"
    },
    {
        "label": "RAK",
        "value": "RAK",
        "iso": "MA"
    },
    {
        "label": "NNA",
        "value": "NNA",
        "iso": "MA"
    },
    {
        "label": "OZZ",
        "value": "OZZ",
        "iso": "MA"
    },
    {
        "label": "AHU",
        "value": "AHU",
        "iso": "MA"
    },
    {
        "label": "TTU",
        "value": "TTU",
        "iso": "MA"
    },
    {
        "label": "TNG",
        "value": "TNG",
        "iso": "MA"
    },
    {
        "label": "GNU",
        "value": "GNU",
        "iso": "US"
    },
    {
        "label": "KDA",
        "value": "KDA",
        "iso": "SN"
    },
    {
        "label": "ZIG",
        "value": "ZIG",
        "iso": "SN"
    },
    {
        "label": "CSK",
        "value": "CSK",
        "iso": "SN"
    },
    {
        "label": "KLC",
        "value": "KLC",
        "iso": "SN"
    },
    {
        "label": "DKR",
        "value": "DKR",
        "iso": "SN"
    },
    {
        "label": "MAX",
        "value": "MAX",
        "iso": "SN"
    },
    {
        "label": "POD",
        "value": "POD",
        "iso": "SN"
    },
    {
        "label": "RDT",
        "value": "RDT",
        "iso": "SN"
    },
    {
        "label": "XLS",
        "value": "XLS",
        "iso": "SN"
    },
    {
        "label": "BXE",
        "value": "BXE",
        "iso": "SN"
    },
    {
        "label": "KGG",
        "value": "KGG",
        "iso": "SN"
    },
    {
        "label": "SMY",
        "value": "SMY",
        "iso": "SN"
    },
    {
        "label": "TUD",
        "value": "TUD",
        "iso": "SN"
    },
    {
        "label": "AEO",
        "value": "AEO",
        "iso": "MR"
    },
    {
        "label": "OTL",
        "value": "OTL",
        "iso": "MR"
    },
    {
        "label": "THI",
        "value": "THI",
        "iso": "MR"
    },
    {
        "label": "TIY",
        "value": "TIY",
        "iso": "MR"
    },
    {
        "label": "BGH",
        "value": "BGH",
        "iso": "MR"
    },
    {
        "label": "KFA",
        "value": "KFA",
        "iso": "MR"
    },
    {
        "label": "TMD",
        "value": "TMD",
        "iso": "MR"
    },
    {
        "label": "EMN",
        "value": "EMN",
        "iso": "MR"
    },
    {
        "label": "AJJ",
        "value": "AJJ",
        "iso": "MR"
    },
    {
        "label": "KED",
        "value": "KED",
        "iso": "MR"
    },
    {
        "label": "MOM",
        "value": "MOM",
        "iso": "MR"
    },
    {
        "label": "NKC",
        "value": "NKC",
        "iso": "MR"
    },
    {
        "label": "SEY",
        "value": "SEY",
        "iso": "MR"
    },
    {
        "label": "THT",
        "value": "THT",
        "iso": "MR"
    },
    {
        "label": "ATR",
        "value": "ATR",
        "iso": "MR"
    },
    {
        "label": "FGD",
        "value": "FGD",
        "iso": "MR"
    },
    {
        "label": "NDB",
        "value": "NDB",
        "iso": "MR"
    },
    {
        "label": "OUZ",
        "value": "OUZ",
        "iso": "MR"
    },
    {
        "label": "JSS",
        "value": "JSS",
        "iso": "GR"
    },
    {
        "label": "CIQ",
        "value": "CIQ",
        "iso": "GT"
    },
    {
        "label": "DON",
        "value": "DON",
        "iso": "GT"
    },
    {
        "label": "ENJ",
        "value": "ENJ",
        "iso": "GT"
    },
    {
        "label": "PCG",
        "value": "PCG",
        "iso": "GT"
    },
    {
        "label": "LCF",
        "value": "LCF",
        "iso": "GT"
    },
    {
        "label": "TKM",
        "value": "TKM",
        "iso": "GT"
    },
    {
        "label": "UAX",
        "value": "UAX",
        "iso": "GT"
    },
    {
        "label": "PKJ",
        "value": "PKJ",
        "iso": "GT"
    },
    {
        "label": "GTZ",
        "value": "GTZ",
        "iso": "TZ"
    },
    {
        "label": "CKY",
        "value": "CKY",
        "iso": "GN"
    },
    {
        "label": "FIG",
        "value": "FIG",
        "iso": "GN"
    },
    {
        "label": "FAA",
        "value": "FAA",
        "iso": "GN"
    },
    {
        "label": "KSI",
        "value": "KSI",
        "iso": "GN"
    },
    {
        "label": "LEK",
        "value": "LEK",
        "iso": "GN"
    },
    {
        "label": "MCA",
        "value": "MCA",
        "iso": "GN"
    },
    {
        "label": "NZE",
        "value": "NZE",
        "iso": "GN"
    },
    {
        "label": "BKJ",
        "value": "BKJ",
        "iso": "GN"
    },
    {
        "label": "SBI",
        "value": "SBI",
        "iso": "GN"
    },
    {
        "label": "GII",
        "value": "GII",
        "iso": "GN"
    },
    {
        "label": "KNN",
        "value": "KNN",
        "iso": "GN"
    },
    {
        "label": "SID",
        "value": "SID",
        "iso": "CV"
    },
    {
        "label": "NTO",
        "value": "NTO",
        "iso": "CV"
    },
    {
        "label": "BVC",
        "value": "BVC",
        "iso": "CV"
    },
    {
        "label": "MMO",
        "value": "MMO",
        "iso": "CV"
    },
    {
        "label": "MTI",
        "value": "MTI",
        "iso": "CV"
    },
    {
        "label": "RAI",
        "value": "RAI",
        "iso": "CV"
    },
    {
        "label": "SFL",
        "value": "SFL",
        "iso": "CV"
    },
    {
        "label": "SNE",
        "value": "SNE",
        "iso": "CV"
    },
    {
        "label": "VXE",
        "value": "VXE",
        "iso": "CV"
    },
    {
        "label": "EDG",
        "value": "EDG",
        "iso": "DE"
    },
    {
        "label": "BCG",
        "value": "BCG",
        "iso": "GY"
    },
    {
        "label": "BTO",
        "value": "BTO",
        "iso": "SR"
    },
    {
        "label": "DOE",
        "value": "DOE",
        "iso": "SR"
    },
    {
        "label": "LDO",
        "value": "LDO",
        "iso": "SR"
    },
    {
        "label": "WSO",
        "value": "WSO",
        "iso": "SR"
    },
    {
        "label": "ADD",
        "value": "ADD",
        "iso": "ET"
    },
    {
        "label": "AMH",
        "value": "AMH",
        "iso": "ET"
    },
    {
        "label": "AXU",
        "value": "AXU",
        "iso": "ET"
    },
    {
        "label": "BCO",
        "value": "BCO",
        "iso": "ET"
    },
    {
        "label": "BJR",
        "value": "BJR",
        "iso": "ET"
    },
    {
        "label": "BEI",
        "value": "BEI",
        "iso": "ET"
    },
    {
        "label": "DSE",
        "value": "DSE",
        "iso": "ET"
    },
    {
        "label": "DEM",
        "value": "DEM",
        "iso": "ET"
    },
    {
        "label": "DBM",
        "value": "DBM",
        "iso": "ET"
    },
    {
        "label": "DIR",
        "value": "DIR",
        "iso": "ET"
    },
    {
        "label": "DBT",
        "value": "DBT",
        "iso": "ET"
    },
    {
        "label": "FNH",
        "value": "FNH",
        "iso": "ET"
    },
    {
        "label": "GOB",
        "value": "GOB",
        "iso": "ET"
    },
    {
        "label": "GNN",
        "value": "GNN",
        "iso": "ET"
    },
    {
        "label": "GMB",
        "value": "GMB",
        "iso": "ET"
    },
    {
        "label": "GDQ",
        "value": "GDQ",
        "iso": "ET"
    },
    {
        "label": "GDE",
        "value": "GDE",
        "iso": "ET"
    },
    {
        "label": "GOR",
        "value": "GOR",
        "iso": "ET"
    },
    {
        "label": "QHR",
        "value": "QHR",
        "iso": "ET"
    },
    {
        "label": "HUE",
        "value": "HUE",
        "iso": "ET"
    },
    {
        "label": "JIM",
        "value": "JIM",
        "iso": "ET"
    },
    {
        "label": "ABK",
        "value": "ABK",
        "iso": "ET"
    },
    {
        "label": "LFO",
        "value": "LFO",
        "iso": "ET"
    },
    {
        "label": "AWA",
        "value": "AWA",
        "iso": "ET"
    },
    {
        "label": "LLI",
        "value": "LLI",
        "iso": "ET"
    },
    {
        "label": "MQX",
        "value": "MQX",
        "iso": "ET"
    },
    {
        "label": "NDM",
        "value": "NDM",
        "iso": "ET"
    },
    {
        "label": "MTF",
        "value": "MTF",
        "iso": "ET"
    },
    {
        "label": "NEJ",
        "value": "NEJ",
        "iso": "ET"
    },
    {
        "label": "NEK",
        "value": "NEK",
        "iso": "ET"
    },
    {
        "label": "SXU",
        "value": "SXU",
        "iso": "ET"
    },
    {
        "label": "ASO",
        "value": "ASO",
        "iso": "ET"
    },
    {
        "label": "TIE",
        "value": "TIE",
        "iso": "ET"
    },
    {
        "label": "WAC",
        "value": "WAC",
        "iso": "ET"
    },
    {
        "label": "BJM",
        "value": "BJM",
        "iso": "BI"
    },
    {
        "label": "GID",
        "value": "GID",
        "iso": "BI"
    },
    {
        "label": "ALU",
        "value": "ALU",
        "iso": "SO"
    },
    {
        "label": "BIB",
        "value": "BIB",
        "iso": "SO"
    },
    {
        "label": "CXN",
        "value": "CXN",
        "iso": "SO"
    },
    {
        "label": "HCM",
        "value": "HCM",
        "iso": "SO"
    },
    {
        "label": "BSA",
        "value": "BSA",
        "iso": "SO"
    },
    {
        "label": "GSR",
        "value": "GSR",
        "iso": "SO"
    },
    {
        "label": "HGA",
        "value": "HGA",
        "iso": "SO"
    },
    {
        "label": "BBO",
        "value": "BBO",
        "iso": "SO"
    },
    {
        "label": "KMU",
        "value": "KMU",
        "iso": "SO"
    },
    {
        "label": "MGQ",
        "value": "MGQ",
        "iso": "SO"
    },
    {
        "label": "CMO",
        "value": "CMO",
        "iso": "SO"
    },
    {
        "label": "GLK",
        "value": "GLK",
        "iso": "SO"
    },
    {
        "label": "CMS",
        "value": "CMS",
        "iso": "SO"
    },
    {
        "label": "ERA",
        "value": "ERA",
        "iso": "SO"
    },
    {
        "label": "BUO",
        "value": "BUO",
        "iso": "SO"
    },
    {
        "label": "JIB",
        "value": "JIB",
        "iso": "DJ"
    },
    {
        "label": "AII",
        "value": "AII",
        "iso": "DJ"
    },
    {
        "label": "MHI",
        "value": "MHI",
        "iso": "DJ"
    },
    {
        "label": "OBC",
        "value": "OBC",
        "iso": "DJ"
    },
    {
        "label": "TDJ",
        "value": "TDJ",
        "iso": "DJ"
    },
    {
        "label": "SEW",
        "value": "SEW",
        "iso": "EG"
    },
    {
        "label": "DBB",
        "value": "DBB",
        "iso": "EG"
    },
    {
        "label": "AAC",
        "value": "AAC",
        "iso": "EG"
    },
    {
        "label": "ATZ",
        "value": "ATZ",
        "iso": "EG"
    },
    {
        "label": "ALY",
        "value": "ALY",
        "iso": "EG"
    },
    {
        "label": "HBE",
        "value": "HBE",
        "iso": "EG"
    },
    {
        "label": "ABS",
        "value": "ABS",
        "iso": "EG"
    },
    {
        "label": "CAI",
        "value": "CAI",
        "iso": "EG"
    },
    {
        "label": "CWE",
        "value": "CWE",
        "iso": "EG"
    },
    {
        "label": "DAK",
        "value": "DAK",
        "iso": "EG"
    },
    {
        "label": "HRG",
        "value": "HRG",
        "iso": "EG"
    },
    {
        "label": "EGH",
        "value": "EGH",
        "iso": "EG"
    },
    {
        "label": "UVL",
        "value": "UVL",
        "iso": "EG"
    },
    {
        "label": "LXR",
        "value": "LXR",
        "iso": "EG"
    },
    {
        "label": "RMF",
        "value": "RMF",
        "iso": "EG"
    },
    {
        "label": "HMB",
        "value": "HMB",
        "iso": "EG"
    },
    {
        "label": "MUH",
        "value": "MUH",
        "iso": "EG"
    },
    {
        "label": "GSQ",
        "value": "GSQ",
        "iso": "EG"
    },
    {
        "label": "PSD",
        "value": "PSD",
        "iso": "EG"
    },
    {
        "label": "SKV",
        "value": "SKV",
        "iso": "EG"
    },
    {
        "label": "SSH",
        "value": "SSH",
        "iso": "EG"
    },
    {
        "label": "ASW",
        "value": "ASW",
        "iso": "EG"
    },
    {
        "label": "TCP",
        "value": "TCP",
        "iso": "EG"
    },
    {
        "label": "ELT",
        "value": "ELT",
        "iso": "EG"
    },
    {
        "label": "HGI",
        "value": "HGI",
        "iso": "SS"
    },
    {
        "label": "ASM",
        "value": "ASM",
        "iso": "ER"
    },
    {
        "label": "MSW",
        "value": "MSW",
        "iso": "ER"
    },
    {
        "label": "ASA",
        "value": "ASA",
        "iso": "ER"
    },
    {
        "label": "TES",
        "value": "TES",
        "iso": "ER"
    },
    {
        "label": "HIA",
        "value": "HIA",
        "iso": "CN"
    },
    {
        "label": "HIL",
        "value": "HIL",
        "iso": "ET"
    },
    {
        "label": "ASV",
        "value": "ASV",
        "iso": "KE"
    },
    {
        "label": "EDL",
        "value": "EDL",
        "iso": "KE"
    },
    {
        "label": "EYS",
        "value": "EYS",
        "iso": "KE"
    },
    {
        "label": "KLK",
        "value": "KLK",
        "iso": "KE"
    },
    {
        "label": "GAS",
        "value": "GAS",
        "iso": "KE"
    },
    {
        "label": "HOA",
        "value": "HOA",
        "iso": "KE"
    },
    {
        "label": "NBO",
        "value": "NBO",
        "iso": "KE"
    },
    {
        "label": "GGM",
        "value": "GGM",
        "iso": "KE"
    },
    {
        "label": "KIS",
        "value": "KIS",
        "iso": "KE"
    },
    {
        "label": "ILU",
        "value": "ILU",
        "iso": "KE"
    },
    {
        "label": "KEY",
        "value": "KEY",
        "iso": "KE"
    },
    {
        "label": "KTL",
        "value": "KTL",
        "iso": "KE"
    },
    {
        "label": "LKG",
        "value": "LKG",
        "iso": "KE"
    },
    {
        "label": "LOK",
        "value": "LOK",
        "iso": "KE"
    },
    {
        "label": "LAU",
        "value": "LAU",
        "iso": "KE"
    },
    {
        "label": "LOY",
        "value": "LOY",
        "iso": "KE"
    },
    {
        "label": "NDE",
        "value": "NDE",
        "iso": "KE"
    },
    {
        "label": "RBT",
        "value": "RBT",
        "iso": "KE"
    },
    {
        "label": "MYD",
        "value": "MYD",
        "iso": "KE"
    },
    {
        "label": "MBA",
        "value": "MBA",
        "iso": "KE"
    },
    {
        "label": "MRE",
        "value": "MRE",
        "iso": "KE"
    },
    {
        "label": "OYL",
        "value": "OYL",
        "iso": "KE"
    },
    {
        "label": "NYE",
        "value": "NYE",
        "iso": "KE"
    },
    {
        "label": "NUU",
        "value": "NUU",
        "iso": "KE"
    },
    {
        "label": "WIL",
        "value": "WIL",
        "iso": "KE"
    },
    {
        "label": "NYK",
        "value": "NYK",
        "iso": "KE"
    },
    {
        "label": "UAS",
        "value": "UAS",
        "iso": "KE"
    },
    {
        "label": "UKA",
        "value": "UKA",
        "iso": "KE"
    },
    {
        "label": "WJR",
        "value": "WJR",
        "iso": "KE"
    },
    {
        "label": "SRX",
        "value": "SRX",
        "iso": "LY"
    },
    {
        "label": "TOB",
        "value": "TOB",
        "iso": "LY"
    },
    {
        "label": "GHT",
        "value": "GHT",
        "iso": "LY"
    },
    {
        "label": "AKF",
        "value": "AKF",
        "iso": "LY"
    },
    {
        "label": "BEN",
        "value": "BEN",
        "iso": "LY"
    },
    {
        "label": "MJI",
        "value": "MJI",
        "iso": "LY"
    },
    {
        "label": "LAQ",
        "value": "LAQ",
        "iso": "LY"
    },
    {
        "label": "SEB",
        "value": "SEB",
        "iso": "LY"
    },
    {
        "label": "TIP",
        "value": "TIP",
        "iso": "LY"
    },
    {
        "label": "LMQ",
        "value": "LMQ",
        "iso": "LY"
    },
    {
        "label": "HUQ",
        "value": "HUQ",
        "iso": "LY"
    },
    {
        "label": "LTD",
        "value": "LTD",
        "iso": "LY"
    },
    {
        "label": "WAX",
        "value": "WAX",
        "iso": "LY"
    },
    {
        "label": "GYI",
        "value": "GYI",
        "iso": "RW"
    },
    {
        "label": "BTQ",
        "value": "BTQ",
        "iso": "RW"
    },
    {
        "label": "KGL",
        "value": "KGL",
        "iso": "RW"
    },
    {
        "label": "RHG",
        "value": "RHG",
        "iso": "RW"
    },
    {
        "label": "KME",
        "value": "KME",
        "iso": "RW"
    },
    {
        "label": "ATB",
        "value": "ATB",
        "iso": "SD"
    },
    {
        "label": "EDB",
        "value": "EDB",
        "iso": "SD"
    },
    {
        "label": "DOG",
        "value": "DOG",
        "iso": "SD"
    },
    {
        "label": "ELF",
        "value": "ELF",
        "iso": "SD"
    },
    {
        "label": "GSU",
        "value": "GSU",
        "iso": "SD"
    },
    {
        "label": "DNX",
        "value": "DNX",
        "iso": "SD"
    },
    {
        "label": "EGN",
        "value": "EGN",
        "iso": "SD"
    },
    {
        "label": "KSL",
        "value": "KSL",
        "iso": "SD"
    },
    {
        "label": "GBU",
        "value": "GBU",
        "iso": "SD"
    },
    {
        "label": "KST",
        "value": "KST",
        "iso": "SD"
    },
    {
        "label": "KDX",
        "value": "KDX",
        "iso": "SD"
    },
    {
        "label": "MWE",
        "value": "MWE",
        "iso": "SD"
    },
    {
        "label": "NUD",
        "value": "NUD",
        "iso": "SD"
    },
    {
        "label": "UYL",
        "value": "UYL",
        "iso": "SD"
    },
    {
        "label": "NHF",
        "value": "NHF",
        "iso": "SD"
    },
    {
        "label": "EBD",
        "value": "EBD",
        "iso": "SD"
    },
    {
        "label": "PZU",
        "value": "PZU",
        "iso": "SD"
    },
    {
        "label": "JUB",
        "value": "JUB",
        "iso": "SS"
    },
    {
        "label": "MAK",
        "value": "MAK",
        "iso": "SS"
    },
    {
        "label": "KRT",
        "value": "KRT",
        "iso": "SD"
    },
    {
        "label": "WHF",
        "value": "WHF",
        "iso": "SD"
    },
    {
        "label": "WUU",
        "value": "WUU",
        "iso": "SS"
    },
    {
        "label": "ARK",
        "value": "ARK",
        "iso": "TZ"
    },
    {
        "label": "BKZ",
        "value": "BKZ",
        "iso": "TZ"
    },
    {
        "label": "DAR",
        "value": "DAR",
        "iso": "TZ"
    },
    {
        "label": "DOD",
        "value": "DOD",
        "iso": "TZ"
    },
    {
        "label": "IRI",
        "value": "IRI",
        "iso": "TZ"
    },
    {
        "label": "TKQ",
        "value": "TKQ",
        "iso": "TZ"
    },
    {
        "label": "KIY",
        "value": "KIY",
        "iso": "TZ"
    },
    {
        "label": "JRO",
        "value": "JRO",
        "iso": "TZ"
    },
    {
        "label": "LDI",
        "value": "LDI",
        "iso": "TZ"
    },
    {
        "label": "LKY",
        "value": "LKY",
        "iso": "TZ"
    },
    {
        "label": "MFA",
        "value": "MFA",
        "iso": "TZ"
    },
    {
        "label": "MBI",
        "value": "MBI",
        "iso": "TZ"
    },
    {
        "label": "MWN",
        "value": "MWN",
        "iso": "TZ"
    },
    {
        "label": "XMI",
        "value": "XMI",
        "iso": "TZ"
    },
    {
        "label": "QSI",
        "value": "QSI",
        "iso": "TZ"
    },
    {
        "label": "MYW",
        "value": "MYW",
        "iso": "TZ"
    },
    {
        "label": "MUZ",
        "value": "MUZ",
        "iso": "TZ"
    },
    {
        "label": "MWZ",
        "value": "MWZ",
        "iso": "TZ"
    },
    {
        "label": "NCH",
        "value": "NCH",
        "iso": "TZ"
    },
    {
        "label": "JOM",
        "value": "JOM",
        "iso": "TZ"
    },
    {
        "label": "PMA",
        "value": "PMA",
        "iso": "TZ"
    },
    {
        "label": "SEU",
        "value": "SEU",
        "iso": "TZ"
    },
    {
        "label": "SGX",
        "value": "SGX",
        "iso": "TZ"
    },
    {
        "label": "SUT",
        "value": "SUT",
        "iso": "TZ"
    },
    {
        "label": "SHY",
        "value": "SHY",
        "iso": "TZ"
    },
    {
        "label": "TBO",
        "value": "TBO",
        "iso": "TZ"
    },
    {
        "label": "TGT",
        "value": "TGT",
        "iso": "TZ"
    },
    {
        "label": "ZNZ",
        "value": "ZNZ",
        "iso": "TZ"
    },
    {
        "label": "RUA",
        "value": "RUA",
        "iso": "UG"
    },
    {
        "label": "EBB",
        "value": "EBB",
        "iso": "UG"
    },
    {
        "label": "ULU",
        "value": "ULU",
        "iso": "UG"
    },
    {
        "label": "JIN",
        "value": "JIN",
        "iso": "UG"
    },
    {
        "label": "KBG",
        "value": "KBG",
        "iso": "UG"
    },
    {
        "label": "KSE",
        "value": "KSE",
        "iso": "UG"
    },
    {
        "label": "MBQ",
        "value": "MBQ",
        "iso": "UG"
    },
    {
        "label": "KCU",
        "value": "KCU",
        "iso": "UG"
    },
    {
        "label": "SRT",
        "value": "SRT",
        "iso": "UG"
    },
    {
        "label": "TRY",
        "value": "TRY",
        "iso": "UG"
    },
    {
        "label": "IBL",
        "value": "IBL",
        "iso": "MZ"
    },
    {
        "label": "PPJ",
        "value": "PPJ",
        "iso": "ID"
    },
    {
        "label": "AAS",
        "value": "AAS",
        "iso": "ID"
    },
    {
        "label": "AGD",
        "value": "AGD",
        "iso": "ID"
    },
    {
        "label": "AKQ",
        "value": "AKQ",
        "iso": "ID"
    },
    {
        "label": "AYW",
        "value": "AYW",
        "iso": "ID"
    },
    {
        "label": "BJG",
        "value": "BJG",
        "iso": "ID"
    },
    {
        "label": "BXM",
        "value": "BXM",
        "iso": "ID"
    },
    {
        "label": "DRH",
        "value": "DRH",
        "iso": "ID"
    },
    {
        "label": "ELR",
        "value": "ELR",
        "iso": "ID"
    },
    {
        "label": "EWE",
        "value": "EWE",
        "iso": "ID"
    },
    {
        "label": "FOO",
        "value": "FOO",
        "iso": "ID"
    },
    {
        "label": "GAV",
        "value": "GAV",
        "iso": "ID"
    },
    {
        "label": "IUL",
        "value": "IUL",
        "iso": "ID"
    },
    {
        "label": "KBF",
        "value": "KBF",
        "iso": "ID"
    },
    {
        "label": "KBX",
        "value": "KBX",
        "iso": "ID"
    },
    {
        "label": "KCD",
        "value": "KCD",
        "iso": "ID"
    },
    {
        "label": "KCI",
        "value": "KCI",
        "iso": "TL"
    },
    {
        "label": "KEA",
        "value": "KEA",
        "iso": "ID"
    },
    {
        "label": "KMM",
        "value": "KMM",
        "iso": "ID"
    },
    {
        "label": "KOD",
        "value": "KOD",
        "iso": "ID"
    },
    {
        "label": "KRC",
        "value": "KRC",
        "iso": "ID"
    },
    {
        "label": "KWB",
        "value": "KWB",
        "iso": "ID"
    },
    {
        "label": "LLN",
        "value": "LLN",
        "iso": "ID"
    },
    {
        "label": "LWE",
        "value": "LWE",
        "iso": "ID"
    },
    {
        "label": "LYK",
        "value": "LYK",
        "iso": "ID"
    },
    {
        "label": "MJY",
        "value": "MJY",
        "iso": "ID"
    },
    {
        "label": "MPT",
        "value": "MPT",
        "iso": "ID"
    },
    {
        "label": "MSI",
        "value": "MSI",
        "iso": "ID"
    },
    {
        "label": "MUF",
        "value": "MUF",
        "iso": "ID"
    },
    {
        "label": "NAF",
        "value": "NAF",
        "iso": "ID"
    },
    {
        "label": "NDA",
        "value": "NDA",
        "iso": "ID"
    },
    {
        "label": "OBD",
        "value": "OBD",
        "iso": "ID"
    },
    {
        "label": "PPJ",
        "value": "PPJ",
        "iso": "ID"
    },
    {
        "label": "PUM",
        "value": "PUM",
        "iso": "ID"
    },
    {
        "label": "PWL",
        "value": "PWL",
        "iso": "ID"
    },
    {
        "label": "RAQ",
        "value": "RAQ",
        "iso": "ID"
    },
    {
        "label": "RKI",
        "value": "RKI",
        "iso": "ID"
    },
    {
        "label": "RTI",
        "value": "RTI",
        "iso": "ID"
    },
    {
        "label": "RUF",
        "value": "RUF",
        "iso": "ID"
    },
    {
        "label": "RZS",
        "value": "RZS",
        "iso": "ID"
    },
    {
        "label": "SAE",
        "value": "SAE",
        "iso": "ID"
    },
    {
        "label": "TBM",
        "value": "TBM",
        "iso": "ID"
    },
    {
        "label": "TMY",
        "value": "TMY",
        "iso": "ID"
    },
    {
        "label": "ZEG",
        "value": "ZEG",
        "iso": "ID"
    },
    {
        "label": "ZGP",
        "value": "ZGP",
        "iso": "ID"
    },
    {
        "label": "IOR",
        "value": "IOR",
        "iso": "IE"
    },
    {
        "label": "CHE",
        "value": "CHE",
        "iso": "IE"
    },
    {
        "label": "VDY",
        "value": "VDY",
        "iso": "IN"
    },
    {
        "label": "JGB",
        "value": "JGB",
        "iso": "IN"
    },
    {
        "label": "NVY",
        "value": "NVY",
        "iso": "IN"
    },
    {
        "label": "RJI",
        "value": "RJI",
        "iso": "IN"
    },
    {
        "label": "TEI",
        "value": "TEI",
        "iso": "IN"
    },
    {
        "label": "KHA",
        "value": "KHA",
        "iso": "IR"
    },
    {
        "label": "GSM",
        "value": "GSM",
        "iso": "IR"
    },
    {
        "label": "TQR",
        "value": "TQR",
        "iso": "IT"
    },
    {
        "label": "JGD",
        "value": "JGD",
        "iso": "CN"
    },
    {
        "label": "JIC",
        "value": "JIC",
        "iso": "CN"
    },
    {
        "label": "JIQ",
        "value": "JIQ",
        "iso": "CN"
    },
    {
        "label": "JMC",
        "value": "JMC",
        "iso": "US"
    },
    {
        "label": "JRA",
        "value": "JRA",
        "iso": "US"
    },
    {
        "label": "AMK",
        "value": "AMK",
        "iso": "US"
    },
    {
        "label": "RLA",
        "value": "RLA",
        "iso": "US"
    },
    {
        "label": "FID",
        "value": "FID",
        "iso": "US"
    },
    {
        "label": "AHD",
        "value": "AHD",
        "iso": "US"
    },
    {
        "label": "GCW",
        "value": "GCW",
        "iso": "US"
    },
    {
        "label": "RKC",
        "value": "RKC",
        "iso": "US"
    },
    {
        "label": "GNF",
        "value": "GNF",
        "iso": "US"
    },
    {
        "label": "AHF",
        "value": "AHF",
        "iso": "US"
    },
    {
        "label": "GTP",
        "value": "GTP",
        "iso": "US"
    },
    {
        "label": "GCD",
        "value": "GCD",
        "iso": "US"
    },
    {
        "label": "FPY",
        "value": "FPY",
        "iso": "US"
    },
    {
        "label": "FHB",
        "value": "FHB",
        "iso": "US"
    },
    {
        "label": "BLD",
        "value": "BLD",
        "iso": "US"
    },
    {
        "label": "AAF",
        "value": "AAF",
        "iso": "US"
    },
    {
        "label": "ABE",
        "value": "ABE",
        "iso": "US"
    },
    {
        "label": "ABI",
        "value": "ABI",
        "iso": "US"
    },
    {
        "label": "ABQ",
        "value": "ABQ",
        "iso": "US"
    },
    {
        "label": "ABR",
        "value": "ABR",
        "iso": "US"
    },
    {
        "label": "ABY",
        "value": "ABY",
        "iso": "US"
    },
    {
        "label": "ACB",
        "value": "ACB",
        "iso": "US"
    },
    {
        "label": "ACK",
        "value": "ACK",
        "iso": "US"
    },
    {
        "label": "ACT",
        "value": "ACT",
        "iso": "US"
    },
    {
        "label": "ACV",
        "value": "ACV",
        "iso": "US"
    },
    {
        "label": "ACY",
        "value": "ACY",
        "iso": "US"
    },
    {
        "label": "ADG",
        "value": "ADG",
        "iso": "US"
    },
    {
        "label": "ADT",
        "value": "ADT",
        "iso": "US"
    },
    {
        "label": "ADM",
        "value": "ADM",
        "iso": "US"
    },
    {
        "label": "ADS",
        "value": "ADS",
        "iso": "US"
    },
    {
        "label": "ADW",
        "value": "ADW",
        "iso": "US"
    },
    {
        "label": "KAE",
        "value": "KAE",
        "iso": "US"
    },
    {
        "label": "AEL",
        "value": "AEL",
        "iso": "US"
    },
    {
        "label": "AEX",
        "value": "AEX",
        "iso": "US"
    },
    {
        "label": "AFF",
        "value": "AFF",
        "iso": "US"
    },
    {
        "label": "WSG",
        "value": "WSG",
        "iso": "US"
    },
    {
        "label": "AFN",
        "value": "AFN",
        "iso": "US"
    },
    {
        "label": "AFO",
        "value": "AFO",
        "iso": "US"
    },
    {
        "label": "AFW",
        "value": "AFW",
        "iso": "US"
    },
    {
        "label": "AGC",
        "value": "AGC",
        "iso": "US"
    },
    {
        "label": "AGO",
        "value": "AGO",
        "iso": "US"
    },
    {
        "label": "AGS",
        "value": "AGS",
        "iso": "US"
    },
    {
        "label": "AHC",
        "value": "AHC",
        "iso": "US"
    },
    {
        "label": "AHH",
        "value": "AHH",
        "iso": "US"
    },
    {
        "label": "AHN",
        "value": "AHN",
        "iso": "US"
    },
    {
        "label": "AIA",
        "value": "AIA",
        "iso": "US"
    },
    {
        "label": "AID",
        "value": "AID",
        "iso": "US"
    },
    {
        "label": "AIK",
        "value": "AIK",
        "iso": "US"
    },
    {
        "label": "AIO",
        "value": "AIO",
        "iso": "US"
    },
    {
        "label": "AIV",
        "value": "AIV",
        "iso": "US"
    },
    {
        "label": "AIY",
        "value": "AIY",
        "iso": "US"
    },
    {
        "label": "AIZ",
        "value": "AIZ",
        "iso": "US"
    },
    {
        "label": "AKO",
        "value": "AKO",
        "iso": "US"
    },
    {
        "label": "AKC",
        "value": "AKC",
        "iso": "US"
    },
    {
        "label": "ALB",
        "value": "ALB",
        "iso": "US"
    },
    {
        "label": "ALI",
        "value": "ALI",
        "iso": "US"
    },
    {
        "label": "ALM",
        "value": "ALM",
        "iso": "US"
    },
    {
        "label": "ALN",
        "value": "ALN",
        "iso": "US"
    },
    {
        "label": "ALO",
        "value": "ALO",
        "iso": "US"
    },
    {
        "label": "ALS",
        "value": "ALS",
        "iso": "US"
    },
    {
        "label": "ALW",
        "value": "ALW",
        "iso": "US"
    },
    {
        "label": "ALX",
        "value": "ALX",
        "iso": "US"
    },
    {
        "label": "AMA",
        "value": "AMA",
        "iso": "US"
    },
    {
        "label": "AMN",
        "value": "AMN",
        "iso": "US"
    },
    {
        "label": "AMW",
        "value": "AMW",
        "iso": "US"
    },
    {
        "label": "ANB",
        "value": "ANB",
        "iso": "US"
    },
    {
        "label": "AND",
        "value": "AND",
        "iso": "US"
    },
    {
        "label": "ANP",
        "value": "ANP",
        "iso": "US"
    },
    {
        "label": "ANQ",
        "value": "ANQ",
        "iso": "US"
    },
    {
        "label": "ANW",
        "value": "ANW",
        "iso": "US"
    },
    {
        "label": "ANY",
        "value": "ANY",
        "iso": "US"
    },
    {
        "label": "AOH",
        "value": "AOH",
        "iso": "US"
    },
    {
        "label": "AOO",
        "value": "AOO",
        "iso": "US"
    },
    {
        "label": "APA",
        "value": "APA",
        "iso": "US"
    },
    {
        "label": "APC",
        "value": "APC",
        "iso": "US"
    },
    {
        "label": "APF",
        "value": "APF",
        "iso": "US"
    },
    {
        "label": "APG",
        "value": "APG",
        "iso": "US"
    },
    {
        "label": "APH",
        "value": "APH",
        "iso": "US"
    },
    {
        "label": "APN",
        "value": "APN",
        "iso": "US"
    },
    {
        "label": "APT",
        "value": "APT",
        "iso": "US"
    },
    {
        "label": "APV",
        "value": "APV",
        "iso": "US"
    },
    {
        "label": "ARA",
        "value": "ARA",
        "iso": "US"
    },
    {
        "label": "ARB",
        "value": "ARB",
        "iso": "US"
    },
    {
        "label": "ARG",
        "value": "ARG",
        "iso": "US"
    },
    {
        "label": "ART",
        "value": "ART",
        "iso": "US"
    },
    {
        "label": "ATL",
        "value": "ATL",
        "iso": "US"
    },
    {
        "label": "ATW",
        "value": "ATW",
        "iso": "US"
    },
    {
        "label": "ATY",
        "value": "ATY",
        "iso": "US"
    },
    {
        "label": "AUG",
        "value": "AUG",
        "iso": "US"
    },
    {
        "label": "AUS",
        "value": "AUS",
        "iso": "US"
    },
    {
        "label": "AVL",
        "value": "AVL",
        "iso": "US"
    },
    {
        "label": "AVO",
        "value": "AVO",
        "iso": "US"
    },
    {
        "label": "AVP",
        "value": "AVP",
        "iso": "US"
    },
    {
        "label": "AWM",
        "value": "AWM",
        "iso": "US"
    },
    {
        "label": "AXN",
        "value": "AXN",
        "iso": "US"
    },
    {
        "label": "AXS",
        "value": "AXS",
        "iso": "US"
    },
    {
        "label": "AXV",
        "value": "AXV",
        "iso": "US"
    },
    {
        "label": "AXX",
        "value": "AXX",
        "iso": "US"
    },
    {
        "label": "AYE",
        "value": "AYE",
        "iso": "US"
    },
    {
        "label": "AYS",
        "value": "AYS",
        "iso": "US"
    },
    {
        "label": "AZO",
        "value": "AZO",
        "iso": "US"
    },
    {
        "label": "BAB",
        "value": "BAB",
        "iso": "US"
    },
    {
        "label": "BAD",
        "value": "BAD",
        "iso": "US"
    },
    {
        "label": "BAF",
        "value": "BAF",
        "iso": "US"
    },
    {
        "label": "BDL",
        "value": "BDL",
        "iso": "US"
    },
    {
        "label": "BDR",
        "value": "BDR",
        "iso": "US"
    },
    {
        "label": "BED",
        "value": "BED",
        "iso": "US"
    },
    {
        "label": "BFD",
        "value": "BFD",
        "iso": "US"
    },
    {
        "label": "BFF",
        "value": "BFF",
        "iso": "US"
    },
    {
        "label": "BFI",
        "value": "BFI",
        "iso": "US"
    },
    {
        "label": "BFL",
        "value": "BFL",
        "iso": "US"
    },
    {
        "label": "BGM",
        "value": "BGM",
        "iso": "US"
    },
    {
        "label": "BGR",
        "value": "BGR",
        "iso": "US"
    },
    {
        "label": "BHB",
        "value": "BHB",
        "iso": "US"
    },
    {
        "label": "BHM",
        "value": "BHM",
        "iso": "US"
    },
    {
        "label": "BIL",
        "value": "BIL",
        "iso": "US"
    },
    {
        "label": "BIS",
        "value": "BIS",
        "iso": "US"
    },
    {
        "label": "BKL",
        "value": "BKL",
        "iso": "US"
    },
    {
        "label": "BKW",
        "value": "BKW",
        "iso": "US"
    },
    {
        "label": "BKX",
        "value": "BKX",
        "iso": "US"
    },
    {
        "label": "BLF",
        "value": "BLF",
        "iso": "US"
    },
    {
        "label": "BLI",
        "value": "BLI",
        "iso": "US"
    },
    {
        "label": "BMG",
        "value": "BMG",
        "iso": "US"
    },
    {
        "label": "BMI",
        "value": "BMI",
        "iso": "US"
    },
    {
        "label": "BMT",
        "value": "BMT",
        "iso": "US"
    },
    {
        "label": "BNA",
        "value": "BNA",
        "iso": "US"
    },
    {
        "label": "BOI",
        "value": "BOI",
        "iso": "US"
    },
    {
        "label": "BOS",
        "value": "BOS",
        "iso": "US"
    },
    {
        "label": "BPT",
        "value": "BPT",
        "iso": "US"
    },
    {
        "label": "BQK",
        "value": "BQK",
        "iso": "US"
    },
    {
        "label": "BRL",
        "value": "BRL",
        "iso": "US"
    },
    {
        "label": "BRO",
        "value": "BRO",
        "iso": "US"
    },
    {
        "label": "BTM",
        "value": "BTM",
        "iso": "US"
    },
    {
        "label": "BTR",
        "value": "BTR",
        "iso": "US"
    },
    {
        "label": "BTV",
        "value": "BTV",
        "iso": "US"
    },
    {
        "label": "BTY",
        "value": "BTY",
        "iso": "US"
    },
    {
        "label": "BUB",
        "value": "BUB",
        "iso": "US"
    },
    {
        "label": "BUF",
        "value": "BUF",
        "iso": "US"
    },
    {
        "label": "BUM",
        "value": "BUM",
        "iso": "US"
    },
    {
        "label": "BUR",
        "value": "BUR",
        "iso": "US"
    },
    {
        "label": "BVO",
        "value": "BVO",
        "iso": "US"
    },
    {
        "label": "BVX",
        "value": "BVX",
        "iso": "US"
    },
    {
        "label": "BVY",
        "value": "BVY",
        "iso": "US"
    },
    {
        "label": "BWC",
        "value": "BWC",
        "iso": "US"
    },
    {
        "label": "BWD",
        "value": "BWD",
        "iso": "US"
    },
    {
        "label": "BWG",
        "value": "BWG",
        "iso": "US"
    },
    {
        "label": "BWI",
        "value": "BWI",
        "iso": "US"
    },
    {
        "label": "BXA",
        "value": "BXA",
        "iso": "US"
    },
    {
        "label": "BXK",
        "value": "BXK",
        "iso": "US"
    },
    {
        "label": "BYS",
        "value": "BYS",
        "iso": "US"
    },
    {
        "label": "BZN",
        "value": "BZN",
        "iso": "US"
    },
    {
        "label": "CLG",
        "value": "CLG",
        "iso": "US"
    },
    {
        "label": "CAE",
        "value": "CAE",
        "iso": "US"
    },
    {
        "label": "CAK",
        "value": "CAK",
        "iso": "US"
    },
    {
        "label": "CDC",
        "value": "CDC",
        "iso": "US"
    },
    {
        "label": "CEC",
        "value": "CEC",
        "iso": "US"
    },
    {
        "label": "CEF",
        "value": "CEF",
        "iso": "US"
    },
    {
        "label": "CGI",
        "value": "CGI",
        "iso": "US"
    },
    {
        "label": "CGX",
        "value": "CGX",
        "iso": "US"
    },
    {
        "label": "CHA",
        "value": "CHA",
        "iso": "US"
    },
    {
        "label": "CHO",
        "value": "CHO",
        "iso": "US"
    },
    {
        "label": "CHS",
        "value": "CHS",
        "iso": "US"
    },
    {
        "label": "CID",
        "value": "CID",
        "iso": "US"
    },
    {
        "label": "CIU",
        "value": "CIU",
        "iso": "US"
    },
    {
        "label": "CKB",
        "value": "CKB",
        "iso": "US"
    },
    {
        "label": "KCL",
        "value": "KCL",
        "iso": "US"
    },
    {
        "label": "CLE",
        "value": "CLE",
        "iso": "US"
    },
    {
        "label": "CLL",
        "value": "CLL",
        "iso": "US"
    },
    {
        "label": "CLM",
        "value": "CLM",
        "iso": "US"
    },
    {
        "label": "CLT",
        "value": "CLT",
        "iso": "US"
    },
    {
        "label": "CMH",
        "value": "CMH",
        "iso": "US"
    },
    {
        "label": "CMI",
        "value": "CMI",
        "iso": "US"
    },
    {
        "label": "CMX",
        "value": "CMX",
        "iso": "US"
    },
    {
        "label": "CNM",
        "value": "CNM",
        "iso": "US"
    },
    {
        "label": "COS",
        "value": "COS",
        "iso": "US"
    },
    {
        "label": "COU",
        "value": "COU",
        "iso": "US"
    },
    {
        "label": "CPR",
        "value": "CPR",
        "iso": "US"
    },
    {
        "label": "CRO",
        "value": "CRO",
        "iso": "US"
    },
    {
        "label": "CRP",
        "value": "CRP",
        "iso": "US"
    },
    {
        "label": "CRW",
        "value": "CRW",
        "iso": "US"
    },
    {
        "label": "CSG",
        "value": "CSG",
        "iso": "US"
    },
    {
        "label": "CVG",
        "value": "CVG",
        "iso": "US"
    },
    {
        "label": "KIP",
        "value": "KIP",
        "iso": "US"
    },
    {
        "label": "CWF",
        "value": "CWF",
        "iso": "US"
    },
    {
        "label": "CYS",
        "value": "CYS",
        "iso": "US"
    },
    {
        "label": "DAB",
        "value": "DAB",
        "iso": "US"
    },
    {
        "label": "DAL",
        "value": "DAL",
        "iso": "US"
    },
    {
        "label": "DAN",
        "value": "DAN",
        "iso": "US"
    },
    {
        "label": "DAY",
        "value": "DAY",
        "iso": "US"
    },
    {
        "label": "DBQ",
        "value": "DBQ",
        "iso": "US"
    },
    {
        "label": "DCA",
        "value": "DCA",
        "iso": "US"
    },
    {
        "label": "DDC",
        "value": "DDC",
        "iso": "US"
    },
    {
        "label": "DEC",
        "value": "DEC",
        "iso": "US"
    },
    {
        "label": "DEN",
        "value": "DEN",
        "iso": "US"
    },
    {
        "label": "DET",
        "value": "DET",
        "iso": "US"
    },
    {
        "label": "DFW",
        "value": "DFW",
        "iso": "US"
    },
    {
        "label": "DHN",
        "value": "DHN",
        "iso": "US"
    },
    {
        "label": "DLH",
        "value": "DLH",
        "iso": "US"
    },
    {
        "label": "DNL",
        "value": "DNL",
        "iso": "US"
    },
    {
        "label": "DSM",
        "value": "DSM",
        "iso": "US"
    },
    {
        "label": "DTW",
        "value": "DTW",
        "iso": "US"
    },
    {
        "label": "DUJ",
        "value": "DUJ",
        "iso": "US"
    },
    {
        "label": "JJM",
        "value": "JJM",
        "iso": "KE"
    },
    {
        "label": "VPG",
        "value": "VPG",
        "iso": "KE"
    },
    {
        "label": "KRV",
        "value": "KRV",
        "iso": "KE"
    },
    {
        "label": "KIU",
        "value": "KIU",
        "iso": "KE"
    },
    {
        "label": "LBK",
        "value": "LBK",
        "iso": "KE"
    },
    {
        "label": "LBN",
        "value": "LBN",
        "iso": "KE"
    },
    {
        "label": "LKU",
        "value": "LKU",
        "iso": "KE"
    },
    {
        "label": "MRE",
        "value": "MRE",
        "iso": "KE"
    },
    {
        "label": "MUM",
        "value": "MUM",
        "iso": "KE"
    },
    {
        "label": "ALE",
        "value": "ALE",
        "iso": "US"
    },
    {
        "label": "BGT",
        "value": "BGT",
        "iso": "US"
    },
    {
        "label": "EAR",
        "value": "EAR",
        "iso": "US"
    },
    {
        "label": "EAU",
        "value": "EAU",
        "iso": "US"
    },
    {
        "label": "ECP",
        "value": "ECP",
        "iso": "US"
    },
    {
        "label": "EEN",
        "value": "EEN",
        "iso": "US"
    },
    {
        "label": "EGE",
        "value": "EGE",
        "iso": "US"
    },
    {
        "label": "KEK",
        "value": "KEK",
        "iso": "US"
    },
    {
        "label": "EKO",
        "value": "EKO",
        "iso": "US"
    },
    {
        "label": "ELD",
        "value": "ELD",
        "iso": "US"
    },
    {
        "label": "ELM",
        "value": "ELM",
        "iso": "US"
    },
    {
        "label": "ELP",
        "value": "ELP",
        "iso": "US"
    },
    {
        "label": "ERI",
        "value": "ERI",
        "iso": "US"
    },
    {
        "label": "EUG",
        "value": "EUG",
        "iso": "US"
    },
    {
        "label": "EVV",
        "value": "EVV",
        "iso": "US"
    },
    {
        "label": "EWB",
        "value": "EWB",
        "iso": "US"
    },
    {
        "label": "EWN",
        "value": "EWN",
        "iso": "US"
    },
    {
        "label": "EWR",
        "value": "EWR",
        "iso": "US"
    },
    {
        "label": "EYW",
        "value": "EYW",
        "iso": "US"
    },
    {
        "label": "FAR",
        "value": "FAR",
        "iso": "US"
    },
    {
        "label": "FAT",
        "value": "FAT",
        "iso": "US"
    },
    {
        "label": "FAY",
        "value": "FAY",
        "iso": "US"
    },
    {
        "label": "FBG",
        "value": "FBG",
        "iso": "US"
    },
    {
        "label": "FCS",
        "value": "FCS",
        "iso": "US"
    },
    {
        "label": "KFE",
        "value": "KFE",
        "iso": "AU"
    },
    {
        "label": "FRD",
        "value": "FRD",
        "iso": "US"
    },
    {
        "label": "FHU",
        "value": "FHU",
        "iso": "US"
    },
    {
        "label": "FLG",
        "value": "FLG",
        "iso": "US"
    },
    {
        "label": "FLL",
        "value": "FLL",
        "iso": "US"
    },
    {
        "label": "FLO",
        "value": "FLO",
        "iso": "US"
    },
    {
        "label": "FLU",
        "value": "FLU",
        "iso": "US"
    },
    {
        "label": "FNT",
        "value": "FNT",
        "iso": "US"
    },
    {
        "label": "FOD",
        "value": "FOD",
        "iso": "US"
    },
    {
        "label": "FOE",
        "value": "FOE",
        "iso": "US"
    },
    {
        "label": "FIL",
        "value": "FIL",
        "iso": "US"
    },
    {
        "label": "FPR",
        "value": "FPR",
        "iso": "US"
    },
    {
        "label": "FRH",
        "value": "FRH",
        "iso": "US"
    },
    {
        "label": "FRI",
        "value": "FRI",
        "iso": "US"
    },
    {
        "label": "FRM",
        "value": "FRM",
        "iso": "US"
    },
    {
        "label": "FSD",
        "value": "FSD",
        "iso": "US"
    },
    {
        "label": "FSM",
        "value": "FSM",
        "iso": "US"
    },
    {
        "label": "FMS",
        "value": "FMS",
        "iso": "US"
    },
    {
        "label": "FTW",
        "value": "FTW",
        "iso": "US"
    },
    {
        "label": "FWA",
        "value": "FWA",
        "iso": "US"
    },
    {
        "label": "FYV",
        "value": "FYV",
        "iso": "US"
    },
    {
        "label": "GCK",
        "value": "GCK",
        "iso": "US"
    },
    {
        "label": "GCN",
        "value": "GCN",
        "iso": "US"
    },
    {
        "label": "GEG",
        "value": "GEG",
        "iso": "US"
    },
    {
        "label": "GFK",
        "value": "GFK",
        "iso": "US"
    },
    {
        "label": "GGG",
        "value": "GGG",
        "iso": "US"
    },
    {
        "label": "GGW",
        "value": "GGW",
        "iso": "US"
    },
    {
        "label": "GJT",
        "value": "GJT",
        "iso": "US"
    },
    {
        "label": "GLH",
        "value": "GLH",
        "iso": "US"
    },
    {
        "label": "GLS",
        "value": "GLS",
        "iso": "US"
    },
    {
        "label": "GNV",
        "value": "GNV",
        "iso": "US"
    },
    {
        "label": "GON",
        "value": "GON",
        "iso": "US"
    },
    {
        "label": "FCA",
        "value": "FCA",
        "iso": "US"
    },
    {
        "label": "GPT",
        "value": "GPT",
        "iso": "US"
    },
    {
        "label": "GRB",
        "value": "GRB",
        "iso": "US"
    },
    {
        "label": "GRI",
        "value": "GRI",
        "iso": "US"
    },
    {
        "label": "GRK",
        "value": "GRK",
        "iso": "US"
    },
    {
        "label": "GRR",
        "value": "GRR",
        "iso": "US"
    },
    {
        "label": "GSO",
        "value": "GSO",
        "iso": "US"
    },
    {
        "label": "GSP",
        "value": "GSP",
        "iso": "US"
    },
    {
        "label": "GTF",
        "value": "GTF",
        "iso": "US"
    },
    {
        "label": "GUP",
        "value": "GUP",
        "iso": "US"
    },
    {
        "label": "GVT",
        "value": "GVT",
        "iso": "US"
    },
    {
        "label": "GVW",
        "value": "GVW",
        "iso": "US"
    },
    {
        "label": "GYY",
        "value": "GYY",
        "iso": "US"
    },
    {
        "label": "HGR",
        "value": "HGR",
        "iso": "US"
    },
    {
        "label": "HIB",
        "value": "HIB",
        "iso": "US"
    },
    {
        "label": "HKY",
        "value": "HKY",
        "iso": "US"
    },
    {
        "label": "HLN",
        "value": "HLN",
        "iso": "US"
    },
    {
        "label": "HON",
        "value": "HON",
        "iso": "US"
    },
    {
        "label": "HOT",
        "value": "HOT",
        "iso": "US"
    },
    {
        "label": "HOU",
        "value": "HOU",
        "iso": "US"
    },
    {
        "label": "HPN",
        "value": "HPN",
        "iso": "US"
    },
    {
        "label": "HRL",
        "value": "HRL",
        "iso": "US"
    },
    {
        "label": "HRO",
        "value": "HRO",
        "iso": "US"
    },
    {
        "label": "HNC",
        "value": "HNC",
        "iso": "US"
    },
    {
        "label": "HSV",
        "value": "HSV",
        "iso": "US"
    },
    {
        "label": "HTS",
        "value": "HTS",
        "iso": "US"
    },
    {
        "label": "HUF",
        "value": "HUF",
        "iso": "US"
    },
    {
        "label": "HVN",
        "value": "HVN",
        "iso": "US"
    },
    {
        "label": "HYA",
        "value": "HYA",
        "iso": "US"
    },
    {
        "label": "IAB",
        "value": "IAB",
        "iso": "US"
    },
    {
        "label": "IAD",
        "value": "IAD",
        "iso": "US"
    },
    {
        "label": "IAG",
        "value": "IAG",
        "iso": "US"
    },
    {
        "label": "IAH",
        "value": "IAH",
        "iso": "US"
    },
    {
        "label": "ICT",
        "value": "ICT",
        "iso": "US"
    },
    {
        "label": "IDA",
        "value": "IDA",
        "iso": "US"
    },
    {
        "label": "IFP",
        "value": "IFP",
        "iso": "US"
    },
    {
        "label": "IGM",
        "value": "IGM",
        "iso": "US"
    },
    {
        "label": "ILG",
        "value": "ILG",
        "iso": "US"
    },
    {
        "label": "ILM",
        "value": "ILM",
        "iso": "US"
    },
    {
        "label": "IND",
        "value": "IND",
        "iso": "US"
    },
    {
        "label": "INL",
        "value": "INL",
        "iso": "US"
    },
    {
        "label": "INT",
        "value": "INT",
        "iso": "US"
    },
    {
        "label": "IPT",
        "value": "IPT",
        "iso": "US"
    },
    {
        "label": "IRK",
        "value": "IRK",
        "iso": "US"
    },
    {
        "label": "ISP",
        "value": "ISP",
        "iso": "US"
    },
    {
        "label": "ITH",
        "value": "ITH",
        "iso": "US"
    },
    {
        "label": "AZA",
        "value": "AZA",
        "iso": "US"
    },
    {
        "label": "JAC",
        "value": "JAC",
        "iso": "US"
    },
    {
        "label": "JAN",
        "value": "JAN",
        "iso": "US"
    },
    {
        "label": "JAX",
        "value": "JAX",
        "iso": "US"
    },
    {
        "label": "JBR",
        "value": "JBR",
        "iso": "US"
    },
    {
        "label": "JFK",
        "value": "JFK",
        "iso": "US"
    },
    {
        "label": "JLN",
        "value": "JLN",
        "iso": "US"
    },
    {
        "label": "JMS",
        "value": "JMS",
        "iso": "US"
    },
    {
        "label": "JST",
        "value": "JST",
        "iso": "US"
    },
    {
        "label": "LAF",
        "value": "LAF",
        "iso": "US"
    },
    {
        "label": "LAN",
        "value": "LAN",
        "iso": "US"
    },
    {
        "label": "LAR",
        "value": "LAR",
        "iso": "US"
    },
    {
        "label": "LAS",
        "value": "LAS",
        "iso": "US"
    },
    {
        "label": "LAX",
        "value": "LAX",
        "iso": "US"
    },
    {
        "label": "LBB",
        "value": "LBB",
        "iso": "US"
    },
    {
        "label": "LBE",
        "value": "LBE",
        "iso": "US"
    },
    {
        "label": "LBF",
        "value": "LBF",
        "iso": "US"
    },
    {
        "label": "LBL",
        "value": "LBL",
        "iso": "US"
    },
    {
        "label": "LCH",
        "value": "LCH",
        "iso": "US"
    },
    {
        "label": "LEB",
        "value": "LEB",
        "iso": "US"
    },
    {
        "label": "LEX",
        "value": "LEX",
        "iso": "US"
    },
    {
        "label": "LFT",
        "value": "LFT",
        "iso": "US"
    },
    {
        "label": "LGA",
        "value": "LGA",
        "iso": "US"
    },
    {
        "label": "LGB",
        "value": "LGB",
        "iso": "US"
    },
    {
        "label": "LHC",
        "value": "LHC",
        "iso": "US"
    },
    {
        "label": "LIT",
        "value": "LIT",
        "iso": "US"
    },
    {
        "label": "LMT",
        "value": "LMT",
        "iso": "US"
    },
    {
        "label": "LNK",
        "value": "LNK",
        "iso": "US"
    },
    {
        "label": "LNS",
        "value": "LNS",
        "iso": "US"
    },
    {
        "label": "LRD",
        "value": "LRD",
        "iso": "US"
    },
    {
        "label": "LRU",
        "value": "LRU",
        "iso": "US"
    },
    {
        "label": "LSE",
        "value": "LSE",
        "iso": "US"
    },
    {
        "label": "LUK",
        "value": "LUK",
        "iso": "US"
    },
    {
        "label": "LWS",
        "value": "LWS",
        "iso": "US"
    },
    {
        "label": "LWT",
        "value": "LWT",
        "iso": "US"
    },
    {
        "label": "LYH",
        "value": "LYH",
        "iso": "US"
    },
    {
        "label": "MAF",
        "value": "MAF",
        "iso": "US"
    },
    {
        "label": "MBS",
        "value": "MBS",
        "iso": "US"
    },
    {
        "label": "MCE",
        "value": "MCE",
        "iso": "US"
    },
    {
        "label": "MCI",
        "value": "MCI",
        "iso": "US"
    },
    {
        "label": "MCN",
        "value": "MCN",
        "iso": "US"
    },
    {
        "label": "MCO",
        "value": "MCO",
        "iso": "US"
    },
    {
        "label": "MDH",
        "value": "MDH",
        "iso": "US"
    },
    {
        "label": "MDT",
        "value": "MDT",
        "iso": "US"
    },
    {
        "label": "MDW",
        "value": "MDW",
        "iso": "US"
    },
    {
        "label": "MEI",
        "value": "MEI",
        "iso": "US"
    },
    {
        "label": "MEM",
        "value": "MEM",
        "iso": "US"
    },
    {
        "label": "MFE",
        "value": "MFE",
        "iso": "US"
    },
    {
        "label": "MFR",
        "value": "MFR",
        "iso": "US"
    },
    {
        "label": "MGM",
        "value": "MGM",
        "iso": "US"
    },
    {
        "label": "MGW",
        "value": "MGW",
        "iso": "US"
    },
    {
        "label": "MHK",
        "value": "MHK",
        "iso": "US"
    },
    {
        "label": "MHT",
        "value": "MHT",
        "iso": "US"
    },
    {
        "label": "MIA",
        "value": "MIA",
        "iso": "US"
    },
    {
        "label": "MIE",
        "value": "MIE",
        "iso": "US"
    },
    {
        "label": "MKC",
        "value": "MKC",
        "iso": "US"
    },
    {
        "label": "MKE",
        "value": "MKE",
        "iso": "US"
    },
    {
        "label": "MKG",
        "value": "MKG",
        "iso": "US"
    },
    {
        "label": "MKL",
        "value": "MKL",
        "iso": "US"
    },
    {
        "label": "MLB",
        "value": "MLB",
        "iso": "US"
    },
    {
        "label": "MLI",
        "value": "MLI",
        "iso": "US"
    },
    {
        "label": "MLS",
        "value": "MLS",
        "iso": "US"
    },
    {
        "label": "MLU",
        "value": "MLU",
        "iso": "US"
    },
    {
        "label": "KMM",
        "value": "KMM",
        "iso": "ID"
    },
    {
        "label": "MMU",
        "value": "MMU",
        "iso": "US"
    },
    {
        "label": "MOB",
        "value": "MOB",
        "iso": "US"
    },
    {
        "label": "MOD",
        "value": "MOD",
        "iso": "US"
    },
    {
        "label": "MOT",
        "value": "MOT",
        "iso": "US"
    },
    {
        "label": "MRY",
        "value": "MRY",
        "iso": "US"
    },
    {
        "label": "MSL",
        "value": "MSL",
        "iso": "US"
    },
    {
        "label": "MSN",
        "value": "MSN",
        "iso": "US"
    },
    {
        "label": "MSO",
        "value": "MSO",
        "iso": "US"
    },
    {
        "label": "MSP",
        "value": "MSP",
        "iso": "US"
    },
    {
        "label": "MSS",
        "value": "MSS",
        "iso": "US"
    },
    {
        "label": "MSY",
        "value": "MSY",
        "iso": "US"
    },
    {
        "label": "MVY",
        "value": "MVY",
        "iso": "US"
    },
    {
        "label": "MYR",
        "value": "MYR",
        "iso": "US"
    },
    {
        "label": "NEL",
        "value": "NEL",
        "iso": "US"
    },
    {
        "label": "NGZ",
        "value": "NGZ",
        "iso": "US"
    },
    {
        "label": "NOP",
        "value": "NOP",
        "iso": "US"
    },
    {
        "label": "YUM",
        "value": "YUM",
        "iso": "US"
    },
    {
        "label": "NVN",
        "value": "NVN",
        "iso": "US"
    },
    {
        "label": "ODC",
        "value": "ODC",
        "iso": "US"
    },
    {
        "label": "OAK",
        "value": "OAK",
        "iso": "US"
    },
    {
        "label": "OAR",
        "value": "OAR",
        "iso": "US"
    },
    {
        "label": "OKC",
        "value": "OKC",
        "iso": "US"
    },
    {
        "label": "OLD",
        "value": "OLD",
        "iso": "US"
    },
    {
        "label": "OLM",
        "value": "OLM",
        "iso": "US"
    },
    {
        "label": "OMA",
        "value": "OMA",
        "iso": "US"
    },
    {
        "label": "ONT",
        "value": "ONT",
        "iso": "US"
    },
    {
        "label": "ORD",
        "value": "ORD",
        "iso": "US"
    },
    {
        "label": "ORF",
        "value": "ORF",
        "iso": "US"
    },
    {
        "label": "ORH",
        "value": "ORH",
        "iso": "US"
    },
    {
        "label": "OSH",
        "value": "OSH",
        "iso": "US"
    },
    {
        "label": "OTH",
        "value": "OTH",
        "iso": "US"
    },
    {
        "label": "OTM",
        "value": "OTM",
        "iso": "US"
    },
    {
        "label": "OWB",
        "value": "OWB",
        "iso": "US"
    },
    {
        "label": "DSO",
        "value": "DSO",
        "iso": "KP"
    },
    {
        "label": "HBK",
        "value": "HBK",
        "iso": "US"
    },
    {
        "label": "CWX",
        "value": "CWX",
        "iso": "US"
    },
    {
        "label": "PAE",
        "value": "PAE",
        "iso": "US"
    },
    {
        "label": "PAH",
        "value": "PAH",
        "iso": "US"
    },
    {
        "label": "PBG",
        "value": "PBG",
        "iso": "US"
    },
    {
        "label": "PBI",
        "value": "PBI",
        "iso": "US"
    },
    {
        "label": "PCA",
        "value": "PCA",
        "iso": "US"
    },
    {
        "label": "PDK",
        "value": "PDK",
        "iso": "US"
    },
    {
        "label": "PDX",
        "value": "PDX",
        "iso": "US"
    },
    {
        "label": "PFN",
        "value": "PFN",
        "iso": "US"
    },
    {
        "label": "PHF",
        "value": "PHF",
        "iso": "US"
    },
    {
        "label": "ADR",
        "value": "ADR",
        "iso": "US"
    },
    {
        "label": "PHL",
        "value": "PHL",
        "iso": "US"
    },
    {
        "label": "PHX",
        "value": "PHX",
        "iso": "US"
    },
    {
        "label": "PIA",
        "value": "PIA",
        "iso": "US"
    },
    {
        "label": "PIB",
        "value": "PIB",
        "iso": "US"
    },
    {
        "label": "PIE",
        "value": "PIE",
        "iso": "US"
    },
    {
        "label": "PIH",
        "value": "PIH",
        "iso": "US"
    },
    {
        "label": "PIR",
        "value": "PIR",
        "iso": "US"
    },
    {
        "label": "PIT",
        "value": "PIT",
        "iso": "US"
    },
    {
        "label": "PKB",
        "value": "PKB",
        "iso": "US"
    },
    {
        "label": "PLB",
        "value": "PLB",
        "iso": "US"
    },
    {
        "label": "PLN",
        "value": "PLN",
        "iso": "US"
    },
    {
        "label": "PMD",
        "value": "PMD",
        "iso": "US"
    },
    {
        "label": "PNS",
        "value": "PNS",
        "iso": "US"
    },
    {
        "label": "POU",
        "value": "POU",
        "iso": "US"
    },
    {
        "label": "PQI",
        "value": "PQI",
        "iso": "US"
    },
    {
        "label": "PRC",
        "value": "PRC",
        "iso": "US"
    },
    {
        "label": "PSC",
        "value": "PSC",
        "iso": "US"
    },
    {
        "label": "PSM",
        "value": "PSM",
        "iso": "US"
    },
    {
        "label": "PSP",
        "value": "PSP",
        "iso": "US"
    },
    {
        "label": "PUB",
        "value": "PUB",
        "iso": "US"
    },
    {
        "label": "PUW",
        "value": "PUW",
        "iso": "US"
    },
    {
        "label": "PVD",
        "value": "PVD",
        "iso": "US"
    },
    {
        "label": "PWM",
        "value": "PWM",
        "iso": "US"
    },
    {
        "label": "PWT",
        "value": "PWT",
        "iso": "US"
    },
    {
        "label": "KQA",
        "value": "KQA",
        "iso": "US"
    },
    {
        "label": "RAP",
        "value": "RAP",
        "iso": "US"
    },
    {
        "label": "RDD",
        "value": "RDD",
        "iso": "US"
    },
    {
        "label": "RDG",
        "value": "RDG",
        "iso": "US"
    },
    {
        "label": "RDM",
        "value": "RDM",
        "iso": "US"
    },
    {
        "label": "RDU",
        "value": "RDU",
        "iso": "US"
    },
    {
        "label": "RFD",
        "value": "RFD",
        "iso": "US"
    },
    {
        "label": "RHI",
        "value": "RHI",
        "iso": "US"
    },
    {
        "label": "RIC",
        "value": "RIC",
        "iso": "US"
    },
    {
        "label": "KRJ",
        "value": "KRJ",
        "iso": "PG"
    },
    {
        "label": "RKS",
        "value": "RKS",
        "iso": "US"
    },
    {
        "label": "RNO",
        "value": "RNO",
        "iso": "US"
    },
    {
        "label": "ROA",
        "value": "ROA",
        "iso": "US"
    },
    {
        "label": "ROC",
        "value": "ROC",
        "iso": "US"
    },
    {
        "label": "ROW",
        "value": "ROW",
        "iso": "US"
    },
    {
        "label": "RQO",
        "value": "RQO",
        "iso": "US"
    },
    {
        "label": "RST",
        "value": "RST",
        "iso": "US"
    },
    {
        "label": "RSW",
        "value": "RSW",
        "iso": "US"
    },
    {
        "label": "RUT",
        "value": "RUT",
        "iso": "US"
    },
    {
        "label": "RWI",
        "value": "RWI",
        "iso": "US"
    },
    {
        "label": "AHM",
        "value": "AHM",
        "iso": "US"
    },
    {
        "label": "SUO",
        "value": "SUO",
        "iso": "US"
    },
    {
        "label": "IDH",
        "value": "IDH",
        "iso": "US"
    },
    {
        "label": "VSK",
        "value": "VSK",
        "iso": "US"
    },
    {
        "label": "SAN",
        "value": "SAN",
        "iso": "US"
    },
    {
        "label": "SAT",
        "value": "SAT",
        "iso": "US"
    },
    {
        "label": "SAV",
        "value": "SAV",
        "iso": "US"
    },
    {
        "label": "SBA",
        "value": "SBA",
        "iso": "US"
    },
    {
        "label": "SBN",
        "value": "SBN",
        "iso": "US"
    },
    {
        "label": "SBP",
        "value": "SBP",
        "iso": "US"
    },
    {
        "label": "SBY",
        "value": "SBY",
        "iso": "US"
    },
    {
        "label": "SDF",
        "value": "SDF",
        "iso": "US"
    },
    {
        "label": "SEA",
        "value": "SEA",
        "iso": "US"
    },
    {
        "label": "SFB",
        "value": "SFB",
        "iso": "US"
    },
    {
        "label": "SFO",
        "value": "SFO",
        "iso": "US"
    },
    {
        "label": "SGF",
        "value": "SGF",
        "iso": "US"
    },
    {
        "label": "SHD",
        "value": "SHD",
        "iso": "US"
    },
    {
        "label": "SHR",
        "value": "SHR",
        "iso": "US"
    },
    {
        "label": "SHV",
        "value": "SHV",
        "iso": "US"
    },
    {
        "label": "SJC",
        "value": "SJC",
        "iso": "US"
    },
    {
        "label": "SJT",
        "value": "SJT",
        "iso": "US"
    },
    {
        "label": "SLC",
        "value": "SLC",
        "iso": "US"
    },
    {
        "label": "SLK",
        "value": "SLK",
        "iso": "US"
    },
    {
        "label": "SLN",
        "value": "SLN",
        "iso": "US"
    },
    {
        "label": "SMF",
        "value": "SMF",
        "iso": "US"
    },
    {
        "label": "SMX",
        "value": "SMX",
        "iso": "US"
    },
    {
        "label": "SNA",
        "value": "SNA",
        "iso": "US"
    },
    {
        "label": "SPI",
        "value": "SPI",
        "iso": "US"
    },
    {
        "label": "SPS",
        "value": "SPS",
        "iso": "US"
    },
    {
        "label": "SRQ",
        "value": "SRQ",
        "iso": "US"
    },
    {
        "label": "STC",
        "value": "STC",
        "iso": "US"
    },
    {
        "label": "STL",
        "value": "STL",
        "iso": "US"
    },
    {
        "label": "STP",
        "value": "STP",
        "iso": "US"
    },
    {
        "label": "SUN",
        "value": "SUN",
        "iso": "US"
    },
    {
        "label": "SUX",
        "value": "SUX",
        "iso": "US"
    },
    {
        "label": "SVC",
        "value": "SVC",
        "iso": "US"
    },
    {
        "label": "SWF",
        "value": "SWF",
        "iso": "US"
    },
    {
        "label": "SYR",
        "value": "SYR",
        "iso": "US"
    },
    {
        "label": "TBN",
        "value": "TBN",
        "iso": "US"
    },
    {
        "label": "TCL",
        "value": "TCL",
        "iso": "US"
    },
    {
        "label": "TLH",
        "value": "TLH",
        "iso": "US"
    },
    {
        "label": "OTK",
        "value": "OTK",
        "iso": "US"
    },
    {
        "label": "TOL",
        "value": "TOL",
        "iso": "US"
    },
    {
        "label": "TPA",
        "value": "TPA",
        "iso": "US"
    },
    {
        "label": "TPL",
        "value": "TPL",
        "iso": "US"
    },
    {
        "label": "TRI",
        "value": "TRI",
        "iso": "US"
    },
    {
        "label": "TTN",
        "value": "TTN",
        "iso": "US"
    },
    {
        "label": "TUL",
        "value": "TUL",
        "iso": "US"
    },
    {
        "label": "TUP",
        "value": "TUP",
        "iso": "US"
    },
    {
        "label": "TUS",
        "value": "TUS",
        "iso": "US"
    },
    {
        "label": "TVC",
        "value": "TVC",
        "iso": "US"
    },
    {
        "label": "TVL",
        "value": "TVL",
        "iso": "US"
    },
    {
        "label": "TWF",
        "value": "TWF",
        "iso": "US"
    },
    {
        "label": "TXK",
        "value": "TXK",
        "iso": "US"
    },
    {
        "label": "TYR",
        "value": "TYR",
        "iso": "US"
    },
    {
        "label": "TYS",
        "value": "TYS",
        "iso": "US"
    },
    {
        "label": "ICS",
        "value": "ICS",
        "iso": "US"
    },
    {
        "label": "UCA",
        "value": "UCA",
        "iso": "US"
    },
    {
        "label": "UIN",
        "value": "UIN",
        "iso": "US"
    },
    {
        "label": "UIZ",
        "value": "UIZ",
        "iso": "US"
    },
    {
        "label": "SCE",
        "value": "SCE",
        "iso": "US"
    },
    {
        "label": "VCT",
        "value": "VCT",
        "iso": "US"
    },
    {
        "label": "VLD",
        "value": "VLD",
        "iso": "US"
    },
    {
        "label": "VPS",
        "value": "VPS",
        "iso": "US"
    },
    {
        "label": "VRB",
        "value": "VRB",
        "iso": "US"
    },
    {
        "label": "WRI",
        "value": "WRI",
        "iso": "US"
    },
    {
        "label": "WRL",
        "value": "WRL",
        "iso": "US"
    },
    {
        "label": "KWY",
        "value": "KWY",
        "iso": "KE"
    },
    {
        "label": "YIP",
        "value": "YIP",
        "iso": "US"
    },
    {
        "label": "YKM",
        "value": "YKM",
        "iso": "US"
    },
    {
        "label": "YNG",
        "value": "YNG",
        "iso": "US"
    },
    {
        "label": "DZN",
        "value": "DZN",
        "iso": "KZ"
    },
    {
        "label": "TDK",
        "value": "TDK",
        "iso": "KZ"
    },
    {
        "label": "ATX",
        "value": "ATX",
        "iso": "KZ"
    },
    {
        "label": "AVX",
        "value": "AVX",
        "iso": "US"
    },
    {
        "label": "LAC",
        "value": "LAC",
        "iso": "CN"
    },
    {
        "label": "TIA",
        "value": "TIA",
        "iso": "AL"
    },
    {
        "label": "BOJ",
        "value": "BOJ",
        "iso": "BG"
    },
    {
        "label": "GOZ",
        "value": "GOZ",
        "iso": "BG"
    },
    {
        "label": "PDV",
        "value": "PDV",
        "iso": "BG"
    },
    {
        "label": "PVN",
        "value": "PVN",
        "iso": "BG"
    },
    {
        "label": "SOF",
        "value": "SOF",
        "iso": "BG"
    },
    {
        "label": "SLS",
        "value": "SLS",
        "iso": "BG"
    },
    {
        "label": "SZR",
        "value": "SZR",
        "iso": "BG"
    },
    {
        "label": "VID",
        "value": "VID",
        "iso": "BG"
    },
    {
        "label": "VAR",
        "value": "VAR",
        "iso": "BG"
    },
    {
        "label": "ECN",
        "value": "ECN",
        "iso": "CY"
    },
    {
        "label": "LCA",
        "value": "LCA",
        "iso": "CY"
    },
    {
        "label": "LCP",
        "value": "LCP",
        "iso": "AR"
    },
    {
        "label": "PFO",
        "value": "PFO",
        "iso": "CY"
    },
    {
        "label": "AKT",
        "value": "AKT",
        "iso": "CY"
    },
    {
        "label": "DBV",
        "value": "DBV",
        "iso": "HR"
    },
    {
        "label": "LSZ",
        "value": "LSZ",
        "iso": "HR"
    },
    {
        "label": "OSI",
        "value": "OSI",
        "iso": "HR"
    },
    {
        "label": "PUY",
        "value": "PUY",
        "iso": "HR"
    },
    {
        "label": "RJK",
        "value": "RJK",
        "iso": "HR"
    },
    {
        "label": "BWK",
        "value": "BWK",
        "iso": "HR"
    },
    {
        "label": "SPU",
        "value": "SPU",
        "iso": "HR"
    },
    {
        "label": "ZAG",
        "value": "ZAG",
        "iso": "HR"
    },
    {
        "label": "ZAD",
        "value": "ZAD",
        "iso": "HR"
    },
    {
        "label": "ABC",
        "value": "ABC",
        "iso": "ES"
    },
    {
        "label": "ALC",
        "value": "ALC",
        "iso": "ES"
    },
    {
        "label": "LEI",
        "value": "LEI",
        "iso": "ES"
    },
    {
        "label": "OVD",
        "value": "OVD",
        "iso": "ES"
    },
    {
        "label": "ODB",
        "value": "ODB",
        "iso": "ES"
    },
    {
        "label": "BIO",
        "value": "BIO",
        "iso": "ES"
    },
    {
        "label": "RGS",
        "value": "RGS",
        "iso": "ES"
    },
    {
        "label": "BCN",
        "value": "BCN",
        "iso": "ES"
    },
    {
        "label": "BJZ",
        "value": "BJZ",
        "iso": "ES"
    },
    {
        "label": "CDT",
        "value": "CDT",
        "iso": "ES"
    },
    {
        "label": "LCG",
        "value": "LCG",
        "iso": "ES"
    },
    {
        "label": "GRO",
        "value": "GRO",
        "iso": "ES"
    },
    {
        "label": "GRX",
        "value": "GRX",
        "iso": "ES"
    },
    {
        "label": "CCX",
        "value": "CCX",
        "iso": "ES"
    },
    {
        "label": "IBZ",
        "value": "IBZ",
        "iso": "ES"
    },
    {
        "label": "XRY",
        "value": "XRY",
        "iso": "ES"
    },
    {
        "label": "MJV",
        "value": "MJV",
        "iso": "ES"
    },
    {
        "label": "QSA",
        "value": "QSA",
        "iso": "ES"
    },
    {
        "label": "LEN",
        "value": "LEN",
        "iso": "ES"
    },
    {
        "label": "RJL",
        "value": "RJL",
        "iso": "ES"
    },
    {
        "label": "MAD",
        "value": "MAD",
        "iso": "ES"
    },
    {
        "label": "HEV",
        "value": "HEV",
        "iso": "ES"
    },
    {
        "label": "AGP",
        "value": "AGP",
        "iso": "ES"
    },
    {
        "label": "MAH",
        "value": "MAH",
        "iso": "ES"
    },
    {
        "label": "OZP",
        "value": "OZP",
        "iso": "ES"
    },
    {
        "label": "PMI",
        "value": "PMI",
        "iso": "ES"
    },
    {
        "label": "PNA",
        "value": "PNA",
        "iso": "ES"
    },
    {
        "label": "CQM",
        "value": "CQM",
        "iso": "ES"
    },
    {
        "label": "REU",
        "value": "REU",
        "iso": "ES"
    },
    {
        "label": "SLM",
        "value": "SLM",
        "iso": "ES"
    },
    {
        "label": "EAS",
        "value": "EAS",
        "iso": "ES"
    },
    {
        "label": "SCQ",
        "value": "SCQ",
        "iso": "ES"
    },
    {
        "label": "LEU",
        "value": "LEU",
        "iso": "ES"
    },
    {
        "label": "TOJ",
        "value": "TOJ",
        "iso": "ES"
    },
    {
        "label": "VLC",
        "value": "VLC",
        "iso": "ES"
    },
    {
        "label": "VLL",
        "value": "VLL",
        "iso": "ES"
    },
    {
        "label": "VIT",
        "value": "VIT",
        "iso": "ES"
    },
    {
        "label": "VGO",
        "value": "VGO",
        "iso": "ES"
    },
    {
        "label": "SDR",
        "value": "SDR",
        "iso": "ES"
    },
    {
        "label": "ZAZ",
        "value": "ZAZ",
        "iso": "ES"
    },
    {
        "label": "SVQ",
        "value": "SVQ",
        "iso": "ES"
    },
    {
        "label": "DPE",
        "value": "DPE",
        "iso": "FR"
    },
    {
        "label": "CQF",
        "value": "CQF",
        "iso": "FR"
    },
    {
        "label": "XCP",
        "value": "XCP",
        "iso": "FR"
    },
    {
        "label": "XLN",
        "value": "XLN",
        "iso": "FR"
    },
    {
        "label": "XSJ",
        "value": "XSJ",
        "iso": "FR"
    },
    {
        "label": "XDK",
        "value": "XDK",
        "iso": "FR"
    },
    {
        "label": "BYF",
        "value": "BYF",
        "iso": "FR"
    },
    {
        "label": "LTQ",
        "value": "LTQ",
        "iso": "FR"
    },
    {
        "label": "XVS",
        "value": "XVS",
        "iso": "FR"
    },
    {
        "label": "QAM",
        "value": "QAM",
        "iso": "FR"
    },
    {
        "label": "AGF",
        "value": "AGF",
        "iso": "FR"
    },
    {
        "label": "BOD",
        "value": "BOD",
        "iso": "FR"
    },
    {
        "label": "EGC",
        "value": "EGC",
        "iso": "FR"
    },
    {
        "label": "CNG",
        "value": "CNG",
        "iso": "FR"
    },
    {
        "label": "LRH",
        "value": "LRH",
        "iso": "FR"
    },
    {
        "label": "PIS",
        "value": "PIS",
        "iso": "FR"
    },
    {
        "label": "MCU",
        "value": "MCU",
        "iso": "FR"
    },
    {
        "label": "LIG",
        "value": "LIG",
        "iso": "FR"
    },
    {
        "label": "XMJ",
        "value": "XMJ",
        "iso": "FR"
    },
    {
        "label": "NIT",
        "value": "NIT",
        "iso": "FR"
    },
    {
        "label": "TLS",
        "value": "TLS",
        "iso": "FR"
    },
    {
        "label": "PUF",
        "value": "PUF",
        "iso": "FR"
    },
    {
        "label": "LDE",
        "value": "LDE",
        "iso": "FR"
    },
    {
        "label": "ANG",
        "value": "ANG",
        "iso": "FR"
    },
    {
        "label": "BVE",
        "value": "BVE",
        "iso": "FR"
    },
    {
        "label": "PGX",
        "value": "PGX",
        "iso": "FR"
    },
    {
        "label": "XDA",
        "value": "XDA",
        "iso": "FR"
    },
    {
        "label": "BIQ",
        "value": "BIQ",
        "iso": "FR"
    },
    {
        "label": "XCX",
        "value": "XCX",
        "iso": "FR"
    },
    {
        "label": "ZAO",
        "value": "ZAO",
        "iso": "FR"
    },
    {
        "label": "XGT",
        "value": "XGT",
        "iso": "FR"
    },
    {
        "label": "XAC",
        "value": "XAC",
        "iso": "FR"
    },
    {
        "label": "LBI",
        "value": "LBI",
        "iso": "FR"
    },
    {
        "label": "DCM",
        "value": "DCM",
        "iso": "FR"
    },
    {
        "label": "RDZ",
        "value": "RDZ",
        "iso": "FR"
    },
    {
        "label": "RYN",
        "value": "RYN",
        "iso": "FR"
    },
    {
        "label": "XMW",
        "value": "XMW",
        "iso": "FR"
    },
    {
        "label": "XLR",
        "value": "XLR",
        "iso": "FR"
    },
    {
        "label": "RCO",
        "value": "RCO",
        "iso": "FR"
    },
    {
        "label": "XSL",
        "value": "XSL",
        "iso": "FR"
    },
    {
        "label": "XTB",
        "value": "XTB",
        "iso": "FR"
    },
    {
        "label": "IDY",
        "value": "IDY",
        "iso": "FR"
    },
    {
        "label": "CMR",
        "value": "CMR",
        "iso": "FR"
    },
    {
        "label": "XBV",
        "value": "XBV",
        "iso": "FR"
    },
    {
        "label": "DLE",
        "value": "DLE",
        "iso": "FR"
    },
    {
        "label": "XVN",
        "value": "XVN",
        "iso": "FR"
    },
    {
        "label": "MVV",
        "value": "MVV",
        "iso": "FR"
    },
    {
        "label": "OBS",
        "value": "OBS",
        "iso": "FR"
    },
    {
        "label": "LPY",
        "value": "LPY",
        "iso": "FR"
    },
    {
        "label": "AHZ",
        "value": "AHZ",
        "iso": "FR"
    },
    {
        "label": "ETZ",
        "value": "ETZ",
        "iso": "FR"
    },
    {
        "label": "ANE",
        "value": "ANE",
        "iso": "FR"
    },
    {
        "label": "BIA",
        "value": "BIA",
        "iso": "FR"
    },
    {
        "label": "CLY",
        "value": "CLY",
        "iso": "FR"
    },
    {
        "label": "FSC",
        "value": "FSC",
        "iso": "FR"
    },
    {
        "label": "AJA",
        "value": "AJA",
        "iso": "FR"
    },
    {
        "label": "PRP",
        "value": "PRP",
        "iso": "FR"
    },
    {
        "label": "SOZ",
        "value": "SOZ",
        "iso": "FR"
    },
    {
        "label": "MFX",
        "value": "MFX",
        "iso": "FR"
    },
    {
        "label": "AUF",
        "value": "AUF",
        "iso": "FR"
    },
    {
        "label": "CMF",
        "value": "CMF",
        "iso": "FR"
    },
    {
        "label": "CFE",
        "value": "CFE",
        "iso": "FR"
    },
    {
        "label": "BOU",
        "value": "BOU",
        "iso": "FR"
    },
    {
        "label": "QNJ",
        "value": "QNJ",
        "iso": "FR"
    },
    {
        "label": "CVF",
        "value": "CVF",
        "iso": "FR"
    },
    {
        "label": "LYS",
        "value": "LYS",
        "iso": "FR"
    },
    {
        "label": "QNX",
        "value": "QNX",
        "iso": "FR"
    },
    {
        "label": "SYT",
        "value": "SYT",
        "iso": "FR"
    },
    {
        "label": "RNE",
        "value": "RNE",
        "iso": "FR"
    },
    {
        "label": "NCY",
        "value": "NCY",
        "iso": "FR"
    },
    {
        "label": "XMK",
        "value": "XMK",
        "iso": "FR"
    },
    {
        "label": "GNB",
        "value": "GNB",
        "iso": "FR"
    },
    {
        "label": "MCU",
        "value": "MCU",
        "iso": "FR"
    },
    {
        "label": "VAF",
        "value": "VAF",
        "iso": "FR"
    },
    {
        "label": "VHY",
        "value": "VHY",
        "iso": "FR"
    },
    {
        "label": "AUR",
        "value": "AUR",
        "iso": "FR"
    },
    {
        "label": "CHR",
        "value": "CHR",
        "iso": "FR"
    },
    {
        "label": "LYN",
        "value": "LYN",
        "iso": "FR"
    },
    {
        "label": "CEQ",
        "value": "CEQ",
        "iso": "FR"
    },
    {
        "label": "EBU",
        "value": "EBU",
        "iso": "FR"
    },
    {
        "label": "QIE",
        "value": "QIE",
        "iso": "FR"
    },
    {
        "label": "CCF",
        "value": "CCF",
        "iso": "FR"
    },
    {
        "label": "MRS",
        "value": "MRS",
        "iso": "FR"
    },
    {
        "label": "NCE",
        "value": "NCE",
        "iso": "FR"
    },
    {
        "label": "XOG",
        "value": "XOG",
        "iso": "FR"
    },
    {
        "label": "PGF",
        "value": "PGF",
        "iso": "FR"
    },
    {
        "label": "CTT",
        "value": "CTT",
        "iso": "FR"
    },
    {
        "label": "BAE",
        "value": "BAE",
        "iso": "FR"
    },
    {
        "label": "XAS",
        "value": "XAS",
        "iso": "FR"
    },
    {
        "label": "MPL",
        "value": "MPL",
        "iso": "FR"
    },
    {
        "label": "BZR",
        "value": "BZR",
        "iso": "FR"
    },
    {
        "label": "AVN",
        "value": "AVN",
        "iso": "FR"
    },
    {
        "label": "GAT",
        "value": "GAT",
        "iso": "FR"
    },
    {
        "label": "MEN",
        "value": "MEN",
        "iso": "FR"
    },
    {
        "label": "SCP",
        "value": "SCP",
        "iso": "FR"
    },
    {
        "label": "BVA",
        "value": "BVA",
        "iso": "FR"
    },
    {
        "label": "XSU",
        "value": "XSU",
        "iso": "FR"
    },
    {
        "label": "EVX",
        "value": "EVX",
        "iso": "FR"
    },
    {
        "label": "XAN",
        "value": "XAN",
        "iso": "FR"
    },
    {
        "label": "LEH",
        "value": "LEH",
        "iso": "FR"
    },
    {
        "label": "XAB",
        "value": "XAB",
        "iso": "FR"
    },
    {
        "label": "ORE",
        "value": "ORE",
        "iso": "FR"
    },
    {
        "label": "XCR",
        "value": "XCR",
        "iso": "FR"
    },
    {
        "label": "LSO",
        "value": "LSO",
        "iso": "FR"
    },
    {
        "label": "URO",
        "value": "URO",
        "iso": "FR"
    },
    {
        "label": "XBQ",
        "value": "XBQ",
        "iso": "FR"
    },
    {
        "label": "QTJ",
        "value": "QTJ",
        "iso": "FR"
    },
    {
        "label": "TUF",
        "value": "TUF",
        "iso": "FR"
    },
    {
        "label": "CET",
        "value": "CET",
        "iso": "FR"
    },
    {
        "label": "LVA",
        "value": "LVA",
        "iso": "FR"
    },
    {
        "label": "LBG",
        "value": "LBG",
        "iso": "FR"
    },
    {
        "label": "CSF",
        "value": "CSF",
        "iso": "FR"
    },
    {
        "label": "CDG",
        "value": "CDG",
        "iso": "FR"
    },
    {
        "label": "JDP",
        "value": "JDP",
        "iso": "FR"
    },
    {
        "label": "TNF",
        "value": "TNF",
        "iso": "FR"
    },
    {
        "label": "ORY",
        "value": "ORY",
        "iso": "FR"
    },
    {
        "label": "POX",
        "value": "POX",
        "iso": "FR"
    },
    {
        "label": "VIY",
        "value": "VIY",
        "iso": "FR"
    },
    {
        "label": "QYR",
        "value": "QYR",
        "iso": "FR"
    },
    {
        "label": "NVS",
        "value": "NVS",
        "iso": "FR"
    },
    {
        "label": "XCB",
        "value": "XCB",
        "iso": "FR"
    },
    {
        "label": "XME",
        "value": "XME",
        "iso": "FR"
    },
    {
        "label": "LIL",
        "value": "LIL",
        "iso": "FR"
    },
    {
        "label": "HZB",
        "value": "HZB",
        "iso": "FR"
    },
    {
        "label": "XCZ",
        "value": "XCZ",
        "iso": "FR"
    },
    {
        "label": "XVO",
        "value": "XVO",
        "iso": "FR"
    },
    {
        "label": "BES",
        "value": "BES",
        "iso": "FR"
    },
    {
        "label": "CER",
        "value": "CER",
        "iso": "FR"
    },
    {
        "label": "DNR",
        "value": "DNR",
        "iso": "FR"
    },
    {
        "label": "LBY",
        "value": "LBY",
        "iso": "FR"
    },
    {
        "label": "GFR",
        "value": "GFR",
        "iso": "FR"
    },
    {
        "label": "DOL",
        "value": "DOL",
        "iso": "FR"
    },
    {
        "label": "LRT",
        "value": "LRT",
        "iso": "FR"
    },
    {
        "label": "EDM",
        "value": "EDM",
        "iso": "FR"
    },
    {
        "label": "LDV",
        "value": "LDV",
        "iso": "FR"
    },
    {
        "label": "CFR",
        "value": "CFR",
        "iso": "FR"
    },
    {
        "label": "LME",
        "value": "LME",
        "iso": "FR"
    },
    {
        "label": "RNS",
        "value": "RNS",
        "iso": "FR"
    },
    {
        "label": "LAI",
        "value": "LAI",
        "iso": "FR"
    },
    {
        "label": "UIP",
        "value": "UIP",
        "iso": "FR"
    },
    {
        "label": "NTE",
        "value": "NTE",
        "iso": "FR"
    },
    {
        "label": "SBK",
        "value": "SBK",
        "iso": "FR"
    },
    {
        "label": "MXN",
        "value": "MXN",
        "iso": "FR"
    },
    {
        "label": "VNE",
        "value": "VNE",
        "iso": "FR"
    },
    {
        "label": "SNR",
        "value": "SNR",
        "iso": "FR"
    },
    {
        "label": "QBQ",
        "value": "QBQ",
        "iso": "FR"
    },
    {
        "label": "BSL",
        "value": "BSL",
        "iso": "FR"
    },
    {
        "label": "DIJ",
        "value": "DIJ",
        "iso": "FR"
    },
    {
        "label": "MZM",
        "value": "MZM",
        "iso": "FR"
    },
    {
        "label": "EPL",
        "value": "EPL",
        "iso": "FR"
    },
    {
        "label": "XMF",
        "value": "XMF",
        "iso": "FR"
    },
    {
        "label": "ENC",
        "value": "ENC",
        "iso": "FR"
    },
    {
        "label": "BOR",
        "value": "BOR",
        "iso": "FR"
    },
    {
        "label": "RHE",
        "value": "RHE",
        "iso": "FR"
    },
    {
        "label": "SXB",
        "value": "SXB",
        "iso": "FR"
    },
    {
        "label": "VTL",
        "value": "VTL",
        "iso": "FR"
    },
    {
        "label": "XHE",
        "value": "XHE",
        "iso": "FR"
    },
    {
        "label": "FNI",
        "value": "FNI",
        "iso": "FR"
    },
    {
        "label": "LTT",
        "value": "LTT",
        "iso": "FR"
    },
    {
        "label": "MQC",
        "value": "MQC",
        "iso": "PM"
    },
    {
        "label": "FSP",
        "value": "FSP",
        "iso": "PM"
    },
    {
        "label": "PYR",
        "value": "PYR",
        "iso": "GR"
    },
    {
        "label": "AGQ",
        "value": "AGQ",
        "iso": "GR"
    },
    {
        "label": "AXD",
        "value": "AXD",
        "iso": "GR"
    },
    {
        "label": "HEW",
        "value": "HEW",
        "iso": "GR"
    },
    {
        "label": "ATH",
        "value": "ATH",
        "iso": "GR"
    },
    {
        "label": "VOL",
        "value": "VOL",
        "iso": "GR"
    },
    {
        "label": "JKH",
        "value": "JKH",
        "iso": "GR"
    },
    {
        "label": "PKH",
        "value": "PKH",
        "iso": "GR"
    },
    {
        "label": "JIK",
        "value": "JIK",
        "iso": "GR"
    },
    {
        "label": "IOA",
        "value": "IOA",
        "iso": "GR"
    },
    {
        "label": "HER",
        "value": "HER",
        "iso": "GR"
    },
    {
        "label": "KSO",
        "value": "KSO",
        "iso": "GR"
    },
    {
        "label": "KIT",
        "value": "KIT",
        "iso": "GR"
    },
    {
        "label": "EFL",
        "value": "EFL",
        "iso": "GR"
    },
    {
        "label": "KZS",
        "value": "KZS",
        "iso": "GR"
    },
    {
        "label": "KLX",
        "value": "KLX",
        "iso": "GR"
    },
    {
        "label": "KGS",
        "value": "KGS",
        "iso": "GR"
    },
    {
        "label": "AOK",
        "value": "AOK",
        "iso": "GR"
    },
    {
        "label": "CFU",
        "value": "CFU",
        "iso": "GR"
    },
    {
        "label": "KSJ",
        "value": "KSJ",
        "iso": "GR"
    },
    {
        "label": "KVA",
        "value": "KVA",
        "iso": "GR"
    },
    {
        "label": "KZI",
        "value": "KZI",
        "iso": "GR"
    },
    {
        "label": "LRS",
        "value": "LRS",
        "iso": "GR"
    },
    {
        "label": "LXS",
        "value": "LXS",
        "iso": "GR"
    },
    {
        "label": "LRA",
        "value": "LRA",
        "iso": "GR"
    },
    {
        "label": "JMK",
        "value": "JMK",
        "iso": "GR"
    },
    {
        "label": "MLO",
        "value": "MLO",
        "iso": "GR"
    },
    {
        "label": "MJT",
        "value": "MJT",
        "iso": "GR"
    },
    {
        "label": "JNX",
        "value": "JNX",
        "iso": "GR"
    },
    {
        "label": "PAS",
        "value": "PAS",
        "iso": "GR"
    },
    {
        "label": "JTY",
        "value": "JTY",
        "iso": "GR"
    },
    {
        "label": "PVK",
        "value": "PVK",
        "iso": "GR"
    },
    {
        "label": "RHO",
        "value": "RHO",
        "iso": "GR"
    },
    {
        "label": "GPA",
        "value": "GPA",
        "iso": "GR"
    },
    {
        "label": "CHQ",
        "value": "CHQ",
        "iso": "GR"
    },
    {
        "label": "JSI",
        "value": "JSI",
        "iso": "GR"
    },
    {
        "label": "SMI",
        "value": "SMI",
        "iso": "GR"
    },
    {
        "label": "JSY",
        "value": "JSY",
        "iso": "GR"
    },
    {
        "label": "SPJ",
        "value": "SPJ",
        "iso": "GR"
    },
    {
        "label": "JTR",
        "value": "JTR",
        "iso": "GR"
    },
    {
        "label": "JSH",
        "value": "JSH",
        "iso": "GR"
    },
    {
        "label": "SKU",
        "value": "SKU",
        "iso": "GR"
    },
    {
        "label": "SKG",
        "value": "SKG",
        "iso": "GR"
    },
    {
        "label": "ZTH",
        "value": "ZTH",
        "iso": "GR"
    },
    {
        "label": "BUD",
        "value": "BUD",
        "iso": "HU"
    },
    {
        "label": "DEB",
        "value": "DEB",
        "iso": "HU"
    },
    {
        "label": "MCQ",
        "value": "MCQ",
        "iso": "HU"
    },
    {
        "label": "PEV",
        "value": "PEV",
        "iso": "HU"
    },
    {
        "label": "QGY",
        "value": "QGY",
        "iso": "HU"
    },
    {
        "label": "SOB",
        "value": "SOB",
        "iso": "HU"
    },
    {
        "label": "TZR",
        "value": "TZR",
        "iso": "HU"
    },
    {
        "label": "QZD",
        "value": "QZD",
        "iso": "HU"
    },
    {
        "label": "CRV",
        "value": "CRV",
        "iso": "IT"
    },
    {
        "label": "BRI",
        "value": "BRI",
        "iso": "IT"
    },
    {
        "label": "FOG",
        "value": "FOG",
        "iso": "IT"
    },
    {
        "label": "TAR",
        "value": "TAR",
        "iso": "IT"
    },
    {
        "label": "LCC",
        "value": "LCC",
        "iso": "IT"
    },
    {
        "label": "PSR",
        "value": "PSR",
        "iso": "IT"
    },
    {
        "label": "BDS",
        "value": "BDS",
        "iso": "IT"
    },
    {
        "label": "SUF",
        "value": "SUF",
        "iso": "IT"
    },
    {
        "label": "CIY",
        "value": "CIY",
        "iso": "IT"
    },
    {
        "label": "CTA",
        "value": "CTA",
        "iso": "IT"
    },
    {
        "label": "LMP",
        "value": "LMP",
        "iso": "IT"
    },
    {
        "label": "PNL",
        "value": "PNL",
        "iso": "IT"
    },
    {
        "label": "PMO",
        "value": "PMO",
        "iso": "IT"
    },
    {
        "label": "REG",
        "value": "REG",
        "iso": "IT"
    },
    {
        "label": "TPS",
        "value": "TPS",
        "iso": "IT"
    },
    {
        "label": "NSY",
        "value": "NSY",
        "iso": "IT"
    },
    {
        "label": "BLX",
        "value": "BLX",
        "iso": "IT"
    },
    {
        "label": "RAN",
        "value": "RAN",
        "iso": "IT"
    },
    {
        "label": "ZIA",
        "value": "ZIA",
        "iso": "IT"
    },
    {
        "label": "AHO",
        "value": "AHO",
        "iso": "IT"
    },
    {
        "label": "DCI",
        "value": "DCI",
        "iso": "IT"
    },
    {
        "label": "CAG",
        "value": "CAG",
        "iso": "IT"
    },
    {
        "label": "OLB",
        "value": "OLB",
        "iso": "IT"
    },
    {
        "label": "TTB",
        "value": "TTB",
        "iso": "IT"
    },
    {
        "label": "QVA",
        "value": "QVA",
        "iso": "IT"
    },
    {
        "label": "QMM",
        "value": "QMM",
        "iso": "IT"
    },
    {
        "label": "MXP",
        "value": "MXP",
        "iso": "IT"
    },
    {
        "label": "BGY",
        "value": "BGY",
        "iso": "IT"
    },
    {
        "label": "TRN",
        "value": "TRN",
        "iso": "IT"
    },
    {
        "label": "ALL",
        "value": "ALL",
        "iso": "IT"
    },
    {
        "label": "GOA",
        "value": "GOA",
        "iso": "IT"
    },
    {
        "label": "LIN",
        "value": "LIN",
        "iso": "IT"
    },
    {
        "label": "PMF",
        "value": "PMF",
        "iso": "IT"
    },
    {
        "label": "QPZ",
        "value": "QPZ",
        "iso": "IT"
    },
    {
        "label": "AOT",
        "value": "AOT",
        "iso": "IT"
    },
    {
        "label": "CUF",
        "value": "CUF",
        "iso": "IT"
    },
    {
        "label": "AVB",
        "value": "AVB",
        "iso": "IT"
    },
    {
        "label": "BZO",
        "value": "BZO",
        "iso": "IT"
    },
    {
        "label": "UDN",
        "value": "UDN",
        "iso": "IT"
    },
    {
        "label": "BLQ",
        "value": "BLQ",
        "iso": "IT"
    },
    {
        "label": "TSF",
        "value": "TSF",
        "iso": "IT"
    },
    {
        "label": "FRL",
        "value": "FRL",
        "iso": "IT"
    },
    {
        "label": "VBS",
        "value": "VBS",
        "iso": "IT"
    },
    {
        "label": "TRS",
        "value": "TRS",
        "iso": "IT"
    },
    {
        "label": "RMI",
        "value": "RMI",
        "iso": "IT"
    },
    {
        "label": "VIC",
        "value": "VIC",
        "iso": "IT"
    },
    {
        "label": "QPA",
        "value": "QPA",
        "iso": "IT"
    },
    {
        "label": "VRN",
        "value": "VRN",
        "iso": "IT"
    },
    {
        "label": "AOI",
        "value": "AOI",
        "iso": "IT"
    },
    {
        "label": "VCE",
        "value": "VCE",
        "iso": "IT"
    },
    {
        "label": "QZO",
        "value": "QZO",
        "iso": "IT"
    },
    {
        "label": "LCV",
        "value": "LCV",
        "iso": "IT"
    },
    {
        "label": "QRT",
        "value": "QRT",
        "iso": "IT"
    },
    {
        "label": "SAY",
        "value": "SAY",
        "iso": "IT"
    },
    {
        "label": "CIA",
        "value": "CIA",
        "iso": "IT"
    },
    {
        "label": "FCO",
        "value": "FCO",
        "iso": "IT"
    },
    {
        "label": "QFR",
        "value": "QFR",
        "iso": "IT"
    },
    {
        "label": "QSR",
        "value": "QSR",
        "iso": "IT"
    },
    {
        "label": "EBA",
        "value": "EBA",
        "iso": "IT"
    },
    {
        "label": "QLT",
        "value": "QLT",
        "iso": "IT"
    },
    {
        "label": "NAP",
        "value": "NAP",
        "iso": "IT"
    },
    {
        "label": "PSA",
        "value": "PSA",
        "iso": "IT"
    },
    {
        "label": "FLR",
        "value": "FLR",
        "iso": "IT"
    },
    {
        "label": "GRS",
        "value": "GRS",
        "iso": "IT"
    },
    {
        "label": "PEG",
        "value": "PEG",
        "iso": "IT"
    },
    {
        "label": "LJU",
        "value": "LJU",
        "iso": "SI"
    },
    {
        "label": "MBX",
        "value": "MBX",
        "iso": "SI"
    },
    {
        "label": "POW",
        "value": "POW",
        "iso": "SI"
    },
    {
        "label": "GTW",
        "value": "GTW",
        "iso": "CZ"
    },
    {
        "label": "UHE",
        "value": "UHE",
        "iso": "CZ"
    },
    {
        "label": "KLV",
        "value": "KLV",
        "iso": "CZ"
    },
    {
        "label": "MKA",
        "value": "MKA",
        "iso": "CZ"
    },
    {
        "label": "OSR",
        "value": "OSR",
        "iso": "CZ"
    },
    {
        "label": "PED",
        "value": "PED",
        "iso": "CZ"
    },
    {
        "label": "PRV",
        "value": "PRV",
        "iso": "CZ"
    },
    {
        "label": "PRG",
        "value": "PRG",
        "iso": "CZ"
    },
    {
        "label": "BRQ",
        "value": "BRQ",
        "iso": "CZ"
    },
    {
        "label": "ZBE",
        "value": "ZBE",
        "iso": "CZ"
    },
    {
        "label": "GHK",
        "value": "GHK",
        "iso": "PS"
    },
    {
        "label": "TLV",
        "value": "TLV",
        "iso": "IL"
    },
    {
        "label": "BEV",
        "value": "BEV",
        "iso": "IL"
    },
    {
        "label": "ETH",
        "value": "ETH",
        "iso": "IL"
    },
    {
        "label": "EIY",
        "value": "EIY",
        "iso": "IL"
    },
    {
        "label": "HFA",
        "value": "HFA",
        "iso": "IL"
    },
    {
        "label": "RPN",
        "value": "RPN",
        "iso": "IL"
    },
    {
        "label": "JRS",
        "value": "JRS",
        "iso": "PS"
    },
    {
        "label": "KSW",
        "value": "KSW",
        "iso": "IL"
    },
    {
        "label": "MTZ",
        "value": "MTZ",
        "iso": "IL"
    },
    {
        "label": "VTM",
        "value": "VTM",
        "iso": "IL"
    },
    {
        "label": "VDA",
        "value": "VDA",
        "iso": "IL"
    },
    {
        "label": "MIP",
        "value": "MIP",
        "iso": "IL"
    },
    {
        "label": "SDV",
        "value": "SDV",
        "iso": "IL"
    },
    {
        "label": "YOT",
        "value": "YOT",
        "iso": "IL"
    },
    {
        "label": "GZM",
        "value": "GZM",
        "iso": "MT"
    },
    {
        "label": "MLA",
        "value": "MLA",
        "iso": "MT"
    },
    {
        "label": "HOH",
        "value": "HOH",
        "iso": "AT"
    },
    {
        "label": "LOR",
        "value": "LOR",
        "iso": "US"
    },
    {
        "label": "GRZ",
        "value": "GRZ",
        "iso": "AT"
    },
    {
        "label": "INN",
        "value": "INN",
        "iso": "AT"
    },
    {
        "label": "KLU",
        "value": "KLU",
        "iso": "AT"
    },
    {
        "label": "LNZ",
        "value": "LNZ",
        "iso": "AT"
    },
    {
        "label": "SZG",
        "value": "SZG",
        "iso": "AT"
    },
    {
        "label": "VIE",
        "value": "VIE",
        "iso": "AT"
    },
    {
        "label": "SMA",
        "value": "SMA",
        "iso": "PT"
    },
    {
        "label": "BGC",
        "value": "BGC",
        "iso": "PT"
    },
    {
        "label": "BYJ",
        "value": "BYJ",
        "iso": "PT"
    },
    {
        "label": "BGZ",
        "value": "BGZ",
        "iso": "PT"
    },
    {
        "label": "CHV",
        "value": "CHV",
        "iso": "PT"
    },
    {
        "label": "CBP",
        "value": "CBP",
        "iso": "PT"
    },
    {
        "label": "CVU",
        "value": "CVU",
        "iso": "PT"
    },
    {
        "label": "COV",
        "value": "COV",
        "iso": "PT"
    },
    {
        "label": "FLW",
        "value": "FLW",
        "iso": "PT"
    },
    {
        "label": "FAO",
        "value": "FAO",
        "iso": "PT"
    },
    {
        "label": "GRW",
        "value": "GRW",
        "iso": "PT"
    },
    {
        "label": "HOR",
        "value": "HOR",
        "iso": "PT"
    },
    {
        "label": "TER",
        "value": "TER",
        "iso": "PT"
    },
    {
        "label": "FNC",
        "value": "FNC",
        "iso": "PT"
    },
    {
        "label": "PAF",
        "value": "PAF",
        "iso": "PT"
    },
    {
        "label": "PDL",
        "value": "PDL",
        "iso": "PT"
    },
    {
        "label": "PIX",
        "value": "PIX",
        "iso": "PT"
    },
    {
        "label": "PRM",
        "value": "PRM",
        "iso": "PT"
    },
    {
        "label": "OPO",
        "value": "OPO",
        "iso": "PT"
    },
    {
        "label": "PXO",
        "value": "PXO",
        "iso": "PT"
    },
    {
        "label": "LIS",
        "value": "LIS",
        "iso": "PT"
    },
    {
        "label": "SIE",
        "value": "SIE",
        "iso": "PT"
    },
    {
        "label": "SJZ",
        "value": "SJZ",
        "iso": "PT"
    },
    {
        "label": "VRL",
        "value": "VRL",
        "iso": "PT"
    },
    {
        "label": "VSE",
        "value": "VSE",
        "iso": "PT"
    },
    {
        "label": "BNX",
        "value": "BNX",
        "iso": "BA"
    },
    {
        "label": "OMO",
        "value": "OMO",
        "iso": "BA"
    },
    {
        "label": "SJJ",
        "value": "SJJ",
        "iso": "BA"
    },
    {
        "label": "TZL",
        "value": "TZL",
        "iso": "BA"
    },
    {
        "label": "ARW",
        "value": "ARW",
        "iso": "RO"
    },
    {
        "label": "BCM",
        "value": "BCM",
        "iso": "RO"
    },
    {
        "label": "BAY",
        "value": "BAY",
        "iso": "RO"
    },
    {
        "label": "BBU",
        "value": "BBU",
        "iso": "RO"
    },
    {
        "label": "CND",
        "value": "CND",
        "iso": "RO"
    },
    {
        "label": "CLJ",
        "value": "CLJ",
        "iso": "RO"
    },
    {
        "label": "CSB",
        "value": "CSB",
        "iso": "RO"
    },
    {
        "label": "CRA",
        "value": "CRA",
        "iso": "RO"
    },
    {
        "label": "IAS",
        "value": "IAS",
        "iso": "RO"
    },
    {
        "label": "LRO",
        "value": "LRO",
        "iso": "US"
    },
    {
        "label": "OMR",
        "value": "OMR",
        "iso": "RO"
    },
    {
        "label": "OTP",
        "value": "OTP",
        "iso": "RO"
    },
    {
        "label": "SBZ",
        "value": "SBZ",
        "iso": "RO"
    },
    {
        "label": "SUJ",
        "value": "SUJ",
        "iso": "RO"
    },
    {
        "label": "SCV",
        "value": "SCV",
        "iso": "RO"
    },
    {
        "label": "TCE",
        "value": "TCE",
        "iso": "RO"
    },
    {
        "label": "TGM",
        "value": "TGM",
        "iso": "RO"
    },
    {
        "label": "TSR",
        "value": "TSR",
        "iso": "RO"
    },
    {
        "label": "GVA",
        "value": "GVA",
        "iso": "CH"
    },
    {
        "label": "QNC",
        "value": "QNC",
        "iso": "CH"
    },
    {
        "label": "SIR",
        "value": "SIR",
        "iso": "CH"
    },
    {
        "label": "ZIN",
        "value": "ZIN",
        "iso": "CH"
    },
    {
        "label": "LUG",
        "value": "LUG",
        "iso": "CH"
    },
    {
        "label": "BRN",
        "value": "BRN",
        "iso": "CH"
    },
    {
        "label": "BXO",
        "value": "BXO",
        "iso": "CH"
    },
    {
        "label": "ACO",
        "value": "ACO",
        "iso": "CH"
    },
    {
        "label": "ZHI",
        "value": "ZHI",
        "iso": "CH"
    },
    {
        "label": "ZRH",
        "value": "ZRH",
        "iso": "CH"
    },
    {
        "label": "ZJI",
        "value": "ZJI",
        "iso": "CH"
    },
    {
        "label": "ACH",
        "value": "ACH",
        "iso": "CH"
    },
    {
        "label": "SMV",
        "value": "SMV",
        "iso": "CH"
    },
    {
        "label": "GKD",
        "value": "GKD",
        "iso": "TR"
    },
    {
        "label": "ESB",
        "value": "ESB",
        "iso": "TR"
    },
    {
        "label": "ANK",
        "value": "ANK",
        "iso": "TR"
    },
    {
        "label": "ADA",
        "value": "ADA",
        "iso": "TR"
    },
    {
        "label": "UAB",
        "value": "UAB",
        "iso": "TR"
    },
    {
        "label": "AFY",
        "value": "AFY",
        "iso": "TR"
    },
    {
        "label": "AYT",
        "value": "AYT",
        "iso": "TR"
    },
    {
        "label": "GZT",
        "value": "GZT",
        "iso": "TR"
    },
    {
        "label": "ISK",
        "value": "ISK",
        "iso": "TR"
    },
    {
        "label": "KFS",
        "value": "KFS",
        "iso": "TR"
    },
    {
        "label": "KYA",
        "value": "KYA",
        "iso": "TR"
    },
    {
        "label": "MZH",
        "value": "MZH",
        "iso": "TR"
    },
    {
        "label": "SSX",
        "value": "SSX",
        "iso": "TR"
    },
    {
        "label": "VAS",
        "value": "VAS",
        "iso": "TR"
    },
    {
        "label": "ONQ",
        "value": "ONQ",
        "iso": "TR"
    },
    {
        "label": "MLX",
        "value": "MLX",
        "iso": "TR"
    },
    {
        "label": "ASR",
        "value": "ASR",
        "iso": "TR"
    },
    {
        "label": "TJK",
        "value": "TJK",
        "iso": "TR"
    },
    {
        "label": "DNZ",
        "value": "DNZ",
        "iso": "TR"
    },
    {
        "label": "NAV",
        "value": "NAV",
        "iso": "TR"
    },
    {
        "label": "LTB",
        "value": "LTB",
        "iso": "AU"
    },
    {
        "label": "IST",
        "value": "IST",
        "iso": "TR"
    },
    {
        "label": "BTZ",
        "value": "BTZ",
        "iso": "TR"
    },
    {
        "label": "BZI",
        "value": "BZI",
        "iso": "TR"
    },
    {
        "label": "BDM",
        "value": "BDM",
        "iso": "TR"
    },
    {
        "label": "CKZ",
        "value": "CKZ",
        "iso": "TR"
    },
    {
        "label": "ESK",
        "value": "ESK",
        "iso": "TR"
    },
    {
        "label": "ADB",
        "value": "ADB",
        "iso": "TR"
    },
    {
        "label": "IGL",
        "value": "IGL",
        "iso": "TR"
    },
    {
        "label": "USQ",
        "value": "USQ",
        "iso": "TR"
    },
    {
        "label": "KCO",
        "value": "KCO",
        "iso": "TR"
    },
    {
        "label": "YEI",
        "value": "YEI",
        "iso": "TR"
    },
    {
        "label": "DLM",
        "value": "DLM",
        "iso": "TR"
    },
    {
        "label": "TEQ",
        "value": "TEQ",
        "iso": "TR"
    },
    {
        "label": "BXN",
        "value": "BXN",
        "iso": "TR"
    },
    {
        "label": "AOE",
        "value": "AOE",
        "iso": "TR"
    },
    {
        "label": "EZS",
        "value": "EZS",
        "iso": "TR"
    },
    {
        "label": "DIY",
        "value": "DIY",
        "iso": "TR"
    },
    {
        "label": "ERC",
        "value": "ERC",
        "iso": "TR"
    },
    {
        "label": "ERZ",
        "value": "ERZ",
        "iso": "TR"
    },
    {
        "label": "KSY",
        "value": "KSY",
        "iso": "TR"
    },
    {
        "label": "TZX",
        "value": "TZX",
        "iso": "TR"
    },
    {
        "label": "SFQ",
        "value": "SFQ",
        "iso": "TR"
    },
    {
        "label": "VAN",
        "value": "VAN",
        "iso": "TR"
    },
    {
        "label": "BAL",
        "value": "BAL",
        "iso": "TR"
    },
    {
        "label": "MSR",
        "value": "MSR",
        "iso": "TR"
    },
    {
        "label": "SXZ",
        "value": "SXZ",
        "iso": "TR"
    },
    {
        "label": "SIC",
        "value": "SIC",
        "iso": "TR"
    },
    {
        "label": "KCM",
        "value": "KCM",
        "iso": "TR"
    },
    {
        "label": "AJI",
        "value": "AJI",
        "iso": "TR"
    },
    {
        "label": "ADF",
        "value": "ADF",
        "iso": "TR"
    },
    {
        "label": "MQM",
        "value": "MQM",
        "iso": "TR"
    },
    {
        "label": "GNY",
        "value": "GNY",
        "iso": "TR"
    },
    {
        "label": "HTY",
        "value": "HTY",
        "iso": "TR"
    },
    {
        "label": "ISE",
        "value": "ISE",
        "iso": "TR"
    },
    {
        "label": "EDO",
        "value": "EDO",
        "iso": "TR"
    },
    {
        "label": "BJV",
        "value": "BJV",
        "iso": "TR"
    },
    {
        "label": "SZF",
        "value": "SZF",
        "iso": "TR"
    },
    {
        "label": "SAW",
        "value": "SAW",
        "iso": "TR"
    },
    {
        "label": "GZP",
        "value": "GZP",
        "iso": "TR"
    },
    {
        "label": "BZY",
        "value": "BZY",
        "iso": "MD"
    },
    {
        "label": "KIV",
        "value": "KIV",
        "iso": "MD"
    },
    {
        "label": "GZA",
        "value": "GZA",
        "iso": "PS"
    },
    {
        "label": "OHD",
        "value": "OHD",
        "iso": "MK"
    },
    {
        "label": "SKP",
        "value": "SKP",
        "iso": "MK"
    },
    {
        "label": "GIB",
        "value": "GIB",
        "iso": "GI"
    },
    {
        "label": "BCQ",
        "value": "BCQ",
        "iso": "LY"
    },
    {
        "label": "DNF",
        "value": "DNF",
        "iso": "LY"
    },
    {
        "label": "MRA",
        "value": "MRA",
        "iso": "LY"
    },
    {
        "label": "QUB",
        "value": "QUB",
        "iso": "LY"
    },
    {
        "label": "UZC",
        "value": "UZC",
        "iso": "RS"
    },
    {
        "label": "BEG",
        "value": "BEG",
        "iso": "RS"
    },
    {
        "label": "LJB",
        "value": "LJB",
        "iso": "RS"
    },
    {
        "label": "IVG",
        "value": "IVG",
        "iso": "ME"
    },
    {
        "label": "BJY",
        "value": "BJY",
        "iso": "RS"
    },
    {
        "label": "GJA",
        "value": "GJA",
        "iso": "KS"
    },
    {
        "label": "KID",
        "value": "KID",
        "iso": "RS"
    },
    {
        "label": "INI",
        "value": "INI",
        "iso": "RS"
    },
    {
        "label": "QND",
        "value": "QND",
        "iso": "RS"
    },
    {
        "label": "TGD",
        "value": "TGD",
        "iso": "ME"
    },
    {
        "label": "TIV",
        "value": "TIV",
        "iso": "ME"
    },
    {
        "label": "BTS",
        "value": "BTS",
        "iso": "SK"
    },
    {
        "label": "KSC",
        "value": "KSC",
        "iso": "SK"
    },
    {
        "label": "LUE",
        "value": "LUE",
        "iso": "SK"
    },
    {
        "label": "PZY",
        "value": "PZY",
        "iso": "SK"
    },
    {
        "label": "POV",
        "value": "POV",
        "iso": "SK"
    },
    {
        "label": "SLD",
        "value": "SLD",
        "iso": "SK"
    },
    {
        "label": "ILZ",
        "value": "ILZ",
        "iso": "SK"
    },
    {
        "label": "GLN",
        "value": "GLN",
        "iso": "MA"
    },
    {
        "label": "GDT",
        "value": "GDT",
        "iso": "TC"
    },
    {
        "label": "MDS",
        "value": "MDS",
        "iso": "TC"
    },
    {
        "label": "NCA",
        "value": "NCA",
        "iso": "TC"
    },
    {
        "label": "PIC",
        "value": "PIC",
        "iso": "TC"
    },
    {
        "label": "PLS",
        "value": "PLS",
        "iso": "TC"
    },
    {
        "label": "XSC",
        "value": "XSC",
        "iso": "TC"
    },
    {
        "label": "SLX",
        "value": "SLX",
        "iso": "TC"
    },
    {
        "label": "EPS",
        "value": "EPS",
        "iso": "DO"
    },
    {
        "label": "BRX",
        "value": "BRX",
        "iso": "DO"
    },
    {
        "label": "CBJ",
        "value": "CBJ",
        "iso": "DO"
    },
    {
        "label": "AZS",
        "value": "AZS",
        "iso": "DO"
    },
    {
        "label": "COZ",
        "value": "COZ",
        "iso": "DO"
    },
    {
        "label": "HEX",
        "value": "HEX",
        "iso": "DO"
    },
    {
        "label": "JBQ",
        "value": "JBQ",
        "iso": "DO"
    },
    {
        "label": "LRM",
        "value": "LRM",
        "iso": "DO"
    },
    {
        "label": "PUJ",
        "value": "PUJ",
        "iso": "DO"
    },
    {
        "label": "POP",
        "value": "POP",
        "iso": "DO"
    },
    {
        "label": "MDR",
        "value": "MDR",
        "iso": "US"
    },
    {
        "label": "SNX",
        "value": "SNX",
        "iso": "DO"
    },
    {
        "label": "SDQ",
        "value": "SDQ",
        "iso": "DO"
    },
    {
        "label": "SJM",
        "value": "SJM",
        "iso": "DO"
    },
    {
        "label": "STI",
        "value": "STI",
        "iso": "DO"
    },
    {
        "label": "DOA",
        "value": "DOA",
        "iso": "MG"
    },
    {
        "label": "CBV",
        "value": "CBV",
        "iso": "GT"
    },
    {
        "label": "CMM",
        "value": "CMM",
        "iso": "GT"
    },
    {
        "label": "CTF",
        "value": "CTF",
        "iso": "GT"
    },
    {
        "label": "GUA",
        "value": "GUA",
        "iso": "GT"
    },
    {
        "label": "HUG",
        "value": "HUG",
        "iso": "GT"
    },
    {
        "label": "MCR",
        "value": "MCR",
        "iso": "GT"
    },
    {
        "label": "PBR",
        "value": "PBR",
        "iso": "GT"
    },
    {
        "label": "PON",
        "value": "PON",
        "iso": "GT"
    },
    {
        "label": "AQB",
        "value": "AQB",
        "iso": "GT"
    },
    {
        "label": "AAZ",
        "value": "AAZ",
        "iso": "GT"
    },
    {
        "label": "RUV",
        "value": "RUV",
        "iso": "GT"
    },
    {
        "label": "RER",
        "value": "RER",
        "iso": "GT"
    },
    {
        "label": "GSJ",
        "value": "GSJ",
        "iso": "GT"
    },
    {
        "label": "FRS",
        "value": "FRS",
        "iso": "GT"
    },
    {
        "label": "AIM",
        "value": "AIM",
        "iso": "MH"
    },
    {
        "label": "AUL",
        "value": "AUL",
        "iso": "MH"
    },
    {
        "label": "BII",
        "value": "BII",
        "iso": "MH"
    },
    {
        "label": "EBN",
        "value": "EBN",
        "iso": "MH"
    },
    {
        "label": "JAT",
        "value": "JAT",
        "iso": "MH"
    },
    {
        "label": "JEJ",
        "value": "JEJ",
        "iso": "MH"
    },
    {
        "label": "KBT",
        "value": "KBT",
        "iso": "MH"
    },
    {
        "label": "LIK",
        "value": "LIK",
        "iso": "MH"
    },
    {
        "label": "LML",
        "value": "LML",
        "iso": "MH"
    },
    {
        "label": "MAV",
        "value": "MAV",
        "iso": "MH"
    },
    {
        "label": "MJB",
        "value": "MJB",
        "iso": "MH"
    },
    {
        "label": "MJE",
        "value": "MJE",
        "iso": "MH"
    },
    {
        "label": "NDK",
        "value": "NDK",
        "iso": "MH"
    },
    {
        "label": "RNP",
        "value": "RNP",
        "iso": "MH"
    },
    {
        "label": "TIC",
        "value": "TIC",
        "iso": "MH"
    },
    {
        "label": "UIT",
        "value": "UIT",
        "iso": "MH"
    },
    {
        "label": "WJA",
        "value": "WJA",
        "iso": "MH"
    },
    {
        "label": "WTE",
        "value": "WTE",
        "iso": "MH"
    },
    {
        "label": "WTO",
        "value": "WTO",
        "iso": "MH"
    },
    {
        "label": "AHS",
        "value": "AHS",
        "iso": "HN"
    },
    {
        "label": "BHG",
        "value": "BHG",
        "iso": "HN"
    },
    {
        "label": "CAA",
        "value": "CAA",
        "iso": "HN"
    },
    {
        "label": "CDD",
        "value": "CDD",
        "iso": "HN"
    },
    {
        "label": "JUT",
        "value": "JUT",
        "iso": "HN"
    },
    {
        "label": "LCE",
        "value": "LCE",
        "iso": "HN"
    },
    {
        "label": "LEZ",
        "value": "LEZ",
        "iso": "HN"
    },
    {
        "label": "SAP",
        "value": "SAP",
        "iso": "HN"
    },
    {
        "label": "GJA",
        "value": "GJA",
        "iso": "HN"
    },
    {
        "label": "PEU",
        "value": "PEU",
        "iso": "HN"
    },
    {
        "label": "RTB",
        "value": "RTB",
        "iso": "HN"
    },
    {
        "label": "SDH",
        "value": "SDH",
        "iso": "HN"
    },
    {
        "label": "RUY",
        "value": "RUY",
        "iso": "HN"
    },
    {
        "label": "XPL",
        "value": "XPL",
        "iso": "HN"
    },
    {
        "label": "TEA",
        "value": "TEA",
        "iso": "HN"
    },
    {
        "label": "TGU",
        "value": "TGU",
        "iso": "HN"
    },
    {
        "label": "TJI",
        "value": "TJI",
        "iso": "HN"
    },
    {
        "label": "SCD",
        "value": "SCD",
        "iso": "HN"
    },
    {
        "label": "UII",
        "value": "UII",
        "iso": "HN"
    },
    {
        "label": "MJG",
        "value": "MJG",
        "iso": "PR"
    },
    {
        "label": "OCJ",
        "value": "OCJ",
        "iso": "JM"
    },
    {
        "label": "KIN",
        "value": "KIN",
        "iso": "JM"
    },
    {
        "label": "MBJ",
        "value": "MBJ",
        "iso": "JM"
    },
    {
        "label": "POT",
        "value": "POT",
        "iso": "JM"
    },
    {
        "label": "NEG",
        "value": "NEG",
        "iso": "JM"
    },
    {
        "label": "KTP",
        "value": "KTP",
        "iso": "JM"
    },
    {
        "label": "MIJ",
        "value": "MIJ",
        "iso": "MH"
    },
    {
        "label": "HEB",
        "value": "HEB",
        "iso": "MM"
    },
    {
        "label": "ACA",
        "value": "ACA",
        "iso": "MX"
    },
    {
        "label": "NTR",
        "value": "NTR",
        "iso": "MX"
    },
    {
        "label": "AGU",
        "value": "AGU",
        "iso": "MX"
    },
    {
        "label": "HUX",
        "value": "HUX",
        "iso": "MX"
    },
    {
        "label": "CNA",
        "value": "CNA",
        "iso": "MX"
    },
    {
        "label": "CVJ",
        "value": "CVJ",
        "iso": "MX"
    },
    {
        "label": "ACN",
        "value": "ACN",
        "iso": "MX"
    },
    {
        "label": "CME",
        "value": "CME",
        "iso": "MX"
    },
    {
        "label": "NCG",
        "value": "NCG",
        "iso": "MX"
    },
    {
        "label": "CUL",
        "value": "CUL",
        "iso": "MX"
    },
    {
        "label": "CTM",
        "value": "CTM",
        "iso": "MX"
    },
    {
        "label": "CEN",
        "value": "CEN",
        "iso": "MX"
    },
    {
        "label": "CJT",
        "value": "CJT",
        "iso": "MX"
    },
    {
        "label": "CPE",
        "value": "CPE",
        "iso": "MX"
    },
    {
        "label": "CJS",
        "value": "CJS",
        "iso": "MX"
    },
    {
        "label": "CZA",
        "value": "CZA",
        "iso": "MX"
    },
    {
        "label": "CUU",
        "value": "CUU",
        "iso": "MX"
    },
    {
        "label": "CVM",
        "value": "CVM",
        "iso": "MX"
    },
    {
        "label": "CYW",
        "value": "CYW",
        "iso": "MX"
    },
    {
        "label": "CZM",
        "value": "CZM",
        "iso": "MX"
    },
    {
        "label": "CUA",
        "value": "CUA",
        "iso": "MX"
    },
    {
        "label": "MMC",
        "value": "MMC",
        "iso": "MX"
    },
    {
        "label": "DGO",
        "value": "DGO",
        "iso": "MX"
    },
    {
        "label": "TPQ",
        "value": "TPQ",
        "iso": "MX"
    },
    {
        "label": "ESE",
        "value": "ESE",
        "iso": "MX"
    },
    {
        "label": "GDL",
        "value": "GDL",
        "iso": "MX"
    },
    {
        "label": "GYM",
        "value": "GYM",
        "iso": "MX"
    },
    {
        "label": "GUB",
        "value": "GUB",
        "iso": "MX"
    },
    {
        "label": "TCN",
        "value": "TCN",
        "iso": "MX"
    },
    {
        "label": "HMO",
        "value": "HMO",
        "iso": "MX"
    },
    {
        "label": "CLQ",
        "value": "CLQ",
        "iso": "MX"
    },
    {
        "label": "ISJ",
        "value": "ISJ",
        "iso": "MX"
    },
    {
        "label": "SLW",
        "value": "SLW",
        "iso": "MX"
    },
    {
        "label": "IZT",
        "value": "IZT",
        "iso": "MX"
    },
    {
        "label": "JAL",
        "value": "JAL",
        "iso": "MX"
    },
    {
        "label": "AZP",
        "value": "AZP",
        "iso": "MX"
    },
    {
        "label": "LZC",
        "value": "LZC",
        "iso": "MX"
    },
    {
        "label": "LMM",
        "value": "LMM",
        "iso": "MX"
    },
    {
        "label": "BJX",
        "value": "BJX",
        "iso": "MX"
    },
    {
        "label": "LAP",
        "value": "LAP",
        "iso": "MX"
    },
    {
        "label": "LTO",
        "value": "LTO",
        "iso": "MX"
    },
    {
        "label": "MAM",
        "value": "MAM",
        "iso": "MX"
    },
    {
        "label": "MID",
        "value": "MID",
        "iso": "MX"
    },
    {
        "label": "MUG",
        "value": "MUG",
        "iso": "MX"
    },
    {
        "label": "MXL",
        "value": "MXL",
        "iso": "MX"
    },
    {
        "label": "MLM",
        "value": "MLM",
        "iso": "MX"
    },
    {
        "label": "MTT",
        "value": "MTT",
        "iso": "MX"
    },
    {
        "label": "LOV",
        "value": "LOV",
        "iso": "MX"
    },
    {
        "label": "MEX",
        "value": "MEX",
        "iso": "MX"
    },
    {
        "label": "MTY",
        "value": "MTY",
        "iso": "MX"
    },
    {
        "label": "MZT",
        "value": "MZT",
        "iso": "MX"
    },
    {
        "label": "NOG",
        "value": "NOG",
        "iso": "MX"
    },
    {
        "label": "NLD",
        "value": "NLD",
        "iso": "MX"
    },
    {
        "label": "OAX",
        "value": "OAX",
        "iso": "MX"
    },
    {
        "label": "PAZ",
        "value": "PAZ",
        "iso": "MX"
    },
    {
        "label": "PBC",
        "value": "PBC",
        "iso": "MX"
    },
    {
        "label": "PPE",
        "value": "PPE",
        "iso": "MX"
    },
    {
        "label": "PDS",
        "value": "PDS",
        "iso": "MX"
    },
    {
        "label": "UPN",
        "value": "UPN",
        "iso": "MX"
    },
    {
        "label": "PQM",
        "value": "PQM",
        "iso": "MX"
    },
    {
        "label": "PVR",
        "value": "PVR",
        "iso": "MX"
    },
    {
        "label": "PXM",
        "value": "PXM",
        "iso": "MX"
    },
    {
        "label": "QRO",
        "value": "QRO",
        "iso": "MX"
    },
    {
        "label": "REX",
        "value": "REX",
        "iso": "MX"
    },
    {
        "label": "SZT",
        "value": "SZT",
        "iso": "MX"
    },
    {
        "label": "SJD",
        "value": "SJD",
        "iso": "MX"
    },
    {
        "label": "SFH",
        "value": "SFH",
        "iso": "MX"
    },
    {
        "label": "SLP",
        "value": "SLP",
        "iso": "MX"
    },
    {
        "label": "TGM",
        "value": "TGM",
        "iso": "MX"
    },
    {
        "label": "TRC",
        "value": "TRC",
        "iso": "MX"
    },
    {
        "label": "TIJ",
        "value": "TIJ",
        "iso": "MX"
    },
    {
        "label": "TAM",
        "value": "TAM",
        "iso": "MX"
    },
    {
        "label": "TSL",
        "value": "TSL",
        "iso": "MX"
    },
    {
        "label": "TLC",
        "value": "TLC",
        "iso": "MX"
    },
    {
        "label": "TAP",
        "value": "TAP",
        "iso": "MX"
    },
    {
        "label": "WIX",
        "value": "WIX",
        "iso": "MX"
    },
    {
        "label": "CUN",
        "value": "CUN",
        "iso": "MX"
    },
    {
        "label": "VSA",
        "value": "VSA",
        "iso": "MX"
    },
    {
        "label": "VER",
        "value": "VER",
        "iso": "MX"
    },
    {
        "label": "ZCL",
        "value": "ZCL",
        "iso": "MX"
    },
    {
        "label": "ZIH",
        "value": "ZIH",
        "iso": "MX"
    },
    {
        "label": "ZMM",
        "value": "ZMM",
        "iso": "MX"
    },
    {
        "label": "ZLO",
        "value": "ZLO",
        "iso": "MX"
    },
    {
        "label": "MXW",
        "value": "MXW",
        "iso": "MN"
    },
    {
        "label": "ULG",
        "value": "ULG",
        "iso": "MN"
    },
    {
        "label": "ULZ",
        "value": "ULZ",
        "iso": "MN"
    },
    {
        "label": "BEF",
        "value": "BEF",
        "iso": "NI"
    },
    {
        "label": "BZA",
        "value": "BZA",
        "iso": "NI"
    },
    {
        "label": "RNI",
        "value": "RNI",
        "iso": "NI"
    },
    {
        "label": "MGA",
        "value": "MGA",
        "iso": "NI"
    },
    {
        "label": "NVG",
        "value": "NVG",
        "iso": "NI"
    },
    {
        "label": "PUZ",
        "value": "PUZ",
        "iso": "NI"
    },
    {
        "label": "RFS",
        "value": "RFS",
        "iso": "NI"
    },
    {
        "label": "NCR",
        "value": "NCR",
        "iso": "NI"
    },
    {
        "label": "SIU",
        "value": "SIU",
        "iso": "NI"
    },
    {
        "label": "WSP",
        "value": "WSP",
        "iso": "NI"
    },
    {
        "label": "BOC",
        "value": "BOC",
        "iso": "PA"
    },
    {
        "label": "CTD",
        "value": "CTD",
        "iso": "PA"
    },
    {
        "label": "CHX",
        "value": "CHX",
        "iso": "PA"
    },
    {
        "label": "DAV",
        "value": "DAV",
        "iso": "PA"
    },
    {
        "label": "ONX",
        "value": "ONX",
        "iso": "PA"
    },
    {
        "label": "BLB",
        "value": "BLB",
        "iso": "PA"
    },
    {
        "label": "JQE",
        "value": "JQE",
        "iso": "PA"
    },
    {
        "label": "PLP",
        "value": "PLP",
        "iso": "PA"
    },
    {
        "label": "PAC",
        "value": "PAC",
        "iso": "PA"
    },
    {
        "label": "SYP",
        "value": "SYP",
        "iso": "PA"
    },
    {
        "label": "PTY",
        "value": "PTY",
        "iso": "PA"
    },
    {
        "label": "NBL",
        "value": "NBL",
        "iso": "PA"
    },
    {
        "label": "FON",
        "value": "FON",
        "iso": "CR"
    },
    {
        "label": "TTQ",
        "value": "TTQ",
        "iso": "CR"
    },
    {
        "label": "BAI",
        "value": "BAI",
        "iso": "CR"
    },
    {
        "label": "BCL",
        "value": "BCL",
        "iso": "CR"
    },
    {
        "label": "CSC",
        "value": "CSC",
        "iso": "CR"
    },
    {
        "label": "OTR",
        "value": "OTR",
        "iso": "CR"
    },
    {
        "label": "RIK",
        "value": "RIK",
        "iso": "CR"
    },
    {
        "label": "TNO",
        "value": "TNO",
        "iso": "CR"
    },
    {
        "label": "FMG",
        "value": "FMG",
        "iso": "CR"
    },
    {
        "label": "GLF",
        "value": "GLF",
        "iso": "CR"
    },
    {
        "label": "GPL",
        "value": "GPL",
        "iso": "CR"
    },
    {
        "label": "PBP",
        "value": "PBP",
        "iso": "CR"
    },
    {
        "label": "LIR",
        "value": "LIR",
        "iso": "CR"
    },
    {
        "label": "LSL",
        "value": "LSL",
        "iso": "CR"
    },
    {
        "label": "LIO",
        "value": "LIO",
        "iso": "CR"
    },
    {
        "label": "NCT",
        "value": "NCT",
        "iso": "CR"
    },
    {
        "label": "NOM",
        "value": "NOM",
        "iso": "CR"
    },
    {
        "label": "SJO",
        "value": "SJO",
        "iso": "CR"
    },
    {
        "label": "PJM",
        "value": "PJM",
        "iso": "CR"
    },
    {
        "label": "PMZ",
        "value": "PMZ",
        "iso": "CR"
    },
    {
        "label": "SYQ",
        "value": "SYQ",
        "iso": "CR"
    },
    {
        "label": "XQP",
        "value": "XQP",
        "iso": "CR"
    },
    {
        "label": "RFR",
        "value": "RFR",
        "iso": "CR"
    },
    {
        "label": "PLD",
        "value": "PLD",
        "iso": "CR"
    },
    {
        "label": "TOO",
        "value": "TOO",
        "iso": "CR"
    },
    {
        "label": "TNO",
        "value": "TNO",
        "iso": "CR"
    },
    {
        "label": "TMU",
        "value": "TMU",
        "iso": "CR"
    },
    {
        "label": "UPL",
        "value": "UPL",
        "iso": "CR"
    },
    {
        "label": "SAL",
        "value": "SAL",
        "iso": "SV"
    },
    {
        "label": "CYA",
        "value": "CYA",
        "iso": "HT"
    },
    {
        "label": "CAP",
        "value": "CAP",
        "iso": "HT"
    },
    {
        "label": "JAK",
        "value": "JAK",
        "iso": "HT"
    },
    {
        "label": "JEE",
        "value": "JEE",
        "iso": "HT"
    },
    {
        "label": "PAP",
        "value": "PAP",
        "iso": "HT"
    },
    {
        "label": "PAX",
        "value": "PAX",
        "iso": "HT"
    },
    {
        "label": "MTU",
        "value": "MTU",
        "iso": "MZ"
    },
    {
        "label": "BCA",
        "value": "BCA",
        "iso": "CU"
    },
    {
        "label": "BWW",
        "value": "BWW",
        "iso": "CU"
    },
    {
        "label": "BYM",
        "value": "BYM",
        "iso": "CU"
    },
    {
        "label": "AVI",
        "value": "AVI",
        "iso": "CU"
    },
    {
        "label": "CCC",
        "value": "CCC",
        "iso": "CU"
    },
    {
        "label": "CFG",
        "value": "CFG",
        "iso": "CU"
    },
    {
        "label": "CYO",
        "value": "CYO",
        "iso": "CU"
    },
    {
        "label": "CMW",
        "value": "CMW",
        "iso": "CU"
    },
    {
        "label": "QCO",
        "value": "QCO",
        "iso": "CU"
    },
    {
        "label": "SCU",
        "value": "SCU",
        "iso": "CU"
    },
    {
        "label": "NBW",
        "value": "NBW",
        "iso": "CU"
    },
    {
        "label": "GAO",
        "value": "GAO",
        "iso": "CU"
    },
    {
        "label": "HAV",
        "value": "HAV",
        "iso": "CU"
    },
    {
        "label": "HOG",
        "value": "HOG",
        "iso": "CU"
    },
    {
        "label": "VRO",
        "value": "VRO",
        "iso": "CU"
    },
    {
        "label": "LCL",
        "value": "LCL",
        "iso": "CU"
    },
    {
        "label": "UMA",
        "value": "UMA",
        "iso": "CU"
    },
    {
        "label": "MJG",
        "value": "MJG",
        "iso": "CU"
    },
    {
        "label": "MOA",
        "value": "MOA",
        "iso": "CU"
    },
    {
        "label": "MZO",
        "value": "MZO",
        "iso": "CU"
    },
    {
        "label": "QSN",
        "value": "QSN",
        "iso": "CU"
    },
    {
        "label": "ICR",
        "value": "ICR",
        "iso": "CU"
    },
    {
        "label": "GER",
        "value": "GER",
        "iso": "CU"
    },
    {
        "label": "UPB",
        "value": "UPB",
        "iso": "CU"
    },
    {
        "label": "QPD",
        "value": "QPD",
        "iso": "CU"
    },
    {
        "label": "SNU",
        "value": "SNU",
        "iso": "CU"
    },
    {
        "label": "SNJ",
        "value": "SNJ",
        "iso": "CU"
    },
    {
        "label": "SZJ",
        "value": "SZJ",
        "iso": "CU"
    },
    {
        "label": "USS",
        "value": "USS",
        "iso": "CU"
    },
    {
        "label": "TND",
        "value": "TND",
        "iso": "CU"
    },
    {
        "label": "VRA",
        "value": "VRA",
        "iso": "CU"
    },
    {
        "label": "VTU",
        "value": "VTU",
        "iso": "CU"
    },
    {
        "label": "CYB",
        "value": "CYB",
        "iso": "KY"
    },
    {
        "label": "LYB",
        "value": "LYB",
        "iso": "KY"
    },
    {
        "label": "GCM",
        "value": "GCM",
        "iso": "KY"
    },
    {
        "label": "AJS",
        "value": "AJS",
        "iso": "MX"
    },
    {
        "label": "AZG",
        "value": "AZG",
        "iso": "MX"
    },
    {
        "label": "CEO",
        "value": "CEO",
        "iso": "MX"
    },
    {
        "label": "NVJ",
        "value": "NVJ",
        "iso": "MX"
    },
    {
        "label": "PCM",
        "value": "PCM",
        "iso": "MX"
    },
    {
        "label": "PCV",
        "value": "PCV",
        "iso": "MX"
    },
    {
        "label": "PNO",
        "value": "PNO",
        "iso": "MX"
    },
    {
        "label": "SCX",
        "value": "SCX",
        "iso": "MX"
    },
    {
        "label": "SGM",
        "value": "SGM",
        "iso": "MX"
    },
    {
        "label": "TUY",
        "value": "TUY",
        "iso": "MX"
    },
    {
        "label": "UAC",
        "value": "UAC",
        "iso": "MX"
    },
    {
        "label": "XAL",
        "value": "XAL",
        "iso": "MX"
    },
    {
        "label": "GTB",
        "value": "GTB",
        "iso": "MY"
    },
    {
        "label": "GTK",
        "value": "GTK",
        "iso": "MY"
    },
    {
        "label": "LBP",
        "value": "LBP",
        "iso": "MY"
    },
    {
        "label": "LLM",
        "value": "LLM",
        "iso": "MY"
    },
    {
        "label": "MZS",
        "value": "MZS",
        "iso": "MY"
    },
    {
        "label": "SPT",
        "value": "SPT",
        "iso": "MY"
    },
    {
        "label": "MAY",
        "value": "MAY",
        "iso": "BS"
    },
    {
        "label": "ASD",
        "value": "ASD",
        "iso": "BS"
    },
    {
        "label": "COX",
        "value": "COX",
        "iso": "BS"
    },
    {
        "label": "MHH",
        "value": "MHH",
        "iso": "BS"
    },
    {
        "label": "SAQ",
        "value": "SAQ",
        "iso": "BS"
    },
    {
        "label": "AXP",
        "value": "AXP",
        "iso": "BS"
    },
    {
        "label": "TCB",
        "value": "TCB",
        "iso": "BS"
    },
    {
        "label": "WKR",
        "value": "WKR",
        "iso": "BS"
    },
    {
        "label": "CCZ",
        "value": "CCZ",
        "iso": "BS"
    },
    {
        "label": "GHC",
        "value": "GHC",
        "iso": "BS"
    },
    {
        "label": "BIM",
        "value": "BIM",
        "iso": "BS"
    },
    {
        "label": "ATC",
        "value": "ATC",
        "iso": "BS"
    },
    {
        "label": "CAT",
        "value": "CAT",
        "iso": "BS"
    },
    {
        "label": "CXY",
        "value": "CXY",
        "iso": "BS"
    },
    {
        "label": "CRI",
        "value": "CRI",
        "iso": "BS"
    },
    {
        "label": "PWN",
        "value": "PWN",
        "iso": "BS"
    },
    {
        "label": "GGT",
        "value": "GGT",
        "iso": "BS"
    },
    {
        "label": "ELH",
        "value": "ELH",
        "iso": "BS"
    },
    {
        "label": "GHB",
        "value": "GHB",
        "iso": "BS"
    },
    {
        "label": "NMC",
        "value": "NMC",
        "iso": "BS"
    },
    {
        "label": "RSD",
        "value": "RSD",
        "iso": "BS"
    },
    {
        "label": "TYM",
        "value": "TYM",
        "iso": "BS"
    },
    {
        "label": "FPO",
        "value": "FPO",
        "iso": "BS"
    },
    {
        "label": "WTD",
        "value": "WTD",
        "iso": "BS"
    },
    {
        "label": "IGA",
        "value": "IGA",
        "iso": "BS"
    },
    {
        "label": "LGI",
        "value": "LGI",
        "iso": "BS"
    },
    {
        "label": "SML",
        "value": "SML",
        "iso": "BS"
    },
    {
        "label": "MYG",
        "value": "MYG",
        "iso": "BS"
    },
    {
        "label": "NAS",
        "value": "NAS",
        "iso": "BS"
    },
    {
        "label": "PID",
        "value": "PID",
        "iso": "BS"
    },
    {
        "label": "DCT",
        "value": "DCT",
        "iso": "BS"
    },
    {
        "label": "RCY",
        "value": "RCY",
        "iso": "BS"
    },
    {
        "label": "ZSA",
        "value": "ZSA",
        "iso": "BS"
    },
    {
        "label": "MFW",
        "value": "MFW",
        "iso": "MZ"
    },
    {
        "label": "TGS",
        "value": "TGS",
        "iso": "MZ"
    },
    {
        "label": "BZE",
        "value": "BZE",
        "iso": "BZ"
    },
    {
        "label": "NBS",
        "value": "NBS",
        "iso": "CN"
    },
    {
        "label": "AIT",
        "value": "AIT",
        "iso": "CK"
    },
    {
        "label": "AIU",
        "value": "AIU",
        "iso": "CK"
    },
    {
        "label": "MGS",
        "value": "MGS",
        "iso": "CK"
    },
    {
        "label": "MHX",
        "value": "MHX",
        "iso": "CK"
    },
    {
        "label": "MUK",
        "value": "MUK",
        "iso": "CK"
    },
    {
        "label": "MOI",
        "value": "MOI",
        "iso": "CK"
    },
    {
        "label": "PYE",
        "value": "PYE",
        "iso": "CK"
    },
    {
        "label": "RAR",
        "value": "RAR",
        "iso": "CK"
    },
    {
        "label": "ICI",
        "value": "ICI",
        "iso": "FJ"
    },
    {
        "label": "NAN",
        "value": "NAN",
        "iso": "FJ"
    },
    {
        "label": "PTF",
        "value": "PTF",
        "iso": "FJ"
    },
    {
        "label": "KDV",
        "value": "KDV",
        "iso": "FJ"
    },
    {
        "label": "MNF",
        "value": "MNF",
        "iso": "FJ"
    },
    {
        "label": "MFJ",
        "value": "MFJ",
        "iso": "FJ"
    },
    {
        "label": "SUV",
        "value": "SUV",
        "iso": "FJ"
    },
    {
        "label": "LEV",
        "value": "LEV",
        "iso": "FJ"
    },
    {
        "label": "NGI",
        "value": "NGI",
        "iso": "FJ"
    },
    {
        "label": "LUC",
        "value": "LUC",
        "iso": "FJ"
    },
    {
        "label": "LKB",
        "value": "LKB",
        "iso": "FJ"
    },
    {
        "label": "LBS",
        "value": "LBS",
        "iso": "FJ"
    },
    {
        "label": "TVU",
        "value": "TVU",
        "iso": "FJ"
    },
    {
        "label": "KXF",
        "value": "KXF",
        "iso": "FJ"
    },
    {
        "label": "RTA",
        "value": "RTA",
        "iso": "FJ"
    },
    {
        "label": "SVU",
        "value": "SVU",
        "iso": "FJ"
    },
    {
        "label": "VAU",
        "value": "VAU",
        "iso": "FJ"
    },
    {
        "label": "KAY",
        "value": "KAY",
        "iso": "FJ"
    },
    {
        "label": "ONU",
        "value": "ONU",
        "iso": "FJ"
    },
    {
        "label": "YAS",
        "value": "YAS",
        "iso": "FJ"
    },
    {
        "label": "EUA",
        "value": "EUA",
        "iso": "TO"
    },
    {
        "label": "TBU",
        "value": "TBU",
        "iso": "TO"
    },
    {
        "label": "HPA",
        "value": "HPA",
        "iso": "TO"
    },
    {
        "label": "NFO",
        "value": "NFO",
        "iso": "TO"
    },
    {
        "label": "NTT",
        "value": "NTT",
        "iso": "TO"
    },
    {
        "label": "VAV",
        "value": "VAV",
        "iso": "TO"
    },
    {
        "label": "VBV",
        "value": "VBV",
        "iso": "FJ"
    },
    {
        "label": "VTF",
        "value": "VTF",
        "iso": "FJ"
    },
    {
        "label": "GMO",
        "value": "GMO",
        "iso": "NG"
    },
    {
        "label": "PHG",
        "value": "PHG",
        "iso": "NG"
    },
    {
        "label": "BCU",
        "value": "BCU",
        "iso": "NG"
    },
    {
        "label": "QRW",
        "value": "QRW",
        "iso": "NG"
    },
    {
        "label": "ABF",
        "value": "ABF",
        "iso": "KI"
    },
    {
        "label": "BEZ",
        "value": "BEZ",
        "iso": "KI"
    },
    {
        "label": "FUN",
        "value": "FUN",
        "iso": "TV"
    },
    {
        "label": "KUC",
        "value": "KUC",
        "iso": "KI"
    },
    {
        "label": "MNK",
        "value": "MNK",
        "iso": "KI"
    },
    {
        "label": "MZK",
        "value": "MZK",
        "iso": "KI"
    },
    {
        "label": "MTK",
        "value": "MTK",
        "iso": "KI"
    },
    {
        "label": "NIG",
        "value": "NIG",
        "iso": "KI"
    },
    {
        "label": "OOT",
        "value": "OOT",
        "iso": "KI"
    },
    {
        "label": "TRW",
        "value": "TRW",
        "iso": "KI"
    },
    {
        "label": "AEA",
        "value": "AEA",
        "iso": "KI"
    },
    {
        "label": "TBF",
        "value": "TBF",
        "iso": "KI"
    },
    {
        "label": "TMN",
        "value": "TMN",
        "iso": "KI"
    },
    {
        "label": "NON",
        "value": "NON",
        "iso": "KI"
    },
    {
        "label": "AIS",
        "value": "AIS",
        "iso": "KI"
    },
    {
        "label": "TSU",
        "value": "TSU",
        "iso": "KI"
    },
    {
        "label": "BBG",
        "value": "BBG",
        "iso": "KI"
    },
    {
        "label": "AAK",
        "value": "AAK",
        "iso": "KI"
    },
    {
        "label": "IUE",
        "value": "IUE",
        "iso": "NU"
    },
    {
        "label": "FUT",
        "value": "FUT",
        "iso": "WF"
    },
    {
        "label": "WLS",
        "value": "WLS",
        "iso": "WF"
    },
    {
        "label": "MWP",
        "value": "MWP",
        "iso": "NP"
    },
    {
        "label": "TPJ",
        "value": "TPJ",
        "iso": "NP"
    },
    {
        "label": "OFU",
        "value": "OFU",
        "iso": "AS"
    },
    {
        "label": "AAU",
        "value": "AAU",
        "iso": "WS"
    },
    {
        "label": "APW",
        "value": "APW",
        "iso": "WS"
    },
    {
        "label": "FGI",
        "value": "FGI",
        "iso": "WS"
    },
    {
        "label": "FAQ",
        "value": "FAQ",
        "iso": "AS"
    },
    {
        "label": "MXS",
        "value": "MXS",
        "iso": "WS"
    },
    {
        "label": "PPG",
        "value": "PPG",
        "iso": "AS"
    },
    {
        "label": "PPT",
        "value": "PPT",
        "iso": "PF"
    },
    {
        "label": "RUR",
        "value": "RUR",
        "iso": "PF"
    },
    {
        "label": "TUB",
        "value": "TUB",
        "iso": "PF"
    },
    {
        "label": "AAA",
        "value": "AAA",
        "iso": "PF"
    },
    {
        "label": "FGU",
        "value": "FGU",
        "iso": "PF"
    },
    {
        "label": "TIH",
        "value": "TIH",
        "iso": "PF"
    },
    {
        "label": "APK",
        "value": "APK",
        "iso": "PF"
    },
    {
        "label": "REA",
        "value": "REA",
        "iso": "PF"
    },
    {
        "label": "FAV",
        "value": "FAV",
        "iso": "PF"
    },
    {
        "label": "HHZ",
        "value": "HHZ",
        "iso": "PF"
    },
    {
        "label": "XMH",
        "value": "XMH",
        "iso": "PF"
    },
    {
        "label": "GMR",
        "value": "GMR",
        "iso": "PF"
    },
    {
        "label": "KKR",
        "value": "KKR",
        "iso": "PF"
    },
    {
        "label": "MKP",
        "value": "MKP",
        "iso": "PF"
    },
    {
        "label": "NAU",
        "value": "NAU",
        "iso": "PF"
    },
    {
        "label": "TKV",
        "value": "TKV",
        "iso": "PF"
    },
    {
        "label": "PKP",
        "value": "PKP",
        "iso": "PF"
    },
    {
        "label": "PUK",
        "value": "PUK",
        "iso": "PF"
    },
    {
        "label": "TKP",
        "value": "TKP",
        "iso": "PF"
    },
    {
        "label": "AXR",
        "value": "AXR",
        "iso": "PF"
    },
    {
        "label": "MVT",
        "value": "MVT",
        "iso": "PF"
    },
    {
        "label": "NUK",
        "value": "NUK",
        "iso": "PF"
    },
    {
        "label": "ZTA",
        "value": "ZTA",
        "iso": "PF"
    },
    {
        "label": "AHE",
        "value": "AHE",
        "iso": "PF"
    },
    {
        "label": "KHZ",
        "value": "KHZ",
        "iso": "PF"
    },
    {
        "label": "FAC",
        "value": "FAC",
        "iso": "PF"
    },
    {
        "label": "FHZ",
        "value": "FHZ",
        "iso": "PF"
    },
    {
        "label": "TKX",
        "value": "TKX",
        "iso": "PF"
    },
    {
        "label": "NHV",
        "value": "NHV",
        "iso": "PF"
    },
    {
        "label": "AUQ",
        "value": "AUQ",
        "iso": "PF"
    },
    {
        "label": "UAP",
        "value": "UAP",
        "iso": "PF"
    },
    {
        "label": "UAH",
        "value": "UAH",
        "iso": "PF"
    },
    {
        "label": "BOB",
        "value": "BOB",
        "iso": "PF"
    },
    {
        "label": "TTI",
        "value": "TTI",
        "iso": "PF"
    },
    {
        "label": "RGI",
        "value": "RGI",
        "iso": "PF"
    },
    {
        "label": "HUH",
        "value": "HUH",
        "iso": "PF"
    },
    {
        "label": "MOZ",
        "value": "MOZ",
        "iso": "PF"
    },
    {
        "label": "HOI",
        "value": "HOI",
        "iso": "PF"
    },
    {
        "label": "MAU",
        "value": "MAU",
        "iso": "PF"
    },
    {
        "label": "RFP",
        "value": "RFP",
        "iso": "PF"
    },
    {
        "label": "VHZ",
        "value": "VHZ",
        "iso": "PF"
    },
    {
        "label": "MTV",
        "value": "MTV",
        "iso": "VU"
    },
    {
        "label": "SLH",
        "value": "SLH",
        "iso": "VU"
    },
    {
        "label": "TOH",
        "value": "TOH",
        "iso": "VU"
    },
    {
        "label": "EAE",
        "value": "EAE",
        "iso": "VU"
    },
    {
        "label": "CCV",
        "value": "CCV",
        "iso": "VU"
    },
    {
        "label": "LOD",
        "value": "LOD",
        "iso": "VU"
    },
    {
        "label": "SSR",
        "value": "SSR",
        "iso": "VU"
    },
    {
        "label": "PBJ",
        "value": "PBJ",
        "iso": "VU"
    },
    {
        "label": "LPM",
        "value": "LPM",
        "iso": "VU"
    },
    {
        "label": "LNB",
        "value": "LNB",
        "iso": "VU"
    },
    {
        "label": "MWF",
        "value": "MWF",
        "iso": "VU"
    },
    {
        "label": "LNE",
        "value": "LNE",
        "iso": "VU"
    },
    {
        "label": "NUS",
        "value": "NUS",
        "iso": "VU"
    },
    {
        "label": "ZGU",
        "value": "ZGU",
        "iso": "VU"
    },
    {
        "label": "RCL",
        "value": "RCL",
        "iso": "VU"
    },
    {
        "label": "SON",
        "value": "SON",
        "iso": "VU"
    },
    {
        "label": "TGH",
        "value": "TGH",
        "iso": "VU"
    },
    {
        "label": "ULB",
        "value": "ULB",
        "iso": "VU"
    },
    {
        "label": "VLS",
        "value": "VLS",
        "iso": "VU"
    },
    {
        "label": "WLH",
        "value": "WLH",
        "iso": "VU"
    },
    {
        "label": "SWJ",
        "value": "SWJ",
        "iso": "VU"
    },
    {
        "label": "OLJ",
        "value": "OLJ",
        "iso": "VU"
    },
    {
        "label": "AUY",
        "value": "AUY",
        "iso": "VU"
    },
    {
        "label": "AWD",
        "value": "AWD",
        "iso": "VU"
    },
    {
        "label": "DLY",
        "value": "DLY",
        "iso": "VU"
    },
    {
        "label": "FTA",
        "value": "FTA",
        "iso": "VU"
    },
    {
        "label": "IPA",
        "value": "IPA",
        "iso": "VU"
    },
    {
        "label": "UIQ",
        "value": "UIQ",
        "iso": "VU"
    },
    {
        "label": "VLI",
        "value": "VLI",
        "iso": "VU"
    },
    {
        "label": "TAH",
        "value": "TAH",
        "iso": "VU"
    },
    {
        "label": "TGJ",
        "value": "TGJ",
        "iso": "NC"
    },
    {
        "label": "BMY",
        "value": "BMY",
        "iso": "NC"
    },
    {
        "label": "KNQ",
        "value": "KNQ",
        "iso": "NC"
    },
    {
        "label": "ILP",
        "value": "ILP",
        "iso": "NC"
    },
    {
        "label": "HLU",
        "value": "HLU",
        "iso": "NC"
    },
    {
        "label": "KOC",
        "value": "KOC",
        "iso": "NC"
    },
    {
        "label": "LIF",
        "value": "LIF",
        "iso": "NC"
    },
    {
        "label": "GEA",
        "value": "GEA",
        "iso": "NC"
    },
    {
        "label": "IOU",
        "value": "IOU",
        "iso": "NC"
    },
    {
        "label": "PUV",
        "value": "PUV",
        "iso": "NC"
    },
    {
        "label": "PDC",
        "value": "PDC",
        "iso": "NC"
    },
    {
        "label": "MEE",
        "value": "MEE",
        "iso": "NC"
    },
    {
        "label": "TOU",
        "value": "TOU",
        "iso": "NC"
    },
    {
        "label": "UVE",
        "value": "UVE",
        "iso": "NC"
    },
    {
        "label": "NOU",
        "value": "NOU",
        "iso": "NC"
    },
    {
        "label": "AKL",
        "value": "AKL",
        "iso": "NZ"
    },
    {
        "label": "TUO",
        "value": "TUO",
        "iso": "NZ"
    },
    {
        "label": "AMZ",
        "value": "AMZ",
        "iso": "NZ"
    },
    {
        "label": "ASG",
        "value": "ASG",
        "iso": "NZ"
    },
    {
        "label": "CHC",
        "value": "CHC",
        "iso": "NZ"
    },
    {
        "label": "CHT",
        "value": "CHT",
        "iso": "NZ"
    },
    {
        "label": "CMV",
        "value": "CMV",
        "iso": "NZ"
    },
    {
        "label": "DGR",
        "value": "DGR",
        "iso": "NZ"
    },
    {
        "label": "DUD",
        "value": "DUD",
        "iso": "NZ"
    },
    {
        "label": "FGL",
        "value": "FGL",
        "iso": "NZ"
    },
    {
        "label": "WHO",
        "value": "WHO",
        "iso": "NZ"
    },
    {
        "label": "GBZ",
        "value": "GBZ",
        "iso": "NZ"
    },
    {
        "label": "GMN",
        "value": "GMN",
        "iso": "NZ"
    },
    {
        "label": "GIS",
        "value": "GIS",
        "iso": "NZ"
    },
    {
        "label": "GTN",
        "value": "GTN",
        "iso": "NZ"
    },
    {
        "label": "HKK",
        "value": "HKK",
        "iso": "NZ"
    },
    {
        "label": "HLZ",
        "value": "HLZ",
        "iso": "NZ"
    },
    {
        "label": "WIK",
        "value": "WIK",
        "iso": "NZ"
    },
    {
        "label": "KBZ",
        "value": "KBZ",
        "iso": "NZ"
    },
    {
        "label": "KKE",
        "value": "KKE",
        "iso": "NZ"
    },
    {
        "label": "KKO",
        "value": "KKO",
        "iso": "NZ"
    },
    {
        "label": "KAT",
        "value": "KAT",
        "iso": "NZ"
    },
    {
        "label": "ALR",
        "value": "ALR",
        "iso": "NZ"
    },
    {
        "label": "MTA",
        "value": "MTA",
        "iso": "NZ"
    },
    {
        "label": "MON",
        "value": "MON",
        "iso": "NZ"
    },
    {
        "label": "MFN",
        "value": "MFN",
        "iso": "NZ"
    },
    {
        "label": "MZP",
        "value": "MZP",
        "iso": "NZ"
    },
    {
        "label": "TEU",
        "value": "TEU",
        "iso": "NZ"
    },
    {
        "label": "MRO",
        "value": "MRO",
        "iso": "NZ"
    },
    {
        "label": "NPL",
        "value": "NPL",
        "iso": "NZ"
    },
    {
        "label": "NPE",
        "value": "NPE",
        "iso": "NZ"
    },
    {
        "label": "NSN",
        "value": "NSN",
        "iso": "NZ"
    },
    {
        "label": "IVC",
        "value": "IVC",
        "iso": "NZ"
    },
    {
        "label": "OHA",
        "value": "OHA",
        "iso": "NZ"
    },
    {
        "label": "OAM",
        "value": "OAM",
        "iso": "NZ"
    },
    {
        "label": "PMR",
        "value": "PMR",
        "iso": "NZ"
    },
    {
        "label": "PCN",
        "value": "PCN",
        "iso": "NZ"
    },
    {
        "label": "PPQ",
        "value": "PPQ",
        "iso": "NZ"
    },
    {
        "label": "ZQN",
        "value": "ZQN",
        "iso": "NZ"
    },
    {
        "label": "RAG",
        "value": "RAG",
        "iso": "NZ"
    },
    {
        "label": "SZS",
        "value": "SZS",
        "iso": "NZ"
    },
    {
        "label": "ROT",
        "value": "ROT",
        "iso": "NZ"
    },
    {
        "label": "TRG",
        "value": "TRG",
        "iso": "NZ"
    },
    {
        "label": "TMZ",
        "value": "TMZ",
        "iso": "NZ"
    },
    {
        "label": "KTF",
        "value": "KTF",
        "iso": "NZ"
    },
    {
        "label": "TKZ",
        "value": "TKZ",
        "iso": "NZ"
    },
    {
        "label": "THH",
        "value": "THH",
        "iso": "NZ"
    },
    {
        "label": "TIU",
        "value": "TIU",
        "iso": "NZ"
    },
    {
        "label": "TWZ",
        "value": "TWZ",
        "iso": "NZ"
    },
    {
        "label": "BHE",
        "value": "BHE",
        "iso": "NZ"
    },
    {
        "label": "WKA",
        "value": "WKA",
        "iso": "NZ"
    },
    {
        "label": "WHK",
        "value": "WHK",
        "iso": "NZ"
    },
    {
        "label": "WLG",
        "value": "WLG",
        "iso": "NZ"
    },
    {
        "label": "WIR",
        "value": "WIR",
        "iso": "NZ"
    },
    {
        "label": "WRE",
        "value": "WRE",
        "iso": "NZ"
    },
    {
        "label": "WSZ",
        "value": "WSZ",
        "iso": "NZ"
    },
    {
        "label": "WTZ",
        "value": "WTZ",
        "iso": "NZ"
    },
    {
        "label": "WAG",
        "value": "WAG",
        "iso": "NZ"
    },
    {
        "label": "O62",
        "value": "O62",
        "iso": "US"
    },
    {
        "label": "BZF",
        "value": "BZF",
        "iso": "US"
    },
    {
        "label": "OAA",
        "value": "OAA",
        "iso": "AF"
    },
    {
        "label": "BIN",
        "value": "BIN",
        "iso": "AF"
    },
    {
        "label": "BST",
        "value": "BST",
        "iso": "AF"
    },
    {
        "label": "CCN",
        "value": "CCN",
        "iso": "AF"
    },
    {
        "label": "DAZ",
        "value": "DAZ",
        "iso": "AF"
    },
    {
        "label": "FAH",
        "value": "FAH",
        "iso": "AF"
    },
    {
        "label": "FBD",
        "value": "FBD",
        "iso": "AF"
    },
    {
        "label": "KWH",
        "value": "KWH",
        "iso": "AF"
    },
    {
        "label": "HEA",
        "value": "HEA",
        "iso": "AF"
    },
    {
        "label": "OAI",
        "value": "OAI",
        "iso": "AF"
    },
    {
        "label": "JAA",
        "value": "JAA",
        "iso": "AF"
    },
    {
        "label": "KBL",
        "value": "KBL",
        "iso": "AF"
    },
    {
        "label": "KDH",
        "value": "KDH",
        "iso": "AF"
    },
    {
        "label": "KHT",
        "value": "KHT",
        "iso": "AF"
    },
    {
        "label": "MMZ",
        "value": "MMZ",
        "iso": "AF"
    },
    {
        "label": "MZR",
        "value": "MZR",
        "iso": "AF"
    },
    {
        "label": "LQN",
        "value": "LQN",
        "iso": "AF"
    },
    {
        "label": "OAS",
        "value": "OAS",
        "iso": "AF"
    },
    {
        "label": "OAH",
        "value": "OAH",
        "iso": "AF"
    },
    {
        "label": "SGA",
        "value": "SGA",
        "iso": "AF"
    },
    {
        "label": "TII",
        "value": "TII",
        "iso": "AF"
    },
    {
        "label": "UND",
        "value": "UND",
        "iso": "AF"
    },
    {
        "label": "OAZ",
        "value": "OAZ",
        "iso": "AF"
    },
    {
        "label": "ZAJ",
        "value": "ZAJ",
        "iso": "AF"
    },
    {
        "label": "BAH",
        "value": "BAH",
        "iso": "BH"
    },
    {
        "label": "OBK",
        "value": "OBK",
        "iso": "US"
    },
    {
        "label": "AHB",
        "value": "AHB",
        "iso": "SA"
    },
    {
        "label": "HOF",
        "value": "HOF",
        "iso": "SA"
    },
    {
        "label": "ABT",
        "value": "ABT",
        "iso": "SA"
    },
    {
        "label": "BHH",
        "value": "BHH",
        "iso": "SA"
    },
    {
        "label": "DMM",
        "value": "DMM",
        "iso": "SA"
    },
    {
        "label": "DHA",
        "value": "DHA",
        "iso": "SA"
    },
    {
        "label": "DWD",
        "value": "DWD",
        "iso": "SA"
    },
    {
        "label": "GIZ",
        "value": "GIZ",
        "iso": "SA"
    },
    {
        "label": "ELQ",
        "value": "ELQ",
        "iso": "SA"
    },
    {
        "label": "URY",
        "value": "URY",
        "iso": "SA"
    },
    {
        "label": "HAS",
        "value": "HAS",
        "iso": "SA"
    },
    {
        "label": "QJB",
        "value": "QJB",
        "iso": "SA"
    },
    {
        "label": "JED",
        "value": "JED",
        "iso": "SA"
    },
    {
        "label": "HBT",
        "value": "HBT",
        "iso": "SA"
    },
    {
        "label": "KMX",
        "value": "KMX",
        "iso": "SA"
    },
    {
        "label": "MED",
        "value": "MED",
        "iso": "SA"
    },
    {
        "label": "EAM",
        "value": "EAM",
        "iso": "SA"
    },
    {
        "label": "AQI",
        "value": "AQI",
        "iso": "SA"
    },
    {
        "label": "AKH",
        "value": "AKH",
        "iso": "SA"
    },
    {
        "label": "RAH",
        "value": "RAH",
        "iso": "SA"
    },
    {
        "label": "RUH",
        "value": "RUH",
        "iso": "SA"
    },
    {
        "label": "RAE",
        "value": "RAE",
        "iso": "SA"
    },
    {
        "label": "XXN",
        "value": "XXN",
        "iso": "SA"
    },
    {
        "label": "SHW",
        "value": "SHW",
        "iso": "SA"
    },
    {
        "label": "AJF",
        "value": "AJF",
        "iso": "SA"
    },
    {
        "label": "SLF",
        "value": "SLF",
        "iso": "SA"
    },
    {
        "label": "TUU",
        "value": "TUU",
        "iso": "SA"
    },
    {
        "label": "TIF",
        "value": "TIF",
        "iso": "SA"
    },
    {
        "label": "TUI",
        "value": "TUI",
        "iso": "SA"
    },
    {
        "label": "EWD",
        "value": "EWD",
        "iso": "SA"
    },
    {
        "label": "EJH",
        "value": "EJH",
        "iso": "SA"
    },
    {
        "label": "YNB",
        "value": "YNB",
        "iso": "SA"
    },
    {
        "label": "ZUL",
        "value": "ZUL",
        "iso": "SA"
    },
    {
        "label": "OHE",
        "value": "OHE",
        "iso": "CN"
    },
    {
        "label": "ABD",
        "value": "ABD",
        "iso": "IR"
    },
    {
        "label": "OMI",
        "value": "OMI",
        "iso": "IR"
    },
    {
        "label": "MRX",
        "value": "MRX",
        "iso": "IR"
    },
    {
        "label": "AWZ",
        "value": "AWZ",
        "iso": "IR"
    },
    {
        "label": "AEU",
        "value": "AEU",
        "iso": "IR"
    },
    {
        "label": "BUZ",
        "value": "BUZ",
        "iso": "IR"
    },
    {
        "label": "AOY",
        "value": "AOY",
        "iso": "IR"
    },
    {
        "label": "KNR",
        "value": "KNR",
        "iso": "IR"
    },
    {
        "label": "KIH",
        "value": "KIH",
        "iso": "IR"
    },
    {
        "label": "BDH",
        "value": "BDH",
        "iso": "IR"
    },
    {
        "label": "YEH",
        "value": "YEH",
        "iso": "IR"
    },
    {
        "label": "KHK",
        "value": "KHK",
        "iso": "IR"
    },
    {
        "label": "SXI",
        "value": "SXI",
        "iso": "IR"
    },
    {
        "label": "LVP",
        "value": "LVP",
        "iso": "IR"
    },
    {
        "label": "KSH",
        "value": "KSH",
        "iso": "IR"
    },
    {
        "label": "IIL",
        "value": "IIL",
        "iso": "IR"
    },
    {
        "label": "KHD",
        "value": "KHD",
        "iso": "IR"
    },
    {
        "label": "SDG",
        "value": "SDG",
        "iso": "IR"
    },
    {
        "label": "IFH",
        "value": "IFH",
        "iso": "IR"
    },
    {
        "label": "IFN",
        "value": "IFN",
        "iso": "IR"
    },
    {
        "label": "CQD",
        "value": "CQD",
        "iso": "IR"
    },
    {
        "label": "RAS",
        "value": "RAS",
        "iso": "IR"
    },
    {
        "label": "HDM",
        "value": "HDM",
        "iso": "IR"
    },
    {
        "label": "AJK",
        "value": "AJK",
        "iso": "IR"
    },
    {
        "label": "IKA",
        "value": "IKA",
        "iso": "IR"
    },
    {
        "label": "THR",
        "value": "THR",
        "iso": "IR"
    },
    {
        "label": "BND",
        "value": "BND",
        "iso": "IR"
    },
    {
        "label": "KER",
        "value": "KER",
        "iso": "IR"
    },
    {
        "label": "BXR",
        "value": "BXR",
        "iso": "IR"
    },
    {
        "label": "HDR",
        "value": "HDR",
        "iso": "IR"
    },
    {
        "label": "RJN",
        "value": "RJN",
        "iso": "IR"
    },
    {
        "label": "SYJ",
        "value": "SYJ",
        "iso": "IR"
    },
    {
        "label": "XBJ",
        "value": "XBJ",
        "iso": "IR"
    },
    {
        "label": "CKT",
        "value": "CKT",
        "iso": "IR"
    },
    {
        "label": "MHD",
        "value": "MHD",
        "iso": "IR"
    },
    {
        "label": "BJB",
        "value": "BJB",
        "iso": "IR"
    },
    {
        "label": "AFZ",
        "value": "AFZ",
        "iso": "IR"
    },
    {
        "label": "TCX",
        "value": "TCX",
        "iso": "IR"
    },
    {
        "label": "BBL",
        "value": "BBL",
        "iso": "IR"
    },
    {
        "label": "GBT",
        "value": "GBT",
        "iso": "IR"
    },
    {
        "label": "BSM",
        "value": "BSM",
        "iso": "IR"
    },
    {
        "label": "NSH",
        "value": "NSH",
        "iso": "IR"
    },
    {
        "label": "RZR",
        "value": "RZR",
        "iso": "IR"
    },
    {
        "label": "SRY",
        "value": "SRY",
        "iso": "IR"
    },
    {
        "label": "FAZ",
        "value": "FAZ",
        "iso": "IR"
    },
    {
        "label": "LRR",
        "value": "LRR",
        "iso": "IR"
    },
    {
        "label": "SYZ",
        "value": "SYZ",
        "iso": "IR"
    },
    {
        "label": "KHY",
        "value": "KHY",
        "iso": "IR"
    },
    {
        "label": "ADU",
        "value": "ADU",
        "iso": "IR"
    },
    {
        "label": "ACP",
        "value": "ACP",
        "iso": "IR"
    },
    {
        "label": "PFQ",
        "value": "PFQ",
        "iso": "IR"
    },
    {
        "label": "OMH",
        "value": "OMH",
        "iso": "IR"
    },
    {
        "label": "TBZ",
        "value": "TBZ",
        "iso": "IR"
    },
    {
        "label": "AZD",
        "value": "AZD",
        "iso": "IR"
    },
    {
        "label": "ACZ",
        "value": "ACZ",
        "iso": "IR"
    },
    {
        "label": "ZBR",
        "value": "ZBR",
        "iso": "IR"
    },
    {
        "label": "ZAH",
        "value": "ZAH",
        "iso": "IR"
    },
    {
        "label": "AMM",
        "value": "AMM",
        "iso": "JO"
    },
    {
        "label": "ADJ",
        "value": "ADJ",
        "iso": "JO"
    },
    {
        "label": "AQJ",
        "value": "AQJ",
        "iso": "JO"
    },
    {
        "label": "JRS",
        "value": "JRS",
        "iso": "PS"
    },
    {
        "label": "OMF",
        "value": "OMF",
        "iso": "JO"
    },
    {
        "label": "KWI",
        "value": "KWI",
        "iso": "KW"
    },
    {
        "label": "BEY",
        "value": "BEY",
        "iso": "LB"
    },
    {
        "label": "KYE",
        "value": "KYE",
        "iso": "LB"
    },
    {
        "label": "BYB",
        "value": "BYB",
        "iso": "OM"
    },
    {
        "label": "AOM",
        "value": "AOM",
        "iso": "OM"
    },
    {
        "label": "AUH",
        "value": "AUH",
        "iso": "AE"
    },
    {
        "label": "AZI",
        "value": "AZI",
        "iso": "AE"
    },
    {
        "label": "AAN",
        "value": "AAN",
        "iso": "AE"
    },
    {
        "label": "DXB",
        "value": "DXB",
        "iso": "AE"
    },
    {
        "label": "NHD",
        "value": "NHD",
        "iso": "AE"
    },
    {
        "label": "DWC",
        "value": "DWC",
        "iso": "AE"
    },
    {
        "label": "FJR",
        "value": "FJR",
        "iso": "AE"
    },
    {
        "label": "RKT",
        "value": "RKT",
        "iso": "AE"
    },
    {
        "label": "SHJ",
        "value": "SHJ",
        "iso": "AE"
    },
    {
        "label": "RMB",
        "value": "RMB",
        "iso": "OM"
    },
    {
        "label": "KHS",
        "value": "KHS",
        "iso": "OM"
    },
    {
        "label": "MSH",
        "value": "MSH",
        "iso": "OM"
    },
    {
        "label": "MCT",
        "value": "MCT",
        "iso": "OM"
    },
    {
        "label": "OMM",
        "value": "OMM",
        "iso": "OM"
    },
    {
        "label": "SLL",
        "value": "SLL",
        "iso": "OM"
    },
    {
        "label": "SUH",
        "value": "SUH",
        "iso": "OM"
    },
    {
        "label": "TTH",
        "value": "TTH",
        "iso": "OM"
    },
    {
        "label": "DDU",
        "value": "DDU",
        "iso": "PK"
    },
    {
        "label": "AAW",
        "value": "AAW",
        "iso": "PK"
    },
    {
        "label": "BNP",
        "value": "BNP",
        "iso": "PK"
    },
    {
        "label": "WGB",
        "value": "WGB",
        "iso": "PK"
    },
    {
        "label": "BHV",
        "value": "BHV",
        "iso": "PK"
    },
    {
        "label": "CJL",
        "value": "CJL",
        "iso": "PK"
    },
    {
        "label": "CHB",
        "value": "CHB",
        "iso": "PK"
    },
    {
        "label": "DBA",
        "value": "DBA",
        "iso": "PK"
    },
    {
        "label": "DDU",
        "value": "DDU",
        "iso": "PK"
    },
    {
        "label": "DEA",
        "value": "DEA",
        "iso": "PK"
    },
    {
        "label": "DSK",
        "value": "DSK",
        "iso": "PK"
    },
    {
        "label": "LYP",
        "value": "LYP",
        "iso": "PK"
    },
    {
        "label": "GWD",
        "value": "GWD",
        "iso": "PK"
    },
    {
        "label": "GIL",
        "value": "GIL",
        "iso": "PK"
    },
    {
        "label": "JAG",
        "value": "JAG",
        "iso": "PK"
    },
    {
        "label": "JIW",
        "value": "JIW",
        "iso": "PK"
    },
    {
        "label": "KHI",
        "value": "KHI",
        "iso": "PK"
    },
    {
        "label": "HDD",
        "value": "HDD",
        "iso": "PK"
    },
    {
        "label": "KDD",
        "value": "KDD",
        "iso": "PK"
    },
    {
        "label": "KBH",
        "value": "KBH",
        "iso": "PK"
    },
    {
        "label": "OHT",
        "value": "OHT",
        "iso": "PK"
    },
    {
        "label": "LHE",
        "value": "LHE",
        "iso": "PK"
    },
    {
        "label": "LRG",
        "value": "LRG",
        "iso": "PK"
    },
    {
        "label": "XJM",
        "value": "XJM",
        "iso": "PK"
    },
    {
        "label": "MFG",
        "value": "MFG",
        "iso": "PK"
    },
    {
        "label": "MWD",
        "value": "MWD",
        "iso": "PK"
    },
    {
        "label": "MJD",
        "value": "MJD",
        "iso": "PK"
    },
    {
        "label": "MPD",
        "value": "MPD",
        "iso": "PK"
    },
    {
        "label": "MPD",
        "value": "MPD",
        "iso": "PK"
    },
    {
        "label": "MUX",
        "value": "MUX",
        "iso": "PK"
    },
    {
        "label": "WNS",
        "value": "WNS",
        "iso": "PK"
    },
    {
        "label": "NHS",
        "value": "NHS",
        "iso": "PK"
    },
    {
        "label": "ORW",
        "value": "ORW",
        "iso": "PK"
    },
    {
        "label": "PAJ",
        "value": "PAJ",
        "iso": "PK"
    },
    {
        "label": "PJG",
        "value": "PJG",
        "iso": "PK"
    },
    {
        "label": "PSI",
        "value": "PSI",
        "iso": "PK"
    },
    {
        "label": "PEW",
        "value": "PEW",
        "iso": "PK"
    },
    {
        "label": "UET",
        "value": "UET",
        "iso": "PK"
    },
    {
        "label": "RYK",
        "value": "RYK",
        "iso": "PK"
    },
    {
        "label": "ISB",
        "value": "ISB",
        "iso": "PK"
    },
    {
        "label": "RAZ",
        "value": "RAZ",
        "iso": "PK"
    },
    {
        "label": "SBQ",
        "value": "SBQ",
        "iso": "PK"
    },
    {
        "label": "KDU",
        "value": "KDU",
        "iso": "PK"
    },
    {
        "label": "SKZ",
        "value": "SKZ",
        "iso": "PK"
    },
    {
        "label": "SYW",
        "value": "SYW",
        "iso": "PK"
    },
    {
        "label": "SGI",
        "value": "SGI",
        "iso": "PK"
    },
    {
        "label": "SDT",
        "value": "SDT",
        "iso": "PK"
    },
    {
        "label": "SKT",
        "value": "SKT",
        "iso": "PK"
    },
    {
        "label": "SUL",
        "value": "SUL",
        "iso": "PK"
    },
    {
        "label": "SWN",
        "value": "SWN",
        "iso": "PK"
    },
    {
        "label": "TLB",
        "value": "TLB",
        "iso": "PK"
    },
    {
        "label": "BDN",
        "value": "BDN",
        "iso": "PK"
    },
    {
        "label": "TFT",
        "value": "TFT",
        "iso": "PK"
    },
    {
        "label": "TUK",
        "value": "TUK",
        "iso": "PK"
    },
    {
        "label": "WAF",
        "value": "WAF",
        "iso": "PK"
    },
    {
        "label": "PZH",
        "value": "PZH",
        "iso": "PK"
    },
    {
        "label": "IQA",
        "value": "IQA",
        "iso": "IQ"
    },
    {
        "label": "BMN",
        "value": "BMN",
        "iso": "IQ"
    },
    {
        "label": "SDA",
        "value": "SDA",
        "iso": "IQ"
    },
    {
        "label": "OSB",
        "value": "OSB",
        "iso": "IQ"
    },
    {
        "label": "EBL",
        "value": "EBL",
        "iso": "IQ"
    },
    {
        "label": "KIK",
        "value": "KIK",
        "iso": "IQ"
    },
    {
        "label": "BSR",
        "value": "BSR",
        "iso": "IQ"
    },
    {
        "label": "OSO",
        "value": "OSO",
        "iso": "IQ"
    },
    {
        "label": "ALP",
        "value": "ALP",
        "iso": "SY"
    },
    {
        "label": "DAM",
        "value": "DAM",
        "iso": "SY"
    },
    {
        "label": "DEZ",
        "value": "DEZ",
        "iso": "SY"
    },
    {
        "label": "KAC",
        "value": "KAC",
        "iso": "SY"
    },
    {
        "label": "LTK",
        "value": "LTK",
        "iso": "SY"
    },
    {
        "label": "PMS",
        "value": "PMS",
        "iso": "SY"
    },
    {
        "label": "DOH",
        "value": "DOH",
        "iso": "QA"
    },
    {
        "label": "IUD",
        "value": "IUD",
        "iso": "QA"
    },
    {
        "label": "OTT",
        "value": "OTT",
        "iso": "BR"
    },
    {
        "label": "ADE",
        "value": "ADE",
        "iso": "YE"
    },
    {
        "label": "EAB",
        "value": "EAB",
        "iso": "YE"
    },
    {
        "label": "AXK",
        "value": "AXK",
        "iso": "YE"
    },
    {
        "label": "BHN",
        "value": "BHN",
        "iso": "YE"
    },
    {
        "label": "AAY",
        "value": "AAY",
        "iso": "YE"
    },
    {
        "label": "HOD",
        "value": "HOD",
        "iso": "YE"
    },
    {
        "label": "MYN",
        "value": "MYN",
        "iso": "YE"
    },
    {
        "label": "IHN",
        "value": "IHN",
        "iso": "YE"
    },
    {
        "label": "RIY",
        "value": "RIY",
        "iso": "YE"
    },
    {
        "label": "SYE",
        "value": "SYE",
        "iso": "YE"
    },
    {
        "label": "SAH",
        "value": "SAH",
        "iso": "YE"
    },
    {
        "label": "SCT",
        "value": "SCT",
        "iso": "YE"
    },
    {
        "label": "GXF",
        "value": "GXF",
        "iso": "YE"
    },
    {
        "label": "TAI",
        "value": "TAI",
        "iso": "YE"
    },
    {
        "label": "ACU",
        "value": "ACU",
        "iso": "PA"
    },
    {
        "label": "AIL",
        "value": "AIL",
        "iso": "PA"
    },
    {
        "label": "AML",
        "value": "AML",
        "iso": "PA"
    },
    {
        "label": "BFQ",
        "value": "BFQ",
        "iso": "PA"
    },
    {
        "label": "ELE",
        "value": "ELE",
        "iso": "PA"
    },
    {
        "label": "OTD",
        "value": "OTD",
        "iso": "PA"
    },
    {
        "label": "SAX",
        "value": "SAX",
        "iso": "PA"
    },
    {
        "label": "AKB",
        "value": "AKB",
        "iso": "US"
    },
    {
        "label": "PTD",
        "value": "PTD",
        "iso": "US"
    },
    {
        "label": "PAQ",
        "value": "PAQ",
        "iso": "US"
    },
    {
        "label": "BTI",
        "value": "BTI",
        "iso": "US"
    },
    {
        "label": "BET",
        "value": "BET",
        "iso": "US"
    },
    {
        "label": "BVU",
        "value": "BVU",
        "iso": "US"
    },
    {
        "label": "BIG",
        "value": "BIG",
        "iso": "US"
    },
    {
        "label": "BKC",
        "value": "BKC",
        "iso": "US"
    },
    {
        "label": "BRW",
        "value": "BRW",
        "iso": "US"
    },
    {
        "label": "BTT",
        "value": "BTT",
        "iso": "US"
    },
    {
        "label": "CDB",
        "value": "CDB",
        "iso": "US"
    },
    {
        "label": "CLF",
        "value": "CLF",
        "iso": "US"
    },
    {
        "label": "CDV",
        "value": "CDV",
        "iso": "US"
    },
    {
        "label": "CZF",
        "value": "CZF",
        "iso": "US"
    },
    {
        "label": "DRG",
        "value": "DRG",
        "iso": "US"
    },
    {
        "label": "ADK",
        "value": "ADK",
        "iso": "US"
    },
    {
        "label": "DLG",
        "value": "DLG",
        "iso": "US"
    },
    {
        "label": "ADQ",
        "value": "ADQ",
        "iso": "US"
    },
    {
        "label": "DUT",
        "value": "DUT",
        "iso": "US"
    },
    {
        "label": "EDF",
        "value": "EDF",
        "iso": "US"
    },
    {
        "label": "EEK",
        "value": "EEK",
        "iso": "US"
    },
    {
        "label": "EHM",
        "value": "EHM",
        "iso": "US"
    },
    {
        "label": "EIL",
        "value": "EIL",
        "iso": "US"
    },
    {
        "label": "ENM",
        "value": "ENM",
        "iso": "US"
    },
    {
        "label": "ENA",
        "value": "ENA",
        "iso": "US"
    },
    {
        "label": "FAI",
        "value": "FAI",
        "iso": "US"
    },
    {
        "label": "FBK",
        "value": "FBK",
        "iso": "US"
    },
    {
        "label": "AFE",
        "value": "AFE",
        "iso": "US"
    },
    {
        "label": "ABL",
        "value": "ABL",
        "iso": "US"
    },
    {
        "label": "FRN",
        "value": "FRN",
        "iso": "US"
    },
    {
        "label": "FMC",
        "value": "FMC",
        "iso": "US"
    },
    {
        "label": "GAL",
        "value": "GAL",
        "iso": "US"
    },
    {
        "label": "GKN",
        "value": "GKN",
        "iso": "US"
    },
    {
        "label": "GAM",
        "value": "GAM",
        "iso": "US"
    },
    {
        "label": "AGN",
        "value": "AGN",
        "iso": "US"
    },
    {
        "label": "GST",
        "value": "GST",
        "iso": "US"
    },
    {
        "label": "SGY",
        "value": "SGY",
        "iso": "US"
    },
    {
        "label": "HCR",
        "value": "HCR",
        "iso": "US"
    },
    {
        "label": "HNS",
        "value": "HNS",
        "iso": "US"
    },
    {
        "label": "HOM",
        "value": "HOM",
        "iso": "US"
    },
    {
        "label": "HPB",
        "value": "HPB",
        "iso": "US"
    },
    {
        "label": "HYG",
        "value": "HYG",
        "iso": "US"
    },
    {
        "label": "IGG",
        "value": "IGG",
        "iso": "US"
    },
    {
        "label": "EII",
        "value": "EII",
        "iso": "US"
    },
    {
        "label": "IAN",
        "value": "IAN",
        "iso": "US"
    },
    {
        "label": "ILI",
        "value": "ILI",
        "iso": "US"
    },
    {
        "label": "UTO",
        "value": "UTO",
        "iso": "US"
    },
    {
        "label": "JNU",
        "value": "JNU",
        "iso": "US"
    },
    {
        "label": "KFP",
        "value": "KFP",
        "iso": "US"
    },
    {
        "label": "AKK",
        "value": "AKK",
        "iso": "US"
    },
    {
        "label": "AKN",
        "value": "AKN",
        "iso": "US"
    },
    {
        "label": "IKO",
        "value": "IKO",
        "iso": "US"
    },
    {
        "label": "AKP",
        "value": "AKP",
        "iso": "US"
    },
    {
        "label": "KTN",
        "value": "KTN",
        "iso": "US"
    },
    {
        "label": "KAL",
        "value": "KAL",
        "iso": "US"
    },
    {
        "label": "AKW",
        "value": "AKW",
        "iso": "US"
    },
    {
        "label": "KLG",
        "value": "KLG",
        "iso": "US"
    },
    {
        "label": "LUR",
        "value": "LUR",
        "iso": "US"
    },
    {
        "label": "MCG",
        "value": "MCG",
        "iso": "US"
    },
    {
        "label": "MOU",
        "value": "MOU",
        "iso": "US"
    },
    {
        "label": "MRI",
        "value": "MRI",
        "iso": "US"
    },
    {
        "label": "MYU",
        "value": "MYU",
        "iso": "US"
    },
    {
        "label": "WNA",
        "value": "WNA",
        "iso": "US"
    },
    {
        "label": "ANC",
        "value": "ANC",
        "iso": "US"
    },
    {
        "label": "ANI",
        "value": "ANI",
        "iso": "US"
    },
    {
        "label": "ENN",
        "value": "ENN",
        "iso": "US"
    },
    {
        "label": "ANN",
        "value": "ANN",
        "iso": "US"
    },
    {
        "label": "ANV",
        "value": "ANV",
        "iso": "US"
    },
    {
        "label": "KNW",
        "value": "KNW",
        "iso": "US"
    },
    {
        "label": "PCA",
        "value": "PCA",
        "iso": "US"
    },
    {
        "label": "HNH",
        "value": "HNH",
        "iso": "US"
    },
    {
        "label": "OME",
        "value": "OME",
        "iso": "US"
    },
    {
        "label": "ORT",
        "value": "ORT",
        "iso": "US"
    },
    {
        "label": "OTZ",
        "value": "OTZ",
        "iso": "US"
    },
    {
        "label": "PBV",
        "value": "PBV",
        "iso": "US"
    },
    {
        "label": "KPC",
        "value": "KPC",
        "iso": "US"
    },
    {
        "label": "PSG",
        "value": "PSG",
        "iso": "US"
    },
    {
        "label": "PTH",
        "value": "PTH",
        "iso": "US"
    },
    {
        "label": "PTU",
        "value": "PTU",
        "iso": "US"
    },
    {
        "label": "PHO",
        "value": "PHO",
        "iso": "US"
    },
    {
        "label": "NUI",
        "value": "NUI",
        "iso": "US"
    },
    {
        "label": "ARC",
        "value": "ARC",
        "iso": "US"
    },
    {
        "label": "RBY",
        "value": "RBY",
        "iso": "US"
    },
    {
        "label": "SVA",
        "value": "SVA",
        "iso": "US"
    },
    {
        "label": "SCC",
        "value": "SCC",
        "iso": "US"
    },
    {
        "label": "SDP",
        "value": "SDP",
        "iso": "US"
    },
    {
        "label": "SHH",
        "value": "SHH",
        "iso": "US"
    },
    {
        "label": "SIT",
        "value": "SIT",
        "iso": "US"
    },
    {
        "label": "KSM",
        "value": "KSM",
        "iso": "US"
    },
    {
        "label": "SNP",
        "value": "SNP",
        "iso": "US"
    },
    {
        "label": "SVW",
        "value": "SVW",
        "iso": "US"
    },
    {
        "label": "SXQ",
        "value": "SXQ",
        "iso": "US"
    },
    {
        "label": "SYA",
        "value": "SYA",
        "iso": "US"
    },
    {
        "label": "TOG",
        "value": "TOG",
        "iso": "US"
    },
    {
        "label": "TKJ",
        "value": "TKJ",
        "iso": "US"
    },
    {
        "label": "TLJ",
        "value": "TLJ",
        "iso": "US"
    },
    {
        "label": "ATK",
        "value": "ATK",
        "iso": "US"
    },
    {
        "label": "VAK",
        "value": "VAK",
        "iso": "US"
    },
    {
        "label": "KVC",
        "value": "KVC",
        "iso": "US"
    },
    {
        "label": "VDZ",
        "value": "VDZ",
        "iso": "US"
    },
    {
        "label": "KVL",
        "value": "KVL",
        "iso": "US"
    },
    {
        "label": "SWD",
        "value": "SWD",
        "iso": "US"
    },
    {
        "label": "WRG",
        "value": "WRG",
        "iso": "US"
    },
    {
        "label": "AIN",
        "value": "AIN",
        "iso": "US"
    },
    {
        "label": "WTK",
        "value": "WTK",
        "iso": "US"
    },
    {
        "label": "IYS",
        "value": "IYS",
        "iso": "US"
    },
    {
        "label": "CIS",
        "value": "CIS",
        "iso": "KI"
    },
    {
        "label": "PCQ",
        "value": "PCQ",
        "iso": "LA"
    },
    {
        "label": "MFT",
        "value": "MFT",
        "iso": "PE"
    },
    {
        "label": "AKI",
        "value": "AKI",
        "iso": "US"
    },
    {
        "label": "AET",
        "value": "AET",
        "iso": "US"
    },
    {
        "label": "FYU",
        "value": "FYU",
        "iso": "US"
    },
    {
        "label": "AKG",
        "value": "AKG",
        "iso": "PG"
    },
    {
        "label": "AWB",
        "value": "AWB",
        "iso": "PG"
    },
    {
        "label": "BAA",
        "value": "BAA",
        "iso": "PG"
    },
    {
        "label": "CVB",
        "value": "CVB",
        "iso": "PG"
    },
    {
        "label": "GMI",
        "value": "GMI",
        "iso": "PG"
    },
    {
        "label": "GVI",
        "value": "GVI",
        "iso": "PG"
    },
    {
        "label": "HYF",
        "value": "HYF",
        "iso": "PG"
    },
    {
        "label": "IHU",
        "value": "IHU",
        "iso": "PG"
    },
    {
        "label": "IIS",
        "value": "IIS",
        "iso": "PG"
    },
    {
        "label": "JAQ",
        "value": "JAQ",
        "iso": "PG"
    },
    {
        "label": "KDR",
        "value": "KDR",
        "iso": "PG"
    },
    {
        "label": "KKD",
        "value": "KKD",
        "iso": "PG"
    },
    {
        "label": "KUY",
        "value": "KUY",
        "iso": "PG"
    },
    {
        "label": "KWO",
        "value": "KWO",
        "iso": "PG"
    },
    {
        "label": "KXR",
        "value": "KXR",
        "iso": "PG"
    },
    {
        "label": "LMI",
        "value": "LMI",
        "iso": "PG"
    },
    {
        "label": "LMY",
        "value": "LMY",
        "iso": "PG"
    },
    {
        "label": "OBX",
        "value": "OBX",
        "iso": "PG"
    },
    {
        "label": "OPU",
        "value": "OPU",
        "iso": "PG"
    },
    {
        "label": "SKC",
        "value": "SKC",
        "iso": "PG"
    },
    {
        "label": "TFI",
        "value": "TFI",
        "iso": "PG"
    },
    {
        "label": "TFM",
        "value": "TFM",
        "iso": "PG"
    },
    {
        "label": "TLO",
        "value": "TLO",
        "iso": "PG"
    },
    {
        "label": "UKU",
        "value": "UKU",
        "iso": "PG"
    },
    {
        "label": "ULE",
        "value": "ULE",
        "iso": "PG"
    },
    {
        "label": "VMU",
        "value": "VMU",
        "iso": "PG"
    },
    {
        "label": "WPM",
        "value": "WPM",
        "iso": "PG"
    },
    {
        "label": "ROP",
        "value": "ROP",
        "iso": "MP"
    },
    {
        "label": "SPN",
        "value": "SPN",
        "iso": "MP"
    },
    {
        "label": "UAM",
        "value": "UAM",
        "iso": "GU"
    },
    {
        "label": "GUM",
        "value": "GUM",
        "iso": "GU"
    },
    {
        "label": "TIQ",
        "value": "TIQ",
        "iso": "MP"
    },
    {
        "label": "ENI",
        "value": "ENI",
        "iso": "PH"
    },
    {
        "label": "BKH",
        "value": "BKH",
        "iso": "US"
    },
    {
        "label": "HDH",
        "value": "HDH",
        "iso": "US"
    },
    {
        "label": "PHG",
        "value": "PHG",
        "iso": "NG"
    },
    {
        "label": "HHI",
        "value": "HHI",
        "iso": "US"
    },
    {
        "label": "HNM",
        "value": "HNM",
        "iso": "US"
    },
    {
        "label": "JHM",
        "value": "JHM",
        "iso": "US"
    },
    {
        "label": "JRF",
        "value": "JRF",
        "iso": "US"
    },
    {
        "label": "KOA",
        "value": "KOA",
        "iso": "US"
    },
    {
        "label": "LIH",
        "value": "LIH",
        "iso": "US"
    },
    {
        "label": "LUP",
        "value": "LUP",
        "iso": "US"
    },
    {
        "label": "MKK",
        "value": "MKK",
        "iso": "US"
    },
    {
        "label": "MUE",
        "value": "MUE",
        "iso": "US"
    },
    {
        "label": "HNL",
        "value": "HNL",
        "iso": "US"
    },
    {
        "label": "LNY",
        "value": "LNY",
        "iso": "US"
    },
    {
        "label": "OGG",
        "value": "OGG",
        "iso": "US"
    },
    {
        "label": "ITO",
        "value": "ITO",
        "iso": "US"
    },
    {
        "label": "UPP",
        "value": "UPP",
        "iso": "US"
    },
    {
        "label": "JON",
        "value": "JON",
        "iso": "UM"
    },
    {
        "label": "BHC",
        "value": "BHC",
        "iso": "PK"
    },
    {
        "label": "CWP",
        "value": "CWP",
        "iso": "PK"
    },
    {
        "label": "GRT",
        "value": "GRT",
        "iso": "PK"
    },
    {
        "label": "HRA",
        "value": "HRA",
        "iso": "PK"
    },
    {
        "label": "KCF",
        "value": "KCF",
        "iso": "PK"
    },
    {
        "label": "REQ",
        "value": "REQ",
        "iso": "PK"
    },
    {
        "label": "RZS",
        "value": "RZS",
        "iso": "PK"
    },
    {
        "label": "SWV",
        "value": "SWV",
        "iso": "PK"
    },
    {
        "label": "ENT",
        "value": "ENT",
        "iso": "MH"
    },
    {
        "label": "MAJ",
        "value": "MAJ",
        "iso": "MH"
    },
    {
        "label": "KIA",
        "value": "KIA",
        "iso": "GY"
    },
    {
        "label": "KWA",
        "value": "KWA",
        "iso": "MH"
    },
    {
        "label": "CXI",
        "value": "CXI",
        "iso": "KI"
    },
    {
        "label": "TNV",
        "value": "TNV",
        "iso": "KI"
    },
    {
        "label": "MDY",
        "value": "MDY",
        "iso": "UM"
    },
    {
        "label": "PIZ",
        "value": "PIZ",
        "iso": "US"
    },
    {
        "label": "PQD",
        "value": "PQD",
        "iso": "LK"
    },
    {
        "label": "DDP",
        "value": "DDP",
        "iso": "PR"
    },
    {
        "label": "HUC",
        "value": "HUC",
        "iso": "PR"
    },
    {
        "label": "PPD",
        "value": "PPD",
        "iso": "PR"
    },
    {
        "label": "TKK",
        "value": "TKK",
        "iso": "FM"
    },
    {
        "label": "PNI",
        "value": "PNI",
        "iso": "FM"
    },
    {
        "label": "ROR",
        "value": "ROR",
        "iso": "PW"
    },
    {
        "label": "KSA",
        "value": "KSA",
        "iso": "FM"
    },
    {
        "label": "YAP",
        "value": "YAP",
        "iso": "FM"
    },
    {
        "label": "AWK",
        "value": "AWK",
        "iso": "UM"
    },
    {
        "label": "BFA",
        "value": "BFA",
        "iso": "PY"
    },
    {
        "label": "OLK",
        "value": "OLK",
        "iso": "PY"
    },
    {
        "label": "PBT",
        "value": "PBT",
        "iso": "PY"
    },
    {
        "label": "PCJ",
        "value": "PCJ",
        "iso": "PY"
    },
    {
        "label": "KIO",
        "value": "KIO",
        "iso": "MH"
    },
    {
        "label": "QFX",
        "value": "QFX",
        "iso": "GL"
    },
    {
        "label": "RAW",
        "value": "RAW",
        "iso": "PG"
    },
    {
        "label": "KNH",
        "value": "KNH",
        "iso": "TW"
    },
    {
        "label": "LZN",
        "value": "LZN",
        "iso": "TW"
    },
    {
        "label": "TTT",
        "value": "TTT",
        "iso": "TW"
    },
    {
        "label": "GNI",
        "value": "GNI",
        "iso": "TW"
    },
    {
        "label": "KHH",
        "value": "KHH",
        "iso": "TW"
    },
    {
        "label": "CYI",
        "value": "CYI",
        "iso": "TW"
    },
    {
        "label": "HCN",
        "value": "HCN",
        "iso": "TW"
    },
    {
        "label": "TXG",
        "value": "TXG",
        "iso": "TW"
    },
    {
        "label": "KYD",
        "value": "KYD",
        "iso": "TW"
    },
    {
        "label": "RMQ",
        "value": "RMQ",
        "iso": "TW"
    },
    {
        "label": "MFK",
        "value": "MFK",
        "iso": "TW"
    },
    {
        "label": "TNN",
        "value": "TNN",
        "iso": "TW"
    },
    {
        "label": "MZG",
        "value": "MZG",
        "iso": "TW"
    },
    {
        "label": "PIF",
        "value": "PIF",
        "iso": "TW"
    },
    {
        "label": "TSA",
        "value": "TSA",
        "iso": "TW"
    },
    {
        "label": "TPE",
        "value": "TPE",
        "iso": "TW"
    },
    {
        "label": "WOT",
        "value": "WOT",
        "iso": "TW"
    },
    {
        "label": "HUN",
        "value": "HUN",
        "iso": "TW"
    },
    {
        "label": "NRT",
        "value": "NRT",
        "iso": "JP"
    },
    {
        "label": "MMJ",
        "value": "MMJ",
        "iso": "JP"
    },
    {
        "label": "IBR",
        "value": "IBR",
        "iso": "JP"
    },
    {
        "label": "MUS",
        "value": "MUS",
        "iso": "JP"
    },
    {
        "label": "IWO",
        "value": "IWO",
        "iso": "JP"
    },
    {
        "label": "KIX",
        "value": "KIX",
        "iso": "JP"
    },
    {
        "label": "SHM",
        "value": "SHM",
        "iso": "JP"
    },
    {
        "label": "UKB",
        "value": "UKB",
        "iso": "JP"
    },
    {
        "label": "HIW",
        "value": "HIW",
        "iso": "JP"
    },
    {
        "label": "TJH",
        "value": "TJH",
        "iso": "JP"
    },
    {
        "label": "OBO",
        "value": "OBO",
        "iso": "JP"
    },
    {
        "label": "CTS",
        "value": "CTS",
        "iso": "JP"
    },
    {
        "label": "HKD",
        "value": "HKD",
        "iso": "JP"
    },
    {
        "label": "KUH",
        "value": "KUH",
        "iso": "JP"
    },
    {
        "label": "MMB",
        "value": "MMB",
        "iso": "JP"
    },
    {
        "label": "SHB",
        "value": "SHB",
        "iso": "JP"
    },
    {
        "label": "OKD",
        "value": "OKD",
        "iso": "JP"
    },
    {
        "label": "RBJ",
        "value": "RBJ",
        "iso": "JP"
    },
    {
        "label": "WKJ",
        "value": "WKJ",
        "iso": "JP"
    },
    {
        "label": "IKI",
        "value": "IKI",
        "iso": "JP"
    },
    {
        "label": "UBJ",
        "value": "UBJ",
        "iso": "JP"
    },
    {
        "label": "TSJ",
        "value": "TSJ",
        "iso": "JP"
    },
    {
        "label": "MBE",
        "value": "MBE",
        "iso": "JP"
    },
    {
        "label": "AKJ",
        "value": "AKJ",
        "iso": "JP"
    },
    {
        "label": "OIR",
        "value": "OIR",
        "iso": "JP"
    },
    {
        "label": "RIS",
        "value": "RIS",
        "iso": "JP"
    },
    {
        "label": "KUM",
        "value": "KUM",
        "iso": "JP"
    },
    {
        "label": "FUJ",
        "value": "FUJ",
        "iso": "JP"
    },
    {
        "label": "FUK",
        "value": "FUK",
        "iso": "JP"
    },
    {
        "label": "TNE",
        "value": "TNE",
        "iso": "JP"
    },
    {
        "label": "KOJ",
        "value": "KOJ",
        "iso": "JP"
    },
    {
        "label": "KMI",
        "value": "KMI",
        "iso": "JP"
    },
    {
        "label": "OIT",
        "value": "OIT",
        "iso": "JP"
    },
    {
        "label": "KKJ",
        "value": "KKJ",
        "iso": "JP"
    },
    {
        "label": "HSG",
        "value": "HSG",
        "iso": "JP"
    },
    {
        "label": "KMJ",
        "value": "KMJ",
        "iso": "JP"
    },
    {
        "label": "NGS",
        "value": "NGS",
        "iso": "JP"
    },
    {
        "label": "NGO",
        "value": "NGO",
        "iso": "JP"
    },
    {
        "label": "ASJ",
        "value": "ASJ",
        "iso": "JP"
    },
    {
        "label": "OKE",
        "value": "OKE",
        "iso": "JP"
    },
    {
        "label": "KKX",
        "value": "KKX",
        "iso": "JP"
    },
    {
        "label": "TKN",
        "value": "TKN",
        "iso": "JP"
    },
    {
        "label": "NKM",
        "value": "NKM",
        "iso": "JP"
    },
    {
        "label": "FKJ",
        "value": "FKJ",
        "iso": "JP"
    },
    {
        "label": "QGU",
        "value": "QGU",
        "iso": "JP"
    },
    {
        "label": "KMQ",
        "value": "KMQ",
        "iso": "JP"
    },
    {
        "label": "OKI",
        "value": "OKI",
        "iso": "JP"
    },
    {
        "label": "TOY",
        "value": "TOY",
        "iso": "JP"
    },
    {
        "label": "NTQ",
        "value": "NTQ",
        "iso": "JP"
    },
    {
        "label": "HIJ",
        "value": "HIJ",
        "iso": "JP"
    },
    {
        "label": "OKJ",
        "value": "OKJ",
        "iso": "JP"
    },
    {
        "label": "IZO",
        "value": "IZO",
        "iso": "JP"
    },
    {
        "label": "YGJ",
        "value": "YGJ",
        "iso": "JP"
    },
    {
        "label": "KCZ",
        "value": "KCZ",
        "iso": "JP"
    },
    {
        "label": "MYJ",
        "value": "MYJ",
        "iso": "JP"
    },
    {
        "label": "ITM",
        "value": "ITM",
        "iso": "JP"
    },
    {
        "label": "TTJ",
        "value": "TTJ",
        "iso": "JP"
    },
    {
        "label": "TKS",
        "value": "TKS",
        "iso": "JP"
    },
    {
        "label": "TAK",
        "value": "TAK",
        "iso": "JP"
    },
    {
        "label": "IWJ",
        "value": "IWJ",
        "iso": "JP"
    },
    {
        "label": "AOJ",
        "value": "AOJ",
        "iso": "JP"
    },
    {
        "label": "GAJ",
        "value": "GAJ",
        "iso": "JP"
    },
    {
        "label": "SDS",
        "value": "SDS",
        "iso": "JP"
    },
    {
        "label": "FKS",
        "value": "FKS",
        "iso": "JP"
    },
    {
        "label": "HHE",
        "value": "HHE",
        "iso": "JP"
    },
    {
        "label": "HNA",
        "value": "HNA",
        "iso": "JP"
    },
    {
        "label": "AXT",
        "value": "AXT",
        "iso": "JP"
    },
    {
        "label": "MSJ",
        "value": "MSJ",
        "iso": "JP"
    },
    {
        "label": "KIJ",
        "value": "KIJ",
        "iso": "JP"
    },
    {
        "label": "ONJ",
        "value": "ONJ",
        "iso": "JP"
    },
    {
        "label": "SDJ",
        "value": "SDJ",
        "iso": "JP"
    },
    {
        "label": "SYO",
        "value": "SYO",
        "iso": "JP"
    },
    {
        "label": "NJA",
        "value": "NJA",
        "iso": "JP"
    },
    {
        "label": "HAC",
        "value": "HAC",
        "iso": "JP"
    },
    {
        "label": "OIM",
        "value": "OIM",
        "iso": "JP"
    },
    {
        "label": "MYE",
        "value": "MYE",
        "iso": "JP"
    },
    {
        "label": "HND",
        "value": "HND",
        "iso": "JP"
    },
    {
        "label": "QUT",
        "value": "QUT",
        "iso": "JP"
    },
    {
        "label": "OKO",
        "value": "OKO",
        "iso": "JP"
    },
    {
        "label": "KWJ",
        "value": "KWJ",
        "iso": "KR"
    },
    {
        "label": "KUV",
        "value": "KUV",
        "iso": "KR"
    },
    {
        "label": "MPK",
        "value": "MPK",
        "iso": "KR"
    },
    {
        "label": "CHN",
        "value": "CHN",
        "iso": "KR"
    },
    {
        "label": "RSU",
        "value": "RSU",
        "iso": "KR"
    },
    {
        "label": "QUN",
        "value": "QUN",
        "iso": "KR"
    },
    {
        "label": "SHO",
        "value": "SHO",
        "iso": "KR"
    },
    {
        "label": "KAG",
        "value": "KAG",
        "iso": "KR"
    },
    {
        "label": "WJU",
        "value": "WJU",
        "iso": "KR"
    },
    {
        "label": "YNY",
        "value": "YNY",
        "iso": "KR"
    },
    {
        "label": "CJU",
        "value": "CJU",
        "iso": "KR"
    },
    {
        "label": "CHF",
        "value": "CHF",
        "iso": "KR"
    },
    {
        "label": "PUS",
        "value": "PUS",
        "iso": "KR"
    },
    {
        "label": "HIN",
        "value": "HIN",
        "iso": "KR"
    },
    {
        "label": "USN",
        "value": "USN",
        "iso": "KR"
    },
    {
        "label": "ICN",
        "value": "ICN",
        "iso": "KR"
    },
    {
        "label": "SSN",
        "value": "SSN",
        "iso": "KR"
    },
    {
        "label": "OSN",
        "value": "OSN",
        "iso": "KR"
    },
    {
        "label": "GMP",
        "value": "GMP",
        "iso": "KR"
    },
    {
        "label": "SWU",
        "value": "SWU",
        "iso": "KR"
    },
    {
        "label": "KPO",
        "value": "KPO",
        "iso": "KR"
    },
    {
        "label": "TAE",
        "value": "TAE",
        "iso": "KR"
    },
    {
        "label": "CJJ",
        "value": "CJJ",
        "iso": "KR"
    },
    {
        "label": "YEC",
        "value": "YEC",
        "iso": "KR"
    },
    {
        "label": "OKA",
        "value": "OKA",
        "iso": "JP"
    },
    {
        "label": "DNA",
        "value": "DNA",
        "iso": "JP"
    },
    {
        "label": "ISG",
        "value": "ISG",
        "iso": "JP"
    },
    {
        "label": "UEO",
        "value": "UEO",
        "iso": "JP"
    },
    {
        "label": "KJP",
        "value": "KJP",
        "iso": "JP"
    },
    {
        "label": "MMD",
        "value": "MMD",
        "iso": "JP"
    },
    {
        "label": "MMY",
        "value": "MMY",
        "iso": "JP"
    },
    {
        "label": "AGJ",
        "value": "AGJ",
        "iso": "JP"
    },
    {
        "label": "IEJ",
        "value": "IEJ",
        "iso": "JP"
    },
    {
        "label": "HTR",
        "value": "HTR",
        "iso": "JP"
    },
    {
        "label": "KTD",
        "value": "KTD",
        "iso": "JP"
    },
    {
        "label": "SHI",
        "value": "SHI",
        "iso": "JP"
    },
    {
        "label": "TRA",
        "value": "TRA",
        "iso": "JP"
    },
    {
        "label": "RNJ",
        "value": "RNJ",
        "iso": "JP"
    },
    {
        "label": "OGN",
        "value": "OGN",
        "iso": "JP"
    },
    {
        "label": "SFS",
        "value": "SFS",
        "iso": "PH"
    },
    {
        "label": "CRK",
        "value": "CRK",
        "iso": "PH"
    },
    {
        "label": "LAO",
        "value": "LAO",
        "iso": "PH"
    },
    {
        "label": "MNL",
        "value": "MNL",
        "iso": "PH"
    },
    {
        "label": "CYU",
        "value": "CYU",
        "iso": "PH"
    },
    {
        "label": "LGP",
        "value": "LGP",
        "iso": "PH"
    },
    {
        "label": "NSP",
        "value": "NSP",
        "iso": "PH"
    },
    {
        "label": "LBX",
        "value": "LBX",
        "iso": "PH"
    },
    {
        "label": "AAV",
        "value": "AAV",
        "iso": "PH"
    },
    {
        "label": "GES",
        "value": "GES",
        "iso": "PH"
    },
    {
        "label": "CBO",
        "value": "CBO",
        "iso": "PH"
    },
    {
        "label": "DVO",
        "value": "DVO",
        "iso": "PH"
    },
    {
        "label": "BXU",
        "value": "BXU",
        "iso": "PH"
    },
    {
        "label": "BPH",
        "value": "BPH",
        "iso": "PH"
    },
    {
        "label": "DPL",
        "value": "DPL",
        "iso": "PH"
    },
    {
        "label": "CGM",
        "value": "CGM",
        "iso": "PH"
    },
    {
        "label": "IGN",
        "value": "IGN",
        "iso": "PH"
    },
    {
        "label": "JOL",
        "value": "JOL",
        "iso": "PH"
    },
    {
        "label": "CGY",
        "value": "CGY",
        "iso": "PH"
    },
    {
        "label": "MLP",
        "value": "MLP",
        "iso": "PH"
    },
    {
        "label": "SGS",
        "value": "SGS",
        "iso": "PH"
    },
    {
        "label": "OZC",
        "value": "OZC",
        "iso": "PH"
    },
    {
        "label": "PAG",
        "value": "PAG",
        "iso": "PH"
    },
    {
        "label": "MXI",
        "value": "MXI",
        "iso": "PH"
    },
    {
        "label": "SUG",
        "value": "SUG",
        "iso": "PH"
    },
    {
        "label": "CDY",
        "value": "CDY",
        "iso": "PH"
    },
    {
        "label": "IPE",
        "value": "IPE",
        "iso": "PH"
    },
    {
        "label": "TDG",
        "value": "TDG",
        "iso": "PH"
    },
    {
        "label": "ZAM",
        "value": "ZAM",
        "iso": "PH"
    },
    {
        "label": "IAO",
        "value": "IAO",
        "iso": "PH"
    },
    {
        "label": "BAG",
        "value": "BAG",
        "iso": "PH"
    },
    {
        "label": "DTE",
        "value": "DTE",
        "iso": "PH"
    },
    {
        "label": "SJI",
        "value": "SJI",
        "iso": "PH"
    },
    {
        "label": "MBO",
        "value": "MBO",
        "iso": "PH"
    },
    {
        "label": "WNP",
        "value": "WNP",
        "iso": "PH"
    },
    {
        "label": "BSO",
        "value": "BSO",
        "iso": "PH"
    },
    {
        "label": "BQA",
        "value": "BQA",
        "iso": "PH"
    },
    {
        "label": "SFE",
        "value": "SFE",
        "iso": "PH"
    },
    {
        "label": "TUG",
        "value": "TUG",
        "iso": "PH"
    },
    {
        "label": "VRC",
        "value": "VRC",
        "iso": "PH"
    },
    {
        "label": "MRQ",
        "value": "MRQ",
        "iso": "PH"
    },
    {
        "label": "CYZ",
        "value": "CYZ",
        "iso": "PH"
    },
    {
        "label": "TAC",
        "value": "TAC",
        "iso": "PH"
    },
    {
        "label": "BCD",
        "value": "BCD",
        "iso": "PH"
    },
    {
        "label": "CYP",
        "value": "CYP",
        "iso": "PH"
    },
    {
        "label": "DGT",
        "value": "DGT",
        "iso": "PH"
    },
    {
        "label": "MPH",
        "value": "MPH",
        "iso": "PH"
    },
    {
        "label": "CRM",
        "value": "CRM",
        "iso": "PH"
    },
    {
        "label": "GUI",
        "value": "GUI",
        "iso": "PH"
    },
    {
        "label": "ILO",
        "value": "ILO",
        "iso": "PH"
    },
    {
        "label": "MBT",
        "value": "MBT",
        "iso": "PH"
    },
    {
        "label": "KLO",
        "value": "KLO",
        "iso": "PH"
    },
    {
        "label": "CEB",
        "value": "CEB",
        "iso": "PH"
    },
    {
        "label": "OMC",
        "value": "OMC",
        "iso": "PH"
    },
    {
        "label": "PPS",
        "value": "PPS",
        "iso": "PH"
    },
    {
        "label": "RXS",
        "value": "RXS",
        "iso": "PH"
    },
    {
        "label": "EUQ",
        "value": "EUQ",
        "iso": "PH"
    },
    {
        "label": "TAG",
        "value": "TAG",
        "iso": "PH"
    },
    {
        "label": "TBH",
        "value": "TBH",
        "iso": "PH"
    },
    {
        "label": "USU",
        "value": "USU",
        "iso": "PH"
    },
    {
        "label": "BPR",
        "value": "BPR",
        "iso": "PH"
    },
    {
        "label": "NGK",
        "value": "NGK",
        "iso": "RU"
    },
    {
        "label": "GRV",
        "value": "GRV",
        "iso": "RU"
    },
    {
        "label": "LNX",
        "value": "LNX",
        "iso": "RU"
    },
    {
        "label": "VUS",
        "value": "VUS",
        "iso": "RU"
    },
    {
        "label": "LPS",
        "value": "LPS",
        "iso": "US"
    },
    {
        "label": "MJR",
        "value": "MJR",
        "iso": "AR"
    },
    {
        "label": "CCT",
        "value": "CCT",
        "iso": "AR"
    },
    {
        "label": "COC",
        "value": "COC",
        "iso": "AR"
    },
    {
        "label": "GHU",
        "value": "GHU",
        "iso": "AR"
    },
    {
        "label": "JNI",
        "value": "JNI",
        "iso": "AR"
    },
    {
        "label": "PRA",
        "value": "PRA",
        "iso": "AR"
    },
    {
        "label": "ROS",
        "value": "ROS",
        "iso": "AR"
    },
    {
        "label": "SFN",
        "value": "SFN",
        "iso": "AR"
    },
    {
        "label": "AEP",
        "value": "AEP",
        "iso": "AR"
    },
    {
        "label": "LCM",
        "value": "LCM",
        "iso": "AR"
    },
    {
        "label": "COR",
        "value": "COR",
        "iso": "AR"
    },
    {
        "label": "FDO",
        "value": "FDO",
        "iso": "AR"
    },
    {
        "label": "LPG",
        "value": "LPG",
        "iso": "AR"
    },
    {
        "label": "EPA",
        "value": "EPA",
        "iso": "AR"
    },
    {
        "label": "EZE",
        "value": "EZE",
        "iso": "AR"
    },
    {
        "label": "HOS",
        "value": "HOS",
        "iso": "AR"
    },
    {
        "label": "CVH",
        "value": "CVH",
        "iso": "AR"
    },
    {
        "label": "GNR",
        "value": "GNR",
        "iso": "AR"
    },
    {
        "label": "RDS",
        "value": "RDS",
        "iso": "AR"
    },
    {
        "label": "APZ",
        "value": "APZ",
        "iso": "AR"
    },
    {
        "label": "MDZ",
        "value": "MDZ",
        "iso": "AR"
    },
    {
        "label": "LGS",
        "value": "LGS",
        "iso": "AR"
    },
    {
        "label": "AFA",
        "value": "AFA",
        "iso": "AR"
    },
    {
        "label": "CTC",
        "value": "CTC",
        "iso": "AR"
    },
    {
        "label": "SDE",
        "value": "SDE",
        "iso": "AR"
    },
    {
        "label": "RHD",
        "value": "RHD",
        "iso": "AR"
    },
    {
        "label": "IRJ",
        "value": "IRJ",
        "iso": "AR"
    },
    {
        "label": "TUC",
        "value": "TUC",
        "iso": "AR"
    },
    {
        "label": "UAQ",
        "value": "UAQ",
        "iso": "AR"
    },
    {
        "label": "CRR",
        "value": "CRR",
        "iso": "AR"
    },
    {
        "label": "RCU",
        "value": "RCU",
        "iso": "AR"
    },
    {
        "label": "VDR",
        "value": "VDR",
        "iso": "AR"
    },
    {
        "label": "VME",
        "value": "VME",
        "iso": "AR"
    },
    {
        "label": "RLO",
        "value": "RLO",
        "iso": "AR"
    },
    {
        "label": "LUQ",
        "value": "LUQ",
        "iso": "AR"
    },
    {
        "label": "CNQ",
        "value": "CNQ",
        "iso": "AR"
    },
    {
        "label": "RES",
        "value": "RES",
        "iso": "AR"
    },
    {
        "label": "FMA",
        "value": "FMA",
        "iso": "AR"
    },
    {
        "label": "IGR",
        "value": "IGR",
        "iso": "AR"
    },
    {
        "label": "AOL",
        "value": "AOL",
        "iso": "AR"
    },
    {
        "label": "MCS",
        "value": "MCS",
        "iso": "AR"
    },
    {
        "label": "PSS",
        "value": "PSS",
        "iso": "AR"
    },
    {
        "label": "PSV",
        "value": "PSV",
        "iso": "AR"
    },
    {
        "label": "SLA",
        "value": "SLA",
        "iso": "AR"
    },
    {
        "label": "JUJ",
        "value": "JUJ",
        "iso": "AR"
    },
    {
        "label": "ORA",
        "value": "ORA",
        "iso": "AR"
    },
    {
        "label": "TTG",
        "value": "TTG",
        "iso": "AR"
    },
    {
        "label": "CLX",
        "value": "CLX",
        "iso": "AR"
    },
    {
        "label": "ELO",
        "value": "ELO",
        "iso": "AR"
    },
    {
        "label": "OYA",
        "value": "OYA",
        "iso": "AR"
    },
    {
        "label": "LLS",
        "value": "LLS",
        "iso": "AR"
    },
    {
        "label": "MDX",
        "value": "MDX",
        "iso": "AR"
    },
    {
        "label": "RCQ",
        "value": "RCQ",
        "iso": "AR"
    },
    {
        "label": "UZU",
        "value": "UZU",
        "iso": "AR"
    },
    {
        "label": "EHL",
        "value": "EHL",
        "iso": "AR"
    },
    {
        "label": "CRD",
        "value": "CRD",
        "iso": "AR"
    },
    {
        "label": "EMX",
        "value": "EMX",
        "iso": "AR"
    },
    {
        "label": "EQS",
        "value": "EQS",
        "iso": "AR"
    },
    {
        "label": "LHS",
        "value": "LHS",
        "iso": "AR"
    },
    {
        "label": "IGB",
        "value": "IGB",
        "iso": "AR"
    },
    {
        "label": "OES",
        "value": "OES",
        "iso": "AR"
    },
    {
        "label": "MQD",
        "value": "MQD",
        "iso": "AR"
    },
    {
        "label": "ARR",
        "value": "ARR",
        "iso": "AR"
    },
    {
        "label": "SGV",
        "value": "SGV",
        "iso": "AR"
    },
    {
        "label": "REL",
        "value": "REL",
        "iso": "AR"
    },
    {
        "label": "VDM",
        "value": "VDM",
        "iso": "AR"
    },
    {
        "label": "PMY",
        "value": "PMY",
        "iso": "AR"
    },
    {
        "label": "ING",
        "value": "ING",
        "iso": "AR"
    },
    {
        "label": "FTE",
        "value": "FTE",
        "iso": "AR"
    },
    {
        "label": "PUD",
        "value": "PUD",
        "iso": "AR"
    },
    {
        "label": "RGA",
        "value": "RGA",
        "iso": "AR"
    },
    {
        "label": "RGL",
        "value": "RGL",
        "iso": "AR"
    },
    {
        "label": "USH",
        "value": "USH",
        "iso": "AR"
    },
    {
        "label": "ULA",
        "value": "ULA",
        "iso": "AR"
    },
    {
        "label": "ROY",
        "value": "ROY",
        "iso": "AR"
    },
    {
        "label": "PMQ",
        "value": "PMQ",
        "iso": "AR"
    },
    {
        "label": "GGS",
        "value": "GGS",
        "iso": "AR"
    },
    {
        "label": "JSM",
        "value": "JSM",
        "iso": "AR"
    },
    {
        "label": "RYO",
        "value": "RYO",
        "iso": "AR"
    },
    {
        "label": "RZA",
        "value": "RZA",
        "iso": "AR"
    },
    {
        "label": "BHI",
        "value": "BHI",
        "iso": "AR"
    },
    {
        "label": "CSZ",
        "value": "CSZ",
        "iso": "AR"
    },
    {
        "label": "OVR",
        "value": "OVR",
        "iso": "AR"
    },
    {
        "label": "GPO",
        "value": "GPO",
        "iso": "AR"
    },
    {
        "label": "OYO",
        "value": "OYO",
        "iso": "AR"
    },
    {
        "label": "SST",
        "value": "SST",
        "iso": "AR"
    },
    {
        "label": "MDQ",
        "value": "MDQ",
        "iso": "AR"
    },
    {
        "label": "NQN",
        "value": "NQN",
        "iso": "AR"
    },
    {
        "label": "NEC",
        "value": "NEC",
        "iso": "AR"
    },
    {
        "label": "PEH",
        "value": "PEH",
        "iso": "AR"
    },
    {
        "label": "RSA",
        "value": "RSA",
        "iso": "AR"
    },
    {
        "label": "BRC",
        "value": "BRC",
        "iso": "AR"
    },
    {
        "label": "TDL",
        "value": "TDL",
        "iso": "AR"
    },
    {
        "label": "VLG",
        "value": "VLG",
        "iso": "AR"
    },
    {
        "label": "CUT",
        "value": "CUT",
        "iso": "AR"
    },
    {
        "label": "CPC",
        "value": "CPC",
        "iso": "AR"
    },
    {
        "label": "VIU",
        "value": "VIU",
        "iso": "SB"
    },
    {
        "label": "SB0",
        "value": "SB0",
        "iso": "BR"
    },
    {
        "label": "SB2",
        "value": "SB2",
        "iso": "BR"
    },
    {
        "label": "CDJ",
        "value": "CDJ",
        "iso": "BR"
    },
    {
        "label": "APS",
        "value": "APS",
        "iso": "BR"
    },
    {
        "label": "AQA",
        "value": "AQA",
        "iso": "BR"
    },
    {
        "label": "AJU",
        "value": "AJU",
        "iso": "BR"
    },
    {
        "label": "AFL",
        "value": "AFL",
        "iso": "BR"
    },
    {
        "label": "ARU",
        "value": "ARU",
        "iso": "BR"
    },
    {
        "label": "AAX",
        "value": "AAX",
        "iso": "BR"
    },
    {
        "label": "BEL",
        "value": "BEL",
        "iso": "BR"
    },
    {
        "label": "BGX",
        "value": "BGX",
        "iso": "BR"
    },
    {
        "label": "PLU",
        "value": "PLU",
        "iso": "BR"
    },
    {
        "label": "QAK",
        "value": "QAK",
        "iso": "BR"
    },
    {
        "label": "BSB",
        "value": "BSB",
        "iso": "BR"
    },
    {
        "label": "BAT",
        "value": "BAT",
        "iso": "BR"
    },
    {
        "label": "BAU",
        "value": "BAU",
        "iso": "BR"
    },
    {
        "label": "BVB",
        "value": "BVB",
        "iso": "BR"
    },
    {
        "label": "BPG",
        "value": "BPG",
        "iso": "BR"
    },
    {
        "label": "BZC",
        "value": "BZC",
        "iso": "BR"
    },
    {
        "label": "CAC",
        "value": "CAC",
        "iso": "BR"
    },
    {
        "label": "CFB",
        "value": "CFB",
        "iso": "BR"
    },
    {
        "label": "ITB",
        "value": "ITB",
        "iso": "BR"
    },
    {
        "label": "CNF",
        "value": "CNF",
        "iso": "BR"
    },
    {
        "label": "CGR",
        "value": "CGR",
        "iso": "BR"
    },
    {
        "label": "XAP",
        "value": "XAP",
        "iso": "BR"
    },
    {
        "label": "CLN",
        "value": "CLN",
        "iso": "BR"
    },
    {
        "label": "CKS",
        "value": "CKS",
        "iso": "BR"
    },
    {
        "label": "CCM",
        "value": "CCM",
        "iso": "BR"
    },
    {
        "label": "CLV",
        "value": "CLV",
        "iso": "BR"
    },
    {
        "label": "QNS",
        "value": "QNS",
        "iso": "BR"
    },
    {
        "label": "CAW",
        "value": "CAW",
        "iso": "BR"
    },
    {
        "label": "CMG",
        "value": "CMG",
        "iso": "BR"
    },
    {
        "label": "CWB",
        "value": "CWB",
        "iso": "BR"
    },
    {
        "label": "CRQ",
        "value": "CRQ",
        "iso": "BR"
    },
    {
        "label": "CXJ",
        "value": "CXJ",
        "iso": "BR"
    },
    {
        "label": "CGB",
        "value": "CGB",
        "iso": "BR"
    },
    {
        "label": "CZS",
        "value": "CZS",
        "iso": "BR"
    },
    {
        "label": "PPB",
        "value": "PPB",
        "iso": "BR"
    },
    {
        "label": "MAO",
        "value": "MAO",
        "iso": "BR"
    },
    {
        "label": "JCR",
        "value": "JCR",
        "iso": "BR"
    },
    {
        "label": "IGU",
        "value": "IGU",
        "iso": "BR"
    },
    {
        "label": "FLN",
        "value": "FLN",
        "iso": "BR"
    },
    {
        "label": "FEN",
        "value": "FEN",
        "iso": "BR"
    },
    {
        "label": "FOR",
        "value": "FOR",
        "iso": "BR"
    },
    {
        "label": "GIG",
        "value": "GIG",
        "iso": "BR"
    },
    {
        "label": "GJM",
        "value": "GJM",
        "iso": "BR"
    },
    {
        "label": "GYN",
        "value": "GYN",
        "iso": "BR"
    },
    {
        "label": "GRU",
        "value": "GRU",
        "iso": "BR"
    },
    {
        "label": "GPB",
        "value": "GPB",
        "iso": "BR"
    },
    {
        "label": "GVR",
        "value": "GVR",
        "iso": "BR"
    },
    {
        "label": "GUJ",
        "value": "GUJ",
        "iso": "BR"
    },
    {
        "label": "ATM",
        "value": "ATM",
        "iso": "BR"
    },
    {
        "label": "ITA",
        "value": "ITA",
        "iso": "BR"
    },
    {
        "label": "ITB",
        "value": "ITB",
        "iso": "BR"
    },
    {
        "label": "IOS",
        "value": "IOS",
        "iso": "BR"
    },
    {
        "label": "IPN",
        "value": "IPN",
        "iso": "BR"
    },
    {
        "label": "IMP",
        "value": "IMP",
        "iso": "BR"
    },
    {
        "label": "JDF",
        "value": "JDF",
        "iso": "BR"
    },
    {
        "label": "JPA",
        "value": "JPA",
        "iso": "BR"
    },
    {
        "label": "JDO",
        "value": "JDO",
        "iso": "BR"
    },
    {
        "label": "JOI",
        "value": "JOI",
        "iso": "BR"
    },
    {
        "label": "CPV",
        "value": "CPV",
        "iso": "BR"
    },
    {
        "label": "VCP",
        "value": "VCP",
        "iso": "BR"
    },
    {
        "label": "LEC",
        "value": "LEC",
        "iso": "BR"
    },
    {
        "label": "LAJ",
        "value": "LAJ",
        "iso": "BR"
    },
    {
        "label": "LIP",
        "value": "LIP",
        "iso": "BR"
    },
    {
        "label": "LDB",
        "value": "LDB",
        "iso": "BR"
    },
    {
        "label": "LAZ",
        "value": "LAZ",
        "iso": "BR"
    },
    {
        "label": "MAB",
        "value": "MAB",
        "iso": "BR"
    },
    {
        "label": "MQH",
        "value": "MQH",
        "iso": "BR"
    },
    {
        "label": "MEU",
        "value": "MEU",
        "iso": "BR"
    },
    {
        "label": "MEA",
        "value": "MEA",
        "iso": "BR"
    },
    {
        "label": "MGF",
        "value": "MGF",
        "iso": "BR"
    },
    {
        "label": "MOC",
        "value": "MOC",
        "iso": "BR"
    },
    {
        "label": "MII",
        "value": "MII",
        "iso": "BR"
    },
    {
        "label": "PLL",
        "value": "PLL",
        "iso": "BR"
    },
    {
        "label": "MCZ",
        "value": "MCZ",
        "iso": "BR"
    },
    {
        "label": "MCP",
        "value": "MCP",
        "iso": "BR"
    },
    {
        "label": "MVF",
        "value": "MVF",
        "iso": "BR"
    },
    {
        "label": "SAO",
        "value": "SAO",
        "iso": "BR"
    },
    {
        "label": "MNX",
        "value": "MNX",
        "iso": "BR"
    },
    {
        "label": "NVT",
        "value": "NVT",
        "iso": "BR"
    },
    {
        "label": "GEL",
        "value": "GEL",
        "iso": "BR"
    },
    {
        "label": "NAT",
        "value": "NAT",
        "iso": "BR"
    },
    {
        "label": "OYK",
        "value": "OYK",
        "iso": "BR"
    },
    {
        "label": "POA",
        "value": "POA",
        "iso": "BR"
    },
    {
        "label": "PBB",
        "value": "PBB",
        "iso": "BR"
    },
    {
        "label": "POO",
        "value": "POO",
        "iso": "BR"
    },
    {
        "label": "PFB",
        "value": "PFB",
        "iso": "BR"
    },
    {
        "label": "PMW",
        "value": "PMW",
        "iso": "BR"
    },
    {
        "label": "PET",
        "value": "PET",
        "iso": "BR"
    },
    {
        "label": "PNZ",
        "value": "PNZ",
        "iso": "BR"
    },
    {
        "label": "PNB",
        "value": "PNB",
        "iso": "BR"
    },
    {
        "label": "PMG",
        "value": "PMG",
        "iso": "BR"
    },
    {
        "label": "BPS",
        "value": "BPS",
        "iso": "BR"
    },
    {
        "label": "PVH",
        "value": "PVH",
        "iso": "BR"
    },
    {
        "label": "VDC",
        "value": "VDC",
        "iso": "BR"
    },
    {
        "label": "RBR",
        "value": "RBR",
        "iso": "BR"
    },
    {
        "label": "REC",
        "value": "REC",
        "iso": "BR"
    },
    {
        "label": "SDU",
        "value": "SDU",
        "iso": "BR"
    },
    {
        "label": "RAO",
        "value": "RAO",
        "iso": "BR"
    },
    {
        "label": "BRB",
        "value": "BRB",
        "iso": "BR"
    },
    {
        "label": "SNZ",
        "value": "SNZ",
        "iso": "BR"
    },
    {
        "label": "SJK",
        "value": "SJK",
        "iso": "BR"
    },
    {
        "label": "SLZ",
        "value": "SLZ",
        "iso": "BR"
    },
    {
        "label": "RIA",
        "value": "RIA",
        "iso": "BR"
    },
    {
        "label": "STM",
        "value": "STM",
        "iso": "BR"
    },
    {
        "label": "CGH",
        "value": "CGH",
        "iso": "BR"
    },
    {
        "label": "SJP",
        "value": "SJP",
        "iso": "BR"
    },
    {
        "label": "SSZ",
        "value": "SSZ",
        "iso": "BR"
    },
    {
        "label": "SSA",
        "value": "SSA",
        "iso": "BR"
    },
    {
        "label": "QHP",
        "value": "QHP",
        "iso": "BR"
    },
    {
        "label": "TMT",
        "value": "TMT",
        "iso": "BR"
    },
    {
        "label": "UNA",
        "value": "UNA",
        "iso": "BR"
    },
    {
        "label": "TOW",
        "value": "TOW",
        "iso": "BR"
    },
    {
        "label": "THE",
        "value": "THE",
        "iso": "BR"
    },
    {
        "label": "TFF",
        "value": "TFF",
        "iso": "BR"
    },
    {
        "label": "TRQ",
        "value": "TRQ",
        "iso": "BR"
    },
    {
        "label": "TEC",
        "value": "TEC",
        "iso": "BR"
    },
    {
        "label": "OBI",
        "value": "OBI",
        "iso": "BR"
    },
    {
        "label": "TBT",
        "value": "TBT",
        "iso": "BR"
    },
    {
        "label": "TUR",
        "value": "TUR",
        "iso": "BR"
    },
    {
        "label": "SJL",
        "value": "SJL",
        "iso": "BR"
    },
    {
        "label": "PAV",
        "value": "PAV",
        "iso": "BR"
    },
    {
        "label": "URG",
        "value": "URG",
        "iso": "BR"
    },
    {
        "label": "UDI",
        "value": "UDI",
        "iso": "BR"
    },
    {
        "label": "UBA",
        "value": "UBA",
        "iso": "BR"
    },
    {
        "label": "VAG",
        "value": "VAG",
        "iso": "BR"
    },
    {
        "label": "BVH",
        "value": "BVH",
        "iso": "BR"
    },
    {
        "label": "VIX",
        "value": "VIX",
        "iso": "BR"
    },
    {
        "label": "QPS",
        "value": "QPS",
        "iso": "BR"
    },
    {
        "label": "PRI",
        "value": "PRI",
        "iso": "SC"
    },
    {
        "label": "PRI",
        "value": "PRI",
        "iso": "SC"
    },
    {
        "label": "ZUD",
        "value": "ZUD",
        "iso": "CL"
    },
    {
        "label": "LOB",
        "value": "LOB",
        "iso": "CL"
    },
    {
        "label": "WAP",
        "value": "WAP",
        "iso": "CL"
    },
    {
        "label": "ARI",
        "value": "ARI",
        "iso": "CL"
    },
    {
        "label": "WPA",
        "value": "WPA",
        "iso": "CL"
    },
    {
        "label": "DAT",
        "value": "DAT",
        "iso": "CL"
    },
    {
        "label": "BBA",
        "value": "BBA",
        "iso": "CL"
    },
    {
        "label": "TOQ",
        "value": "TOQ",
        "iso": "CL"
    },
    {
        "label": "CCH",
        "value": "CCH",
        "iso": "CL"
    },
    {
        "label": "CJC",
        "value": "CJC",
        "iso": "CL"
    },
    {
        "label": "YAI",
        "value": "YAI",
        "iso": "CL"
    },
    {
        "label": "PUQ",
        "value": "PUQ",
        "iso": "CL"
    },
    {
        "label": "GXQ",
        "value": "GXQ",
        "iso": "CL"
    },
    {
        "label": "IQQ",
        "value": "IQQ",
        "iso": "CL"
    },
    {
        "label": "SCL",
        "value": "SCL",
        "iso": "CL"
    },
    {
        "label": "ESR",
        "value": "ESR",
        "iso": "CL"
    },
    {
        "label": "FRT",
        "value": "FRT",
        "iso": "CL"
    },
    {
        "label": "ANF",
        "value": "ANF",
        "iso": "CL"
    },
    {
        "label": "WPR",
        "value": "WPR",
        "iso": "CL"
    },
    {
        "label": "FFU",
        "value": "FFU",
        "iso": "CL"
    },
    {
        "label": "LSQ",
        "value": "LSQ",
        "iso": "CL"
    },
    {
        "label": "WPU",
        "value": "WPU",
        "iso": "CL"
    },
    {
        "label": "CPO",
        "value": "CPO",
        "iso": "CL"
    },
    {
        "label": "LGR",
        "value": "LGR",
        "iso": "CL"
    },
    {
        "label": "CCP",
        "value": "CCP",
        "iso": "CL"
    },
    {
        "label": "IPC",
        "value": "IPC",
        "iso": "CL"
    },
    {
        "label": "ZOS",
        "value": "ZOS",
        "iso": "CL"
    },
    {
        "label": "VLR",
        "value": "VLR",
        "iso": "CL"
    },
    {
        "label": "ZLR",
        "value": "ZLR",
        "iso": "CL"
    },
    {
        "label": "PNT",
        "value": "PNT",
        "iso": "CL"
    },
    {
        "label": "OVL",
        "value": "OVL",
        "iso": "CL"
    },
    {
        "label": "ZPC",
        "value": "ZPC",
        "iso": "CL"
    },
    {
        "label": "PUX",
        "value": "PUX",
        "iso": "CL"
    },
    {
        "label": "CNR",
        "value": "CNR",
        "iso": "CL"
    },
    {
        "label": "VAP",
        "value": "VAP",
        "iso": "CL"
    },
    {
        "label": "QRC",
        "value": "QRC",
        "iso": "CL"
    },
    {
        "label": "SMB",
        "value": "SMB",
        "iso": "CL"
    },
    {
        "label": "LSC",
        "value": "LSC",
        "iso": "CL"
    },
    {
        "label": "SSD",
        "value": "SSD",
        "iso": "CL"
    },
    {
        "label": "WCA",
        "value": "WCA",
        "iso": "CL"
    },
    {
        "label": "ZCO",
        "value": "ZCO",
        "iso": "CL"
    },
    {
        "label": "PMC",
        "value": "PMC",
        "iso": "CL"
    },
    {
        "label": "ULC",
        "value": "ULC",
        "iso": "CL"
    },
    {
        "label": "TLX",
        "value": "TLX",
        "iso": "CL"
    },
    {
        "label": "WCH",
        "value": "WCH",
        "iso": "CL"
    },
    {
        "label": "ZIC",
        "value": "ZIC",
        "iso": "CL"
    },
    {
        "label": "TTC",
        "value": "TTC",
        "iso": "CL"
    },
    {
        "label": "ZAL",
        "value": "ZAL",
        "iso": "CL"
    },
    {
        "label": "KNA",
        "value": "KNA",
        "iso": "CL"
    },
    {
        "label": "CPQ",
        "value": "CPQ",
        "iso": "BR"
    },
    {
        "label": "QCJ",
        "value": "QCJ",
        "iso": "BR"
    },
    {
        "label": "OLC",
        "value": "OLC",
        "iso": "BR"
    },
    {
        "label": "SOD",
        "value": "SOD",
        "iso": "BR"
    },
    {
        "label": "QDC",
        "value": "QDC",
        "iso": "BR"
    },
    {
        "label": "JLS",
        "value": "JLS",
        "iso": "BR"
    },
    {
        "label": "QOA",
        "value": "QOA",
        "iso": "BR"
    },
    {
        "label": "QGC",
        "value": "QGC",
        "iso": "BR"
    },
    {
        "label": "QNV",
        "value": "QNV",
        "iso": "BR"
    },
    {
        "label": "OUS",
        "value": "OUS",
        "iso": "BR"
    },
    {
        "label": "QHB",
        "value": "QHB",
        "iso": "BR"
    },
    {
        "label": "QIQ",
        "value": "QIQ",
        "iso": "BR"
    },
    {
        "label": "QVP",
        "value": "QVP",
        "iso": "BR"
    },
    {
        "label": "QRZ",
        "value": "QRZ",
        "iso": "BR"
    },
    {
        "label": "QSC",
        "value": "QSC",
        "iso": "BR"
    },
    {
        "label": "UBT",
        "value": "UBT",
        "iso": "BR"
    },
    {
        "label": "QGS",
        "value": "QGS",
        "iso": "BR"
    },
    {
        "label": "VOT",
        "value": "VOT",
        "iso": "BR"
    },
    {
        "label": "QGB",
        "value": "QGB",
        "iso": "BR"
    },
    {
        "label": "IZA",
        "value": "IZA",
        "iso": "BR"
    },
    {
        "label": "ATF",
        "value": "ATF",
        "iso": "EC"
    },
    {
        "label": "OCC",
        "value": "OCC",
        "iso": "EC"
    },
    {
        "label": "CUE",
        "value": "CUE",
        "iso": "EC"
    },
    {
        "label": "GPS",
        "value": "GPS",
        "iso": "EC"
    },
    {
        "label": "GYE",
        "value": "GYE",
        "iso": "EC"
    },
    {
        "label": "IBB",
        "value": "IBB",
        "iso": "EC"
    },
    {
        "label": "JIP",
        "value": "JIP",
        "iso": "EC"
    },
    {
        "label": "LTX",
        "value": "LTX",
        "iso": "EC"
    },
    {
        "label": "MRR",
        "value": "MRR",
        "iso": "EC"
    },
    {
        "label": "XMS",
        "value": "XMS",
        "iso": "EC"
    },
    {
        "label": "MCH",
        "value": "MCH",
        "iso": "EC"
    },
    {
        "label": "MEC",
        "value": "MEC",
        "iso": "EC"
    },
    {
        "label": "LGQ",
        "value": "LGQ",
        "iso": "EC"
    },
    {
        "label": "PYO",
        "value": "PYO",
        "iso": "EC"
    },
    {
        "label": "PVO",
        "value": "PVO",
        "iso": "EC"
    },
    {
        "label": "UIO",
        "value": "UIO",
        "iso": "EC"
    },
    {
        "label": "ETR",
        "value": "ETR",
        "iso": "EC"
    },
    {
        "label": "SNC",
        "value": "SNC",
        "iso": "EC"
    },
    {
        "label": "SUQ",
        "value": "SUQ",
        "iso": "EC"
    },
    {
        "label": "PTZ",
        "value": "PTZ",
        "iso": "EC"
    },
    {
        "label": "SCY",
        "value": "SCY",
        "iso": "EC"
    },
    {
        "label": "BHA",
        "value": "BHA",
        "iso": "EC"
    },
    {
        "label": "TSC",
        "value": "TSC",
        "iso": "EC"
    },
    {
        "label": "TPN",
        "value": "TPN",
        "iso": "EC"
    },
    {
        "label": "LOH",
        "value": "LOH",
        "iso": "EC"
    },
    {
        "label": "ESM",
        "value": "ESM",
        "iso": "EC"
    },
    {
        "label": "TPC",
        "value": "TPC",
        "iso": "EC"
    },
    {
        "label": "TUA",
        "value": "TUA",
        "iso": "EC"
    },
    {
        "label": "PSY",
        "value": "PSY",
        "iso": "FK"
    },
    {
        "label": "ASU",
        "value": "ASU",
        "iso": "PY"
    },
    {
        "label": "AYO",
        "value": "AYO",
        "iso": "PY"
    },
    {
        "label": "CIO",
        "value": "CIO",
        "iso": "PY"
    },
    {
        "label": "ENO",
        "value": "ENO",
        "iso": "PY"
    },
    {
        "label": "AGT",
        "value": "AGT",
        "iso": "PY"
    },
    {
        "label": "FLM",
        "value": "FLM",
        "iso": "PY"
    },
    {
        "label": "ESG",
        "value": "ESG",
        "iso": "PY"
    },
    {
        "label": "PIL",
        "value": "PIL",
        "iso": "PY"
    },
    {
        "label": "PJC",
        "value": "PJC",
        "iso": "PY"
    },
    {
        "label": "LVR",
        "value": "LVR",
        "iso": "BR"
    },
    {
        "label": "FRC",
        "value": "FRC",
        "iso": "BR"
    },
    {
        "label": "CFO",
        "value": "CFO",
        "iso": "BR"
    },
    {
        "label": "JTC",
        "value": "JTC",
        "iso": "BR"
    },
    {
        "label": "ARS",
        "value": "ARS",
        "iso": "BR"
    },
    {
        "label": "ACM",
        "value": "ACM",
        "iso": "CO"
    },
    {
        "label": "ACL",
        "value": "ACL",
        "iso": "CO"
    },
    {
        "label": "NBB",
        "value": "NBB",
        "iso": "CO"
    },
    {
        "label": "AZT",
        "value": "AZT",
        "iso": "CO"
    },
    {
        "label": "SQB",
        "value": "SQB",
        "iso": "CO"
    },
    {
        "label": "ARF",
        "value": "ARF",
        "iso": "CO"
    },
    {
        "label": "ACR",
        "value": "ACR",
        "iso": "CO"
    },
    {
        "label": "ACD",
        "value": "ACD",
        "iso": "CO"
    },
    {
        "label": "AFI",
        "value": "AFI",
        "iso": "CO"
    },
    {
        "label": "ADN",
        "value": "ADN",
        "iso": "CO"
    },
    {
        "label": "APY",
        "value": "APY",
        "iso": "CO"
    },
    {
        "label": "AXM",
        "value": "AXM",
        "iso": "CO"
    },
    {
        "label": "PUU",
        "value": "PUU",
        "iso": "CO"
    },
    {
        "label": "ELB",
        "value": "ELB",
        "iso": "CO"
    },
    {
        "label": "BGA",
        "value": "BGA",
        "iso": "CO"
    },
    {
        "label": "BOG",
        "value": "BOG",
        "iso": "CO"
    },
    {
        "label": "BAQ",
        "value": "BAQ",
        "iso": "CO"
    },
    {
        "label": "BSC",
        "value": "BSC",
        "iso": "CO"
    },
    {
        "label": "BUN",
        "value": "BUN",
        "iso": "CO"
    },
    {
        "label": "CPB",
        "value": "CPB",
        "iso": "CO"
    },
    {
        "label": "CUC",
        "value": "CUC",
        "iso": "CO"
    },
    {
        "label": "COG",
        "value": "COG",
        "iso": "CO"
    },
    {
        "label": "CTG",
        "value": "CTG",
        "iso": "CO"
    },
    {
        "label": "CCO",
        "value": "CCO",
        "iso": "CO"
    },
    {
        "label": "CLO",
        "value": "CLO",
        "iso": "CO"
    },
    {
        "label": "CIM",
        "value": "CIM",
        "iso": "CO"
    },
    {
        "label": "RAV",
        "value": "RAV",
        "iso": "CO"
    },
    {
        "label": "TCO",
        "value": "TCO",
        "iso": "CO"
    },
    {
        "label": "CUO",
        "value": "CUO",
        "iso": "CO"
    },
    {
        "label": "CAQ",
        "value": "CAQ",
        "iso": "CO"
    },
    {
        "label": "CVE",
        "value": "CVE",
        "iso": "CO"
    },
    {
        "label": "CZU",
        "value": "CZU",
        "iso": "CO"
    },
    {
        "label": "EBG",
        "value": "EBG",
        "iso": "CO"
    },
    {
        "label": "EJA",
        "value": "EJA",
        "iso": "CO"
    },
    {
        "label": "FLA",
        "value": "FLA",
        "iso": "CO"
    },
    {
        "label": "FDA",
        "value": "FDA",
        "iso": "CO"
    },
    {
        "label": "GIR",
        "value": "GIR",
        "iso": "CO"
    },
    {
        "label": "CRC",
        "value": "CRC",
        "iso": "CO"
    },
    {
        "label": "GPI",
        "value": "GPI",
        "iso": "CO"
    },
    {
        "label": "GLJ",
        "value": "GLJ",
        "iso": "CO"
    },
    {
        "label": "CPL",
        "value": "CPL",
        "iso": "CO"
    },
    {
        "label": "HTZ",
        "value": "HTZ",
        "iso": "CO"
    },
    {
        "label": "IBE",
        "value": "IBE",
        "iso": "CO"
    },
    {
        "label": "IGO",
        "value": "IGO",
        "iso": "CO"
    },
    {
        "label": "MMP",
        "value": "MMP",
        "iso": "CO"
    },
    {
        "label": "IPI",
        "value": "IPI",
        "iso": "CO"
    },
    {
        "label": "LQM",
        "value": "LQM",
        "iso": "CO"
    },
    {
        "label": "MCJ",
        "value": "MCJ",
        "iso": "CO"
    },
    {
        "label": "LPD",
        "value": "LPD",
        "iso": "CO"
    },
    {
        "label": "LET",
        "value": "LET",
        "iso": "CO"
    },
    {
        "label": "EOH",
        "value": "EOH",
        "iso": "CO"
    },
    {
        "label": "MFS",
        "value": "MFS",
        "iso": "CO"
    },
    {
        "label": "MGN",
        "value": "MGN",
        "iso": "CO"
    },
    {
        "label": "MCJ",
        "value": "MCJ",
        "iso": "CO"
    },
    {
        "label": "MTB",
        "value": "MTB",
        "iso": "CO"
    },
    {
        "label": "MTR",
        "value": "MTR",
        "iso": "CO"
    },
    {
        "label": "MVP",
        "value": "MVP",
        "iso": "CO"
    },
    {
        "label": "MZL",
        "value": "MZL",
        "iso": "CO"
    },
    {
        "label": "NCI",
        "value": "NCI",
        "iso": "CO"
    },
    {
        "label": "NQU",
        "value": "NQU",
        "iso": "CO"
    },
    {
        "label": "NVA",
        "value": "NVA",
        "iso": "CO"
    },
    {
        "label": "OCV",
        "value": "OCV",
        "iso": "CO"
    },
    {
        "label": "ORC",
        "value": "ORC",
        "iso": "CO"
    },
    {
        "label": "PCR",
        "value": "PCR",
        "iso": "CO"
    },
    {
        "label": "PDA",
        "value": "PDA",
        "iso": "CO"
    },
    {
        "label": "PEI",
        "value": "PEI",
        "iso": "CO"
    },
    {
        "label": "PTX",
        "value": "PTX",
        "iso": "CO"
    },
    {
        "label": "PLT",
        "value": "PLT",
        "iso": "CO"
    },
    {
        "label": "NAR",
        "value": "NAR",
        "iso": "CO"
    },
    {
        "label": "PPN",
        "value": "PPN",
        "iso": "CO"
    },
    {
        "label": "PQE",
        "value": "PQE",
        "iso": "CO"
    },
    {
        "label": "PBE",
        "value": "PBE",
        "iso": "CO"
    },
    {
        "label": "PSO",
        "value": "PSO",
        "iso": "CO"
    },
    {
        "label": "PVA",
        "value": "PVA",
        "iso": "CO"
    },
    {
        "label": "PZA",
        "value": "PZA",
        "iso": "CO"
    },
    {
        "label": "MQU",
        "value": "MQU",
        "iso": "CO"
    },
    {
        "label": "MDE",
        "value": "MDE",
        "iso": "CO"
    },
    {
        "label": "RCH",
        "value": "RCH",
        "iso": "CO"
    },
    {
        "label": "SJE",
        "value": "SJE",
        "iso": "CO"
    },
    {
        "label": "SMR",
        "value": "SMR",
        "iso": "CO"
    },
    {
        "label": "SOX",
        "value": "SOX",
        "iso": "CO"
    },
    {
        "label": "ADZ",
        "value": "ADZ",
        "iso": "CO"
    },
    {
        "label": "SVI",
        "value": "SVI",
        "iso": "CO"
    },
    {
        "label": "TBU",
        "value": "TBU",
        "iso": "CO"
    },
    {
        "label": "TDA",
        "value": "TDA",
        "iso": "CO"
    },
    {
        "label": "TLU",
        "value": "TLU",
        "iso": "CO"
    },
    {
        "label": "TME",
        "value": "TME",
        "iso": "CO"
    },
    {
        "label": "TQS",
        "value": "TQS",
        "iso": "CO"
    },
    {
        "label": "TRB",
        "value": "TRB",
        "iso": "CO"
    },
    {
        "label": "MQZ",
        "value": "MQZ",
        "iso": "CO"
    },
    {
        "label": "AUC",
        "value": "AUC",
        "iso": "CO"
    },
    {
        "label": "UIB",
        "value": "UIB",
        "iso": "CO"
    },
    {
        "label": "ULQ",
        "value": "ULQ",
        "iso": "CO"
    },
    {
        "label": "URR",
        "value": "URR",
        "iso": "CO"
    },
    {
        "label": "VUP",
        "value": "VUP",
        "iso": "CO"
    },
    {
        "label": "VVC",
        "value": "VVC",
        "iso": "CO"
    },
    {
        "label": "AYG",
        "value": "AYG",
        "iso": "CO"
    },
    {
        "label": "EYP",
        "value": "EYP",
        "iso": "CO"
    },
    {
        "label": "MHW",
        "value": "MHW",
        "iso": "BO"
    },
    {
        "label": "APB",
        "value": "APB",
        "iso": "BO"
    },
    {
        "label": "ASC",
        "value": "ASC",
        "iso": "BO"
    },
    {
        "label": "BJO",
        "value": "BJO",
        "iso": "BO"
    },
    {
        "label": "CAM",
        "value": "CAM",
        "iso": "BO"
    },
    {
        "label": "CBB",
        "value": "CBB",
        "iso": "BO"
    },
    {
        "label": "CIJ",
        "value": "CIJ",
        "iso": "BO"
    },
    {
        "label": "CEP",
        "value": "CEP",
        "iso": "BO"
    },
    {
        "label": "SRZ",
        "value": "SRZ",
        "iso": "BO"
    },
    {
        "label": "GYA",
        "value": "GYA",
        "iso": "BO"
    },
    {
        "label": "BVK",
        "value": "BVK",
        "iso": "BO"
    },
    {
        "label": "SJS",
        "value": "SJS",
        "iso": "BO"
    },
    {
        "label": "SJB",
        "value": "SJB",
        "iso": "BO"
    },
    {
        "label": "SJV",
        "value": "SJV",
        "iso": "BO"
    },
    {
        "label": "LPB",
        "value": "LPB",
        "iso": "BO"
    },
    {
        "label": "MGD",
        "value": "MGD",
        "iso": "BO"
    },
    {
        "label": "ORU",
        "value": "ORU",
        "iso": "BO"
    },
    {
        "label": "POI",
        "value": "POI",
        "iso": "BO"
    },
    {
        "label": "PUR",
        "value": "PUR",
        "iso": "BO"
    },
    {
        "label": "PSZ",
        "value": "PSZ",
        "iso": "BO"
    },
    {
        "label": "SRD",
        "value": "SRD",
        "iso": "BO"
    },
    {
        "label": "RBO",
        "value": "RBO",
        "iso": "BO"
    },
    {
        "label": "RIB",
        "value": "RIB",
        "iso": "BO"
    },
    {
        "label": "REY",
        "value": "REY",
        "iso": "BO"
    },
    {
        "label": "SBL",
        "value": "SBL",
        "iso": "BO"
    },
    {
        "label": "SRJ",
        "value": "SRJ",
        "iso": "BO"
    },
    {
        "label": "SNG",
        "value": "SNG",
        "iso": "BO"
    },
    {
        "label": "SNM",
        "value": "SNM",
        "iso": "BO"
    },
    {
        "label": "SRB",
        "value": "SRB",
        "iso": "BO"
    },
    {
        "label": "SRE",
        "value": "SRE",
        "iso": "BO"
    },
    {
        "label": "MQK",
        "value": "MQK",
        "iso": "BO"
    },
    {
        "label": "TJA",
        "value": "TJA",
        "iso": "BO"
    },
    {
        "label": "TDD",
        "value": "TDD",
        "iso": "BO"
    },
    {
        "label": "UYU",
        "value": "UYU",
        "iso": "BO"
    },
    {
        "label": "VAH",
        "value": "VAH",
        "iso": "BO"
    },
    {
        "label": "VLM",
        "value": "VLM",
        "iso": "BO"
    },
    {
        "label": "VVI",
        "value": "VVI",
        "iso": "BO"
    },
    {
        "label": "BYC",
        "value": "BYC",
        "iso": "BO"
    },
    {
        "label": "ABN",
        "value": "ABN",
        "iso": "SR"
    },
    {
        "label": "TOT",
        "value": "TOT",
        "iso": "SR"
    },
    {
        "label": "DRJ",
        "value": "DRJ",
        "iso": "SR"
    },
    {
        "label": "PBM",
        "value": "PBM",
        "iso": "SR"
    },
    {
        "label": "ICK",
        "value": "ICK",
        "iso": "SR"
    },
    {
        "label": "OEM",
        "value": "OEM",
        "iso": "SR"
    },
    {
        "label": "SMZ",
        "value": "SMZ",
        "iso": "SR"
    },
    {
        "label": "AGI",
        "value": "AGI",
        "iso": "SR"
    },
    {
        "label": "ORG",
        "value": "ORG",
        "iso": "SR"
    },
    {
        "label": "APY",
        "value": "APY",
        "iso": "BR"
    },
    {
        "label": "APQ",
        "value": "APQ",
        "iso": "BR"
    },
    {
        "label": "AMJ",
        "value": "AMJ",
        "iso": "BR"
    },
    {
        "label": "AIF",
        "value": "AIF",
        "iso": "BR"
    },
    {
        "label": "BDC",
        "value": "BDC",
        "iso": "BR"
    },
    {
        "label": "BVM",
        "value": "BVM",
        "iso": "BR"
    },
    {
        "label": "BRA",
        "value": "BRA",
        "iso": "BR"
    },
    {
        "label": "BSS",
        "value": "BSS",
        "iso": "BR"
    },
    {
        "label": "BMS",
        "value": "BMS",
        "iso": "BR"
    },
    {
        "label": "BQQ",
        "value": "BQQ",
        "iso": "BR"
    },
    {
        "label": "CTP",
        "value": "CTP",
        "iso": "BR"
    },
    {
        "label": "CPU",
        "value": "CPU",
        "iso": "BR"
    },
    {
        "label": "QCH",
        "value": "QCH",
        "iso": "BR"
    },
    {
        "label": "RDC",
        "value": "RDC",
        "iso": "BR"
    },
    {
        "label": "LEP",
        "value": "LEP",
        "iso": "BR"
    },
    {
        "label": "DIQ",
        "value": "DIQ",
        "iso": "BR"
    },
    {
        "label": "CNV",
        "value": "CNV",
        "iso": "BR"
    },
    {
        "label": "SXX",
        "value": "SXX",
        "iso": "BR"
    },
    {
        "label": "ODL",
        "value": "ODL",
        "iso": "BR"
    },
    {
        "label": "GDP",
        "value": "GDP",
        "iso": "BR"
    },
    {
        "label": "GNM",
        "value": "GNM",
        "iso": "BR"
    },
    {
        "label": "GMS",
        "value": "GMS",
        "iso": "BR"
    },
    {
        "label": "QGP",
        "value": "QGP",
        "iso": "BR"
    },
    {
        "label": "ITN",
        "value": "ITN",
        "iso": "BR"
    },
    {
        "label": "IRE",
        "value": "IRE",
        "iso": "BR"
    },
    {
        "label": "QIG",
        "value": "QIG",
        "iso": "BR"
    },
    {
        "label": "QIT",
        "value": "QIT",
        "iso": "BR"
    },
    {
        "label": "IPU",
        "value": "IPU",
        "iso": "BR"
    },
    {
        "label": "JCM",
        "value": "JCM",
        "iso": "BR"
    },
    {
        "label": "FEC",
        "value": "FEC",
        "iso": "BR"
    },
    {
        "label": "JEQ",
        "value": "JEQ",
        "iso": "BR"
    },
    {
        "label": "JNA",
        "value": "JNA",
        "iso": "BR"
    },
    {
        "label": "JDR",
        "value": "JDR",
        "iso": "BR"
    },
    {
        "label": "CMP",
        "value": "CMP",
        "iso": "BR"
    },
    {
        "label": "QDF",
        "value": "QDF",
        "iso": "BR"
    },
    {
        "label": "QXD",
        "value": "QXD",
        "iso": "BR"
    },
    {
        "label": "QCP",
        "value": "QCP",
        "iso": "BR"
    },
    {
        "label": "LVB",
        "value": "LVB",
        "iso": "BR"
    },
    {
        "label": "SSO",
        "value": "SSO",
        "iso": "BR"
    },
    {
        "label": "MTE",
        "value": "MTE",
        "iso": "BR"
    },
    {
        "label": "MVS",
        "value": "MVS",
        "iso": "BR"
    },
    {
        "label": "SBJ",
        "value": "SBJ",
        "iso": "BR"
    },
    {
        "label": "PTQ",
        "value": "PTQ",
        "iso": "BR"
    },
    {
        "label": "NNU",
        "value": "NNU",
        "iso": "BR"
    },
    {
        "label": "QBX",
        "value": "QBX",
        "iso": "BR"
    },
    {
        "label": "PSW",
        "value": "PSW",
        "iso": "BR"
    },
    {
        "label": "ORX",
        "value": "ORX",
        "iso": "BR"
    },
    {
        "label": "PCS",
        "value": "PCS",
        "iso": "BR"
    },
    {
        "label": "POJ",
        "value": "POJ",
        "iso": "BR"
    },
    {
        "label": "PIV",
        "value": "PIV",
        "iso": "BR"
    },
    {
        "label": "FLB",
        "value": "FLB",
        "iso": "BR"
    },
    {
        "label": "JDO",
        "value": "JDO",
        "iso": "BR"
    },
    {
        "label": "PIV",
        "value": "PIV",
        "iso": "BR"
    },
    {
        "label": "PDF",
        "value": "PDF",
        "iso": "BR"
    },
    {
        "label": "CAU",
        "value": "CAU",
        "iso": "BR"
    },
    {
        "label": "SFK",
        "value": "SFK",
        "iso": "BR"
    },
    {
        "label": "OBI",
        "value": "OBI",
        "iso": "BR"
    },
    {
        "label": "TFL",
        "value": "TFL",
        "iso": "BR"
    },
    {
        "label": "VAL",
        "value": "VAL",
        "iso": "BR"
    },
    {
        "label": "QID",
        "value": "QID",
        "iso": "BR"
    },
    {
        "label": "BVS",
        "value": "BVS",
        "iso": "BR"
    },
    {
        "label": "CMC",
        "value": "CMC",
        "iso": "BR"
    },
    {
        "label": "QXC",
        "value": "QXC",
        "iso": "BR"
    },
    {
        "label": "PHI",
        "value": "PHI",
        "iso": "BR"
    },
    {
        "label": "ITI",
        "value": "ITI",
        "iso": "BR"
    },
    {
        "label": "PPY",
        "value": "PPY",
        "iso": "BR"
    },
    {
        "label": "BXX",
        "value": "BXX",
        "iso": "SO"
    },
    {
        "label": "GTA",
        "value": "GTA",
        "iso": "SB"
    },
    {
        "label": "CAY",
        "value": "CAY",
        "iso": "GF"
    },
    {
        "label": "MPY",
        "value": "MPY",
        "iso": "GF"
    },
    {
        "label": "OXP",
        "value": "OXP",
        "iso": "GF"
    },
    {
        "label": "LDX",
        "value": "LDX",
        "iso": "GF"
    },
    {
        "label": "REI",
        "value": "REI",
        "iso": "GF"
    },
    {
        "label": "XAU",
        "value": "XAU",
        "iso": "GF"
    },
    {
        "label": "APE",
        "value": "APE",
        "iso": "PE"
    },
    {
        "label": "ALD",
        "value": "ALD",
        "iso": "PE"
    },
    {
        "label": "AOP",
        "value": "AOP",
        "iso": "PE"
    },
    {
        "label": "ATG",
        "value": "ATG",
        "iso": "PE"
    },
    {
        "label": "MBP",
        "value": "MBP",
        "iso": "PE"
    },
    {
        "label": "LHC",
        "value": "LHC",
        "iso": "PE"
    },
    {
        "label": "BLP",
        "value": "BLP",
        "iso": "PE"
    },
    {
        "label": "IBP",
        "value": "IBP",
        "iso": "PE"
    },
    {
        "label": "TCG",
        "value": "TCG",
        "iso": "PE"
    },
    {
        "label": "PCL",
        "value": "PCL",
        "iso": "PE"
    },
    {
        "label": "CTF",
        "value": "CTF",
        "iso": "PE"
    },
    {
        "label": "CHM",
        "value": "CHM",
        "iso": "PE"
    },
    {
        "label": "TGI",
        "value": "TGI",
        "iso": "PE"
    },
    {
        "label": "CIX",
        "value": "CIX",
        "iso": "PE"
    },
    {
        "label": "AYP",
        "value": "AYP",
        "iso": "PE"
    },
    {
        "label": "ANS",
        "value": "ANS",
        "iso": "PE"
    },
    {
        "label": "ATA",
        "value": "ATA",
        "iso": "PE"
    },
    {
        "label": "UMI",
        "value": "UMI",
        "iso": "PE"
    },
    {
        "label": "LIM",
        "value": "LIM",
        "iso": "PE"
    },
    {
        "label": "SFK",
        "value": "SFK",
        "iso": "PE"
    },
    {
        "label": "UCZ",
        "value": "UCZ",
        "iso": "PE"
    },
    {
        "label": "RIJ",
        "value": "RIJ",
        "iso": "PE"
    },
    {
        "label": "JJI",
        "value": "JJI",
        "iso": "PE"
    },
    {
        "label": "JAU",
        "value": "JAU",
        "iso": "PE"
    },
    {
        "label": "JUL",
        "value": "JUL",
        "iso": "PE"
    },
    {
        "label": "SJA",
        "value": "SJA",
        "iso": "PE"
    },
    {
        "label": "CJA",
        "value": "CJA",
        "iso": "PE"
    },
    {
        "label": "RIM",
        "value": "RIM",
        "iso": "PE"
    },
    {
        "label": "ILQ",
        "value": "ILQ",
        "iso": "PE"
    },
    {
        "label": "LIM",
        "value": "LIM",
        "iso": "PE"
    },
    {
        "label": "TBP",
        "value": "TBP",
        "iso": "PE"
    },
    {
        "label": "CHM",
        "value": "CHM",
        "iso": "PE"
    },
    {
        "label": "SMG",
        "value": "SMG",
        "iso": "PE"
    },
    {
        "label": "YMS",
        "value": "YMS",
        "iso": "PE"
    },
    {
        "label": "HUU",
        "value": "HUU",
        "iso": "PE"
    },
    {
        "label": "SQU",
        "value": "SQU",
        "iso": "PE"
    },
    {
        "label": "SYC",
        "value": "SYC",
        "iso": "PE"
    },
    {
        "label": "CHH",
        "value": "CHH",
        "iso": "PE"
    },
    {
        "label": "REQ",
        "value": "REQ",
        "iso": "PE"
    },
    {
        "label": "IQT",
        "value": "IQT",
        "iso": "PE"
    },
    {
        "label": "AQP",
        "value": "AQP",
        "iso": "PE"
    },
    {
        "label": "TRU",
        "value": "TRU",
        "iso": "PE"
    },
    {
        "label": "SQD",
        "value": "SQD",
        "iso": "PE"
    },
    {
        "label": "PIO",
        "value": "PIO",
        "iso": "PE"
    },
    {
        "label": "TPP",
        "value": "TPP",
        "iso": "PE"
    },
    {
        "label": "SYC",
        "value": "SYC",
        "iso": "PE"
    },
    {
        "label": "TCQ",
        "value": "TCQ",
        "iso": "PE"
    },
    {
        "label": "PEM",
        "value": "PEM",
        "iso": "PE"
    },
    {
        "label": "PIU",
        "value": "PIU",
        "iso": "PE"
    },
    {
        "label": "TYL",
        "value": "TYL",
        "iso": "PE"
    },
    {
        "label": "NZA",
        "value": "NZA",
        "iso": "PE"
    },
    {
        "label": "CUZ",
        "value": "CUZ",
        "iso": "PE"
    },
    {
        "label": "AAJ",
        "value": "AAJ",
        "iso": "SR"
    },
    {
        "label": "KCB",
        "value": "KCB",
        "iso": "SR"
    },
    {
        "label": "APU",
        "value": "APU",
        "iso": "BR"
    },
    {
        "label": "BNU",
        "value": "BNU",
        "iso": "BR"
    },
    {
        "label": "CCI",
        "value": "CCI",
        "iso": "BR"
    },
    {
        "label": "QCN",
        "value": "QCN",
        "iso": "BR"
    },
    {
        "label": "CKO",
        "value": "CKO",
        "iso": "BR"
    },
    {
        "label": "DOU",
        "value": "DOU",
        "iso": "BR"
    },
    {
        "label": "ERM",
        "value": "ERM",
        "iso": "BR"
    },
    {
        "label": "FBE",
        "value": "FBE",
        "iso": "BR"
    },
    {
        "label": "QGA",
        "value": "QGA",
        "iso": "BR"
    },
    {
        "label": "IJU",
        "value": "IJU",
        "iso": "BR"
    },
    {
        "label": "ITQ",
        "value": "ITQ",
        "iso": "BR"
    },
    {
        "label": "JCB",
        "value": "JCB",
        "iso": "BR"
    },
    {
        "label": "QDB",
        "value": "QDB",
        "iso": "BR"
    },
    {
        "label": "QCR",
        "value": "QCR",
        "iso": "BR"
    },
    {
        "label": "QRE",
        "value": "QRE",
        "iso": "BR"
    },
    {
        "label": "ALQ",
        "value": "ALQ",
        "iso": "BR"
    },
    {
        "label": "QMF",
        "value": "QMF",
        "iso": "BR"
    },
    {
        "label": "QGF",
        "value": "QGF",
        "iso": "BR"
    },
    {
        "label": "QHV",
        "value": "QHV",
        "iso": "BR"
    },
    {
        "label": "SQX",
        "value": "SQX",
        "iso": "BR"
    },
    {
        "label": "APX",
        "value": "APX",
        "iso": "BR"
    },
    {
        "label": "PTO",
        "value": "PTO",
        "iso": "BR"
    },
    {
        "label": "PNG",
        "value": "PNG",
        "iso": "BR"
    },
    {
        "label": "PVI",
        "value": "PVI",
        "iso": "BR"
    },
    {
        "label": "PBB",
        "value": "PBB",
        "iso": "BR"
    },
    {
        "label": "QAC",
        "value": "QAC",
        "iso": "BR"
    },
    {
        "label": "SQY",
        "value": "SQY",
        "iso": "BR"
    },
    {
        "label": "QOJ",
        "value": "QOJ",
        "iso": "BR"
    },
    {
        "label": "CSU",
        "value": "CSU",
        "iso": "BR"
    },
    {
        "label": "UMU",
        "value": "UMU",
        "iso": "BR"
    },
    {
        "label": "QVB",
        "value": "QVB",
        "iso": "BR"
    },
    {
        "label": "VIA",
        "value": "VIA",
        "iso": "BR"
    },
    {
        "label": "CTQ",
        "value": "CTQ",
        "iso": "BR"
    },
    {
        "label": "AXE",
        "value": "AXE",
        "iso": "BR"
    },
    {
        "label": "AAG",
        "value": "AAG",
        "iso": "BR"
    },
    {
        "label": "SRA",
        "value": "SRA",
        "iso": "BR"
    },
    {
        "label": "PGZ",
        "value": "PGZ",
        "iso": "BR"
    },
    {
        "label": "ATI",
        "value": "ATI",
        "iso": "UY"
    },
    {
        "label": "BUV",
        "value": "BUV",
        "iso": "UY"
    },
    {
        "label": "CYR",
        "value": "CYR",
        "iso": "UY"
    },
    {
        "label": "CAR",
        "value": "CAR",
        "iso": "UY"
    },
    {
        "label": "DZO",
        "value": "DZO",
        "iso": "UY"
    },
    {
        "label": "PDP",
        "value": "PDP",
        "iso": "UY"
    },
    {
        "label": "MER",
        "value": "MER",
        "iso": "UY"
    },
    {
        "label": "MLZ",
        "value": "MLZ",
        "iso": "UY"
    },
    {
        "label": "MVD",
        "value": "MVD",
        "iso": "UY"
    },
    {
        "label": "MDO",
        "value": "MDO",
        "iso": "UY"
    },
    {
        "label": "PDU",
        "value": "PDU",
        "iso": "UY"
    },
    {
        "label": "RVY",
        "value": "RVY",
        "iso": "UY"
    },
    {
        "label": "STY",
        "value": "STY",
        "iso": "UY"
    },
    {
        "label": "TAW",
        "value": "TAW",
        "iso": "UY"
    },
    {
        "label": "TYT",
        "value": "TYT",
        "iso": "UY"
    },
    {
        "label": "VCH",
        "value": "VCH",
        "iso": "UY"
    },
    {
        "label": "AGV",
        "value": "AGV",
        "iso": "VE"
    },
    {
        "label": "AAO",
        "value": "AAO",
        "iso": "VE"
    },
    {
        "label": "LPJ",
        "value": "LPJ",
        "iso": "VE"
    },
    {
        "label": "BLA",
        "value": "BLA",
        "iso": "VE"
    },
    {
        "label": "BNS",
        "value": "BNS",
        "iso": "VE"
    },
    {
        "label": "ELR",
        "value": "ELR",
        "iso": "VE"
    },
    {
        "label": "BRM",
        "value": "BRM",
        "iso": "VE"
    },
    {
        "label": "MYC",
        "value": "MYC",
        "iso": "VE"
    },
    {
        "label": "CBL",
        "value": "CBL",
        "iso": "VE"
    },
    {
        "label": "CXA",
        "value": "CXA",
        "iso": "VE"
    },
    {
        "label": "CUV",
        "value": "CUV",
        "iso": "VE"
    },
    {
        "label": "CLZ",
        "value": "CLZ",
        "iso": "VE"
    },
    {
        "label": "CAJ",
        "value": "CAJ",
        "iso": "VE"
    },
    {
        "label": "VCR",
        "value": "VCR",
        "iso": "VE"
    },
    {
        "label": "CUP",
        "value": "CUP",
        "iso": "VE"
    },
    {
        "label": "CZE",
        "value": "CZE",
        "iso": "VE"
    },
    {
        "label": "CUM",
        "value": "CUM",
        "iso": "VE"
    },
    {
        "label": "isl",
        "value": "isl",
        "iso": "VE"
    },
    {
        "label": "EOR",
        "value": "EOR",
        "iso": "VE"
    },
    {
        "label": "EOZ",
        "value": "EOZ",
        "iso": "VE"
    },
    {
        "label": "GDO",
        "value": "GDO",
        "iso": "VE"
    },
    {
        "label": "GUI",
        "value": "GUI",
        "iso": "VE"
    },
    {
        "label": "GUQ",
        "value": "GUQ",
        "iso": "VE"
    },
    {
        "label": "ICA",
        "value": "ICA",
        "iso": "VE"
    },
    {
        "label": "LSP",
        "value": "LSP",
        "iso": "VE"
    },
    {
        "label": "KAV",
        "value": "KAV",
        "iso": "VE"
    },
    {
        "label": "LFR",
        "value": "LFR",
        "iso": "VE"
    },
    {
        "label": "MAR",
        "value": "MAR",
        "iso": "VE"
    },
    {
        "label": "MRD",
        "value": "MRD",
        "iso": "VE"
    },
    {
        "label": "PMV",
        "value": "PMV",
        "iso": "VE"
    },
    {
        "label": "CCS",
        "value": "CCS",
        "iso": "VE"
    },
    {
        "label": "MUN",
        "value": "MUN",
        "iso": "VE"
    },
    {
        "label": "CBS",
        "value": "CBS",
        "iso": "VE"
    },
    {
        "label": "PYH",
        "value": "PYH",
        "iso": "VE"
    },
    {
        "label": "PBL",
        "value": "PBL",
        "iso": "VE"
    },
    {
        "label": "PDZ",
        "value": "PDZ",
        "iso": "VE"
    },
    {
        "label": "PPH",
        "value": "PPH",
        "iso": "VE"
    },
    {
        "label": "SCI",
        "value": "SCI",
        "iso": "VE"
    },
    {
        "label": "PZO",
        "value": "PZO",
        "iso": "VE"
    },
    {
        "label": "PTM",
        "value": "PTM",
        "iso": "VE"
    },
    {
        "label": "LRV",
        "value": "LRV",
        "iso": "VE"
    },
    {
        "label": "SVZ",
        "value": "SVZ",
        "iso": "VE"
    },
    {
        "label": "SBB",
        "value": "SBB",
        "iso": "VE"
    },
    {
        "label": "SNV",
        "value": "SNV",
        "iso": "VE"
    },
    {
        "label": "STD",
        "value": "STD",
        "iso": "VE"
    },
    {
        "label": "SNF",
        "value": "SNF",
        "iso": "VE"
    },
    {
        "label": "SFD",
        "value": "SFD",
        "iso": "VE"
    },
    {
        "label": "SOM",
        "value": "SOM",
        "iso": "VE"
    },
    {
        "label": "STB",
        "value": "STB",
        "iso": "VE"
    },
    {
        "label": "TUV",
        "value": "TUV",
        "iso": "VE"
    },
    {
        "label": "TMO",
        "value": "TMO",
        "iso": "VE"
    },
    {
        "label": "URM",
        "value": "URM",
        "iso": "VE"
    },
    {
        "label": "VLN",
        "value": "VLN",
        "iso": "VE"
    },
    {
        "label": "VIG",
        "value": "VIG",
        "iso": "VE"
    },
    {
        "label": "VLV",
        "value": "VLV",
        "iso": "VE"
    },
    {
        "label": "VDP",
        "value": "VDP",
        "iso": "VE"
    },
    {
        "label": "BAZ",
        "value": "BAZ",
        "iso": "BR"
    },
    {
        "label": "RBB",
        "value": "RBB",
        "iso": "BR"
    },
    {
        "label": "CAF",
        "value": "CAF",
        "iso": "BR"
    },
    {
        "label": "CQS",
        "value": "CQS",
        "iso": "BR"
    },
    {
        "label": "DMT",
        "value": "DMT",
        "iso": "BR"
    },
    {
        "label": "DNO",
        "value": "DNO",
        "iso": "BR"
    },
    {
        "label": "ARS",
        "value": "ARS",
        "iso": "BR"
    },
    {
        "label": "ERN",
        "value": "ERN",
        "iso": "BR"
    },
    {
        "label": "CQA",
        "value": "CQA",
        "iso": "BR"
    },
    {
        "label": "FEJ",
        "value": "FEJ",
        "iso": "BR"
    },
    {
        "label": "SXO",
        "value": "SXO",
        "iso": "BR"
    },
    {
        "label": "GRP",
        "value": "GRP",
        "iso": "BR"
    },
    {
        "label": "AUX",
        "value": "AUX",
        "iso": "BR"
    },
    {
        "label": "IPG",
        "value": "IPG",
        "iso": "BR"
    },
    {
        "label": "IDO",
        "value": "IDO",
        "iso": "BR"
    },
    {
        "label": "JPR",
        "value": "JPR",
        "iso": "BR"
    },
    {
        "label": "JIA",
        "value": "JIA",
        "iso": "BR"
    },
    {
        "label": "JRN",
        "value": "JRN",
        "iso": "BR"
    },
    {
        "label": "CCX",
        "value": "CCX",
        "iso": "BR"
    },
    {
        "label": "CIZ",
        "value": "CIZ",
        "iso": "BR"
    },
    {
        "label": "TLZ",
        "value": "TLZ",
        "iso": "BR"
    },
    {
        "label": "LBR",
        "value": "LBR",
        "iso": "BR"
    },
    {
        "label": "RVD",
        "value": "RVD",
        "iso": "BR"
    },
    {
        "label": "MBZ",
        "value": "MBZ",
        "iso": "BR"
    },
    {
        "label": "NVP",
        "value": "NVP",
        "iso": "BR"
    },
    {
        "label": "AQM",
        "value": "AQM",
        "iso": "BR"
    },
    {
        "label": "BCR",
        "value": "BCR",
        "iso": "BR"
    },
    {
        "label": "NQL",
        "value": "NQL",
        "iso": "BR"
    },
    {
        "label": "APS",
        "value": "APS",
        "iso": "BR"
    },
    {
        "label": "PIN",
        "value": "PIN",
        "iso": "BR"
    },
    {
        "label": "PMW",
        "value": "PMW",
        "iso": "BR"
    },
    {
        "label": "PBQ",
        "value": "PBQ",
        "iso": "BR"
    },
    {
        "label": "AAI",
        "value": "AAI",
        "iso": "BR"
    },
    {
        "label": "ROO",
        "value": "ROO",
        "iso": "BR"
    },
    {
        "label": "AIR",
        "value": "AIR",
        "iso": "BR"
    },
    {
        "label": "OPS",
        "value": "OPS",
        "iso": "BR"
    },
    {
        "label": "STZ",
        "value": "STZ",
        "iso": "BR"
    },
    {
        "label": "IRZ",
        "value": "IRZ",
        "iso": "BR"
    },
    {
        "label": "AZL",
        "value": "AZL",
        "iso": "BR"
    },
    {
        "label": "QHN",
        "value": "QHN",
        "iso": "BR"
    },
    {
        "label": "SQM",
        "value": "SQM",
        "iso": "BR"
    },
    {
        "label": "VLP",
        "value": "VLP",
        "iso": "BR"
    },
    {
        "label": "MBK",
        "value": "MBK",
        "iso": "BR"
    },
    {
        "label": "NOK",
        "value": "NOK",
        "iso": "BR"
    },
    {
        "label": "AHL",
        "value": "AHL",
        "iso": "GY"
    },
    {
        "label": "NAI",
        "value": "NAI",
        "iso": "GY"
    },
    {
        "label": "BMJ",
        "value": "BMJ",
        "iso": "GY"
    },
    {
        "label": "GFO",
        "value": "GFO",
        "iso": "GY"
    },
    {
        "label": "GEO",
        "value": "GEO",
        "iso": "GY"
    },
    {
        "label": "OGL",
        "value": "OGL",
        "iso": "GY"
    },
    {
        "label": "IMB",
        "value": "IMB",
        "iso": "GY"
    },
    {
        "label": "KAR",
        "value": "KAR",
        "iso": "GY"
    },
    {
        "label": "KRM",
        "value": "KRM",
        "iso": "GY"
    },
    {
        "label": "KRG",
        "value": "KRG",
        "iso": "GY"
    },
    {
        "label": "KTO",
        "value": "KTO",
        "iso": "GY"
    },
    {
        "label": "LUB",
        "value": "LUB",
        "iso": "GY"
    },
    {
        "label": "LTM",
        "value": "LTM",
        "iso": "GY"
    },
    {
        "label": "USI",
        "value": "USI",
        "iso": "GY"
    },
    {
        "label": "MHA",
        "value": "MHA",
        "iso": "GY"
    },
    {
        "label": "MYM",
        "value": "MYM",
        "iso": "GY"
    },
    {
        "label": "QSX",
        "value": "QSX",
        "iso": "GY"
    },
    {
        "label": "ORJ",
        "value": "ORJ",
        "iso": "GY"
    },
    {
        "label": "PRR",
        "value": "PRR",
        "iso": "GY"
    },
    {
        "label": "SZN",
        "value": "SZN",
        "iso": "US"
    },
    {
        "label": "ANU",
        "value": "ANU",
        "iso": "AG"
    },
    {
        "label": "BBQ",
        "value": "BBQ",
        "iso": "AG"
    },
    {
        "label": "BGI",
        "value": "BGI",
        "iso": "BB"
    },
    {
        "label": "DCF",
        "value": "DCF",
        "iso": "DM"
    },
    {
        "label": "DOM",
        "value": "DOM",
        "iso": "DM"
    },
    {
        "label": "DSD",
        "value": "DSD",
        "iso": "GP"
    },
    {
        "label": "BBR",
        "value": "BBR",
        "iso": "GP"
    },
    {
        "label": "SFC",
        "value": "SFC",
        "iso": "GP"
    },
    {
        "label": "FDF",
        "value": "FDF",
        "iso": "MQ"
    },
    {
        "label": "SFG",
        "value": "SFG",
        "iso": "MF"
    },
    {
        "label": "SBH",
        "value": "SBH",
        "iso": "BL"
    },
    {
        "label": "GBJ",
        "value": "GBJ",
        "iso": "GP"
    },
    {
        "label": "PTP",
        "value": "PTP",
        "iso": "GP"
    },
    {
        "label": "LSS",
        "value": "LSS",
        "iso": "GP"
    },
    {
        "label": "GND",
        "value": "GND",
        "iso": "GD"
    },
    {
        "label": "CRU",
        "value": "CRU",
        "iso": "GD"
    },
    {
        "label": "STT",
        "value": "STT",
        "iso": "VI"
    },
    {
        "label": "STX",
        "value": "STX",
        "iso": "VI"
    },
    {
        "label": "ARE",
        "value": "ARE",
        "iso": "PR"
    },
    {
        "label": "BQN",
        "value": "BQN",
        "iso": "PR"
    },
    {
        "label": "VQS",
        "value": "VQS",
        "iso": "PR"
    },
    {
        "label": "CPX",
        "value": "CPX",
        "iso": "PR"
    },
    {
        "label": "FAJ",
        "value": "FAJ",
        "iso": "PR"
    },
    {
        "label": "SIG",
        "value": "SIG",
        "iso": "PR"
    },
    {
        "label": "MAZ",
        "value": "MAZ",
        "iso": "PR"
    },
    {
        "label": "PSE",
        "value": "PSE",
        "iso": "PR"
    },
    {
        "label": "SJU",
        "value": "SJU",
        "iso": "PR"
    },
    {
        "label": "SKB",
        "value": "SKB",
        "iso": "KN"
    },
    {
        "label": "NEV",
        "value": "NEV",
        "iso": "KN"
    },
    {
        "label": "SLU",
        "value": "SLU",
        "iso": "LC"
    },
    {
        "label": "UVF",
        "value": "UVF",
        "iso": "LC"
    },
    {
        "label": "NBE",
        "value": "NBE",
        "iso": "TN"
    },
    {
        "label": "AUA",
        "value": "AUA",
        "iso": "AW"
    },
    {
        "label": "BON",
        "value": "BON",
        "iso": "AN"
    },
    {
        "label": "CUR",
        "value": "CUR",
        "iso": "AN"
    },
    {
        "label": "EUX",
        "value": "EUX",
        "iso": "AN"
    },
    {
        "label": "SXM",
        "value": "SXM",
        "iso": "AN"
    },
    {
        "label": "SAB",
        "value": "SAB",
        "iso": "AN"
    },
    {
        "label": "AXA",
        "value": "AXA",
        "iso": "AI"
    },
    {
        "label": "MNI",
        "value": "MNI",
        "iso": "MS"
    },
    {
        "label": "TAB",
        "value": "TAB",
        "iso": "TT"
    },
    {
        "label": "POS",
        "value": "POS",
        "iso": "TT"
    },
    {
        "label": "NGD",
        "value": "NGD",
        "iso": "VG"
    },
    {
        "label": "EIS",
        "value": "EIS",
        "iso": "VG"
    },
    {
        "label": "VIJ",
        "value": "VIJ",
        "iso": "VG"
    },
    {
        "label": "BR-",
        "value": "BR-",
        "iso": "BR"
    },
    {
        "label": "BQU",
        "value": "BQU",
        "iso": "VC"
    },
    {
        "label": "CIW",
        "value": "CIW",
        "iso": "VC"
    },
    {
        "label": "MQS",
        "value": "MQS",
        "iso": "VC"
    },
    {
        "label": "UNI",
        "value": "UNI",
        "iso": "VC"
    },
    {
        "label": "SVD",
        "value": "SVD",
        "iso": "VC"
    },
    {
        "label": "CMJ",
        "value": "CMJ",
        "iso": "TW"
    },
    {
        "label": "TWH",
        "value": "TWH",
        "iso": "US"
    },
    {
        "label": "BDA",
        "value": "BDA",
        "iso": "BM"
    },
    {
        "label": "GIT",
        "value": "GIT",
        "iso": "TZ"
    },
    {
        "label": "LUY",
        "value": "LUY",
        "iso": "TZ"
    },
    {
        "label": "ALA",
        "value": "ALA",
        "iso": "KZ"
    },
    {
        "label": "BXH",
        "value": "BXH",
        "iso": "KZ"
    },
    {
        "label": "BXJ",
        "value": "BXJ",
        "iso": "KZ"
    },
    {
        "label": "TSE",
        "value": "TSE",
        "iso": "KZ"
    },
    {
        "label": "KOV",
        "value": "KOV",
        "iso": "KZ"
    },
    {
        "label": "PPK",
        "value": "PPK",
        "iso": "KZ"
    },
    {
        "label": "DMB",
        "value": "DMB",
        "iso": "KZ"
    },
    {
        "label": "UAE",
        "value": "UAE",
        "iso": "PG"
    },
    {
        "label": "FRU",
        "value": "FRU",
        "iso": "KG"
    },
    {
        "label": "OSS",
        "value": "OSS",
        "iso": "KG"
    },
    {
        "label": "CIT",
        "value": "CIT",
        "iso": "KZ"
    },
    {
        "label": "DZN",
        "value": "DZN",
        "iso": "KZ"
    },
    {
        "label": "KGF",
        "value": "KGF",
        "iso": "KZ"
    },
    {
        "label": "KZO",
        "value": "KZO",
        "iso": "KZ"
    },
    {
        "label": "URA",
        "value": "URA",
        "iso": "KZ"
    },
    {
        "label": "EKB",
        "value": "EKB",
        "iso": "KZ"
    },
    {
        "label": "UKK",
        "value": "UKK",
        "iso": "KZ"
    },
    {
        "label": "PWQ",
        "value": "PWQ",
        "iso": "KZ"
    },
    {
        "label": "DLX",
        "value": "DLX",
        "iso": "KZ"
    },
    {
        "label": "SCO",
        "value": "SCO",
        "iso": "KZ"
    },
    {
        "label": "GUW",
        "value": "GUW",
        "iso": "KZ"
    },
    {
        "label": "AKX",
        "value": "AKX",
        "iso": "KZ"
    },
    {
        "label": "AYK",
        "value": "AYK",
        "iso": "KZ"
    },
    {
        "label": "KSN",
        "value": "KSN",
        "iso": "KZ"
    },
    {
        "label": "GYD",
        "value": "GYD",
        "iso": "AZ"
    },
    {
        "label": "KVD",
        "value": "KVD",
        "iso": "AZ"
    },
    {
        "label": "NAJ",
        "value": "NAJ",
        "iso": "AZ"
    },
    {
        "label": "GBB",
        "value": "GBB",
        "iso": "AZ"
    },
    {
        "label": "ZTU",
        "value": "ZTU",
        "iso": "AZ"
    },
    {
        "label": "LWN",
        "value": "LWN",
        "iso": "AM"
    },
    {
        "label": "EVN",
        "value": "EVN",
        "iso": "AM"
    },
    {
        "label": "ADH",
        "value": "ADH",
        "iso": "RU"
    },
    {
        "label": "YKS",
        "value": "YKS",
        "iso": "RU"
    },
    {
        "label": "CNN",
        "value": "CNN",
        "iso": "RU"
    },
    {
        "label": "ULK",
        "value": "ULK",
        "iso": "RU"
    },
    {
        "label": "PYJ",
        "value": "PYJ",
        "iso": "RU"
    },
    {
        "label": "MJZ",
        "value": "MJZ",
        "iso": "RU"
    },
    {
        "label": "CKH",
        "value": "CKH",
        "iso": "RU"
    },
    {
        "label": "CYX",
        "value": "CYX",
        "iso": "RU"
    },
    {
        "label": "IKS",
        "value": "IKS",
        "iso": "RU"
    },
    {
        "label": "ENK",
        "value": "ENK",
        "iso": "RU"
    },
    {
        "label": "OYG",
        "value": "OYG",
        "iso": "UG"
    },
    {
        "label": "UGB",
        "value": "UGB",
        "iso": "US"
    },
    {
        "label": "KUT",
        "value": "KUT",
        "iso": "GE"
    },
    {
        "label": "BUS",
        "value": "BUS",
        "iso": "GE"
    },
    {
        "label": "SUI",
        "value": "SUI",
        "iso": "GE"
    },
    {
        "label": "TBS",
        "value": "TBS",
        "iso": "GE"
    },
    {
        "label": "BQS",
        "value": "BQS",
        "iso": "RU"
    },
    {
        "label": "GDG",
        "value": "GDG",
        "iso": "RU"
    },
    {
        "label": "TYD",
        "value": "TYD",
        "iso": "RU"
    },
    {
        "label": "KHV",
        "value": "KHV",
        "iso": "RU"
    },
    {
        "label": "KXK",
        "value": "KXK",
        "iso": "RU"
    },
    {
        "label": "DYR",
        "value": "DYR",
        "iso": "RU"
    },
    {
        "label": "PVS",
        "value": "PVS",
        "iso": "RU"
    },
    {
        "label": "GDX",
        "value": "GDX",
        "iso": "RU"
    },
    {
        "label": "PWE",
        "value": "PWE",
        "iso": "RU"
    },
    {
        "label": "BQG",
        "value": "BQG",
        "iso": "RU"
    },
    {
        "label": "OHO",
        "value": "OHO",
        "iso": "RU"
    },
    {
        "label": "PKC",
        "value": "PKC",
        "iso": "RU"
    },
    {
        "label": "OHH",
        "value": "OHH",
        "iso": "RU"
    },
    {
        "label": "EKS",
        "value": "EKS",
        "iso": "RU"
    },
    {
        "label": "DEE",
        "value": "DEE",
        "iso": "RU"
    },
    {
        "label": "ZZO",
        "value": "ZZO",
        "iso": "RU"
    },
    {
        "label": "UUS",
        "value": "UUS",
        "iso": "RU"
    },
    {
        "label": "VVO",
        "value": "VVO",
        "iso": "RU"
    },
    {
        "label": "HTA",
        "value": "HTA",
        "iso": "RU"
    },
    {
        "label": "BTK",
        "value": "BTK",
        "iso": "RU"
    },
    {
        "label": "UIK",
        "value": "UIK",
        "iso": "RU"
    },
    {
        "label": "IKT",
        "value": "IKT",
        "iso": "RU"
    },
    {
        "label": "ODO",
        "value": "ODO",
        "iso": "RU"
    },
    {
        "label": "ERG",
        "value": "ERG",
        "iso": "RU"
    },
    {
        "label": "UKX",
        "value": "UKX",
        "iso": "RU"
    },
    {
        "label": "UUD",
        "value": "UUD",
        "iso": "RU"
    },
    {
        "label": "UJE",
        "value": "UJE",
        "iso": "MH"
    },
    {
        "label": "UJN",
        "value": "UJN",
        "iso": "KR"
    },
    {
        "label": "KBP",
        "value": "KBP",
        "iso": "UA"
    },
    {
        "label": "DOK",
        "value": "DOK",
        "iso": "UA"
    },
    {
        "label": "KRQ",
        "value": "KRQ",
        "iso": "UA"
    },
    {
        "label": "MPW",
        "value": "MPW",
        "iso": "UA"
    },
    {
        "label": "SEV",
        "value": "SEV",
        "iso": "UA"
    },
    {
        "label": "VSG",
        "value": "VSG",
        "iso": "UA"
    },
    {
        "label": "ERD",
        "value": "ERD",
        "iso": "UA"
    },
    {
        "label": "DNK",
        "value": "DNK",
        "iso": "UA"
    },
    {
        "label": "OZH",
        "value": "OZH",
        "iso": "UA"
    },
    {
        "label": "KWG",
        "value": "KWG",
        "iso": "UA"
    },
    {
        "label": "UKS",
        "value": "UKS",
        "iso": "UA"
    },
    {
        "label": "SIP",
        "value": "SIP",
        "iso": "UA"
    },
    {
        "label": "KHC",
        "value": "KHC",
        "iso": "UA"
    },
    {
        "label": "UKH",
        "value": "UKH",
        "iso": "OM"
    },
    {
        "label": "HRK",
        "value": "HRK",
        "iso": "UA"
    },
    {
        "label": "PLV",
        "value": "PLV",
        "iso": "UA"
    },
    {
        "label": "UMY",
        "value": "UMY",
        "iso": "UA"
    },
    {
        "label": "CKC",
        "value": "CKC",
        "iso": "UA"
    },
    {
        "label": "KGO",
        "value": "KGO",
        "iso": "UA"
    },
    {
        "label": "IEV",
        "value": "IEV",
        "iso": "UA"
    },
    {
        "label": "GML",
        "value": "GML",
        "iso": "UA"
    },
    {
        "label": "UCK",
        "value": "UCK",
        "iso": "UA"
    },
    {
        "label": "HMJ",
        "value": "HMJ",
        "iso": "UA"
    },
    {
        "label": "IFO",
        "value": "IFO",
        "iso": "UA"
    },
    {
        "label": "LWO",
        "value": "LWO",
        "iso": "UA"
    },
    {
        "label": "CWC",
        "value": "CWC",
        "iso": "UA"
    },
    {
        "label": "RWN",
        "value": "RWN",
        "iso": "UA"
    },
    {
        "label": "TNL",
        "value": "TNL",
        "iso": "UA"
    },
    {
        "label": "UDJ",
        "value": "UDJ",
        "iso": "UA"
    },
    {
        "label": "KHE",
        "value": "KHE",
        "iso": "UA"
    },
    {
        "label": "NLV",
        "value": "NLV",
        "iso": "UA"
    },
    {
        "label": "ODS",
        "value": "ODS",
        "iso": "UA"
    },
    {
        "label": "VIN",
        "value": "VIN",
        "iso": "UA"
    },
    {
        "label": "ARH",
        "value": "ARH",
        "iso": "RU"
    },
    {
        "label": "NNM",
        "value": "NNM",
        "iso": "RU"
    },
    {
        "label": "CSH",
        "value": "CSH",
        "iso": "RU"
    },
    {
        "label": "CEE",
        "value": "CEE",
        "iso": "RU"
    },
    {
        "label": "AMV",
        "value": "AMV",
        "iso": "RU"
    },
    {
        "label": "ULH",
        "value": "ULH",
        "iso": "SA"
    },
    {
        "label": "KSZ",
        "value": "KSZ",
        "iso": "RU"
    },
    {
        "label": "LED",
        "value": "LED",
        "iso": "RU"
    },
    {
        "label": "KVK",
        "value": "KVK",
        "iso": "RU"
    },
    {
        "label": "MMK",
        "value": "MMK",
        "iso": "RU"
    },
    {
        "label": "NVR",
        "value": "NVR",
        "iso": "RU"
    },
    {
        "label": "VLU",
        "value": "VLU",
        "iso": "RU"
    },
    {
        "label": "PKV",
        "value": "PKV",
        "iso": "RU"
    },
    {
        "label": "PES",
        "value": "PES",
        "iso": "RU"
    },
    {
        "label": "RVH",
        "value": "RVH",
        "iso": "RU"
    },
    {
        "label": "VGD",
        "value": "VGD",
        "iso": "RU"
    },
    {
        "label": "BQT",
        "value": "BQT",
        "iso": "BY"
    },
    {
        "label": "GME",
        "value": "GME",
        "iso": "BY"
    },
    {
        "label": "VTB",
        "value": "VTB",
        "iso": "BY"
    },
    {
        "label": "KGD",
        "value": "KGD",
        "iso": "RU"
    },
    {
        "label": "GNA",
        "value": "GNA",
        "iso": "BY"
    },
    {
        "label": "MHP",
        "value": "MHP",
        "iso": "BY"
    },
    {
        "label": "MSQ",
        "value": "MSQ",
        "iso": "BY"
    },
    {
        "label": "MVQ",
        "value": "MVQ",
        "iso": "BY"
    },
    {
        "label": "ABA",
        "value": "ABA",
        "iso": "RU"
    },
    {
        "label": "BAX",
        "value": "BAX",
        "iso": "RU"
    },
    {
        "label": "KEJ",
        "value": "KEJ",
        "iso": "RU"
    },
    {
        "label": "EIE",
        "value": "EIE",
        "iso": "RU"
    },
    {
        "label": "KJA",
        "value": "KJA",
        "iso": "RU"
    },
    {
        "label": "ACS",
        "value": "ACS",
        "iso": "RU"
    },
    {
        "label": "KYZ",
        "value": "KYZ",
        "iso": "RU"
    },
    {
        "label": "OVB",
        "value": "OVB",
        "iso": "RU"
    },
    {
        "label": "OMS",
        "value": "OMS",
        "iso": "RU"
    },
    {
        "label": "TOF",
        "value": "TOF",
        "iso": "RU"
    },
    {
        "label": "NOZ",
        "value": "NOZ",
        "iso": "RU"
    },
    {
        "label": "DKS",
        "value": "DKS",
        "iso": "RU"
    },
    {
        "label": "HTG",
        "value": "HTG",
        "iso": "RU"
    },
    {
        "label": "IAA",
        "value": "IAA",
        "iso": "RU"
    },
    {
        "label": "NSK",
        "value": "NSK",
        "iso": "RU"
    },
    {
        "label": "AAQ",
        "value": "AAQ",
        "iso": "RU"
    },
    {
        "label": "EIK",
        "value": "EIK",
        "iso": "RU"
    },
    {
        "label": "GDZ",
        "value": "GDZ",
        "iso": "RU"
    },
    {
        "label": "KRR",
        "value": "KRR",
        "iso": "RU"
    },
    {
        "label": "MCX",
        "value": "MCX",
        "iso": "RU"
    },
    {
        "label": "MRV",
        "value": "MRV",
        "iso": "RU"
    },
    {
        "label": "NAL",
        "value": "NAL",
        "iso": "RU"
    },
    {
        "label": "OGZ",
        "value": "OGZ",
        "iso": "RU"
    },
    {
        "label": "IGT",
        "value": "IGT",
        "iso": "RU"
    },
    {
        "label": "STW",
        "value": "STW",
        "iso": "RU"
    },
    {
        "label": "ROV",
        "value": "ROV",
        "iso": "RU"
    },
    {
        "label": "VLK",
        "value": "VLK",
        "iso": "RU"
    },
    {
        "label": "AER",
        "value": "AER",
        "iso": "RU"
    },
    {
        "label": "ASF",
        "value": "ASF",
        "iso": "RU"
    },
    {
        "label": "ESL",
        "value": "ESL",
        "iso": "RU"
    },
    {
        "label": "VOG",
        "value": "VOG",
        "iso": "RU"
    },
    {
        "label": "AHT",
        "value": "AHT",
        "iso": "US"
    },
    {
        "label": "CEK",
        "value": "CEK",
        "iso": "RU"
    },
    {
        "label": "MQF",
        "value": "MQF",
        "iso": "RU"
    },
    {
        "label": "SLY",
        "value": "SLY",
        "iso": "RU"
    },
    {
        "label": "YMK",
        "value": "YMK",
        "iso": "RU"
    },
    {
        "label": "UEN",
        "value": "UEN",
        "iso": "RU"
    },
    {
        "label": "EZV",
        "value": "EZV",
        "iso": "RU"
    },
    {
        "label": "HMA",
        "value": "HMA",
        "iso": "RU"
    },
    {
        "label": "IRM",
        "value": "IRM",
        "iso": "RU"
    },
    {
        "label": "NYA",
        "value": "NYA",
        "iso": "RU"
    },
    {
        "label": "OVS",
        "value": "OVS",
        "iso": "RU"
    },
    {
        "label": "URJ",
        "value": "URJ",
        "iso": "RU"
    },
    {
        "label": "EYK",
        "value": "EYK",
        "iso": "RU"
    },
    {
        "label": "IJK",
        "value": "IJK",
        "iso": "RU"
    },
    {
        "label": "KVX",
        "value": "KVX",
        "iso": "RU"
    },
    {
        "label": "NYM",
        "value": "NYM",
        "iso": "RU"
    },
    {
        "label": "NUX",
        "value": "NUX",
        "iso": "RU"
    },
    {
        "label": "NJC",
        "value": "NJC",
        "iso": "RU"
    },
    {
        "label": "RAT",
        "value": "RAT",
        "iso": "RU"
    },
    {
        "label": "PEE",
        "value": "PEE",
        "iso": "RU"
    },
    {
        "label": "KGP",
        "value": "KGP",
        "iso": "RU"
    },
    {
        "label": "NFG",
        "value": "NFG",
        "iso": "RU"
    },
    {
        "label": "NOJ",
        "value": "NOJ",
        "iso": "RU"
    },
    {
        "label": "SGC",
        "value": "SGC",
        "iso": "RU"
    },
    {
        "label": "SVX",
        "value": "SVX",
        "iso": "RU"
    },
    {
        "label": "TOX",
        "value": "TOX",
        "iso": "RU"
    },
    {
        "label": "TJM",
        "value": "TJM",
        "iso": "RU"
    },
    {
        "label": "KRO",
        "value": "KRO",
        "iso": "RU"
    },
    {
        "label": "ASB",
        "value": "ASB",
        "iso": "TM"
    },
    {
        "label": "KRW",
        "value": "KRW",
        "iso": "TM"
    },
    {
        "label": "MYP",
        "value": "MYP",
        "iso": "TM"
    },
    {
        "label": "TAZ",
        "value": "TAZ",
        "iso": "TM"
    },
    {
        "label": "CRZ",
        "value": "CRZ",
        "iso": "TM"
    },
    {
        "label": "DYU",
        "value": "DYU",
        "iso": "TJ"
    },
    {
        "label": "TJU",
        "value": "TJU",
        "iso": "TJ"
    },
    {
        "label": "LBD",
        "value": "LBD",
        "iso": "TJ"
    },
    {
        "label": "KQT",
        "value": "KQT",
        "iso": "TJ"
    },
    {
        "label": "AZN",
        "value": "AZN",
        "iso": "UZ"
    },
    {
        "label": "FEG",
        "value": "FEG",
        "iso": "UZ"
    },
    {
        "label": "NMA",
        "value": "NMA",
        "iso": "UZ"
    },
    {
        "label": "NCU",
        "value": "NCU",
        "iso": "UZ"
    },
    {
        "label": "UGC",
        "value": "UGC",
        "iso": "UZ"
    },
    {
        "label": "NVI",
        "value": "NVI",
        "iso": "UZ"
    },
    {
        "label": "BHK",
        "value": "BHK",
        "iso": "UZ"
    },
    {
        "label": "KSQ",
        "value": "KSQ",
        "iso": "UZ"
    },
    {
        "label": "AFS",
        "value": "AFS",
        "iso": "UZ"
    },
    {
        "label": "SKD",
        "value": "SKD",
        "iso": "UZ"
    },
    {
        "label": "TMJ",
        "value": "TMJ",
        "iso": "UZ"
    },
    {
        "label": "TAS",
        "value": "TAS",
        "iso": "UZ"
    },
    {
        "label": "KMW",
        "value": "KMW",
        "iso": "RU"
    },
    {
        "label": "BKA",
        "value": "BKA",
        "iso": "RU"
    },
    {
        "label": "IWA",
        "value": "IWA",
        "iso": "RU"
    },
    {
        "label": "RYB",
        "value": "RYB",
        "iso": "RU"
    },
    {
        "label": "BZK",
        "value": "BZK",
        "iso": "RU"
    },
    {
        "label": "DME",
        "value": "DME",
        "iso": "RU"
    },
    {
        "label": "IAR",
        "value": "IAR",
        "iso": "RU"
    },
    {
        "label": "SVO",
        "value": "SVO",
        "iso": "RU"
    },
    {
        "label": "KLD",
        "value": "KLD",
        "iso": "RU"
    },
    {
        "label": "EGO",
        "value": "EGO",
        "iso": "RU"
    },
    {
        "label": "URS",
        "value": "URS",
        "iso": "RU"
    },
    {
        "label": "LPK",
        "value": "LPK",
        "iso": "RU"
    },
    {
        "label": "VOZ",
        "value": "VOZ",
        "iso": "RU"
    },
    {
        "label": "OEL",
        "value": "OEL",
        "iso": "RU"
    },
    {
        "label": "TBW",
        "value": "TBW",
        "iso": "RU"
    },
    {
        "label": "RZN",
        "value": "RZN",
        "iso": "RU"
    },
    {
        "label": "VKO",
        "value": "VKO",
        "iso": "RU"
    },
    {
        "label": "UCT",
        "value": "UCT",
        "iso": "RU"
    },
    {
        "label": "INA",
        "value": "INA",
        "iso": "RU"
    },
    {
        "label": "PEX",
        "value": "PEX",
        "iso": "RU"
    },
    {
        "label": "USK",
        "value": "USK",
        "iso": "RU"
    },
    {
        "label": "VKT",
        "value": "VKT",
        "iso": "RU"
    },
    {
        "label": "UTS",
        "value": "UTS",
        "iso": "RU"
    },
    {
        "label": "SCW",
        "value": "SCW",
        "iso": "RU"
    },
    {
        "label": "GOJ",
        "value": "GOJ",
        "iso": "RU"
    },
    {
        "label": "UUA",
        "value": "UUA",
        "iso": "RU"
    },
    {
        "label": "KZN",
        "value": "KZN",
        "iso": "RU"
    },
    {
        "label": "NBC",
        "value": "NBC",
        "iso": "RU"
    },
    {
        "label": "JOK",
        "value": "JOK",
        "iso": "RU"
    },
    {
        "label": "CSY",
        "value": "CSY",
        "iso": "RU"
    },
    {
        "label": "ULV",
        "value": "ULV",
        "iso": "RU"
    },
    {
        "label": "ULY",
        "value": "ULY",
        "iso": "RU"
    },
    {
        "label": "REN",
        "value": "REN",
        "iso": "RU"
    },
    {
        "label": "OSW",
        "value": "OSW",
        "iso": "RU"
    },
    {
        "label": "PEZ",
        "value": "PEZ",
        "iso": "RU"
    },
    {
        "label": "SKX",
        "value": "SKX",
        "iso": "RU"
    },
    {
        "label": "BWO",
        "value": "BWO",
        "iso": "RU"
    },
    {
        "label": "RTW",
        "value": "RTW",
        "iso": "RU"
    },
    {
        "label": "BCX",
        "value": "BCX",
        "iso": "RU"
    },
    {
        "label": "NEF",
        "value": "NEF",
        "iso": "RU"
    },
    {
        "label": "OKT",
        "value": "OKT",
        "iso": "RU"
    },
    {
        "label": "UFA",
        "value": "UFA",
        "iso": "RU"
    },
    {
        "label": "KUF",
        "value": "KUF",
        "iso": "RU"
    },
    {
        "label": "FZB",
        "value": "FZB",
        "iso": "UY"
    },
    {
        "label": "RCH",
        "value": "RCH",
        "iso": "UY"
    },
    {
        "label": "DIU",
        "value": "DIU",
        "iso": "IN"
    },
    {
        "label": "AMD",
        "value": "AMD",
        "iso": "IN"
    },
    {
        "label": "AKD",
        "value": "AKD",
        "iso": "IN"
    },
    {
        "label": "IXU",
        "value": "IXU",
        "iso": "IN"
    },
    {
        "label": "BOM",
        "value": "BOM",
        "iso": "IN"
    },
    {
        "label": "PAB",
        "value": "PAB",
        "iso": "IN"
    },
    {
        "label": "BHJ",
        "value": "BHJ",
        "iso": "IN"
    },
    {
        "label": "IXG",
        "value": "IXG",
        "iso": "IN"
    },
    {
        "label": "BDQ",
        "value": "BDQ",
        "iso": "IN"
    },
    {
        "label": "BHO",
        "value": "BHO",
        "iso": "IN"
    },
    {
        "label": "BHU",
        "value": "BHU",
        "iso": "IN"
    },
    {
        "label": "NMB",
        "value": "NMB",
        "iso": "IN"
    },
    {
        "label": "GUX",
        "value": "GUX",
        "iso": "IN"
    },
    {
        "label": "GOI",
        "value": "GOI",
        "iso": "IN"
    },
    {
        "label": "HBX",
        "value": "HBX",
        "iso": "IN"
    },
    {
        "label": "IDR",
        "value": "IDR",
        "iso": "IN"
    },
    {
        "label": "JLR",
        "value": "JLR",
        "iso": "IN"
    },
    {
        "label": "JGA",
        "value": "JGA",
        "iso": "IN"
    },
    {
        "label": "IXY",
        "value": "IXY",
        "iso": "IN"
    },
    {
        "label": "HJR",
        "value": "HJR",
        "iso": "IN"
    },
    {
        "label": "KLH",
        "value": "KLH",
        "iso": "IN"
    },
    {
        "label": "IXK",
        "value": "IXK",
        "iso": "IN"
    },
    {
        "label": "NDC",
        "value": "NDC",
        "iso": "IN"
    },
    {
        "label": "NAG",
        "value": "NAG",
        "iso": "IN"
    },
    {
        "label": "ISK",
        "value": "ISK",
        "iso": "IN"
    },
    {
        "label": "PNQ",
        "value": "PNQ",
        "iso": "IN"
    },
    {
        "label": "PBD",
        "value": "PBD",
        "iso": "IN"
    },
    {
        "label": "RTC",
        "value": "RTC",
        "iso": "IN"
    },
    {
        "label": "RAJ",
        "value": "RAJ",
        "iso": "IN"
    },
    {
        "label": "RPR",
        "value": "RPR",
        "iso": "IN"
    },
    {
        "label": "SSE",
        "value": "SSE",
        "iso": "IN"
    },
    {
        "label": "STV",
        "value": "STV",
        "iso": "IN"
    },
    {
        "label": "UDR",
        "value": "UDR",
        "iso": "IN"
    },
    {
        "label": "CMB",
        "value": "CMB",
        "iso": "LK"
    },
    {
        "label": "ACJ",
        "value": "ACJ",
        "iso": "LK"
    },
    {
        "label": "BTC",
        "value": "BTC",
        "iso": "LK"
    },
    {
        "label": "RML",
        "value": "RML",
        "iso": "LK"
    },
    {
        "label": "GOY",
        "value": "GOY",
        "iso": "LK"
    },
    {
        "label": "MNH",
        "value": "MNH",
        "iso": "LK"
    },
    {
        "label": "JAF",
        "value": "JAF",
        "iso": "LK"
    },
    {
        "label": "KCT",
        "value": "KCT",
        "iso": "LK"
    },
    {
        "label": "GIU",
        "value": "GIU",
        "iso": "LK"
    },
    {
        "label": "TRR",
        "value": "TRR",
        "iso": "LK"
    },
    {
        "label": "WRZ",
        "value": "WRZ",
        "iso": "LK"
    },
    {
        "label": "BBM",
        "value": "BBM",
        "iso": "KH"
    },
    {
        "label": "KZC",
        "value": "KZC",
        "iso": "KH"
    },
    {
        "label": "KKZ",
        "value": "KKZ",
        "iso": "KH"
    },
    {
        "label": "KTI",
        "value": "KTI",
        "iso": "KH"
    },
    {
        "label": "PNH",
        "value": "PNH",
        "iso": "KH"
    },
    {
        "label": "RBE",
        "value": "RBE",
        "iso": "KH"
    },
    {
        "label": "REP",
        "value": "REP",
        "iso": "KH"
    },
    {
        "label": "TNX",
        "value": "TNX",
        "iso": "KH"
    },
    {
        "label": "KOS",
        "value": "KOS",
        "iso": "KH"
    },
    {
        "label": "ELX",
        "value": "ELX",
        "iso": "VE"
    },
    {
        "label": "LGY",
        "value": "LGY",
        "iso": "VE"
    },
    {
        "label": "KTV",
        "value": "KTV",
        "iso": "VE"
    },
    {
        "label": "LAG",
        "value": "LAG",
        "iso": "VE"
    },
    {
        "label": "SFX",
        "value": "SFX",
        "iso": "VE"
    },
    {
        "label": "SVV",
        "value": "SVV",
        "iso": "VE"
    },
    {
        "label": "WOK",
        "value": "WOK",
        "iso": "VE"
    },
    {
        "label": "IXV",
        "value": "IXV",
        "iso": "IN"
    },
    {
        "label": "IXA",
        "value": "IXA",
        "iso": "IN"
    },
    {
        "label": "AJL",
        "value": "AJL",
        "iso": "IN"
    },
    {
        "label": "IXB",
        "value": "IXB",
        "iso": "IN"
    },
    {
        "label": "RGH",
        "value": "RGH",
        "iso": "IN"
    },
    {
        "label": "SHL",
        "value": "SHL",
        "iso": "IN"
    },
    {
        "label": "BBI",
        "value": "BBI",
        "iso": "IN"
    },
    {
        "label": "CCU",
        "value": "CCU",
        "iso": "IN"
    },
    {
        "label": "COH",
        "value": "COH",
        "iso": "IN"
    },
    {
        "label": "DBD",
        "value": "DBD",
        "iso": "IN"
    },
    {
        "label": "DAE",
        "value": "DAE",
        "iso": "IN"
    },
    {
        "label": "GOP",
        "value": "GOP",
        "iso": "IN"
    },
    {
        "label": "GAU",
        "value": "GAU",
        "iso": "IN"
    },
    {
        "label": "GAY",
        "value": "GAY",
        "iso": "IN"
    },
    {
        "label": "IMF",
        "value": "IMF",
        "iso": "IN"
    },
    {
        "label": "PYB",
        "value": "PYB",
        "iso": "IN"
    },
    {
        "label": "IXW",
        "value": "IXW",
        "iso": "IN"
    },
    {
        "label": "JRH",
        "value": "JRH",
        "iso": "IN"
    },
    {
        "label": "IXQ",
        "value": "IXQ",
        "iso": "IN"
    },
    {
        "label": "IXH",
        "value": "IXH",
        "iso": "IN"
    },
    {
        "label": "IXS",
        "value": "IXS",
        "iso": "IN"
    },
    {
        "label": "IXN",
        "value": "IXN",
        "iso": "IN"
    },
    {
        "label": "AJL",
        "value": "AJL",
        "iso": "IN"
    },
    {
        "label": "IXI",
        "value": "IXI",
        "iso": "IN"
    },
    {
        "label": "LDA",
        "value": "LDA",
        "iso": "IN"
    },
    {
        "label": "DIB",
        "value": "DIB",
        "iso": "IN"
    },
    {
        "label": "DMU",
        "value": "DMU",
        "iso": "IN"
    },
    {
        "label": "MZU",
        "value": "MZU",
        "iso": "IN"
    },
    {
        "label": "IXT",
        "value": "IXT",
        "iso": "IN"
    },
    {
        "label": "PAT",
        "value": "PAT",
        "iso": "IN"
    },
    {
        "label": "IXR",
        "value": "IXR",
        "iso": "IN"
    },
    {
        "label": "RRK",
        "value": "RRK",
        "iso": "IN"
    },
    {
        "label": "RUP",
        "value": "RUP",
        "iso": "IN"
    },
    {
        "label": "TEZ",
        "value": "TEZ",
        "iso": "IN"
    },
    {
        "label": "VTZ",
        "value": "VTZ",
        "iso": "IN"
    },
    {
        "label": "ZER",
        "value": "ZER",
        "iso": "IN"
    },
    {
        "label": "BZL",
        "value": "BZL",
        "iso": "BD"
    },
    {
        "label": "CXB",
        "value": "CXB",
        "iso": "BD"
    },
    {
        "label": "CLA",
        "value": "CLA",
        "iso": "BD"
    },
    {
        "label": "CGP",
        "value": "CGP",
        "iso": "BD"
    },
    {
        "label": "IRD",
        "value": "IRD",
        "iso": "BD"
    },
    {
        "label": "JSR",
        "value": "JSR",
        "iso": "BD"
    },
    {
        "label": "LLJ",
        "value": "LLJ",
        "iso": "BD"
    },
    {
        "label": "RJH",
        "value": "RJH",
        "iso": "BD"
    },
    {
        "label": "SPD",
        "value": "SPD",
        "iso": "BD"
    },
    {
        "label": "TKR",
        "value": "TKR",
        "iso": "BD"
    },
    {
        "label": "ZHM",
        "value": "ZHM",
        "iso": "BD"
    },
    {
        "label": "ZYL",
        "value": "ZYL",
        "iso": "BD"
    },
    {
        "label": "DAC",
        "value": "DAC",
        "iso": "BD"
    },
    {
        "label": "HKG",
        "value": "HKG",
        "iso": "HK"
    },
    {
        "label": "HKG",
        "value": "HKG",
        "iso": "HK"
    },
    {
        "label": "HHP",
        "value": "HHP",
        "iso": "HK"
    },
    {
        "label": "SPB",
        "value": "SPB",
        "iso": "VI"
    },
    {
        "label": "SSB",
        "value": "SSB",
        "iso": "VI"
    },
    {
        "label": "AGR",
        "value": "AGR",
        "iso": "IN"
    },
    {
        "label": "IXD",
        "value": "IXD",
        "iso": "IN"
    },
    {
        "label": "ATQ",
        "value": "ATQ",
        "iso": "IN"
    },
    {
        "label": "BKB",
        "value": "BKB",
        "iso": "IN"
    },
    {
        "label": "VNS",
        "value": "VNS",
        "iso": "IN"
    },
    {
        "label": "KUU",
        "value": "KUU",
        "iso": "IN"
    },
    {
        "label": "BUP",
        "value": "BUP",
        "iso": "IN"
    },
    {
        "label": "IXC",
        "value": "IXC",
        "iso": "IN"
    },
    {
        "label": "DED",
        "value": "DED",
        "iso": "IN"
    },
    {
        "label": "DEL",
        "value": "DEL",
        "iso": "IN"
    },
    {
        "label": "DHM",
        "value": "DHM",
        "iso": "IN"
    },
    {
        "label": "GWL",
        "value": "GWL",
        "iso": "IN"
    },
    {
        "label": "HSS",
        "value": "HSS",
        "iso": "IN"
    },
    {
        "label": "JDH",
        "value": "JDH",
        "iso": "IN"
    },
    {
        "label": "JAI",
        "value": "JAI",
        "iso": "IN"
    },
    {
        "label": "JSA",
        "value": "JSA",
        "iso": "IN"
    },
    {
        "label": "IXJ",
        "value": "IXJ",
        "iso": "IN"
    },
    {
        "label": "KNU",
        "value": "KNU",
        "iso": "IN"
    },
    {
        "label": "KTU",
        "value": "KTU",
        "iso": "IN"
    },
    {
        "label": "LUH",
        "value": "LUH",
        "iso": "IN"
    },
    {
        "label": "IXL",
        "value": "IXL",
        "iso": "IN"
    },
    {
        "label": "LKO",
        "value": "LKO",
        "iso": "IN"
    },
    {
        "label": "IXP",
        "value": "IXP",
        "iso": "IN"
    },
    {
        "label": "PGH",
        "value": "PGH",
        "iso": "IN"
    },
    {
        "label": "SLV",
        "value": "SLV",
        "iso": "IN"
    },
    {
        "label": "SXR",
        "value": "SXR",
        "iso": "IN"
    },
    {
        "label": "TNI",
        "value": "TNI",
        "iso": "IN"
    },
    {
        "label": "AOU",
        "value": "AOU",
        "iso": "LA"
    },
    {
        "label": "OUI",
        "value": "OUI",
        "iso": "LA"
    },
    {
        "label": "LPQ",
        "value": "LPQ",
        "iso": "LA"
    },
    {
        "label": "LXG",
        "value": "LXG",
        "iso": "LA"
    },
    {
        "label": "ODY",
        "value": "ODY",
        "iso": "LA"
    },
    {
        "label": "PKZ",
        "value": "PKZ",
        "iso": "LA"
    },
    {
        "label": "ZBY",
        "value": "ZBY",
        "iso": "LA"
    },
    {
        "label": "ZVK",
        "value": "ZVK",
        "iso": "LA"
    },
    {
        "label": "NEU",
        "value": "NEU",
        "iso": "LA"
    },
    {
        "label": "VNA",
        "value": "VNA",
        "iso": "LA"
    },
    {
        "label": "THK",
        "value": "THK",
        "iso": "LA"
    },
    {
        "label": "VTE",
        "value": "VTE",
        "iso": "LA"
    },
    {
        "label": "XKH",
        "value": "XKH",
        "iso": "LA"
    },
    {
        "label": "VMI",
        "value": "VMI",
        "iso": "PY"
    },
    {
        "label": "MFM",
        "value": "MFM",
        "iso": "MO"
    },
    {
        "label": "VDH",
        "value": "VDH",
        "iso": "VN"
    },
    {
        "label": "KON",
        "value": "KON",
        "iso": "VN"
    },
    {
        "label": "BJH",
        "value": "BJH",
        "iso": "NP"
    },
    {
        "label": "BHP",
        "value": "BHP",
        "iso": "NP"
    },
    {
        "label": "BGL",
        "value": "BGL",
        "iso": "NP"
    },
    {
        "label": "BHR",
        "value": "BHR",
        "iso": "NP"
    },
    {
        "label": "BJU",
        "value": "BJU",
        "iso": "NP"
    },
    {
        "label": "BIT",
        "value": "BIT",
        "iso": "NP"
    },
    {
        "label": "BWA",
        "value": "BWA",
        "iso": "NP"
    },
    {
        "label": "BDP",
        "value": "BDP",
        "iso": "NP"
    },
    {
        "label": "DNP",
        "value": "DNP",
        "iso": "NP"
    },
    {
        "label": "DHI",
        "value": "DHI",
        "iso": "NP"
    },
    {
        "label": "DAP",
        "value": "DAP",
        "iso": "NP"
    },
    {
        "label": "DOP",
        "value": "DOP",
        "iso": "NP"
    },
    {
        "label": "SIH",
        "value": "SIH",
        "iso": "NP"
    },
    {
        "label": "GKH",
        "value": "GKH",
        "iso": "NP"
    },
    {
        "label": "JIR",
        "value": "JIR",
        "iso": "NP"
    },
    {
        "label": "JUM",
        "value": "JUM",
        "iso": "NP"
    },
    {
        "label": "JKR",
        "value": "JKR",
        "iso": "NP"
    },
    {
        "label": "KTM",
        "value": "KTM",
        "iso": "NP"
    },
    {
        "label": "LDN",
        "value": "LDN",
        "iso": "NP"
    },
    {
        "label": "LUA",
        "value": "LUA",
        "iso": "NP"
    },
    {
        "label": "LTG",
        "value": "LTG",
        "iso": "NP"
    },
    {
        "label": "NGX",
        "value": "NGX",
        "iso": "NP"
    },
    {
        "label": "MEY",
        "value": "MEY",
        "iso": "NP"
    },
    {
        "label": "XMG",
        "value": "XMG",
        "iso": "NP"
    },
    {
        "label": "KEP",
        "value": "KEP",
        "iso": "NP"
    },
    {
        "label": "PKR",
        "value": "PKR",
        "iso": "NP"
    },
    {
        "label": "PPL",
        "value": "PPL",
        "iso": "NP"
    },
    {
        "label": "RJB",
        "value": "RJB",
        "iso": "NP"
    },
    {
        "label": "RHP",
        "value": "RHP",
        "iso": "NP"
    },
    {
        "label": "RUK",
        "value": "RUK",
        "iso": "NP"
    },
    {
        "label": "RUM",
        "value": "RUM",
        "iso": "NP"
    },
    {
        "label": "SIF",
        "value": "SIF",
        "iso": "NP"
    },
    {
        "label": "SKH",
        "value": "SKH",
        "iso": "NP"
    },
    {
        "label": "FEB",
        "value": "FEB",
        "iso": "NP"
    },
    {
        "label": "IMK",
        "value": "IMK",
        "iso": "NP"
    },
    {
        "label": "TMI",
        "value": "TMI",
        "iso": "NP"
    },
    {
        "label": "BIR",
        "value": "BIR",
        "iso": "NP"
    },
    {
        "label": "LTU",
        "value": "LTU",
        "iso": "IN"
    },
    {
        "label": "AGX",
        "value": "AGX",
        "iso": "IN"
    },
    {
        "label": "BEP",
        "value": "BEP",
        "iso": "IN"
    },
    {
        "label": "BLR",
        "value": "BLR",
        "iso": "IN"
    },
    {
        "label": "VGA",
        "value": "VGA",
        "iso": "IN"
    },
    {
        "label": "CJB",
        "value": "CJB",
        "iso": "IN"
    },
    {
        "label": "COK",
        "value": "COK",
        "iso": "IN"
    },
    {
        "label": "CCJ",
        "value": "CCJ",
        "iso": "IN"
    },
    {
        "label": "CDP",
        "value": "CDP",
        "iso": "IN"
    },
    {
        "label": "CBD",
        "value": "CBD",
        "iso": "IN"
    },
    {
        "label": "HYD",
        "value": "HYD",
        "iso": "IN"
    },
    {
        "label": "IXM",
        "value": "IXM",
        "iso": "IN"
    },
    {
        "label": "IXE",
        "value": "IXE",
        "iso": "IN"
    },
    {
        "label": "MAA",
        "value": "MAA",
        "iso": "IN"
    },
    {
        "label": "MYQ",
        "value": "MYQ",
        "iso": "IN"
    },
    {
        "label": "IXZ",
        "value": "IXZ",
        "iso": "IN"
    },
    {
        "label": "PNY",
        "value": "PNY",
        "iso": "IN"
    },
    {
        "label": "HYD",
        "value": "HYD",
        "iso": "IN"
    },
    {
        "label": "RJA",
        "value": "RJA",
        "iso": "IN"
    },
    {
        "label": "SXV",
        "value": "SXV",
        "iso": "IN"
    },
    {
        "label": "TJV",
        "value": "TJV",
        "iso": "IN"
    },
    {
        "label": "TIR",
        "value": "TIR",
        "iso": "IN"
    },
    {
        "label": "TRZ",
        "value": "TRZ",
        "iso": "IN"
    },
    {
        "label": "TRV",
        "value": "TRV",
        "iso": "IN"
    },
    {
        "label": "WGC",
        "value": "WGC",
        "iso": "IN"
    },
    {
        "label": "PBH",
        "value": "PBH",
        "iso": "BT"
    },
    {
        "label": "FVM",
        "value": "FVM",
        "iso": "MV"
    },
    {
        "label": "GAN",
        "value": "GAN",
        "iso": "MV"
    },
    {
        "label": "HAQ",
        "value": "HAQ",
        "iso": "MV"
    },
    {
        "label": "KDO",
        "value": "KDO",
        "iso": "MV"
    },
    {
        "label": "MLE",
        "value": "MLE",
        "iso": "MV"
    },
    {
        "label": "KDM",
        "value": "KDM",
        "iso": "MV"
    },
    {
        "label": "VAM",
        "value": "VAM",
        "iso": "MV"
    },
    {
        "label": "DMK",
        "value": "DMK",
        "iso": "TH"
    },
    {
        "label": "KKM",
        "value": "KKM",
        "iso": "TH"
    },
    {
        "label": "KKM",
        "value": "KKM",
        "iso": "TH"
    },
    {
        "label": "TDX",
        "value": "TDX",
        "iso": "TH"
    },
    {
        "label": "BKK",
        "value": "BKK",
        "iso": "TH"
    },
    {
        "label": "UTP",
        "value": "UTP",
        "iso": "TH"
    },
    {
        "label": "CNX",
        "value": "CNX",
        "iso": "TH"
    },
    {
        "label": "HGN",
        "value": "HGN",
        "iso": "TH"
    },
    {
        "label": "PYY",
        "value": "PYY",
        "iso": "TH"
    },
    {
        "label": "LPT",
        "value": "LPT",
        "iso": "TH"
    },
    {
        "label": "NNT",
        "value": "NNT",
        "iso": "TH"
    },
    {
        "label": "PRH",
        "value": "PRH",
        "iso": "TH"
    },
    {
        "label": "CEI",
        "value": "CEI",
        "iso": "TH"
    },
    {
        "label": "BAO",
        "value": "BAO",
        "iso": "TH"
    },
    {
        "label": "PHY",
        "value": "PHY",
        "iso": "TH"
    },
    {
        "label": "HHQ",
        "value": "HHQ",
        "iso": "TH"
    },
    {
        "label": "TKH",
        "value": "TKH",
        "iso": "TH"
    },
    {
        "label": "MAQ",
        "value": "MAQ",
        "iso": "TH"
    },
    {
        "label": "THS",
        "value": "THS",
        "iso": "TH"
    },
    {
        "label": "PHS",
        "value": "PHS",
        "iso": "TH"
    },
    {
        "label": "TKT",
        "value": "TKT",
        "iso": "TH"
    },
    {
        "label": "UTR",
        "value": "UTR",
        "iso": "TH"
    },
    {
        "label": "URT",
        "value": "URT",
        "iso": "TH"
    },
    {
        "label": "NAW",
        "value": "NAW",
        "iso": "TH"
    },
    {
        "label": "CJM",
        "value": "CJM",
        "iso": "TH"
    },
    {
        "label": "NST",
        "value": "NST",
        "iso": "TH"
    },
    {
        "label": "KBV",
        "value": "KBV",
        "iso": "TH"
    },
    {
        "label": "SGZ",
        "value": "SGZ",
        "iso": "TH"
    },
    {
        "label": "PAN",
        "value": "PAN",
        "iso": "TH"
    },
    {
        "label": "USM",
        "value": "USM",
        "iso": "TH"
    },
    {
        "label": "NST",
        "value": "NST",
        "iso": "TH"
    },
    {
        "label": "HKT",
        "value": "HKT",
        "iso": "TH"
    },
    {
        "label": "UNN",
        "value": "UNN",
        "iso": "TH"
    },
    {
        "label": "HDY",
        "value": "HDY",
        "iso": "TH"
    },
    {
        "label": "TST",
        "value": "TST",
        "iso": "TH"
    },
    {
        "label": "UTH",
        "value": "UTH",
        "iso": "TH"
    },
    {
        "label": "SNO",
        "value": "SNO",
        "iso": "TH"
    },
    {
        "label": "KKC",
        "value": "KKC",
        "iso": "TH"
    },
    {
        "label": "LOE",
        "value": "LOE",
        "iso": "TH"
    },
    {
        "label": "BFV",
        "value": "BFV",
        "iso": "TH"
    },
    {
        "label": "NAK",
        "value": "NAK",
        "iso": "TH"
    },
    {
        "label": "UBP",
        "value": "UBP",
        "iso": "TH"
    },
    {
        "label": "ROI",
        "value": "ROI",
        "iso": "TH"
    },
    {
        "label": "KOP",
        "value": "KOP",
        "iso": "TH"
    },
    {
        "label": "VUU",
        "value": "VUU",
        "iso": "MW"
    },
    {
        "label": "BMV",
        "value": "BMV",
        "iso": "VN"
    },
    {
        "label": "VCL",
        "value": "VCL",
        "iso": "VN"
    },
    {
        "label": "HPH",
        "value": "HPH",
        "iso": "VN"
    },
    {
        "label": "CXR",
        "value": "CXR",
        "iso": "VN"
    },
    {
        "label": "VCS",
        "value": "VCS",
        "iso": "VN"
    },
    {
        "label": "VCA",
        "value": "VCA",
        "iso": "VN"
    },
    {
        "label": "DIN",
        "value": "DIN",
        "iso": "VN"
    },
    {
        "label": "DLI",
        "value": "DLI",
        "iso": "VN"
    },
    {
        "label": "DAD",
        "value": "DAD",
        "iso": "VN"
    },
    {
        "label": "VVN",
        "value": "VVN",
        "iso": "PE"
    },
    {
        "label": "HAN",
        "value": "HAN",
        "iso": "VN"
    },
    {
        "label": "SQH",
        "value": "SQH",
        "iso": "VN"
    },
    {
        "label": "NHA",
        "value": "NHA",
        "iso": "VN"
    },
    {
        "label": "HUI",
        "value": "HUI",
        "iso": "VN"
    },
    {
        "label": "UIH",
        "value": "UIH",
        "iso": "VN"
    },
    {
        "label": "PXU",
        "value": "PXU",
        "iso": "VN"
    },
    {
        "label": "PQC",
        "value": "PQC",
        "iso": "VN"
    },
    {
        "label": "PHA",
        "value": "PHA",
        "iso": "VN"
    },
    {
        "label": "PHH",
        "value": "PHH",
        "iso": "VN"
    },
    {
        "label": "VKG",
        "value": "VKG",
        "iso": "VN"
    },
    {
        "label": "TBB",
        "value": "TBB",
        "iso": "VN"
    },
    {
        "label": "SGN",
        "value": "SGN",
        "iso": "VN"
    },
    {
        "label": "VII",
        "value": "VII",
        "iso": "VN"
    },
    {
        "label": "VTG",
        "value": "VTG",
        "iso": "VN"
    },
    {
        "label": "NYU",
        "value": "NYU",
        "iso": "MM"
    },
    {
        "label": "BMO",
        "value": "BMO",
        "iso": "MM"
    },
    {
        "label": "TVY",
        "value": "TVY",
        "iso": "MM"
    },
    {
        "label": "NYT",
        "value": "NYT",
        "iso": "MM"
    },
    {
        "label": "GAW",
        "value": "GAW",
        "iso": "MM"
    },
    {
        "label": "GWA",
        "value": "GWA",
        "iso": "MM"
    },
    {
        "label": "HEH",
        "value": "HEH",
        "iso": "MM"
    },
    {
        "label": "HOX",
        "value": "HOX",
        "iso": "MM"
    },
    {
        "label": "TIO",
        "value": "TIO",
        "iso": "MM"
    },
    {
        "label": "KET",
        "value": "KET",
        "iso": "MM"
    },
    {
        "label": "KHM",
        "value": "KHM",
        "iso": "MM"
    },
    {
        "label": "KMV",
        "value": "KMV",
        "iso": "MM"
    },
    {
        "label": "KYP",
        "value": "KYP",
        "iso": "MM"
    },
    {
        "label": "KAW",
        "value": "KAW",
        "iso": "MM"
    },
    {
        "label": "KYT",
        "value": "KYT",
        "iso": "MM"
    },
    {
        "label": "LIW",
        "value": "LIW",
        "iso": "MM"
    },
    {
        "label": "LSH",
        "value": "LSH",
        "iso": "MM"
    },
    {
        "label": "MDL",
        "value": "MDL",
        "iso": "MM"
    },
    {
        "label": "MGZ",
        "value": "MGZ",
        "iso": "MM"
    },
    {
        "label": "MYT",
        "value": "MYT",
        "iso": "MM"
    },
    {
        "label": "MNU",
        "value": "MNU",
        "iso": "MM"
    },
    {
        "label": "MGU",
        "value": "MGU",
        "iso": "MM"
    },
    {
        "label": "MOE",
        "value": "MOE",
        "iso": "MM"
    },
    {
        "label": "MOG",
        "value": "MOG",
        "iso": "MM"
    },
    {
        "label": "MGK",
        "value": "MGK",
        "iso": "MM"
    },
    {
        "label": "MWQ",
        "value": "MWQ",
        "iso": "MM"
    },
    {
        "label": "NMS",
        "value": "NMS",
        "iso": "MM"
    },
    {
        "label": "NMT",
        "value": "NMT",
        "iso": "MM"
    },
    {
        "label": "PAA",
        "value": "PAA",
        "iso": "MM"
    },
    {
        "label": "PAU",
        "value": "PAU",
        "iso": "MM"
    },
    {
        "label": "BSX",
        "value": "BSX",
        "iso": "MM"
    },
    {
        "label": "PPU",
        "value": "PPU",
        "iso": "MM"
    },
    {
        "label": "PBU",
        "value": "PBU",
        "iso": "MM"
    },
    {
        "label": "PKK",
        "value": "PKK",
        "iso": "MM"
    },
    {
        "label": "PRU",
        "value": "PRU",
        "iso": "MM"
    },
    {
        "label": "AKY",
        "value": "AKY",
        "iso": "MM"
    },
    {
        "label": "SNW",
        "value": "SNW",
        "iso": "MM"
    },
    {
        "label": "THL",
        "value": "THL",
        "iso": "MM"
    },
    {
        "label": "XYE",
        "value": "XYE",
        "iso": "MM"
    },
    {
        "label": "RGN",
        "value": "RGN",
        "iso": "MM"
    },
    {
        "label": "FBS",
        "value": "FBS",
        "iso": "US"
    },
    {
        "label": "UPG",
        "value": "UPG",
        "iso": "ID"
    },
    {
        "label": "MJU",
        "value": "MJU",
        "iso": "ID"
    },
    {
        "label": "BIK",
        "value": "BIK",
        "iso": "ID"
    },
    {
        "label": "ONI",
        "value": "ONI",
        "iso": "ID"
    },
    {
        "label": "WET",
        "value": "WET",
        "iso": "ID"
    },
    {
        "label": "NBX",
        "value": "NBX",
        "iso": "ID"
    },
    {
        "label": "ILA",
        "value": "ILA",
        "iso": "ID"
    },
    {
        "label": "KOX",
        "value": "KOX",
        "iso": "ID"
    },
    {
        "label": "ZRI",
        "value": "ZRI",
        "iso": "ID"
    },
    {
        "label": "TIM",
        "value": "TIM",
        "iso": "ID"
    },
    {
        "label": "EWI",
        "value": "EWI",
        "iso": "ID"
    },
    {
        "label": "AMI",
        "value": "AMI",
        "iso": "ID"
    },
    {
        "label": "BMU",
        "value": "BMU",
        "iso": "ID"
    },
    {
        "label": "DPS",
        "value": "DPS",
        "iso": "ID"
    },
    {
        "label": "LOP",
        "value": "LOP",
        "iso": "ID"
    },
    {
        "label": "SWQ",
        "value": "SWQ",
        "iso": "ID"
    },
    {
        "label": "TMC",
        "value": "TMC",
        "iso": "ID"
    },
    {
        "label": "WGP",
        "value": "WGP",
        "iso": "ID"
    },
    {
        "label": "ARJ",
        "value": "ARJ",
        "iso": "ID"
    },
    {
        "label": "BUI",
        "value": "BUI",
        "iso": "ID"
    },
    {
        "label": "ZRM",
        "value": "ZRM",
        "iso": "ID"
    },
    {
        "label": "DJJ",
        "value": "DJJ",
        "iso": "ID"
    },
    {
        "label": "LHI",
        "value": "LHI",
        "iso": "ID"
    },
    {
        "label": "LII",
        "value": "LII",
        "iso": "ID"
    },
    {
        "label": "OKL",
        "value": "OKL",
        "iso": "ID"
    },
    {
        "label": "WAR",
        "value": "WAR",
        "iso": "ID"
    },
    {
        "label": "SEH",
        "value": "SEH",
        "iso": "ID"
    },
    {
        "label": "UBR",
        "value": "UBR",
        "iso": "ID"
    },
    {
        "label": "WMX",
        "value": "WMX",
        "iso": "ID"
    },
    {
        "label": "MDP",
        "value": "MDP",
        "iso": "ID"
    },
    {
        "label": "BXD",
        "value": "BXD",
        "iso": "ID"
    },
    {
        "label": "MKQ",
        "value": "MKQ",
        "iso": "ID"
    },
    {
        "label": "OKQ",
        "value": "OKQ",
        "iso": "ID"
    },
    {
        "label": "KEI",
        "value": "KEI",
        "iso": "ID"
    },
    {
        "label": "TMH",
        "value": "TMH",
        "iso": "ID"
    },
    {
        "label": "TJS",
        "value": "TJS",
        "iso": "ID"
    },
    {
        "label": "DTD",
        "value": "DTD",
        "iso": "ID"
    },
    {
        "label": "BEJ",
        "value": "BEJ",
        "iso": "ID"
    },
    {
        "label": "BPN",
        "value": "BPN",
        "iso": "ID"
    },
    {
        "label": "TRK",
        "value": "TRK",
        "iso": "ID"
    },
    {
        "label": "SRI",
        "value": "SRI",
        "iso": "ID"
    },
    {
        "label": "TSX",
        "value": "TSX",
        "iso": "ID"
    },
    {
        "label": "BYQ",
        "value": "BYQ",
        "iso": "ID"
    },
    {
        "label": "GLX",
        "value": "GLX",
        "iso": "ID"
    },
    {
        "label": "GTO",
        "value": "GTO",
        "iso": "ID"
    },
    {
        "label": "NAH",
        "value": "NAH",
        "iso": "ID"
    },
    {
        "label": "TLI",
        "value": "TLI",
        "iso": "ID"
    },
    {
        "label": "GEB",
        "value": "GEB",
        "iso": "ID"
    },
    {
        "label": "KAZ",
        "value": "KAZ",
        "iso": "ID"
    },
    {
        "label": "PLW",
        "value": "PLW",
        "iso": "ID"
    },
    {
        "label": "MDC",
        "value": "MDC",
        "iso": "ID"
    },
    {
        "label": "MNA",
        "value": "MNA",
        "iso": "ID"
    },
    {
        "label": "PSJ",
        "value": "PSJ",
        "iso": "ID"
    },
    {
        "label": "OTI",
        "value": "OTI",
        "iso": "ID"
    },
    {
        "label": "TTE",
        "value": "TTE",
        "iso": "ID"
    },
    {
        "label": "LUW",
        "value": "LUW",
        "iso": "ID"
    },
    {
        "label": "UOL",
        "value": "UOL",
        "iso": "ID"
    },
    {
        "label": "BTW",
        "value": "BTW",
        "iso": "ID"
    },
    {
        "label": "PKN",
        "value": "PKN",
        "iso": "ID"
    },
    {
        "label": "KBU",
        "value": "KBU",
        "iso": "ID"
    },
    {
        "label": "MTW",
        "value": "MTW",
        "iso": "ID"
    },
    {
        "label": "TJG",
        "value": "TJG",
        "iso": "ID"
    },
    {
        "label": "BDJ",
        "value": "BDJ",
        "iso": "ID"
    },
    {
        "label": "PKY",
        "value": "PKY",
        "iso": "ID"
    },
    {
        "label": "SMQ",
        "value": "SMQ",
        "iso": "ID"
    },
    {
        "label": "AHI",
        "value": "AHI",
        "iso": "ID"
    },
    {
        "label": "DOB",
        "value": "DOB",
        "iso": "ID"
    },
    {
        "label": "MAL",
        "value": "MAL",
        "iso": "ID"
    },
    {
        "label": "NRE",
        "value": "NRE",
        "iso": "ID"
    },
    {
        "label": "LAH",
        "value": "LAH",
        "iso": "ID"
    },
    {
        "label": "SXK",
        "value": "SXK",
        "iso": "ID"
    },
    {
        "label": "BJK",
        "value": "BJK",
        "iso": "ID"
    },
    {
        "label": "LUV",
        "value": "LUV",
        "iso": "ID"
    },
    {
        "label": "SQN",
        "value": "SQN",
        "iso": "ID"
    },
    {
        "label": "AMQ",
        "value": "AMQ",
        "iso": "ID"
    },
    {
        "label": "NAM",
        "value": "NAM",
        "iso": "ID"
    },
    {
        "label": "TAX",
        "value": "TAX",
        "iso": "ID"
    },
    {
        "label": "MLG",
        "value": "MLG",
        "iso": "ID"
    },
    {
        "label": "CPF",
        "value": "CPF",
        "iso": "ID"
    },
    {
        "label": "JOG",
        "value": "JOG",
        "iso": "ID"
    },
    {
        "label": "SOC",
        "value": "SOC",
        "iso": "ID"
    },
    {
        "label": "SUB",
        "value": "SUB",
        "iso": "ID"
    },
    {
        "label": "SRG",
        "value": "SRG",
        "iso": "ID"
    },
    {
        "label": "SUP",
        "value": "SUP",
        "iso": "ID"
    },
    {
        "label": "NTI",
        "value": "NTI",
        "iso": "ID"
    },
    {
        "label": "RSK",
        "value": "RSK",
        "iso": "ID"
    },
    {
        "label": "KEQ",
        "value": "KEQ",
        "iso": "ID"
    },
    {
        "label": "FKQ",
        "value": "FKQ",
        "iso": "ID"
    },
    {
        "label": "INX",
        "value": "INX",
        "iso": "ID"
    },
    {
        "label": "KNG",
        "value": "KNG",
        "iso": "ID"
    },
    {
        "label": "RDE",
        "value": "RDE",
        "iso": "ID"
    },
    {
        "label": "BXB",
        "value": "BXB",
        "iso": "ID"
    },
    {
        "label": "MKW",
        "value": "MKW",
        "iso": "ID"
    },
    {
        "label": "SOQ",
        "value": "SOQ",
        "iso": "ID"
    },
    {
        "label": "TXM",
        "value": "TXM",
        "iso": "ID"
    },
    {
        "label": "WSR",
        "value": "WSR",
        "iso": "ID"
    },
    {
        "label": "BJW",
        "value": "BJW",
        "iso": "ID"
    },
    {
        "label": "MOF",
        "value": "MOF",
        "iso": "ID"
    },
    {
        "label": "ENE",
        "value": "ENE",
        "iso": "ID"
    },
    {
        "label": "RTG",
        "value": "RTG",
        "iso": "ID"
    },
    {
        "label": "ARD",
        "value": "ARD",
        "iso": "ID"
    },
    {
        "label": "LBJ",
        "value": "LBJ",
        "iso": "ID"
    },
    {
        "label": "KOE",
        "value": "KOE",
        "iso": "ID"
    },
    {
        "label": "BUW",
        "value": "BUW",
        "iso": "ID"
    },
    {
        "label": "MXB",
        "value": "MXB",
        "iso": "ID"
    },
    {
        "label": "SQR",
        "value": "SQR",
        "iso": "ID"
    },
    {
        "label": "TTR",
        "value": "TTR",
        "iso": "ID"
    },
    {
        "label": "KDI",
        "value": "KDI",
        "iso": "ID"
    },
    {
        "label": "BTU",
        "value": "BTU",
        "iso": "MY"
    },
    {
        "label": "BLG",
        "value": "BLG",
        "iso": "MY"
    },
    {
        "label": "LSM",
        "value": "LSM",
        "iso": "MY"
    },
    {
        "label": "LGL",
        "value": "LGL",
        "iso": "MY"
    },
    {
        "label": "KCH",
        "value": "KCH",
        "iso": "MY"
    },
    {
        "label": "ODN",
        "value": "ODN",
        "iso": "MY"
    },
    {
        "label": "LMN",
        "value": "LMN",
        "iso": "MY"
    },
    {
        "label": "MKM",
        "value": "MKM",
        "iso": "MY"
    },
    {
        "label": "LKH",
        "value": "LKH",
        "iso": "MY"
    },
    {
        "label": "MUR",
        "value": "MUR",
        "iso": "MY"
    },
    {
        "label": "BSE",
        "value": "BSE",
        "iso": "MY"
    },
    {
        "label": "KPI",
        "value": "KPI",
        "iso": "MY"
    },
    {
        "label": "BKM",
        "value": "BKM",
        "iso": "MY"
    },
    {
        "label": "MYY",
        "value": "MYY",
        "iso": "MY"
    },
    {
        "label": "SBW",
        "value": "SBW",
        "iso": "MY"
    },
    {
        "label": "TGC",
        "value": "TGC",
        "iso": "MY"
    },
    {
        "label": "LSU",
        "value": "LSU",
        "iso": "MY"
    },
    {
        "label": "LWY",
        "value": "LWY",
        "iso": "MY"
    },
    {
        "label": "SGG",
        "value": "SGG",
        "iso": "MY"
    },
    {
        "label": "BBN",
        "value": "BBN",
        "iso": "MY"
    },
    {
        "label": "SMM",
        "value": "SMM",
        "iso": "MY"
    },
    {
        "label": "LDU",
        "value": "LDU",
        "iso": "MY"
    },
    {
        "label": "TEL",
        "value": "TEL",
        "iso": "MY"
    },
    {
        "label": "KGU",
        "value": "KGU",
        "iso": "MY"
    },
    {
        "label": "SXS",
        "value": "SXS",
        "iso": "MY"
    },
    {
        "label": "BKI",
        "value": "BKI",
        "iso": "MY"
    },
    {
        "label": "LBU",
        "value": "LBU",
        "iso": "MY"
    },
    {
        "label": "TMG",
        "value": "TMG",
        "iso": "MY"
    },
    {
        "label": "GSA",
        "value": "GSA",
        "iso": "MY"
    },
    {
        "label": "SPE",
        "value": "SPE",
        "iso": "MY"
    },
    {
        "label": "PAY",
        "value": "PAY",
        "iso": "MY"
    },
    {
        "label": "RNU",
        "value": "RNU",
        "iso": "MY"
    },
    {
        "label": "SDK",
        "value": "SDK",
        "iso": "MY"
    },
    {
        "label": "KUD",
        "value": "KUD",
        "iso": "MY"
    },
    {
        "label": "TWU",
        "value": "TWU",
        "iso": "MY"
    },
    {
        "label": "MZV",
        "value": "MZV",
        "iso": "MY"
    },
    {
        "label": "BWN",
        "value": "BWN",
        "iso": "BN"
    },
    {
        "label": "TKG",
        "value": "TKG",
        "iso": "ID"
    },
    {
        "label": "PKU",
        "value": "PKU",
        "iso": "ID"
    },
    {
        "label": "DUM",
        "value": "DUM",
        "iso": "ID"
    },
    {
        "label": "RKO",
        "value": "RKO",
        "iso": "ID"
    },
    {
        "label": "SEQ",
        "value": "SEQ",
        "iso": "ID"
    },
    {
        "label": "TJB",
        "value": "TJB",
        "iso": "ID"
    },
    {
        "label": "BDO",
        "value": "BDO",
        "iso": "ID"
    },
    {
        "label": "CBN",
        "value": "CBN",
        "iso": "ID"
    },
    {
        "label": "TSY",
        "value": "TSY",
        "iso": "ID"
    },
    {
        "label": "BTH",
        "value": "BTH",
        "iso": "ID"
    },
    {
        "label": "PPR",
        "value": "PPR",
        "iso": "ID"
    },
    {
        "label": "TNJ",
        "value": "TNJ",
        "iso": "ID"
    },
    {
        "label": "SIQ",
        "value": "SIQ",
        "iso": "ID"
    },
    {
        "label": "HLP",
        "value": "HLP",
        "iso": "ID"
    },
    {
        "label": "CXP",
        "value": "CXP",
        "iso": "ID"
    },
    {
        "label": "PCB",
        "value": "PCB",
        "iso": "ID"
    },
    {
        "label": "CGK",
        "value": "CGK",
        "iso": "ID"
    },
    {
        "label": "LLG",
        "value": "LLG",
        "iso": "ID"
    },
    {
        "label": "GNS",
        "value": "GNS",
        "iso": "ID"
    },
    {
        "label": "AEG",
        "value": "AEG",
        "iso": "ID"
    },
    {
        "label": "PDG",
        "value": "PDG",
        "iso": "ID"
    },
    {
        "label": "MES",
        "value": "MES",
        "iso": "ID"
    },
    {
        "label": "TJQ",
        "value": "TJQ",
        "iso": "ID"
    },
    {
        "label": "NPO",
        "value": "NPO",
        "iso": "ID"
    },
    {
        "label": "KTG",
        "value": "KTG",
        "iso": "ID"
    },
    {
        "label": "MWK",
        "value": "MWK",
        "iso": "ID"
    },
    {
        "label": "NTX",
        "value": "NTX",
        "iso": "ID"
    },
    {
        "label": "PNK",
        "value": "PNK",
        "iso": "ID"
    },
    {
        "label": "PSU",
        "value": "PSU",
        "iso": "ID"
    },
    {
        "label": "SQG",
        "value": "SQG",
        "iso": "ID"
    },
    {
        "label": "DJB",
        "value": "DJB",
        "iso": "ID"
    },
    {
        "label": "PGK",
        "value": "PGK",
        "iso": "ID"
    },
    {
        "label": "BKS",
        "value": "BKS",
        "iso": "ID"
    },
    {
        "label": "PLM",
        "value": "PLM",
        "iso": "ID"
    },
    {
        "label": "PDO",
        "value": "PDO",
        "iso": "ID"
    },
    {
        "label": "RGT",
        "value": "RGT",
        "iso": "ID"
    },
    {
        "label": "PDG",
        "value": "PDG",
        "iso": "ID"
    },
    {
        "label": "MPC",
        "value": "MPC",
        "iso": "ID"
    },
    {
        "label": "KLQ",
        "value": "KLQ",
        "iso": "ID"
    },
    {
        "label": "TPK",
        "value": "TPK",
        "iso": "ID"
    },
    {
        "label": "SBG",
        "value": "SBG",
        "iso": "ID"
    },
    {
        "label": "MEQ",
        "value": "MEQ",
        "iso": "ID"
    },
    {
        "label": "LSX",
        "value": "LSX",
        "iso": "ID"
    },
    {
        "label": "LSW",
        "value": "LSW",
        "iso": "ID"
    },
    {
        "label": "BTJ",
        "value": "BTJ",
        "iso": "ID"
    },
    {
        "label": "SXT",
        "value": "SXT",
        "iso": "MY"
    },
    {
        "label": "MEP",
        "value": "MEP",
        "iso": "MY"
    },
    {
        "label": "SWY",
        "value": "SWY",
        "iso": "MY"
    },
    {
        "label": "TPG",
        "value": "TPG",
        "iso": "MY"
    },
    {
        "label": "TOD",
        "value": "TOD",
        "iso": "MY"
    },
    {
        "label": "AOR",
        "value": "AOR",
        "iso": "MY"
    },
    {
        "label": "BWH",
        "value": "BWH",
        "iso": "MY"
    },
    {
        "label": "KBR",
        "value": "KBR",
        "iso": "MY"
    },
    {
        "label": "KUA",
        "value": "KUA",
        "iso": "MY"
    },
    {
        "label": "KTE",
        "value": "KTE",
        "iso": "MY"
    },
    {
        "label": "IPH",
        "value": "IPH",
        "iso": "MY"
    },
    {
        "label": "JHB",
        "value": "JHB",
        "iso": "MY"
    },
    {
        "label": "KUL",
        "value": "KUL",
        "iso": "MY"
    },
    {
        "label": "LGK",
        "value": "LGK",
        "iso": "MY"
    },
    {
        "label": "MKZ",
        "value": "MKZ",
        "iso": "MY"
    },
    {
        "label": "TGG",
        "value": "TGG",
        "iso": "MY"
    },
    {
        "label": "PEN",
        "value": "PEN",
        "iso": "MY"
    },
    {
        "label": "PKG",
        "value": "PKG",
        "iso": "MY"
    },
    {
        "label": "RDN",
        "value": "RDN",
        "iso": "MY"
    },
    {
        "label": "SZB",
        "value": "SZB",
        "iso": "MY"
    },
    {
        "label": "AUT",
        "value": "AUT",
        "iso": "TL"
    },
    {
        "label": "UAI",
        "value": "UAI",
        "iso": "TL"
    },
    {
        "label": "DIC",
        "value": "DIC",
        "iso": "TL"
    },
    {
        "label": "DIL",
        "value": "DIL",
        "iso": "TL"
    },
    {
        "label": "BCH",
        "value": "BCH",
        "iso": "TL"
    },
    {
        "label": "MPT",
        "value": "MPT",
        "iso": "TL"
    },
    {
        "label": "OEC",
        "value": "OEC",
        "iso": "TL"
    },
    {
        "label": "VIQ",
        "value": "VIQ",
        "iso": "TL"
    },
    {
        "label": "ABU",
        "value": "ABU",
        "iso": "ID"
    },
    {
        "label": "BJW",
        "value": "BJW",
        "iso": "ID"
    },
    {
        "label": "LKA",
        "value": "LKA",
        "iso": "ID"
    },
    {
        "label": "SAU",
        "value": "SAU",
        "iso": "ID"
    },
    {
        "label": "SGQ",
        "value": "SGQ",
        "iso": "ID"
    },
    {
        "label": "LBW",
        "value": "LBW",
        "iso": "ID"
    },
    {
        "label": "BXT",
        "value": "BXT",
        "iso": "ID"
    },
    {
        "label": "NNX",
        "value": "NNX",
        "iso": "ID"
    },
    {
        "label": "TNB",
        "value": "TNB",
        "iso": "ID"
    },
    {
        "label": "LPU",
        "value": "LPU",
        "iso": "ID"
    },
    {
        "label": "SZH",
        "value": "SZH",
        "iso": "ID"
    },
    {
        "label": "QPG",
        "value": "QPG",
        "iso": "SG"
    },
    {
        "label": "TGA",
        "value": "TGA",
        "iso": "SG"
    },
    {
        "label": "XSP",
        "value": "XSP",
        "iso": "SG"
    },
    {
        "label": "SIN",
        "value": "SIN",
        "iso": "SG"
    },
    {
        "label": "WWT",
        "value": "WWT",
        "iso": "US"
    },
    {
        "label": "BCD",
        "value": "BCD",
        "iso": "PH"
    },
    {
        "label": "MNI",
        "value": "MNI",
        "iso": "MS"
    },
    {
        "label": "SKL",
        "value": "SKL",
        "iso": "GB"
    },
    {
        "label": "MUC",
        "value": "MUC",
        "iso": "DE"
    },
    {
        "label": "ALH",
        "value": "ALH",
        "iso": "AU"
    },
    {
        "label": "ABG",
        "value": "ABG",
        "iso": "AU"
    },
    {
        "label": "AWN",
        "value": "AWN",
        "iso": "AU"
    },
    {
        "label": "AUD",
        "value": "AUD",
        "iso": "AU"
    },
    {
        "label": "MRP",
        "value": "MRP",
        "iso": "AU"
    },
    {
        "label": "AXL",
        "value": "AXL",
        "iso": "AU"
    },
    {
        "label": "AXC",
        "value": "AXC",
        "iso": "AU"
    },
    {
        "label": "ADO",
        "value": "ADO",
        "iso": "AU"
    },
    {
        "label": "AMX",
        "value": "AMX",
        "iso": "AU"
    },
    {
        "label": "AMT",
        "value": "AMT",
        "iso": "AU"
    },
    {
        "label": "AYL",
        "value": "AYL",
        "iso": "AU"
    },
    {
        "label": "ABH",
        "value": "ABH",
        "iso": "AU"
    },
    {
        "label": "ARY",
        "value": "ARY",
        "iso": "AU"
    },
    {
        "label": "GYL",
        "value": "GYL",
        "iso": "AU"
    },
    {
        "label": "ARM",
        "value": "ARM",
        "iso": "AU"
    },
    {
        "label": "AAB",
        "value": "AAB",
        "iso": "AU"
    },
    {
        "label": "AUU",
        "value": "AUU",
        "iso": "AU"
    },
    {
        "label": "AWP",
        "value": "AWP",
        "iso": "AU"
    },
    {
        "label": "AVG",
        "value": "AVG",
        "iso": "AU"
    },
    {
        "label": "AYQ",
        "value": "AYQ",
        "iso": "AU"
    },
    {
        "label": "AYR",
        "value": "AYR",
        "iso": "AU"
    },
    {
        "label": "ACF",
        "value": "ACF",
        "iso": "AU"
    },
    {
        "label": "ABM",
        "value": "ABM",
        "iso": "AU"
    },
    {
        "label": "BCI",
        "value": "BCI",
        "iso": "AU"
    },
    {
        "label": "ASP",
        "value": "ASP",
        "iso": "AU"
    },
    {
        "label": "BDD",
        "value": "BDD",
        "iso": "AU"
    },
    {
        "label": "BBE",
        "value": "BBE",
        "iso": "AU"
    },
    {
        "label": "BNE",
        "value": "BNE",
        "iso": "AU"
    },
    {
        "label": "OOL",
        "value": "OOL",
        "iso": "AU"
    },
    {
        "label": "BKQ",
        "value": "BKQ",
        "iso": "AU"
    },
    {
        "label": "CNS",
        "value": "CNS",
        "iso": "AU"
    },
    {
        "label": "CTL",
        "value": "CTL",
        "iso": "AU"
    },
    {
        "label": "BXG",
        "value": "BXG",
        "iso": "AU"
    },
    {
        "label": "BVI",
        "value": "BVI",
        "iso": "AU"
    },
    {
        "label": "BTX",
        "value": "BTX",
        "iso": "AU"
    },
    {
        "label": "BQW",
        "value": "BQW",
        "iso": "AU"
    },
    {
        "label": "BHQ",
        "value": "BHQ",
        "iso": "AU"
    },
    {
        "label": "HTI",
        "value": "HTI",
        "iso": "AU"
    },
    {
        "label": "BEU",
        "value": "BEU",
        "iso": "AU"
    },
    {
        "label": "BRK",
        "value": "BRK",
        "iso": "AU"
    },
    {
        "label": "BUC",
        "value": "BUC",
        "iso": "AU"
    },
    {
        "label": "BLN",
        "value": "BLN",
        "iso": "AU"
    },
    {
        "label": "LCN",
        "value": "LCN",
        "iso": "AU"
    },
    {
        "label": "BLS",
        "value": "BLS",
        "iso": "AU"
    },
    {
        "label": "BQB",
        "value": "BQB",
        "iso": "AU"
    },
    {
        "label": "ISA",
        "value": "ISA",
        "iso": "AU"
    },
    {
        "label": "MCY",
        "value": "MCY",
        "iso": "AU"
    },
    {
        "label": "MKY",
        "value": "MKY",
        "iso": "AU"
    },
    {
        "label": "BNK",
        "value": "BNK",
        "iso": "AU"
    },
    {
        "label": "BSJ",
        "value": "BSJ",
        "iso": "AU"
    },
    {
        "label": "GIC",
        "value": "GIC",
        "iso": "AU"
    },
    {
        "label": "OKY",
        "value": "OKY",
        "iso": "AU"
    },
    {
        "label": "BQL",
        "value": "BQL",
        "iso": "AU"
    },
    {
        "label": "BMP",
        "value": "BMP",
        "iso": "AU"
    },
    {
        "label": "PPP",
        "value": "PPP",
        "iso": "AU"
    },
    {
        "label": "ROK",
        "value": "ROK",
        "iso": "AU"
    },
    {
        "label": "BOX",
        "value": "BOX",
        "iso": "AU"
    },
    {
        "label": "BME",
        "value": "BME",
        "iso": "AU"
    },
    {
        "label": "BZD",
        "value": "BZD",
        "iso": "AU"
    },
    {
        "label": "BWQ",
        "value": "BWQ",
        "iso": "AU"
    },
    {
        "label": "BHS",
        "value": "BHS",
        "iso": "AU"
    },
    {
        "label": "BRT",
        "value": "BRT",
        "iso": "AU"
    },
    {
        "label": "TSV",
        "value": "TSV",
        "iso": "AU"
    },
    {
        "label": "BLT",
        "value": "BLT",
        "iso": "AU"
    },
    {
        "label": "BDB",
        "value": "BDB",
        "iso": "AU"
    },
    {
        "label": "BUY",
        "value": "BUY",
        "iso": "AU"
    },
    {
        "label": "ZBO",
        "value": "ZBO",
        "iso": "AU"
    },
    {
        "label": "WEI",
        "value": "WEI",
        "iso": "AU"
    },
    {
        "label": "BWB",
        "value": "BWB",
        "iso": "AU"
    },
    {
        "label": "BVZ",
        "value": "BVZ",
        "iso": "AU"
    },
    {
        "label": "CGV",
        "value": "CGV",
        "iso": "AU"
    },
    {
        "label": "CLH",
        "value": "CLH",
        "iso": "AU"
    },
    {
        "label": "CVQ",
        "value": "CVQ",
        "iso": "AU"
    },
    {
        "label": "CSI",
        "value": "CSI",
        "iso": "AU"
    },
    {
        "label": "CAZ",
        "value": "CAZ",
        "iso": "AU"
    },
    {
        "label": "COJ",
        "value": "COJ",
        "iso": "AU"
    },
    {
        "label": "CBY",
        "value": "CBY",
        "iso": "AU"
    },
    {
        "label": "CBI",
        "value": "CBI",
        "iso": "AU"
    },
    {
        "label": "CPD",
        "value": "CPD",
        "iso": "AU"
    },
    {
        "label": "CRB",
        "value": "CRB",
        "iso": "AU"
    },
    {
        "label": "CCL",
        "value": "CCL",
        "iso": "AU"
    },
    {
        "label": "CNC",
        "value": "CNC",
        "iso": "AU"
    },
    {
        "label": "CNJ",
        "value": "CNJ",
        "iso": "AU"
    },
    {
        "label": "CBX",
        "value": "CBX",
        "iso": "AU"
    },
    {
        "label": "CUD",
        "value": "CUD",
        "iso": "AU"
    },
    {
        "label": "CED",
        "value": "CED",
        "iso": "AU"
    },
    {
        "label": "CVC",
        "value": "CVC",
        "iso": "AU"
    },
    {
        "label": "CFI",
        "value": "CFI",
        "iso": "AU"
    },
    {
        "label": "LLG",
        "value": "LLG",
        "iso": "AU"
    },
    {
        "label": "CXT",
        "value": "CXT",
        "iso": "AU"
    },
    {
        "label": "CKI",
        "value": "CKI",
        "iso": "AU"
    },
    {
        "label": "CTN",
        "value": "CTN",
        "iso": "AU"
    },
    {
        "label": "CMQ",
        "value": "CMQ",
        "iso": "AU"
    },
    {
        "label": "CMA",
        "value": "CMA",
        "iso": "AU"
    },
    {
        "label": "CML",
        "value": "CML",
        "iso": "AU"
    },
    {
        "label": "NIF",
        "value": "NIF",
        "iso": "AU"
    },
    {
        "label": "CES",
        "value": "CES",
        "iso": "AU"
    },
    {
        "label": "CNB",
        "value": "CNB",
        "iso": "AU"
    },
    {
        "label": "ODL",
        "value": "ODL",
        "iso": "AU"
    },
    {
        "label": "CUQ",
        "value": "CUQ",
        "iso": "AU"
    },
    {
        "label": "OOM",
        "value": "OOM",
        "iso": "AU"
    },
    {
        "label": "CDA",
        "value": "CDA",
        "iso": "AU"
    },
    {
        "label": "CWW",
        "value": "CWW",
        "iso": "AU"
    },
    {
        "label": "CYG",
        "value": "CYG",
        "iso": "AU"
    },
    {
        "label": "CDQ",
        "value": "CDQ",
        "iso": "AU"
    },
    {
        "label": "KCE",
        "value": "KCE",
        "iso": "AU"
    },
    {
        "label": "CMD",
        "value": "CMD",
        "iso": "AU"
    },
    {
        "label": "CUG",
        "value": "CUG",
        "iso": "AU"
    },
    {
        "label": "CUY",
        "value": "CUY",
        "iso": "AU"
    },
    {
        "label": "CWR",
        "value": "CWR",
        "iso": "AU"
    },
    {
        "label": "CCW",
        "value": "CCW",
        "iso": "AU"
    },
    {
        "label": "CWT",
        "value": "CWT",
        "iso": "AU"
    },
    {
        "label": "DJR",
        "value": "DJR",
        "iso": "AU"
    },
    {
        "label": "DBY",
        "value": "DBY",
        "iso": "AU"
    },
    {
        "label": "DRN",
        "value": "DRN",
        "iso": "AU"
    },
    {
        "label": "DNB",
        "value": "DNB",
        "iso": "AU"
    },
    {
        "label": "DRB",
        "value": "DRB",
        "iso": "AU"
    },
    {
        "label": "DFP",
        "value": "DFP",
        "iso": "AU"
    },
    {
        "label": "DLK",
        "value": "DLK",
        "iso": "AU"
    },
    {
        "label": "DDN",
        "value": "DDN",
        "iso": "AU"
    },
    {
        "label": "DLV",
        "value": "DLV",
        "iso": "AU"
    },
    {
        "label": "DMD",
        "value": "DMD",
        "iso": "AU"
    },
    {
        "label": "DVR",
        "value": "DVR",
        "iso": "AU"
    },
    {
        "label": "NLF",
        "value": "NLF",
        "iso": "AU"
    },
    {
        "label": "DRD",
        "value": "DRD",
        "iso": "AU"
    },
    {
        "label": "DVP",
        "value": "DVP",
        "iso": "AU"
    },
    {
        "label": "DPO",
        "value": "DPO",
        "iso": "AU"
    },
    {
        "label": "DHD",
        "value": "DHD",
        "iso": "AU"
    },
    {
        "label": "DRR",
        "value": "DRR",
        "iso": "AU"
    },
    {
        "label": "DKV",
        "value": "DKV",
        "iso": "AU"
    },
    {
        "label": "DYA",
        "value": "DYA",
        "iso": "AU"
    },
    {
        "label": "WDA",
        "value": "WDA",
        "iso": "YE"
    },
    {
        "label": "ECH",
        "value": "ECH",
        "iso": "AU"
    },
    {
        "label": "EUC",
        "value": "EUC",
        "iso": "AU"
    },
    {
        "label": "ETD",
        "value": "ETD",
        "iso": "AU"
    },
    {
        "label": "ENB",
        "value": "ENB",
        "iso": "AU"
    },
    {
        "label": "EIH",
        "value": "EIH",
        "iso": "AU"
    },
    {
        "label": "ELC",
        "value": "ELC",
        "iso": "AU"
    },
    {
        "label": "EMD",
        "value": "EMD",
        "iso": "AU"
    },
    {
        "label": "ERB",
        "value": "ERB",
        "iso": "AU"
    },
    {
        "label": "EPR",
        "value": "EPR",
        "iso": "AU"
    },
    {
        "label": "EVD",
        "value": "EVD",
        "iso": "AU"
    },
    {
        "label": "EVH",
        "value": "EVH",
        "iso": "AU"
    },
    {
        "label": "EXM",
        "value": "EXM",
        "iso": "AU"
    },
    {
        "label": "FRB",
        "value": "FRB",
        "iso": "AU"
    },
    {
        "label": "KFE",
        "value": "KFE",
        "iso": "AU"
    },
    {
        "label": "FLY",
        "value": "FLY",
        "iso": "AU"
    },
    {
        "label": "FLS",
        "value": "FLS",
        "iso": "AU"
    },
    {
        "label": "FVL",
        "value": "FVL",
        "iso": "AU"
    },
    {
        "label": "FIK",
        "value": "FIK",
        "iso": "AU"
    },
    {
        "label": "FOS",
        "value": "FOS",
        "iso": "AU"
    },
    {
        "label": "FOT",
        "value": "FOT",
        "iso": "AU"
    },
    {
        "label": "FIZ",
        "value": "FIZ",
        "iso": "AU"
    },
    {
        "label": "GAH",
        "value": "GAH",
        "iso": "AU"
    },
    {
        "label": "GBL",
        "value": "GBL",
        "iso": "AU"
    },
    {
        "label": "GUH",
        "value": "GUH",
        "iso": "AU"
    },
    {
        "label": "GOO",
        "value": "GOO",
        "iso": "AU"
    },
    {
        "label": "GDD",
        "value": "GDD",
        "iso": "AU"
    },
    {
        "label": "GGD",
        "value": "GGD",
        "iso": "AU"
    },
    {
        "label": "GTS",
        "value": "GTS",
        "iso": "AU"
    },
    {
        "label": "GET",
        "value": "GET",
        "iso": "AU"
    },
    {
        "label": "GFN",
        "value": "GFN",
        "iso": "AU"
    },
    {
        "label": "GBV",
        "value": "GBV",
        "iso": "AU"
    },
    {
        "label": "GLT",
        "value": "GLT",
        "iso": "AU"
    },
    {
        "label": "GUL",
        "value": "GUL",
        "iso": "AU"
    },
    {
        "label": "GLG",
        "value": "GLG",
        "iso": "AU"
    },
    {
        "label": "GEX",
        "value": "GEX",
        "iso": "AU"
    },
    {
        "label": "GLI",
        "value": "GLI",
        "iso": "AU"
    },
    {
        "label": "GLM",
        "value": "GLM",
        "iso": "AU"
    },
    {
        "label": "GVP",
        "value": "GVP",
        "iso": "AU"
    },
    {
        "label": "GPN",
        "value": "GPN",
        "iso": "AU"
    },
    {
        "label": "GTE",
        "value": "GTE",
        "iso": "AU"
    },
    {
        "label": "GFF",
        "value": "GFF",
        "iso": "AU"
    },
    {
        "label": "GTT",
        "value": "GTT",
        "iso": "AU"
    },
    {
        "label": "GEE",
        "value": "GEE",
        "iso": "AU"
    },
    {
        "label": "GYP",
        "value": "GYP",
        "iso": "AU"
    },
    {
        "label": "HWK",
        "value": "HWK",
        "iso": "AU"
    },
    {
        "label": "HXX",
        "value": "HXX",
        "iso": "AU"
    },
    {
        "label": "HVB",
        "value": "HVB",
        "iso": "AU"
    },
    {
        "label": "HUB",
        "value": "HUB",
        "iso": "AU"
    },
    {
        "label": "HIP",
        "value": "HIP",
        "iso": "AU"
    },
    {
        "label": "HID",
        "value": "HID",
        "iso": "AU"
    },
    {
        "label": "HCQ",
        "value": "HCQ",
        "iso": "AU"
    },
    {
        "label": "HMG",
        "value": "HMG",
        "iso": "AU"
    },
    {
        "label": "HLT",
        "value": "HLT",
        "iso": "AU"
    },
    {
        "label": "HOK",
        "value": "HOK",
        "iso": "AU"
    },
    {
        "label": "MHU",
        "value": "MHU",
        "iso": "AU"
    },
    {
        "label": "HTU",
        "value": "HTU",
        "iso": "AU"
    },
    {
        "label": "HSM",
        "value": "HSM",
        "iso": "AU"
    },
    {
        "label": "HGD",
        "value": "HGD",
        "iso": "AU"
    },
    {
        "label": "IDK",
        "value": "IDK",
        "iso": "AU"
    },
    {
        "label": "IFL",
        "value": "IFL",
        "iso": "AU"
    },
    {
        "label": "IFF",
        "value": "IFF",
        "iso": "AU"
    },
    {
        "label": "IGH",
        "value": "IGH",
        "iso": "AU"
    },
    {
        "label": "IKP",
        "value": "IKP",
        "iso": "AU"
    },
    {
        "label": "INJ",
        "value": "INJ",
        "iso": "AU"
    },
    {
        "label": "INM",
        "value": "INM",
        "iso": "AU"
    },
    {
        "label": "IVW",
        "value": "IVW",
        "iso": "AU"
    },
    {
        "label": "ISI",
        "value": "ISI",
        "iso": "AU"
    },
    {
        "label": "IVR",
        "value": "IVR",
        "iso": "AU"
    },
    {
        "label": "JAB",
        "value": "JAB",
        "iso": "AU"
    },
    {
        "label": "JUN",
        "value": "JUN",
        "iso": "AU"
    },
    {
        "label": "QJD",
        "value": "QJD",
        "iso": "AU"
    },
    {
        "label": "JCK",
        "value": "JCK",
        "iso": "AU"
    },
    {
        "label": "JUR",
        "value": "JUR",
        "iso": "AU"
    },
    {
        "label": "UBU",
        "value": "UBU",
        "iso": "AU"
    },
    {
        "label": "KAX",
        "value": "KAX",
        "iso": "AU"
    },
    {
        "label": "KBY",
        "value": "KBY",
        "iso": "AU"
    },
    {
        "label": "KCS",
        "value": "KCS",
        "iso": "AU"
    },
    {
        "label": "KRA",
        "value": "KRA",
        "iso": "AU"
    },
    {
        "label": "KNS",
        "value": "KNS",
        "iso": "AU"
    },
    {
        "label": "KBB",
        "value": "KBB",
        "iso": "AU"
    },
    {
        "label": "KFG",
        "value": "KFG",
        "iso": "AU"
    },
    {
        "label": "KKP",
        "value": "KKP",
        "iso": "AU"
    },
    {
        "label": "KRB",
        "value": "KRB",
        "iso": "AU"
    },
    {
        "label": "KML",
        "value": "KML",
        "iso": "AU"
    },
    {
        "label": "KPS",
        "value": "KPS",
        "iso": "AU"
    },
    {
        "label": "KNI",
        "value": "KNI",
        "iso": "AU"
    },
    {
        "label": "KWM",
        "value": "KWM",
        "iso": "AU"
    },
    {
        "label": "KGY",
        "value": "KGY",
        "iso": "AU"
    },
    {
        "label": "KGC",
        "value": "KGC",
        "iso": "AU"
    },
    {
        "label": "KUG",
        "value": "KUG",
        "iso": "AU"
    },
    {
        "label": "LWH",
        "value": "LWH",
        "iso": "AU"
    },
    {
        "label": "LGH",
        "value": "LGH",
        "iso": "AU"
    },
    {
        "label": "LNO",
        "value": "LNO",
        "iso": "AU"
    },
    {
        "label": "LEL",
        "value": "LEL",
        "iso": "AU"
    },
    {
        "label": "LDH",
        "value": "LDH",
        "iso": "AU"
    },
    {
        "label": "IRG",
        "value": "IRG",
        "iso": "AU"
    },
    {
        "label": "LTP",
        "value": "LTP",
        "iso": "AU"
    },
    {
        "label": "LDC",
        "value": "LDC",
        "iso": "AU"
    },
    {
        "label": "LSY",
        "value": "LSY",
        "iso": "AU"
    },
    {
        "label": "LOC",
        "value": "LOC",
        "iso": "AU"
    },
    {
        "label": "LOA",
        "value": "LOA",
        "iso": "AU"
    },
    {
        "label": "LTV",
        "value": "LTV",
        "iso": "AU"
    },
    {
        "label": "LUU",
        "value": "LUU",
        "iso": "AU"
    },
    {
        "label": "LHG",
        "value": "LHG",
        "iso": "AU"
    },
    {
        "label": "LRE",
        "value": "LRE",
        "iso": "AU"
    },
    {
        "label": "LUT",
        "value": "LUT",
        "iso": "AU"
    },
    {
        "label": "LER",
        "value": "LER",
        "iso": "AU"
    },
    {
        "label": "LVO",
        "value": "LVO",
        "iso": "AU"
    },
    {
        "label": "TGN",
        "value": "TGN",
        "iso": "AU"
    },
    {
        "label": "LZR",
        "value": "LZR",
        "iso": "AU"
    },
    {
        "label": "UBB",
        "value": "UBB",
        "iso": "AU"
    },
    {
        "label": "AVV",
        "value": "AVV",
        "iso": "AU"
    },
    {
        "label": "ABX",
        "value": "ABX",
        "iso": "AU"
    },
    {
        "label": "MRG",
        "value": "MRG",
        "iso": "AU"
    },
    {
        "label": "MBB",
        "value": "MBB",
        "iso": "AU"
    },
    {
        "label": "XMC",
        "value": "XMC",
        "iso": "AU"
    },
    {
        "label": "MFP",
        "value": "MFP",
        "iso": "AU"
    },
    {
        "label": "MLR",
        "value": "MLR",
        "iso": "AU"
    },
    {
        "label": "DGE",
        "value": "DGE",
        "iso": "AU"
    },
    {
        "label": "MQA",
        "value": "MQA",
        "iso": "AU"
    },
    {
        "label": "MKR",
        "value": "MKR",
        "iso": "AU"
    },
    {
        "label": "MEB",
        "value": "MEB",
        "iso": "AU"
    },
    {
        "label": "MIM",
        "value": "MIM",
        "iso": "AU"
    },
    {
        "label": "SXE",
        "value": "SXE",
        "iso": "AU"
    },
    {
        "label": "MGT",
        "value": "MGT",
        "iso": "AU"
    },
    {
        "label": "MNG",
        "value": "MNG",
        "iso": "AU"
    },
    {
        "label": "GSN",
        "value": "GSN",
        "iso": "AU"
    },
    {
        "label": "MGV",
        "value": "MGV",
        "iso": "AU"
    },
    {
        "label": "MQZ",
        "value": "MQZ",
        "iso": "AU"
    },
    {
        "label": "HBA",
        "value": "HBA",
        "iso": "AU"
    },
    {
        "label": "MHO",
        "value": "MHO",
        "iso": "AU"
    },
    {
        "label": "MCV",
        "value": "MCV",
        "iso": "AU"
    },
    {
        "label": "MQL",
        "value": "MQL",
        "iso": "AU"
    },
    {
        "label": "XML",
        "value": "XML",
        "iso": "AU"
    },
    {
        "label": "MIH",
        "value": "MIH",
        "iso": "AU"
    },
    {
        "label": "MTQ",
        "value": "MTQ",
        "iso": "AU"
    },
    {
        "label": "MJP",
        "value": "MJP",
        "iso": "AU"
    },
    {
        "label": "LST",
        "value": "LST",
        "iso": "AU"
    },
    {
        "label": "MBW",
        "value": "MBW",
        "iso": "AU"
    },
    {
        "label": "MEL",
        "value": "MEL",
        "iso": "AU"
    },
    {
        "label": "MMM",
        "value": "MMM",
        "iso": "AU"
    },
    {
        "label": "MTL",
        "value": "MTL",
        "iso": "AU"
    },
    {
        "label": "WME",
        "value": "WME",
        "iso": "AU"
    },
    {
        "label": "ONR",
        "value": "ONR",
        "iso": "AU"
    },
    {
        "label": "OXY",
        "value": "OXY",
        "iso": "AU"
    },
    {
        "label": "MMG",
        "value": "MMG",
        "iso": "AU"
    },
    {
        "label": "OOR",
        "value": "OOR",
        "iso": "AU"
    },
    {
        "label": "MRZ",
        "value": "MRZ",
        "iso": "AU"
    },
    {
        "label": "MET",
        "value": "MET",
        "iso": "AU"
    },
    {
        "label": "MIN",
        "value": "MIN",
        "iso": "AU"
    },
    {
        "label": "MQE",
        "value": "MQE",
        "iso": "AU"
    },
    {
        "label": "MOV",
        "value": "MOV",
        "iso": "AU"
    },
    {
        "label": "RRE",
        "value": "RRE",
        "iso": "AU"
    },
    {
        "label": "MWB",
        "value": "MWB",
        "iso": "AU"
    },
    {
        "label": "MYA",
        "value": "MYA",
        "iso": "AU"
    },
    {
        "label": "MTD",
        "value": "MTD",
        "iso": "AU"
    },
    {
        "label": "UTB",
        "value": "UTB",
        "iso": "AU"
    },
    {
        "label": "MGB",
        "value": "MGB",
        "iso": "AU"
    },
    {
        "label": "ONG",
        "value": "ONG",
        "iso": "AU"
    },
    {
        "label": "MNQ",
        "value": "MNQ",
        "iso": "AU"
    },
    {
        "label": "MUQ",
        "value": "MUQ",
        "iso": "AU"
    },
    {
        "label": "MNE",
        "value": "MNE",
        "iso": "AU"
    },
    {
        "label": "MYI",
        "value": "MYI",
        "iso": "AU"
    },
    {
        "label": "MVK",
        "value": "MVK",
        "iso": "AU"
    },
    {
        "label": "MXU",
        "value": "MXU",
        "iso": "AU"
    },
    {
        "label": "MXD",
        "value": "MXD",
        "iso": "AU"
    },
    {
        "label": "MBH",
        "value": "MBH",
        "iso": "AU"
    },
    {
        "label": "RTY",
        "value": "RTY",
        "iso": "AU"
    },
    {
        "label": "NMR",
        "value": "NMR",
        "iso": "AU"
    },
    {
        "label": "NRA",
        "value": "NRA",
        "iso": "AU"
    },
    {
        "label": "NAA",
        "value": "NAA",
        "iso": "AU"
    },
    {
        "label": "RPM",
        "value": "RPM",
        "iso": "AU"
    },
    {
        "label": "NBH",
        "value": "NBH",
        "iso": "AU"
    },
    {
        "label": "NLS",
        "value": "NLS",
        "iso": "AU"
    },
    {
        "label": "NAC",
        "value": "NAC",
        "iso": "AU"
    },
    {
        "label": "QRM",
        "value": "QRM",
        "iso": "AU"
    },
    {
        "label": "RVT",
        "value": "RVT",
        "iso": "AU"
    },
    {
        "label": "NSV",
        "value": "NSV",
        "iso": "AU"
    },
    {
        "label": "NSM",
        "value": "NSM",
        "iso": "AU"
    },
    {
        "label": "NTN",
        "value": "NTN",
        "iso": "AU"
    },
    {
        "label": "NUR",
        "value": "NUR",
        "iso": "AU"
    },
    {
        "label": "NLL",
        "value": "NLL",
        "iso": "AU"
    },
    {
        "label": "NUB",
        "value": "NUB",
        "iso": "AU"
    },
    {
        "label": "ZNE",
        "value": "ZNE",
        "iso": "AU"
    },
    {
        "label": "NYN",
        "value": "NYN",
        "iso": "AU"
    },
    {
        "label": "OPI",
        "value": "OPI",
        "iso": "AU"
    },
    {
        "label": "YOI",
        "value": "YOI",
        "iso": "CA"
    },
    {
        "label": "XCO",
        "value": "XCO",
        "iso": "AU"
    },
    {
        "label": "OLP",
        "value": "OLP",
        "iso": "AU"
    },
    {
        "label": "ODD",
        "value": "ODD",
        "iso": "AU"
    },
    {
        "label": "MOO",
        "value": "MOO",
        "iso": "AU"
    },
    {
        "label": "RBS",
        "value": "RBS",
        "iso": "AU"
    },
    {
        "label": "OAG",
        "value": "OAG",
        "iso": "AU"
    },
    {
        "label": "ODR",
        "value": "ODR",
        "iso": "AU"
    },
    {
        "label": "OSO",
        "value": "OSO",
        "iso": "AU"
    },
    {
        "label": "OYN",
        "value": "OYN",
        "iso": "AU"
    },
    {
        "label": "ADL",
        "value": "ADL",
        "iso": "AU"
    },
    {
        "label": "PUG",
        "value": "PUG",
        "iso": "AU"
    },
    {
        "label": "PMK",
        "value": "PMK",
        "iso": "AU"
    },
    {
        "label": "PBO",
        "value": "PBO",
        "iso": "AU"
    },
    {
        "label": "CCK",
        "value": "CCK",
        "iso": "CC"
    },
    {
        "label": "PDE",
        "value": "PDE",
        "iso": "AU"
    },
    {
        "label": "DRW",
        "value": "DRW",
        "iso": "AU"
    },
    {
        "label": "PRD",
        "value": "PRD",
        "iso": "AU"
    },
    {
        "label": "BEO",
        "value": "BEO",
        "iso": "AU"
    },
    {
        "label": "GOV",
        "value": "GOV",
        "iso": "AU"
    },
    {
        "label": "PPI",
        "value": "PPI",
        "iso": "AU"
    },
    {
        "label": "JAD",
        "value": "JAD",
        "iso": "AU"
    },
    {
        "label": "KTA",
        "value": "KTA",
        "iso": "AU"
    },
    {
        "label": "KGI",
        "value": "KGI",
        "iso": "AU"
    },
    {
        "label": "PKE",
        "value": "PKE",
        "iso": "AU"
    },
    {
        "label": "PKT",
        "value": "PKT",
        "iso": "AU"
    },
    {
        "label": "KNX",
        "value": "KNX",
        "iso": "AU"
    },
    {
        "label": "PLO",
        "value": "PLO",
        "iso": "AU"
    },
    {
        "label": "LEA",
        "value": "LEA",
        "iso": "AU"
    },
    {
        "label": "EDR",
        "value": "EDR",
        "iso": "AU"
    },
    {
        "label": "PQQ",
        "value": "PQQ",
        "iso": "AU"
    },
    {
        "label": "PTJ",
        "value": "PTJ",
        "iso": "AU"
    },
    {
        "label": "PHE",
        "value": "PHE",
        "iso": "AU"
    },
    {
        "label": "PER",
        "value": "PER",
        "iso": "AU"
    },
    {
        "label": "PEA",
        "value": "PEA",
        "iso": "AU"
    },
    {
        "label": "KTR",
        "value": "KTR",
        "iso": "AU"
    },
    {
        "label": "UMR",
        "value": "UMR",
        "iso": "AU"
    },
    {
        "label": "XCH",
        "value": "XCH",
        "iso": "CX"
    },
    {
        "label": "UIR",
        "value": "UIR",
        "iso": "AU"
    },
    {
        "label": "ULP",
        "value": "ULP",
        "iso": "AU"
    },
    {
        "label": "UEE",
        "value": "UEE",
        "iso": "AU"
    },
    {
        "label": "RMK",
        "value": "RMK",
        "iso": "AU"
    },
    {
        "label": "RCM",
        "value": "RCM",
        "iso": "AU"
    },
    {
        "label": "RAM",
        "value": "RAM",
        "iso": "AU"
    },
    {
        "label": "ROH",
        "value": "ROH",
        "iso": "AU"
    },
    {
        "label": "RBU",
        "value": "RBU",
        "iso": "AU"
    },
    {
        "label": "RBC",
        "value": "RBC",
        "iso": "AU"
    },
    {
        "label": "RMA",
        "value": "RMA",
        "iso": "AU"
    },
    {
        "label": "RSB",
        "value": "RSB",
        "iso": "AU"
    },
    {
        "label": "RTS",
        "value": "RTS",
        "iso": "AU"
    },
    {
        "label": "RTP",
        "value": "RTP",
        "iso": "AU"
    },
    {
        "label": "RHL",
        "value": "RHL",
        "iso": "AU"
    },
    {
        "label": "NDS",
        "value": "NDS",
        "iso": "AU"
    },
    {
        "label": "BWU",
        "value": "BWU",
        "iso": "AU"
    },
    {
        "label": "CBR",
        "value": "CBR",
        "iso": "AU"
    },
    {
        "label": "CFS",
        "value": "CFS",
        "iso": "AU"
    },
    {
        "label": "CDU",
        "value": "CDU",
        "iso": "AU"
    },
    {
        "label": "NSO",
        "value": "NSO",
        "iso": "AU"
    },
    {
        "label": "SQC",
        "value": "SQC",
        "iso": "AU"
    },
    {
        "label": "DBO",
        "value": "DBO",
        "iso": "AU"
    },
    {
        "label": "SGO",
        "value": "SGO",
        "iso": "AU"
    },
    {
        "label": "SIX",
        "value": "SIX",
        "iso": "AU"
    },
    {
        "label": "ZGL",
        "value": "ZGL",
        "iso": "AU"
    },
    {
        "label": "SGP",
        "value": "SGP",
        "iso": "AU"
    },
    {
        "label": "DNM",
        "value": "DNM",
        "iso": "AU"
    },
    {
        "label": "JHQ",
        "value": "JHQ",
        "iso": "AU"
    },
    {
        "label": "SHT",
        "value": "SHT",
        "iso": "AU"
    },
    {
        "label": "SBR",
        "value": "SBR",
        "iso": "AU"
    },
    {
        "label": "SIO",
        "value": "SIO",
        "iso": "AU"
    },
    {
        "label": "SHU",
        "value": "SHU",
        "iso": "AU"
    },
    {
        "label": "STH",
        "value": "STH",
        "iso": "AU"
    },
    {
        "label": "SNB",
        "value": "SNB",
        "iso": "AU"
    },
    {
        "label": "NLK",
        "value": "NLK",
        "iso": "NF"
    },
    {
        "label": "NOA",
        "value": "NOA",
        "iso": "AU"
    },
    {
        "label": "SNH",
        "value": "SNH",
        "iso": "AU"
    },
    {
        "label": "SCG",
        "value": "SCG",
        "iso": "AU"
    },
    {
        "label": "SHQ",
        "value": "SHQ",
        "iso": "AU"
    },
    {
        "label": "KSV",
        "value": "KSV",
        "iso": "AU"
    },
    {
        "label": "SRN",
        "value": "SRN",
        "iso": "AU"
    },
    {
        "label": "SYD",
        "value": "SYD",
        "iso": "AU"
    },
    {
        "label": "HLS",
        "value": "HLS",
        "iso": "AU"
    },
    {
        "label": "TMW",
        "value": "TMW",
        "iso": "AU"
    },
    {
        "label": "WGA",
        "value": "WGA",
        "iso": "AU"
    },
    {
        "label": "SWH",
        "value": "SWH",
        "iso": "AU"
    },
    {
        "label": "SWC",
        "value": "SWC",
        "iso": "AU"
    },
    {
        "label": "XTR",
        "value": "XTR",
        "iso": "AU"
    },
    {
        "label": "TBL",
        "value": "TBL",
        "iso": "AU"
    },
    {
        "label": "XTO",
        "value": "XTO",
        "iso": "AU"
    },
    {
        "label": "TAQ",
        "value": "TAQ",
        "iso": "AU"
    },
    {
        "label": "TBK",
        "value": "TBK",
        "iso": "AU"
    },
    {
        "label": "TDR",
        "value": "TDR",
        "iso": "AU"
    },
    {
        "label": "TEF",
        "value": "TEF",
        "iso": "AU"
    },
    {
        "label": "TEM",
        "value": "TEM",
        "iso": "AU"
    },
    {
        "label": "TAN",
        "value": "TAN",
        "iso": "AU"
    },
    {
        "label": "XTG",
        "value": "XTG",
        "iso": "AU"
    },
    {
        "label": "TYG",
        "value": "TYG",
        "iso": "AU"
    },
    {
        "label": "TYB",
        "value": "TYB",
        "iso": "AU"
    },
    {
        "label": "TKY",
        "value": "TKY",
        "iso": "AU"
    },
    {
        "label": "TUM",
        "value": "TUM",
        "iso": "AU"
    },
    {
        "label": "TYP",
        "value": "TYP",
        "iso": "AU"
    },
    {
        "label": "THG",
        "value": "THG",
        "iso": "AU"
    },
    {
        "label": "TCA",
        "value": "TCA",
        "iso": "AU"
    },
    {
        "label": "TCW",
        "value": "TCW",
        "iso": "AU"
    },
    {
        "label": "TRO",
        "value": "TRO",
        "iso": "AU"
    },
    {
        "label": "YTU",
        "value": "YTU",
        "iso": "CA"
    },
    {
        "label": "TWB",
        "value": "TWB",
        "iso": "AU"
    },
    {
        "label": "UDA",
        "value": "UDA",
        "iso": "AU"
    },
    {
        "label": "CZY",
        "value": "CZY",
        "iso": "AU"
    },
    {
        "label": "USL",
        "value": "USL",
        "iso": "AU"
    },
    {
        "label": "VCD",
        "value": "VCD",
        "iso": "AU"
    },
    {
        "label": "VNR",
        "value": "VNR",
        "iso": "AU"
    },
    {
        "label": "WLA",
        "value": "WLA",
        "iso": "AU"
    },
    {
        "label": "WAV",
        "value": "WAV",
        "iso": "AU"
    },
    {
        "label": "WMB",
        "value": "WMB",
        "iso": "AU"
    },
    {
        "label": "SYU",
        "value": "SYU",
        "iso": "AU"
    },
    {
        "label": "WIO",
        "value": "WIO",
        "iso": "AU"
    },
    {
        "label": "WLC",
        "value": "WLC",
        "iso": "AU"
    },
    {
        "label": "WAZ",
        "value": "WAZ",
        "iso": "AU"
    },
    {
        "label": "WND",
        "value": "WND",
        "iso": "AU"
    },
    {
        "label": "WNR",
        "value": "WNR",
        "iso": "AU"
    },
    {
        "label": "WGT",
        "value": "WGT",
        "iso": "AU"
    },
    {
        "label": "WYA",
        "value": "WYA",
        "iso": "AU"
    },
    {
        "label": "WIT",
        "value": "WIT",
        "iso": "AU"
    },
    {
        "label": "WKB",
        "value": "WKB",
        "iso": "AU"
    },
    {
        "label": "WGE",
        "value": "WGE",
        "iso": "AU"
    },
    {
        "label": "NTL",
        "value": "NTL",
        "iso": "AU"
    },
    {
        "label": "WUN",
        "value": "WUN",
        "iso": "AU"
    },
    {
        "label": "WPK",
        "value": "WPK",
        "iso": "AU"
    },
    {
        "label": "WDI",
        "value": "WDI",
        "iso": "AU"
    },
    {
        "label": "WOL",
        "value": "WOL",
        "iso": "AU"
    },
    {
        "label": "QRR",
        "value": "QRR",
        "iso": "AU"
    },
    {
        "label": "WLO",
        "value": "WLO",
        "iso": "AU"
    },
    {
        "label": "WIN",
        "value": "WIN",
        "iso": "AU"
    },
    {
        "label": "WUD",
        "value": "WUD",
        "iso": "AU"
    },
    {
        "label": "WEW",
        "value": "WEW",
        "iso": "AU"
    },
    {
        "label": "WRW",
        "value": "WRW",
        "iso": "AU"
    },
    {
        "label": "WWI",
        "value": "WWI",
        "iso": "AU"
    },
    {
        "label": "WYN",
        "value": "WYN",
        "iso": "AU"
    },
    {
        "label": "BWT",
        "value": "BWT",
        "iso": "AU"
    },
    {
        "label": "YLG",
        "value": "YLG",
        "iso": "AU"
    },
    {
        "label": "OKR",
        "value": "OKR",
        "iso": "AU"
    },
    {
        "label": "KYF",
        "value": "KYF",
        "iso": "AU"
    },
    {
        "label": "XMY",
        "value": "XMY",
        "iso": "AU"
    },
    {
        "label": "YUE",
        "value": "YUE",
        "iso": "AU"
    },
    {
        "label": "NGA",
        "value": "NGA",
        "iso": "AU"
    },
    {
        "label": "ORR",
        "value": "ORR",
        "iso": "AU"
    },
    {
        "label": "KYI",
        "value": "KYI",
        "iso": "AU"
    },
    {
        "label": "HPR",
        "value": "HPR",
        "iso": "ZA"
    },
    {
        "label": "ULX",
        "value": "ULX",
        "iso": "ZA"
    },
    {
        "label": "TDT",
        "value": "TDT",
        "iso": "ZA"
    },
    {
        "label": "HZV",
        "value": "HZV",
        "iso": "ZA"
    },
    {
        "label": "KHO",
        "value": "KHO",
        "iso": "ZA"
    },
    {
        "label": "MBM",
        "value": "MBM",
        "iso": "ZA"
    },
    {
        "label": "INY",
        "value": "INY",
        "iso": "ZA"
    },
    {
        "label": "TSD",
        "value": "TSD",
        "iso": "ZA"
    },
    {
        "label": "KIG",
        "value": "KIG",
        "iso": "ZA"
    },
    {
        "label": "PEK",
        "value": "PEK",
        "iso": "CN"
    },
    {
        "label": "CIF",
        "value": "CIF",
        "iso": "CN"
    },
    {
        "label": "CIH",
        "value": "CIH",
        "iso": "CN"
    },
    {
        "label": "DSN",
        "value": "DSN",
        "iso": "CN"
    },
    {
        "label": "DAT",
        "value": "DAT",
        "iso": "CN"
    },
    {
        "label": "ERL",
        "value": "ERL",
        "iso": "CN"
    },
    {
        "label": "HDG",
        "value": "HDG",
        "iso": "CN"
    },
    {
        "label": "HET",
        "value": "HET",
        "iso": "CN"
    },
    {
        "label": "ZBK",
        "value": "ZBK",
        "iso": "ME"
    },
    {
        "label": "HLD",
        "value": "HLD",
        "iso": "CN"
    },
    {
        "label": "NAY",
        "value": "NAY",
        "iso": "CN"
    },
    {
        "label": "BAV",
        "value": "BAV",
        "iso": "CN"
    },
    {
        "label": "SHP",
        "value": "SHP",
        "iso": "CN"
    },
    {
        "label": "SJW",
        "value": "SJW",
        "iso": "CN"
    },
    {
        "label": "TSN",
        "value": "TSN",
        "iso": "CN"
    },
    {
        "label": "TGO",
        "value": "TGO",
        "iso": "CN"
    },
    {
        "label": "HLH",
        "value": "HLH",
        "iso": "CN"
    },
    {
        "label": "XIL",
        "value": "XIL",
        "iso": "CN"
    },
    {
        "label": "YCU",
        "value": "YCU",
        "iso": "CN"
    },
    {
        "label": "TYN",
        "value": "TYN",
        "iso": "CN"
    },
    {
        "label": "BHY",
        "value": "BHY",
        "iso": "CN"
    },
    {
        "label": "CGD",
        "value": "CGD",
        "iso": "CN"
    },
    {
        "label": "HJJ",
        "value": "HJJ",
        "iso": "CN"
    },
    {
        "label": "DYG",
        "value": "DYG",
        "iso": "CN"
    },
    {
        "label": "CAN",
        "value": "CAN",
        "iso": "CN"
    },
    {
        "label": "CSX",
        "value": "CSX",
        "iso": "CN"
    },
    {
        "label": "HNY",
        "value": "HNY",
        "iso": "CN"
    },
    {
        "label": "KWL",
        "value": "KWL",
        "iso": "CN"
    },
    {
        "label": "MXZ",
        "value": "MXZ",
        "iso": "CN"
    },
    {
        "label": "NNG",
        "value": "NNG",
        "iso": "CN"
    },
    {
        "label": "SWA",
        "value": "SWA",
        "iso": "CN"
    },
    {
        "label": "ZUH",
        "value": "ZUH",
        "iso": "CN"
    },
    {
        "label": "SZX",
        "value": "SZX",
        "iso": "CN"
    },
    {
        "label": "WUZ",
        "value": "WUZ",
        "iso": "CN"
    },
    {
        "label": "XIN",
        "value": "XIN",
        "iso": "CN"
    },
    {
        "label": "LZH",
        "value": "LZH",
        "iso": "CN"
    },
    {
        "label": "ZHA",
        "value": "ZHA",
        "iso": "CN"
    },
    {
        "label": "AYN",
        "value": "AYN",
        "iso": "CN"
    },
    {
        "label": "CGO",
        "value": "CGO",
        "iso": "CN"
    },
    {
        "label": "ENH",
        "value": "ENH",
        "iso": "CN"
    },
    {
        "label": "LHK",
        "value": "LHK",
        "iso": "CN"
    },
    {
        "label": "WUH",
        "value": "WUH",
        "iso": "CN"
    },
    {
        "label": "LYA",
        "value": "LYA",
        "iso": "CN"
    },
    {
        "label": "NNY",
        "value": "NNY",
        "iso": "CN"
    },
    {
        "label": "SHS",
        "value": "SHS",
        "iso": "CN"
    },
    {
        "label": "XFN",
        "value": "XFN",
        "iso": "CN"
    },
    {
        "label": "YIH",
        "value": "YIH",
        "iso": "CN"
    },
    {
        "label": "ZIZ",
        "value": "ZIZ",
        "iso": "PK"
    },
    {
        "label": "HAK",
        "value": "HAK",
        "iso": "CN"
    },
    {
        "label": "SYX",
        "value": "SYX",
        "iso": "CN"
    },
    {
        "label": "FNJ",
        "value": "FNJ",
        "iso": "KP"
    },
    {
        "label": "AKA",
        "value": "AKA",
        "iso": "CN"
    },
    {
        "label": "LHW",
        "value": "LHW",
        "iso": "CN"
    },
    {
        "label": "DNH",
        "value": "DNH",
        "iso": "CN"
    },
    {
        "label": "GOQ",
        "value": "GOQ",
        "iso": "CN"
    },
    {
        "label": "GYU",
        "value": "GYU",
        "iso": "CN"
    },
    {
        "label": "HZG",
        "value": "HZG",
        "iso": "CN"
    },
    {
        "label": "INC",
        "value": "INC",
        "iso": "CN"
    },
    {
        "label": "JNG",
        "value": "JNG",
        "iso": "CN"
    },
    {
        "label": "JGN",
        "value": "JGN",
        "iso": "CN"
    },
    {
        "label": "ZGC",
        "value": "ZGC",
        "iso": "CN"
    },
    {
        "label": "IQN",
        "value": "IQN",
        "iso": "CN"
    },
    {
        "label": "SIA",
        "value": "SIA",
        "iso": "CN"
    },
    {
        "label": "XNN",
        "value": "XNN",
        "iso": "CN"
    },
    {
        "label": "XIY",
        "value": "XIY",
        "iso": "CN"
    },
    {
        "label": "ENY",
        "value": "ENY",
        "iso": "CN"
    },
    {
        "label": "UYN",
        "value": "UYN",
        "iso": "CN"
    },
    {
        "label": "CGJ",
        "value": "CGJ",
        "iso": "ZM"
    },
    {
        "label": "AVK",
        "value": "AVK",
        "iso": "MN"
    },
    {
        "label": "LTI",
        "value": "LTI",
        "iso": "MN"
    },
    {
        "label": "BYN",
        "value": "BYN",
        "iso": "MN"
    },
    {
        "label": "UGA",
        "value": "UGA",
        "iso": "MN"
    },
    {
        "label": "UGT",
        "value": "UGT",
        "iso": "MN"
    },
    {
        "label": "HBU",
        "value": "HBU",
        "iso": "MN"
    },
    {
        "label": "UUN",
        "value": "UUN",
        "iso": "MN"
    },
    {
        "label": "COQ",
        "value": "COQ",
        "iso": "MN"
    },
    {
        "label": "DLZ",
        "value": "DLZ",
        "iso": "MN"
    },
    {
        "label": "HVD",
        "value": "HVD",
        "iso": "MN"
    },
    {
        "label": "MXV",
        "value": "MXV",
        "iso": "MN"
    },
    {
        "label": "ULN",
        "value": "ULN",
        "iso": "MN"
    },
    {
        "label": "ULG",
        "value": "ULG",
        "iso": "MN"
    },
    {
        "label": "DLU",
        "value": "DLU",
        "iso": "CN"
    },
    {
        "label": "DIG",
        "value": "DIG",
        "iso": "CN"
    },
    {
        "label": "JHG",
        "value": "JHG",
        "iso": "CN"
    },
    {
        "label": "LJG",
        "value": "LJG",
        "iso": "CN"
    },
    {
        "label": "LUM",
        "value": "LUM",
        "iso": "CN"
    },
    {
        "label": "KMG",
        "value": "KMG",
        "iso": "CN"
    },
    {
        "label": "SYM",
        "value": "SYM",
        "iso": "CN"
    },
    {
        "label": "ZAT",
        "value": "ZAT",
        "iso": "CN"
    },
    {
        "label": "XMN",
        "value": "XMN",
        "iso": "CN"
    },
    {
        "label": "AQG",
        "value": "AQG",
        "iso": "CN"
    },
    {
        "label": "CZX",
        "value": "CZX",
        "iso": "CN"
    },
    {
        "label": "KHN",
        "value": "KHN",
        "iso": "CN"
    },
    {
        "label": "FOC",
        "value": "FOC",
        "iso": "CN"
    },
    {
        "label": "KOW",
        "value": "KOW",
        "iso": "CN"
    },
    {
        "label": "HGH",
        "value": "HGH",
        "iso": "CN"
    },
    {
        "label": "JDZ",
        "value": "JDZ",
        "iso": "CN"
    },
    {
        "label": "JIU",
        "value": "JIU",
        "iso": "CN"
    },
    {
        "label": "TNA",
        "value": "TNA",
        "iso": "CN"
    },
    {
        "label": "JUZ",
        "value": "JUZ",
        "iso": "CN"
    },
    {
        "label": "LCX",
        "value": "LCX",
        "iso": "CN"
    },
    {
        "label": "LYG",
        "value": "LYG",
        "iso": "CN"
    },
    {
        "label": "HYN",
        "value": "HYN",
        "iso": "CN"
    },
    {
        "label": "LYI",
        "value": "LYI",
        "iso": "CN"
    },
    {
        "label": "NGB",
        "value": "NGB",
        "iso": "CN"
    },
    {
        "label": "NKG",
        "value": "NKG",
        "iso": "CN"
    },
    {
        "label": "HFE",
        "value": "HFE",
        "iso": "CN"
    },
    {
        "label": "PVG",
        "value": "PVG",
        "iso": "CN"
    },
    {
        "label": "TAO",
        "value": "TAO",
        "iso": "CN"
    },
    {
        "label": "JJN",
        "value": "JJN",
        "iso": "CN"
    },
    {
        "label": "RUG",
        "value": "RUG",
        "iso": "CN"
    },
    {
        "label": "SHA",
        "value": "SHA",
        "iso": "CN"
    },
    {
        "label": "SZV",
        "value": "SZV",
        "iso": "CN"
    },
    {
        "label": "TXN",
        "value": "TXN",
        "iso": "CN"
    },
    {
        "label": "WEF",
        "value": "WEF",
        "iso": "CN"
    },
    {
        "label": "WEH",
        "value": "WEH",
        "iso": "CN"
    },
    {
        "label": "WHU",
        "value": "WHU",
        "iso": "CN"
    },
    {
        "label": "WUX",
        "value": "WUX",
        "iso": "CN"
    },
    {
        "label": "WUS",
        "value": "WUS",
        "iso": "CN"
    },
    {
        "label": "WNZ",
        "value": "WNZ",
        "iso": "CN"
    },
    {
        "label": "XUZ",
        "value": "XUZ",
        "iso": "CN"
    },
    {
        "label": "YNZ",
        "value": "YNZ",
        "iso": "CN"
    },
    {
        "label": "YNT",
        "value": "YNT",
        "iso": "CN"
    },
    {
        "label": "YIW",
        "value": "YIW",
        "iso": "CN"
    },
    {
        "label": "HSN",
        "value": "HSN",
        "iso": "CN"
    },
    {
        "label": "NGQ",
        "value": "NGQ",
        "iso": "CN"
    },
    {
        "label": "AVA",
        "value": "AVA",
        "iso": "CN"
    },
    {
        "label": "BPX",
        "value": "BPX",
        "iso": "CN"
    },
    {
        "label": "CKG",
        "value": "CKG",
        "iso": "CN"
    },
    {
        "label": "DAX",
        "value": "DAX",
        "iso": "CN"
    },
    {
        "label": "GYS",
        "value": "GYS",
        "iso": "CN"
    },
    {
        "label": "KWE",
        "value": "KWE",
        "iso": "CN"
    },
    {
        "label": "JZH",
        "value": "JZH",
        "iso": "CN"
    },
    {
        "label": "LIA",
        "value": "LIA",
        "iso": "CN"
    },
    {
        "label": "LXA",
        "value": "LXA",
        "iso": "CN"
    },
    {
        "label": "LZO",
        "value": "LZO",
        "iso": "CN"
    },
    {
        "label": "MIG",
        "value": "MIG",
        "iso": "CN"
    },
    {
        "label": "NAO",
        "value": "NAO",
        "iso": "CN"
    },
    {
        "label": "LZY",
        "value": "LZY",
        "iso": "CN"
    },
    {
        "label": "TCZ",
        "value": "TCZ",
        "iso": "CN"
    },
    {
        "label": "CTU",
        "value": "CTU",
        "iso": "CN"
    },
    {
        "label": "WXN",
        "value": "WXN",
        "iso": "CN"
    },
    {
        "label": "XIC",
        "value": "XIC",
        "iso": "CN"
    },
    {
        "label": "YBP",
        "value": "YBP",
        "iso": "CN"
    },
    {
        "label": "ACX",
        "value": "ACX",
        "iso": "CN"
    },
    {
        "label": "AKU",
        "value": "AKU",
        "iso": "CN"
    },
    {
        "label": "BPL",
        "value": "BPL",
        "iso": "CN"
    },
    {
        "label": "IQM",
        "value": "IQM",
        "iso": "CN"
    },
    {
        "label": "HMI",
        "value": "HMI",
        "iso": "CN"
    },
    {
        "label": "KCA",
        "value": "KCA",
        "iso": "CN"
    },
    {
        "label": "KRL",
        "value": "KRL",
        "iso": "CN"
    },
    {
        "label": "KRY",
        "value": "KRY",
        "iso": "CN"
    },
    {
        "label": "KHG",
        "value": "KHG",
        "iso": "CN"
    },
    {
        "label": "SXJ",
        "value": "SXJ",
        "iso": "CN"
    },
    {
        "label": "TCG",
        "value": "TCG",
        "iso": "CN"
    },
    {
        "label": "HTN",
        "value": "HTN",
        "iso": "CN"
    },
    {
        "label": "URC",
        "value": "URC",
        "iso": "CN"
    },
    {
        "label": "YIN",
        "value": "YIN",
        "iso": "CN"
    },
    {
        "label": "AOG",
        "value": "AOG",
        "iso": "CN"
    },
    {
        "label": "CGQ",
        "value": "CGQ",
        "iso": "CN"
    },
    {
        "label": "CNI",
        "value": "CNI",
        "iso": "CN"
    },
    {
        "label": "CHG",
        "value": "CHG",
        "iso": "CN"
    },
    {
        "label": "HRB",
        "value": "HRB",
        "iso": "CN"
    },
    {
        "label": "HEK",
        "value": "HEK",
        "iso": "CN"
    },
    {
        "label": "JIL",
        "value": "JIL",
        "iso": "CN"
    },
    {
        "label": "JMU",
        "value": "JMU",
        "iso": "CN"
    },
    {
        "label": "JNZ",
        "value": "JNZ",
        "iso": "CN"
    },
    {
        "label": "YUS",
        "value": "YUS",
        "iso": "CN"
    },
    {
        "label": "MDG",
        "value": "MDG",
        "iso": "CN"
    },
    {
        "label": "OHE",
        "value": "OHE",
        "iso": "CN"
    },
    {
        "label": "NDG",
        "value": "NDG",
        "iso": "CN"
    },
    {
        "label": "DLC",
        "value": "DLC",
        "iso": "CN"
    },
    {
        "label": "SHE",
        "value": "SHE",
        "iso": "CN"
    },
    {
        "label": "YNJ",
        "value": "YNJ",
        "iso": "CN"
    }
]