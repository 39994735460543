import React, { Component, Fragment } from 'react'
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, FormGroup, Input, Label } from 'reactstrap'
import { createTemplate } from '../../api/Form';
import inputData from "./tempData.json"

export default class AddTemplate extends Component {
    state = {
        inputList: [],
        tempName: "",
        userId: '',
        isDisabled: false
    };

    async componentDidMount() {
        const userId = await localStorage.getItem('userId');
        this.setState({ userId })
        if (this.state.inputList.length > 0) {
            this.state.inputList[this.state.inputList.length - 1].input === ""
                ? this.setState({ isDisabled: true })
                : this.setState({ isDisabled: false })
        }
    }

    handleListAdd = (e) => {
        let flag = false
        this.state.inputList.forEach(element => {
            if (element.key === e.target.value) {
                flag = true
            }
        });
        if (!flag) {
            this.setState({
                inputList: ([
                    ...this.state.inputList,
                    {
                        key: e.target.value,
                        label_name: e.target.selectedOptions[0].label,
                        type: e.target[e.target.selectedIndex].id
                    }
                ])
            });
        }
        else {
            toast.error("Field has been already added", {
                position: toast.POSITION.TOP_RIGHT
            })
        }
    }

    handleInputChange = (event, index) => {
        const { value } = event.target
        const newInputList = [...this.state.inputList]
        newInputList[index].key = index + 1
        newInputList[index].label_name = index + 1
        this.state.inputList(newInputList)
    }


    handleRemoveItem = (index) => {
        const newList = [...this.state.inputList]
        newList.splice(index, 1)
        this.setState({ inputList: newList })
    }

    sendApiCall = async () => {
        console.log(this.state.inputList)
        await createTemplate({
            userId: this.state.userId,
            name: this.state.tempName,
            customFields: this.state.inputList
        }).then((response) => {
            console.log(response, "response")

            this.setState({ loading: false })
            this.props.history.push('/admin/index');
            toast.success('Template saved successfully!!', {
                position: toast.POSITION.TOP_RIGHT
            })
        }).catch((err) => {
            console.log(err, "errorrr")
            this.setState({ loading: false })
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            })
        })
    }

    saveTemplate = () => {
        this.setState({ loading: true });
        if (this.state.tempName === "" || this.state.tempName === undefined || this.state.tempName === null) {
            this.setState({ loading: false })
            toast.error('Please enter template name', {
                position: toast.POSITION.TOP_RIGHT
            });
        } else if (this.state.inputList.length < 1) {
            this.setState({ loading: false })
            toast.error('Atleast 1 custom field is required', {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            this.sendApiCall();
        }
    }

    render() {
        return (
            <div className="mt-2 style">
                <Card>
                    <CardHeader className="c-header">Add Template</CardHeader>
                    <CardBody>
                        <div className="col-12">
                            <Label className="mb-2">Name</Label>
                            <Input
                                type="text"
                                id="outlined-basic"
                                placeholder="Name"
                                variant="outlined"
                                onChange={(e) => this.setState({ tempName: e.target.value })}
                            />
                        </div>
                        <div>
                            <div className="col-12 custom-filed-select input-group">
                                <Label className="mb-2 mt-2">Select Custom Fields</Label>
                                <select onChange={(e) => this.handleListAdd(e)}>
                                    <option hidden>Select custom fields</option>
                                    {
                                        inputData && inputData.map((item) => {
                                            return (
                                                <option value={item.value} id={item.type} key={item.value}>{item.label}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-12">
                            {this.state.inputList.length > 0
                                ? this.state.inputList.map((input, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <Label className="mb-2 mt-2">{input.label_name}</Label>
                                            <div className="input-group flex-data-select">
                                                {
                                                    input.type === "text" && input.key !== "dimension" &&
                                                    <Input
                                                        type={input.type}
                                                        id="outlined-basic"
                                                        placeholder={input.label_name}
                                                        variant="outlined"
                                                        onChange={(event) => this.handleInputChange(event, index)}
                                                        disabled
                                                    />
                                                }
                                                {
                                                    input.type === "text" && input.key === "dimension" &&
                                                    <div className='dimensionBox'>
                                                        <div className="row">
                                                            <div className="col-lg-4 col-xs-12 col-sm-12">
                                                                <Input type={input.type}
                                                                    variant="outlined"
                                                                    placeholder="Enter Height of Dimension"
                                                                    disabled />
                                                            </div>
                                                            <div className="col-lg-4 col-xs-12 col-sm-12">
                                                                <Input type={input.type}
                                                                    className="mt-sm-3 mt-lg-0"
                                                                    variant="outlined"
                                                                    placeholder="Enter Width of Dimension"
                                                                    disabled />
                                                            </div>
                                                            <div className="col-lg-4 col-xs-12 col-sm-12 ">
                                                                <Input type={input.type}
                                                                    className="mt-sm-3 mt-lg-0"
                                                                    variant="outlined"
                                                                    placeholder="Enter Length of Dimension"
                                                                    disabled />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    input.type === "select" &&
                                                    <div className='custom-filed-select-2'>
                                                        <select disabled>
                                                            <option hidden>{`Select ` + input.label_name}</option>
                                                            <option value="vshg">sdgf</option>
                                                        </select>
                                                    </div>
                                                }
                                                {
                                                    index !== 0 &&
                                                    <button className="btn btn-primary" onClick={() => this.handleRemoveItem(index)} style={{ marginLeft: "8px" }}>
                                                        <span role="img" aria-label="x emoji">
                                                            ❌
                                                        </span>
                                                    </button>
                                                }
                                            </div>
                                        </Fragment>
                                    )
                                })
                                :
                                <p className='mt-2'>
                                    No item in the list please select fields
                                </p>
                            }
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                            <button className="btn btn-primary d-flex align-items-center" onClick={() => this.saveTemplate()}>Add Template</button>
                        </div>
                    </CardBody>
                </Card>
            </div >
        )
    }
}
