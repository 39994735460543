import React, { Component } from "react";
import { NavLink as RRNavLink } from "react-router-dom";
import './Sidebar.css';
import { NavItem, NavLink, Nav, Collapse, Button, Badge } from "reactstrap";
import { countAccessPendingRequest } from "../../api/Form";

export default class Sidebar extends Component {

  state = {
    Collapse: true,
    pendingRequests: '',
    deviceAdCode: '',
  }

  async componentDidMount() {
    const deviceAdCode = await localStorage.getItem('adCodes');
    this.setState({ deviceAdCode });
    this.getPendingCount()
  }
  getPendingCount = () => {
    countAccessPendingRequest().then(Response => {
      if (Response.data.status === true) {
        this.setState({ pendingRequests: Response.data.data.pending })
      }
    })
  }

  createLinks = routes => {

    return routes.filter(p => !p.hidden).map((prop, key) => {

      return (

        <NavItem key={key} >
          {prop.child ?
            <>
              <div className="row" style={{ padding: '10px', cursor: 'pointer' }} onClick={() => this.setState({ ['collapsed' + key]: !this.state['collapsed' + key] })}>
                <div className="col-1 p-0 pl-3">
                  <i className={prop.icon} />
                </div>
                <div className="col p-0 pl-3">
                  <span className="pl-2 ange ">
                    {prop.name}
                  </span>
                  {" "}<i className={this.state['collapsed' + key] ? "fa fa-caret-up" : "fa fa-caret-down"}></i>
                </div>

              </div>
              {this.state['collapsed' + key] ? <div className="dropdown-container">
                {prop.child.map((item, i) => (
                  <NavLink
                    key={i}
                    to={item.layout + item.path}
                    tag={RRNavLink}
                    activeClassName="active"
                    activeStyle={{
                      color: "#0000a0",
                      background: "#e4e5ff",
                      borderBottomRightRadius: "51px",
                      borderTopRightRadius: "51px"
                    }}>{item.name}</NavLink>
                ))}
              </div> : null}
            </>
            :
            <NavLink
              to={prop.layout + prop.path}
              tag={RRNavLink}
              activeClassName="active"
              activeStyle={{
                color: "#0000a0",
                background: "#e4e5ff",
                borderBottomRightRadius: "51px",
                borderTopRightRadius: "51px"
              }}>
              <div className="row" onClick={() => prop.name === 'Create Shipment' && this.props.showClick ? this.props.collapseStatus() : ''}>
                <div className="col-1 p-0 pl-3">
                  <i className={prop.icon} />
                </div>
                <div className="col p-0 pl-3" onClick={this.getPendingCount}>
                  <span className="pl-2 ange ">
                    {prop.name}
                    {
                      prop.path === "/accessRequests" &&
                      <Badge color="danger" style={{ marginLeft: "10px" }}>{this.state.pendingRequests ? this.state.pendingRequests : 0}</Badge>
                    }
                  </span>
                </div>
              </div>
            </NavLink>
          }

        </NavItem >
      );
    });
  };

  CollapseHandler = () => {
    this.setState({ Collapse: !this.state.Collapse });
  }

  render() {
    const { routes } = this.props;
    return (
      <div>
        <div className="wrapper">
          <nav id="sidebar" className="d-flex justify-content-center" >
            <Nav navbar style={{ position: 'fixed', paddingTop: "10px", paddingBottom: "15px", overflow: 'scroll', height: 'calc(100vh - 34px)' }}>{this.createLinks(routes)}</Nav>
          </nav>
        </div>
      </div>
    )
  }
}
