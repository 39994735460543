import React, { Component } from 'react'
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import scan from '../../assets/noun_scan_3165200.svg'
import './Modal.css'
import {srRequest} from '../../api/Form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { tsid } from '../../utils/utils';
import PhoneInput from 'react-intl-tel-input';
import { isValidNumber } from 'libphonenumber-js';
import 'react-intl-tel-input/dist/main.css';

export default class Modals extends Component {
  state = {
    modal: true,
    camScan: false,
    delay: 100,
    scan: 'Scan QR Code',
    receiverType: 'Normal',
    nfcADcode: '',
    phoneNumber: '',
    deviceADcode: '',
    isValidPhoneNo: true,
  }

  async componentDidMount() {
    const data = await localStorage.getItem('auth');
    const { ADCode } = JSON.parse(data);
    this.setState({ deviceADcode: ADCode });
  }

  inputChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  scanHandler = () => {
    this.addParcelComponentHandler({nfcADcode: this.state.nfcADcode, phoneNumber: this.state.phoneNumber, nfcserialno: tsid(10)});
  }
  
  toggleCloseHandler = () => {
    this.props.close.push('/admin/index')
  }
  
  receiverTypeHandler = (e) => {
    this.setState({ receiverType: e })
  }
  
  addParcelComponentHandler = (values) => {
    if (this.state.receiverType === 'SR') {
      this.setState({ isLoading: true});
      srRequest({ nfcADcode: values.nfcADcode, phoneNumber: values.phoneNumber})
      .then(response => {
               this.props.scan({nfcADcode: values.nfcADcode, type: this.state.receiverType, phoneNumber: this.state.phoneNumber, nfcserialno: values.nfcserialno});
                this.setState({ isLoading: false });
            })
            .catch(err => {
              let message = err.message;
              if (err.response && err.response.data.message) {
                message = err.response.data.message;
              }
              toast.error(message, {
                position: toast.POSITION.TOP_RIGHT
              });
                console.log(err.response, 'err');
                this.setState({ isLoading: false });
            })
    } else {
      this.props.scan({nfcADcode: values.nfcADcode, type: this.state.receiverType, phoneNumber: this.state.phoneNumber, nfcserialno: values.nfcserialno});

    }
  };

  handlePhoneNumberChange = (isEdited, phoneNumber, selectedCountry) => {
    const isValid = isValidNumber(phoneNumber, [selectedCountry.iso2.toUpperCase()])
    const number = `+${selectedCountry.dialCode}${phoneNumber}`
    this.setState({ isValidPhoneNo: isValid, phoneNumber: number });
  };

  render() {
    return (
      <div>
        <Modal isOpen={this.state.modal} >
          <ModalBody>
            <>
              <div className="d-flex justify-content-center pt-3">
                <img src={scan} alt="" width="80px" height="80px" />
              </div>
              <div className="row pt-3 d-flex justify-content-center">
                <div className="col-12 py-2 d-flex justify-content-center">
                  <h1 className="scan-title">1. Type in AD Code</h1>
                </div>
                <div className=" input-container col-12 py-2 d-flex justify-content-center input-field align-items-center">
                  <input
                    type="text"
                    className="scan-input"
                    name="nfcADcode"
                    placeholder="Enter ADCode"
                    value={this.state.nfcADcode}
                    onChange={this.inputChangeHandler} />
                  <i className="fa fa-bars refNo d-flex" onClick={() => this.setState({ nfcADcode: this.state.deviceADcode })}></i>

                </div>
                <div className="col-12 py-2 d-flex justify-content-center">
                  <h1 className="scan-title">2. Select receiver type</h1>
                </div>
                <div className="pb-3">
                  <div class="radio-item">
                    <input type="radio" id="ritema" name="ritem" value="ropt1" defaultChecked checked={this.state.receiverType === 'SR'} onChange={() => this.receiverTypeHandler("SR")} />
                    <label for="ritema">SR</label>
                  </div>

                  <div class="radio-item">
                    <input type="radio" id="ritemb" name="ritem" value="ropt2" checked={this.state.receiverType === 'Normal'} defaultChecked onChange={() => this.receiverTypeHandler("Normal")} />
                    <label for="ritemb">Normal</label>
                  </div>
                </div>
                {this.state.receiverType === 'SR' &&
                  <>
                    <div className="col-12 d-flex justify-content-center">
                      <h1 className="scan-title">3. Enter phone number</h1>
                    </div>
                  <div className="d-flex justify-content-center pb-3 input-field">
                    <div>
                      <PhoneInput
                        containerClassName="intl-tel-input"
                        inputClassName="scan-input scan-input-number-phone"
                        style={{ width: "210px" }}
                        onPhoneNumberChange={(isEdited, phoneNumber, selectedCountry) => this.handlePhoneNumberChange(isEdited, phoneNumber, selectedCountry)}
                      />
                      <br />
                      <h5 className='error-display'>
                        {!this.state.isValidPhoneNo && "Please enter a valid phone number"}
                      </h5>
                    </div>
                    </div>
                  </>
                }
              </div>
              <div className="d-flex justify-content-center">
                <div>
                  <div className="scan-btn">
                    <button className="btn btn btn-submit mr-2 btn-block" onClick={this.scanHandler} disabled={!this.state.nfcADcode} >Next</button>
                  </div>
                  <div className="scan-btn">
                  </div>
                </div>
              </div>
            </>

          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleCloseHandler}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div >
    )
  }
}
