import React, { Component } from 'react';
import Slider from 'react-slick';
import testimonialUser1 from '../../assets/testimonialsuser1.png'
import testimonialUser2 from '../../assets/testimonial2.webp'
import testimonialUser3 from '../../assets/testimonial3.webp'
import quoteImg from '../../assets/quotationImg.png'


class TestimonialCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = { activeIndex: 0 };
        this.slickRef = React.createRef();
    }

    goToSlide = (index) => {
        this.slickRef.current.slickGoTo(index);
        this.setState({ activeIndex: index });
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 6000,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            beforeChange: (oldIndex, newIndex) => {
                this.setState({ activeIndex: newIndex });
            }
        };

        const testimonials = [
            {
                text: "This is due to their excellent service, competitive pricing and customer support. That’s becoming so distant and digital, it’s thoroughly refreshing to get such a personal touch.",
                name: "Frank",
                image: testimonialUser1
            },
            {
                text: "Their team provided us with excellent service and were extremely professional throughout the project.",
                name: "Angel",
                image: testimonialUser2
            },
            {
                text: "We are very happy with the results. Their attention to detail and quality of work is outstanding.",
                name: "John",
                image: testimonialUser3
            }
        ];

        return (
            <div className="container testimonial-carousel">
                <div className="row">
                    <div className="col-md-4">
                        <div className='testimonial-info'>
                            <h2>Testimonials</h2>
                            <p>What Customers say about us:</p>
                            <p>Exceptional Service and Results, Transforming Visions into Reality</p>
                            <div className="dots">
                                {testimonials.map((_, index) => (
                                    <span
                                        key={`TestimonialSliderDot-${index}`}
                                        className={`dot ${this.state.activeIndex === index ? 'active' : ''}`}
                                        onClick={() => this.goToSlide(index)}
                                    ></span>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <Slider {...settings} ref={this.slickRef}>
                            {testimonials.map((testimonial, index) => (
                                <div key={`TestimonialSlider-${index}`} className="testimonial-slide">
                                    <img src={quoteImg} alt='quote' />
                                    <p className="testimonial-text">{testimonial.text}</p>
                                    <div className="testimonial-author">
                                        <img src={testimonial.image} alt={testimonial.name} className="author-image" />
                                        <p className="author-name">{testimonial.name}</p>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        );
    }
}

export default TestimonialCarousel;
