import React, { Component } from 'react'
import { Card, CardBody, Label, Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalBody, ModalHeader, } from 'reactstrap';
import classnames from 'classnames';
import 'react-vertical-timeline-component/style.min.css';
import { searchRequests, getAllScreenAccessRequests, updateStatus, sendChats, chatsRequest } from '../../api/Form';
import { ClipLoader } from "react-spinners";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

export default class ScreenAccessRequests extends Component {
	state = {
		activeTab: '1',
		deviceAdCode: '',
		value: '',
		pendingRequests: [],
		approvedRequests: [],
		rejectedRequests: [],
		searchItems: [],
		searchItem: '',
		loading: true,
		trackShipmentModal: false,
		contactScreen: false,
		idComments: '',
		statusComment: '',
		credits: null,
		emailComments: '',
		comments: '',
		userId: '',
		sendMessageUserId: '',
		userSendRequestId: "",
		uidUsers: "",
		screenType2: "",
		loader: false,
		uuid: ""
	}

	async componentDidMount() {
		const deviceAdCode = await localStorage.getItem('adCodes');
		const userId = await localStorage.getItem("userId");
		this.setState({ deviceAdCode, userId: userId });
		getAllScreenAccessRequests({
			userId: userId
		}).then(Response => {
			if (Response.data.status === true) {
				this.setState({ pendingRequests: Response.data.data.pending, approvedRequests: Response.data.data.approved, rejectedRequests: Response.data.data.rejected, loading: false })
			}
		})
	}

	// get chatId for sending message to user of her request approved or rejected

	contactRequestSendHandler = (reciverId) => {
		chatsRequest({ userId: this.state.userSendRequestId, senderId: this.state.userId })
			.then((Response) => {
				this.setState({
					contactScreen: false, searchContact: "", sendMessageUserId: Response.data.data.id
				});
				this.sendChatHandler(Response.data.data.id)
			})
			.catch((err) => {
				console.log(err);
			});
	};

	//send chat to user of reject or accept her request
	sendChatHandler = (chatId) => {
		if (this.state.sendMessageUserId || chatId) {
			sendChats({
				text: this.state.comments,
				chatId: this.state.sendMessageUserId || chatId,
				senderId: Number(this.state.userId),
				userId: this.state.userId,
			}).then((Response) => {
				console.log(Response);
			}).catch((err) => {
				console.log(err);
			});
		} else {
			this.contactRequestSendHandler()
		}
	};

	changeAccessStatus = (id, status, email, uidUsers, screenType2, is_accepted) => {
		if (
			this.state.screenType2 === "Request Free Credit" &&
			this.state.statusComment === 1 &&
			this.state.credits === null
		) {
			alert("Please enter Credits");
		} else {
			this.setState({
				loader: true
			})

			updateStatus({
				'id': this.state.idComments ? this.state.idComments : id,
				'status': this.state.statusComment ? this.state.statusComment : status,
				'email': this.state.emailComments ? this.state.emailComments : email,
				'comments': this.state.comments,
				'username': this.state.uidUsers ? this.state.uidUsers : uidUsers,
				'screen_type': this.state.screenType2?.toLowerCase() ? this.state.screenType2?.toLowerCase() : screenType2?.toLowerCase(),
				'is_accepted': is_accepted ? true : false,
				credits: Number(this.state.credits) ? Number(this.state.credits) : null,
				uuid: this.state.uuid
			})
				.then(response => {
					let message;
					if (status === 1 || this.state.statusComment === 1) {
						message = "Access Enabled";
					} else {
						message = "Access Disabled";
					}
					toast.success(response.data.message)
					this.setState({
						loading: false,
						loader: false
					})
					window.location.reload();
				})
				.catch(err => {
					this.setState({
						loading: false,
						loader: false
					})
					toast.error('Something went wrong !!', {
						position: toast.POSITION.TOP_RIGHT
					})
				})
		}
	}

	handleChange = (e) => {
		this.setState({ searchItem: e.target.value, searchItems: [] })
	}

	searchRequests = () => {
		if (this.state.searchItem !== "" || this.state.searchItem !== undefined || this.state.searchItem !== null) {
			searchRequests({ searchItem: this.state.searchItem })
				.then(Response => {
					this.setState({ searchItems: Response.data.data, activeTab: '4' })
				})
				.catch(err => {
					toast.error(err.response.data.message, {
						position: toast.POSITION.TOP_RIGHT
					});
				})
		} else {
			toast.error("Please enter a valid Name OR Email.", {
				position: toast.POSITION.TOP_RIGHT
			});
			this.setState({ searchItems: '', searchItem: '' })
		}
	}

	keyPress = (e) => {
		if (e.keyCode === 13) {
			this.searchRequests()
		}
	}

	toggle = (tab) => {
		if (this.state.activeTab !== tab) {
			this.setState({ activeTab: tab });
		}
	}

	newContactHandler = (id, status, email, sendMessageUserId, specialID, uidUsers, screenType2, uuid) => {
		this.setState({ contactScreen: !this.state.contactScreen, idComments: id, statusComment: status, emailComments: email, sendMessageUserId: sendMessageUserId, userSendRequestId: specialID, uidUsers: uidUsers, screenType2: screenType2, uuid: uuid });
	};
	newContactHandlerClose = () => {
		this.setState({ contactScreen: !this.state.contactScreen, credits: "", idComments: '', comments: "", statusComment: '', emailComments: '', sendMessageUserId: '', userSendRequestId: '', uidUsers: '', screenType2: '', uuid: '' });
	}
	render() {
		const pending = this.state.pendingRequests.map(p => p)
		const approved = this.state.approvedRequests.map(p => p)
		const rejected = this.state.rejectedRequests.map(p => p)
		const searchItems = this.state.searchItems.map(p => p)
		const { loader, idComments, statusComment, emailComments, comments } = this.state
		return (
			<div className="mt-2 style">
				<Card>
					<CardBody>
						<div class="input-group col-md-4 ml-auto p-0 pb-3 pb-sm-3 pb-md-0 pb-lg-0 pb-xl-0">
							<input class="form-control py-2 border-right-0 border" value={this.state.searchItem}
								onChange={this.handleChange} onKeyDown={this.keyPress}
								placeholder="Enter name or email" />
							<span class="input-group-append">
								<button class="btn btn-outline-secondary border-left-0 border" type="button" style={{ 'z-index': 0 }}
									onClick={this.searchRequests}>
									<i class="fa fa-search"></i>
								</button>
							</span>
						</div>
						<Nav tabs>
							<NavItem>
								<NavLink
									className={classnames({ active: this.state.activeTab === '1' })}
									onClick={() => {
										this.toggle('1');
									}}
								>
									Pending
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classnames({ active: this.state.activeTab === '2' })}
									onClick={() => {
										this.toggle('2');
									}}
								>
									Accepted
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classnames({ active: this.state.activeTab === '3' })}
									onClick={() => {
										this.toggle('3');
									}}
								>
									Rejected
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classnames({ active: this.state.activeTab === '4' })}
									onClick={() => {
										this.toggle('4');
									}}
								>
									Search Requests
								</NavLink>
							</NavItem>
						</Nav>
						<TabContent activeTab={this.state.activeTab} className="pt-5">
							<TabPane tabId="1">
								<div className="row">
									{this.state.loading === true ?
										<div className="d-flex justify-content-center">
											< ClipLoader
												size={15}
												color={"#123abc"}
												loading={true}
											/>
										</div>
										:
										<>
											{pending.map(p => {
												return (
													<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 pb-4">
														<Card className="shadow border-0">
															<CardBody>
																<div className="row">
																	<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style head-label"
																					style={{ whiteSpace: "nowrap" }}>User Name </Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{(p.firstnameUsers)}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style head-label">Status</Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{'Pending'}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style head-label">Screen Access</Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{p.screenType}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style head-label"
																					style={{ whiteSpace: "nowrap" }}>Email</Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description" style={{ overflowWrap: "anywhere" }}>{p.emailUsers}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style head-label"
																					style={{ whiteSpace: "nowrap" }}>Account type</Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{p.accounttypeUsers}</Label>
																			</div>
																		</div>
																	</div>
																	{p.accounttypeUsers === 'Business' &&
																		<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																			<div className="row">
																				<div className="col-6">
																					<Label for="exampleEmail " className="pr-5 label-style head-label"
																						style={{ whiteSpace: "nowrap" }}>Business name</Label>
																				</div>
																				<div className="col-6">
																					<Label for="exampleEmail"
																						className="label-description">{p.businessnameUsers}</Label>
																				</div>
																			</div>
																		</div>
																	}
																	<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style head-label"
																				>Access request date & time</Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{moment(p.created).format("DD-MM-YYYY")} & {moment(p.created).format("HH:mm:ss")}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																		<div className="d-flex justify-content-center">
																			<div className="row d-flex justify-content-center">
																				<div className="d-flex justify-content-end pr-2">
																					<button className="btn btn-submit-track px-4"
																						onClick={() => this.newContactHandler(p.id, 1, p.emailUsers, p.chatId, p.userId, p.uidUsers, p.screenType, p.uuid)}>Approve
																					</button>
																				</div>
																				<div className="d-flex justify-content-end ">
																					<button className="btn btn-submit-package px-4"
																						onClick={() => this.newContactHandler(p.id, 2, p.emailUsers, p.chatId, p.userId, p.uidUsers, p.screenType, p.uuid)}>Reject
																					</button>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>

															</CardBody>
														</Card>

													</div>
												)
											})}
										</>}
								</div>
							</TabPane>
							<TabPane tabId="2">
								<div className="row">
									{this.state.loading === true ?
										<div className="d-flex justify-content-center">
											< ClipLoader
												size={15}
												color={"#123abc"}
												loading={true}
											/>
										</div>
										:
										<>
											{approved.map(p => {
												// console.log(p, "ppppppppp");
												// screenType
												// uidUsers
												return (
													<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 pb-4">
														<Card className="shadow border-0">
															<CardBody>
																<div className="row">
																	<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style"
																					style={{ whiteSpace: "nowrap" }}>User Name </Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{(p.firstnameUsers)}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style">Status</Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{'Accepted'}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style">Screen Access</Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{p.screenType}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style"
																					style={{ whiteSpace: "nowrap" }}>Email</Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{p.emailUsers}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style"
																					style={{ whiteSpace: "nowrap" }}>Account type</Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{p.accounttypeUsers}</Label>
																			</div>
																		</div>
																	</div>
																	{p.accounttypeUsers === 'Business' &&
																		<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																			<div className="row">
																				<div className="col-6">
																					<Label for="exampleEmail " className="pr-5 label-style"
																						style={{ whiteSpace: "nowrap" }}>Business name</Label>
																				</div>
																				<div className="col-6">
																					<Label for="exampleEmail"
																						className="label-description">{p.businessnameUsers}</Label>
																				</div>
																			</div>
																		</div>
																	}
																	<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style"
																					style={{ whiteSpace: "nowrap" }}>Accepted date & time</Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{moment(p.modified).format("DD-MM-YYYY")} & {moment(p.modified).format("HH:mm:ss")}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																		<div className="d-flex justify-content-end">
																			<div className="row d-flex justify-content-center">
																				<div className="d-flex justify-content-end ">
																					{
																						p.screenType !== "Request Free Credit" &&
																						(<button className="btn btn-submit-package px-4 mr-4"
																							onClick={() => this.changeAccessStatus(p.id, 2, p.emailUsers, p.uidUsers, p.screenType, true)}>Disable
																						</button>)
																					}
																				</div>
																			</div>
																		</div>
																	</div>
																</div>

															</CardBody>
														</Card>
													</div>
												)
											})}
										</>}
								</div>
							</TabPane>
							<TabPane tabId="3">
								<div className="row">
									{this.state.loading === true ?
										<div className="d-flex justify-content-center">
											< ClipLoader
												size={15}
												color={"#123abc"}
												loading={true}
											/>
										</div>
										:
										<>
											{rejected.map(p => {
												return (
													<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 pb-4">
														<Card className="shadow border-0">
															<CardBody>
																<div className="row">
																	<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style"
																					style={{ whiteSpace: "nowrap" }}>User Name </Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{(p.firstnameUsers)}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style">Status</Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{'Rejected'}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style">Screen Access</Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{p.screenType}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style"
																					style={{ whiteSpace: "nowrap" }}>Email</Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{p.emailUsers}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style"
																					style={{ whiteSpace: "nowrap" }}>Account type</Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{p.accounttypeUsers}</Label>
																			</div>
																		</div>
																	</div>
																	{
																		p.accounttypeUsers === 'Business' &&
																		<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																			<div className="row">
																				<div className="col-6">
																					<Label for="exampleEmail " className="pr-5 label-style"
																						style={{ whiteSpace: "nowrap" }}>Business name</Label>
																				</div>
																				<div className="col-6">
																					<Label for="exampleEmail"
																						className="label-description">{p.businessnameUsers}</Label>
																				</div>
																			</div>
																		</div>
																	}
																	<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style"
																					style={{ whiteSpace: "nowrap" }}>Rejected date & time</Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{moment(p.modified).format("DD-MM-YYYY")} & {moment(p.modified).format("HH:mm:ss")}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																		<div className="d-flex justify-content-end">
																			<div className="row d-flex justify-content-center">
																				<div className="d-flex justify-content-end pr-2">
																					{
																						p.screenType !== "Request Free Credit" &&
																						(<button className="btn btn-submit-track px-4 mr-4"
																							onClick={() => this.changeAccessStatus(p.id, 1, p.emailUsers, p.uidUsers, p.screenType)}>Enable
																						</button>)
																					}
																				</div>
																			</div>
																		</div>
																	</div>
																</div>

															</CardBody>
														</Card>

													</div>

												)
											})}
										</>}
								</div>
							</TabPane>
							<TabPane tabId="4">
								<div className="row">
									{this.state.loading === true ?
										<div className="d-flex justify-content-center">
											< ClipLoader
												size={15}
												color={"#123abc"}
												loading={true}
											/>
										</div>
										:
										<>
											{searchItems.map(p => {
												return (
													<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 pb-4">
														<Card className="shadow border-0">
															<CardBody>
																<div className="row">
																	<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style"
																					style={{ whiteSpace: "nowrap" }}>User Name </Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{(p.firstnameUsers)}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style">Status</Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">
																					{p.access_status === 0 && 'Pending'}
																					{p.access_status === 1 && 'Accepted'}
																					{p.access_status === 2 && 'Rejected'}
																				</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style">Screen Access</Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{p.screenType}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style"
																					style={{ whiteSpace: "nowrap" }}>Email</Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{p.emailUsers}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style"
																					style={{ whiteSpace: "nowrap" }}>Access request date & time</Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{moment(p.modified).format("DD-MM-YYYY")} & {moment(p.modified).format("HH:mm:ss")}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																		{p.access_status === 0 &&
																			<div className="d-flex justify-content-center">
																				<div className="row d-flex justify-content-center">
																					<div className="d-flex justify-content-end pr-2">
																						<button className="btn btn-submit-track px-4"
																							onClick={() => this.changeAccessStatus(p.id, 1, p.emailUsers, p.uidUsers, p.screenType)}>Approve
																						</button>
																					</div>
																					<div className="d-flex justify-content-end ">
																						<button className="btn btn-submit-package px-4"
																							onClick={() => this.changeAccessStatus(p.id, 2, p.emailUsers, p.uidUsers, p.screenType)}>Reject
																						</button>
																					</div>
																				</div>
																			</div>
																		}
																		{p.access_status === 1 &&
																			<div className="d-flex justify-content-center">
																				<div className="row d-flex justify-content-end">

																					<div className="d-flex justify-content-end ">
																						<button className="btn btn-submit-package px-4 mr-4"
																							onClick={() => this.changeAccessStatus(p.id, 2, p.emailUsers, p.uidUsers, p.screenType)}>Disable
																						</button>
																					</div>
																				</div>
																			</div>
																		}
																		{p.access_status === 2 &&
																			<div className="d-flex justify-content-center">
																				<div className="row d-flex justify-content-end">
																					<div className="d-flex justify-content-end pr-2">
																						<button className="btn btn-submit-track px-4 mr-3"
																							onClick={() => this.changeAccessStatus(p.id, 1, p.emailUsers, p.uidUsers, p.screenType)}>Enable
																						</button>
																					</div>
																				</div>
																			</div>
																		}
																	</div>
																</div>
															</CardBody>
														</Card>
													</div>
												)
											})}
										</>}
								</div>
							</TabPane>
						</TabContent>
					</CardBody>
				</Card>
				<Modal isOpen={this.state.contactScreen} toggle={this.newContactHandler} zIndex="99999999">
					<ModalHeader toggle={this.newContactHandlerClose}>Add comments</ModalHeader>
					<ModalBody>
						<div class="input-group py-2 px-1">
							<input
								class="form-control py-2 border-right-0 border"
								type="search"
								value={this.state.comments}
								id="example-search-input"
								placeholder="Add comments"
								onChange={(e) => this.setState({ comments: e.target.value })}
							/>
						</div>
						{
							this.state.screenType2 === "Request Free Credit" && this.state.statusComment === 1 &&
							(<div class="input-group py-2 px-1">
								<input
									class="form-control py-2 border-right-0 border"
									type="number"
									id="example-search-input"
									placeholder="credits"
									onChange={(e) => this.setState({ credits: e.target.value })}
								/>
							</div>)
						}
						<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<div className="d-flex justify-content-end">
								<div className="row d-flex justify-content-center">
									<div className="d-flex justify-content-end pr-2">
										<button className="btn btn-submit-track px-4"
											disabled={this.state.loader}
											onClick={() => {
												this.changeAccessStatus()
												this.sendChatHandler()
											}}>
											Submit
										</button>
									</div>
								</div>
							</div>
						</div>
					</ModalBody>
				</Modal>
			</div>
		)
	}
}
