import React, { Component } from 'react';
import './PurchaseInvoice.css';
import moment from 'moment';


export default class PrintFile extends Component {
    state = {
        userInfo: {}
    }
    async componentDidMount() {
        const data = await localStorage.getItem('auth');
        this.setState({ userInfo: JSON.parse(data) })

    }
    render() {
        const info = this.props.format;

        const userInfo = this.state.userInfo;
        return (
            <div className="section1 row">
                {/* <div className="col-2" /> */}
                <div className="col-12 margin_005">
                    <div className="borderTop"></div>
                    <div className="innerContent">
                        <div className="margin_30">
                            <div className="cls_002">
                                <span className="cls_002">Smyna Pty Ltd</span>
                            </div>
                            <div className="cls_003"><span className="cls_003">Merrylandsaaa</span>
                            </div>
                            <div className="cls_003"><span className="cls_003">NSW Australia</span></div>
                            {/* <div className="cls_003"><span className="cls_003">Tel:
                                0410902852</span>
                            </div> */}
                        </div>
                        <div className="row margin_30">
                            <div className="col-6">
                                <div className="cls_004"><span className="cls_004">Invoice</span>
                                </div>
                                <div className="cls_005"><span className="cls_005">Created on {" "}
                                    {moment(info.created).format('MMMM Do YYYY, h:mm:ss a')}</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <span className="cls_006">Payee Details</span>
                                <span className="cls_003">{info.firstnameUsers}  {info.lastnameUsers}</span>
                                <span className="cls_003">{info.emailUsers}</span>
                            </div>
                            <div className="col-4">
                                <span className="cls_006">Amount Spent</span>
                                <span className="cls_003">${info.total_amount}</span>
                            </div>
                            <div className="col-4">
                                <span className="cls_006">Invoice #</span>
                                <span className="cls_003">{info.invoice_number}</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <span className="cls_006">Payment Status</span>
                                <span className="cls_003">{info.payment_status}</span>
                            </div>
                            <div className="col-4">
                                <span className="cls_006">Invoice Type</span>
                                <span className="cls_003">Invoice</span>
                            </div>
                            <div className="col-4">
                                <span className="cls_006">Credits</span>
                                <span className="cls_003">{info.credits}</span>
                            </div>
                        </div>

                        <div className="border_002" />
                        <div className="row" />
                        <div className="row">
                            <div className="col-6">
                                <div className="cls_010">
                                    <span className="cls_006">Payment details</span>
                                </div>
                                <div className="cls_010">
                                    <span className="cls_010">Bank Name: National Australia Bank</span>
                                </div>
                                <div className="cls_010">
                                    <span className="cls_010">BIC/SWIFT Code: NATAAU3303M</span>
                                </div>
                                <div className="cls_010">
                                    <span className="cls_010">Account Name: Smyna Pty Ltd</span>
                                </div>
                                <div className="cls_010">
                                    <span className="cls_010">BSB: 082112</span>
                                </div>
                                <div className="cls_010">
                                    <span className="cls_010">Account No. 298888224</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-2" /> */}
            </div>
        );
    }
}
