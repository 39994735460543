import React, { Component, Fragment } from 'react'
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, FormGroup, Input, Label } from 'reactstrap'
import { updateTemplate } from '../../api/Form';
import inputData from "./tempData.json"


var removeData = []

export default class EditTemplate extends Component {
    state = {
        inputList: [],
        tempName: "",
        isDisabled: false,
        filedOptions: [],
        userId: "",
        tempId: ""
    };

    async componentDidMount() {
        const userId = await localStorage.getItem('userId');
        this.setState({ userId })
        const allData = this.props.location.state;
        const { custom_fields } = allData
        this.setState({ filedOptions: JSON.parse(custom_fields) })
        this.setState({ tempName: allData.name, tempId: allData.id })
    }

    handleListAdd = (e) => {
        let flag = false
        this.state.inputList.forEach(element => {
            if (element.key === e.target.value) {
                flag = true
            }
        });
        this.state.filedOptions.forEach(element => {
            if (element.key === e.target.value) {
                flag = true
            }
        });
        if (!flag) {
            this.setState({
                inputList: ([
                    ...this.state.inputList,
                    {
                        key: e.target.value,
                        label_name: e.target.selectedOptions[0].label,
                        type: e.target[e.target.selectedIndex].id
                    }
                ])
            });
        }
        else {
            toast.error("Field has been already added", {
                position: toast.POSITION.TOP_RIGHT
            })
        }
    }

    handleListRemove = (e) => {
        removeData.push(e)
    }


    handleInputChange = (event, index) => {
        const { value } = event.target
        const newInputList = [...this.state.inputList]
        newInputList[index].input = value
        newInputList[index].key = index + 1
        newInputList[index].label_name = index + 1
        this.state.inputList(newInputList)
    }


    handleRemoveItem = (index) => {
        const newList = [...this.state.inputList]
        newList.splice(index, 1)
        this.setState({ inputList: newList })
    }
    handleRemoveItemOld = (index) => {
        const newList = [...this.state.filedOptions]
        newList.splice(index, 1)
        this.setState({ filedOptions: newList })
    }

    sendApiCall = async (values) => {
        const allData = [...this.state.inputList, ...this.state.filedOptions]
        await updateTemplate(
            {
                userId: this.state.userId,
                name: this.state.tempName,
                customFields: allData,
                removeFields: removeData,
                id: this.state.tempId
            }
        ).then((response) => {
            this.setState({ loading: false })
            this.props.history.push('/admin/index');
            toast.success('Template update successfully!!', {
                position: toast.POSITION.TOP_RIGHT
            })
        }).catch((err) => {
            this.setState({ loading: false })
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            })
        })
    }

    editTemplate = (values) => {
        this.setState({ loading: true });
        this.sendApiCall(values);
    }

    render() {
        const { filedOptions, tempName } = this.state
        return (
            <div className="mt-2 style">
                <Card>
                    <CardHeader className="c-header">Edit Template</CardHeader>
                    <CardBody>
                        <div className="col-12">
                            <Label className="mb-2">Name</Label>
                            <Input
                                type="text"
                                id="outlined-basic"
                                placeholder="Template Name"
                                variant="outlined"
                                value={tempName}
                                onChange={(e) => this.setState({ tempName: e.target.value })}
                            />
                        </div>
                        <div>
                            <div className="col-12 custom-filed-select input-group">
                                <Label className="mb-2 mt-2">Select Custom Fields</Label>
                                <select onChange={(e) => this.handleListAdd(e)}>
                                    <option hidden>Select custom fields</option>
                                    {
                                        inputData && inputData.map((item) => {
                                            return (
                                                <option value={item.value} id={item.type} key={item.value}>{item.label}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-12">
                            {filedOptions && filedOptions.map((input, index) => {
                                return (
                                    <Fragment key={index}>
                                        <Label className="mb-2 mt-2">{input.label_name}</Label>
                                        <div className="input-group justify-content-between">
                                            {
                                                input.type === "text" && input.key !== "dimension" &&
                                                <Input
                                                    type={input.type}
                                                    id="outlined-basic"
                                                    placeholder={input.label_name}
                                                    variant="outlined"
                                                    onChange={(event) => this.handleInputChange(event, index)}
                                                    disabled
                                                />
                                            }
                                            {
                                                input.type === "text" && input.key === "dimension" &&
                                                <div className='dimensionBox'>
                                                    <div className="row">
                                                        <div className="col-lg-4 col-xs-12 col-sm-12 mb-sm-3">
                                                            <Input type={input.type}
                                                                variant="outlined"
                                                                placeholder="Enter Height of Dimension"
                                                                disabled />
                                                        </div>
                                                        <div className="col-lg-4 col-xs-12 col-sm-12 mb-sm-3">
                                                            <Input type={input.type}
                                                                className="mt-sm-3 mt-lg-0"
                                                                variant="outlined"
                                                                placeholder="Enter Width of Dimension"
                                                                disabled />
                                                        </div>
                                                        <div className="col-lg-4 col-xs-12 col-sm-12 ">
                                                            <Input type={input.type}
                                                                className="mt-sm-3 mt-lg-0"
                                                                variant="outlined"
                                                                placeholder="Enter Length of Dimension"
                                                                disabled />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                input.type === "select" &&
                                                <div className='custom-filed-select-2'>
                                                    <select disabled>
                                                        <option hidden>{`Select ` + input.label_name}</option>
                                                        <option value="vshg">sdgf</option>
                                                    </select>
                                                </div>
                                            }
                                            {
                                                input.key !== "ref_no" && index !== 0 &&
                                                <button className="btn btn-primary" onClick={() => {
                                                    this.handleRemoveItemOld(index)
                                                    this.handleListRemove(input.key)
                                                }} style={{ marginLeft: "0px", height: "38px" }}>
                                                    <span role="img" aria-label="x emoji">
                                                        ❌
                                                    </span>
                                                </button>
                                            }

                                        </div>
                                    </Fragment>
                                )
                            })
                            }
                        </div>
                        <div className="col-12">
                            {this.state.inputList.length > 0
                                ? this.state.inputList.map((input, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <Label className="mb-2 mt-2">{input.label_name}</Label>
                                            <div className="input-group flex-data-select">
                                                {
                                                    input.type === "text" && input.key !== "dimension" &&
                                                    <Input
                                                        type={input.type}
                                                        id="outlined-basic"
                                                        placeholder={input.label_name}
                                                        variant="outlined"
                                                        onChange={(event) => this.handleInputChange(event, index)}
                                                        disabled
                                                    />
                                                }
                                                {
                                                    input.type === "text" && input.key === "dimension" &&
                                                    <div className='dimensionBox'>
                                                        <div className="row">
                                                            <div className="col-lg-4 col-xs-12 col-sm-12 mb-sm-3">
                                                                <Input type={input.type}
                                                                    variant="outlined"
                                                                    placeholder="Enter Height of Dimension"
                                                                    disabled
                                                                />
                                                            </div>
                                                            <div className="col-lg-4 col-xs-12 col-sm-12 mb-sm-3">
                                                                <Input type={input.type}
                                                                    className="mt-sm-3 mt-lg-0"
                                                                    variant="outlined"
                                                                    placeholder="Enter Width of Dimension"
                                                                    disabled
                                                                />
                                                            </div>
                                                            <div className="col-lg-4 col-xs-12 col-sm-12 ">
                                                                <Input type={input.type}
                                                                    className="mt-sm-3 mt-lg-0"
                                                                    variant="outlined"
                                                                    placeholder="Enter Length of Dimension"
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    input.type === "select" &&
                                                    <div className='custom-filed-select-2'>
                                                        <select disabled>
                                                            <option hidden>{`Select ` + input.label_name}</option>
                                                            <option value="vshg">sdgf</option>
                                                        </select>
                                                    </div>
                                                }
                                                {
                                                    <button className="btn btn-primary" onClick={() => {
                                                        this.handleRemoveItem(index)
                                                    }} style={{ marginLeft: "5px", height: "38px !important" }}>
                                                        <span role="img" aria-label="x emoji">
                                                            ❌
                                                        </span>
                                                    </button>
                                                }
                                            </div>
                                        </Fragment>
                                    )
                                })
                                :
                                null
                            }
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                            <button className="btn btn-primary d-flex align-items-center" onClick={() => this.editTemplate()}>Update Template</button>
                        </div>
                    </CardBody>
                </Card>
            </div >
        )
    }
}
