import React, { Component } from 'react'
import { Card, CardBody, CardHeader, FormFeedback, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { getLocation, trim, makeid, phoneNoVerifyCheck } from '../../utils/utils';
import { getIpAddress, getTrackingNimber, getUserActivities } from '../../api/Form';
import { toast } from 'react-toastify';
import { ClipLoader } from "react-spinners";
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTableExtensions from 'react-data-table-component-extensions';
import moment from 'moment';
import classnames from 'classnames';


export default class UserActivity extends Component {
  input = React.createRef();
  state = {
    userId: '',
    deviceADcode: '',
    nfcADcode: '',
    nfcserialno: '',
    document: null,
    loading: false,
    data: [],
    showData: [],
    columns: [],
    columns1: [],
    notLoginShowData: [],
    location: 'system_generated',
    showTable: true,
    requestAccess: true,
    startDate: '',
    endDate: '',
    activeTab: '1',
    userType: ""
  }

  async componentDidMount() {
    const historyPush = this.props.history.push
    phoneNoVerifyCheck(historyPush)
    const data = await localStorage.getItem('auth');
    const { id, ADCode } = JSON.parse(data);
    var userType = await localStorage.getItem('accounttypeUsers');
    this.setState({ userId: id, deviceADcode: ADCode, userType: userType });
    getUserActivities(
      {
        'userId': id,
        'userType': userType
      }
    ).then((response) => {
      this.setState({ loading: false, showData: response.data.data });
    }).catch(err => {
      this.setState({ loading: false });
    });

    if (userType === "Admin") {
      getIpAddress(
        {
          'user_id': id,
          'device_ad_code': ADCode
        }
      ).then((response) => {
        this.setState({ loading: false, notLoginShowData: response.data.data });
      }).catch(err => {
        this.setState({ loading: false });
      });
    }


    let columns = [
      {
        name: 'Id',
        selector: 'id',
        sortable: true,
      },
      {
        name: 'User AdCode',
        selector: 'user_adCode',
        sortable: true,
      },
      {
        name: 'Page Name',
        selector: 'page_name',
        sortable: true,
      },
      {
        name: 'Description',
        selector: 'description',
        sortable: true,
      },
      {
        name: 'Location',
        selector: 'location',
        sortable: true,
      },
      {
        name: 'Created At',
        selector: 'created',
        sortable: true,
      }
    ];
    this.setState({ columns });

    let columns1 = [
      {
        name: 'Id',
        selector: 'id',
        sortable: true,
      },
      {
        name: 'User IP',
        selector: 'ip',
        sortable: true,
      },
      {
        name: 'Page Name',
        selector: 'page_name',
        sortable: true,
      },
      {
        name: 'City',
        selector: 'city',
        sortable: true,
      },
      {
        name: 'Region',
        selector: 'region',
        sortable: true,
      },
      {
        name: 'Country',
        selector: 'country',
        sortable: true,
      },
      {
        name: 'Created At',
        selector: 'createdAt',
        sortable: true,
      },
      {
        name: 'Timezone',
        selector: 'timezone',
        sortable: true,
      },
      {
        name: 'Network',
        selector: 'org',
        sortable: true,
      }
    ];
    this.setState({ columns1: columns1 });
  }

  nfcADcodeHandler = () => {
    this.setState({ modal: !this.state.modal })
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  render() {
    const { loading, data, columns, columns1, showTable, requestAccess, showData, notLoginShowData } = this.state;

    const tableData = {
      columns,
      exportHeaders: true,
      data: showData,
    };

    const tableData2 = {
      columns: columns1,
      // exportHeaders: true,
      data: notLoginShowData,
    };
    return (
      <div>
        {!requestAccess ? <div className="justify-content-center pt-2 main-contain-title">
          <>
            <p>This feature is restricted.</p>
            <Button onClick={() => this.sendRequestAccess()}>Request Access for free</Button>
          </>
        </div> :
          <>
            <div className="d-flex justify-content-center scan-parcel-loader">
              <ClipLoader
                size={50}
                color={"#123abc"}
                loading={loading}
              />
            </div>
            {!loading ?
              <Card>
                {/* <CardHeader className="c-header">View User Activity</CardHeader> */}
                <CardBody>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => {
                          this.toggle('1');
                        }}
                      >
                        View User Activity
                      </NavLink>
                    </NavItem>
                    {
                      this.state.userType === "Admin" ?
                        <NavItem>
                          <NavLink
                            className={classnames({ active: this.state.activeTab === '2' })}
                            onClick={() => {
                              this.toggle('2');
                            }}
                          >
                            View Not Login User Activity
                          </NavLink>
                        </NavItem>
                        :
                        null
                    }
                  </Nav>
                  <>
                    <TabContent activeTab={this.state.activeTab} >
                      <TabPane tabId="1">
                        {showTable ?
                          <DataTableExtensions  {...tableData}>
                            <DataTable
                              className="dataTables_wrapper"
                              pagination
                              highlightOnHover
                              columns={columns}
                              export={true}
                              responsive={true}
                              data={data}
                            />
                          </DataTableExtensions> : null}
                      </TabPane>
                    </TabContent>
                    {
                      this.state.userType === "Admin" ?
                        <TabContent activeTab={this.state.activeTab} >
                          <TabPane tabId="2">
                            <DataTableExtensions  {...tableData2}>
                              <DataTable
                                className="dataTables_wrapper"
                                pagination
                                highlightOnHover
                                columns={columns1}
                                export={true}
                                responsive={true}
                                data={data}
                              />
                            </DataTableExtensions>
                          </TabPane>
                        </TabContent>
                        : null
                    }
                  </>
                </CardBody>
              </Card>
              : null}
          </>
        }

      </div>
    )
  }
}
