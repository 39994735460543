import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './DeliveryExceptionHub.css'

export default class DeliveryExceptionHub extends Component {
  render() {
    return (
      <div className=" w-100 h-100">
        <div className="container">
          <div className="row pt-5">
            <Link class="btn btn-back" to="/"><i class="fa fa-long-arrow-left pr-3"> </i> Go Back</Link>
            {/* <div className="ml-auto">
            <span className="email-style"><i class="fa fa-envelope  " aria-hidden="true"></i> support@addressdynamic.com</span>
          </div> */}
          </div>
          <hr />
          <div className="main-contain-style ">
            <div className="row">
              <div className="col-6 pb-4 btn-block">
                <Link to="" className="btn btn-primary btn-block">I received the Wrong Parcel</Link>
              </div>
              <div className="col-6 pb-4 btn-block">
                <Link to="" className="btn btn-primary btn-block">My Parcel is Missing</Link>
              </div>
              <div className="col-6 pb-4 btn-block">
                <Link to="" className="btn btn-primary btn-block">I Want a Parcelguard Service</Link>
              </div>
              <div className="col-6 pb-4 btn-block">
                <Link to="" className="btn btn-primary btn-block"> Missing & Lost Parcel Reconciliation for Couriers</Link>
              </div>
              <div className="col-6 pb-4 btn-block">
                <Link to="" className="btn btn-primary btn-block">Lost Order Reconciliation for Ecommerce </Link>
              </div>
              <div className="col-6 pb-4 btn-block">
                <Link to="" className="btn btn-primary btn-block">Delivery Exception Partners </Link>
              </div>
              <div className="col-6 pb-4 btn-block">
                <Link to="" className="btn btn-primary btn-block">Get AD Code & Never Lose a Parcel Again </Link>
              </div>
              <div className="col-6 pb-4 btn-block">
                <Link to="" className="btn btn-primary btn-block"> Parcel Tracking by GPS</Link>
              </div>
              <div className="col-6 pb-4 btn-block">
                <Link to="" className="btn btn-primary btn-block">SMS Notification Service</Link>
              </div>
              <div className="col-6 pb-4 btn-block">
                <Link to="" className="btn btn-primary btn-block">Return To Homepage </Link>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <Link to="/ourparcelguardservice" className="link-style">what is parcelguard? Learn more</Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
