import React, { Component, Fragment } from 'react'
import { CardHeader, CardBody, FormGroup, Label, Input, Card, FormFeedback, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { Formik } from 'formik';
import { ClipLoader } from "react-spinners";
import { lookUpAirway, requestAccess, checkScreenAccess } from '../../api/Form';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import DataTableExtensions from 'react-data-table-component-extensions';
import { callActivityApi } from '../../utils/saveUserActivity';
import { phoneNoVerifyCheck } from '../../utils/utils';

export default class LookupAirway extends Component {
  input = React.createRef();
  state = {
    deviceADcode: '',
    airwayBill: '',
    userId: '',
    refno: '',
    trackingnoall: '',
    modal: false,
    nfcADcode: '',
    loading: true,
    customErr: '',
    requestAccess: false,
    showTable: false,
  }

  addClick = (array) => {
    array.insert(1, {
      nfcADcode: ''
    })
  }

  async componentDidMount() {
    callActivityApi({ 'pageName': 'Lookup Airway', 'description': 'User has visited Lookup Airway Screen' });
    const historyPush = this.props.history.push
    phoneNoVerifyCheck(historyPush)
    const deviceADcode = await localStorage.getItem('adCodes');
    this.setState({ deviceADcode })
    const userId = await localStorage.getItem('userId');
    this.setState({ userId })
    var userType = await localStorage.getItem('accounttypeUsers');
    if (userType !== 'Admin') {
      checkScreenAccess(
        {
          'userId': userId,
          'screenType': 'Lookup Airway'
        }
      ).then((response) => {
        this.setState({ loading: false, requestAccess: true });
      }).catch(err => {
        this.setState({ loading: false, requestAccess: false });
      });
    } else {
      this.setState({ requestAccess: true, loading: false })
    }
  }

  submitHandler = (values, setFieldValue) => {
    if (values.refno || values.airwayBill || values.trackingnoall) {
      const data = {
        airwayBill: values.airwayBill,
        refno: values.refno,
        trackingno: values.trackingnoall
      };
      lookUpAirway(data)
        .then(Response => {
          if (Response.data.data.length) {
            if (values.refno) {
              let columns = [
                {
                  name: 'Airline',
                  selector: 'airline',
                  sortable: true,
                },
                {
                  name: 'Flight Number',
                  selector: 'flight_number',
                  sortable: true,
                },
                {
                  name: 'Origin',
                  selector: 'origin',
                  sortable: true,
                },
                {
                  name: 'Destination',
                  selector: 'destination',
                  sortable: true,
                },
                {
                  name: 'From Country',
                  selector: 'from_country',
                  sortable: true,
                },
                {
                  name: 'To Country',
                  selector: 'to_country',
                  sortable: true,
                },
                {
                  name: 'Master Airway Number',
                  selector: 'masterairway_number',
                  sortable: true,
                },
              ];
              this.setState({
                columns,
                showData: Response.data.data,
                loading: false,
                showTable: true
              })
            }
            if (values.airwayBill || values.trackingnoall) {

              let columns = [
                {
                  name: 'Tracking Number',
                  selector: 'refno',
                  sortable: true,
                },
                {
                  name: 'Quantity',
                  selector: 'quantity',
                  sortable: true,
                },
                {
                  name: 'Comment',
                  selector: 'comment',
                  sortable: true,
                },
                {
                  name: 'Tracking Status',
                  selector: 'trackingstatus',
                  sortable: true,
                },
                {
                  name: 'Origin',
                  selector: 'origin',
                  sortable: true,
                },
                {
                  name: 'Destination',
                  selector: 'destination',
                  sortable: true,
                },
                {
                  name: 'Type',
                  selector: 'type',
                  sortable: true,
                },
                {
                  name: 'Delivery Address',
                  selector: 'deliveryAddress',
                  sortable: true,
                },
                {
                  name: 'Pickup',
                  selector: 'pickup',
                  sortable: true,
                },
                {
                  name: 'Carrier Name',
                  selector: 'carrierName',
                  sortable: true,
                },
                {
                  name: 'Receiver Name',
                  selector: 'receiverName',
                  sortable: true,
                },
                {
                  name: 'Weight',
                  selector: 'weight',
                  sortable: true,
                },
                {
                  name: 'Height',
                  selector: 'height',
                  sortable: true,
                },
                {
                  name: 'Width',
                  selector: 'width',
                  sortable: true,
                },
                {
                  name: 'Length',
                  selector: 'length',
                  sortable: true,
                },
                {
                  name: 'Package Type',
                  selector: 'packageType',
                  sortable: true,
                },
                {
                  name: 'Receiver Business Name',
                  selector: 'receiverBusinessName',
                  sortable: true,
                },
                {
                  name: 'Receiver Email',
                  selector: 'receiverEmail',
                  sortable: true,
                },
                {
                  name: 'Customer Ref no',
                  selector: 'customerRefNo',
                  sortable: true,
                },
                {
                  name: 'Package Currency',
                  selector: 'packageCurrency',
                  sortable: true,
                },
                {
                  name: 'Package Value',
                  selector: 'packageValue',
                  sortable: true,
                },
                {
                  name: 'Reciever Phone',
                  selector: 'receiverPhone',
                  sortable: true,
                },
                {
                  name: 'Shipment Type',
                  selector: 'shipmentType',
                  sortable: true,
                },
                {
                  name: 'Origin Country',
                  selector: 'originCountry',
                  sortable: true,
                },
                {
                  name: 'Destination Country',
                  selector: 'destinationCountry',
                  sortable: true,
                },
                {
                  name: 'Shipment Description',
                  selector: 'shipmentDescription',
                  sortable: true,
                },
                {
                  name: 'Phone Number1',
                  selector: 'phoneNumber1',
                  sortable: true,
                },
                {
                  name: 'EmailAddress1',
                  selector: 'emailAddress1',
                  sortable: true,
                },
              ];
              this.setState({
                columns,
                showData: Response.data.data,
                loading: false,
                showTable: true
              })
            }
          } else {
            setFieldValue('airwayBill', '');
            setFieldValue('refno', '');
            setFieldValue('trackingnoall', '');
            toast.error('Data not found!!', {
              position: toast.POSITION.TOP_RIGHT
            });
            this.setState({
              showTable: false
            })
          }

        }).catch(err => {
          this.setState({ loading: false, showTable: false });
          let message = err.message;
          if (err.response && err.response.data.message) {
            message = err.response.data.message;
          }
          setFieldValue('airwayBill', '');
          setFieldValue('refno', '');
          setFieldValue('trackingnoall', '');
          toast.error(message, {
            position: toast.POSITION.TOP_RIGHT
          });
        })
    } else {
      toast.error('Please enter value in one field alteast', {
        position: toast.POSITION.TOP_RIGHT
      });

    }
  }

  sendRequestAccess = async () => {
    this.setState({ loading: true });
    const info = await localStorage.getItem('auth');
    const sendInfo = JSON.parse(info);
    requestAccess({
      'username': sendInfo.username,
      'firstName': sendInfo.firstName,
      'lastName': sendInfo.lastName,
      'accounttypeUsers': sendInfo.accounttypeUsers,
      'businessname': sendInfo.businessname,
      'ADCode': sendInfo.ADCode,
      'address': sendInfo.address,
      'email': sendInfo.email,
      'phoneNumber': sendInfo.phonenumber,
      'userId': sendInfo.id,
      'screenType': 'Lookup Airway',
    }).then(Response => {
      this.setState({ loading: false });
      toast.success(Response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }).catch(err => {
      this.setState({ loading: false });
      let message = err.message;
      if (err.response && err.response.data.message) {
        message = err.response.data.message;
      }
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })

  };

  render() {
    const { scan, requestAccess, showTable, columns, showData } = this.state;
    const tableData = {
      columns,
      exportHeaders: true,
      data: showData,
    };
    return (
      <div>
        {!requestAccess ?
          <div className="justify-content-center pt-2 main-contain-title">
            <>
              <p>This feature is restricted.</p>
              <Button onClick={() => this.sendRequestAccess()}
                disabled={this.state.loading}>Request Access for free</Button>
            </>
          </div>
          :
          <>
            <div className="d-flex justify-content-center scan-parcel-loader">
              <ClipLoader
                size={50}
                color={"#123abc"}
                loading={this.state.loading}
              />
            </div>
            <Formik
              enableReinitialize
              initialValues={{
                refno: this.state.refno,
                airwayBill: this.state.airwayBill,
              }}
              onSubmit={(values, { setSubmitting, setErrors, setFieldValue }) => {
                this.setErrors = setErrors;
                this.submitHandler(values, setFieldValue);
              }}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                handleBlur,
                setFieldValue,
                status,
                isSubmitting, }) => (
                <Fragment>
                  <div className={this.state.loading ? "mt-2 style loadingDiv" : "mt-2 style"}>
                    <Card>
                      <CardHeader className="c-header">Lookup Tracking Number</CardHeader>
                      <CardBody>
                        <div className="col-12">
                          <FormGroup>
                            <Label>Tracking Numbers (Master assigned)</Label>
                            <Input
                              type="text"
                              disabled={values.airwayBill || values.trackingnoall ? true : false}
                              invalid={errors.refno && touched.refno}
                              name="refno"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.refno}
                              placeholder="Enter Tracking no" />
                            <FormFeedback>
                              {(errors.refno && touched.refno && errors.refno) || this.state.customErr}
                            </FormFeedback>
                          </FormGroup>
                        </div>
                        <div className="col-12 text-center">
                          <Label>------------- OR -------------</Label>
                        </div>
                        <div className="col-12">
                          <FormGroup>
                            <Label>Master Airwaybill</Label>
                            <Input
                              type="text"
                              disabled={values.refno || values.trackingnoall ? true : false}
                              invalid={errors.airwayBill && touched.airwayBill}
                              name="airwayBill"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.airwayBill}
                              placeholder="Enter Master Airwaybill" />
                            <FormFeedback>
                              {errors.airwayBill && touched.airwayBill && errors.airwayBill}
                            </FormFeedback>
                          </FormGroup>
                        </div>
                        <div className="col-12 text-center">
                          <Label>------------- OR -------------</Label>
                        </div>
                        <div className="col-12">
                          <FormGroup>
                            <Label>Search Tracking Number</Label>
                            <Input
                              type="text"
                              disabled={values.refno || values.airwayBill ? true : false}
                              invalid={errors.trackingnoall && touched.trackingnoall}
                              name="trackingnoall"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.trackingnoall}
                              placeholder="Enter Tracking no" />
                            <FormFeedback>
                              {errors.trackingnoall && touched.trackingnoall && errors.trackingnoall}
                            </FormFeedback>
                          </FormGroup>
                        </div>
                        <div className="d-flex justify-content-center">
                          <button className="btn btn-submit" onClick={handleSubmit} >Submit</button>
                        </div>

                        {showTable ?
                          <Card className="mt-5">
                            <CardHeader className="c-header">{values.refno ? 'Master Airway Details' : 'Tracking Numbers'}</CardHeader>
                            <DataTableExtensions  {...tableData}>
                              <DataTable
                                noHeader
                                pagination
                                highlightOnHover
                                columns={columns}
                                exportHeaders={true}
                                export={true}


                              />
                            </DataTableExtensions>
                          </Card> : null}
                      </CardBody>
                    </Card>
                  </div>
                </Fragment>

              )}
            </Formik>
          </>
        }
      </div>
    )
  }
}
