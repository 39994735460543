import React, { Component } from 'react';
import { CardHeader, CardBody, FormGroup, Label, Input, Card, FormFeedback } from 'reactstrap';
import { ClipLoader } from "react-spinners";
import { userProfile } from '../../api/Form';
import { toast } from 'react-toastify';

class Setting extends Component {
    state = {
        accountType: '',
        loading: false
    }
    accountTypeHandler = (key) => {
        this.setState({ accountType: key });
    }

    handleSubmit = async () => {
        const userId = await localStorage.getItem('userId');
        const token = await localStorage.getItem('token');
        userProfile({ userId, token, accountType: this.state.accountType })
            .then(Response => {
                this.setState({
                    firstName: Response.data.data.firstName,
                    lastName: Response.data.data.lastName,
                    businessname: Response.data.data.businessname,
                    credit: Response.data.data.credit,
                    creditStatus: Response.data.data.creditStatus,
                    email: Response.data.data.email,
                    profile: [Response.data.data.profile]
                })
            })
            .catch(err => {
                toast.error(err.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                console.log(err.response.data.message)
            })
    }
    render() {
        return (
            <div className="mt-2 style">
                <Card>
                    <CardHeader className="c-header">Setting</CardHeader>
                    <CardBody >
                        <div>
                            <Label>Account Type</Label>
                            <div className="d-flex m-4">
                                <div class="radio-item">
                                    <input type="radio" id="ritema" name="ritem" value="ropt1" defaultChecked checked={this.state.accountType === 'Standard'} onChange={() => this.accountTypeHandler("Standard")} />
                                    <label for="ritema">Standard</label>
                                </div>

                                <div class="radio-item ml-5">
                                    <input type="radio" id="ritemb" name="ritem" value="ropt2" checked={this.state.accountType === 'Premium'} defaultChecked onChange={() => this.accountTypeHandler("Premium")} />
                                    <label for="ritemb">Premium</label>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center">
                            <button className="btn btn-submit" onClick={this.handleSubmit}>
                                {this.state.loading ?
                                    < ClipLoader
                                        size={15}
                                        color={"#123abc"}
                                        loading={true}
                                    /> : 'Save'
                                }
                            </button>
                        </div>

                    </CardBody>
                </Card>
            </div>
        )
    }
}

export default Setting;