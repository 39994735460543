import React, { Component } from 'react'
import { Card, CardBody, Label, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import './../TrackShipments/TrackShipments.css'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { incomingShipments, outgoingShipments, trackShipment, courierDashBoard } from '../../api/Form';
import moment from 'moment';
import { trim } from '../../utils/utils';
import { ClipLoader } from "react-spinners";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import product from '../../assets/product.png'

export default class CourierDash extends Component {
	state = {
		activeTab: '1',
		deviceAdCode: '',
		value: '',
		all: [],
		current: [],
        rejected: [],
        accepted: [],
        completed: [],	
		refno: '',
		loading: true,
		trackShipmentModal: false

	}


	packageHandler = (refno) => {
		this.props.history.push({ pathname: './outGoingPackageHandler', state: refno })
	}

	incomingPackageHandler = (refno) => {
		this.props.history.push({ pathname: './incomingPackageHandler', state: refno })
	}

	async componentDidMount() {
		const deviceAdCode = await localStorage.getItem('adCodes');
		this.setState({ deviceAdCode })
		//     const userId = await localStorage.getItem('userId');
		//     console.log(userId)
		//     this.setState({ userId })
		// console.log(deviceADcode)
		// incomingShipments({ deviceAdCode })
		// 	.then(Response => {
		// 		this.setState({ incomingShipments: Response.data.data })
		// 	})
		// 	.catch(err => {
		// 		console.log(err)
		// 	})
		courierDashBoard({ 'deviceAdCode': deviceAdCode})
			.then(Response => {
                //console.log('response', Response.data.data);
				this.setState({ 
                    all: Response.data.data.all, 
                    current: Response.data.data.current, 
                    completed: Response.data.data.completed, 
                    rejected: Response.data.data.rejected, 
                    accepted: Response.data.data.accepted, 
                    loading: false })
			})
			.catch(err => {
				console.log(err)
				this.setState({ 
                    loading: false 
				});
			})
	}

	handleChange = (e) => {
		this.setState({ refno: e.target.value })
	}


	toggle = (tab) => {
		if (this.state.activeTab !== tab) {
			this.setState({ activeTab: tab });
		}
	}

	outgoingTrackHandler = (nfcserialno) => {
		this.props.history.push({ pathname: './trackShipmentsDetails', state: nfcserialno })
	}

	incomingTrackHandler = (nfcserialno) => {
		this.props.history.push({ pathname: './trackShipmentsDetailsIncoming', state: nfcserialno })
	}


	render() {
		const all = this.state.all.map(p => p)
		const completed = this.state.completed.map(p => p)
        const rejected = this.state.rejected.map(p => p)
        const accepted = this.state.accepted.map(p => p)
        const current = this.state.current.map(p => p)
		return (
			<div className="mt-2 style">
				<Card>
					<CardBody>
						<Nav tabs>
							<NavItem>
								<NavLink
									className={classnames({ active: this.state.activeTab === '1' })}
									onClick={() => {
										this.toggle('1');
									}}
								>
									All
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classnames({ active: this.state.activeTab === '2' })}
									onClick={() => {
										this.toggle('2');
									}}
								>
									Current
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classnames({ active: this.state.activeTab === '3' })}
									onClick={() => {
										this.toggle('3');
									}}
								>
									Rejected
								</NavLink>
							</NavItem>
                            <NavItem>
								<NavLink
									className={classnames({ active: this.state.activeTab === '4' })}
									onClick={() => {
										this.toggle('4');
									}}
								>
									Accepted
								</NavLink>
							</NavItem>
                            <NavItem>
								<NavLink
									className={classnames({ active: this.state.activeTab === '5' })}
									onClick={() => {
										this.toggle('5');
									}}
								>
									Completed
								</NavLink>
							</NavItem>
						</Nav>
						<TabContent activeTab={this.state.activeTab} className="pt-5">
							<TabPane tabId="1">
                            <div className="row">
									{this.state.loading === true ?
										<div className="d-flex justify-content-center">
											< ClipLoader
												size={15}
												color={"#123abc"}
												loading={true}
											/>
										</div>
										:
										<>
											{all.map(p => {
												return (
													<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 pb-4">
														<Card className="shadow border-0">
															<CardBody>
																<div className="row">
																	<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style"
																					style={{ whiteSpace: "nowrap" }}>AD Code </Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{trim(p.partner_aDCode, 13)}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style"
																					style={{ whiteSpace: "nowrap" }}>First Name</Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{p.partner_firstName}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																		<div className="row">
																			<div className="col-3">
																				<Label for="exampleEmail " className="pr-5 label-style">Pickup Address</Label>
																			</div>
																			<div className="col-9">
																				<Label for="exampleEmail"
																					className="label-description">{p.pickupAddress}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																		<div className="row">
																			<div className="col-3">
																				<Label for="exampleEmail " className="pr-5 label-style">Delivery Address</Label>
																			</div>
																			<div className="col-9">
																				<Label for="exampleEmail"
																					className="label-description">{p.deliveryAddress}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style">Payment Method</Label>
																			</div>
																			<div className="col-6">
																				<span
																					class="badge badge-pill badge-primary font-weight-light px-2">{p.paymentType}</span>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-5">
																				<Label for="exampleEmail " className="pr-5 label-style">Tracking#</Label>
																			</div>
																			<div className="col-7">
																				<Label for="exampleEmail"
																					className="label-description">{p.refno}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style">Amount</Label>
																			</div>
																			<div className="col-6">
																				<span
																					class="badge badge-pill badge-primary font-weight-light px-2">{p.amount}</span>
																			</div>
																		</div>
																	</div>
                                                                    
																</div>

															</CardBody>
														</Card>

													</div>

												)
											})}
										</>}
								</div>
							</TabPane>
							<TabPane tabId="2">
                                <div className="row">
                                        {this.state.loading === true ?
                                            <div className="d-flex justify-content-center">
                                                < ClipLoader
                                                    size={15}
                                                    color={"#123abc"}
                                                    loading={true}
                                                />
                                            </div>
                                            :
                                            <>
                                                {current.map(p => {
                                                    return (
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 pb-4">
                                                            <Card className="shadow border-0">
                                                                <CardBody>
                                                                    <div className="row">
                                                                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                                            <div className="row">
                                                                                <div className="col-6">
                                                                                    <Label for="exampleEmail " className="pr-5 label-style"
                                                                                        style={{ whiteSpace: "nowrap" }}>AD Code </Label>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <Label for="exampleEmail"
                                                                                        className="label-description">{trim(p.partner_aDCode, 13)}</Label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                                            <div className="row">
                                                                                <div className="col-6">
                                                                                    <Label for="exampleEmail " className="pr-5 label-style"
                                                                                        style={{ whiteSpace: "nowrap" }}>First Name</Label>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <Label for="exampleEmail"
                                                                                        className="label-description">{p.partner_firstName}</Label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                            <div className="row">
                                                                                <div className="col-3">
                                                                                    <Label for="exampleEmail " className="pr-5 label-style">Pickup Address</Label>
                                                                                </div>
                                                                                <div className="col-9">
                                                                                    <Label for="exampleEmail"
                                                                                        className="label-description">{p.pickupAddress}</Label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                            <div className="row">
                                                                                <div className="col-3">
                                                                                    <Label for="exampleEmail " className="pr-5 label-style">Delivery Address</Label>
                                                                                </div>
                                                                                <div className="col-9">
                                                                                    <Label for="exampleEmail"
                                                                                        className="label-description">{p.deliveryAddress}</Label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <div className="row">
                                                                                <div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                                                    <Label for="exampleEmail " className="pr-5 label-style">Tracking#</Label>
                                                                                </div>
                                                                                <div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                                                    <Label for="exampleEmail"
                                                                                        className="label-description">{trim(p.trackingNum, 13)}</Label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                                            <div className="row">
                                                                                <div className="col-6">
                                                                                    <Label for="exampleEmail " className="pr-5 label-style">Amount</Label>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <span
                                                                                        class="badge badge-pill badge-primary font-weight-light px-2">{trim(p.amount, 13)}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                                            <div className="row">
                                                                                <div className="col-6">
                                                                                    <Label for="exampleEmail " className="pr-5 label-style">Payment Method</Label>
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <span
                                                                                        class="badge badge-pill badge-primary font-weight-light px-2">{p.payment_type}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </CardBody>
                                                            </Card>

                                                        </div>

                                                    )
                                                })}
                                            </>}
                                    </div>
							</TabPane>
							<TabPane tabId="3">
                            <div className="row">
									{this.state.loading === true ?
										<div className="d-flex justify-content-center">
											< ClipLoader
												size={15}
												color={"#123abc"}
												loading={true}
											/>
										</div>
										:
										<>
											{rejected.map(p => {
												return (
													<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 pb-4">
														<Card className="shadow border-0">
															<CardBody>
																<div className="row">
																	<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style"
																					style={{ whiteSpace: "nowrap" }}>AD Code </Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{trim(p.partner_aDCode, 13)}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style"
																					style={{ whiteSpace: "nowrap" }}>First Name</Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{p.partner_firstName}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																		<div className="row">
																			<div className="col-3">
																				<Label for="exampleEmail " className="pr-5 label-style">Pickup Address</Label>
																			</div>
																			<div className="col-9">
																				<Label for="exampleEmail"
																					className="label-description">{p.pickupAddress}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																		<div className="row">
																			<div className="col-3">
																				<Label for="exampleEmail " className="pr-5 label-style">Delivery Address</Label>
																			</div>
																			<div className="col-9">
																				<Label for="exampleEmail"
																					className="label-description">{p.deliveryAddress}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12">
																		<div className="row">
																			<div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3">
																				<Label for="exampleEmail " className="pr-5 label-style">Tracking#</Label>
																			</div>
																			<div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3">
																				<Label for="exampleEmail"
																					className="label-description">{trim(p.trackingNum, 13)}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style">Amount</Label>
																			</div>
																			<div className="col-6">
																				<span
																					class="badge badge-pill badge-primary font-weight-light px-2">{trim(p.amount, 13)}</span>
																			</div>
																		</div>
																	</div>
                                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style">Payment Method</Label>
																			</div>
																			<div className="col-6">
																				<span
																					class="badge badge-pill badge-primary font-weight-light px-2">{p.payment_type}</span>
																			</div>
																		</div>
																	</div>
																</div>

															</CardBody>
														</Card>

													</div>

												)
											})}
										</>}
								</div>	
							</TabPane>
                            <TabPane tabId="4">
                            <div className="row">
									{this.state.loading === true ?
										<div className="d-flex justify-content-center">
											< ClipLoader
												size={15}
												color={"#123abc"}
												loading={true}
											/>
										</div>
										:
										<>
											{accepted.map(p => {
												return (
													<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 pb-4">
														<Card className="shadow border-0">
															<CardBody>
																<div className="row">
																	<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style"
																					style={{ whiteSpace: "nowrap" }}>AD Code </Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{trim(p.partner_aDCode, 13)}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style"
																					style={{ whiteSpace: "nowrap" }}>First Name</Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{p.partner_firstName}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																		<div className="row">
																			<div className="col-3">
																				<Label for="exampleEmail " className="pr-5 label-style">Pickup Address</Label>
																			</div>
																			<div className="col-9">
																				<Label for="exampleEmail"
																					className="label-description">{p.pickupAddress}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																		<div className="row">
																			<div className="col-3">
																				<Label for="exampleEmail " className="pr-5 label-style">Delivery Address</Label>
																			</div>
																			<div className="col-9">
																				<Label for="exampleEmail"
																					className="label-description">{p.deliveryAdd}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12">
																		<div className="row">
																			<div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3">
																				<Label for="exampleEmail " className="pr-5 label-style">Tracking#</Label>
																			</div>
																			<div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3">
																				<Label for="exampleEmail"
																					className="label-description">{trim(p.trackingNum, 13)}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style">Amount</Label>
																			</div>
																			<div className="col-6">
																				<span
																					class="badge badge-pill badge-primary font-weight-light px-2">{trim(p.amount, 13)}</span>
																			</div>
																		</div>
																	</div>
                                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style">Payment Method</Label>
																			</div>
																			<div className="col-6">
																				<span
																					class="badge badge-pill badge-primary font-weight-light px-2">{p.payment_type}</span>
																			</div>
																		</div>
																	</div>
																</div>

															</CardBody>
														</Card>

													</div>

												)
											})}
										</>}
								</div>
							</TabPane>
                            <TabPane tabId="5">
                            <div className="row">
									{this.state.loading === true ?
										<div className="d-flex justify-content-center">
											< ClipLoader
												size={15}
												color={"#123abc"}
												loading={true}
											/>
										</div>
										:
										<>
											{completed.map(p => {
												return (
													<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 pb-4">
														<Card className="shadow border-0">
															<CardBody>
																<div className="row">
																	<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style"
																					style={{ whiteSpace: "nowrap" }}>AD Code </Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{trim(p.partner_aDCode, 13)}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style"
																					style={{ whiteSpace: "nowrap" }}>First Name</Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{p.partner_firstName}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																		<div className="row">
																			<div className="col-3">
																				<Label for="exampleEmail " className="pr-5 label-style">Pickup Address</Label>
																			</div>
																			<div className="col-9">
																				<Label for="exampleEmail"
																					className="label-description">{p.pickupAddress}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																		<div className="row">
																			<div className="col-3">
																				<Label for="exampleEmail " className="pr-5 label-style">Delivery Address</Label>
																			</div>
																			<div className="col-9">
																				<Label for="exampleEmail"
																					className="label-description">{p.deliveryAdd}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12">
																		<div className="row">
																			<div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3">
																				<Label for="exampleEmail " className="pr-5 label-style">Tracking#</Label>
																			</div>
																			<div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3">
																				<Label for="exampleEmail"
																					className="label-description">{trim(p.trackingNum, 13)}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style">Amount</Label>
																			</div>
																			<div className="col-6">
																				<span
																					class="badge badge-pill badge-primary font-weight-light px-2">{trim(p.amount, 13)}</span>
																			</div>
																		</div>
																	</div>
                                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style">Payment Method</Label>
																			</div>
																			<div className="col-6">
																				<span
																					class="badge badge-pill badge-primary font-weight-light px-2">{p.payment_type}</span>
																			</div>
																		</div>
																	</div>
																</div>

															</CardBody>
														</Card>

													</div>

												)
											})}
										</>}
								</div>
							</TabPane>
						</TabContent>
					</CardBody>
				</Card>
			</div>
		)
	}
}
