import 'react-tagsinput/react-tagsinput.css'
import React, { Component, Fragment } from 'react'
import { CardHeader, CardBody, FormGroup, Label, Input, Card, FormFeedback, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ClipLoader } from "react-spinners";
import Select from 'react-select';
import { cityList } from './IATAcities';
import { seaPort } from './SeaPortCodes'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { saveflightDetails, checkScreenAccess, requestAccess, getCountryData, validateManifestNums } from '../../api/Form';
import { toast } from 'react-toastify';
import Modals from './Modal';
import moment from 'moment';
import { callActivityApi } from '../../utils/saveUserActivity';
import { phoneNoVerifyCheck } from '../../utils/utils';

const Schema = Yup.object().shape({
  flightNumber: Yup.string()
    .when('modeOfTransport', {
      is: (value) => value === 'Air' || value === 'Sea',
      then: Yup.string().required('Number is required'),
      otherwise: Yup.string(),
    }),
  airline: Yup.string()
    .required('Field is required'),
  origin: Yup.string()
    .required('Origin is required'),
  destination: Yup.string()
    .required('Destination is required'),
  modeOfTransport: Yup.string()
    .required('Mode of Transport is required'),
  from: Yup.string()
    .required('From country is required'),
  to: Yup.string()
    .required('To country is required'),
  eta: Yup.string()
    .required('ETA is required'),
  etd: Yup.string()
    .required('ETD is required'),
  airwayBill: Yup.string()
    .min(8, 'Master bill number must be exactly 8 digits')
    .max(20, 'Master bill number must be exactly 20 digits')
    .required('Master bill number is required'),
});

const countryList = [
  { value: 'Afghanistan', label: 'Afghanistan' },
  { value: 'Aland Islands', label: 'Aland Islands' },
  { value: 'Albania', label: 'Albania' },
  { value: 'Algeria', label: 'Algeria' },
  { value: 'American Samoa', label: 'American Samoa' },
  { value: 'AndorrA', label: 'AndorrA' },
  { value: 'Angola', label: 'Angola' },
  { value: 'Anguilla', label: 'Anguilla' },
  { value: 'Antarctica', label: 'Antarctica' },
  { value: 'Antigua and Barbuda', label: 'Antigua and Barbuda' },
  { value: 'Argentina', label: 'Argentina' },
  { value: 'Armenia', label: 'Armenia' },
  { value: 'Aruba', label: 'Aruba' },
  { value: 'Australia', label: 'Australia' },
  { value: 'Austria', label: 'Australia' },
  { value: 'Azerbaijan', label: 'Azerbaijan' },
  { value: 'Bahamas', label: 'Bahamas' },
  { value: 'Bahrain', label: 'Bahrain' },
  { value: 'Bangladesh', label: 'Bangladesh' },
  { value: 'Barbados', label: 'Barbados' },
  { value: 'Belarus', label: 'Belarus' },
  { value: 'Belgium', label: 'Belgium' },
  { value: 'Belize', label: 'Belize' },
  { value: 'Benin', label: 'Benin' },
  { value: 'Bermuda', label: 'Bermuda' },
  { value: 'Bhutan', label: 'Bhutan' },
  { value: 'Bolivia', label: 'Bolivia' },
  { value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
  { value: 'Botswana', label: 'Botswana' },
  { value: 'Bouvet Island', label: 'Bouvet Island' },
  { value: 'Brazil', label: 'Brazil' },
  { value: 'British Indian Ocean Territory', label: 'British Indian Ocean Territory' },
  { value: 'Brunei Darussalam', label: 'Brunei Darussalam' },
  { value: 'Bulgaria', label: 'Bulgaria' },
  { value: 'Burkina Faso', label: 'Burkina Faso' },
  { value: 'Burundi', label: 'Burundi' },
  { value: 'Cambodia', label: 'Cambodia' },
  { value: 'Cameroon', label: 'Cameroon' },
  { value: 'Canada', label: 'Canada' },
  { value: 'Cape Verde', label: 'Cape Verde' },
  { value: 'Cayman Islands', label: 'Cayman Islands' },
  { value: 'Central African Republic', label: 'Central African Republic' },
  { value: 'Chad', label: 'Chad' },
  { value: 'Chile', label: 'Chile' },
  { value: 'China', label: 'China' },
  { value: 'Christmas Island', label: 'Christmas Island' },
  { value: 'Cocos (Keeling) Islands', label: 'Cocos (Keeling) Islands' },
  { value: 'Colombia', label: 'Colombia' },
  { value: 'Comoros', label: 'Comoros' },
  { value: 'Congo', label: 'Congo' },
  { value: 'Congo, The Democratic Republic of the', label: 'Congo, The Democratic Republic of the' },
  { value: 'Cook Islands', label: 'Cook Islands' },
  { value: 'Costa Rica', label: 'Costa Rica' },
  { value: "Cote DIvoire", label: "Cote D'Ivoire" },
  { value: 'Croatia', label: 'Croatia' },
  { value: 'Cuba', label: 'Cuba' },
  { value: 'Cyprus', label: 'Cyprus' },
  { value: 'Czech Republic', label: 'Czech Republic' },
  { value: 'Denmark', label: 'Denmark' },
  { value: 'Djibouti', label: 'Djibouti' },
  { value: 'Dominica', label: 'Dominica' },
  { value: 'Dominican Republic', label: 'Dominican Republic' },
  { value: 'Ecuador', label: 'Ecuador' },
  { value: 'Egypt', label: 'Egypt' },
  { value: 'El Salvador', label: 'El Salvador' },
  { value: 'Equatorial Guinea', label: 'Equatorial Guinea' },
  { value: 'Eritrea', label: 'Eritrea' },
  { value: 'Estonia', label: 'Estonia' },
  { value: 'Ethiopia', label: 'Ethiopia' },
  { value: 'Falkland Islands (Malvinas)', label: 'Falkland Islands (Malvinas)' },
  { value: 'Faroe Islands', label: 'Faroe Islands' },
  { value: 'Fiji', label: 'Fiji' },
  { value: 'Finland', label: 'Finland' },
  { value: 'France', label: 'France' },
  { value: 'French Guiana', label: 'French Guiana' },
  { value: 'French Polynesia', label: 'French Polynesia' },
  { value: 'French Southern Territories', label: 'French Southern Territories' },
  { value: 'Gabon', label: 'Gabon' },
  { value: 'Gambia', label: 'Gambia' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Germany', label: 'Germany' },
  { value: 'Ghana', label: 'Ghana' },
  { value: 'Gibraltar', label: 'Gibraltar' },
  { value: 'Greece', label: 'Greece' },
  { value: 'Greenland', label: 'Greenland' },
  { value: 'Grenada', label: 'Grenada' },
  { value: 'Guadeloupe', label: 'Guadeloupe' },
  { value: 'Guam', label: 'Guam' },
  { value: 'Guatemala', label: 'Guatemala' },
  { value: 'Guernsey', label: 'Guernsey' },
  { value: 'Guinea', label: 'Guinea' },
  { value: 'Guinea-Bissau', label: 'Guinea-Bissau' },
  { value: 'Guyana', label: 'Guyana' },
  { value: 'Haiti', label: 'Haiti' },
  { value: 'Heard Island and Mcdonald Islands', label: 'Heard Island and Mcdonald Islands' },
  { value: 'Holy See (Vatican City State)', label: 'Holy See (Vatican City State)' },
  { value: 'Honduras', label: 'Honduras' },
  { value: 'Hong Kong', label: 'Hong Kong' },
  { value: 'Hungary', label: 'Hungary' },
  { value: 'Iceland', label: 'Iceland' },
  { value: 'India', label: 'India' },
  { value: 'Indonesia', label: 'Indonesia' },
  { value: 'Iran, Islamic Republic Of', label: 'Iran, Islamic Republic Of' },
  { value: 'Iraq', label: 'Iraq' },
  { value: 'Ireland', label: 'Ireland' },
  { value: 'Isle of Man', label: 'Isle of Man' },
  { value: 'Israel', label: 'Israel' },
  { value: 'Italy', label: 'Italy' },
  { value: 'Jamaica', label: 'Jamaica' },
  { value: 'Japan', label: 'Japan' },
  { value: 'Jersey', label: 'Jersey' },
  { value: 'Jordan', label: 'Jordan' },
  { value: 'Kazakhstan', label: 'Kazakhstan' },
  { value: 'Kenya', label: 'Kenya' },
  { value: 'Kiribati', label: 'Kiribati' },
  { value: "Korea, Democratic PeopleS Republic of", label: "Korea, Democratic People'S Republic of" },
  { value: 'Korea, Republic of', label: 'Korea, Republic of' },
  { value: 'Kuwait', label: 'Kuwait' },
  { value: 'Kyrgyzstan', label: 'Kyrgyzstan' },
  { value: "Lao PeopleS Democratic Republic", label: "Lao People'S Democratic Republic" },
  { value: 'Latvia', label: 'Latvia' },
  { value: 'Lebanon', label: 'Lebanon' },
  { value: 'Lesotho', label: 'Lesotho' },
  { value: 'Liberia', label: 'Liberia' },
  { value: 'Libyan Arab Jamahiriya', label: 'Libyan Arab Jamahiriya' },
  { value: 'Liechtenstein', label: 'Liechtenstein' },
  { value: 'Lithuania', label: 'Lithuania' },
  { value: 'Luxembourg', label: 'Luxembourg' },
  { value: 'Macao', label: 'Macao' },
  { value: 'Macedonia, The Former Yugoslav Republic of', label: 'Macedonia, The Former Yugoslav Republic of' },
  { value: 'Madagascar', label: 'Madagascar' },
  { value: 'Malawi', label: 'Malawi' },
  { value: 'Malaysia', label: 'Malaysia' },
  { value: 'Maldives', label: 'Maldives' },
  { value: 'Mali', label: 'Mali' },
  { value: 'Malta', label: 'Malta' },
  { value: 'Marshall Islands', label: 'Marshall Islands' },
  { value: 'Martinique', label: 'Martinique' },
  { value: 'Mauritania', label: 'Mauritania' },
  { value: 'Mauritius', label: 'Mauritius' },
  { value: 'Mayotte', label: 'Mayotte' },
  { value: 'Mexico', label: 'Mexico' },
  { value: 'Micronesia, Federated States of', label: 'Micronesia, Federated States of' },
  { value: 'Moldova, Republic of', label: 'Moldova, Republic of' },
  { value: 'Monaco', label: 'Monaco' },
  { value: 'Mongolia', label: 'Mongolia' },
  { value: 'Montserrat', label: 'Montserrat' },
  { value: 'Morocco', label: 'Morocco' },
  { value: 'Mozambique', label: 'Mozambique' },
  { value: 'Myanmar', label: 'Myanmar' },
  { value: 'Namibia', label: 'Namibia' },
  { value: 'Nauru', label: 'Nauru' },
  { value: 'Nepal', label: 'Nepal' },
  { value: 'Netherlands', label: 'Netherlands' },
  { value: 'Netherlands Antilles', label: 'Netherlands Antilles' },
  { value: 'New Caledonia', label: 'New Caledonia' },
  { value: 'New Zealand', label: 'New Zealand' },
  { value: 'Nicaragua', label: 'Nicaragua' },
  { value: 'Niger', label: 'Niger' },
  { value: 'Nigeria', label: 'Nigeria' },
  { value: 'Niue', label: 'Niue' },
  { value: 'Norfolk Island', label: 'Norfolk Island' },
  { value: 'Northern Mariana Islands', label: 'Northern Mariana Islands' },
  { value: 'Norway', label: 'Norway' },
  { value: 'Oman', label: 'Oman' },
  { value: 'Pakistan', label: 'Pakistan' },
  { value: 'Palau', label: 'Palau' },
  { value: 'Palestinian Territory, Occupied', label: 'Palestinian Territory, Occupied' },
  { value: 'Panama', label: 'Panama' },
  { value: 'Papua New Guinea', label: 'Papua New Guinea' },
  { value: 'Paraguay', label: 'Paraguay' },
  { value: 'Peru', label: 'Peru' },
  { value: 'Philippines', label: 'Philippines' },
  { value: 'Pitcairn', label: 'Pitcairn' },
  { value: 'Poland', label: 'Poland' },
  { value: 'Portugal', label: 'Portugal' },
  { value: 'Puerto Rico', label: 'Puerto Rico' },
  { value: 'Qatar', label: 'Qatar' },
  { value: 'Reunion', label: 'Reunion' },
  { value: 'Romania', label: 'Romania' },
  { value: 'Russian Federation', label: 'Russian Federation' },
  { value: 'RWANDA', label: 'RWANDA' },
  { value: 'Saint Helena', label: 'Saint Helena' },
  { value: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis' },
  { value: 'Saint Lucia', label: 'Saint Lucia' },
  { value: 'Saint Pierre and Miquelon', label: 'Saint Pierre and Miquelon' },
  { value: 'Saint Vincent and the Grenadines', label: 'Saint Vincent and the Grenadines' },
  { value: 'Samoa', label: 'Samoa' },
  { value: 'San Marino', label: 'San Marino' },
  { value: 'Sao Tome and Principe', label: 'Sao Tome and Principe' },
  { value: 'Saudi Arabia', label: 'Saudi Arabia' },
  { value: 'Senegal', label: 'Senegal' },
  { value: 'Serbia and Montenegro', label: 'Serbia and Montenegro' },
  { value: 'Seychelles', label: 'Seychelles' },
  { value: 'Sierra Leone', label: 'Sierra Leone' },
  { value: 'Singapore', label: 'Singapore' },
  { value: 'Slovakia', label: 'Slovakia' },
  { value: 'Slovenia', label: 'Slovenia' },
  { value: 'Solomon Islands', label: 'Solomon Islands' },
  { value: 'Somalia', label: 'Somalia' },
  { value: 'South Africa', label: 'South Africa' },
  { value: 'South Georgia and the South Sandwich Islands', label: 'South Georgia and the South Sandwich Islands' },
  { value: 'Spain', label: 'Spain' },
  { value: 'Sri Lanka', label: 'Sri Lanka' },
  { value: 'Sudan', label: 'Sudan' },
  { value: 'Suriname', label: 'Suriname' },
  { value: 'Svalbard and Jan Mayen', label: 'Svalbard and Jan Mayen' },
  { value: 'Swaziland', label: 'Swaziland' },
  { value: 'Sweden', label: 'Sweden' },
  { value: 'Switzerland', label: 'Switzerland' },
  { value: 'Syrian Arab Republic', label: 'Syrian Arab Republic' },
  { value: 'Taiwan, Province of China', label: 'Taiwan, Province of China' },
  { value: 'Tajikistan', label: 'Tajikistan' },
  { value: 'Tanzania, United Republic of', label: 'Tanzania' },
  { value: 'Thailand', label: 'Thailand' },
  { value: 'Timor-Leste', label: 'Timor-Leste' },
  { value: 'Togo', label: 'Togo' },
  { value: 'Tokelau', label: 'Tokelau' },
  { value: 'Tonga', label: 'Tonga' },
  { value: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
  { value: 'Tunisia', label: 'Tunisia' },
  { value: 'Turkey', label: 'Turkey' },
  { value: 'Turkmenistan', label: 'Turkmenistan' },
  { value: 'Turks and Caicos Islands', label: 'Turks and Caicos Islands' },
  { value: 'Tuvalu', label: 'Tuvalu' },
  { value: 'Uganda', label: 'Uganda' },
  { value: 'Ukraine', label: 'Ukraine' },
  { value: 'United Arab Emirates', label: 'United Arab Emirates' },
  { value: 'United Kingdom', label: 'United Kingdom' },
  { value: 'United States', label: 'United States' },
  { value: 'United States Minor Outlying Islands', label: 'United States Minor Outlying Islands' },
  { value: 'Uruguay', label: 'Uruguay' },
  { value: 'Uzbekistan', label: 'Uzbekistan' },
  { value: 'Vanuatu', label: 'Vanuatu' },
  { value: 'Venezuela', label: 'Venezuela' },
  { value: 'Viet Nam', label: 'Viet Nam' },
  { value: 'Virgin Islands, British', label: 'Virgin Islands, British' },
  { value: 'Virgin Islands, U.S.', label: 'Virgin Islands, U.S.' },
  { value: 'Wallis and Futuna', label: 'Wallis and Futuna' },
  { value: 'Western Sahara', label: 'Western Sahara' },
  { value: 'Yemen', label: 'Yemen' },
  { value: 'Zambia', label: 'Zambia' },
  { value: 'Zimbabwe', label: 'Zimbabwe' }
];

const modeOfTransportOption = [{ value: 'Air', label: 'Air' },
{ value: 'Sea', label: 'Sea' },
{ value: 'Road', label: 'Road' },
{ value: 'Rail', label: 'Rail' }]

export default class AssignFlightDetails extends Component {
  input = React.createRef();
  state = {
    deviceADcode: '',
    airwayBill: '',
    airline: '',
    flightNumber: '',
    origin: '',
    destination: '',
    modeOfTransport: '',
    confirmOriginCountry: '',
    confirmDestinationCountry: '',
    from: '',
    to: '',
    eta: '',
    etd: '',
    userId: '',
    nfcADcode: '',
    loading: true,
    isConfirm: false,
    overrideInfo: {},
    overrideMessage: '',
  }

  addClick = (array) => {
    array.insert(1, {
      nfcADcode: ''
    })
  }

  async componentDidMount() {
    callActivityApi({ 'pageName': 'Assign Master Flight Details', 'description': 'User has visited Flight Details Screen' });
    const historyPush = this.props.history.push
    phoneNoVerifyCheck(historyPush)
    const deviceADcode = await localStorage.getItem('adCodes');
    this.setState({ deviceADcode })
    const userId = await localStorage.getItem('userId');
    this.setState({ userId })
    var userType = await localStorage.getItem('accounttypeUsers');
    if (userType !== 'Admin') {
      checkScreenAccess(
        {
          'userId': userId,
          'screenType': 'Assign Flight Detail'
        }
      ).then((response) => {
        this.setState({ loading: false, requestAccess: true });
      }).catch(err => {
        this.setState({ loading: false, requestAccess: false });
      });
    } else {
      this.setState({ requestAccess: true, loading: false })
    }
  }

  submitHandler = (values) => {
    if (values.from !== values.confirmOriginCountry &&
      (values?.modeOfTransport === "Air" || values?.modeOfTransport === "Sea")) {
      toast.error("origin and origin country not matched, try again!");
      return
    }

    if (values.to !== values.confirmDestinationCountry &&
      (values?.modeOfTransport === "Air" || values?.modeOfTransport === "Sea")) {
      toast.error("Destination and destination country not matched, try again!");
      return
    }


    if (new Date(values.eta) >= new Date(values.etd)) {
      this.setState({ loading: true });
      const data = {
        airwayBill: values.airwayBill,
        airline: values.airline,
        flightNumber: values.flightNumber,
        userId: this.state.userId,
        origin: values.origin,
        destination: values.destination,
        modeOfTransport: values.modeOfTransport,
        from: values.from,
        eta: moment(values.eta).format(moment.HTML5_FMT.DATE),
        etd: moment(values.etd).format(moment.HTML5_FMT.DATE),
        to: values.to,
        override: 0,
      };
      saveflightDetails(data)
        .then(Response => {
          this.setState({ loading: false });
          const overrideInfo = Response.data.data;
          if (overrideInfo) {
            this.setState({
              isConfirm: true,
              overrideInfo,
              overrideMessage: 'Flight details for this master airwaybill already assigned, do you want to override? '
            });
          } else {
            toast.success(Response.data.message, {
              position: toast.POSITION.TOP_RIGHT
            });
            this.props.history.push('/admin/index');
          }
        }).catch(err => {
          this.setState({ loading: false });
          let message = err.message;
          if (err.response && err.response.data.message) {
            message = err.response.data.message;
          }
          toast.error(message, {
            position: toast.POSITION.TOP_RIGHT
          });
        })
    } else {
      toast.error('ETA must be greater than ETD', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  overrideHandler = () => {
    this.setState({ loading: true, isConfirm: false });
    saveflightDetails({
      airwayBill: this.state.overrideInfo.airwayBill,
      airline: this.state.overrideInfo.airline,
      flightNumber: this.state.overrideInfo.flightNumber,
      userId: this.state.overrideInfo.userId,
      origin: this.state.overrideInfo.origin,
      destination: this.state.overrideInfo.destination,
      modeOfTransport: this.state.overrideInfo.modeOfTransport,
      from: this.state.overrideInfo.from,
      to: this.state.overrideInfo.to,
      eta: moment(this.state.overrideInfo.eta).format(moment.HTML5_FMT.DATE),
      etd: moment(this.state.overrideInfo.etd).format(moment.HTML5_FMT.DATE),
      override: 1,
    }).then(Response => {
      this.setState({ loading: false });
      toast.success(Response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      this.props.history.push('/admin/index');
    }).catch(err => {
      this.setState({ loading: false });
      let message = err.message;
      if (err.response && err.response.data.message) {
        message = err.response.data.message;
      }
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })
  }

  sendRequestAccess = async () => {
    this.setState({ loading: true });
    const info = await localStorage.getItem('auth');
    const sendInfo = JSON.parse(info);
    requestAccess({
      'username': sendInfo.username,
      'firstName': sendInfo.firstName,
      'lastName': sendInfo.lastName,
      'accounttypeUsers': sendInfo.accounttypeUsers,
      'businessname': sendInfo.businessname,
      'ADCode': sendInfo.ADCode,
      'address': sendInfo.address,
      'email': sendInfo.email,
      'phoneNumber': sendInfo.phonenumber,
      'userId': sendInfo.id,
      'screenType': 'Assign Flight Detail',
    }).then(Response => {
      this.setState({ loading: false });
      toast.success(Response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }).catch(err => {
      this.setState({ loading: false });
      let message = err.message;
      if (err.response && err.response.data.message) {
        message = err.response.data.message;
      }
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })

  };

  fetchOriginCountry = async (option, name, values, setFieldValue) => {

    setFieldValue(name, option.value)
    try {
      if (values.modeOfTransport === 'Air') {
        const response = await getCountryData(option.iso)
        if (response.data[0]?.name?.common) {

          setFieldValue(name === 'origin' ? 'from' : 'to', response.data[0]?.name?.common);
          setFieldValue(name === 'origin' ? 'confirmOriginCountry' : 'confirmDestinationCountry', response.data[0]?.name?.common);
        }
      } else if (values.modeOfTransport === 'Sea') {
        setFieldValue(name === 'origin' ? 'from' : 'to', option.country);
        setFieldValue(name === 'origin' ? 'confirmOriginCountry' : 'confirmDestinationCountry', option.country);
      }
    } catch (error) {
      // console.log(error)
    }
  }

  handleTags = (airwayBill, setFieldValue) => {



    setFieldValue('airwayBill', airwayBill);

    const airwayBillMinLength = 10
    if (airwayBill.length >= airwayBillMinLength) {
      validateManifestNums({
        masterNumber: airwayBill
      })
        .then(Response => {
          if (Response?.status === 200) {
            const mot = Response?.data?.data?.modeOfTransport
            this.setState({ loading: false, customErr: '' });
            setFieldValue('modeOfTransport', mot);
          } else {
            this.setState({ loading: false, customErr: '' });
          }
        }).catch(err => {
          this.setState({ loading: false });
          let message = err.message;
          if (err.response && err.response.data.message) {
            message = err.response.data.message;
            // setFieldValue('refno', tags.toString());
          }
          toast.error(message, {
            position: toast.POSITION.TOP_RIGHT
          });
        });
    }

  };


  render() {
    const { scan, requestAccess } = this.state;
    if (this.state.isConfirm) {
      return (
        <Modals
          overrideInfo={this.state.overrideInfo}
          overrideMessage={this.state.overrideMessage}
          overrideHandler={this.overrideHandler}
          close={this.props.history} />
      )
    }
    return (
      <div>
        {!requestAccess ?
          <div className="justify-content-center pt-2 main-contain-title">
            <>
              <p>This feature is restricted.</p>
              <Button onClick={() => this.sendRequestAccess()}
                disabled={this.state.loading}>Request Access for free</Button>
            </>
          </div>
          :
          <>
            <div className="d-flex justify-content-center scan-parcel-loader">
              <ClipLoader
                size={50}
                color={"#123abc"}
                loading={this.state.loading}
              />
            </div>
            <Formik
              // enableReinitialize
              initialValues={{
                airwayBill: this.state.airwayBill,
                airline: this.state.airline,
                flightNumber: this.state.flightNumber,
                origin: this.state.origin,
                destination: this.state.destination,
                modeOfTransport: this.state.modeOfTransport,
                from: this.state.from,
                to: this.state.to,
                eta: this.state.eta,
                etd: this.state.etd,
              }}
              validationSchema={Schema}
              onSubmit={(values, { setSubmitting, setErrors }) => {
                this.setErrors = setErrors;
                this.submitHandler(values);
              }}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                handleBlur,
                setFieldValue,
                status,
                isSubmitting, }) => (
                <Fragment>
                  <div className={this.state.loading ? "mt-2 style loadingDiv" : "mt-2 style"}>
                    <Card>
                      <CardHeader className="c-header">Assign MOT Details</CardHeader>
                      <CardBody>

                        <div className="col-12">
                          <FormGroup>
                            <Label>{values.modeOfTransport === "Air" ? "Master Airwaybill" :
                              values.modeOfTransport === "Sea" ? "Master Bill of Lading/ Master seaway bill" :
                                values.modeOfTransport === "Rail" || values.modeOfTransport === "Road" ? "Courier Manifest No" : "Manifest No"
                            }</Label>
                            <Input
                              type="text"
                              invalid={errors.airwayBill && touched.airwayBill}
                              name="airwayBill"
                              // onChange={handleChange}
                              onChange={(e) => this.handleTags(e.target.value, setFieldValue)}
                              onBlur={handleBlur}
                              value={values.airwayBill}
                              placeholder={
                                values.modeOfTransport === "Air"
                                  ? "Enter Master Airwaybill"
                                  : values.modeOfTransport === "Sea"
                                    ? "Enter Master Bill of Lading/ Master seaway bill"
                                    : "Enter Manifest No"
                              }
                            />
                            <FormFeedback>
                              {errors.airwayBill && touched.airwayBill && errors.airwayBill}
                            </FormFeedback>
                          </FormGroup>
                        </div>

                        <div className="col-12">
                          <FormGroup>
                            <Label>Mode of Transport (MOT)</Label>
                            <Select
                              name="modeOfTransport"
                              onChange={(option) => setFieldValue('modeOfTransport', option.value)}
                              onBlur={handleBlur}
                              className="is-invalid"
                              isDisabled={true}
                              value={modeOfTransportOption.filter(option => values.modeOfTransport === option.value)}
                              options={modeOfTransportOption}
                            />
                            <FormFeedback>
                              {errors.modeOfTransport && touched.modeOfTransport && errors.modeOfTransport}
                            </FormFeedback>
                          </FormGroup>
                        </div>

                        <div className="col-12">
                          <FormGroup>
                            <Label>{values.modeOfTransport === "Air" ? "Airline" :
                              values.modeOfTransport === "Sea" ? "Sealine" : "Courier name"
                            }</Label>
                            <Input
                              type="text"
                              invalid={errors.airline && touched.airline}
                              name="airline"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.airline}
                              placeholder={values.modeOfTransport === "Air" ? "Enter Airline" :
                                values.modeOfTransport === "Sea" ? "Enter Sealine" : "Enter Courier name"} />
                            <FormFeedback>
                              {errors.airline && touched.airline && errors.airline}
                            </FormFeedback>
                          </FormGroup>
                        </div>

                        {(values.modeOfTransport === "Sea" || values.modeOfTransport === "Air") &&
                          <div className="col-12">
                            <FormGroup>
                              <Label> {values.modeOfTransport === "Air" ? "Flight Number" :
                                "Voyage Number"
                              }</Label>
                              <Input
                                type="text"
                                invalid={errors.flightNumber && touched.flightNumber}
                                name="flightNumber"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.flightNumber}
                                placeholder={values.modeOfTransport === "Air" ? "Enter Flight Number" :
                                  "Enter Voyage Number"
                                } />
                              <FormFeedback>
                                {errors.flightNumber && touched.flightNumber && errors.flightNumber}
                              </FormFeedback>
                            </FormGroup>
                          </div>}

                        {(values.modeOfTransport === "Road" || values.modeOfTransport === "Rail") && <div className="col-12">
                          <FormGroup>
                            <Label>Origin</Label>
                            <Input type="text"
                              invalid={errors.origin && touched.origin}
                              name="origin" onChange={(e) => {
                                handleChange(e)
                              }} onBlur={handleBlur} value={values.origin}
                              placeholder="Enter origin" />
                            <FormFeedback>
                              {errors.origin && touched.origin && errors.origin}
                            </FormFeedback>
                          </FormGroup>
                        </div>}

                        {(values.modeOfTransport === "Road" || values.modeOfTransport === "Rail") && <div className="col-12">
                          <FormGroup>
                            <Label>Destination</Label>
                            <Input type="text"
                              invalid={errors.destination && touched.destination}
                              name="destination" onChange={(e) => {
                                handleChange(e)
                              }} onBlur={handleBlur} value={values.destination}
                              placeholder="Enter destination" />
                            <FormFeedback>
                              {errors.destination && touched.destination && errors.destination}
                            </FormFeedback>
                          </FormGroup>
                        </div>}

                        {(values.modeOfTransport === "Sea" || values.modeOfTransport === "Air") &&
                          <div className="col-12">
                            <FormGroup>
                              <Label> Origin</Label>
                              <Select
                                name="origin"
                                onChange={(option) => this.fetchOriginCountry(option, 'origin', values, setFieldValue)}
                                onBlur={handleBlur}
                                className="is-invalid"
                                value={values.modeOfTransport === "Air" ? cityList.filter(option => values.origin === option.value) :
                                  seaPort.filter(option => values.origin === option.value)}
                                options={values.modeOfTransport === "Air" ? cityList : seaPort}
                              />
                              <FormFeedback>
                                {errors.origin && touched.origin && errors.origin}
                              </FormFeedback>
                            </FormGroup>
                          </div>}

                        {(values.modeOfTransport === "Sea" || values.modeOfTransport === "Air") &&
                          <div className="col-12">
                            <FormGroup>
                              <Label> Destination</Label>
                              <Select
                                name="destination"
                                onChange={(option) => this.fetchOriginCountry(option, 'destination', values, setFieldValue)}
                                onBlur={handleBlur}
                                className="is-invalid"
                                value={values.modeOfTransport === "Air" ? cityList.filter(option => values.destination === option.value) :
                                  seaPort.filter(option => values.destination === option.value)
                                }
                                options={values.modeOfTransport === "Air" ? cityList : seaPort}
                              />
                              <FormFeedback>
                                {errors.destination && touched.destination && errors.destination}
                              </FormFeedback>
                            </FormGroup>
                          </div>}

                        <div className="col-12">
                          <FormGroup>
                            <Label> Country of Origin</Label>
                            <Select
                              name="from"
                              onChange={(option) => setFieldValue('from', option.value)}
                              onBlur={handleBlur}
                              className="is-invalid"
                              value={countryList.filter(option => values.from === option.value)}
                              options={countryList}
                            />
                            <FormFeedback>
                              {errors.from && touched.from && errors.from}
                            </FormFeedback>
                          </FormGroup>
                        </div>

                        <div className="col-12">
                          <FormGroup>
                            <Label> Country of Destination</Label>
                            <Select
                              name="to"
                              onChange={(option) => setFieldValue('to', option.value)}
                              onBlur={handleBlur}
                              className="is-invalid"
                              value={countryList.filter(option => values.to === option.value)}
                              options={countryList}
                            />
                            <FormFeedback>
                              {errors.to && touched.to && errors.to}
                            </FormFeedback>
                          </FormGroup>
                        </div>

                        <div className="col-12">
                          <FormGroup>
                            <Label >ETA</Label>
                            <DatePicker
                              name="eta"
                              placeholderText="Select ETA"
                              className="is-invalid"
                              // minDate={moment().toDate()}
                              selected={(values.eta && new Date(values.eta)) || null}
                              onChange={val => {
                                setFieldValue('eta', val);
                              }}
                            />
                            <Input type="hidden" name="eta" placeholder="Select ETA" value={values.eta} className="is-invalid" />
                            <FormFeedback>
                              {errors.eta && touched.eta && errors.eta}
                            </FormFeedback>
                          </FormGroup>
                        </div>

                        <div className="col-12">
                          <FormGroup>
                            <Label >ETD</Label>
                            <DatePicker
                              name="etd"
                              placeholderText="Select ETD"
                              className="is-invalid"
                              // minDate={moment().toDate()}
                              selected={(values.etd && new Date(values.etd)) || null}
                              onChange={val => {
                                setFieldValue('etd', val);
                              }}
                            />
                            <Input type="hidden" name="etd" placeholder="Select ETD" value={values.eta} className="is-invalid" />
                            <FormFeedback>
                              {errors.etd && touched.etd && errors.etd}
                            </FormFeedback>
                          </FormGroup>
                        </div>

                        <div className="d-flex justify-content-center">
                          <button className="btn btn-submit" onClick={handleSubmit} >Submit</button>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </Fragment>

              )}
            </Formik>
          </>
        }
      </div>
    )
  }
}
