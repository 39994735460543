import React, { Component } from 'react'
import './HomePage.css'
import { saveIpAddress } from "../api/Form";
import RegisterTrackSection from './components/RegisterTrackSection';
import ServicesSection from './components/ServicesSection'
import PartnersAndTestimonialSection from './components/PartnersAndTestimonialsSection'
import Footer from './components/Footer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Faq } from './components/Faq';
import AppSection from './components/AppSection';
import { Helmet } from 'react-helmet';
import organizationSchema from './schema/organizationSchema';
import webPageSchema from './schema/webPageSchema';
import breadcrumbSchema from './schema/breadcrumbSchema';
import serviceSchema from './schema/serviceSchema';
import faqSchema from './schema/faqSchema';

export default class HomePage extends Component {

  async componentDidMount() {

    saveIpAddress({
      page_name: "HomePage"
    })
      .then(res => {
        console.log(res.data.message);
      })
      .catch(err => {
        console.log(err)
      })
  }

  signupHandler = () => {
    this.props.history.push('./verifyEmail')
  }

  lostParcelHandler = () => {
    this.props.history.push('./missingparcel')
  }
  wrongParcelHandler = () => {
    this.props.history.push('./receivedwrongmail')
  }
  adCodeHandler = () => {
    this.props.history.push('./ADIoTCodes')
  }

  registerHandler = () => {
    this.props.history.push('./verifyEmail')
  }


  render() {
    const token = localStorage.getItem('token')
    if (!token) {
      // return <Redirect from="/" to="/login" />
    }
    return (
      <>
        <Helmet>
          <title>Global Parcel Tracking - Shipment & Delivery | Postkodes</title>
          <meta name="description" content="Need cheap international shipping? Postkodes provides global parcel tracking, pickup services, and worldwide delivery solutions at great prices." />
          <script type="application/ld+json">
            {JSON.stringify(organizationSchema)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(webPageSchema)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(breadcrumbSchema)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(serviceSchema)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(faqSchema)}
          </script>
        </Helmet>

        <div className='home-page-container'>

          <RegisterTrackSection />

          <ServicesSection />

          <PartnersAndTestimonialSection />

          <Faq />

          <AppSection />


          {/* <div class="site-section bg-image overlay img" id="section-how-it-works" style={{ minHeight: 400 }} /> */}


        </div>
        <Footer />
      </>
    )
  }
}   
