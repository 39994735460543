import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import HomePage from './HomePage/HomePageNew';
import './App.css';
import ScrollToTop from "./scrollToTop";
import Login from './Login/Login';
import RequestErrand from './Login/RequestErrand';
import VerifyPhone from './SignUp/VerifyPhone';
import SignupForm from './SignUp/Form';
import MissingParcel from './Form/MissingParcel/MissingParcel';
import ReceivedWrongMail from './Form/ReceivedWrongMail/ReceivedWrongMail';
import DeliveryExceptionHub from './Form/DeliveryExceptionHub/DeliveryExceptionHub';
import OurParcelGuardService from './Form/OurParcelGuardService/OurParcelGuardService';
import ADIoTCodes from './Form/ADIoTCodes/ADIoTCodes';
import PrivacyPolicy from './Form/PrivacyPolicy/PrivacyPolicy';
import TermsConditions from './Form/Terms&Conditions/Terms&Conditions';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import AddressDynamicIoTCodes from './Form/AddressDynamicIoTCodes/AddressDynamicIoTCodes';
import AboutAddressDynamic from './Form/AboutAddressDynamic/AboutAddressDynamic';
import DeliveryExceptionPartners from './Form/DeliveryExceptionPartners/DeliveryExceptionPartners';
import TrackShipment from './Form/TrackShipment/TrackShipments';
import Dashboard from './Dashboard/HomePage/HomePage';
import Admin from './layout/Admin';
import NoPageFound from './NoPage/NoPageFound';
import createPassword from './ForgotPassword/createPassword';
import PaymentSuccess from './Form/MissingParcel/PaymentSuccess';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AirFreight from './HomePage/freights/AirFreight';
import SeaFreight from './HomePage/freights/SeaFreight';
import withCanonical from './utils/withCanonical';
import ShippingCalculator from './components/ShippingCalculator';
import RoadFreight from './HomePage/freights/RoadFreight';
import Errands from './HomePage/freights/Errands';
const { REACT_APP_UI_URL } = process.env;

export default class App extends Component {
	render() {
		if (process.env.NODE_ENV !== "development") {
			console.log = () => { };
			console.debug = () => { };
			console.info = () => { };
			console.warn = () => { };
		}

		return (
			<div className="bg-style">
				<ToastContainer />
				<BrowserRouter>
					<ScrollToTop />
					<Switch>
						<Route path="/" exact component={withCanonical(HomePage, `${REACT_APP_UI_URL}/`)} />
						<Route path="/air-freight-services" exact component={withCanonical(AirFreight, `${REACT_APP_UI_URL}/air-freight-services`)} />
						<Route path="/sea-freight-services" exact component={withCanonical(SeaFreight, `${REACT_APP_UI_URL}/sea-freight-services`)} />
						<Route path="/road-freight-services" exact component={withCanonical(RoadFreight, `${REACT_APP_UI_URL}/road-freight-services`)} />
						<Route path="/errands-services" exact component={withCanonical(Errands, `${REACT_APP_UI_URL}/errands-services`)} />
						<Route path="/shipping-calculator" exact component={withCanonical(ShippingCalculator, `${REACT_APP_UI_URL}/shipping-calculator`)} />
						<Route path="/login" component={Login} />
						<Route path="/requesterrand" component={withCanonical(RequestErrand, `${REACT_APP_UI_URL}/requesterrand`)} />
						<Route path="/verifyEmail" component={withCanonical(VerifyPhone, `${REACT_APP_UI_URL}/verifyEmail`)} />
						<Route path="/forgotpassword" component={withCanonical(ForgotPassword, `${REACT_APP_UI_URL}/forgotpassword`)} />
						<Route path="/createPassword" component={createPassword} />
						<Route path="/signupform" component={SignupForm} />
						<Route path="/missingparcel" component={MissingParcel} />
						<Route path="/paymentSuccess" component={PaymentSuccess} />
						<Route path="/receivedwrongmail" component={ReceivedWrongMail} />
						<Route path="/trackShipment" component={TrackShipment} />
						<Route path="/deliveryexceptionhub" component={DeliveryExceptionHub} />
						<Route path="/ourparcelguardservice" component={OurParcelGuardService} />
						<Route path="/ADIoTCodes" component={ADIoTCodes} />
						<Route path="/privacy-policy" component={withCanonical(PrivacyPolicy, `${REACT_APP_UI_URL}/privacy-policy`)} />
						<Redirect from="/privacypolicy" to="/privacy-policy" status={301} />
						<Route path="/terms-conditions" component={withCanonical(TermsConditions, `${REACT_APP_UI_URL}/terms-conditions`)} />
						<Redirect from="/termsconditions" to="/terms-conditions" status={301} />
						<Route path="/AddressDynamicIoTCodes" component={AddressDynamicIoTCodes} />
						<Route path="/about" component={withCanonical(AboutAddressDynamic, `${REACT_APP_UI_URL}/about`)} />
						<Redirect from="/aboutpostkodes" to="/about" status={301} />
						<Route path="/deliveryexceptionpartners" component={DeliveryExceptionPartners} />
						<Route path="/dashboard" component={Dashboard} />
						<Route path="/admin" component={Admin} />
						<Route path="*" component={NoPageFound} />
					</Switch>
				</BrowserRouter>
			</div>
		)
	}
}
