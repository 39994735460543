import 'react-tagsinput/react-tagsinput.css'
import React, { Component, Fragment } from 'react'
import { CardHeader, CardBody, FormGroup, Label, Input, Card, FormFeedback } from 'reactstrap';
import { ClipLoader } from "react-spinners";
import 'react-phone-number-input/style.css'
import { addCredits, searchUserByAdCode } from '../../api/Form';
import { toast } from 'react-toastify';




export default class AddCredits extends Component {
    input = React.createRef();
    state = {
        userAdCode: '',
        loading: false,
        credits: 0,
        userAdCodeError: '',
        creditsError: '',
        userDetails: {},
    }



    async componentDidMount() {

    }

    preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };

    submitHandler = () => {
        var showMsg = "Please confirm you are adding "+this.state.credits+" credit to "+this.state.userAdCode+" with "+this.state.userDetails.firstnameUsers+" "+this.state.userDetails.lastnameUsers+" "+this.state.userDetails.phoneUsers+" and "+this.state.userDetails.addressUsers+" ?";
        if (parseInt(this.state.credits) > 0){
            if (window.confirm(showMsg)) {
                this.setState({ loading: true });
                addCredits({
                    'credits': this.state.credits,
                    'uuid': this.state.userAdCode
                }).then((Response) => {
                    this.setState({ loading: false });
                    toast.success('Credits added successfully!!', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    this.props.history.push('/admin/index');
                }).catch((err) => {
                    this.setState({ loading: false });
                    toast.error(err.response.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
            }
        } else {
            toast.error('Please enter valid credits', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        
    }
    searchAnother = () => {
        this.setState({userDetails: {}, userAdCode: ''})
    }

    // search user ad code
    searchAdCode = () => {
        if (this.state.userAdCode) {
            this.setState({ loading: true });
            searchUserByAdCode({
                'userAdCode': this.state.userAdCode
            }).then((Response) => {
                this.setState({ loading: false });
                this.setState({ userDetails: Response.data.data, userAdCodeError: '' });
            }).catch((err) => {
                this.setState({ loading: false, userDetails: {}, userAdCodeError: 'User Ad Code is invalid' });
                console.log(err)
            })
        } else {
            this.setState({ userAdCodeError: 'User Ad Code is required' })
        }
    }
    // handleChange function
    handleChange = (name, value) => {
        this.setState({ [name]: value.target.value })
    };

    render() {
        return (
            <div>
                {this.state.loading ?
                    <div className="d-flex justify-content-center scan-parcel-loader">
                        <ClipLoader
                            size={50}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
                    :
                    <>
                        <Fragment>
                            <div className={this.state.loading ? "mt-2 style loadingDiv" : "mt-2 style"}>
                                <Card>
                                    <CardHeader className="c-header">Add Credits</CardHeader>
                                    <CardBody>

                                        <div className="row">

                                            <div className="col-lg-12 col-xs-12 col-sm-12">
                                                <div className="row">
                                                {Object.keys(this.state.userDetails).length == 0 && <div className="col-lg-12 col-xs-12 col-sm-12">
                                                        <FormGroup>
                                                            <Label>Enter User ADcode</Label>
                                                            <div className="input-group">
                                                                <Input
                                                                    type="text"
                                                                    name="userAdCode"
                                                                    invalid={this.state.userAdCodeError}
                                                                    onChange={(v) => this.handleChange('userAdCode', v)}
                                                                    value={this.state.userAdCode}
                                                                    placeholder="Enter User Adcode"
                                                                />
                                                                <button className="btn btn-submit mx-2" onClick={() => this.searchAdCode()} >Proceed</button>
                                                            </div>
                                                            <div className='text-danger'>{this.state.userAdCodeError}</div>
                                                        </FormGroup>
                                                    </div>}

                                                    {Object.keys(this.state.userDetails).length > 0 && <div className="col-lg-12 col-xs-12 col-sm-12">

                                                        <b>User AdCode</b> <p>{this.state.userDetails.uuid}</p>
                                                        <b>UserName</b> <p>{this.state.userDetails.uidUsers}</p>
                                                        <b>FirstName </b> <p>{this.state.userDetails.firstnameUsers}</p>
                                                        <b>LastName</b> <p>{this.state.userDetails.lastnameUsers}</p>
                                                        <b>Email</b> <p>{this.state.userDetails.emailUsers}</p>
                                                        <b>Phone</b> <p>{this.state.userDetails.phoneUsers}</p>
                                                        <b>Current Credits</b> <p>{this.state.userDetails.credit}</p>
                                                        <b>Address</b> <p>{this.state.userDetails.addressUsers}</p>
                                                        <b>Account Type</b> <p>{this.state.userDetails.accounttypeUsers}</p>

                                                    </div>}

                                                    {Object.keys(this.state.userDetails).length > 0 && <div className="col-lg-12 col-xs-12 col-sm-12">
                                                        <FormGroup>
                                                            <Label>Credits</Label>
                                                            <Input
                                                                type="number"
                                                                name="credits"
                                                                min="0"
                                                                onKeyPress={this.preventMinus}
                                                                onChange={(v) => this.handleChange('credits', v)}
                                                                value={this.state.credits}
                                                                placeholder="Add credits"
                                                            />
                                                            <FormFeedback>
                                                                {this.state.creditsError}
                                                            </FormFeedback>
                                                        </FormGroup>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>


                                        <div className="d-flex justify-content-center">
                                            {Object.keys(this.state.userDetails).length > 0 && <><button className="btn btn-submit mx-2" onClick={() => this.submitHandler()} >Submit</button><button className="btn btn-submit mx-2" onClick={() => this.searchAnother()} >Search Another</button></>}
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </Fragment>
                    </>}
            </div>);
    }


}

