import React, { Component } from 'react'
import { getShipment, checkScreenAccess, requestAccess, searchShipmentInvoice, updateShipmentInvoice, sendInvoice } from '../../api/Form';
import { image } from '../../api/URLS';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Formik } from 'formik';
import 'react-toastify/dist/ReactToastify.css';
import PrintInvoice from './PrintInvoice';
import { CardHeader, CardBody, FormGroup, Label, Input, Card, Button, FormFeedback, Toast } from 'reactstrap';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { callActivityApi } from '../../utils/saveUserActivity';
import { ClipLoader } from 'react-spinners';
import Select from 'react-select';
import { currencyList } from '../CreateShipment/CurrencyList';
import currenySymbol from "./currencies.json"
import { getLocation, phoneNoVerifyCheck } from '../../utils/utils';

const Schema = Yup.object().shape({
	refno: Yup.string()
		.required('Tracking Number is required'),
	amountCharged: Yup.number()
		.required('Amount Charged is required'),
	gst: Yup.number()
		.required('GST is required'),
	invoicedTo: Yup.string()
		.required('Invoiced To is required'),
	invoiceStatus: Yup.string()
		.required('Invoice Status is required'),
	// package_currency: Yup.string()
	// 	.required('Currency is required'),
	comment: Yup.string(),
	shipperName: Yup
		.string()
		.when("invoicedTo", {
			is: 'shipper',
			then: Yup.string().required("Shipper Name is required")
		}),
	shipperEmail: Yup
		.string()
		.when("invoicedTo", {
			is: 'shipper',
			then: Yup.string().required("Shipper Email is required")
		}),
	shipperAddress: Yup
		.string()
		.when("invoicedTo", {
			is: 'shipper',
			then: Yup.string().required("Shipper Address is required")
		}),
	shipperPhone: Yup
		.number()
		.when("invoicedTo", {
			is: 'shipper',
			then: Yup.number().required("Shipper Phone is required")
		})
});

export default class InvoiceByAmount extends Component {
	constructor() {
		super();
		this.handleInputChange = this.handleInputChange.bind(this);
	}
	state = {
		invoice: '',
		refno: '',
		amountCharged: '',
		gst: '',
		comment: '',
		shipperName: '',
		shipperPhone: '',
		shipperEmail: '',
		shipperAddress: '',
		loading: false,
		invoicedTo: '',
		print: false,
		requestAccess: false,
		invoiceStatus: '',
		showContent: false,
		receiverName: '',
		receiverPhone: '',
		receiverAddress: '',
		receiverEmail: '',
		invoiceData: {},
		showPopup: false,
		shipperMode: false,
		receiverMode: false,
		creatorMode: false,
		userInfo: {},
		additionalMode: false,
		additionalEmail: '',
		referanceNo: '',
		email: null,
		emailError: null,
		loader: false,
		currencySymbol: '',
		allDataOfShipper: {},
		package_currency: '',
		currencyCode: "",
		location: ""
	}

	handleInputChange(event) {
		this.setState({
			additionalEmail: event.target.value
		});
	}

	validate() {
		const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (!this.state.additionalEmail || reg.test(this.state.additionalEmail) === false) {
			this.setState({ emailError: "Email Field is Invalid" });
			return false;
		}
		return true;
	}

	async componentDidMount() {
		callActivityApi({ 'pageName': 'Invoice', 'description': 'User has visited Invoice Screen' });
		const historyPush = this.props.history.push
		phoneNoVerifyCheck(historyPush)
		const refNo = this.props.location.state;
		this.setState({ referanceNo: refNo })
		if (refNo) {
			this.searchAdCode()
		}
		getLocation().then((location) => this.setState({ location: location }));
		const userId = await localStorage.getItem('userId');
		this.setState({ userId })
		const data = await localStorage.getItem('auth');
		this.setState({ userInfo: JSON.parse(data) })
		var userType = await localStorage.getItem('accounttypeUsers');
		if (userType !== 'Admin') {
			checkScreenAccess(
				{
					'userId': userId,
					'screenType': 'Invoice'
				}
			).then((response) => {
				this.setState({ loading: false, requestAccess: true });
			}).catch(err => {
				this.setState({ loading: false, requestAccess: false });
			});
		} else {
			this.setState({ requestAccess: true, loading: false })
		}
	}

	sendRequestAccess = async () => {
		this.setState({ loading: true });
		const info = await localStorage.getItem('auth');
		const sendInfo = JSON.parse(info);
		// username, first name, last name, account type, business name, AD Code, address, email and phone number
		requestAccess({
			'username': sendInfo.username,
			'firstName': sendInfo.firstName,
			'lastName': sendInfo.lastName,
			'accounttypeUsers': sendInfo.accounttypeUsers,
			'businessname': sendInfo.businessname,
			'ADCode': sendInfo.ADCode,
			'address': sendInfo.address,
			'email': sendInfo.email,
			'phoneNumber': sendInfo.phonenumber,
			'userId': sendInfo.id,
			'screenType': 'Invoice',
		}).then(Response => {
			this.setState({ loading: false });
			toast.success(Response.data.message, {
				position: toast.POSITION.TOP_RIGHT
			});
		}).catch(err => {
			this.setState({ loading: false });
			let message = err.message;
			if (err.response && err.response.data.message) {
				message = err.response.data.message;
			}
			toast.error(message, {
				position: toast.POSITION.TOP_RIGHT
			});
		})
	};

	loadCss = () => {
		const stylePath = "/printInvoice.css";
		const head = document.head;
		if (this.state.link) {
			head.removeChild(this.state.link);
		}
		const link = document.createElement("link");
		link.type = "text/css";
		link.rel = "stylesheet";
		link.href = stylePath;
		head.appendChild(link);
		this.setState({ link, print: true })
	}

	submitHandler = async (values) => {
		if (values.package_currency) {
			this.setState({ loading: true });
			const info = await localStorage.getItem('auth');
			const userData = JSON.parse(info);
			this.setState({ currencyCode: values.package_currency })
			var sendInfo = {};
			if (values.invoicedTo == "receiver") {
				values.receiverName = this.state.receiverName;
				values.receiverAddress = this.state.receiverAddress;
				values.receiverPhone = this.state.receiverPhone;
				values.receiverEmail = this.state.receiverEmail;
			} else {
				values.receiverName = values.shipperName;
				values.receiverAddress = values.shipperAddress;
				values.receiverPhone = values.shipperPhone;
				values.receiverEmail = values.shipperEmail
			}
			if (Object.keys(this.state.invoiceData).length > 0) {
				sendInfo.id = this.state.invoiceData.id;
				sendInfo.ref_no = this.state.invoiceData.ref_no;
				sendInfo.amount_charged = values.amountCharged;
				sendInfo.vat_percentage = values.gst;
				sendInfo.invoice_status = values.invoiceStatus;
				sendInfo.invoice_to = values.invoicedTo;
				sendInfo.receiver_name = values.receiverName;
				sendInfo.receiver_address = values.receiverAddress;
				sendInfo.receiver_phone = values.receiverPhone;
				sendInfo.receiver_email = values.receiverEmail;
				sendInfo.created_by = this.state.invoiceData.created_by;
				sendInfo.business_name = userData.businessname;
				sendInfo.comment = values.comment;
				sendInfo.shipped_items = this.state.invoiceData.shipped_items;
				sendInfo.weight = this.state.invoiceData.weight;
				sendInfo.package_currency = values.package_currency;
				sendInfo.created_location = this.state.location;
			} else {
				sendInfo.id = '0';
				sendInfo.ref_no = values.refno;
				sendInfo.amount_charged = values.amountCharged;
				sendInfo.vat_percentage = values.gst;
				sendInfo.invoice_status = values.invoiceStatus;
				sendInfo.invoice_to = values.invoicedTo;
				sendInfo.receiver_name = values.receiverName;
				sendInfo.receiver_address = values.receiverAddress;
				sendInfo.receiver_phone = values.receiverPhone;
				sendInfo.receiver_email = values.receiverEmail;
				sendInfo.created_by = userData.id;
				sendInfo.business_name = userData.businessname;
				sendInfo.comment = values.comment;
				sendInfo.shipped_items = this.state.shipped_items;
				sendInfo.weight = this.state.weight;
				sendInfo.package_currency = values.package_currency;
				sendInfo.created_location = this.state.location;
			}

			updateShipmentInvoice(sendInfo).then(response => {
				this.searchAdCode(values.refno, true)
			}).catch(err => {
				toast.error(err.response.data.message, {
					position: toast.POSITION.TOP_RIGHT
				})
			})

			const sendVal = values;
			getShipment({ refno: values.refno })
				.then(response => {
					response.data.data.sendInfo = sendVal;
					this.setState({ invoice: response.data.data, print: true });
				})
				.catch(err => {
					this.setState({ printButton: false });
					toast.error('This reference number does not exist!', {
						position: toast.POSITION.TOP_RIGHT
					})
				})
		} else {
			toast.error('Please Select Currency first', {
				position: toast.POSITION.TOP_RIGHT
			})
		}

	}

	callApiData = () => {
		this.setState({ loader: true })
		sendInvoice({
			'invoiceData': this.state.invoice,
			'shipperMode': this.state.shipperMode,
			'creatorMode': this.state.creatorMode,
			'receiverMode': this.state.receiverMode,
			'userInfo': this.state.userInfo,
			'refno': this.state.refno,
			'additionalMode': this.state.additionalMode,
			'additionalEmail': this.state.additionalEmail,
			'package_currency': this.state.currencyCode
		}).then((response) => {
			toast.success('Invoice sent successfully!!', {
				position: toast.POSITION.TOP_RIGHT
			})
			this.setState({ showPopup: false, loader: false })
		}).catch((e) => {
			toast.error('Something went wrong', {
				position: toast.POSITION.TOP_RIGHT
			})
			this.setState({ showPopup: false, loader: false })
		})
	}

	// send email function
	scanHandler = () => {
		if (this.state.additionalMode) {
			if (this.validate()) {
				this.callApiData()
			} else {
				toast.error('Please enter a valid email!', {
					position: toast.POSITION.TOP_RIGHT
				})
			}
		}
		if (this.state.shipperMode || this.state.receiverMode || this.state.creatorMode) {
			if (!this.state.additionalMode) {
				this.callApiData()
			}
		}

	}



	searchAdCode = async (refno, status) => {
		const info = await localStorage.getItem('auth');
		const sendInfo = JSON.parse(info);

		// call search code api
		if (refno || this.state.referanceNo) {
			searchShipmentInvoice({
				'business_name': sendInfo.businessname,
				'ref_no': refno ? refno : this.state.referanceNo
			}).then(response => {
				this.setState({ allDataOfShipper: response.data.data })
				if (response.data.message == 'This is new shipment') {
					this.setState({
						weight: response.data.data.weight,
						shipped_items: response.data.data.quantity,
						receiverName: response.data.data.receiverName,
						receiverAddress: response.data.data.deliveryAddress,
						receiverPhone: response.data.data.receiverPhone,
						receiverEmail: response.data.data.receiverEmail,
						invoiceData: {},
						amountCharged: '',
						gst: '',
						invoicedTo: '',
						comment: '',
						invoiceStatus: '',
						showContent: true,
					})
				} else {
					this.setState({
						invoiceData: response.data.data,
						receiverEmail: response.data.data.user_receiver_email,
						refno: response.data.data.ref_no,
						amountCharged: response.data.data.amount_charged,
						gst: response.data.data.vat_percentage,
						invoicedTo: response.data.data.invoice_to,
						comment: response.data.data.comment,
						package_currency: response.data.data.package_currency,
						invoiceStatus: response.data.data.invoice_status,
						showContent: true,
					})
					if (this.state.invoicedTo == 'shipper') {
						this.setState({
							shipperName: response.data.data.receiver_name,
							shipperPhone: response.data.data.receiver_phone,
							shipperAddress: response.data.data.receiver_address,
							shipperEmail: response.data.data.receiver_email,
						})
					} else {
						this.setState({
							receiverName: response.data.data.user_receiver_name,
							receiverPhone: response.data.data.user_receiver_phone,
							receiverAddress: response.data.data.user_receiver_address,
							receiverEmail: response.data.data.user_receiver_email,
						})
					}
					if (status == true) {
						this.setState({ invoice: this.state.invoiceData, print: true, showContent: false });
					}
				}

			}).catch(err => {
				//	console.log(err)
				// toast.error(err.response.data.message, {
				// 	position: toast.POSITION.TOP_RIGHT
				// });
			})
		} else {
			toast.error('Tracking Number is required', {
				position: toast.POSITION.TOP_RIGHT
			});
		}
	}




	render() {
		const { print, requestAccess, showPopup, shipperMode, creatorMode, receiverMode, additionalMode, additionalEmail, currencySymbol, allDataOfShipper } = this.state;
		return (
			<div className="mt-2 style">
				{!requestAccess ?
					<div className="justify-content-center pt-2 main-contain-title">
						<>
							<p>This feature is restricted.</p>
							<Button onClick={() => this.sendRequestAccess()}
								disabled={this.state.loading}>Request Access for free</Button>
						</>
					</div>
					:
					<Card>
						<CardHeader className="c-header">Invoice</CardHeader>
						<CardBody>
							{showPopup && <Modal isOpen={showPopup} >
								<ModalBody>
									<>
										<div className="row pt-3 d-flex justify-content-center">
											<div className="col-12 py-2 d-flex justify-content-center">
												<h1 className="scan-title">Please choose</h1>
											</div>
											<div className="pb-3 row">

												{this.state.invoicedTo == 'shipper' && <FormGroup check className="pr-3">
													<Label check>
														<Input type="checkbox" checked={this.state.shipperMode} placeholder="" onChange={() => this.setState({ shipperMode: !this.state.shipperMode })} />
														Shipper{' '}
													</Label>
												</FormGroup>}
												{this.state.invoicedTo == 'receiver' && <FormGroup check className="pr-3">
													<Label check>
														<Input type="checkbox" checked={this.state.receiverMode} placeholder="" onChange={() => this.setState({ receiverMode: !this.state.receiverMode })} />
														Receiver{' '}
													</Label>
												</FormGroup>}
												<FormGroup check className="pr-3">
													<Label check>
														<Input type="checkbox" checked={this.state.creatorMode} placeholder="" onChange={() => this.setState({ creatorMode: !this.state.creatorMode })} />
														Creator{' '}
													</Label>
												</FormGroup>
												<FormGroup check className="pr-3">
													<Label check>
														<Input type="checkbox" checked={this.state.additionalMode} placeholder="" onChange={() => this.setState({ additionalMode: !this.state.additionalMode })} />
														Additional email
													</Label>
												</FormGroup>
											</div>
										</div>
										{
											additionalMode &&
											<>
												<div className="col-12">
													<FormGroup>
														<div className="input-group">
															<Input
																type="text"
																name="refno"
																onChange={this.handleInputChange}
																placeholder="Enter Email"
															/>
														</div>
													</FormGroup>
												</div>
											</>
										}
										<div className="d-flex justify-content-center">
											<div>
												<div className="scan-btn">
													{
														this.state.loader ?
															<button className="btn btn btn-submit mr-2 btn-block" disabled >
																< ClipLoader
																	size={15}
																	color={"#123abc"}
																	loading={true}
																/>
															</button>
															:
															<button className="btn btn btn-submit mr-2 btn-block" disabled={(creatorMode || receiverMode || shipperMode || additionalEmail || this.state.loader) ? false : true} onClick={this.scanHandler} >
																Next
															</button>
													}
												</div>
											</div>
										</div>
									</>

								</ModalBody>
								<ModalFooter>
									{/* <Button color="primary" onClick={this.toggle}>Do Something</Button>{' '} */}
									<Button color="secondary" onClick={() => this.setState({ showPopup: false })}>Cancel</Button>
								</ModalFooter>
							</Modal>}

							{print ?
								<PrintInvoice invoice={this.state.invoice} symbolCurrency={allDataOfShipper.package_currency} size={this.state.size} showPopup={() => this.setState({ showPopup: true })}
									reset={() => this.setState({ print: false, printButton: false })} /> :
								<>
									<Formik
										isInitialValid={false}
										enableReinitialize
										initialValues={{
											refno: this.state.refno,
											amountCharged: this.state.amountCharged,
											gst: this.state.gst,
											invoicedTo: this.state.invoicedTo,
											shipperName: this.state.shipperName,
											shipperPhone: this.state.shipperPhone,
											shipperEmail: this.state.shipperEmail,
											shipperAddress: this.state.shipperAddress,
											comment: this.state.comment,
											invoiceStatus: this.state.invoiceStatus,
											package_currency: this.state.package_currency,
										}}
										validationSchema={Schema}
										onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
											resetForm({ values: '' })
											this.setErrors = setErrors;
											this.submitHandler(values);
										}}>
										{({
											values,
											errors,
											touched,
											handleChange,
											handleSubmit,
											handleBlur,
											setFieldValue,
										}) => (
											<>
												<div className="col-12">
													<FormGroup>
														<Label>Enter Tracking Number</Label>
														<div className="input-group">
															<Input
																type="text"
																name="refno"
																invalid={errors.refno && touched.refno}
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.refno ? values.refno : this.state.referanceNo}
																placeholder="Enter Tracking Number"
															/>
															<button className="btn btn-submit mx-2" onClick={() => this.searchAdCode(values.refno)} >Search</button>
														</div>
													</FormGroup>
												</div>
												{this.state.showContent && <>
													<div className="col-12">
														<FormGroup>
															<Label >Amount Charged</Label>
															<div className="row">
																<div className="col-lg-3 col-xs-12 col-sm-12 mb-3 mb-sm-3">
																	<Select
																		placeholder={<div>Please Select Currency</div>}
																		name="package_currency"
																		onChange={(option) => setFieldValue('package_currency', option.value)}
																		onBlur={handleBlur}
																		className="is-invalid"
																		value={currencyList.filter(option => values.package_currency === option.value)}
																		options={currencyList}
																	/>
																</div>
																<FormFeedback>
																	{errors.package_currency && touched.package_currency && errors.package_currency}
																</FormFeedback>
																<div className="col-lg-9 col-xs-12 col-sm-12 mb-3 mb-sm-3">
																	<Input
																		type="text"
																		name="amountCharged"
																		invalid={errors.amountCharged && touched.amountCharged}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		value={values.amountCharged}
																		placeholder="Enter Amount Charged" />
																</div>
															</div>
															<FormFeedback>
																{errors.amountCharged && touched.amountCharged && errors.amountCharged}
															</FormFeedback>
														</FormGroup>
													</div>
													<div className="col-12">
														<FormGroup>
															<Label >GST / VAT Percentage </Label>
															<Input
																type="text"
																name="gst"
																invalid={errors.gst && touched.gst}
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.gst}
																placeholder="Enter GST / VAT Percentage" />
															<FormFeedback>
																{errors.gst && touched.gst && errors.gst}
															</FormFeedback>
														</FormGroup>
													</div>
													<div className="col-12">
														<FormGroup>
															<Label >Weight</Label>
															<Input
																disabled={true}
																value={allDataOfShipper.weight ? allDataOfShipper.weight : '-'}
															/>
														</FormGroup>
													</div>
													<div className="col-12">
														<FormGroup>
															<Label >Quantity</Label>
															<Input
																disabled={true}
																value={allDataOfShipper.shipped_items ? allDataOfShipper.shipped_items : '-'}
															/>
														</FormGroup>
													</div>
													<div className="col-12">
														<FormGroup>
															<Label >Receiver Name</Label>
															<Input
																disabled={true}
																value={allDataOfShipper.receiver_name ? allDataOfShipper.receiver_name : '-'}
															/>
														</FormGroup>
													</div>
													<div className="col-12">
														<FormGroup>
															<Label >Receiver Address</Label>
															<Input
																disabled={true}
																value={allDataOfShipper.receiver_address ? allDataOfShipper.receiver_address : '-'}
															/>
														</FormGroup>
													</div>
													<div className="col-12">
														<Label >Status </Label>
														<FormGroup>
															<div class="radio-item">
																<input type="radio"
																	invalid={errors.invoiceStatus && touched.invoiceStatus}
																	id="ritemc" name="invoiceStatus"
																	value="paid"
																	checked={values.invoiceStatus === 'paid'}
																	onChange={(option) => setFieldValue('invoiceStatus', option.target.value)}
																/>
																<label for="ritemc">Paid</label>
															</div>

															<div class="radio-item">
																<input
																	type="radio"
																	invalid={errors.invoiceStatus && touched.invoiceStatus}
																	id="ritemd"
																	name="invoiceStatus"
																	value="pending"
																	checked={values.invoiceStatus === 'pending'}
																	onChange={(option) => setFieldValue('invoiceStatus', option.target.value)}
																/>
																<label for="ritemd">Pending</label>
															</div>
															<div class="radio-item">
																<input
																	type="radio"
																	invalid={errors.invoiceStatus && touched.invoiceStatus}
																	id="riteme"
																	name="invoiceStatus"
																	value="cancelled"
																	checked={values.invoiceStatus === 'cancelled'}
																	onChange={(option) => setFieldValue('invoiceStatus', option.target.value)}
																/>
																<label for="riteme">Cancelled</label>
															</div>
															<Input
																type="hidden"
																name="invoiceStatus"
																invalid={errors.invoiceStatus && touched.invoiceStatus}
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.invoiceStatus}
															/>
															<FormFeedback>
																{errors.invoiceStatus && touched.invoiceStatus && errors.invoiceStatus}
															</FormFeedback>
														</FormGroup>
													</div>

													<div className="col-12">
														<Label >Invoiced To </Label>
														<FormGroup>
															<div class="radio-item">
																<input type="radio" invalid={errors.invoicedTo && touched.invoicedTo} id="ritema" name="invoicedTo" value="shipper"
																	checked={values.invoicedTo === 'shipper'}
																	onChange={(option) => setFieldValue('invoicedTo', option.target.value)} />
																<label for="ritema">Shipper</label>
															</div>

															<div class="radio-item">
																<input type="radio" invalid={errors.invoicedTo && touched.invoicedTo} id="ritemb" name="invoicedTo" value="receiver"
																	checked={values.invoicedTo === 'receiver'}
																	onChange={(option) => setFieldValue('invoicedTo', option.target.value)} />
																<label for="ritemb">Receiver</label>
															</div>
															<Input
																type="hidden"
																name="gst"
																invalid={errors.invoicedTo && touched.invoicedTo}
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.invoicedTo}
															/>
															<FormFeedback>
																{errors.invoicedTo && touched.invoicedTo && errors.invoicedTo}
															</FormFeedback>
														</FormGroup>
													</div>
													{values.invoicedTo === 'shipper' ?
														<>
															<div className="col-12">
																<FormGroup>
																	<Label >Shipper's Name </Label>
																	<Input
																		type="text"
																		name="shipperName"
																		onChange={handleChange}
																		invalid={errors.shipperName && touched.shipperName}
																		onBlur={handleBlur}
																		value={values.shipperName}
																		placeholder="Enter Shipper's Name" />
																	<FormFeedback>
																		{errors.shipperName && touched.shipperName && errors.shipperName}
																	</FormFeedback>
																</FormGroup>
															</div>
															<div className="col-12">
																<FormGroup>
																	<Label >Shipper's Email </Label>
																	<Input
																		type="text"
																		name="shipperEmail"
																		onChange={handleChange}
																		invalid={errors.shipperEmail && touched.shipperEmail}
																		onBlur={handleBlur}
																		value={values.shipperEmail}
																		placeholder="Enter Shipper's Email" />
																	<FormFeedback>
																		{errors.shipperEmail && touched.shipperEmail && errors.shipperEmail}
																	</FormFeedback>
																</FormGroup>
															</div>
															<div className="col-12">
																<FormGroup>
																	<Label >Shipper's Address </Label>
																	<Input
																		type="text"
																		name="shipperAddress"
																		invalid={errors.shipperAddress && touched.shipperAddress}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		value={values.shipperAddress}
																		placeholder="Enter Shipper's Address" />
																	<FormFeedback>
																		{errors.shipperAddress && touched.shipperAddress && errors.shipperAddress}
																	</FormFeedback>
																</FormGroup>
															</div>
															<div className="col-12">
																<FormGroup>
																	<Label >Shipper's Phone Number </Label>
																	<Input
																		type="number"
																		name="shipperPhone"
																		invalid={errors.shipperPhone && touched.shipperPhone}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		value={values.shipperPhone}
																		placeholder="Enter Shipper's Phone Number" />
																	<FormFeedback>
																		{errors.shipperPhone && touched.shipperPhone && errors.shipperPhone}
																	</FormFeedback>
																</FormGroup>
															</div>
														</> :
														null}
													<div className="col-12">
														<FormGroup>
															<Label>Comment</Label>
															<Input type="textarea"
																invalid={errors.comment && touched.comment}
																name="comment"
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.comment}
																placeholder="Enter comment" />
															<FormFeedback>
																{errors.comment && touched.comment && errors.comment}
															</FormFeedback>
														</FormGroup>
													</div>
												</>}



												{this.state.showContent && <div className="d-flex justify-content-center">
													<button className="btn btn-submit" onClick={handleSubmit}>
														Print
													</button>
												</div>}
											</>
										)}
									</Formik >
								</>}

						</CardBody>
					</Card >}
			</div >
		)
	}
}
