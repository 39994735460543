import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import {Button} from 'reactstrap'

export default class OurParcelGuardService extends Component {
  render() {
    return (
      <div className=" w-100 h-100">
        <div className="container">
          <div className="row pt-5">
            <Link class="btn btn-back" to="/"><i class="fa fa-long-arrow-left pr-3"> </i> Go Back</Link>
            {/* <div className="ml-auto">
            <span className="email-style"><i class="fa fa-envelope  " aria-hidden="true"></i> support@addressdynamic.com</span>
          </div> */}
          </div>
          <hr />
          <div className="main-contain-style ">
            <div>
              <h1 className=" main-contain-title">Our ParcelGuard Service</h1>
              <p className="main-contain-description">Our ParcelGuard service relieves you of the headache involved in tracking parcel and
              chasing up the courier service providers regarding the whereabout of your package. AddressDynamic independently takes over
              the monitoring of your parcel from pickup to delivery.
              We monitor your parcel minute by minute and send you updates twice a day. We also notify you through SMS when it is picked up,
              when it is delivered, and when something happens that may affect the delivery of your parcel on time.</p>
              <p className="main-contain-description">If your parcel goes missing or lost AddressDynamic will immediately follow up with the responsible courier and when necessary
              help you with requesting a claim.
              Our fee for this service is USD $1.99 per tracking number. 100% refund with no question asked if you're not satisfied
                 with the service we provided.</p>
            </div>
            <div className="pt-3 d-flex justify-content-center pb-3">
              <Button className="btn btn-login ">Request Service</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
