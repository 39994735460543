import React, { Component } from 'react'
import { Card, CardBody, CardHeader, FormFeedback, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import Select from 'react-select';
import { uploadCSV, checkScreenAccess, requestAccess, shipmentFilterResult, getInvoiceReports } from '../../api/Form';
import { toast } from 'react-toastify';
import { ClipLoader } from "react-spinners";
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import "react-datepicker/dist/react-datepicker.css";
import DataTableExtensions from 'react-data-table-component-extensions';
import { callActivityApi } from '../../utils/saveUserActivity';
import { phoneNoVerifyCheck } from '../../utils/utils';
const categoryOption = [
  { label: 'paid', value: 'paid' },
  { label: 'pending', value: 'pending' },
  { label: 'all', value: 'all' }
];
export default class InvoiceReports extends Component {
  input = React.createRef();
  state = {
    userId: '',
    deviceADcode: '',
    nfcADcode: '',
    nfcserialno: '',
    document: null,
    loading: false,
    data: [],
    showData: [],
    columns: [],
    location: 'system_generated',
    showTable: true,
    requestAccess: true,
    startDate: '',
    endDate: '',
    invoice_status: ''
  }

  async componentDidMount() {
    callActivityApi({ 'pageName': 'Invoice Reports', 'description': 'User has visited Invoice Reports Screen' });
    const historyPush = this.props.history.push
    phoneNoVerifyCheck(historyPush)
    const data = await localStorage.getItem('auth');
    const { id, ADCode } = JSON.parse(data);
    this.setState({ userId: id, deviceADcode: ADCode });
    var userType = await localStorage.getItem('accounttypeUsers');
    if (userType !== 'Admin') {
      checkScreenAccess(
        {
          'userId': id,
          'screenType': 'Invoice Reports'
        }
      ).then((response) => {
        this.setState({ loading: false, requestAccess: true });
      }).catch(err => {
        this.setState({ loading: false, requestAccess: false });
      });
    } else {
      this.setState({ requestAccess: true, loading: false })
    }

    let dataInvoice = (refNo) => {
      this.props.history.push({ pathname: '/admin/invoiceByAmount', state: refNo })
    }

    let columns = [
      {
        name: 'Tracking Number',
        selector: 'ref_no',
        cell: (row) => (
          <div className="flex mr-6 pr-2" style={{ width: 200 }}>
            <a href="javascript:void(0)" onClick={() => dataInvoice(row.ref_no)} className='text-primary p-1'>{row.ref_no}</a>
          </div>
        ),
        sortable: true,
      },
      {
        name: 'Amount Charged',
        selector: 'amount_charged',
        sortable: true,
      },
      {
        name: 'Vat Percentage',
        selector: 'vat_percentage',
        sortable: true,
      },
      {
        name: 'Invoice Status',
        selector: 'invoice_status',
        sortable: true,
      },
      {
        name: 'Shipped Items',
        selector: 'shipped_items',
        sortable: true,
      },
      {
        name: 'Weight',
        selector: 'weight',
        sortable: true,
      },
      {
        name: 'Receiver Name',
        selector: 'receiver_name',
        cell: (row) => {
          return (
            <>
              {
                row.invoice_to === 'receiver' &&
                <div className="flex mr-6 pr-2" style={{ width: 200 }}>
                  <span>{row.receiver_name}</span>
                </div>
              }
            </>
          )
        },
        sortable: true,
      },
      {
        name: 'Shipper Name',
        selector: 'receiver_name',
        cell: (row) => {
          return (
            <>
              {
                row.invoice_to === 'shipper' &&
                <div className="flex mr-6 pr-2" style={{ width: 200 }}>
                  <span>{row.receiver_name}</span>
                </div>
              }
            </>
          )
        },
        sortable: true,
      },
      {
        name: 'Additional Email',
        selector: 'additional_email',
        sortable: true,
      },
    ];

    // call api
    getInvoiceReports(
      {
        'userId': id
      }
    ).then((response) => {
      console.log('responseData', response);
      this.setState({ loading: false, showData: response.data.data });
    }).catch(err => {
      this.setState({ loading: false });
    });

    this.setState({ columns });
  }

  nfcADcodeHandler = () => {
    this.setState({ modal: !this.state.modal })
  }

  printErrors = (obj) => {
    var string = '';
    obj.map((val) => {
      for (var k in val) {
        if (val[k] instanceof Object) {
          this.printErrors(val[k]);
        } else {
          console.log(val[k] + "<br>");
          if (string) {
            string += ', ' + val[k];
          } else {
            string += val[k];
          }

        };
      }
    })
    return string;
  };


  // searchData

  searchData = () => {
    this.setState({ loading: true });
    // call api

    getInvoiceReports(
      {
        'userId': this.state.userId,
        'invoice_status': this.state.invoice_status
      }
    ).then((response) => {
      this.setState({ loading: false, showData: response.data.data });
    }).catch(err => {
      this.setState({ loading: false, showData: [] });
    });

  };
  sendRequestAccess = async () => {
    this.setState({ loading: true });
    const info = await localStorage.getItem('auth');
    const sendInfo = JSON.parse(info);
    requestAccess({
      'username': sendInfo.username,
      'firstName': sendInfo.firstName,
      'lastName': sendInfo.lastName,
      'accounttypeUsers': sendInfo.accounttypeUsers,
      'businessname': sendInfo.businessname,
      'ADCode': sendInfo.ADCode,
      'address': sendInfo.address,
      'email': sendInfo.email,
      'phoneNumber': sendInfo.phonenumber,
      'userId': sendInfo.id,
      'screenType': 'Invoice Reports',
    }).then(Response => {
      this.setState({ loading: false });
      toast.success(Response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }).catch(err => {
      this.setState({ loading: false });
      let message = err.message;
      if (err.response && err.response.data.message) {
        message = err.response.data.message;
      }
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })
  };

  render() {
    const { loading, data, columns, showTable, requestAccess, showData } = this.state;

    const tableData = {
      columns,
      exportHeaders: true,
      data: showData,
    };
    return (
      <div>
        {!requestAccess ? <div className="justify-content-center pt-2 main-contain-title">
          <>
            <p>This feature is restricted.</p>
            <Button onClick={() => this.sendRequestAccess()}
              disabled={this.state.loading}>Request Access for free</Button>
          </>
        </div> :
          <>
            <div className="d-flex justify-content-center scan-parcel-loader">
              <ClipLoader
                size={50}
                color={"#123abc"}
                loading={loading}
              />
            </div>
            {!loading ?
              <Card>
                <CardHeader className="c-header">Invoice History</CardHeader>

                <div className='row mt-4 col-12'>
                  <div className='col-4'>
                    <FormGroup>
                      <Select
                        placeholderText="Select Status"
                        name="invoice_status"
                        onChange={(option) => this.setState({ invoice_status: option.value })}
                        className="is-invalid"
                        value={categoryOption.filter(option => this.state.invoice_status === option.value)}
                        options={categoryOption}
                      />
                    </FormGroup>
                  </div>
                  <div className='col-4'>
                    <button className="btn btn-submit" onClick={() => { this.searchData() }} >Search</button>
                  </div>
                </div>
                {showTable ?
                  <DataTableExtensions  {...tableData}>
                    <DataTable
                      className="dataTables_wrapper"
                      pagination
                      highlightOnHover
                      columns={columns}
                      export={true}
                      responsive={true}
                      data={data}
                    />
                  </DataTableExtensions> : null}
              </Card>
              : null}
          </>
        }

      </div>
    )
  }
}
