import React, { Component } from 'react'
import { Card, CardBody } from 'reactstrap'
import './PrintForm.css'
import QRCode from "react-qr-code";
import Barcode from 'react-barcode';
import { getShipment } from '../../api/Form';
import { toast } from 'react-toastify';
import moment from 'moment';
import Logo2 from "../../assets/new_logo.png";

export default class PrintForm extends Component {
	state = {
		size: '',
		refno: '',
		firstnameUsers: '',
		lastnameUsers: '',
		businessnameUsers: '',
		receiverName: '',
		deliveryAddress: '',
		weight: '',
		date: '',
		nfcADcode: '',
		addressUsers: '',
		carrierName: '',
		comment: '',
		quantity: '',
		receiverLastName: '',
		receiverFirstName: '',
		receiverBusinessName: '',
		receiverName: '',
		receiverPhone: '',
		pickup: '',
		shipperName1: '',
		shipperAddress1: '',
		print: false
	}

	componentDidMount() {
		const shipment = this.props.format;
		const size = this.props.size
		this.setState({ ...shipment, size })
	}

	checkReference = () => {
		getShipment({ refno: this.state.refno })
			.then(response => {
				const { refno, firstnameUsers, businessnameUsers, addressUsers, receiverName, lastnameUsers, deliveryAddress, receiverFirstName, receiverBusinessName, receiverLastName, weight, date, comment, quantity, pickup, receiverPhone } = response.data.data;
				this.setState({
					refno,
					firstnameUsers,
					businessnameUsers,
					addressUsers,
					lastnameUsers,
					deliveryAddress,
					weight,
					date,
					comment,
					quantity,
					receiverName,
					receiverPhone,
					receiverFirstName,
					receiverLastName,
					receiverBusinessName,
					pickup,
				});
			})
			.catch(err => {
				this.setState({ error: err.response.data.message });
				toast.error('This reference number does not exist!', {
					position: toast.POSITION.TOP_RIGHT
				})
			})
	}

	render() {
		const { refno,
			firstnameUsers,
			nfcADcode,
			modeOfTransport,
			carrierName,
			addressUsers,
			receiverName,
			receiverPhone,
			origin,
			lastnameUsers,
			receiverBusinessName,
			deliveryAddress,
			receiverFirstName,
			receiverLastName,
			weight,
			date,
			comment,
			quantity,
			pickup,
			shipperName1,
			shipperAddress1,
			destination,
			destinationCountry,
			length,
			width,
			height,
			masterNo
		} = this.state;

		const modeOfTransports = {
			"Air": "AWB",
			"Sea": "SWB",
		}
		const billType = modeOfTransports[modeOfTransport] || "CONNOTE No"

		return <div className="tabStyle" ref={this.props.propsRef}> {Array.from(Array(+quantity)).map((_, i) => (
			<div key={i} className="Section1 landscape">
				<Card className='print-adhoc'>
					<CardBody >
						<div className="row">
							<div className="col-12 d-flex justify-content-between gap-2">
								<h2 className='float-left pt-2'><b>{billType}: {refno}</b></h2>
								<img src={Logo2} alt="Logo" className="img-fluid pdf-logo-img ml-4" />
							</div>
						</div>
						{/* <hr className="border-style my-2" /> */}
						<div className='mt-3'>
							<div className="row">
								<div className='col-12 d-flex justify-content-between gap-2'>
									<div>
										<h3 className="print-header-title mr-2 font02">Deliver To:</h3>

										<p className="print-header-description font02" > {receiverName || `${receiverFirstName} ${receiverLastName}`}</p>

										{receiverPhone ? <p className="print-header-description font02"><b style={{ fontWeight: 'bold' }}>Ph:</b>{receiverPhone}</p>
											: null}
										<p className="print-header-description font02">{receiverBusinessName}</p>
										<p className="print-header-description font02">{deliveryAddress}</p>
										<p className="print-header-description font02">{destinationCountry}</p>

									</div>
									<div>
										<div className='label-creator' style={{ float: "right", textAlign: "left", width: "220px" }}>

											<div className="barcode-size barcode-1" style={{ float: "right", width: "220px" }}>
												<h3 className="print-header-title mb-0 pl-2" style={{ fontSize: "17px", width: "220px", float: "left", textAlign: "left", fontWeight: "600" }}>
													Label Creator ID:
												</h3>
												<Barcode width={1.99} height={35} value={nfcADcode} fontSize={15} />
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="row mt-2">
								<div className='col-6'>
									<div className="row m-0 ">
										<h3 className="print-header-title mr-2">Carrier:</h3>
										<p className="print-header-description">{carrierName}</p>
									</div>
									<div className="row m-0 ">
										<h3 className="print-header-title mr-2">Weight:</h3>
										<p className="print-header-description">{weight}KG</p>
									</div>
									<div className="row m-0 ">
										<h3 className="print-header-title mr-2">Quantity:</h3>
										<p className="print-header-description">{quantity}</p>
									</div>
									<div className="row m-0 ">
										<h3 className="print-header-title mr-2">No of Package:</h3>
										<p className="print-header-description">{i + 1} of {quantity}</p>
									</div>
									<div className="row m-0 ">
										<h3 className="print-header-title mr-2">MOT:</h3>
										<p className="print-header-description">{modeOfTransport}</p>
									</div>
									<div className="row m-0 ">
										<h3 className="print-header-title mr-2">ORI:</h3>
										<p className="print-header-description">{origin}</p>
									</div>
									<div className="row m-0 ">
										<h3 className="print-header-title mr-2">DES:</h3>
										<p className="print-header-description">{destination}</p>
									</div>
									<div className="row m-0 ">
										<h3 className="print-header-title mr-2">DIMS:</h3>
										<p className="print-header-description">{length} * {width} * {height}</p>
									</div>

								</div>
								<div className='col-6'>
									<div className="mb-3">
										<h3 className="print-header-title mr-2">From:</h3>
										<span className="print-header-description">{shipperName1 ? shipperName1 : firstnameUsers + ' ' + lastnameUsers}</span>
										<p className="print-header-description">{shipperAddress1 || pickup}</p>
									</div>
									<QRCode size={80} value={refno} />
									<div className='mt-2'>
										<h3 className="print-header-title mr-2">Date: <span className="print-header-description">{date && moment(date).format('DD/MM/YYYY')}</span> </h3>

									</div>
									<div >
										<h3 className="print-header-title mr-2">Comment: <span className="print-header-description">{comment}</span> </h3>

									</div>
								</div>
							</div>
						</div>

						<div className="row mt-2">

							<div className="col-12 text-center">
								<Barcode width={3} height={70} value={refno} fontSize={20} />
							</div>
						</div>
					</CardBody>
				</Card>
			</div>
		))
		}
		</div>
	}
}
