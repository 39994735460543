import React, { Component } from 'react'
import { CardHeader, CardBody, FormGroup, Label, Input, Card, FormFeedback } from 'reactstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';
import './PriceList.css'
import { scanParcel, getPriceList, updatePriceList, getShipment, trackShipment } from '../../api/Form'
import { getLocation, makeid } from '../../utils/utils';
import { toast } from 'react-toastify';

const Schema = Yup.object().shape({
    bike_per_km: Yup.number().required('Price is required'),
    car_per_km: Yup.number().required('Price is required'),
    bike_per_min: Yup.number().required('Price is required'),
    car_per_min: Yup.number().required('Price is required'),
});

export default class PriceList extends Component {
  state = {
    bike_per_km: 0,
    bike_per_min: 0,
    car_per_km: 0,
    car_per_min: 0,
    priceList: [],
  }

  async componentDidMount() {
    const deviceADcode = await localStorage.getItem('adCodes');
    this.setState({ deviceADcode})
    const userId = await localStorage.getItem('userId');
    getPriceList().then(Response => {
        if(Response.data.status === true){
            this.setState({ priceList: Response.data.data})
        }
       //  console.log(this.state.priceList);
    })
    getLocation().then(response => {
      this.setState({ userId, location: response });
    }).catch(e => console.log(e, 'error'))
  }

  submitHandler = (values) => {
    updatePriceList(values)
			.then(response => {
				toast.success('Updated successfully!!', {
					position: toast.POSITION.TOP_RIGHT
				})
			})
			.catch(err => {
				toast.error('Something went wrong !!', {
					position: toast.POSITION.TOP_RIGHT
				})
			})
  }

  handlerError = (error) => {
    console.log(error)
  }

  

  render() {
    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            bike_per_min: this.state.priceList.length ? this.state.priceList[0].price : 0,
            bike_per_km: this.state.priceList.length ? this.state.priceList[1].price : 0,
            car_per_min: this.state.priceList.length ? this.state.priceList[2].price : 0,
            car_per_km: this.state.priceList.length ? this.state.priceList[3].price : 0,
          }}
          validationSchema={Schema}
          onSubmit={(values, { setFieldError }) => {
            this.submitHandler(values);
          }}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
          }) => (
              <>
                <div className="mt-2 style">
                  <Card>
                    <CardHeader className="c-header">Price Configuration</CardHeader>

                    <CardBody>
                      <div className="col-12">
                        <FormGroup>
                          <Label >Bike (per minute)</Label>
                          <Input
                            invalid={errors.bike_per_min && touched.bike_per_min}
                            type="number"
                            name="bike_per_min" onChange={handleChange} onBlur={handleBlur} value={values.bike_per_min} placeholder="Enter Price" />
                          <FormFeedback>
                            {errors.bike_per_min && touched.bike_per_min && errors.bike_per_min}
                          </FormFeedback>
                        </FormGroup>
                      </div>
                      <div className="col-12">
                        <FormGroup>
                          <Label >Bike (per kilometer)</Label>
                          <Input type="number"
                            invalid={errors.bike_per_km && touched.bike_per_km}
                            name="bike_per_km" onChange={handleChange} onBlur={handleBlur} value={values.bike_per_km} placeholder="Enter Price" />
                          <FormFeedback>
                            {errors.bike_per_km && touched.bike_per_km && errors.bike_per_km}
                          </FormFeedback>
                        </FormGroup>
                      </div>
                      <div className="col-12">
                        <FormGroup>
                          <Label >Car (per minute)</Label>
                          <Input type="number"
                            invalid={errors.car_per_min && touched.car_per_min}
                            name="car_per_min" onChange={handleChange} onBlur={handleBlur} value={values.car_per_min} placeholder="Enter Price" />
                          <FormFeedback>
                            {errors.car_per_min && touched.car_per_min && errors.car_per_min}
                          </FormFeedback>
                        </FormGroup>
                      </div>
                      <div className="col-12">
                        <FormGroup>
                          <Label >Car (per kilometer)</Label>
                          <Input type="number"
                            invalid={errors.car_per_km && touched.car_per_km}
                            name="car_per_km" onChange={handleChange} onBlur={handleBlur} value={values.car_per_km} placeholder="Enter Price" />
                          <FormFeedback>
                            {errors.car_per_km && touched.car_per_km && errors.car_per_km}
                          </FormFeedback>
                        </FormGroup>
                      </div>
                      <div className="d-flex justify-content-center">
                        <button className="btn btn-submit" onClick={handleSubmit} >Submit</button>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </>
            )}
        </Formik>
      </div>
    )
  }
}
