import React, { Component } from 'react'
import { CardHeader, CardBody, FormGroup, Label, Input, Card, FormFeedback, Table } from 'reactstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {  updatePriceList, getApiClientList } from '../../api/Form'
import { getLocation, makeid } from '../../utils/utils';
import { toast } from 'react-toastify';

const Schema = Yup.object().shape({
    bike_per_km: Yup.number().required('Price is required'),
    car_per_km: Yup.number().required('Price is required'),
    bike_per_min: Yup.number().required('Price is required'),
    car_per_min: Yup.number().required('Price is required'),
});

export default class ApiAccess extends Component {
  state = {
    clientList: [],
  }

  async componentDidMount() {
    getApiClientList().then(Response => {
        if(Response.data.status === true){
            this.setState({ clientList: Response.data.data})
        }
       // console.log(this.state.clientList);
    })
  }

  goToApiSettings = (id) => {
        this.props.history.push('/admin/grantApiAcess/'+id)
  }
  submitHandler = (values) => {
    updatePriceList(values)
			.then(response => {
				toast.success('Updated successfully!!', {
					position: toast.POSITION.TOP_RIGHT
				})
			})
			.catch(err => {
				toast.error('Something went wrong !!', {
					position: toast.POSITION.TOP_RIGHT
				})
			})
  }

  handlerError = (error) => {
    console.log(error)
  }

  

  render() {
    const { clientList } = this.state;
    return (
      <div>
          <div className="card">
              <div className="c-header card-header">
                <div class="row ">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-8">
                        <span>API Client List</span>
                    </div>
                </div>
              </div>
          </div>
        <Table striped bordered hover size="sm">
            <thead>
                <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Site Link</th>
                <th>Token</th>
                <th>Action</th>
                </tr>
            </thead>
            <tbody>
            {clientList.length?clientList.map((item, i) => ( 
                <tr key={i}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.site_url}</td>
                    <td>{item.access_token}</td>
                    <td><button class="btn btn-submit-track px-4" onClick={() => this.goToApiSettings(item.user_id)}>Settings</button></td>
                </tr>
            )): <tr>
                  <td colSpan="5" style={{textAlign: 'center'}}>Data not found!!</td>
              </tr>}
                
            </tbody>
        </Table>
      </div>
    )
  }
}
