import React, { Component } from 'react'
import { Card, CardBody, CardHeader} from 'reactstrap';
import { getPurchaseHistory } from '../../api/Form';
import { ClipLoader } from "react-spinners";
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import "react-datepicker/dist/react-datepicker.css";
import DataTableExtensions from 'react-data-table-component-extensions';
import { callActivityApi } from '../../utils/saveUserActivity';
import PurchaseInvoice from './PurchaseInvoice';

export default class PaypalHistory extends Component {
    input = React.createRef();
    state = {
        userId: '',
        deviceADcode: '',
        nfcADcode: '',
        nfcserialno: '',
        document: null,
        loading: false,
        data: [],
        showData: [],
        columns: [],
        location: 'system_generated',
        showTable: true,
        requestAccess: true,
        startDate: '',
        endDate: '',
        activeInvoice: {},
        showInvoice: false,
    }

    async componentDidMount() {
        callActivityApi({ 'pageName': 'Paypal History', 'description': 'User has visited Paypal history Screen' });
        const data = await localStorage.getItem('auth');
        const { id, ADCode } = JSON.parse(data);
        this.setState({ userId: id, deviceADcode: ADCode });
        var userType = await localStorage.getItem('accounttypeUsers');


        let columns = [
            {
                name: 'Credits',
                selector: 'credits',
                sortable: true,
            },
            {
                name: 'Amount',
                selector: 'credits_amount',
                sortable: true,
            },
            {
                name: 'Invoice Number',
                selector: 'invoice_number',
                sortable: true,
            },
            {
                name: 'Date of Purchase',
                selector: 'created',
                sortable: true,
            },
            {
                name: "Action",
                cell: (row) => (
                    <div className="row pl-4" style={{ width: 80 }}>
                        <a href="javascript:void(0)" onClick={() => this.viewInvoice(row)} className='text-primary p-1'>View</a>
                    </div>
                ),

                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            },

        ];

        // call api
        this.callApi();
        this.setState({ columns });
    }

    viewInvoice = (invoice) => {
        this.setState({ activeInvoice: invoice, showInvoice: true })
    }

    nfcADcodeHandler = () => {
        this.setState({ modal: !this.state.modal })
    }

    printErrors = (obj) => {
        var string = '';
        obj.map((val) => {
            for (var k in val) {
                if (val[k] instanceof Object) {
                    this.printErrors(val[k]);
                } else {
                    console.log(val[k] + "<br>");
                    if (string) {
                        string += ', ' + val[k];
                    } else {
                        string += val[k];
                    }

                };
            }
        })
        return string;
    };

    callApi = () => {
        this.setState({ loading: true });
        getPurchaseHistory({
            userId: this.state.userId
        }).then((response) => {
            this.setState({ loading: false, showData: response.data.data, showTable: true })
        }).catch(err => {
            this.setState({ loading: false, showData: [], showTable: true })
        })
    }


    render() {
        const { loading, data, columns, showTable, requestAccess, showData, showInvoice } = this.state;

        const tableData = {
            columns,
            exportHeaders: true,
            data: showData,
        };

        return (
            <div>
                <>
                    {loading && <div className="d-flex justify-content-center scan-parcel-loader">
                        <ClipLoader
                            size={50}
                            color={"#123abc"}
                            loading={loading}
                        />
                    </div>}

                    {showInvoice ? <PurchaseInvoice invoice={this.state.activeInvoice} /> : <Card>
                        <CardHeader className="c-header">Download Payment History</CardHeader>
                        {showTable ?
                            <DataTableExtensions  {...tableData}>
                                <DataTable
                                    className="dataTables_wrapper"
                                    pagination
                                    highlightOnHover
                                    columns={columns}
                                    export={true}
                                    responsive={true}
                                    data={data}
                                />
                            </DataTableExtensions> : null}
                    </Card>}
                </>
            </div>
        )
    }
}
