import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Geocoder from 'react-geocode';
import { Helmet } from 'react-helmet';

Geocoder.setApiKey(process.env.REACT_APP_GEOCODE_KEY);

const googleTagId = process.env.REACT_APP_GOOGLE_TAG_ID;
const searchConsoleVerficationId = process.env.REACT_APP_GOOGLE_SITE_VERIFICATION;

if (googleTagId) {
  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=${googleTagId}`;
  script.async = true;
  document.head.appendChild(script);

  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', googleTagId);
  };
}

// Add the meta tag for Google site verification
if (searchConsoleVerficationId) {
  const metaTag = document.createElement('meta');
  metaTag.name = 'google-site-verification';
  metaTag.content = searchConsoleVerficationId;
  document.head.appendChild(metaTag);
}

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <title>Global Parcel Tracking - Shipment & Delivery | Postkodes</title>
      <meta name="description"
        content="Need cheap international shipping? Postkodes provides global parcel tracking, pickup services, and worldwide delivery solutions at great prices." />
    </Helmet>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
