import React, { Component, useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print';
import PrintFile from './PrintFile';
import currenySymbol from "../Invoice/currencies.json"

const tabStyle = {
  height: 800,
  maxHeight: 1000,
  overflowY: "scroll"
  //backgroundColor: "blue"
};
export default function PrintInvoice(props) {
  const [allSymbol, _allSymbol] = useState('')
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    _allSymbol(currenySymbol.find((o) => o.iso_code === props.symbolCurrency)?.symbol)
  }, [props.symbolCurrency])

  return (
    <div style={tabStyle}>
      <div className="justify-content-center">
        <PrintFile ref={componentRef} format={props.invoice} symbolCurrency={allSymbol} />
      </div>
      <div className="d-flex justify-content-center" id="no-print" >
        <button className="btn btn-submit ml-2 mt-3" onClick={handlePrint}>Print this out!</button>
        <button className="btn btn-submit ml-2 mt-3" onClick={props.reset}>Print another</button>
        <button className="btn btn-submit ml-2 mt-3" onClick={props.showPopup}>Send Invoice</button>
      </div>
    </div>
  )
}
