import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css'
import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormFeedback,
} from 'reactstrap';
import Select from 'react-select';
import { ClipLoader } from "react-spinners";
import * as Yup from 'yup';
import close from '../../assets/close.svg';
import { getShipment, getMultipleShipments } from '../../api/Form';
import { toast } from 'react-toastify';
import Print from './Print';
import style from 'classnames';
import { callActivityApi } from '../../utils/saveUserActivity';

class AdhocMultiplePrint extends Component {
  input = React.createRef();
  state = {
    loading: false,
    refno: '',
    shipment: '',
    modal: false,
    print: false,
    printButton: false,
    nfcADcode: '',
    size: '',
    editScreen: false,
    deviceADcode: '',
    location: '',
    nfcserialno: '',
    userId: '',
    tags: [],
    errorTags: [],
    editTags: [],
    editActiveValue: '',
    customErr: '',
  }

  async componentDidMount() {
    callActivityApi({ 'pageName': 'Print Multiple Label', 'description': 'User has visited Print Multiple Label Screen' });
    const { refno } = this.props.location.state || {};
    this.setState({ refno });
    const data = await localStorage.getItem('auth');
    const { id, ADCode, address } = JSON.parse(data);
    this.setState({ userId: id, deviceADcode: ADCode, pickup: address });
  }


  checkReference = () => {
    if (!this.state.customErr) {
      this.setState({ loading: true });
      //call api to check here info
      let data = {
        ref_nums: this.state.tags,
        showValues: true,
      };
      getMultipleShipments(data)
        .then(Response => {
          //console.log(Response);
          this.setState({ shipment: Response.data.data, printButton: true, loading: false, errorTags: [] });
        }).catch(err => {
          this.setState({ loading: false, tags: [], errorTags: [] });
          let message = err.message;
          if (err.response && err.response.data.message) {
            message = err.response.data.message;
          }
          toast.error(message, {
            position: toast.POSITION.TOP_RIGHT
          });
        });
    } else {
      this.setState({ printButton: false, tags: [], errorTags: [] });
      toast.error('Please check tracking numbers', {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  }

  QRGenerator = () => {
    this.setState({ modal: true })
  }

  toggle = () => {
    this.setState({ modal: !this.state.modal })
  }
  loadCss = (stylePath) => {
    const head = document.head;
    if (this.state.link) {
      head.removeChild(this.state.link);
    }
    const link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = stylePath;
    head.appendChild(link);
    this.setState({ link })
  }
  unloadCss = () => {
    const head = document.head;
    if (this.state.link) {
      head.removeChild(this.state.link);
    }
  }

  componentWillUnmount() {
    this.unloadCss();
  }
  handleTags = (tags) => {
    this.setState({ loading: true });
    //call api to check here info
    let data = {
      ref_nums: tags
    };
    getMultipleShipments(data)
      .then(Response => {
        //console.log(Response);
        this.setState({ loading: false, tags: tags, customErr: '' });
      }).catch(err => {
        this.setState({ loading: false });
        let message = err.message;
        if (err.response && err.response.data.message) {
          message = err.response.data.message;
        }
        this.setState({ errorTags: (err.response.data.data === null ? [] : err.response.data.data), tags: tags, customErr: message });
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT
        });
      });

  };
  checkEdit = (key) => {
    let editTags = [];
    editTags.push(this.state.tags[key]);
    this.setState({ editTags, editActiveValue: this.state.tags[key] });
  };

  handleEdit = (value, key) => {
    // console.log(value);
    let tags = this.state.tags;
    tags[key] = value;
    this.setState({ loading: true, tags, editActiveValue: '', editTags: [] });
    //call api to check here info
    let data = {
      ref_nums: tags
    };
    getMultipleShipments(data)
      .then(Response => {
        //console.log(Response);
        this.setState({ loading: false, tags: tags, customErr: '' });
        //setFieldValue('refno', tags.toString());
      }).catch(err => {
        this.setState({ loading: false });
        let message = err.message;
        if (err.response && err.response.data.message) {
          message = err.response.data.message;
        }

        this.setState({ errorTags: (err.response.data.data === null ? [] : err.response.data.data), tags: tags, customErr: message });
        //setFieldValue('refno', tags.toString());
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  };

  pasteSplit = (data) => {
    const separators = [',', ';', '\\(', '\\)', '\\*', '/', ':', '\\?', '\n', '\r', 'g'];
    return data.split(new RegExp(separators.join('|'))).map(d => d.trim());
  };
  renderTags = (props) => {
    // console.log(this.state.errorTags);
    let { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props;
    let classvar = { ...other };
    if (this.state.errorTags.includes(getTagDisplayValue(tag))) {
      classvar.className = "react-tagsinput-tag error";
    }
    if (this.state.editTags.includes(getTagDisplayValue(tag))) {
      return (
        <Input
          name={'edit' + key}
          onChange={(tags) => {
            this.setState({ editActiveValue: tags.target.value });
          }}
          onKeyPress={event => {
            //console.log(event.key);
            if (event.key === 'Enter') {
              this.handleEdit(event.target.value, key);
            }
          }}
          className={'editTag'}
          value={this.state.editActiveValue}
        />
      )
    } else {
      return (
        <span key={key} {...classvar} >
          <span onClick={() => this.checkEdit(key)}>{getTagDisplayValue(tag)}</span>
          {!disabled &&
            <a className={classNameRemove} onClick={(e) => onRemove(key)} />
          }
        </span>
      )
    }
  };


  printSizeHandler = (e) => {
    if (e === '150*100mm') this.loadCss('/PrintForm.css');
    else if (e === '99.1*67.7mm') this.loadCss('/PrintForm1.css');
    else this.loadCss('/PrintForm2.css');
    this.setState({ modal: false, print: true, size: e })
  }

  render() {
    const { print, printButton } = this.state
    return (
      <div>
        <div className="mt-2 style">
          <Modal isOpen={this.state.modal} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}>Choose sizes</ModalHeader>
            <ModalBody>
              <div className="row d-flex justify-content-center">
                <button className={style('btn btn-submit ml-2 mt-2', { 'printActive': this.state.size === '150*100mm' })}
                  onClick={() => this.printSizeHandler('150*100mm')}>150x100mm
                </button>
                <button className={style('btn btn-submit ml-2 mt-2', { 'printActive': this.state.size === '99.1*67.7mm' })}
                  onClick={() => this.printSizeHandler('99.1*67.7mm')}>99.1x67.7mm
                </button>
                <button className={style('btn btn-submit ml-2 mt-2', { 'printActive': this.state.size === '64*24.3mm' })}
                  onClick={() => this.printSizeHandler('64*24.3mm')}>64x24.3mm
                </button>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.toggle}>Cancel</Button>
            </ModalFooter>
          </Modal>
          <Card>
            <CardHeader className="c-header">Adhoc Bulk Label Print</CardHeader>
            <CardBody>
              {print ?
                this.state.shipment.map(p => {
                  return (
                    <Print nfcADcode={p} size={this.state.size}
                      reset={() => this.setState({ print: false, printButton: false })} />
                  )
                }) :
                <>
                  <div className="col-12">
                    <FormGroup>
                      <Label>Tracking Numbers</Label>
                      <TagsInput
                        value={this.state.tags}
                        onChange={(tags) => this.handleTags(tags)}
                        renderInput={this.defaultRenderInput}
                        pasteSplit={this.pasteSplit}
                        addOnPaste={true}
                        onlyUnique={true}
                        renderTag={this.renderTags}
                        addKeys={[9, 13, 188]}
                        inputProps={{
                          className: 'react-tagsinput-input',
                          placeholder: ''
                        }}
                      />
                      <FormFeedback>
                        {this.state.customErr}
                      </FormFeedback>
                    </FormGroup>
                  </div>
                  <div className="d-flex justify-content-center">
                    <button className="btn btn-submit" onClick={this.checkReference}>
                      {this.state.loading ?
                        < ClipLoader
                          size={15}
                          color={"#123abc"}
                          loading={true}
                        /> : 'Check'
                      }
                    </button>
                    <button className="btn btn-submit ml-2" disabled={!printButton} onClick={this.QRGenerator}>Print
                    </button>
                  </div>
                </>
              }
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default AdhocMultiplePrint;
