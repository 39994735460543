import React, { useState, useRef } from 'react';
import { Card, CardBody, Button, Modal, ModalHeader, ModalBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import QRCode from 'react-qr-code';
import Barcode from 'react-barcode';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import Logo2 from "../../assets/new_logo.png";


const dateFormat = (date) => {
    if (!date) {
        return
    }

    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    const formattedDate = new Date(date).toLocaleDateString('en-GB', options);
    const [day, month, year] = formattedDate.split(' ');
    return `${day} ${month.toUpperCase()} ${year}`;
};


const PrintShippingBill = React.forwardRef((props, ref) => (

    <div className="tabStyle print-bill-pdf-view-container" ref={ref} >

        <div className="Section1 landscape print-bill-pdf-view">
            <Card cla>
                <CardBody>
                    <div className="watermark"> {props.selectedOption} </div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <div xs="auto">
                            <img src={Logo2} alt="Logo" className="img-fluid pdf-logo-img ml-4" />
                        </div>
                        <div className="text-right company-name">
                            <p><b>POSTKODES INTERNATIONAL</b></p>
                            <p><b>NON-NEGOTIABLE WAYBILL</b></p>
                        </div>
                    </div>

                    <div>
                        <table className="print-pdf" style={{ width: "100%" }}>
                            <tbody>
                                <tr>
                                    <td>
                                        <p>SHIPPER </p>
                                        <p className='text-uppercase'>

                                            <b>{props?.shipmentData?.shipper_address},{"  "}
                                                {props?.shipmentData?.shipper_zipcode},{"  "}
                                                {props?.shipmentData?.shipper_city},{"  "}
                                                {props?.shipmentData?.shipper_country}</b>
                                        </p>
                                    </td>
                                    <td>
                                        <table className="print-pdf">
                                            <tbody>
                                                <tr>
                                                    <td><p>SHIPPER'S REF.<b>{props?.shipmentData?.shipper_reference}</b></p></td>
                                                    <td><p>{props?.shipmentData?.modeOfTransport === "Air" ? "Air WAYBILL NO." : props?.shipmentData?.modeOfTransport === "Sea" ? "Sea WAYBILL NO." : "Courier No."} <b>{props?.shipmentData?.bill_no}</b> </p></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        <p>
                                                            {props?.shipmentData?.modeOfTransport === "Air" ? "Airway Bill / House Airway Bill:" :
                                                                props?.shipmentData?.modeOfTransport === "Sea" ? "Seaway Bill / House Bill of Lading:" : "Courier No."}
                                                            <b>{props?.shipmentData?.ref_no}</b>
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p className='text-uppercase'>CONSIGNEE<br />
                                            <b>{props?.shipmentData?.consignee_address},
                                                {props?.shipmentData?.consignee_city} ,{" "}
                                                {props?.shipmentData?.consignee_zipcode}, {" "}
                                                {/* {props?.shipmentData?.consignee_state} <br /> */}
                                                {props?.shipmentData?.consignee_country}</b></p>
                                        <p></p>
                                    </td>
                                    <td>
                                        <p>CONTACT DETAILS FOR CARGO RELEASE </p>
                                        <p className='text-uppercase'><b>{props?.shipmentData?.cargo_address},{" "}
                                            {props?.shipmentData?.cargo_city},{" "}
                                            {props?.shipmentData?.cargo_zipcode}</b></p>
                                        <p className='text-uppercase'><b>{props?.shipmentData?.cargo_state},{" "}
                                            {props?.shipmentData?.cargo_country}</b></p>
                                        <p className='contact-in-pdf'>
                                            <div>Tel : <b>{props?.shipmentData?.cargo_phone}</b></div>
                                            <div>Fax : <b>{props?.shipmentData?.cargo_fax}</b></div>
                                            <div>Contact : <b>{props?.shipmentData?.cargo_city} Office</b></div>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>NOTIFY PARTY </p>
                                        <p className='text-uppercase'><b>{props?.shipmentData?.notify_company}</b></p>
                                        <p className='text-uppercase'><b>{props?.shipmentData?.notify_name} Ph: {props?.shipmentData?.notify_phone}</b></p>
                                        <p className='text-uppercase'>Email: <b>{props?.shipmentData?.notify_email}</b></p>
                                        <p className='text-uppercase'>Address:<b> {props?.shipmentData?.notify_address}</b></p>

                                    </td>
                                    <td>
                                        <p>DELIVER TO</p>
                                        <p className='text-uppercase'><b>{props?.shipmentData?.deliver_company}</b></p>
                                        <p className='text-uppercase'><b>{props?.shipmentData?.deliver_name}{" "} {props?.shipmentData?.deliver_email}</b></p>

                                        <p ><b>{props?.shipmentData?.deliver_phone}</b></p>
                                        <p className='text-uppercase'><b>{props?.shipmentData?.deliver_address} {" "}
                                            {props?.shipmentData?.deliver_zipcode}</b></p>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='w-half'>
                                            <p>PLACE OF RECEIPT</p>
                                            <p className='text-uppercase'><b>{props?.shipmentData?.port_of_receipt}</b> </p>
                                        </div>
                                        <div className='w-half'>
                                            <p>PORT OF LOADING</p>
                                            <p className='text-uppercase'><b >{props?.shipmentData?.port_of_loading} </b></p>
                                        </div>
                                    </td>
                                    <td>
                                        <p>COMMENTS</p>
                                        <p className='text-uppercase'><b >{props?.shipmentData?.comments} </b></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {props?.shipmentData?.vessel && <div className='w-half'>
                                            <p>
                                                {props?.shipmentData?.modeOfTransport === "Air" ? "AIRLINE" : "VESSEL"}
                                                <b></b></p>
                                            <p className='text-uppercase'><b>{props?.shipmentData?.vessel}</b></p>
                                        </div>}
                                        {props?.shipmentData?.voyage_no && <div className='w-half'>
                                            <p> {props?.shipmentData?.modeOfTransport === "Air" ? "FLIGHT NO." : "VOYAGE NO."}<b></b></p>
                                            <p className='text-uppercase'><b>{props?.shipmentData?.voyage_no}</b></p>
                                        </div>}
                                    </td>
                                    <td>
                                        {/* <div>
                                            <p>PORT OF DISCHARGE</p>
                                            <p>{props?.shipmentData?.port_of_discharge} <b>5</b></p>
                                        </div>
                                        <div>
                                            <p>PORT OF DELIVERY</p>
                                            <p>{props?.shipmentData?.place_of_delivery} <b>6</b></p>
                                        </div> */}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='w-half'>
                                            <p>PLACE OF DISCHARGE</p>
                                            <p><b>{props?.shipmentData?.port_of_discharge}</b> </p>
                                        </div>
                                        <div className='w-half'>
                                            <p>PLACE OF DELIVERY</p>
                                            <p><b>{props?.shipmentData?.place_of_delivery}</b> </p>
                                        </div>
                                    </td>
                                    <td>
                                        <p> SHIPPER DECLARED VALUE <b>{props?.shipmentData?.shipper_declared_value} </b></p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='tableFooter'>
                            <h4>PARTICULARS FURNISHED BY SHIPPER – WITHOUT RESPONSIBILITY AND WARRANTY BY CARRIER & POSTKODES </h4>
                            <div className='tableFooter-flex'>
                                <div>
                                    {props.shipmentData.modeOfTransport === "Sea" &&
                                        <p>CONTAINER NO:<b>{props?.shipmentData?.container_no}, {" "}</b>{" "} /SEAL NO.
                                            <b>{props?.shipmentData?.seal_no}, {" "}</b>{" "} NO. OF CONTAINERS OR PACKAGES:
                                            <b>{props?.shipmentData?.quantity}</b></p>}
                                    <p>MARKS AND NUMBERS: <b>{props?.shipmentData?.marks_and_numbers}</b></p>
                                    <p>DESCRIPTION OF PACKAGES AND GOODS: <b>{props?.shipmentData?.description}</b></p>
                                </div>
                                <div>
                                    <p>GROSS WEIGHT(KGS):<b>{props?.shipmentData?.gross_weight}</b></p>
                                    <p>MEASUREMENT(CBM):<b>{props?.shipmentData?.cbm}</b></p>
                                </div>
                            </div>
                        </div>

                        {(props?.shipmentData?.shipped_on_board_title || props?.shipmentData?.shipped_on_board_date) && <h5 className='text-uppercase'>SHIPPED ON BOARD {props?.shipmentData?.shipped_on_board_title} {dateFormat(props?.shipmentData?.shipped_on_board_date)}  </h5>}
                        <hr />
                        <table className="print-pdf">
                            <tbody>
                                <tr>
                                    <td className='p-0 align-baseline'>
                                        <table className="print-pdf table-noborder">
                                            <tbody>
                                                <tr>
                                                    <td className='border-0'><p>FREIGHT AND CHARGES</p> </td>
                                                    <td className='border-top-0'><p>PREPAID</p></td>
                                                    <td className='border-0'><p>COLLECT</p></td>
                                                </tr>
                                                <tr>
                                                    <td className='border-left-0'><p><b>{props?.shipmentData?.freight_and_charges}</b></p></td>
                                                    <td><p><b>{props?.shipmentData?.prepaid}</b></p></td>
                                                    <td className='border-right-0'><p><b>{props?.shipmentData?.collect}</b></p></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td>
                                        <p><b>Received by the Carrier from the Shipper in external apparent good order and
                                            condition, unless otherwise indicated, the total number or quantity of Containers
                                            or other packages or other customary freight units identified as "Total Number of
                                            Container received by the Carrier" on the face hereof for Carriage subject to all
                                            the terms and conditions hereof (INCLUDING THE TERMS AND CONDITIONS
                                            OF POSTKODES AND THE TERMS AND CONDITIONS OF THE
                                            CARRIER’S APPLICABLE TARIFF) from Place of Receipt or the Port of
                                            Loading, whichever is applicable, to Place of Delivery or Port of Discharge,
                                            whichever is applicable. Weights, measurements, marks, numbers, quantity,
                                            contents and value if mentioned herein are to be considered unknown by the Carrier.</b></p>
                                        <div>
                                            <div className='mt-2'>
                                                <p>PLACE  AND DATE  OF ISSUE<br />
                                                    <b>{props?.shipmentData?.place_of_issue}</b></p>
                                                <p>{dateFormat(props?.shipmentData?.date_of_issue)}</p>
                                                <p>Signed for POSTKODES INTERNATIONAL<br />
                                                    By <b>{props?.shipmentData?.ad_code}</b>,
                                                    <div className="row my-2">

                                                        <div className="col-10">
                                                            <Barcode width={2} height={40} value={props?.shipmentData?.ad_code} fontSize={10} />
                                                        </div>

                                                        <div className="col-1 ">
                                                            <QRCode size={50} value={props?.shipmentData?.ref_no} />
                                                        </div>

                                                    </div>

                                                </p>
                                            </div>
                                            <div>

                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </CardBody>
            </Card >
        </div >
    </div >
));

const PrintComponentWrapper = (props) => {
    const [modal, setModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [printTriggered, setPrintTriggered] = useState(false);
    const componentRef = useRef();



    const toggleModal = () => setModal(!modal);
    const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onBeforeGetContent: () => {
            setPrintTriggered(true);
        },
        onAfterPrint: () => {
            if (printTriggered) {
                toast.success("Document printed successfully!");
                setPrintTriggered(false);
                setModal(false);
            }
        },
        onPrintError: () => {
            if (printTriggered) {
                toast.error("An error occurred while printing the document.");
                setPrintTriggered(false);
                setModal(false);
            }
        },
    });

    const handleDropdownSelect = (option) => {
        setSelectedOption(option);
        toggleDropdown();
        toggleModal();
    };

    const handleCancel = () => {
        setPrintTriggered(false);
        setModal(false);
    };

    return (
        <div className='print-btn'>
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle caret>
                    Print
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => handleDropdownSelect('DRAFT')}>Draft</DropdownItem>
                    <DropdownItem onClick={() => handleDropdownSelect('ORIGINAL & NON NEGOTIABLE')}>Finalized</DropdownItem>
                </DropdownMenu>
            </Dropdown>

            <Modal isOpen={modal} toggle={handleCancel} size="xl">
                <ModalHeader toggle={handleCancel}>
                    Preview
                </ModalHeader>
                <ModalBody>
                    <PrintShippingBill ref={componentRef} selectedOption={selectedOption} shipmentData={props?.shipmentData} />
                </ModalBody>
                <div className="d-flex justify-content-end m-3">
                    <Button color="primary" onClick={handlePrint}>Print</Button>

                </div>
            </Modal>
        </div>
    );
};


export default PrintComponentWrapper;
