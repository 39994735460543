import React, { Component } from 'react'
import { Card, CardBody, CardHeader, FormFeedback, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { getLocation, trim, makeid, phoneNoVerifyCheck } from '../../utils/utils';
import { uploadCSV, checkScreenAccess, requestAccess, shipmentFilterResults } from '../../api/Form';
import close from '../../assets/close.svg'
import { toast } from 'react-toastify';
import { ClipLoader } from "react-spinners";
import XLSX from 'xlsx';
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTableExtensions from 'react-data-table-component-extensions';
import moment from 'moment';
import { callActivityApi } from '../../utils/saveUserActivity';
const categoryOption = [
  { label: 'Created', value: 'created' },
  { label: 'Pickup', value: 'Pickup' },
  { label: 'Transfer', value: 'Transfer' },
  { label: 'Customs', value: 'Customs' },
  { label: 'Delivered', value: 'Delivered' },
  { label: 'Received', value: 'Received' },
  { label: 'Despatched', value: 'Despatched' },
  { label: 'Sorting Facility', value: 'Sorting Facility' },
  { label: 'Onboard to Delivery Address', value: 'Onboard to Delivery Address' },
  { label: 'Held at Customs', value: 'Held at Customs' },
  { label: 'Custom Released', value: 'Custom Released' },
  { label: 'Delivery Missed', value: 'Delivery Missed' },
  { label: 'Delivery Exception', value: 'Delivery Exception' },
  { label: 'Intransit', value: 'Intransit' },
  { label: 'Custom Cleared', value: 'Custom Cleared' },
  { label: 'Airport Transfer', value: 'Airport Transfer' },
  { label: 'Destination Airport', value: 'Destination Airport' },
  { label: 'Onforwarded', value: 'Onforwarded' },
  { label: 'Arrived', value: 'Arrived' },
  { label: 'Departed', value: 'Departed' },
  { label: 'Custom Inspection', value: 'Custom Inspection' },
  { label: 'Collect', value: 'Collect' },
  { label: 'Collected', value: 'Collected' },
  { label: 'Accepted', value: 'Accepted' },
  { label: 'Warehouse Received', value: 'Warehouse Received' },
  { label: 'Outbound Staged', value: 'Outbound Staged' },
  { label: 'Biosecurity Inspection', value: 'Biosecurity Inspection' },
  { label: 'Cancelled', value: 'Cancelled' },
  { label: 'Withdrawn', value: 'Withdrawn' },
  { label: 'Airline Transfer', value: 'Airline Transfer' },
  { label: 'Damaged', value: 'Damaged' },
  { label: 'Lost', value: 'Lost' },
  { label: 'Missing', value: 'Missing' },
  { label: 'Abandoned', value: 'Abandoned' },
  { label: 'Unclaimed', value: 'Unclaimed' },
  { label: 'Biosecurity Destruction', value: 'Biosecurity Destruction' },
  { label: 'Destruction', value: 'Destruction' },
  { label: 'Partial Disposal', value: 'Partial Disposal' },
  { label: 'Biosecurity Control', value: 'Biosecurity Control' },
  { label: 'Re-Export', value: 'Re-Export' },
  { label: 'Tran-Shipment', value: 'Tran-Shipment' },
];
export default class DownloadHistory extends Component {
  input = React.createRef();
  state = {
    userId: '',
    deviceADcode: '',
    nfcADcode: '',
    nfcserialno: '',
    document: null,
    loading: false,
    data: [],
    showData: [],
    columns: [],
    location: 'system_generated',
    showTable: true,
    requestAccess: true,
    startDate: '',
    endDate: '',
    trackingstatus: ''
  }

  async componentDidMount() {
    callActivityApi({ 'pageName': 'Download History', 'description': 'User has visited Downnload history Screen' });
    const historyPush = this.props.history.push
    phoneNoVerifyCheck(historyPush)
    const data = await localStorage.getItem('auth');
    const { id, ADCode } = JSON.parse(data);
    this.setState({ userId: id, deviceADcode: ADCode });
    var userType = await localStorage.getItem('accounttypeUsers');
    if (userType !== 'Admin') {
      checkScreenAccess(
        {
          'userId': id,
          'screenType': 'Download History'
        }
      ).then((response) => {
        this.setState({ loading: false, requestAccess: true });
      }).catch(err => {
        this.setState({ loading: false, requestAccess: false });
      });
    } else {
      this.setState({ requestAccess: true, loading: false })
    }

    let columns = [
      {
        name: 'Tracking Number',
        selector: 'refno',
        sortable: true,
      },
      {
        name: 'Quantity',
        selector: 'quantity',
        sortable: true,
      },
      {
        name: 'Delivery Instruction',
        selector: 'comment',
        sortable: true,
      },
      {
        name: 'Tracking Status',
        selector: 'trackingstatus',
        sortable: true,
      },
      {
        name: 'Origin',
        selector: 'origin',
        sortable: true,
      },
      {
        name: 'Destination',
        selector: 'destination',
        sortable: true,
      },
      {
        name: 'Type',
        selector: 'type',
        sortable: true,
      },
      {
        name: 'Delivery Address',
        selector: 'deliveryAddress',
        sortable: true,
      },
      {
        name: 'Created At',
        selector: 'created_at',
        sortable: true,
      },
      {
        name: 'Pickup',
        selector: 'pickup',
        sortable: true,
      },
      {
        name: 'Carrier Name',
        selector: 'carrierName',
        sortable: true,
      },
      {
        name: 'Receiver Name',
        selector: 'receiverName',
        sortable: true,
      },
      {
        name: 'Weight',
        selector: 'weight',
        sortable: true,
      },
      {
        name: 'Height',
        selector: 'height',
        sortable: true,
      },
      {
        name: 'Width',
        selector: 'width',
        sortable: true,
      },
      {
        name: 'Length',
        selector: 'length',
        sortable: true,
      },
      {
        name: 'Package Type',
        selector: 'packageType',
        sortable: true,
      },
      {
        name: 'Receiver Business Name',
        selector: 'receiverBusinessName',
        sortable: true,
      },
      {
        name: 'Receiver Email',
        selector: 'receiverEmail',
        sortable: true,
      },
      {
        name: 'Customer Ref no',
        selector: 'customerRefNo',
        sortable: true,
      },
      {
        name: 'Package Currency',
        selector: 'packageCurrency',
        sortable: true,
      },
      {
        name: 'Package Value',
        selector: 'packageValue',
        sortable: true,
      },
      {
        name: 'Reciever Phone',
        selector: 'receiverPhone',
        sortable: true,
      },
      {
        name: 'Shipment Type',
        selector: 'shipmentType',
        sortable: true,
      },
      {
        name: 'Origin Country',
        selector: 'originCountry',
        sortable: true,
      },
      {
        name: 'Destination Country',
        selector: 'destinationCountry',
        sortable: true,
      },
      {
        name: 'Shipment Description',
        selector: 'shipmentDescription',
        sortable: true,
      },
      {
        name: 'Phone Number1',
        selector: 'phoneNumber1',
        sortable: true,
      },
      {
        name: 'EmailAddress1',
        selector: 'emailAddress1',
        sortable: true,
      },
      {
        name: 'Shipper Name',
        selector: 'shipperName1',
        sortable: true,
      },
      {
        name: 'Shipper Phone',
        selector: 'shipperPhone1',
        sortable: true,
      },
      {
        name: 'Shipper Email',
        selector: 'shipperEmail1',
        sortable: true,
      },
      {
        name: 'Shipper Address',
        selector: 'shipperAddress1',
        sortable: true,
      }
    ];

    // call api
    shipmentFilterResults(
      {
        'deviceADcode': this.state.deviceADcode,
        'mode': 'self',
      }
    ).then((response) => {
      console.log('responseData', response);
      this.setState({ loading: false, showData: response.data.data });
    }).catch(err => {
      this.setState({ loading: false });
    });

    this.setState({ columns });
  }

  nfcADcodeHandler = () => {
    this.setState({ modal: !this.state.modal })
  }





  printErrors = (obj) => {
    var string = '';
    obj.map((val) => {
      for (var k in val) {
        if (val[k] instanceof Object) {
          this.printErrors(val[k]);
        } else {
          console.log(val[k] + "<br>");
          if (string) {
            string += ', ' + val[k];
          } else {
            string += val[k];
          }

        };
      }
    })
    return string;
  };
  callApi = () => {
    this.setState({ loading: true });
    console.log(this.state.data);
    console.log({
      'shipments': this.state.data,
      'userId': this.state.userId,
      'deviceADcode': this.state.deviceADcode,
    })
    uploadCSV({
      'shipments': this.state.data,
      'userId': this.state.userId,
      'deviceADcode': this.state.deviceADcode,
    }).then(response => {
      this.setState({ loading: false, showTable: true, showData: response.data.data });
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      //this.props.history.push('/admin/index')
    })
      .catch(err => {
        let message = err.message;
        if (err.response && err.response.data.message) {
          if (err.response && err.response.data.data && err.response.data.data.length !== 0) {
            const label = this.printErrors(err.response.data.data);
            message = label;
          } else {
            message = err.response.data.message;
          }
        }
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT
        });
        this.setState({ loading: false });
      })
  }

  // searchData

  searchData = () => {
    this.setState({ loading: true });
    // call api
    if (this.state.startDate && this.state.endDate) {
      if (new Date(this.state.endDate) >= new Date(this.state.startDate)) {
        shipmentFilterResults(
          {
            'deviceADcode': this.state.deviceADcode,
            'mode': 'self',
            'startDate': moment(this.state.startDate).format('YYYY-MM-DD') + " 00:00:00",
            'endDate': moment(this.state.endDate).format('YYYY-MM-DD') + " 23:59:00",
            'trackingStatus': this.state.trackingstatus
          }
        ).then((response) => {
          this.setState({ loading: false, showData: response.data.data });
        }).catch(err => {
          this.setState({ loading: false, showData: [] });
        });
      } else {
        this.setState({ loading: false })
        toast.error('End Date must be greater than Start Date', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    } else {
      this.setState({ loading: false });
      var msg;
      if (!this.state.startDate && !this.state.endDate) {
        msg = 'Start Date and End Date are required!!';
      } else if (!this.state.startDate) {
        msg = 'Start Date is required!!';
      } else {
        msg = 'End Date is required!!';
      }
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT
      });
    }

  };
  sendRequestAccess = async () => {
    this.setState({ loading: true });
    const info = await localStorage.getItem('auth');
    const sendInfo = JSON.parse(info);
    requestAccess({
      'username': sendInfo.username,
      'firstName': sendInfo.firstName,
      'lastName': sendInfo.lastName,
      'accounttypeUsers': sendInfo.accounttypeUsers,
      'businessname': sendInfo.businessname,
      'ADCode': sendInfo.ADCode,
      'address': sendInfo.address,
      'email': sendInfo.email,
      'phoneNumber': sendInfo.phonenumber,
      'userId': sendInfo.id,
      'screenType': 'Download History',
    }).then(Response => {
      this.setState({ loading: false });
      toast.success(Response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }).catch(err => {
      this.setState({ loading: false });
      let message = err.message;
      if (err.response && err.response.data.message) {
        message = err.response.data.message;
      }
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })
  };

  render() {
    const { loading, data, columns, showTable, requestAccess, showData } = this.state;

    const tableData = {
      columns,
      exportHeaders: true,
      data: showData,
    };
    return (
      <div>
        {!requestAccess ? <div className="justify-content-center pt-2 main-contain-title">
          <>
            <p>This feature is restricted.</p>
            <Button
              onClick={() => this.sendRequestAccess()}
              disabled={this.state.loading}>Request Access for free</Button>
          </>
        </div> :
          <>
            <div className="d-flex justify-content-center scan-parcel-loader">
              <ClipLoader
                size={50}
                color={"#123abc"}
                loading={loading}
              />
            </div>
            {!loading ?
              <Card>
                <CardHeader className="c-header">Download Historical Created Shipments</CardHeader>

                <div className='row mt-4 col-12'>
                  <div className='col-4'>
                    <FormGroup>
                      <DatePicker
                        // {...field}
                        // {...props}
                        required
                        placeholderText='Select Start Date'
                        maxDate={moment().toDate()}
                        selected={(this.state.startDate && new Date(this.state.startDate)) || null}
                        onChange={val => {
                          this.setState({ startDate: val })
                        }}
                      />
                    </FormGroup>
                  </div>
                  <div className='col-4'>
                    <FormGroup>
                      <DatePicker
                        // {...field}
                        // {...props}
                        required
                        placeholderText='Select End Date'
                        maxDate={moment().toDate()}
                        selected={(this.state.endDate && new Date(this.state.endDate)) || null}
                        onChange={val => {
                          this.setState({ endDate: val })
                        }}
                      />
                    </FormGroup>
                  </div>
                  <div className='col-4'>
                    <FormGroup>
                      <Select
                        placeholderText="Select Status"
                        name="trackingstatus"
                        onChange={(option) => this.setState({ trackingstatus: option.value })}
                        className="is-invalid"
                        value={categoryOption.filter(option => this.state.trackingstatus === option.value)}
                        options={categoryOption}
                        placeholder={<div>Select Status</div>}
                      />
                    </FormGroup>
                  </div>
                  <div className='col-4'>
                    <button className="btn btn-submit" onClick={() => { this.searchData() }} >Search</button>
                  </div>
                </div>
                {showTable ?
                  <DataTableExtensions  {...tableData}>
                    <DataTable
                      className="dataTables_wrapper"
                      pagination
                      highlightOnHover
                      columns={columns}
                      export={true}
                      responsive={true}
                      data={data}
                    />
                  </DataTableExtensions> : null}
              </Card>
              : null}
          </>
        }

      </div>
    )
  }
}
