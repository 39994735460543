import React, { Component } from 'react'
import { CardHeader, CardBody, Label, Card } from 'reactstrap';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { customTracking, getTrackingNimber, saveTrackingNimber } from '../../api/Form';
import moment from 'moment';
import { ClipLoader } from "react-spinners";
import { toast } from 'react-toastify';
import ProgressBar from '../TrackShipments/components/ProgressBar';
import { phoneNoVerifyCheck, progreeOfTrcking } from '../../utils/utils';


export default class TrackShipmentHomePage extends Component {
    state = {
        trackDetails: [],
        loading: true,
        trackDetailsModal: false,
        showTabs: [],
        completed: 0,
        trackMore: false,
        courierName: "",
        refTrackNumber: "",
        checkRefNumber: "",
        etaShipment: "",
        completedData: '',
        internalData: "",
        deviceAdCode: "",
        userId: "",
        savedList: [],
    }

    async componentDidMount() {
        const deviceAdCode = await localStorage.getItem('adCodes');
        this.setState({ deviceAdCode: deviceAdCode })
        const userId = await localStorage.getItem('userId');
        this.setState({ userId: userId })
        this.getTrackNumber()
        const historyPush = this.props.history.push
        phoneNoVerifyCheck(historyPush)
        const refno = this.props.location.state;
        this.setState({ refTrackNumber: refno })

        customTracking({ deviceAdCode: this.state.deviceAdCode, refno: this.props.location.state, tripSerialId: this.props.location.state })
            .then(Response => {
                this.setState({ deviceAdCode: this.state.deviceAdCode, trackDetails: Response.data.data, internalData: Response.data.internal_data, etaShipment: Response.data.eta, loading: false, trackDetailsModal: true })
                const { completedData, classNameData } = this.props;

                const newState = progreeOfTrcking(completedData, classNameData, Response);
                this.setState(newState);
            })
            .catch(err => {
                if (err.response?.status === 400) {
                    this.setState({ trackMore: true, trackShipmentModal: false, loading: false })
                }
                toast.error(err?.response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            })

    }

    getTrackNumber = () => {
        getTrackingNimber({ user_id: this.state.userId })
            .then(Response => {
                this.setState({ savedList: Response.data.data })
            })
            .catch(err => {
                console.log(err)
            })
    }

    handleChangeCourier = (e) => {
        this.setState({ courierName: e.value })
    }

    trackingMore = (value) => {
        this.setState({ checkRefNumber: value })
    }

    saveTrackingNimberApi = async () => {
        await saveTrackingNimber({
            user_id: this.state.userId,
            device_ad_code: this.state.deviceAdCode,
            ref_no: this.props.location.state
        }).then((response) => {
            this.setState({ loading: false, heartSave: true })
            toast.success('Tracking number saved successfully!!', {
                position: toast.POSITION.TOP_RIGHT
            })
        }).catch((err) => {
            this.setState({ loading: false, heartSave: false })
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            })
        })
    }

    render() {
        const { trackDetails } = this.state
        return (
            <div className="mt-2 style">
                <Card>
                    <CardHeader className="c-header">Track Shipments</CardHeader>
                    <CardBody>

                        {this.state.loading === true ?
                            <div className="d-flex justify-content-center">
                                < ClipLoader
                                    size={15}
                                    color={"#123abc"}
                                    loading={true}
                                />
                            </div>
                            :
                            <>
                                {this.state.showTabs.length > 0 &&
                                    <>
                                        <div className="d-flex justify-content-center track-card">
                                            <Card className="dashboard-card-style track-card-style shadow mb-4">
                                                <CardBody>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <h1 className="title-main-head-track">Master Airway Number</h1>
                                                                </div>
                                                                <div className="col-6">
                                                                    <h1 className="title-description-profile-track">{this.state.showTabs[0].masterairwayno}</h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>

                                        </div>
                                        <div className="scroll">
                                            {this.state.showTabs.map(a => {
                                                return (

                                                    <button className="btn btn-submit mt-3 mr-3" onClick={() => this.goToTracking(a.trackingno)}>{a.trackingno}</button>

                                                )
                                            })}
                                        </div>
                                    </>
                                }
                                {this.state.trackDetailsModal &&
                                    <>
                                        <div className="d-flex justify-content-center track-card">
                                            <Card className="dashboard-card-style track-card-style shadow mb-4">
                                                <CardBody>
                                                    <div className='d-flex justify-content-end'>
                                                        {
                                                            this.state.heartSave === true || this.state.internalData.is_trackingno_saved === true ?
                                                                <i class="fa fa-heart" style={{ fontSize: "28px", color: "red" }} role="button" ></i> :
                                                                <i class="fa fa-heart-o" style={{ fontSize: '28px' }} role="button" onClick={this.saveTrackingNimberApi} id='save-temp'></i>
                                                        }
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <h1 className="title-main-head-track">Ref No</h1>
                                                                </div>
                                                                <div className="col-6">
                                                                    <h1 className="title-description-profile-track">{this.state?.internalData?.refno ? this.state?.internalData?.refno : this.state.refTrackNumber}</h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <h1 className="title-main-head-track">Trip Serial ID</h1>
                                                                </div>
                                                                <div className="col-6">
                                                                    <h1 className="title-description-profile-track">{this.state?.internalData?.nfcserialno}</h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <h1 className="title-main-head-track">Quantity</h1>
                                                                </div>
                                                                <div className="col-6">
                                                                    <h1 className="title-description-profile-track">{this.state?.internalData?.quantity}</h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <h1 className="title-main-head-track">MOT</h1>
                                                                </div>
                                                                <div className="col-6">
                                                                    <h1 className="title-description-profile-track">{this.state?.internalData?.mot}</h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <h1 className="title-main-head-track">Origin</h1>
                                                                </div>
                                                                <div className="col-6">
                                                                    <h1 className="title-description-profile-track">{this.state?.internalData?.origin}</h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <h1 className="title-main-head-track">Destination</h1>
                                                                </div>
                                                                <div className="col-6">
                                                                    <h1 className="title-description-profile-track">{this.state?.internalData?.destination}</h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    </>
                                }
                                <>
                                    {
                                        this.state.trackDetailsModal &&

                                        <>
                                            {
                                                trackDetails.length > 0 &&
                                                <div className="timeline-progress">
                                                    <ProgressBar completed={this.state.completed} label={trackDetails} completedData={this.state.completedData} classNameData={this.state.classNameData} />

                                                </div>
                                            }
                                            {
                                                this.state.etaShipment &&
                                                <div className='eta-progress pb-3'>
                                                    <Label className="pr-3">Estimated time of arrival : </Label>
                                                    <Label for="exampleEmail" className="label-description">{this.state.etaShipment}</Label>
                                                </div>
                                            }
                                        </>
                                    }
                                </>
                                {
                                    trackDetails.length > 0 &&
                                    <>
                                        {this.state?.trackDetailsModal ?
                                            <VerticalTimeline layout='1-column' className="overflow-auto pb-0 vt-style" >
                                                {trackDetails.length > 0 && trackDetails.map(a => {
                                                    return (
                                                        <VerticalTimelineElement
                                                            className="vertical-timeline-element--work "
                                                            date={
                                                                <div>
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <span className="pl-2 vertical-time-style" style={{ color: '#0000a0' }}>{a.date ? moment(a.date).format('DD-MM-yy') : moment(a.new_date_format).format('DD-MM-yy')}</span>
                                                                        </div>
                                                                        <div className="col-12 ">
                                                                            <span className="pl-2 vertical-time-style" style={{ color: '#0000a0' }}>{a.date ? moment(a.date).format("h:mm A") : moment(a.new_date_format).format("h:mm A")}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            }
                                                            iconStyle={{ background: '#fff', color: '#0000a0' }}
                                                        >
                                                            <Card className="shadow border-0">
                                                                <CardBody>
                                                                    <table className="track-table">
                                                                        <tr>
                                                                            <td>
                                                                                <Label for="exampleEmail " className="pr-5 label-style-tracking">Status</Label>
                                                                            </td>
                                                                            <td>
                                                                                <span class="badge badge-pill badge-primary px-2 font-weight-light ">{a?.trackingstatus ? a?.trackingstatus : a?.status ? a?.status : a?.track_status}</span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <Label for="exampleEmail " className="pr-5 label-style-tracking">Quantity</Label>
                                                                            </td>
                                                                            <td>
                                                                                <Label for="exampleEmail" className="label-description">{a.quantity ? a.quantity : a.track_quantity}</Label>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <Label for="exampleEmail " className="pr-5 label-style-tracking">Comment</Label>
                                                                            </td>
                                                                            <td>
                                                                                <Label for="exampleEmail" className="label-description">{a.comment ? a.comment : a.track_comment}</Label>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <Label for="exampleEmail " className="pr-5 label-style-tracking">Location</Label>
                                                                            </td>
                                                                            <td>
                                                                                <Label for="exampleEmail" className="label-description">{a.location ? a.location : a.track_location}</Label>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </CardBody>
                                                            </Card>
                                                        </VerticalTimelineElement>
                                                    );
                                                })}
                                            </VerticalTimeline> :
                                            null}
                                    </>
                                }
                            </>}
                    </CardBody>
                </Card>
            </div>
        )
    }
}
