import React, { Component } from "react";
import { calculateCBM, calculateCVW } from "../utils/businessLogic";
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

export default class ShippingCalculator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cbmForm: true,
            vwForm: false,
            modeOfTransport: '',
            quantity: '',
            quantityCBM: '',
            weight: '',
            length: '',
            lengthCBM: '',
            width: '',
            widthCBM: '',
            height: '',
            heightCBM: '',
            cbm: 0,
            cvw: 0,
            isAdminLayout: false
        };
    }
    componentDidMount() {
        // Check if the URL path contains "admin"
        if (window.location.pathname.includes('admin')) {
            this.setState({ isAdminLayout: true });
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const { modeOfTransport, length, width, height, quantity, weight, quantityCBM, lengthCBM, widthCBM, heightCBM, cbmForm, vwForm } = this.state;

        // Only proceed with calculation if all required fields are filled
        if (vwForm && modeOfTransport && quantity && weight && length && width && height) {
            const cvw = calculateCVW(modeOfTransport, Number(length), Number(width), Number(height), Number(quantity), Number(weight));
            this.setState({ cvw });
        }
        else if (cbmForm && quantityCBM && lengthCBM && widthCBM && heightCBM) {
            const cbm = calculateCBM(Number(lengthCBM), Number(widthCBM), Number(heightCBM), Number(quantityCBM));
            this.setState({ cbm });
        }
        else {
            alert("Please fill out all required fields.");
        }
    };

    handleReset = () => {
        if (this.state.cbmForm) {
            this.setState({
                quantityCBM: '',
                lengthCBM: '',
                widthCBM: '',
                heightCBM: '',
                cbm: 0,
            });
        }
        else {
            this.setState({
                modeOfTransport: '',
                quantity: '',
                weight: '',
                length: '',
                width: '',
                height: '',
                cvw: 0
            });
        }

    };


    render() {
        const { modeOfTransport, quantity, weight, length, width, height, quantityCBM, lengthCBM, widthCBM, heightCBM, cbm, cvw } = this.state;

        return (
            <>
                <Helmet>
                    <title>Courier Charges - Shipping Rates Calculator - Postkodes</title>
                    <meta name="description" content="Estimate delivery costs for eCommerce parcels with our tool. Get accurate professional courier charges per KG for shipping items to your customers." />
                </Helmet>
                {!this.state.isAdminLayout &&
                    <Link class="btn btn-back" to="/"><i class="fa fa-long-arrow-left pr-3 ml-4 mt-4"> </i> Go Back</Link>}
                <div className="container mt-4">
                    <h1>Shipping Calculator</h1>
                    <div className="d-flex justify-content-left flex-wrap mt-4 mb-2">
                        <button onClick={() => this.setState({ cbmForm: true, vwForm: false })} type="button" className={`btn col-12 col-md-6 mb-6 border ${this.state.cbmForm ? 'bg-primary text-white' : 'bg-light'}`}>CBM</button>
                        <button onClick={() => this.setState({ vwForm: true, cbmForm: false })} type="button" className={`btn col-12 col-md-6 mb-6 border ${this.state.vwForm ? 'bg-primary text-white' : 'bg-light'}`}>Volumetric Weight</button>
                    </div>
                    {this.state.vwForm &&
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="modeOfTransport">Mode of Transport (MOT)</label>
                                <select
                                    className="form-control"
                                    id="modeOfTransport"
                                    value={modeOfTransport}
                                    onChange={this.handleChange}
                                    required
                                >
                                    <option value="" disabled>Select...</option>
                                    <option value="Air">Air</option>
                                    <option value="Sea">Sea</option>
                                    <option value="Road">Road</option>
                                    <option value="Rail">Rail</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="quantity">Quantity of box items</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="quantity"
                                    value={quantity}
                                    onChange={this.handleChange}
                                    placeholder="Enter quantity of box items"
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="weight">Total Weight (kg)</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="weight"
                                    value={weight}
                                    onChange={this.handleChange}
                                    placeholder="Enter weight"
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label>Dimension (cm)</label>
                                <div className="form-row">
                                    <div className="col-lg-4 col-md-4 col-sm-12 mb-2">
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="length"
                                            value={length}
                                            onChange={this.handleChange}
                                            placeholder="Enter Length of Dimension"
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 mb-2">
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="width"
                                            value={width}
                                            onChange={this.handleChange}
                                            placeholder="Enter Width of Dimension"
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 mb-2">
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="height"
                                            value={height}
                                            onChange={this.handleChange}
                                            placeholder="Enter Height of Dimension"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-3 col-md-4 col-sm-4 mb-2 text-center border p-1">
                                <span className='custom-span-cbm'>
                                    Volumetric Weight = {cvw}
                                </span>
                            </div>


                            <div className="d-flex justify-content-left flex-wrap">
                                <button type="submit" className="btn btn-primary col-12 col-md-2 mb-2">Calculate</button>
                                <button type="button" className="btn btn-danger col-12 col-md-2 mb-2 ml-md-2 ml-lg-3" onClick={this.handleReset}>Reset</button>
                            </div>

                        </form>
                    }
                    {this.state.cbmForm &&
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="quantityCBM">Quantity of box items</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="quantityCBM"
                                    value={quantityCBM}
                                    onChange={this.handleChange}
                                    placeholder="Enter quantity of box items"
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label>Dimension (cm)</label>
                                <div className="form-row">
                                    <div className="col-lg-4 col-md-4 col-sm-12 mb-2">
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="lengthCBM"
                                            value={lengthCBM}
                                            onChange={this.handleChange}
                                            placeholder="Enter Length of Dimension"
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 mb-2">
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="widthCBM"
                                            value={widthCBM}
                                            onChange={this.handleChange}
                                            placeholder="Enter Width of Dimension"
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 mb-2">
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="heightCBM"
                                            value={heightCBM}
                                            onChange={this.handleChange}
                                            placeholder="Enter Height of Dimension"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group d-flex justify-content-start">
                                <div className="col-lg-2 col-md-2 col-sm-3 mb-2 text-center border p-1">
                                    <span className='custom-span-cbm'>
                                        CBM = {cbm}
                                    </span>
                                </div>
                            </div>

                            <div className="d-flex justify-content-left flex-wrap">
                                <button type="submit" className="btn btn-primary col-12 col-md-2 mb-2">Calculate</button>
                                <button type="button" className="btn btn-danger col-12 col-md-2 mb-2 ml-md-2 ml-lg-3" onClick={this.handleReset}>Reset</button>
                            </div>

                        </form>
                    }
                </div>
            </>
        );
    }
}
