import React, { Component } from 'react'
import './AboutAddressDynamic.css'
import { Link } from 'react-router-dom';
import ab1 from '../../assets/ab-1.png'
import ab2 from '../../assets/ab-2.png'
import ab3 from '../../assets/ab-3.png'
import { Helmet } from 'react-helmet';


export default class AboutAddressDynamic extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>About us - Postkodes</title>
          <meta name="description" content="Postkodes mission simplifies parcel handling for consumers & e-commerce. With IoT tech, track parcels, print labels, and manage shipments seamlessly worldwide." />
        </Helmet>
        <div className=" w-100 h-100">
          <div className="container">
            <div className="row pt-5">
              <Link className="btn btn-back" to="/"><i class="fa fa-long-arrow-left pr-3"> </i> Go Back</Link>
            </div>
            <hr />
            <div className="main-contain-style ">
              <div className="row">
                <div className="col-6">
                  <h1 className="about-title">Postkodes</h1>
                  <p className="about-description">Perfect for consumers, e-commerce businesses, and small to medium sized couriers. Postkodes is a platform designed to make parcel handling seamless. Embedded with IoT technology, Postkodes enables users to create shipment, print shipping documents & labels, scan parcels, track parcels & set-up sms & email tracking alerts for any parcel. Along with other great features, users can also exchange shipping documents, request a click & collect or courier collect by proxy (COPY) service.
                    Our universal parcel tracking solution indexes more than 1000 carriers worldwide including all major couriers and airlines.</p>
                </div>
                <div className="col-6">
                  <img src={ab1} alt="" width="500px" height="400px" />
                </div>
              </div>
              {/* <div className="row about-contain">
              <div className="col-6">
                <img src={ab2} alt="" width="500px" height="400px" />
              </div>
              <div className="col-6">
                <h1 className="about-title">AAddressDynamic ..Global Business Tool.</h1>
                <p className="about-description">Generate & send parcel delivery status notifications through SMS to your customers. Ready to send list of parcel delivery status notifications are available. Perfect for • Small couriers. • Small businesses • eCommerce businesses • Notification can be generated in 2 simple clicks • No IT or system skill required.</p>
              </div>
            </div>
            <div className="row about-contain">
              <div className="col-6">
                <h1 className="about-title">Delivery status notification examples?</h1>
                <p className="about-description">Your parcel has now been shipped estimated delivery date is xyz
                Your parcel has been custom cleared and will be delivered today.
                Your packgae is ready to be picked up
                Missed attempted delivery call us to arrange a redelivery.
                We've encountered delivery exception with your parcel
                . Your order has been received Your order has been processed and shipped tracking number is xyz and ETA is 01/01/1900
                And many more features.
                Register and click on the Global Business Tool icon to enjoy these and other features.</p>
              </div>
              <div className="col-6">
                <img src={ab3} alt="" width="500px" height="400px" />
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </>
    )
  }
}
