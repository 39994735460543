import React, { Component } from 'react'
import { CardHeader, CardBody, FormGroup, Label, Input, Card, FormFeedback } from 'reactstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Select from 'react-select';
import './SacanParcel.css'
import { scanParcel, getShipment, trackShipment, getShippingDocs } from '../../api/Form'
import { getLocation, makeid, phoneNoVerifyCheck } from '../../utils/utils';
import { toast } from 'react-toastify';
import BarcodeReader from 'react-barcode-reader';
import beep from '../../assets/audio/beep.mp3';
import { callActivityApi } from '../../utils/saveUserActivity';
import { image } from '../../api/URLS';

const Beep = new Audio(beep);

const Schema = Yup.object().shape({
  quantity: Yup.string()
    .required('Quantity is required')
    .test('checkQuantity', 'Package already received by recipient. contact support.', function (value) {
      if (!value) return null;
      return this.parent.trackingstatus !== "Delivered" || +value <= (this.parent.parcelQuantity - this.parent.delivered)
    }),
  trackingstatus: Yup.string()
    .required('Tracking Status is required'),
  refno: Yup.string()
    .required('Ref No is required')
    .test('checkRefno', 'This Ref No. is not valid.', function (value) {
      if (!value) return null;
      return new Promise((resolve, reject) => {
        getShipment({ refno: value })
          .then(Response => {
            resolve(true);
          }).catch(e => resolve(false));
      })
    }),
});

const categoryOption = [
  { label: 'Pickup', value: 'Pickup' },
  { label: 'Transfer', value: 'Transfer' },
  { label: 'Customs', value: 'Customs' },
  { label: 'Delivered', value: 'Delivered' },
  { label: 'Received', value: 'Received' },
  { label: 'Despatched', value: 'Despatched' },
  { label: 'Sorting Facility', value: 'Sorting Facility' },
  { label: 'Onboard to Delivery Address', value: 'Onboard to Delivery Address' },
  { label: 'Held at Customs', value: 'Held at Customs' },
  { label: 'Custom Released', value: 'Custom Released' },
  { label: 'Delivery Missed', value: 'Delivery Missed' },
  { label: 'Delivery Exception', value: 'Delivery Exception' },
  { label: 'Intransit', value: 'Intransit' },
  { label: 'Custom Cleared', value: 'Custom Cleared' },
  { label: 'Airport Transfer', value: 'Airport Transfer' },
  { label: 'Destination Airport', value: 'Destination Airport' },
  { label: 'Onforwarded', value: 'Onforwarded' },
  { label: 'Arrived', value: 'Arrived' },
  { label: 'Departed', value: 'Departed' },
  { label: 'Custom Inspection', value: 'Custom Inspection' },
  { label: 'Collect', value: 'Collect' },
  { label: 'Collected', value: 'Collected' },
  { label: 'Accepted', value: 'Accepted' },
  { label: 'Warehouse Received', value: 'Warehouse Received' },
  { label: 'Outbound Staged', value: 'Outbound Staged' },
  { label: 'Biosecurity Inspection', value: 'Biosecurity Inspection' },
  { label: 'Cancelled', value: 'Cancelled' },
  { label: 'Withdrawn', value: 'Withdrawn' },
  { label: 'Airline Transfer', value: 'Airline Transfer' },
  { label: 'Damaged', value: 'Damaged' },
  { label: 'Lost', value: 'Lost' },
  { label: 'Missing', value: 'Missing' },
  { label: 'Abandoned', value: 'Abandoned' },
  { label: 'Unclaimed', value: 'Unclaimed' },
  { label: 'Biosecurity Destruction', value: 'Biosecurity Destruction' },
  { label: 'Destruction', value: 'Destruction' },
  { label: 'Partial Disposal', value: 'Partial Disposal' },
  { label: 'Biosecurity Control', value: 'Biosecurity Control' },
  { label: 'Re-Export', value: 'Re-Export' },
  { label: 'Tran-Shipment', value: 'Tran-Shipment' },
];

export default class ScanParcel extends Component {
  state = {
    refno: '',
    nfcserialno: '',
    trackingstatus: '',
    type: '',
    comment: '',
    deviceADcode: '',
    userId: '',
    location: '',
    SR: '',
    package: '',
    data: [],
    nfcADcode: '',
    isBoxAlert: true,
    quantity: '',
    parcelQuantity: '',
    delivered: '',
    files: [],
    filesMsg: '',
    loading: false
  }

  async componentDidMount() {
    callActivityApi({ 'pageName': 'Scan Parcel', 'description': 'User has visited Scan Parcel Screen' });
    const data = this.props.history.push
    phoneNoVerifyCheck(data)
    const deviceADcode = await localStorage.getItem('adCodes');
    this.setState({ deviceADcode })
    const userId = await localStorage.getItem('userId');
    this.setState({ userId });
    getLocation().then(response => {
      this.setState({ userId, location: response });
    }).catch(e => console.log(e, 'error'))

  }

  submitHandler = (values) => {
    this.setState({
      loading: true
    })
    const data = {
      ...values, deviceADcode: this.state.deviceADcode,
      nfcADcode: this.state.nfcADcode,
      location: this.state.location,
      userId: this.state.userId,
      type: this.state.type,
      pinNumber: values.SR,
      nfcserialno: this.state.nfcserialno,
    }
    scanParcel(data)
      .then(Response => {
        toast.success(Response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        this.setState({
          loading: false
        })
        this.props.history.push('/admin/index')
      })
      .catch(err => {
        let message = err.message;
        if (err.response && err.response.data.message) {
          message = err.response.data.message;
        }
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT
        });
        this.setState({
          loading: false
        })
      })
  }
  // download files
  download = e => {
    e.preventDefault();
    var or_link = e.target.href;
    fetch(e.target.href, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          var extension = or_link.split('.').pop();
          link.setAttribute("download", "shipping-doc." + extension); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  handlerError = (error) => {
    console.log(error)
  }
  // 
  handleRefNum = async (setFieldValue, setFieldTouched, refno) => {
    const deviceADcode = await localStorage.getItem('adCodes');
    var userType = await localStorage.getItem('accounttypeUsers');

    if (refno.length > 8) {
      trackShipment({ refno, tripSerialId: refno })
        .then(response => {
          const trackings = response.data.data;
          const type = trackings[0].type;
          const parcelQuantity = +trackings[trackings.length - 1].quantity;
          const nfcserialno = +trackings[trackings.length - 1].nfcserialno;
          const delivered = trackings.filter(p => p.trackingstatus === 'Delivered').reduce((p, c) => p + +c.quantity, 0);
          this.setState({ refno, parcelQuantity, delivered, type, nfcserialno }, () => {
            setFieldValue('refno', refno)
            setFieldValue('quantity', trackings[trackings.length - 1].quantity)
            setFieldValue('nfcserialno', nfcserialno)
            setFieldTouched('refno')

          });
        })
        .catch(err => {
          // toast.error(err.response.data.message, {
          //   position: toast.POSITION.TOP_RIGHT
          // });
          this.setState({ quantity: '' }, () => {
            setFieldValue('quantity', '')
          });
        })
      getShippingDocs({ refno, tripSerialId: refno, deviceAdCode: deviceADcode, userType })
        .then(response => {
          const responseIn = response.data.data;
          this.setState({ files: responseIn.uploads, filesMsg: response.data.message });
        })
        .catch(err => {
          //console.log(err.response);
          this.setState({ files: [], filesMsg: err.response.data.message });
        })

    }
  };

  handleScan = (setFieldValue, setFieldTouched) => (refno) => {
    if (refno && this.state.refno !== refno) {
      Beep.play();
      trackShipment({ refno, tripSerialId: refno })
        .then(response => {
          const trackings = response.data.data;
          const parcelQuantity = +trackings[trackings.length - 1].quantity;
          const nfcserialno = +trackings[trackings.length - 1].nfcserialno;
          const delivered = trackings.filter(p => p.trackingstatus === 'Delivered').reduce((p, c) => p + +c.quantity, 0);
          this.setState({ refno, parcelQuantity, delivered }, () => {
            setFieldValue('refno', refno)
            setFieldValue('nfcserialno', nfcserialno)
            setFieldTouched('refno')
          });
        })
        .catch(err => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        })
    }
  }

  render() {
    const { delivered, parcelQuantity } = this.state;
    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            refno: this.state.refno,
            trackingstatus: this.state.trackingstatus,
            delivered,
            parcelQuantity,
            comment: this.state.comment,
            package: this.state.package,
            quantity: this.state.quantity,
          }}
          validationSchema={Schema}
          onSubmit={(values, { setFieldError }) => {
            this.submitHandler(values);
          }}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            setFieldError
          }) => (
            <>
              <BarcodeReader
                // testCode={'GWZ927PXS'}
                onError={this.handlerError}
                onScan={this.handleScan(setFieldValue, setFieldTouched)} />
              <div className="mt-2 style">
                <Card>
                  <CardHeader className="c-header">Scan Parcel</CardHeader>

                  <CardBody>
                    <div className="col-12">
                      <FormGroup>
                        <Label >Ref No</Label>
                        <Input type="text"
                          invalid={errors.refno && touched.refno}
                          name="refno"
                          onInput={(e) => this.handleRefNum(setFieldValue, setFieldTouched, e.target.value)}
                          onChange={(e) => {
                            handleChange(e);
                            this.handleRefNum(setFieldValue, setFieldTouched, e.target.value);
                          }}
                          onBlur={handleBlur} value={values.refno} placeholder="Enter Ref No" />
                        <FormFeedback>
                          {errors.refno && touched.refno && errors.refno}
                        </FormFeedback>
                      </FormGroup>
                    </div>

                    <div className="col-12">
                      <FormGroup>
                        <Label >Status</Label>
                        <Select
                          name="trackingstatus"
                          onChange={(option) => {
                            setFieldValue('trackingstatus', option.value);
                          }}
                          onBlur={handleBlur}
                          className="is-invalid"
                          value={categoryOption.filter(option => values.trackingstatus === option.value)}
                          options={categoryOption}
                        />
                        <FormFeedback>
                          {errors.trackingstatus && touched.trackingstatus && errors.trackingstatus}
                        </FormFeedback>
                      </FormGroup>
                    </div>

                    <div className="col-12">
                      <FormGroup>
                        <Label >Quantity of box items</Label>
                        <Input type="text"
                          disabled="false"
                          invalid={errors.quantity && touched.quantity}
                          name="quantity" onChange={(qun) => {
                            handleChange(qun)
                          }}
                          onBlur={handleBlur} value={values.quantity} placeholder="Enter Box Quantity" />
                        <FormFeedback>
                          {errors.quantity && touched.quantity && errors.quantity}
                        </FormFeedback>
                      </FormGroup>
                    </div>

                    {(values.trackingstatus === 'Delivered' || values.trackingstatus === 'Received') && this.state.type === 'SR' &&
                      <>
                        <div className="col-12">
                          <FormGroup>
                            <Label >SR pin</Label>
                            <Input type="text"
                              invalid={errors.SR && touched.SR}
                              name="SR" onChange={handleChange} onBlur={handleBlur} value={values.SR} placeholder="Enter SR" />
                            <FormFeedback>
                              {errors.SR && touched.SR && errors.SR}
                            </FormFeedback>
                          </FormGroup>
                        </div>
                        <div className="col-12">
                          <FormGroup>
                            <Label >Package</Label>
                            <Input type="text"
                              invalid={errors.package && touched.package}
                              name="package" onChange={handleChange} onBlur={handleBlur} value={values.package} placeholder="Enter package" />
                            <FormFeedback>
                              {errors.package && touched.package && errors.package}
                            </FormFeedback>
                          </FormGroup>
                        </div>
                      </>
                    }
                    {values.refno && <div className="col-12">
                      <FormGroup>
                        <Label >Shipping Documents</Label>
                        {this.state.files.length > 0 ? <div>
                          {this.state.files.map(p => {
                            return (
                              <div>
                                {p.fileName} <a href={image + p.fileLink} target="_blank" class="help-text-style" >View</a> <a href={image + p.fileLink} class="help-text-style" download="shipping-doc" onClick={e => this.download(e)}>Download</a><br />
                              </div>
                            )
                          }
                          )
                          }
                        </div> : <p className="alert-danger">{this.state.filesMsg}</p>}
                      </FormGroup>
                    </div>}

                    <div className="col-12">
                      <FormGroup>
                        <Label >Comment</Label>
                        <Input type="textarea"
                          invalid={errors.comment && touched.comment}
                          name="comment" onChange={handleChange} onBlur={handleBlur} value={values.comment} placeholder="Enter comments" />
                        <FormFeedback>
                          {errors.comment && touched.comment && errors.comment}
                        </FormFeedback>
                      </FormGroup>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button className="btn btn-submit" onClick={handleSubmit} disabled={this.state.loading}>Submit</button>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </>
          )}
        </Formik>
      </div>
    )
  }
}
