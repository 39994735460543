import React, { Component } from 'react'
import { CardHeader, CardBody, FormGroup, Label, Input, Card, FormFeedback } from 'reactstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Select from 'react-select';
import { getApiClientDetail, apiRequest } from '../../api/Form'
import { URLS } from "../../api/URLS";
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./GrantAcess.css";
import moment from 'moment';
import { phoneNoVerifyCheck } from '../../utils/utils';


export default class GetApiAccess extends Component {
  state = {
    access_token : '',
    validity: moment().add(90,'days'),
    access_routes: [],
    clientDetail: {},
    routesList: [],
    access_status: 0,
    site_url : '',
  }

  async componentDidMount() {
    const historyPush = this.props.history.push
    phoneNoVerifyCheck(historyPush)
   const userInfo = JSON.parse(localStorage.getItem('auth'));
   getApiClientDetail(userInfo.id).then(Response => {
        if(Response.data.status === true){
            this.setState({ clientDetail: Response.data.data})
        }else{
            const info = {
                name : userInfo.firstName,
                email : userInfo.email,
                access_token : '',
                validity : '',
                user_id : userInfo.id,
            }
            this.setState({clientDetail: info});
        }
    }).catch(err => {
       // console.log(err)
        const info = {
            name : userInfo.firstName,
            email : userInfo.email,
            access_token : '',
            site_url : '',
            validity : '',
            user_id : userInfo.id,
        }
        this.setState({clientDetail: info});
      }); 
  }
   generateToken() {
    var length = 25;
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

 changeStatus(){
   this.setState({ access_status : !this.state.access_status } );
 }
 
 
  submitHandler = (values) => {
     // console.log(values);
    values.user_id = this.state.clientDetail.user_id;
    values.email = this.state.clientDetail.email;
    values.name = this.state.clientDetail.name;
    values.access_routes = URLS.SHIPMENT;
    apiRequest(values)
    .then(response => {
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      window.location.reload();
    })
    .catch(err => {
      // console.log(err)
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      this.setState({loading: false});
    })
  }

  handlerError = (error) => {
    console.log(error)
  }
  

  

  render() {
    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            site_url :  this.state.clientDetail.site_url? this.state.clientDetail.site_url: this.state.site_url
          }}
          onSubmit={(values, { setFieldError }) => {
            this.submitHandler(values);
          }}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue
          }) => (
              <>
                <div className="mt-2 style">
                  <Card>
                    <CardHeader className="c-header">Request Api Access</CardHeader>

                    <CardBody>
                      <div className="col-12">
                        <FormGroup>
                          <Label >Name</Label>
                          <Input
                          disabled
                            type="text"
                            name="name"  value={this.state.clientDetail.name}  />
                        </FormGroup>
                      </div>
                      <div className="col-12">
                        <FormGroup>
                          <Label >Email</Label>
                          <Input
                          disabled
                            type="text"
                            name="name"  value={this.state.clientDetail.email}  />
                        </FormGroup>
                      </div>
                      <div className="col-12">
                        <FormGroup>
                          <Label >Site Url</Label>
                          <Input
                          disabled={this.state.clientDetail.access_token?true:false}
                            type="text"
                            name="site_url" onChange={handleChange} onBlur={handleBlur}  value={values.site_url}  />
                        </FormGroup>
                      </div>
                      {this.state.clientDetail.access_token? <div className="col-12">
                        <FormGroup>
                          <Label >API Access Token</Label>
                          <Input type="text"
                            disabled
                            invalid={errors.access_token && touched.access_token}
                            name="access_token" onChange={handleChange} onBlur={handleBlur} value={this.state.clientDetail.access_token} placeholder="Access Token" />
                        </FormGroup>
                      </div>: null}
                
                      {this.state.clientDetail.validity? <div className="col-12">
                        <FormGroup>
                          <Label >Token Valid Days</Label>
                          <Input
                            disabled
                            type="text"
                            name="validity"  value={this.state.clientDetail.validity}  />
                        </FormGroup>
                      </div>: null}
                      
                      <div className="col-12">
                        <FormGroup>
                          <Label >Route :</Label>
                          <Input
                            disabled
                            type="text"
                            name="access_routes"  value={URLS.SHIPMENT}  />
                        </FormGroup>
                      </div>
                     {this.state.clientDetail.id ?<div className="col-12">
                        <FormGroup>
                          <Label >Access Status : </Label>
                          <p>{this.state.clientDetail.access_token && this.state.clientDetail.access_status ? 'Request Granted': null}
                          {this.state.clientDetail.access_token && !this.state.clientDetail.access_status ? 'Request Declined': null}
                          {!this.state.clientDetail.access_token && !this.state.clientDetail.access_status ? 'Request in waiting': null}</p>

                        </FormGroup>
                      </div> :null}
                      {!this.state.clientDetail.id?<div className="d-flex justify-content-center">
                        <button type="submit" className="btn btn-submit" onClick={handleSubmit} >Submit</button>
                      </div>:null}
                    </CardBody>
                  </Card>
                </div>
              </>
            )}
        </Formik>
      </div>
    )
  }
}
