import React, { Component, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print';
import PrintInvoiceFile from './PrintInvoiceFile';

const tabStyle = {
  height: 800,
  maxHeight: 1000,
  overflowY: "scroll"
  //backgroundColor: "blue"
};
export default function PurchaseInvoice(props) {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div style={tabStyle}>
        <div className="justify-content-center">
            <PrintInvoiceFile ref={componentRef} format={props.invoice} />
        </div>
      <div className="d-flex justify-content-center" id="no-print" >
        <button className="btn btn-submit ml-2 mt-3" onClick={handlePrint}>Print</button>
      </div>
    </div>
  )
}
