import React, { Component, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print';
import PrintFileAdhoc from './PrintFileAdhoc';

const tabStyle = {
    height: 800,
    maxHeight: 1000,
    overflowY: "scroll"
    //backgroundColor: "blue"
};
export default function PrintInvoiceAdhoc(props) {
    const { invoice } = props
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <div style={tabStyle}>
            <div className="justify-content-center">
                <PrintFileAdhoc ref={componentRef} format={props.invoice} invoice={invoice} />
            </div>
            <div className="d-flex justify-content-center" id="no-print" >
                <button className="btn btn-submit ml-2 mt-3" onClick={handlePrint}>Print this out!</button>
                <button className="btn btn-submit ml-2 mt-3" onClick={props.reset}>Print another</button>
                {/* <button className="btn btn-submit ml-2 mt-3" onClick={props.showPopup}>Send Invoice</button> */}
            </div>
        </div>
    )
}
