import React, { Component } from 'react'
import { Card, CardBody, FormGroup, Label, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import './TrackShipments.css'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Link } from 'react-router-dom';
import { trackShipment, customTracking, trackingMoreData, saveIpAddress } from '../../api/Form';
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProgressBar from '../../Dashboard/TrackShipments/components/ProgressBar';
import Select from 'react-select';
import courierNameData from "../../components/courierData.json"
import { statusData } from '../../components/config';
import { progreeOfTrcking } from '../../utils/utils';
import { ClipLoader } from 'react-spinners';
import { Helmet } from 'react-helmet';
const options = [
	{ value: 'Afghanistan', label: 'Afghanistan' },
	{ value: 'Aland Islands', label: 'Aland Islands' },
	{ value: 'Albania', label: 'Albania' },
	{ value: 'Algeria', label: 'Algeria' },
	{ value: 'American Samoa', label: 'American Samoa' },
	{ value: 'AndorrA', label: 'AndorrA' },
	{ value: 'Angola', label: 'Angola' },
	{ value: 'Anguilla', label: 'Anguilla' },
	{ value: 'Antarctica', label: 'Antarctica' },
	{ value: 'Antigua and Barbuda', label: 'Antigua and Barbuda' },
	{ value: 'Argentina', label: 'Argentina' },
	{ value: 'Armenia', label: 'Armenia' },
	{ value: 'Aruba', label: 'Aruba' },
	{ value: 'Australia', label: 'Australia' },
	{ value: 'Austria', label: 'Australia' },
	{ value: 'Azerbaijan', label: 'Azerbaijan' },
	{ value: 'Bahamas', label: 'Bahamas' },
	{ value: 'Bahrain', label: 'Bahrain' },
	{ value: 'Bangladesh', label: 'Bangladesh' },
	{ value: 'Barbados', label: 'Barbados' },
	{ value: 'Belarus', label: 'Belarus' },
	{ value: 'Belgium', label: 'Belgium' },
	{ value: 'Belize', label: 'Belize' },
	{ value: 'Benin', label: 'Benin' },
	{ value: 'Bermuda', label: 'Bermuda' },
	{ value: 'Bhutan', label: 'Bhutan' },
	{ value: 'Bolivia', label: 'Bolivia' },
	{ value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
	{ value: 'Botswana', label: 'Botswana' },
	{ value: 'Bouvet Island', label: 'Bouvet Island' },
	{ value: 'Brazil', label: 'Brazil' },
	{ value: 'British Indian Ocean Territory', label: 'British Indian Ocean Territory' },
	{ value: 'Brunei Darussalam', label: 'Brunei Darussalam' },
	{ value: 'Bulgaria', label: 'Bulgaria' },
	{ value: 'Burkina Faso', label: 'Burkina Faso' },
	{ value: 'Burundi', label: 'Burundi' },
	{ value: 'Cambodia', label: 'Cambodia' },
	{ value: 'Cameroon', label: 'Cameroon' },
	{ value: 'Canada', label: 'Canada' },
	{ value: 'Cape Verde', label: 'Cape Verde' },
	{ value: 'Cayman Islands', label: 'Cayman Islands' },
	{ value: 'Central African Republic', label: 'Central African Republic' },
	{ value: 'Chad', label: 'Chad' },
	{ value: 'Chile', label: 'Chile' },
	{ value: 'China', label: 'China' },
	{ value: 'Christmas Island', label: 'Christmas Island' },
	{ value: 'Cocos (Keeling) Islands', label: 'Cocos (Keeling) Islands' },
	{ value: 'Colombia', label: 'Colombia' },
	{ value: 'Comoros', label: 'Comoros' },
	{ value: 'Congo', label: 'Congo' },
	{ value: 'Congo, The Democratic Republic of the', label: 'Congo, The Democratic Republic of the' },
	{ value: 'Cook Islands', label: 'Cook Islands' },
	{ value: 'Costa Rica', label: 'Costa Rica' },
	{ value: "Cote DIvoire", label: "Cote D'Ivoire" },
	{ value: 'Croatia', label: 'Croatia' },
	{ value: 'Cuba', label: 'Cuba' },
	{ value: 'Cyprus', label: 'Cyprus' },
	{ value: 'Czech Republic', label: 'Czech Republic' },
	{ value: 'Denmark', label: 'Denmark' },
	{ value: 'Djibouti', label: 'Djibouti' },
	{ value: 'Dominica', label: 'Dominica' },
	{ value: 'Dominican Republic', label: 'Dominican Republic' },
	{ value: 'Ecuador', label: 'Ecuador' },
	{ value: 'Egypt', label: 'Egypt' },
	{ value: 'El Salvador', label: 'El Salvador' },
	{ value: 'Equatorial Guinea', label: 'Equatorial Guinea' },
	{ value: 'Eritrea', label: 'Eritrea' },
	{ value: 'Estonia', label: 'Estonia' },
	{ value: 'Ethiopia', label: 'Ethiopia' },
	{ value: 'Falkland Islands (Malvinas)', label: 'Falkland Islands (Malvinas)' },
	{ value: 'Faroe Islands', label: 'Faroe Islands' },
	{ value: 'Fiji', label: 'Fiji' },
	{ value: 'Finland', label: 'Finland' },
	{ value: 'France', label: 'France' },
	{ value: 'French Guiana', label: 'French Guiana' },
	{ value: 'French Polynesia', label: 'French Polynesia' },
	{ value: 'French Southern Territories', label: 'French Southern Territories' },
	{ value: 'Gabon', label: 'Gabon' },
	{ value: 'Gambia', label: 'Gambia' },
	{ value: 'Georgia', label: 'Georgia' },
	{ value: 'Germany', label: 'Germany' },
	{ value: 'Ghana', label: 'Ghana' },
	{ value: 'Gibraltar', label: 'Gibraltar' },
	{ value: 'Greece', label: 'Greece' },
	{ value: 'Greenland', label: 'Greenland' },
	{ value: 'Grenada', label: 'Grenada' },
	{ value: 'Guadeloupe', label: 'Guadeloupe' },
	{ value: 'Guam', label: 'Guam' },
	{ value: 'Guatemala', label: 'Guatemala' },
	{ value: 'Guernsey', label: 'Guernsey' },
	{ value: 'Guinea', label: 'Guinea' },
	{ value: 'Guinea-Bissau', label: 'Guinea-Bissau' },
	{ value: 'Guyana', label: 'Guyana' },
	{ value: 'Haiti', label: 'Haiti' },
	{ value: 'Heard Island and Mcdonald Islands', label: 'Heard Island and Mcdonald Islands' },
	{ value: 'Holy See (Vatican City State)', label: 'Holy See (Vatican City State)' },
	{ value: 'Honduras', label: 'Honduras' },
	{ value: 'Hong Kong', label: 'Hong Kong' },
	{ value: 'Hungary', label: 'Hungary' },
	{ value: 'Iceland', label: 'Iceland' },
	{ value: 'India', label: 'India' },
	{ value: 'Indonesia', label: 'Indonesia' },
	{ value: 'Iran, Islamic Republic Of', label: 'Iran, Islamic Republic Of' },
	{ value: 'Iraq', label: 'Iraq' },
	{ value: 'Ireland', label: 'Ireland' },
	{ value: 'Isle of Man', label: 'Isle of Man' },
	{ value: 'Israel', label: 'Israel' },
	{ value: 'Italy', label: 'Italy' },
	{ value: 'Jamaica', label: 'Jamaica' },
	{ value: 'Japan', label: 'Japan' },
	{ value: 'Jersey', label: 'Jersey' },
	{ value: 'Jordan', label: 'Jordan' },
	{ value: 'Kazakhstan', label: 'Kazakhstan' },
	{ value: 'Kenya', label: 'Kenya' },
	{ value: 'Kiribati', label: 'Kiribati' },
	{ value: "Korea, Democratic PeopleS Republic of", label: "Korea, Democratic People'S Republic of" },
	{ value: 'Korea, Republic of', label: 'Korea, Republic of' },
	{ value: 'Kuwait', label: 'Kuwait' },
	{ value: 'Kyrgyzstan', label: 'Kyrgyzstan' },
	{ value: "Lao PeopleS Democratic Republic", label: "Lao People'S Democratic Republic" },
	{ value: 'Latvia', label: 'Latvia' },
	{ value: 'Lebanon', label: 'Lebanon' },
	{ value: 'Lesotho', label: 'Lesotho' },
	{ value: 'Liberia', label: 'Liberia' },
	{ value: 'Libyan Arab Jamahiriya', label: 'Libyan Arab Jamahiriya' },
	{ value: 'Liechtenstein', label: 'Liechtenstein' },
	{ value: 'Lithuania', label: 'Lithuania' },
	{ value: 'Luxembourg', label: 'Luxembourg' },
	{ value: 'Macao', label: 'Macao' },
	{ value: 'Macedonia, The Former Yugoslav Republic of', label: 'Macedonia, The Former Yugoslav Republic of' },
	{ value: 'Madagascar', label: 'Madagascar' },
	{ value: 'Malawi', label: 'Malawi' },
	{ value: 'Malaysia', label: 'Malaysia' },
	{ value: 'Maldives', label: 'Maldives' },
	{ value: 'Mali', label: 'Mali' },
	{ value: 'Malta', label: 'Malta' },
	{ value: 'Marshall Islands', label: 'Marshall Islands' },
	{ value: 'Martinique', label: 'Martinique' },
	{ value: 'Mauritania', label: 'Mauritania' },
	{ value: 'Mauritius', label: 'Mauritius' },
	{ value: 'Mayotte', label: 'Mayotte' },
	{ value: 'Mexico', label: 'Mexico' },
	{ value: 'Micronesia, Federated States of', label: 'Micronesia, Federated States of' },
	{ value: 'Moldova, Republic of', label: 'Moldova, Republic of' },
	{ value: 'Monaco', label: 'Monaco' },
	{ value: 'Mongolia', label: 'Mongolia' },
	{ value: 'Montserrat', label: 'Montserrat' },
	{ value: 'Morocco', label: 'Morocco' },
	{ value: 'Mozambique', label: 'Mozambique' },
	{ value: 'Myanmar', label: 'Myanmar' },
	{ value: 'Namibia', label: 'Namibia' },
	{ value: 'Nauru', label: 'Nauru' },
	{ value: 'Nepal', label: 'Nepal' },
	{ value: 'Netherlands', label: 'Netherlands' },
	{ value: 'Netherlands Antilles', label: 'Netherlands Antilles' },
	{ value: 'New Caledonia', label: 'New Caledonia' },
	{ value: 'New Zealand', label: 'New Zealand' },
	{ value: 'Nicaragua', label: 'Nicaragua' },
	{ value: 'Niger', label: 'Niger' },
	{ value: 'Nigeria', label: 'Nigeria' },
	{ value: 'Niue', label: 'Niue' },
	{ value: 'Norfolk Island', label: 'Norfolk Island' },
	{ value: 'Northern Mariana Islands', label: 'Northern Mariana Islands' },
	{ value: 'Norway', label: 'Norway' },
	{ value: 'Oman', label: 'Oman' },
	{ value: 'Pakistan', label: 'Pakistan' },
	{ value: 'Palau', label: 'Palau' },
	{ value: 'Palestinian Territory, Occupied', label: 'Palestinian Territory, Occupied' },
	{ value: 'Panama', label: 'Panama' },
	{ value: 'Papua New Guinea', label: 'Papua New Guinea' },
	{ value: 'Paraguay', label: 'Paraguay' },
	{ value: 'Peru', label: 'Peru' },
	{ value: 'Philippines', label: 'Philippines' },
	{ value: 'Pitcairn', label: 'Pitcairn' },
	{ value: 'Poland', label: 'Poland' },
	{ value: 'Portugal', label: 'Portugal' },
	{ value: 'Puerto Rico', label: 'Puerto Rico' },
	{ value: 'Qatar', label: 'Qatar' },
	{ value: 'Reunion', label: 'Reunion' },
	{ value: 'Romania', label: 'Romania' },
	{ value: 'Russian Federation', label: 'Russian Federation' },
	{ value: 'RWANDA', label: 'RWANDA' },
	{ value: 'Saint Helena', label: 'Saint Helena' },
	{ value: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis' },
	{ value: 'Saint Lucia', label: 'Saint Lucia' },
	{ value: 'Saint Pierre and Miquelon', label: 'Saint Pierre and Miquelon' },
	{ value: 'Saint Vincent and the Grenadines', label: 'Saint Vincent and the Grenadines' },
	{ value: 'Samoa', label: 'Samoa' },
	{ value: 'San Marino', label: 'San Marino' },
	{ value: 'Sao Tome and Principe', label: 'Sao Tome and Principe' },
	{ value: 'Saudi Arabia', label: 'Saudi Arabia' },
	{ value: 'Senegal', label: 'Senegal' },
	{ value: 'Serbia and Montenegro', label: 'Serbia and Montenegro' },
	{ value: 'Seychelles', label: 'Seychelles' },
	{ value: 'Sierra Leone', label: 'Sierra Leone' },
	{ value: 'Singapore', label: 'Singapore' },
	{ value: 'Slovakia', label: 'Slovakia' },
	{ value: 'Slovenia', label: 'Slovenia' },
	{ value: 'Solomon Islands', label: 'Solomon Islands' },
	{ value: 'Somalia', label: 'Somalia' },
	{ value: 'South Africa', label: 'South Africa' },
	{ value: 'South Georgia and the South Sandwich Islands', label: 'South Georgia and the South Sandwich Islands' },
	{ value: 'Spain', label: 'Spain' },
	{ value: 'Sri Lanka', label: 'Sri Lanka' },
	{ value: 'Sudan', label: 'Sudan' },
	{ value: 'Suriname', label: 'Suriname' },
	{ value: 'Svalbard and Jan Mayen', label: 'Svalbard and Jan Mayen' },
	{ value: 'Swaziland', label: 'Swaziland' },
	{ value: 'Sweden', label: 'Sweden' },
	{ value: 'Switzerland', label: 'Switzerland' },
	{ value: 'Syrian Arab Republic', label: 'Syrian Arab Republic' },
	{ value: 'Taiwan, Province of China', label: 'Taiwan, Province of China' },
	{ value: 'Tajikistan', label: 'Tajikistan' },
	{ value: 'Tanzania, United Republic of', label: 'Tanzania' },
	{ value: 'Thailand', label: 'Thailand' },
	{ value: 'Timor-Leste', label: 'Timor-Leste' },
	{ value: 'Togo', label: 'Togo' },
	{ value: 'Tokelau', label: 'Tokelau' },
	{ value: 'Tonga', label: 'Tonga' },
	{ value: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
	{ value: 'Tunisia', label: 'Tunisia' },
	{ value: 'Turkey', label: 'Turkey' },
	{ value: 'Turkmenistan', label: 'Turkmenistan' },
	{ value: 'Turks and Caicos Islands', label: 'Turks and Caicos Islands' },
	{ value: 'Tuvalu', label: 'Tuvalu' },
	{ value: 'Uganda', label: 'Uganda' },
	{ value: 'Ukraine', label: 'Ukraine' },
	{ value: 'United Arab Emirates', label: 'United Arab Emirates' },
	{ value: 'United Kingdom', label: 'United Kingdom' },
	{ value: 'United States', label: 'United States' },
	{ value: 'United States Minor Outlying Islands', label: 'United States Minor Outlying Islands' },
	{ value: 'Uruguay', label: 'Uruguay' },
	{ value: 'Uzbekistan', label: 'Uzbekistan' },
	{ value: 'Vanuatu', label: 'Vanuatu' },
	{ value: 'Venezuela', label: 'Venezuela' },
	{ value: 'Viet Nam', label: 'Viet Nam' },
	{ value: 'Virgin Islands, British', label: 'Virgin Islands, British' },
	{ value: 'Virgin Islands, U.S.', label: 'Virgin Islands, U.S.' },
	{ value: 'Wallis and Futuna', label: 'Wallis and Futuna' },
	{ value: 'Western Sahara', label: 'Western Sahara' },
	{ value: 'Yemen', label: 'Yemen' },
	{ value: 'Zambia', label: 'Zambia' },
	{ value: 'Zimbabwe', label: 'Zimbabwe' }
];

export default class TrackShipments extends Component {
	state = {
		activeTab: '1',
		deviceAdCode: '',
		value: '',
		trackShipment: [],
		incomingShipments: [],
		outgoingShipments: [],
		refno: '',
		loading: true,
		trackShipmentModal: false,
		modal: false,
		completed: 0,
		trackMore: false,
		courierName: "",
		pathName: "",
		etaShipment: "",
		completedData: "",
		classNameData: "",
		loaderNew: false,
		internalData: ""
	}


	packageHandler = (refno) => {
		this.props.history.push({ pathname: './outGoingPackageHandler', state: refno })
	}

	incomingPackageHandler = (refno) => {
		this.props.history.push({ pathname: './incomingPackageHandler', state: refno })
	}

	async componentDidMount() {
		const deviceAdCode = await localStorage.getItem('adCodes');
		this.setState({ deviceAdCode })
		var values = window.location.pathname.split('/');
		this.setState({ pathName: values })
		if (values.length >= 3 && values[2]) {
			this.setState({
				loaderNew: true
			})
			customTracking({ tripSerialId: values[2], refno: values[2] })
				.then(Response => {
					this.setState({ trackShipment: Response.data.data, internalData: Response.data.internal_data, etaShipment: Response.data.eta, tripSerialId: '', trackShipmentModal: true, trackMore: false })
					const { completedData, classNameData } = this.props;
					const newState = progreeOfTrcking(completedData, classNameData, Response);
					this.setState(newState);
					this.setState({
						loaderNew: false
					})
				})
				.catch(err => {
					toast.error(err.response?.data.message, {
						position: toast.POSITION.TOP_RIGHT
					});
					if (err.response?.status === 400) {
						this.setState({ trackMore: true, trackShipmentModal: false })
					}
					this.setState({
						loaderNew: false
					})
				})
		}

		saveIpAddress({
			page_name: "Track Shipment"
		})
			.then(res => {
				console.log(res.data.message);
			})
			.catch(err => {
				console.log(err)
			})
	}


	handleChange = (e) => {
		this.setState({ refno: e.target.value })
	}

	searchTracksShipment = () => {
		this.setState({ modal: true })
		if (this.state.refno) {
			this.setState({
				loaderNew: true
			})
			customTracking({ tripSerialId: this.state.refno, refno: this.state.refno })
				.then(Response => {
					this.setState({ trackShipment: Response.data.data, internalData: Response.data.internal_data, etaShipment: Response.data.eta, tripSerialId: '', trackShipmentModal: true, trackMore: false, trackMore: false })
					const { completedData, classNameData } = this.props;

					const newState = progreeOfTrcking(completedData, classNameData, Response);
					this.setState(newState);
					this.setState({
						loaderNew: false
					})
				})
				.catch(err => {
					toast.error(err.response.data.message, {
						position: toast.POSITION.TOP_RIGHT
					});
					if (err.response.status === 400) {
						this.setState({ trackMore: true, trackShipmentModal: false })
					}
					this.setState({
						loaderNew: false
					})
				})
		} else {
			toast.error(" please enter a valid ref no OR TSID to track.", {
				position: toast.POSITION.TOP_RIGHT
			});
			this.setState({ trackShipment: '', tripSerialId: '', trackShipmentModal: true, })
			this.setState({
				loaderNew: false
			})
		}
	}

	keyPress = (e) => {
		if (e.keyCode === 13) {
			this.searchTracksShipment()
		}
	}

	handleChangeCourier = (e) => {
		this.setState({ courierName: e.value })
	}

	render() {
		return (
			<>
				<Helmet>
					<title>Track Shipments - Postkodes</title>
					<meta name="description" content="Track shipments on Postkodes" />
				</Helmet>
				<div className="mt-2 style">
					<Card>
						<CardBody>
							{this.state.activeTab === '1' &&
								<div class="input-group col-md-4 ml-auto p-0 pb-3 pb-sm-3 pb-md-0 pb-lg-0 pb-xl-0">
									<input class="form-control py-2 border-right-0 border" value={this.state.refno}
										onChange={this.handleChange} onKeyDown={this.keyPress}
										placeholder="Enter Ref No OR Trip Serial ID" />
									<span class="input-group-append">
										<button class="btn btn-outline-secondary border-left-0 border" type="button" style={{ 'z-index': 0 }}
											onClick={this.searchTracksShipment}>
											<i class="fa fa-search"></i>
										</button>
									</span>
								</div>
							}
							<Link class="btn btn-back" to="/"><i class="fa fa-long-arrow-left pr-3"> </i> Home</Link>
							<Nav tabs>
								<NavItem>
									<NavLink
										className={classnames({ active: this.state.activeTab === '1' })}
										onClick={() => {
											this.toggle('1');
										}}>
										Track Shipments
									</NavLink>
								</NavItem>
							</Nav>
							<TabContent activeTab={this.state.activeTab} className="pt-5">
								{
									this.state.loaderNew ?
										<div className='d-flex justify-content-center'>
											<ClipLoader />
										</div> :
										<TabPane tabId="1">
											{/* {
											this.state.trackMore &&
											<div className="row justify-content-end">
												<div className="col-4">
													<FormGroup>
														<Select
															placeholder={<div>Select Courier Name</div>}
															onChange={(e) => {
																this.searchTracksShipmentTrackingMore(e.value);
															}}
															options={courierNameData}
														/>
													</FormGroup>
												</div>
											</div>
										} */}

											{this.state.trackShipmentModal && this.state.trackShipment &&
												<div className="d-flex justify-content-center track-card">
													<Card className="dashboard-card-style track-card-style shadow mb-4">
														<CardBody>
															<div className="row">
																<div className="col-6">
																	<div className="row">
																		<div className="col-6">
																			<h1 className="title-main-head-track">Ref No</h1>
																		</div>
																		<div className="col-6">
																			<h1 className="title-description-profile-track">{this.state?.internalData?.refno || this.state.pathName[2] || this.state.refno}</h1>
																		</div>
																	</div>
																</div>
																<div className="col-6">
																	<div className="row">
																		<div className="col-6">
																			<h1 className="title-main-head-track">Trip Serial ID</h1>
																		</div>
																		<div className="col-6">
																			<h1
																				className="title-description-profile-track">{this.state?.internalData?.nfcserialno}</h1>
																		</div>
																	</div>
																</div>
																<div className="col-6">
																	<div className="row">
																		<div className="col-6">
																			<h1 className="title-main-head-track">Quantity </h1>
																		</div>
																		<div className="col-6">
																			<h1
																				className="title-description-profile-track">{this.state?.internalData?.quantity}</h1>
																		</div>
																	</div>
																</div>
																<div className="col-6">
																	<div className="row">
																		<div className="col-6">
																			<h1 className="title-main-head-track">MOT </h1>
																		</div>
																		<div className="col-6">
																			<h1
																				className="title-description-profile-track">{this.state?.internalData?.mot}</h1>
																		</div>
																	</div>
																</div>
																<div className="col-6">
																	<div className="row">
																		<div className="col-6">
																			<h1 className="title-main-head-track">Origin</h1>
																		</div>
																		<div className="col-6">
																			<h1 className="title-description-profile-track">{this.state?.internalData?.origin}</h1>
																		</div>
																	</div>
																</div>
																<div className="col-6">
																	<div className="row">
																		<div className="col-6">
																			<h1 className="title-main-head-track">Destination</h1>
																		</div>
																		<div className="col-6">
																			<h1
																				className="title-description-profile-track">{this.state?.internalData?.destination}</h1>
																		</div>
																	</div>
																</div>
															</div>
														</CardBody>
													</Card>
												</div>
											}
											<>
												{this.state.trackShipmentModal && this.state.trackShipment &&
													<>
														<div className="timeline-progress">
															<ProgressBar completed={this.state.completed} label={this.state.trackShipment} completedData={this.state.completedData} classNameData={this.state.classNameData} />
														</div>
														{
															this.state.etaShipment &&
															<div className='eta-progress pb-3'>
																<Label className="pr-3">Estimated time of arrival : </Label>
																<Label for="exampleEmail" className="label-description">{this.state.etaShipment}</Label>
															</div>
														}
													</>
												}
											</>
											{this.state.trackShipmentModal && this.state.trackShipment &&
												<VerticalTimeline layout='1-column' className="overflow-auto pb-0 vt-style">
													{this.state.trackShipment.map(a => {
														return (
															<VerticalTimelineElement
																className="vertical-timeline-element--work "
																date={
																	<div>
																		<div className="row">
																			<div className="col-12">
																				<span className="pl-2 vertical-time-style"
																					style={{ color: '#0000a0' }}>{a.date ? moment(a.date).format('DD-MM-yy') : moment(a.new_date_format).format('DD-MM-yy')}</span>
																			</div>
																			<div className="col-12 ">
																				<span className="pl-2 vertical-time-style"
																					style={{ color: '#0000a0' }}>{a.date ? moment(a.date).format("h:mm A") : moment(a.new_date_format).format("h:mm A")}</span>
																			</div>
																		</div>
																	</div>
																}
																iconStyle={{ background: '#fff', color: '#0000a0' }}>
																<Card className="shadow border-0">
																	<CardBody>
																		<table className="track-table">
																			<tr>
																				<td>
																					<Label for="exampleEmail " className="pr-5 label-style-tracking">Status</Label>
																				</td>
																				<td>
																					<span class="badge badge-pill badge-primary px-2 font-weight-light ">{a.trackingstatus ? a.trackingstatus : a.status ? a.status : a.track_status}</span>
																				</td>
																			</tr>
																			<tr>
																				<td>
																					<Label for="exampleEmail " className="pr-5 label-style-tracking">Quantity</Label>
																				</td>
																				<td>
																					<Label for="exampleEmail" className="label-description">{a.quantity ? a.quantity : a.track_quantity}</Label>
																				</td>
																			</tr>
																			<tr>
																				<td>
																					<Label for="exampleEmail " className="pr-5 label-style-tracking">Comment</Label>
																				</td>
																				<td>
																					<Label for="exampleEmail" className="label-description">{a.comment ? a.comment : a.track_comment}</Label>
																				</td>
																			</tr>
																			<tr>
																				<td>
																					<Label for="exampleEmail " className="pr-5 label-style-tracking">Location</Label>
																				</td>
																				<td>
																					<Label for="exampleEmail" className="label-description">{a.location ? a.location : a.track_location}</Label>
																				</td>
																			</tr>
																		</table>
																	</CardBody>
																</Card>
															</VerticalTimelineElement>
														);
													})}
												</VerticalTimeline>
											}
										</TabPane>
								}
							</TabContent>
						</CardBody>
					</Card>
				</div>
			</>
		)
	}
}
