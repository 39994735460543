import React, { Component } from 'react';
import './UpdateBanner.css'; // Ensure you have the corresponding CSS file
import { getBannerSlides, updateBannerSlides } from '../../api/Form';
import { toast } from 'react-toastify';

class UpdateBanner extends Component {
    constructor(props) {
        super(props);
        // Initialize state with some sets of fields
        this.state = {
            fields: []
        };
    }
    componentDidMount() {
        getBannerSlides().then((res) => {
            const response = res.data;
            this.setState({ fields: response.data });
        })
            .catch((err) => {
                toast.error(err.message)
            });
    }

    // Handle change in input fields
    handleChange = (index, e) => {
        const { name, value } = e.target;
        const newFields = [...this.state.fields];
        newFields[index][name] = value;
        this.setState({ fields: newFields });
    };

    // Add a new set of fields
    handleAddField = () => {
        this.setState(prevState => ({
            fields: [...prevState.fields, { freight: '', country: '', price: '' }]
        }));
    };

    // Remove a set of fields
    handleRemoveField = (index) => {
        this.setState(prevState => ({
            fields: prevState.fields.filter((_, i) => i !== index)
        }));
    };
    // Send the updated fields to server
    handleSubmit = (event) => {
        event.preventDefault();
        const { fields } = this.state;

        // Check if any field is empty
        for (let i = 0; i < fields.length; i++) {
            const { freight, country, price } = fields[i];
            if (!freight || !country || !price) {
                alert("Please fill out all fields.");
                return;
            }
        }

        updateBannerSlides(this.state.fields).then((res) => {
            toast.success(res.data.message);
        })
            .catch((err) => {
                toast.error(err.message)
            });
    };

    render() {
        const { fields } = this.state;

        return (
            <div className="update-banner">
                <form onSubmit={this.handleSubmit}>
                    {fields.map((field, index) => (
                        <div key={index} className="form-group">
                            <div className="row mb-3">
                                <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
                                    <label htmlFor={`freight${index}`}>Freight</label>
                                    <input
                                        type="text"
                                        id={`freight${index}`}
                                        name="freight"
                                        value={field.freight}
                                        onChange={(e) => this.handleChange(index, e)}
                                        placeholder="Enter freight"
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                                    <label htmlFor={`country${index}`}>Country</label>
                                    <input
                                        type="text"
                                        id={`country${index}`}
                                        name="country"
                                        value={field.country}
                                        onChange={(e) => this.handleChange(index, e)}
                                        placeholder="Enter country"
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                                    <label htmlFor={`price${index}`}>Price</label>
                                    <input
                                        type="text"
                                        id={`price${index}`}
                                        name="price"
                                        value={field.price}
                                        onChange={(e) => this.handleChange(index, e)}
                                        placeholder="Enter price"
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-lg-11 col-md-6 text-end mt-4">
                                    <button
                                        type="button"
                                        onClick={() => this.handleRemoveField(index)}
                                        className="btn btn-danger"
                                    >
                                        Remove
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="button-group mt-3">
                        <button
                            type="button"
                            onClick={this.handleAddField}
                            className="btn btn-primary btn-add-more"  // Added class for fixed width
                        >
                            Add More
                        </button>
                        <button
                            type="submit"
                            className="btn btn-danger btn-submit"  // Added class for fixed width
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

export default UpdateBanner;
