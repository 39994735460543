import React, { Component } from 'react'
import { CardHeader, CardBody, Card, Button, FormGroup, Input, Label } from 'reactstrap';
import { callActivityApi } from '../../utils/saveUserActivity';
import { checkScreenAccess, deleteShipment, requestAccess, shipmentFilterResults } from '../../api/Form';
import { ClipLoader } from "react-spinners";
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import DataTableExtensions from 'react-data-table-component-extensions';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { getLocation, phoneNoVerifyCheck } from '../../utils/utils';
import PopOverCard from './PopOverCard';
import { image } from '../../api/URLS';
export default class ViewShipment extends Component {
  state = {
    shippingHistory: [],
    viewBy: 'ViewbyRefNo',
    loading: true,
    data: [],
    showData: [],
    columns: [],
    requestAccess: true,
    keyword: '',
    startDate: '',
    endDate: '',
    location: '',
    userId: '',
    userRole: ''
  }

  async componentDidMount() {
    callActivityApi({ 'pageName': 'View Shipment', 'description': 'User has visited View Shipment Screen' });
    const historyPush = this.props.history.push
    phoneNoVerifyCheck(historyPush)
    const data = await localStorage.getItem('auth');
    const { id, ADCode } = JSON.parse(data);
    this.setState({ userId: id, deviceADcode: ADCode });
    var userType = await localStorage.getItem('accounttypeUsers');
    if (userType !== 'Admin') {
      checkScreenAccess(
        {
          'userId': id,
          'screenType': 'View Shipments'
        }
      ).then((response) => {
        this.setState({ loading: false, requestAccess: true });
      }).catch(err => {
        this.setState({ loading: false, requestAccess: false });
      });
    } else {
      this.setState({ requestAccess: true, loading: false, userRole: userType })
    }
    getLocation().then((location) => this.setState({ location: location }));


    let columns = [
      {
        name: 'Tracking Number',
        selector: 'refno',
        cell: (row) => (
          <div style={{ width: 200 }}>
            <PopOverCard refno={row} trackShipment={this.trackShipment} editShipment={this.editShipment} goToPrintLabel={this.goToPrintLabel} createInvoice={this.createInvoice} deleteEntry={this.deleteEntry} />
          </div>
        ),
        sortable: true,
      },
      {
        name: 'Quantity',
        selector: 'quantity',
        sortable: true,
      },
      {
        name: 'Delivery Instruction',
        selector: 'comment',
        sortable: true,
      },
      {
        name: 'Tracking Status',
        selector: 'trackingstatus',
        sortable: true,
      },
      {
        name: 'Origin',
        selector: 'origin',
        sortable: true,
      },
      {
        name: 'Destination',
        selector: 'destination',
        sortable: true,
      },
      {
        name: 'Type',
        selector: 'type',
        sortable: true,
      },
      {
        name: 'Delivery Address',
        selector: 'deliveryAddress',
        sortable: true,
      },
      {
        name: 'Created At',
        selector: 'created_at',
        sortable: true,
      },
      {
        name: 'Pickup',
        selector: 'pickup',
        sortable: true,
      },
      {
        name: 'Carrier Name',
        selector: 'carrierName',
        sortable: true,
      },
      {
        name: 'Receiver Name',
        selector: 'receiverName',
        sortable: true,
      },
      {
        name: 'Weight',
        selector: 'weight',
        sortable: true,
      },
      {
        name: 'Height',
        selector: 'height',
        sortable: true,
      },
      {
        name: 'Width',
        selector: 'width',
        sortable: true,
      },
      {
        name: 'Length',
        selector: 'length',
        sortable: true,
      },
      {
        name: 'Package Type',
        selector: 'packageType',
        sortable: true,
      },
      {
        name: 'Receiver Business Name',
        selector: 'receiverBusinessName',
        sortable: true,
      },
      {
        name: 'Receiver Email',
        selector: 'receiverEmail',
        sortable: true,
      },
      {
        name: 'Customer Ref no',
        selector: 'customerRefNo',
        sortable: true,
      },
      {
        name: 'Package Currency',
        selector: 'packageCurrency',
        sortable: true,
      },
      {
        name: 'Package Value',
        selector: 'packageValue',
        sortable: true,
      },
      {
        name: 'Reciever Phone',
        selector: 'receiverPhone',
        sortable: true,
      },
      {
        name: 'Shipment Type',
        selector: 'shipmentType',
        sortable: true,
      },
      {
        name: 'Origin Country',
        selector: 'originCountry',
        sortable: true,
      },
      {
        name: 'Destination Country',
        selector: 'destinationCountry',
        sortable: true,
      },
      {
        name: 'Shipment Description',
        selector: 'shipmentDescription',
        sortable: true,
      },
      {
        name: 'Phone Number1',
        selector: 'phoneNumber1',
        sortable: true,
      },
      {
        name: 'EmailAddress1',
        selector: 'emailAddress1',
        sortable: true,
      },
      {
        name: 'Shipper Name',
        selector: 'shipperName1',
        sortable: true,
      },
      {
        name: 'Shipper Phone',
        selector: 'shipperPhone1',
        sortable: true,
      },
      {
        name: 'Shipper Email',
        selector: 'shipperEmail1',
        sortable: true,
      },
      {
        name: 'Shipper Address',
        selector: 'shipperAddress1',
        sortable: true,
      },
      {
        name: 'Internal Comment',
        selector: 'internalComment',
        cell: (row) => (
          <div>
            {(this.state?.showData[0]?.deviceADcode === this.state.deviceADcode || this.state.userRole === "Admin") ?
              row.internalComment : "NA"}
          </div>
        ),
        sortable: true,
        className: "internalComment"
      },
      {
        name: "Documents",
        cell: (row) => (
          <div>
            {row.uploads && row.uploads.map(p => {
              return (
                <div className='p-1'>
                  <a href={image + p.fileLink} target="_blank" class="help-text-style" >View</a>
                  <br />
                </div>
              )
            })
            }
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ];
    this.setState({ columns });
  }



  editShipment = (refno) => {
    this.props.history.push('/admin/editShipment', { refno: refno })
  }

  trackShipment = (refno) => {
    this.props.history.push('/admin/trackShipments', { refno: refno })
  }

  createInvoice = (refNo) => {
    this.props.history.push({ pathname: '/admin/invoiceByAmount', state: refNo })
  }

  goToPrintLabel = (refno) => {
    this.props.history.push('/admin/adhoc', { refno: refno });
  }

  deleteEntry = (refNo, uId) => {
    if (uId) {
      if (window.confirm("Are you sure to delete this?")) {
        deleteShipment({
          'device_ad_code': this.state.deviceADcode,
          'ref_no': refNo,
          'user_id': this.state.userId,
          'location': this.state.location,
        })
          .then(Response => {
            toast.success(Response.data.message ? Response.data.message : 'Entry is deleted successfully!!', {
              position: toast.POSITION.TOP_RIGHT
            });
            window.location.reload();
          })
          .catch(err => {
            toast.error(err.response.data.message, {
              position: toast.POSITION.TOP_RIGHT
            });
            if (err.response.status === 400) {
              setTimeout(() => this.props.history.push('/admin/deleteShipmentAccessRequest'), 1000)
            }
          })
      }
    }
  };

  sendRequestAccess = async () => {
    this.setState({ loading: true });
    const info = await localStorage.getItem('auth');
    const sendInfo = JSON.parse(info);
    requestAccess({
      'username': sendInfo.username,
      'firstName': sendInfo.firstName,
      'lastName': sendInfo.lastName,
      'accounttypeUsers': sendInfo.accounttypeUsers,
      'businessname': sendInfo.businessname,
      'ADCode': sendInfo.ADCode,
      'address': sendInfo.address,
      'email': sendInfo.email,
      'phoneNumber': sendInfo.phonenumber,
      'userId': sendInfo.id,
      'screenType': 'View Shipments',
    }).then(Response => {
      this.setState({ loading: false });
      toast.success(Response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }).catch(err => {
      this.setState({ loading: false });
      let message = err.message;
      if (err.response && err.response.data.message) {
        message = err.response.data.message;
      }
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })
  };

  searchData = () => {
    this.setState({ loading: true });
    let errMsg = '';
    var input = {
      'deviceADcode': this.state.deviceADcode,
      'mode': 'all',
    };
    if (this.state.keyword || (this.state.startDate && this.state.endDate)) {

      if (this.state.startDate && this.state.endDate) {
        if (new Date(this.state.endDate) >= new Date(this.state.startDate)) {
          input.startDate = moment(this.state.startDate).format('YYYY-MM-DD') + " 00:00:00";
          input.endDate = moment(this.state.endDate).format('YYYY-MM-DD') + " 23:59:00";
        } else {
          errMsg = 'End Date must be greater than Start Date';
        }
      }

      if (this.state.keyword) {
        input.keyword = this.state.keyword;
      }


      if (!errMsg) {
        shipmentFilterResults(input).then((response) => {
          this.setState({ loading: false, showData: response.data.data });
        }).catch((err) => {
          toast.error('Data not found', {
            position: toast.POSITION.TOP_RIGHT
          });
          this.setState({ loading: false, showData: [] });
        })
      } else {
        toast.error(errMsg, {
          position: toast.POSITION.TOP_RIGHT
        });
        this.setState({ loading: false, showData: [] });
      }

    } else {
      toast.error('Please fill keyword or date range', {
        position: toast.POSITION.TOP_RIGHT
      });
      this.setState({ loading: false, showData: [] });
    }



  }
  render() {
    const { requestAccess, loading, showTable, columns, showData, data } = this.state;



    const tableData = {
      columns,
      exportHeaders: true,
      data: showData,
    };
    return (
      <>
        {!requestAccess ? <div className="justify-content-center pt-2 main-contain-title">
          <>
            <p>This feature is restricted.</p>
            <Button onClick={() => this.sendRequestAccess()}
              disabled={this.state.loading}>Request Access for free</Button>
          </>
        </div> :
          <>
            <div className="d-flex justify-content-center scan-parcel-loader">
              <ClipLoader
                size={50}
                color={"#123abc"}
                loading={loading}
              />
            </div>
            {!loading ?
              <div className="mt-2 style">
                <Card>
                  <CardHeader className="c-header">
                    <div className="row ">
                      <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-8">
                        <span>View Shipment</span>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className="row ">
                      <span className="ml-3">User can search by tracking number or phone number, shipper name, receiver name, email address</span>
                      <div className='row mt-4 col-12'>
                        <div className='col-lg-4 col-xs-12 col-sm-12 mb-3 mb-sm-3'>
                          <FormGroup>
                            <Label>Enter Keyword</Label>
                            <Input
                              type="text"
                              name="nfcADcode"
                              onChange={(e) => this.setState({ keyword: e.target.value })}
                              value={this.state.keyword}
                              placeholder="Enter Keyword" />
                          </FormGroup>
                        </div>
                        <div className='col-lg-4 col-xs-12 col-sm-12 mb-3 mb-sm-3'>
                          <FormGroup>
                            <Label>Start Date</Label>
                            <DatePicker
                              // {...field}
                              // {...props}
                              required
                              placeholderText='Select Start Date'
                              maxDate={moment().toDate()}
                              selected={(this.state.startDate && new Date(this.state.startDate)) || null}
                              onChange={val => {
                                this.setState({ startDate: val })
                              }}
                            />
                          </FormGroup>
                        </div>
                        <div className='col-lg-4 col-xs-12 col-sm-12'>
                          <FormGroup>
                            <Label>End Date</Label>
                            <DatePicker
                              // {...field}
                              // {...props}
                              required
                              placeholderText='Select End Date'
                              maxDate={moment().toDate()}
                              selected={(this.state.endDate && new Date(this.state.endDate)) || null}
                              onChange={val => {
                                this.setState({ endDate: val })
                              }}
                            />
                          </FormGroup>
                        </div>
                        <div className='col-4'>
                          <button className="btn btn-submit" onClick={() => { this.searchData() }} >Search</button>
                        </div>
                      </div>
                    </div>

                    <DataTableExtensions  {...tableData}>
                      <DataTable
                        className="dataTables_wrapper"
                        pagination
                        highlightOnHover
                        columns={columns}
                        export={true}
                        responsive={true}
                        data={data}
                      />
                    </DataTableExtensions>
                  </CardBody>
                </Card>
              </div>
              : null}
          </>}
      </>
    )
  }
}
