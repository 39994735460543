import React, { Component, Fragment } from 'react'
import { CardHeader, CardBody, FormGroup, Label, Input, Card, FormFeedback, Button } from 'reactstrap';
import * as Yup from 'yup';
import { Formik} from 'formik';
import {ClipLoader} from "react-spinners";
import Select from 'react-select';
import {  requestAccess, checkScreenAccess, updateStatusAirway} from '../../api/Form';
import { toast } from 'react-toastify';
import { callActivityApi } from '../../utils/saveUserActivity';
import { phoneNoVerifyCheck } from '../../utils/utils';

const Schema = Yup.object().shape({
  airwayBill: Yup.string()
        .min(8, 'Master Airwaybill number must be exactly 8 digits')
        .max(20, 'Master Airwaybill number must be exactly 20 digits')
        .required('Master Airwaybill number is required'),
  trackingstatus: Yup.string().required('Status is required'),
  comment: Yup.string().required('Comment is required'),
  bySMS: Yup.boolean(),
  smsComment: Yup
      .string()
      .when("bySMS", {
        is: true,
        then: Yup.string()
        .max(160, 'SMS comment must be exactly 160 digits')
        .required("SMS comment box is required")
      })
});

const categoryOption = [
	{ label: 'Created', value: 'created' },
	{ label: 'Pickup', value: 'Pickup' },
	{ label: 'Transfer', value: 'Transfer' },
	{ label: 'Customs', value: 'Customs' },
	{ label: 'Delivered', value: 'Delivered' },
	{ label: 'Received', value: 'Received' },
	{ label: 'Despatched', value: 'Despatched' },
	{ label: 'Sorting Facility', value: 'Sorting Facility' },
	{ label: 'Onboard to Delivery Address', value: 'Onboard to Delivery Address' },
	{ label: 'Held at Customs', value: 'Held at Customs' },
	{ label: 'Custom Released', value: 'Custom Released' },
	{ label: 'Delivery Missed', value: 'Delivery Missed' },
	{ label: 'Delivery Exception', value: 'Delivery Exception' },
	{ label: 'Intransit', value: 'Intransit' },
	{ label: 'Custom Cleared', value: 'Custom Cleared' },
	{ label: 'Airport Transfer', value: 'Airport Transfer' },
	{ label: 'Destination Airport', value: 'Destination Airport' },
  { label: 'Onforwarded', value: 'Onforwarded'},
	{ label: 'Arrived', value: 'Arrived'},
	{ label: 'Departed', value: 'Departed'},
	{ label: 'Custom Inspection', value: 'Custom Inspection'},
  { label: 'Collect', value: 'Collect'},
  { label: 'Collected', value: 'Collected'},
  { label: 'Accepted', value: 'Accepted'},
  { label: 'Warehouse Received', value: 'Warehouse Received'},
  { label: 'Outbound Staged', value: 'Outbound Staged'},
  { label: 'Biosecurity Inspection', value: 'Biosecurity Inspection'},
  { label: 'Cancelled', value: 'Cancelled'},
  { label: 'Withdrawn', value: 'Withdrawn'},
  { label: 'Airline Transfer', value: 'Airline Transfer'},
  { label: 'Damaged', value: 'Damaged'},
  { label: 'Lost', value: 'Lost'},
  { label: 'Missing', value: 'Missing'},
  { label: 'Abandoned', value: 'Abandoned'},
  { label: 'Unclaimed', value: 'Unclaimed'},
  { label: 'Biosecurity Destruction', value: 'Biosecurity Destruction'},
  { label: 'Destruction', value: 'Destruction'},
  { label: 'Partial Disposal', value: 'Partial Disposal'},
  { label: 'Biosecurity Control', value: 'Biosecurity Control'},
  { label: 'Re-Export', value: 'Re-Export'},
  { label: 'Tran-Shipment', value: 'Tran-Shipment'},
];

export default class UpdateStatusAirway extends Component {
  input = React.createRef();
  state = {
    deviceADcode: '',
    airwayBill: '',
    nfcserialno: '',
    trackingstatus: '',
    userId: '',
    comment: '',
    modal: false,
    nfcADcode: '',
    tags: [],
    loading: true,
    customErr: '',
    requestAccess: false,
    byEmail: true,
    bySMS: false,
    smsComment: '',
  }

  addClick = (array) => {
    array.insert(1, {
      nfcADcode: ''
    })
  }

  async componentDidMount() {
    callActivityApi({ 'pageName': 'Update Status(MAWB)', 'description': 'User has visited Update status Screen' });
    const historyPush = this.props.history.push
    phoneNoVerifyCheck(historyPush)
    const deviceADcode = await localStorage.getItem('adCodes');
    this.setState({ deviceADcode })
    const userId = await localStorage.getItem('userId');
    this.setState({ userId })
    var userType = await localStorage.getItem('accounttypeUsers');
    
    if (userType !== 'Admin') {
        checkScreenAccess(
            {
              'userId' : userId,
              'screenType' : 'Update Status Airway'
            }
          ).then((response) => {
            this.setState({loading: false, requestAccess: true});
          }).catch(err => {
            this.setState({loading: false, requestAccess: false});
          });
    } else {
        this.setState({ requestAccess : true, loading: false})
    }
  }

  submitHandler = (values) => {
    console.log(values);
    if (!this.state.customErr){
      this.setState({loading : true});
      const data = {
        airwayBill: values.airwayBill,
        trackingstatus: values.trackingstatus,
        comment: values.comment,
        userId: this.state.userId,
        byEmail: this.state.byEmail,
        bySms: values.bySMS,
        smsComment: values.smsComment,
        nfcADcode: this.state.deviceADcode,
        deviceADcode: this.state.deviceADcode,
      };
      updateStatusAirway(data)
        .then(Response => {
          toast.success(Response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
          this.props.history.push('/admin/index')
        }).catch(err => {
          this.setState({loading: false});
          let message = err.message;
          if (err.response && err.response.data.message) {
            message = err.response.data.message;
          }
          toast.error(message, {
            position: toast.POSITION.TOP_RIGHT
          });
        })
    }
  }

  sendRequestAccess  = async () => {
    this.setState({loading: true});
    const info = await localStorage.getItem('auth');
    const sendInfo = JSON.parse(info);    
    requestAccess({
      'username': sendInfo.username,
      'firstName' : sendInfo.firstName,
      'lastName': sendInfo.lastName,
      'accounttypeUsers' : sendInfo.accounttypeUsers,
      'businessname': sendInfo.businessname,
      'ADCode' : sendInfo.ADCode,
      'address': sendInfo.address,
      'email' : sendInfo.email,
      'phoneNumber' : sendInfo.phonenumber,
      'userId' : sendInfo.id,
      'screenType': 'Update Status Airway',
    }).then(Response => {
		  this.setState({loading: false});
      toast.success(Response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }).catch(err => {
      this.setState({loading: false});
      let message = err.message;
      if (err.response && err.response.data.message) {
        message = err.response.data.message;
      }
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })
  };

  render() {
    const { scan, requestAccess } = this.state;
    return (
      <div>
        {!requestAccess?
          <div className="justify-content-center pt-2 main-contain-title">
            <>
              <p>This feature is restricted.</p>
              <Button onClick = {() => this.sendRequestAccess()}
                disabled={this.state.loading}>Request Access for free</Button>
            </>
          </div>
        :
        <>
          <div className="d-flex justify-content-center scan-parcel-loader">
          <ClipLoader
            size={50}
            color={"#123abc"}
            loading={this.state.loading}
          />
        </div>
        <Formik
          // enableReinitialize
          initialValues={{
            airwayBill: this.state.airwayBill,
            trackingstatus: this.state.trackingstatus,
            comment: this.state.comment,
            smsComment: this.state.smsComment,
            bySMS: this.state.bySMS,
          }}
          validationSchema={Schema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            this.setErrors = setErrors;
            this.submitHandler(values);
          }}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            status,
            isSubmitting, }) => (
              <Fragment>
                <div className={this.state.loading ? "mt-2 style loadingDiv" : "mt-2 style"}>
                  <Card>
                    <CardHeader className="c-header">Update Tracking Numbers using Master Number</CardHeader>
                    <CardBody>
                       <div className="col-12">                                           
                          <FormGroup>
                            <Label>Master Airwaybill</Label>
                            <Input 
                              type="text"
                              invalid={errors.airwayBill && touched.airwayBill}
                              name="airwayBill" 
                              onChange={handleChange} 
                              onBlur={handleBlur} 
                              value={values.airwayBill} 
                              placeholder="Enter Master Airwaybill" />
                            <FormFeedback>
                              {errors.airwayBill && touched.airwayBill && errors.airwayBill}
                            </FormFeedback>
                          </FormGroup>
                        </div>
                        <div className="col-12">            
                            <FormGroup>
                                <Label> Status</Label>
                                <Select
                                    name="trackingstatus"
                                    invalid={errors.trackingstatus && touched.trackingstatus}
                                    onChange={(option) => setFieldValue('trackingstatus', option.value)}
                                    onBlur={handleBlur}
                                    className="is-invalid"
                                    value={categoryOption.filter(option => values.trackingstatus === option.value)}
                                    options={categoryOption}
                                />
                                <FormFeedback>
                                    {errors.trackingstatus && touched.trackingstatus && errors.trackingstatus}
                                </FormFeedback>
                            </FormGroup>
                        </div>

                        <div className="col-12">
                          <FormGroup>
                            <Label>Comment</Label>
                            <Input type="textarea"
                              invalid={errors.comment && touched.comment}
                              name="comment" 
                              onChange={handleChange} 
                              onBlur={handleBlur} 
                              value={values.comment}
                              placeholder="Enter comment" />
                            <FormFeedback>
                              {errors.comment && touched.comment && errors.comment}
                            </FormFeedback>
                          </FormGroup>
							          </div>

                            <div className="col-12 pt-3">
                                <Label>Select option for distributing message</Label>
                                <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" checked placeholder="" onChange={() => this.setState({byEmail: true})} />{' '}
                                    By Email
                                </Label>
                                </FormGroup>
                                <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" placeholder="" onChange={() => setFieldValue('bySMS', !values.bySMS)} />{' '}
                                    By Message
                                </Label>
                                </FormGroup>
                            </div>


                            {values.bySMS ? <div className="col-12">
                              <FormGroup>
                                <Label >Comment For SMS</Label>
                                <Input type="textarea"
                                  invalid={errors.smsComment && touched.smsComment}
                                  name="smsComment" onChange={handleChange} onBlur={handleBlur} value={values.smsComment} placeholder="Enter comments for SMS" />
                                <FormFeedback>
                                  {errors.smsComment && touched.smsComment && errors.smsComment}
                                </FormFeedback>
                              </FormGroup>
                             </div> : null}

                            
                        
                        <div className="d-flex justify-content-center">
                          <button className="btn btn-submit" onClick={handleSubmit} >Submit</button>
                        </div>
                    </CardBody>
                  </Card>
                </div>
              </Fragment>

            )}
        </Formik>
        </>
      }
      </div>
    )
  }
}