import React, { Component } from 'react'
import './../HomePage.css'
import './Freight.css'
import roadFreightBg from './../../assets/slide-4.webp'
import roadFreightImg from './../../assets/errands-bg.webp'
import PartnersAndTestimonialSection from './../components/PartnersAndTestimonialsSection'
import Footer from './../components/Footer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Navbars from '../../Navbar/Navbar';
import SpecialServicesCarousel from '../components/SpecialServicesCarousel'
import AppSection from '../components/AppSection'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'


export default class RoadFreight extends Component {
    render() {
        const slides = [
            { src: roadFreightBg, alt: 'First slide' },
        ]
        return (
            <>
                <Helmet>
                    <title>Road Freight Services - Shipment & Delivery | Postkodes</title>
                    <meta name="description" content="Postkodes offers top-quality road freight services with reliable delivery and competitive pricing. Trust us to deliver your goods safely and on time." />
                </Helmet>
                <div className='home-page-container'>
                    <div className="register-track-section">
                        <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
                            <div className="carousel-inner">
                                {slides.map((slide, index) => (
                                    <div key={`Home-${slide.alt}`} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                        <img className="d-block w-100 freight-bg-style img-fluid" src={slide.src} alt={slide.alt} />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="freight-layer">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Navbars />
                                    </div>
                                    <div className="col-12">
                                        <div className='freight-banner-content d-flex justify-content-center'>
                                            <h1>POSTKODES: Comprehensive Road Freight Services</h1>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className='freight-description-section'>
                        <div class="container my-5">
                            <div class="row">
                                <div class="text-left freight-intro col-md-8">
                                    <p>POSTKODES offers reliable and efficient road freight services, connecting your goods to destinations across the country and beyond. Whether you’re transporting goods locally or across borders, our road freight logistics solutions are designed to meet your needs with precision and care.</p>
                                    <h2 class="mb-5">
                                        Our Road Freight Services Include:
                                    </h2>
                                    <ul>
                                        <li>
                                            <h3>Full Truckload (FTL) Shipping:</h3> <p>Ideal for large shipments, our FTL service offers dedicated road freight transport, ensuring your cargo is the sole occupant of the truck, providing direct delivery with maximum efficiency.</p>
                                        </li>
                                        <li>
                                            <h3>Less-than-Truckload (LTL) Shipping:</h3> <p>Perfect for smaller shipments, our LTL service allows you to share truck space with other customers, optimizing costs while maintaining reliable delivery schedules.</p>
                                        </li>
                                        <li>
                                            <h3>Domestic and Cross-Border Transport:</h3> <p>We offer both domestic and international road freight shipping, seamlessly transporting your goods across regions and countries with expert handling at every stage.</p>
                                        </li>
                                        <li>
                                            <h3>Customizable Solutions:</h3><p> From temperature-controlled transport to specialized equipment, our road freight logistics are tailored to meet the unique requirements of your cargo.</p>
                                        </li>
                                        <li>
                                            <h3>Real-Time Road Freight Tracking:</h3> <p> Stay informed with our advanced tracking system, providing real-time updates on the status and location of your shipment throughout the journey.</p>
                                        </li>
                                        <li>
                                            <h3>Door-to-Door Delivery:</h3> <p> Our comprehensive road freight services include end-to-end solutions, picking up your cargo at the origin and delivering it directly to its final destination.</p>
                                        </li>
                                    </ul>
                                </div>
                                <img className="mx-md-0 mx-3" width={300} height={200} src={roadFreightImg} alt="roadFreightImg" />
                            </div>

                            <div class="mb-5 freight-benefits">
                                <h2 class="mb-3">Why Choose POSTKODES for Road Freight Shipping?</h2>
                                <p>At POSTKODES, we’re committed to providing top-notch road freight services that you can depend on. With our expertise, extensive network, and dedication to customer satisfaction, we make road freight shipping smooth, efficient, and reliable every time.</p>
                                <ul class="">
                                    <li class="mb-2"><strong>Extensive Network:</strong><br /> Our wide-reaching network of carriers and partners ensures that we can provide road freight transport to even the most remote locations, both domestically and internationally.</li>
                                    <li class="mb-2"><strong>Expertise in Logistics:<br /></strong>  Our team of logistics experts has the experience and knowledge to handle all aspects of road freight shipping, from route planning to compliance with local regulations.</li>
                                    <li class="mb-2"><strong>Reliable & Timely Deliveries:<br /></strong>We prioritize punctuality, ensuring that your goods arrive on time with our efficient road freight logistics solutions.</li>
                                    <li class="mb-2"><strong>Cost-Effective Solutions:<br /></strong> We offer competitive pricing options, giving you the best value for your road freight needs without compromising on service quality.</li>
                                    <li class="mb-2"><strong>Safety & Security:<br /></strong> We implement stringent safety measures to protect your cargo during transit, ensuring it arrives in the same condition it was sent.</li>
                                </ul>
                            </div>
                        </div>
                    </section>

                    <section className='faq-section p-0'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h2>Frequently Asked Questions</h2>
                                    <div id="accordion">
                                        <div class="card">
                                            <div class="card-header" id="headingOne">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                        What are road freight services?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>

                                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                                <div class="card-body">
                                                    Road freight services, offered by Postkodes, refer to the transportation of goods via road using trucks or other vehicles. It is a cost-effective and efficient method for transporting goods across short and long distances.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header" id="headingTwo">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        How does road freight shipping work?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                                <div class="card-body">
                                                    Road freight shipping with Postkodes involves loading goods onto trucks, which then transport them to their destination via road networks. The process includes pickup, transportation, and delivery, often with real-time tracking available through our systems.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header" id="headingThree">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        What are the benefits of road freight transport?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                                <div class="card-body">
                                                    Road freight transport offers flexibility, door-to-door delivery, and cost-effectiveness. Postkodes ensures that it's ideal for both small parcels and large shipments, with the ability to reach remote areas.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header" id="heading5">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                                                        How can I track my road freight shipment?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse5" class="collapse" aria-labelledby="heading5" data-parent="#accordion">
                                                <div class="card-body">
                                                    At Postkodes, we provide a road freight tracking system that allows you to monitor your shipment in real-time. You'll receive updates and notifications about the status of your delivery.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header" id="heading6">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                                                        What types of goods can be transported by road freight?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse6" class="collapse" aria-labelledby="heading6" data-parent="#accordion">
                                                <div class="card-body">
                                                    Almost any type of goods can be transported by road freight, including perishable items, industrial equipment, consumer goods, and more. Postkodes offers services that are customizable to meet the specific needs of your shipment.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header" id="heading7">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                                                        How do I choose the right road freight logistics provider?
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapse7" class="collapse" aria-labelledby="heading7" data-parent="#accordion">
                                                <div class="card-body">
                                                    When selecting a road freight logistics provider, consider their experience, network coverage, technology for tracking, and ability to offer customized solutions. Postkodes excels in all these areas, making us a reliable choice for your freight needs.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <SpecialServicesCarousel />

                    <section className="reach-destination-section">
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-8'>
                                    <h2>Innovative Technology</h2>
                                    <p>Leveraging the latest technologies, we continuously innovate to stay ahead of the curve and provide our clients with state-of-the-art logistics solutions.</p>
                                </div>
                                <div className='col-md-4 d-flex align-items-center justify-content-centerd'>
                                    <Link to='/verifyEmail'><button className="btn btn-danger">Register Now &rarr;</button></Link>
                                </div>
                            </div>
                        </div>
                    </section>

                    <PartnersAndTestimonialSection />

                    <AppSection />

                </div >
                <Footer />
            </>
        )
    }
}   
