import React, { Component, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print';
import PrintForm from './PrintForm';
import PrintForm1 from './PrintForm1';
import PrintForm2 from './PrintForm2';
const tabStyle = {
  height: 800,
  maxHeight: 1000,
  overflowY: "scroll"
  //backgroundColor: "blue"
};
export default function Print(props) {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div style={tabStyle}>
      {props.size === '150*100mm' &&
        <PrintForm ref={componentRef} format={props.nfcADcode} />
      }

      {props.size === '99.1*67.7mm' &&
        <PrintForm1 ref={componentRef} format={props.nfcADcode} pageStyle={`@media print{
                    @page size2{
                      size: 99.1mm 67.7mm;
                      margin: 0;
                    }
                  }`} />
      }
      {props.size === '64*24.3mm' &&
        <PrintForm2 ref={componentRef} format={props.nfcADcode} pageStyle={`@media print{
                    @page size3{
                      size: 64mm 24.3mm ;
                      margin: 0;
                    }
                  }`} />
      }
      <div className="d-flex justify-content-center" id="no-print" >
        <button className="btn btn-submit ml-2 mt-3" onClick={handlePrint}>Print this out!</button>
        <button className="btn btn-submit ml-2 mt-3" onClick={props.reset}>Print another</button>
      </div>
    </div >
  )
}
