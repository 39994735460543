import { saveUserActivity } from "../api/Form";
import { getLocation } from "./utils";


export const callActivityApi = async (data) => {
    // get current location
    const location = await getLocation();

    // get current stored user
    const userData = await localStorage.getItem('auth');
    const { id, ADCode } = JSON.parse(userData);

        saveUserActivity({
            userId: id,
            deviceAdcode: ADCode,
            pageName: data.pageName,
            description: data.description,
            location: location,
            system: 'web'
        }).then(response => {
            console.log('Activity saved successfully!!');
        }).catch(err => {
            console.log('Activity not saved!!');
        })
}