export function calculateCBM(length, width, height, quantity) {
    const divisor = 1000000 // static value
    // calculate cubic meters = cbm
    const cbm = ((length * width * height) / divisor) * quantity;
    return parseFloat(cbm.toFixed(2));
}

export function averageWeight(quantity, weight) {
    if (quantity === 0) return 0;

    const avgWeight = weight / quantity;
    return parseFloat(avgWeight.toFixed(2));
};

export function calculateCVW(mot, length, width, height, quantity, weight) {
    // mot = modeoftransport
    const divisors = {
        Air: 6000,
        Sea: 1000,
        default: 4000
    };

    const divisor = divisors[mot] || divisors.default;
    // cvw = Chargeable/Volumetric Weight
    const cvw = ((length * width * height) / divisor) * quantity;

    const result = weight > cvw ? weight : cvw;
    return parseFloat(result.toFixed(2));
};