import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css'
import React, { Component, Fragment } from 'react'
import { CardHeader, CardBody, FormGroup, Label, Input, Card, FormFeedback, Button } from 'reactstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ClipLoader } from "react-spinners";
import Select from 'react-select';
import { scanMultipleParcelsUpdated, validateRefNums, requestAccess, checkScreenAccess } from '../../api/Form';
import { getLocation, phoneNoVerifyCheck } from '../../utils/utils';
import { toast } from 'react-toastify';
import { callActivityApi } from '../../utils/saveUserActivity';

const Schema = Yup.object().shape({
  refno: Yup.string()
    .required('Ref No is required'),
  trackingstatus: Yup.string()
    .required('Tracking status is required'),
  comment: Yup.string().trim()
    .required('Comment is required'),
  bySMS: Yup.boolean(),
  smsComment: Yup
    .string()
    .when("bySMS", {
      is: true,
      then: Yup.string()
        .max(160, 'SMS comment must be exactly 160 digits')
        .required("SMS comment box is required")
    })
});

const categoryOption = [
  { label: 'Pickup', value: 'Pickup' },
  { label: 'Transfer', value: 'Transfer' },
  { label: 'Customs', value: 'Customs' },
  { label: 'Delivered', value: 'Delivered' },
  { label: 'Received', value: 'Received' },
  { label: 'Despatched', value: 'Despatched' },
  { label: 'Sorting Facility', value: 'Sorting Facility' },
  { label: 'Onboard to Delivery Address', value: 'Onboard to Delivery Address' },
  { label: 'Held at Customs', value: 'Held at Customs' },
  { label: 'Custom Released', value: 'Custom Released' },
  { label: 'Delivery Missed', value: 'Delivery Missed' },
  { label: 'Delivery Exception', value: 'Delivery Exception' },
  { label: 'Intransit', value: 'Intransit' },
  { label: 'Custom Cleared', value: 'Custom Cleared' },
  { label: 'Airport Transfer', value: 'Airport Transfer' },
  { label: 'Destination Airport', value: 'Destination Airport' },
  { label: 'Onforwarded', value: 'Onforwarded' },
  { label: 'Arrived', value: 'Arrived' },
  { label: 'Departed', value: 'Departed' },
  { label: 'Custom Inspection', value: 'Custom Inspection' },
  { label: 'Collect', value: 'Collect' },
  { label: 'Collected', value: 'Collected' },
  { label: 'Accepted', value: 'Accepted' },
  { label: 'Warehouse Received', value: 'Warehouse Received' },
  { label: 'Outbound Staged', value: 'Outbound Staged' },
  { label: 'Biosecurity Inspection', value: 'Biosecurity Inspection' },
  { label: 'Cancelled', value: 'Cancelled' },
  { label: 'Withdrawn', value: 'Withdrawn' },
  { label: 'Airline Transfer', value: 'Airline Transfer' },
  { label: 'Damaged', value: 'Damaged' },
  { label: 'Lost', value: 'Lost' },
  { label: 'Missing', value: 'Missing' },
  { label: 'Abandoned', value: 'Abandoned' },
  { label: 'Unclaimed', value: 'Unclaimed' },
  { label: 'Biosecurity Destruction', value: 'Biosecurity Destruction' },
  { label: 'Destruction', value: 'Destruction' },
  { label: 'Partial Disposal', value: 'Partial Disposal' },
  { label: 'Biosecurity Control', value: 'Biosecurity Control' },
  { label: 'Re-Export', value: 'Re-Export' },
  { label: 'Tran-Shipment', value: 'Tran-Shipment' },
];

export default class ScanMultipleParcels extends Component {
  input = React.createRef();
  state = {
    deviceADcode: '',
    location: '',
    scan: [],
    nfcserialno: '',
    trackingstatus: '',
    userId: '',
    comment: '',
    SR: '',
    refno: '',
    modal: false,
    nfcADcode: '',
    tags: [],
    errorTags: [],
    editTags: [],
    editActiveValue: '',
    loading: true,
    customErr: '',
    requestAccess: false,
    byEmail: true,
    bySMS: false,
    smsComment: '',
  }

  addClick = (array) => {
    array.insert(1, {
      nfcADcode: ''
    })
  }

  async componentDidMount() {
    callActivityApi({ 'pageName': 'Multiple Scan Parcel', 'description': 'User has visited Multiple Scan Screen' });
    const data = this.props.history.push
    phoneNoVerifyCheck(data)
    const deviceADcode = await localStorage.getItem('adCodes');
    this.setState({ deviceADcode })
    const userId = await localStorage.getItem('userId');
    this.setState({ userId })
    getLocation().then((location) => this.setState({ location }));
    var userType = await localStorage.getItem('accounttypeUsers');
    if (userType !== 'Admin') {
      checkScreenAccess(
        {
          'userId': userId,
          'screenType': 'Scan Multiple Parcel'
        }
      ).then((response) => {
        this.setState({ loading: false, requestAccess: true });
      }).catch(err => {
        this.setState({ loading: false, requestAccess: false });
      });
    } else {
      this.setState({ requestAccess: true, loading: false })
    }
  }

  handleTags = (tags, setFieldValue) => {
    this.setState({ loading: true });
    //call api to check here info
    let data = {
      ref_nums: tags
    };
    validateRefNums(data)
      .then(Response => {
        //console.log(Response);
        this.setState({ loading: false, tags: tags, customErr: '' });
        setFieldValue('refno', tags.toString());
      }).catch(err => {
        this.setState({ loading: false });
        let message = err.message;
        if (err.response && err.response.data.message) {
          message = err.response.data.message;
        }
        this.setState({ errorTags: (err.response.data.data === null ? [] : err.response.data.data), tags: tags, customErr: message });
        setFieldValue('refno', tags.toString());
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT
        });
      });

  };
  checkEdit = (key) => {
    let editTags = [];
    editTags.push(this.state.tags[key]);
    this.setState({ editTags, editActiveValue: this.state.tags[key] });
  };

  handleEdit = (value, key) => {
    // console.log(value);
    let tags = this.state.tags;
    tags[key] = value;
    this.setState({ loading: true, tags, editActiveValue: '', editTags: [] });
    //call api to check here info
    let data = {
      ref_nums: tags,
    };
    validateRefNums(data)
      .then(Response => {
        //console.log(Response);
        this.setState({ loading: false, tags: tags, customErr: '' });
        //setFieldValue('refno', tags.toString());
      }).catch(err => {
        this.setState({ loading: false });
        let message = err.message;
        if (err.response && err.response.data.message) {
          message = err.response.data.message;
        }
        this.setState({ errorTags: (err.response.data.data === null ? [] : err.response.data.data), tags: tags, customErr: message });
        //setFieldValue('refno', tags.toString());
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  };

  pasteSplit = (data) => {
    const separators = [',', ';', '\\(', '\\)', '\\*', '/', ':', '\\?', '\n', '\r', 'g'];
    return data.split(new RegExp(separators.join('|'))).map(d => d.trim());
  };
  renderTags = (props) => {
    let { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props;
    let classvar = { ...other };
    if (this.state.errorTags.includes(getTagDisplayValue(tag))) {
      classvar.className = "react-tagsinput-tag error";
    }
    if (this.state.editTags.includes(getTagDisplayValue(tag))) {
      return (
        <Input
          name={'edit' + key}
          onChange={(tags) => {
            this.setState({ editActiveValue: tags.target.value });
          }}
          onKeyPress={event => {
            if (event.key === 'Enter') {
              this.handleEdit(event.target.value, key);
            }
          }}
          className={'editTag'}
          value={this.state.editActiveValue}
        />
      )
    } else {
      return (
        <span key={key} {...classvar} >
          <span onClick={() => this.checkEdit(key)}> {getTagDisplayValue(tag)}</span>
          {!disabled &&
            <a className={classNameRemove} onClick={(e) => onRemove(key)} />
          }
        </span>
      )
    }
  };
  submitHandler = async (values) => {
    if (!this.state.customErr) {
      // check acces
      var userType = await localStorage.getItem('accounttypeUsers');
      if (userType !== 'Admin') {
        checkScreenAccess(
          {
            'userId': this.state.userId,
            'screenType': 'Scan Multiple Parcel'
          }
        ).then((response) => {
          this.saveData(values);
        }).catch(err => {
          this.setState({ loading: false, requestAccess: false });
        });
      } else {
        this.saveData(values);
      }
    }
  }

  saveData = (values) => {
    this.setState({ loading: true });
    const data = {
      trackingstatus: values.trackingstatus,
      comment: values.comment,
      ref_nums: this.state.tags,
      nfcADcode: this.state.nfcADcode,
      deviceADcode: this.state.deviceADcode,
      userId: this.state.userId,
      bySms: values.bySMS,
      smsComment: values.smsComment,
      location: this.state.location
    };
    scanMultipleParcelsUpdated(data)
      .then(Response => {
        toast.success(Response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        this.props.history.push('/admin/index')
      }).catch(err => {
        console.log(err.response, 'errr');
        this.setState({ loading: false, tags: [] });
        let message = err.message;
        if (err.response && err.response.data.message) {
          message = err.response.data.message;
        }
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT
        });
      })
  }
  // The request will come as an email notification to support@addressdynamic.com. Including the username, first name, last name, account type, business name, AD Code, address, email and phone number of the user who is making the request.
  sendRequestAccess = async () => {
    this.setState({ loading: true });
    const info = await localStorage.getItem('auth');
    const sendInfo = JSON.parse(info);

    // username, first name, last name, account type, business name, AD Code, address, email and phone number
    requestAccess({
      'username': sendInfo.username,
      'firstName': sendInfo.firstName,
      'lastName': sendInfo.lastName,
      'accounttypeUsers': sendInfo.accounttypeUsers,
      'businessname': sendInfo.businessname,
      'ADCode': sendInfo.ADCode,
      'address': sendInfo.address,
      'email': sendInfo.email,
      'phoneNumber': sendInfo.phonenumber,
      'userId': sendInfo.id,
      'screenType': 'Scan Multiple Parcel',
    }).then(Response => {
      this.setState({ loading: false });
      toast.success(Response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }).catch(err => {
      this.setState({ loading: false });
      let message = err.message;
      if (err.response && err.response.data.message) {
        message = err.response.data.message;
      }
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
    })

  };
  nfcADcodeHandler = () => {
    this.setState({ modal: !this.state.modal })
  }
  defaultRenderInput = (props) => {
    let { onChange, value, addTag, ...other } = props
    return (
      <input type='text' onChange={(e) => { onChange(e); this.setState({ editTags: [] }) }} value={value} {...other} />
    )
  }

  render() {
    const { scan, requestAccess } = this.state;
    return (
      <div>
        {!requestAccess ?
          <div className="justify-content-center pt-2 main-contain-title">
            <>
              <p>This feature is restricted.</p>
              <Button onClick={() => this.sendRequestAccess()}
                disabled={this.state.loading}>Request Access for free</Button>
            </>
          </div>
          :
          <>
            <div className="d-flex justify-content-center scan-parcel-loader">
              <ClipLoader
                size={50}
                color={"#123abc"}
                loading={this.state.loading}
              />
            </div>
            <Formik
              // enableReinitialize
              initialValues={{
                SR: this.state.SR,
                location: this.state.location,
                package: this.state.package,
                trackingstatus: this.state.trackingstatus,
                comment: this.state.comment,
                tags: this.state.tags,
                refno: this.state.refno,
                smsComment: this.state.smsComment,
                bySMS: this.state.bySMS,
              }}
              validationSchema={Schema}
              onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
                this.setErrors = setErrors;
                this.submitHandler(values);
                resetForm({ values: '' })
              }}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                handleBlur,
                setFieldValue,
                status,
                isSubmitting, }) => (
                <Fragment>
                  <div className={this.state.loading ? "mt-2 style loadingDiv" : "mt-2 style"}>
                    <Card>
                      <CardHeader className="c-header">Update Multiple Parcels</CardHeader>
                      <CardBody>
                        <div className="col-12">
                          <FormGroup>
                            <Label>Ref No</Label>
                            <TagsInput
                              value={this.state.tags}
                              onChange={(tags) => this.handleTags(tags, setFieldValue)}
                              renderInput={this.defaultRenderInput}
                              pasteSplit={this.pasteSplit}
                              addOnPaste={true}
                              onlyUnique={true}
                              renderTag={this.renderTags}
                              addKeys={[9, 13, 188]}
                              inputProps={{
                                className: 'react-tagsinput-input',
                                placeholder: ''
                              }}
                            />
                            <Input
                              type="hidden"
                              invalid={(errors.refno && touched.refno) || this.state.customErr}
                              name="refno"
                              onBlur={handleBlur}
                              value={values.refno}
                            // onClick={this.nfcADcodeHandler}
                            />
                            <FormFeedback>
                              {(errors.refno && touched.refno && errors.refno) || this.state.customErr}
                            </FormFeedback>
                          </FormGroup>
                        </div>
                        <div className="col-12">
                          <FormGroup>
                            <Label >Status</Label>
                            <Select
                              name="trackingstatus"
                              onChange={(option) => setFieldValue('trackingstatus', option.value)}
                              onBlur={handleBlur}
                              className="is-invalid"
                              value={categoryOption.filter(option => values.trackingstatus === option.value)}
                              options={categoryOption}
                            />
                            <FormFeedback>
                              {errors.trackingstatus && touched.trackingstatus && errors.trackingstatus}
                            </FormFeedback>
                          </FormGroup>
                        </div>

                        {values.trackingstatus === 'Delivered' && this.state.type === 'SR' &&
                          <>
                            <div className="col-12">
                              <FormGroup>
                                <Label >SR pin</Label>
                                <Input type="text"
                                  invalid={errors.SR && touched.SR}
                                  name="SR" onChange={handleChange} onBlur={handleBlur} value={values.SR} placeholder="Enter SR" />
                                <FormFeedback>
                                  {errors.SR && touched.SR && errors.SR}


                                </FormFeedback>
                              </FormGroup>
                            </div>
                            <div className="col-12">
                              <FormGroup>
                                <Label >Package</Label>
                                <Input type="text"
                                  invalid={errors.package && touched.package}
                                  name="package" onChange={handleChange} onBlur={handleBlur} value={values.package} placeholder="Enter package" />
                                <FormFeedback>
                                  {errors.package && touched.package && errors.package}
                                </FormFeedback>
                              </FormGroup>
                            </div>
                          </>
                        }
                        <div className="col-12">
                          <FormGroup>
                            <Label >Comment</Label>
                            <Input type="textarea"
                              invalid={errors.comment && touched.comment}
                              name="comment" onChange={handleChange} onBlur={handleBlur} value={values.comment} placeholder="Enter comments" />
                            <FormFeedback>
                              {errors.comment && touched.comment && errors.comment}
                            </FormFeedback>
                          </FormGroup>
                        </div>
                        <div className="col-12 pt-3">
                          <Label>Select option for distributing message</Label>
                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" placeholder="" checked onChange={() => this.setState({ byEmail: true })} />{' '}
                              By Email
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                name="bySMS"
                                invalid={errors.bySMS && touched.bySMS}

                                placeholder=""
                                onChange={() => setFieldValue('bySMS', !values.bySMS)} />{' '}
                              By Message
                            </Label>
                          </FormGroup>
                        </div>
                        {values.bySMS ? <div className="col-12">
                          <FormGroup>
                            <Label >Comment For SMS</Label>
                            <Input type="textarea"
                              invalid={errors.smsComment && touched.smsComment}
                              name="smsComment" onChange={handleChange} onBlur={handleBlur} value={values.smsComment} placeholder="Enter comments for SMS" />
                            <FormFeedback>
                              {errors.smsComment && touched.smsComment && errors.smsComment}
                            </FormFeedback>
                          </FormGroup>
                        </div> : null}
                        <div className="d-flex justify-content-center">
                          <button className="btn btn-submit" onClick={handleSubmit} >Submit</button>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </Fragment>

              )}
            </Formik>
          </>
        }
      </div>
    )
  }
}
