import { devDetails, liveDetails } from "../QBConfig";
const mode = process.env.REACT_APP_MODE;
const config = mode == "live" ? liveDetails : devDetails;

export function createSession() {
  return new Promise((resolve, reject) => {
    window.QB.createSession(function (err, result) {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  });
}

export function login(user) {
  return new Promise((resolve, reject) => {
    window.QB.createSession({ login: user.username, password: "123456789" }, function (err, result) {
      if (err) {
        reject(err);
      } else {
        console.log(result);
        resolve(result);
      }
    });
  });
}

export function logout() {
  return new Promise((resolve, reject) => {
    window.QB.logout(function (err, result) {
      if (err) {
        reject(err);
      } else {
        console.log(result);
        resolve(result);
      }
    });
  });
}

// export function release() {
// 	return new Promise((resolve, reject) => {
// 		window.QB.release(function (err, result) {
// 			if (err) {
// 				reject(err);
// 			} else {
// 				console.log(result);
// 				resolve(result);
// 			}
// 		});
// 	});
// }

export function connect(userId) {
  return new Promise((resolve, reject) => {
    window.QB.chat.connect({ userId, password: "123456789" }, function (err, result) {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  });
}

export function disconnect() {
  return new Promise((resolve, reject) => {
    window.QB.chat.disconnect({}, function (err, result) {
      if (err) {
        reject(err);
      } else {
        console.log(result);
        resolve(result);
      }
    });
  });
}

export function createUser(user) {
  return new Promise((resolve, reject) => {
    window.QB.users.create(
      {
        email: user.email,
        login: user.username,
        fullName: user.username,
        password: "123456789",
      },
      function (err, result) {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      }
    );
  });
}

export function getUsers(userId) {
  return new Promise((resolve, reject) => {
    window.QB.users.listUsers({ userId }, function (err, result) {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  });
}

// console.log(config, 'config')
var CONFIG = { debug: true };

export function init() {
  window.QB.init(config.appId, config.authKey, config.authSecret, config.accountKey, CONFIG, {
    webrtc: {
      answerTimeInterval: 30,
      dialingTimeInterval: 5,
      disconnectTimeInterval: 35,
      statsReportTimeInterval: 5,
      // incomingLimit: 1,
    },
  });
}
