import 'react-tagsinput/react-tagsinput.css'
import React, { Component, Fragment } from 'react'
import { CardHeader, CardBody, FormGroup, Label, Input, Card, FormFeedback, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import * as Yup from 'yup';
import { Formik, Field, Form, FieldArray } from "formik";
import { ClipLoader } from "react-spinners";
import 'react-phone-number-input/style.css'
import { saveExternalRefNumsUnassign, checkScreenAccess, requestAccess, validateExternalRefNum, userPreferences } from '../../api/Form';
import { toast } from 'react-toastify';
import { callActivityApi } from '../../utils/saveUserActivity';
import { phoneNoVerifyCheck } from '../../utils/utils';
import PhoneInput from 'react-intl-tel-input';
import { isValidNumber } from 'libphonenumber-js';
import 'react-intl-tel-input/dist/main.css';

const Schema = Yup.object().shape({
  inputList: Yup.array().of(
    Yup.object().shape({
      externalRefNo: Yup.string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          "No special characters"
        )
        .required('External Tracking Number is required'),
      externalCourierName: Yup.string()
        .trim()
        .required('External Courier Name is required'),
      emailAddress1: Yup.string().email('Invalid email')
        .trim(),
      emailAddress2: Yup.string().email('Invalid email address')
        .trim()
    })
  ),
});

const websitesOption = [
  { 'label': 'https://auspost.com.au/', 'value': 'https://auspost.com.au/' },
  { 'label': 'https://www.qrcargo.com/', 'value': 'https://www.qrcargo.com/' },
  { 'label': 'https://trackingmore.com/', 'value': 'https://trackingmore.com/' }
];

export default class UnAssignExternalTracking extends Component {
  input = React.createRef();
  state = {
    deviceADcode: '',
    userId: '',
    nfcADcode: '',
    loading: true,
    errorList: [],
    inputList: [
      {
        internalRefno: 'default',
        externalRefNo: '',
        externalCourierName: '',
        externalCourierWebsite: 'https://trackingmore.com/',
        emailAddress1: '',
        emailAddress2: '',
        phoneNumber1: '',
        phoneNumber2: '',
        collectedEnable: false,
      }
    ],
    isValidPhoneNo: true
  }

  addClick = (array) => {
    array.insert(1, {
      nfcADcode: ''
    })
  }

  async componentDidMount() {
    callActivityApi({ 'pageName': 'UnAssigned External Number', 'description': 'User has visited UnAssigned external Screen' });
    const historyPush = this.props.history.push
		phoneNoVerifyCheck(historyPush)
    const deviceADcode = await localStorage.getItem('adCodes');
    this.setState({ deviceADcode })
    const userId = await localStorage.getItem('userId');
    this.setState({ userId })
    var userType = await localStorage.getItem('accounttypeUsers');

    if (userType !== 'Admin') {
      checkScreenAccess(
        {
          'userId': userId,
          'screenType': 'UnAssign External Tracking Number'
        }
      ).then((response) => {
        this.checkUserPrefrences();
      }).catch(err => {
        this.setState({ loading: false, requestAccess: false });
      });
    } else {
      this.setState({ requestAccess: true, loading: false })
    }
  }

  checkUserPrefrences = async() => {
    const info = await localStorage.getItem('auth');
		const sendInfo = JSON.parse(info);
		userPreferences({
			userAdCode: sendInfo.ADCode,
			userId: sendInfo.id
		}).then(Response => {
			this.setState({ loading: false })
			if (Response.data.data.length) {
        this.setState({ loading: false, requestAccess: true });
			}
		}).catch(err => {
			this.setState({ loading: false })
      this.props.history.push('/admin/userSettings');
		})
  }

  checkIfArrayIsUnique(myArray) {

    // Better:
    const arrKeys = myArray.map(el => el.internalRefno);
    const arrExts = myArray.map(el => el.externalRefNo);
    let resInternal = [];
    for (var i = 0; i < arrKeys.length; i++) {
      let indexV = {};
      if (arrKeys.indexOf(arrKeys[i]) !== arrKeys.lastIndexOf(arrKeys[i])) {
        indexV.internalRefno = 'Already exists';
      }
      if (arrExts.indexOf(arrExts[i]) !== arrExts.lastIndexOf(arrExts[i])) {
        indexV.externalRefno = 'Already exists';
      }

      resInternal.push(indexV);


    }

    return resInternal;   // this means not unique
  }

  submitHandler = (values, setErrors) => {
    this.setState({ loading: true });
    const uniqueValues = this.checkIfArrayIsUnique(values.inputList);
    let checkKeyPresenceInArray = key => uniqueValues.some(obj => Object.keys(obj).includes(key));
    const findExternal = checkKeyPresenceInArray('internalRefno');
    const findInternal = checkKeyPresenceInArray('externalRefno');
    if (uniqueValues.length <= 0 || (!findExternal && !findInternal)) {
      const data = {
        entries: values.inputList,
        deviceADcode: this.state.deviceADcode,
        userId: this.state.userId,
      };
      saveExternalRefNumsUnassign(data)
        .then(response => {
          toast.success('Tracking number are assigned successfully', {
            position: toast.POSITION.TOP_RIGHT
          });
          this.props.history.push('/admin/index')
        })
        .catch(err => {
          let message = err.message;
          if (err.response && err.response.data.message) {
            if (err.response.data.data) {
              this.setState({ errorList: err.response.data.data });
              message = err.response.data.message;
            } else {
              message = err.response.data.message;
            }
          }
          toast.error(message, {
            position: toast.POSITION.TOP_RIGHT
          });
          this.setState({ loading: false });
        })
    } else {
      this.setState({ errorList: uniqueValues, loading: false });
    }
  }

  addItems = (setFieldValue) => {
    var list = this.state.inputList;
    list = [
      ...list,
      {
        internalRefno: '',
        externalRefNo: '',
        externalCourierName: '',
        externalCourierWebsite: '',
      }
    ];
    this.setState(prevState => ({
      inputList: [
        ...prevState.inputList,
        {
          internalRefno: '',
          externalRefNo: '',
          externalCourierName: '',
          externalCourierWebsite: '',
        }
      ]
    }));
    setFieldValue('inputList', list);
  };

  handleRemoveClick = (index, setFieldValue) => {
    this.setState({ errorList: [] });
    const list = [...this.state.inputList];
    list.splice(index, 1);
    this.setState({ inputList: list });
    setFieldValue('inputList', list);
    setFieldValue(`x.${index}.internalRefno`, '');
    setFieldValue(`x.${index}.externalRefNo`, '');
    setFieldValue(`x.${index}.externalCourierName`, '');
    setFieldValue(`x.${index}.externalCourierWebsite`, '');

  };

  findDuplicates = (arr) => {
    let sorted_arr = arr.slice().sort(); // You can define the comparing function here. 
    // JS by default uses a crappy string compare.
    // (we use slice to clone the array so the
    // original array won't be modified)
    let results = [];
    let results2 = [];
    for (let i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1].internalRefno == sorted_arr[i].internalRefno) {
        results.push(i);
      }
      if (sorted_arr[i + 1].externalRefNo == sorted_arr[i].externalRefNo) {
        results2.push(i);
      }
    }
    let finalVals = { internalRef: results, externalRef: results2 }
    return finalVals;
  }


  printErrors = (obj) => {
    var string = '';

    for (var k in obj) {
      if (obj[k] instanceof Object) {
        this.printErrors(obj[k]);
      } else {

        if (string) {
          string += ', ' + obj[k];
        } else {
          string += obj[k];
        }

      };
    }
    return string;
  };

  handleToggleInputChange = (index, setFieldValue) => {
    var fieldName = 'collectedEnable';
    const list = [...this.state.inputList];
    list[index][fieldName] = !list[index][fieldName];
    this.setState({ inputList: list });
    setFieldValue('inputList', list);
  };

  handleInputChange = (index, e, name, setFieldValue) => {
    this.setState({ errorList: [] });
    var value;
    if (name == 'phoneNumber1' || name == 'phoneNumber2') {
      value = e.replace(/\s/g, '');
    } else {
      value = e.target.value;
    }

    const list = [...this.state.inputList];
    list[index][name] = value;
    this.setState({ inputList: list });
    setFieldValue('inputList', list);
  };

  handleSelectChange = (index, e, name, setFieldValue) => {
    this.setState({ errorList: [] });
    const value = e;
    const list = [...this.state.inputList];
    list[index][name] = value;
    this.setState({ inputList: list });
    setFieldValue('inputList', list);
  };

  sendRequestAccess = async () => {
    this.setState({loading: true});
    const info = await localStorage.getItem('auth');
    const sendInfo = JSON.parse(info);
    requestAccess({
      'username': sendInfo.username,
      'firstName': sendInfo.firstName,
      'lastName': sendInfo.lastName,
      'accounttypeUsers': sendInfo.accounttypeUsers,
      'businessname': sendInfo.businessname,
      'ADCode': sendInfo.ADCode,
      'address': sendInfo.address,
      'email': sendInfo.email,
      'phoneNumber': sendInfo.phonenumber,
      'userId': sendInfo.id,
      'screenType': 'UnAssign External Tracking Number',
    }).then(Response => {
		  this.setState({loading: false});
      toast.success(Response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }).catch(err => {
		  this.setState({loading: false});
      let message = err.message;
      if (err.response && err.response.data.message) {
        message = err.response.data.message;
      }
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
      this.setState({ loading: false });
    })
  };

  goToMultipleScreen = () => {
    this.props.history.push('/admin/multipleExternalUnassign');
  }

  handlePhoneNumberChange = (isEdited, phoneNumber, selectedCountry, setFieldValue, fieldName) => {
    const isValid = isValidNumber(phoneNumber, [selectedCountry.iso2.toUpperCase()])
    this.setState({ isValidPhoneNo: isValid });
    const number = `+${selectedCountry.dialCode}${phoneNumber}`
    setFieldValue(fieldName, number)
  };

  render() {
    const { requestAccess } = this.state;

    return (
      <div>
        {!requestAccess ?
          <div className="justify-content-center pt-2 main-contain-title">
            <>
              <p>This feature is restricted.</p>
              <Button onClick={() => this.sendRequestAccess()}
              disabled={this.state.loading}>Request Access for free</Button>
            </>
          </div>
          :
          <>
            <div className="d-flex justify-content-center scan-parcel-loader">
              <ClipLoader
                size={50}
                color={"#123abc"}
                loading={this.state.loading}
              />
            </div>
            <Formik
              // enableReinitialize
              initialValues={{
                inputList: this.state.inputList,
              }}
              validationSchema={Schema}
              onSubmit={(values, { setSubmitting, setErrors }) => {
                this.setErrors = setErrors;
                this.submitHandler(values, setErrors);
              }}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                handleBlur,
                setFieldValue,
                status,
                isSubmitting, }) => (
                <Fragment>
                  <div className={this.state.loading ? "mt-2 style loadingDiv" : "mt-2 style"}>
                    <Card>
                      <CardHeader className="c-header">
                        <div className="row ">
                          <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-10">
                            <span>Assign External Tracking Number(UnAssigned)</span>
                          </div>
                          <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2 ml-auto ml-md-auto pr-0 pr-sm-0 pr-md-0 ">
                            <a href="javascript:void(0)" onClick={() => this.goToMultipleScreen()}>Upload Bulk Numbers</a>
                          </div>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <FieldArray name="entries">
                          {() => (values.inputList.map((x, i) => {

                            const inputListErrors = errors.inputList?.length && errors.inputList[i] || {};
                            const inputListTouched = touched.inputList?.length && touched.inputList[i] || {};
                            return (
                              <div className="row">
                                <div className="col-lg-11 col-xs-12 col-sm-12">
                                  <div className="row">
                                    <div className="col-lg-6 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                      <FormGroup>
                                        <Label>External Tracking Number</Label>
                                        <Field
                                          className={'form-control' + ((inputListErrors.externalRefNo && inputListTouched.externalRefNo) || (i in this.state.errorList && this.state.errorList[i].externalRefno) ? ' is-invalid' : '')}
                                          name={`x.${i}.externalRefNo`}
                                          placeholder="External Tracking Number"
                                          type="text"
                                          value={x.externalRefNo}
                                          onChange={e => this.handleInputChange(i, e, 'externalRefNo', setFieldValue)}
                                        />

                                        <FormFeedback>
                                          {inputListErrors.externalRefNo}
                                        </FormFeedback>
                                      </FormGroup>
                                    </div>
                                    <div className="col-lg-6 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                      <FormGroup>
                                        <Label>External Courier Name</Label>
                                        <Field
                                          className={'form-control' + ((inputListErrors.externalCourierName && inputListTouched.externalCourierName) || (i in this.state.errorList && this.state.errorList[i].externalCourierName) ? ' is-invalid' : '')}
                                          name={`x.${i}.externalCourierName`}
                                          placeholder="External Courier Name"
                                          type="text"
                                          value={x.externalCourierName}
                                          onChange={e => this.handleInputChange(i, e, 'externalCourierName', setFieldValue)}
                                        />

                                        <FormFeedback>
                                          {inputListErrors.externalCourierName}

                                        </FormFeedback>
                                      </FormGroup>
                                    </div>

                                  </div>
                                </div>

                                <div className="col-lg-11 col-xs-12 col-sm-12">
                                  <div className="row">
                                    <div className="col-lg-3 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                      <FormGroup>
                                        <Label>Phone Number 1</Label>
                                        <div class="input-group mb-3">
                                          <PhoneInput
                                            name={`x.${i}.phoneNumber1`}
                                            containerClassName="intl-tel-input"
                                            inputClassName="form-control"
                                            onPhoneNumberChange={(a, b, c, d) => this.handleInputChange(i, d, 'phoneNumber1', setFieldValue)}
                                          />
                                          <h5 className='error-display'>
                                            {!errors.phoneNumber1 && !touched.phoneNumber1 && !this.state.isValidPhoneNo && "Please enter a valid phone number"}
                                            {errors.phoneNumber1 && touched.phoneNumber1 && errors.phoneNumber1}
                                          </h5>
                                        </div>
                                      </FormGroup>
                                    </div>
                                    <div className="col-lg-3 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                      <FormGroup>
                                        <Label>Phone Number 2</Label>
                                        <div class="input-group mb-3">
                                          <PhoneInput
                                            containerClassName="intl-tel-input"
                                            inputClassName="form-control"
                                            name={`x.${i}.phoneNumber2`}
                                            onPhoneNumberChange={(a, b, c, d) => this.handleInputChange(i, d, 'phoneNumber2', setFieldValue)}
                                          />
                                          <h5 className='error-display'>
                                            {!errors.phoneNumber1 && !touched.phoneNumber1 && !this.state.isValidPhoneNo && "Please enter a valid phone number"}
                                            {errors.phoneNumber1 && touched.phoneNumber1 && errors.phoneNumber1}
                                          </h5>
                                        </div>
                                      </FormGroup>
                                    </div>
                                    <div className="col-lg-3 col-xs-12 col-sm-12 mb-3 mb-sm-3">
                                      <FormGroup>
                                        <Label>Email Address 1</Label>
                                        <Field
                                          className={'form-control' + ((inputListErrors.emailAddress1 && inputListTouched.emailAddress1) || (i in this.state.errorList && this.state.errorList[i].emailAddress1) ? ' is-invalid' : '')}
                                          name={`x.${i}.emailAddress1`}
                                          placeholder="Email Address"
                                          type="email"
                                          value={x.emailAddress1}
                                          onChange={e => this.handleInputChange(i, e, 'emailAddress1', setFieldValue)}
                                        />

                                        <FormFeedback>
                                          {inputListErrors.emailAddress1}

                                        </FormFeedback>
                                      </FormGroup>
                                    </div>
                                    <div className="col-lg-3 col-xs-12 col-sm-12">
                                      <FormGroup>
                                        <Label>Email Address 2</Label>

                                        <Field
                                          className={'form-control' + ((inputListErrors.emailAddress2 && inputListTouched.emailAddress2) || (i in this.state.errorList && this.state.errorList[i].emailAddress2) ? ' is-invalid' : '')}
                                          name={`x.${i}.emailAddress2`}
                                          placeholder="Email Address"
                                          type="email"
                                          value={x.emailAddress2}
                                          onChange={e => this.handleInputChange(i, e, 'emailAddress2', setFieldValue)}
                                        />

                                        <FormFeedback>
                                          {inputListErrors.emailAddress2}

                                        </FormFeedback>
                                      </FormGroup>
                                    </div>

                                  </div>
                                </div>
                                <div className="col-lg-1 col-xs-12 col-sm-12 mt-lg-2 mt-sm-0 mb-sm-4 mb-lg-0 ml-sm-3 ml-lg-0 ">
                                  <Label></Label>
                                  <div className="btn-box row">
                                    {this.state.inputList.length !== 1 && <button className="btn btn-danger mr-1" onClick={() => this.handleRemoveClick(i, setFieldValue)}>-</button>}
                                    {this.state.inputList.length - 1 === i && <button className="btn btn-primary" onClick={() => this.addItems(setFieldValue)}>+</button>}
                                  </div>
                                </div>
                              </div>);
                          }))}
                        </FieldArray>


                        <div className="d-flex justify-content-center">
                          <button className="btn btn-submit" onClick={handleSubmit} >Submit</button>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </Fragment>

              )}
            </Formik>
          </>
        }
      </div>
    )
  }
}
