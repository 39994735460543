const breadcrumbSchema = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
        {
            "@type": "ListItem",
            "position": 1,
            "item": {
                "@id": "https://postkodes.com/",
                "name": "Home"
            }
        }
    ]
};

export default breadcrumbSchema;
