import React, { Component } from 'react'
import { Card, CardBody, CardHeader, Label } from 'reactstrap';
import 'react-vertical-timeline-component/style.min.css';
import { getDeleteShipment } from '../../api/Form';
import { ClipLoader } from "react-spinners";
import 'react-toastify/dist/ReactToastify.css';
import { renistateShipment } from '../../api/Form';
import { getLocation, phoneNoVerifyCheck } from '../../utils/utils';
import { toast } from 'react-toastify';
import moment from 'moment';
export default class DeleteShipment extends Component {
    state = {
        activeTab: '1',
        deviceAdCode: '',
        binAllData: [],
        loading: true,
        contactScreen: false,
        idComments: '',
        statusComment: '',
        emailComments: '',
        comments: '',
        userId: '',
        sendMessageUserId: '',
        userType: '',
        location: '',
        searchItem: '',
    }

    async componentDidMount() {
        const historyPush = this.props.history.push
        phoneNoVerifyCheck(historyPush)
        const deviceAdCode = await localStorage.getItem('adCodes');
        const userId = await localStorage.getItem("userId");
        var userTypes = await localStorage.getItem('accounttypeUsers');
        this.setState({ deviceAdCode, userId: userId, userType: userTypes, loading: false });

        await this.getDeleteShipmentHistory(deviceAdCode, userId)
        getLocation().then((location) => this.setState({ location: location }));
    }

    getDeleteShipmentHistory = async (dId, uId) => {
        await getDeleteShipment({
            device_ad_code: dId,
            user_id: uId,
            keyword: this.state.searchItem
        }).then(Response => {
            console.log(Response, "kjhlh;");
            this.setState({ binAllData: Response.data.data, loading: false })
        })
    }

    accessReinstate = (refNo) => {
        renistateShipment({
            'ref_no': refNo,
            'user_id': this.state.userId,
            'device_ad_code': this.state.deviceAdCode,
        })
            .then(response => {
                console.log(response, "this.data");
                this.getDeleteShipmentHistory(this.state.deviceAdCode, this.state.userId)
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                })
            })
            .catch(err => {
                toast.error('Something went wrong !!', {
                    position: toast.POSITION.TOP_RIGHT
                })
            })
    }

    handleChange = (e) => {
        this.setState({ searchItem: e.target.value })
    }

    keyPress = (e) => {
        if (e.keyCode === 13) {
            this.getDeleteShipmentHistory(this.state.deviceAdCode, this.state.userId)
        }
    }


    render() {
        const { userType, binAllData } = this.state
        const pending = this.state.binAllData.map(p => p)
        return (
            <div className="mt-2 style">
                <Card>
                    <CardHeader className="c-header">Bin</CardHeader>
                    <CardBody>
                        <div class="input-group col-md-4 ml-auto p-0 pb-3 pb-sm-3 pb-md-0 pb-lg-0 pb-xl-0 mb-3">
                            <input class="form-control py-2 border-right-0 border" value={this.state.searchItem}
                                onChange={this.handleChange} onKeyDown={this.keyPress}
                                placeholder="Enter keyword to search" />
                            <span class="input-group-append">
                                <button class="btn btn-outline-secondary border-left-0 border" type="button" style={{ 'z-index': 0 }}
                                    onClick={() => this.getDeleteShipmentHistory(this.state.deviceAdCode, this.state.userId)}>
                                    <i class="fa fa-search"></i>
                                </button>
                            </span>
                        </div>
                        {
                            binAllData.length === 0 &&
                            <div className='d-flex justify-content-center align-items-center' style={{ height: "80vh" }}>
                                <div>
                                    <i class="fa fa-trash" aria-hidden="true" style={{ marginLeft: "60px", fontSize: "120px", color: "#ccc" }}></i>
                                    <div>
                                        <span style={{ fontSize: "45px", color: "#ccc" }}>Bin is Empty</span>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="row">
                            {this.state.loading === true ?
                                <div className="d-flex justify-content-center">
                                    < ClipLoader
                                        size={15}
                                        color={"#123abc"}
                                        loading={true}
                                    />
                                </div>
                                :
                                <>
                                    {pending.map(p => {
                                        return (
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 pb-4">
                                                <Card className="shadow border-0">
                                                    <CardBody>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <Label for="exampleEmail " className="pr-5 label-style"
                                                                        >Ref No.</Label>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <Label for="exampleEmail"
                                                                            className="label-description">{(p.refno)}</Label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <Label for="exampleEmail " className="pr-5 label-style"
                                                                        >Created By</Label>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <Label for="exampleEmail"
                                                                            className="label-description">{(p.created_by)}</Label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <Label for="exampleEmail " className="pr-5 label-style"
                                                                        >Deleted By</Label>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <Label for="exampleEmail"
                                                                            className="label-description">{(p.deleted_by)}</Label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <Label for="exampleEmail " className="pr-5 label-style"
                                                                        >Deleted date & time</Label>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <Label for="exampleEmail"
                                                                            className="label-description">{moment(p.deleted_at).format("DD-MM-YYYY")} & {moment(p.deleted_at).format("HH:mm:ss")}</Label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <Label for="exampleEmail " className="pr-5 label-style"
                                                                        >Created Location</Label>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <Label for="exampleEmail"
                                                                            className="label-description text-bind">{p.location || "-"}</Label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <Label for="exampleEmail " className="pr-5 label-style"
                                                                        >Deleted Location</Label>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <Label for="exampleEmail"
                                                                            className="label-description text-bind">{p.deleted_location || "-"}</Label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center mt-2">
                                                                {
                                                                    <>
                                                                        {p.deleted_status === "0" ?
                                                                            <div>
                                                                                <Label for="exampleEmail " className="pr-5 label-style text-success"
                                                                                    style={{ whiteSpace: "nowrap" }}>
                                                                                    Shipment is restored.
                                                                                </Label>
                                                                            </div>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    userType === "Admin" ?
                                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                                            <div className="d-flex justify-content-center">
                                                                                                <div className="row d-flex justify-content-center">
                                                                                                    <div className="d-flex justify-content-end pr-2">
                                                                                                        <button
                                                                                                            className="btn btn-submit-track px-4"
                                                                                                            onClick={() => this.accessReinstate(p.refno)}
                                                                                                        >
                                                                                                            Access Reinstate
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                            </>
                                                                        }
                                                                    </>
                                                                }
                                                            </div>

                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        )
                                    })}
                                </>}
                        </div>
                    </CardBody>
                </Card>
            </div>
        )
    }
}
