import React, { Component } from 'react'
import { Card, CardBody, Label, Button, CardHeader } from 'reactstrap';
import './../TrackShipments/TrackShipments.css'
import 'react-vertical-timeline-component/style.min.css';
import { getAllRiders } from '../../api/Form';
import moment from 'moment';
import { trim } from '../../utils/utils';
import { ClipLoader } from "react-spinners";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class AllRiders extends Component {
	state = {
        loading: true,
        riders: [],
        userInfo: {},
	}

	async componentDidMount() {
		const info = await localStorage.getItem('auth');
        this.setState({userInfo: JSON.parse(info)});
        getAllRiders({ 'userId': this.state.userInfo.id, 'businessname': this.state.userInfo.businessname })
            .then(Response => {
                this.setState({ riders : Response.data.data, loading : false })
            }).catch(err => {
				console.log(err)
				this.setState({ 
                    loading: false 
				});
			})
	}

	handleChange = (e) => {
		this.setState({ refno: e.target.value })
	}


	toggle = (tab) => {
		if (this.state.activeTab !== tab) {
			this.setState({ activeTab: tab });
		}
	}

	outgoingTrackHandler = (nfcserialno) => {
		this.props.history.push({ pathname: './trackShipmentsDetails', state: nfcserialno })
	}

	incomingTrackHandler = (nfcserialno) => {
		this.props.history.push({ pathname: './trackShipmentsDetailsIncoming', state: nfcserialno })
	}


	render() {
		const riders = this.state.riders.map(p => p)
		return (
			<div className="mt-2 style">
				<Card>
                    <CardHeader className="c-header">List of All Riders in business {this.state.userInfo.businessname}</CardHeader>
					<CardBody>
                            <div className="row">
									{this.state.loading === true ?
										<div className="d-flex justify-content-center">
											< ClipLoader
												size={15}
												color={"#123abc"}
												loading={true}
											/>
										</div>
										:
										<>
											{riders.map(p => {
												return (
													<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 pb-4">
														<Card className="shadow border-0">
															<CardBody>
																<div className="row">
																	<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style"
																					style={{ whiteSpace: "nowrap" }}>First Name </Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{p.firstnameUsers}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
																		<div className="row">
																			<div className="col-6">
																				<Label for="exampleEmail " className="pr-5 label-style"
																					style={{ whiteSpace: "nowrap" }}>Last Name</Label>
																			</div>
																			<div className="col-6">
																				<Label for="exampleEmail"
																					className="label-description">{p.lastnameUsers}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																		<div className="row">
																			<div className="col-3">
																				<Label for="exampleEmail " className="pr-5 label-style">Location</Label>
																			</div>
																			<div className="col-9">
																				<Label for="exampleEmail"
																					className="label-description">{p.current_location}</Label>
																			</div>
																		</div>
																	</div>
																	<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																		<div className="row">
																			<div className="col-6 text-center">
																				<Button onClick={() => this.props.history.push('/admin/chatPage/'+p.idUsers)}>Chat</Button>
																			</div>
																			<div className="col-6 text-center">
                                                                                <Button onClick={() => this.props.history.push('/admin/chatPage/'+p.idUsers)}>Call</Button>
																			</div>
																		</div>
																	</div>    
																</div>
															</CardBody>
														</Card>
													</div>
												)
											})}
										</>}
								</div>
					</CardBody>
				</Card>
			</div>
		)
	}
}
