import React, { Component } from 'react'
import { Button, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
const SignupSchema = Yup.object().shape({

});

export default class ReceivedWrongMail extends Component {
  render() {
    return (
      <Formik
        initialValues={{}}
        validationSchema={SignupSchema}
        onSubmit={(values, { setErrors }) => {
          this.setErrors = setErrors;
          this.submitHandler(values);
        }}
      >{({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
      }) => (
          <div className=" w-100 h-100">
            <div className="container">
              <div className="row pt-5">
                <Link class="btn btn-back" to="/"><i class="fa fa-long-arrow-left pr-3"> </i> Go Back</Link>
                <div className="ml-auto">
                  <button class="btn btn-login1 mr-3">Download Template</button>
                </div>
              </div>
              <hr />
              <div className="main-contain-style ">
                <div>
                  <h1 className=" main-contain-title">Someone Parcel OR Mail in Letterbox/Warehouse</h1>
                  <p className="main-contain-description">Don't throw it away, it's value to the real owner. Join us save the environment and help reconnect lost mail or parcels
                  to their intended owners by logging it here. For bulk reporting download and use template,
                   email completed template to support@addressdynamic.com. For information on how we use the information you provide on our website read our privacy policy.</p>
                </div>
              </div>
              <Form>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <FormGroup className="form13-size-style">
                      <Label className="label-style">Your Name</Label>
                      <Input type="text"
                        invalid={errors.yourName && touched.yourName}
                        name="yourName" className="input-style" onChange={handleChange} onBlur={handleBlur}
                        value={values.yourName} placeholder="Enter your full name" />
                      <FormFeedback>
                        {errors.yourName && touched.yourName && errors.yourName}
                      </FormFeedback>
                    </FormGroup>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <FormGroup className="form13-size-style">
                      <Label className="label-style">Your Mobile Phone * Intl country code required e.g.+12345678912</Label>
                      <Input type="text"
                        invalid={errors.mobileNumber && touched.mobileNumber}
                        name="mobileNumber" className="input-style" onChange={handleChange} onBlur={handleBlur}
                        value={values.mobileNumber} placeholder="Enter your phone, including country code" />
                      <FormFeedback>
                        {errors.mobileNumber && touched.mobileNumber && errors.mobileNumber}
                      </FormFeedback>
                    </FormGroup>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <FormGroup className="form13-size-style">
                      <Label className="label-style">Your Email </Label>
                      <Input type="text"
                        invalid={errors.email && touched.email}
                        name="email" className="input-style" onChange={handleChange} onBlur={handleBlur}
                        value={values.email} placeholder="Enter your email" />
                      <FormFeedback>
                        {errors.email && touched.email && errors.email}
                      </FormFeedback>
                    </FormGroup>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <FormGroup className="form13-size-style">
                      <Label className="label-style">Address on mail </Label>
                      <Input type="firstName"
                        invalid={errors.address && touched.address}
                        name="address" className="input-style" onChange={handleChange} onBlur={handleBlur}
                        value={values.address} placeholder="Enter address on the mail" />
                      <FormFeedback>
                        {errors.address && touched.address && errors.address}
                      </FormFeedback>
                    </FormGroup>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                    <FormGroup className="form13-size-style">
                      <Label className="label-style">Name as it appears on mail</Label>
                      <Input type="text"
                        invalid={errors.nameOnEmail && touched.nameOnEmail}
                        name="nameOnEmail" className="input-style" onChange={handleChange} onBlur={handleBlur}
                        value={values.nameOnEmail} placeholder="Enter full name as it appears on mail " />
                      <FormFeedback>
                        {errors.nameOnEmail && touched.nameOnEmail && errors.nameOnEmail}
                      </FormFeedback>
                    </FormGroup>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                    <FormGroup className="form13-size-style">
                      <Label className="label-style">Courier name:if known</Label>
                      <Input type="text"
                        invalid={errors.courierName && touched.courierName}
                        name="courierName" className="input-style" onChange={handleChange} onBlur={handleBlur}
                        value={values.courierName} placeholder="Enter delivery courier " />
                      <FormFeedback>
                        {errors.courierName && touched.courierName && errors.courierName}
                      </FormFeedback>
                    </FormGroup>
                  </div>
                  <div className="col-12  ">
                    <FormGroup className="form13-size-style">
                      <Label className="label-style">Mail Content Description</Label>
                      <Input type="textarea"
                        invalid={errors.mailDescription && touched.mailDescription}
                        name="mailDescription" className="input-style" onChange={handleChange} onBlur={handleBlur}
                        value={values.mailDescription} placeholder="Briefly describe the mail include track# if available, e.g mail is a letter from abcbank " />
                      <FormFeedback>
                        {errors.mailDescription && touched.mailDescription && errors.mailDescription}
                      </FormFeedback>
                    </FormGroup>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                    <FormGroup className="form13-size-style">
                      <Label className="label-style">Tracking#: if any</Label>
                      <Input type="text"
                        invalid={errors.tracking && touched.tracking}
                        name="tracking" className="input-style" onChange={handleChange} onBlur={handleBlur}
                        value={values.tracking} placeholder="Enter mail tracking number if any " />
                      <FormFeedback>
                        {errors.tracking && touched.tracking && errors.tracking}
                      </FormFeedback>
                    </FormGroup>
                  </div>
                </div>
                <div className="pt-3 d-flex justify-content-center pb-3">
                  <Button className="btn btn-login ">Submit</Button>
                </div>
              </Form>
            </div>

          </div>
        )}
      </Formik>
    )
  }
}
