import React, { Component } from 'react';
import './PrintInvoice.css';
import currenySymbol from "../Invoice/currencies.json"
import moment from 'moment';

export default class PrintFile extends Component {
  state = {
    userInfo: {},
  }
  async componentDidMount() {
    const data = await localStorage.getItem('auth');
    this.setState({ userInfo: JSON.parse(data) })
  }
  render() {

    const info = this.props.format;
    const symbolData = this.props.symbolCurrency;
    const userInfo = this.state.userInfo;
    return (
      <div className="section1 row">
        {/* <div className="col-2" /> */}
        <div className="col-12 margin_005">
          <div className="borderTop"></div>
          <div className="innerContent">
            <div className="margin_30">
              <div className="cls_002">
                <span className="cls_002">Smyna Pty Ltd</span>
              </div>
              <div className="cls_003">
                <span className="cls_003">Merrylands</span>
              </div>
              <div className="cls_003">
                <span className="cls_003">NSW Australia</span>
              </div>
              {/* <div className="cls_003">
                <span className="cls_003">Tel: 0410902852</span>
              </div> */}
            </div>
            <div className="row margin_30">
              <div className="col-6">
                <div className="cls_004">
                  <span className="cls_004">Invoice</span>
                </div>
                <div className="cls_005">
                  <span className="cls_005">Created on {moment(info.created).format('MMMM Do YYYY, h:mm:ss A')}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <span className="cls_006">Invoiced To</span>
                <span className="cls_003">{info.receiver_name}</span>
                <span className="cls_003">{info.receiver_address}</span>
              </div>
              <div className="col-4">
                <span className="cls_006">Payable To</span>
                <span className="cls_003">{info.user_business_name ? info.user_business_name : userInfo.firstName + " " + userInfo.lastName}</span>
              </div>
              <div className="col-4">
                <span className="cls_006">Invoice #</span>
                <span className="cls_003">{info.ref_no}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <span className="cls_006">Payment ref</span>
                <span className="cls_003">{info.ref_no}</span>
              </div>
              <div className="col-4">
                <span className="cls_006">Invoice Type</span>
                <span className="cls_003">Invoice</span>
              </div>
              <div className="col-4">
                <span className="cls_006">Status</span>
                <span className="cls_003">{info.invoice_status}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <span className="cls_006">Quantity</span>
                <span className="cls_003">{info.shipped_items}</span>
              </div>
              <div className="col-4">
                <span className="cls_006">Weight</span>
                <span className="cls_003">{info.weight}</span>
              </div>
            </div>
            <div className="border_002" />
            <div className="row">
              <table className="table_002">
                <tr>
                  <th>
                    <span class="cls_008">Item</span>
                  </th>
                  <th>
                    <span class="cls_008">Description</span>
                  </th>
                  <th style={{ textAlign: "end" }}>
                    <span class="cls_008">Qty</span>
                  </th>
                  <th style={{ textAlign: "end" }}>
                    <span class="cls_008">Unit Price</span>
                  </th>
                  <th style={{ textAlign: "end" }}>
                    <span class="cls_008">Total Price</span>
                  </th>
                </tr>
                <tr className="border_005">
                  <td>
                    <span class="cls_007">Destination charges</span>
                  </td>
                  <td>
                    <span class="cls_003">Inbound Air Frieght Fee</span>
                  </td>
                  <td style={{ textAlign: "end" }}>
                    <span class="cls_003">{info.shipped_items}</span>
                  </td>
                  <td style={{ textAlign: "end" }}>
                    <span class="cls_003">{symbolData}{Number(info.amount_charged).toFixed(2)}</span>
                  </td>
                  <td style={{ textAlign: "end" }}>
                    <span class="cls_003">{symbolData}{Number(info.amount_charged).toFixed(2)}</span>
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <span class="cls_009">Notes:</span>
                    <span class="cls_010"> {info.comment} </span>
                  </td>
                  <td style={{ textAlign: "end" }}>
                    <span class="cls_011">Subtotal</span>
                  </td>
                  <td style={{ textAlign: "end" }}>
                    <span class="cls_012">{symbolData}{Number(info.amount_charged).toFixed(2)}</span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ textAlign: "end" }}>
                    <span class="cls_011">GST/VAT</span>
                  </td>
                  <td style={{ textAlign: "end" }}>
                    <span class="cls_013">{symbolData}{((info.amount_charged * info.vat_percentage) / 100).toFixed(2)}</span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ textAlign: "end" }}>
                    <span class="cls_014">{symbolData}{(Number(info.amount_charged) + (info.amount_charged * info.vat_percentage) / 100).toFixed(2)}</span>
                  </td>
                </tr>
              </table>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="cls_010">
                  <span className="cls_006">Payment details</span>
                </div>
                <div className="cls_010">
                  <span className="cls_010">Bank Name: National Australia Bank</span>
                </div>
                <div className="cls_010">
                  <span className="cls_010">BIC/SWIFT Code: NATAAU3303M</span>
                </div>
                <div className="cls_010">
                  <span className="cls_010">Account Name: Smyna Pty Ltd</span>
                </div>
                <div className="cls_010">
                  <span className="cls_010">BSB: 082112</span>
                </div>
                <div className="cls_010">
                  <span className="cls_010">Account No. 298888224</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-2" /> */}
      </div>
    );
  }
}
