import React, { Component } from "react";
import { Context } from "../../context";
import moment from "moment";
import { image, URLS } from "../../api/URLS";
import Logo from "../../assets/4.png";
import Logo2 from "../../assets/new_logo.png";
import { call, sendChats, addCall, callHistory } from "../../api/Form";
import io from "socket.io-client";
import outgoing from "../../assets/audio/outgoing.mp3";
import { toast } from "react-toastify";
import Notification, { notify } from "react-notify-toast";

const audio = new Audio(outgoing);
export default class chat extends Component {
  messagesEndRef = React.createRef();

  static contextType = Context;
  socket = null;
  state = {
    deviceAdCode: "",
    userId: "",
    firstName: "",
    statusLoading: false,
    mobileView: true,
    contactScreen: false,
    searchContact: "",
    contactList: [],
    chatScreen: "",
    typing: false,
    counter: 0,
  };

  componentWillUnmount() {
    if (this.state.session && this.state.audioCall) {
      this.endCall();
    }
  }

  async componentDidMount() {
    const deviceAdCode = await localStorage.getItem("adCodes");
    const userId = await localStorage.getItem("userId");
    const firstName = await localStorage.getItem("firstName");
    this.setState({ userId, deviceAdCode, firstName });
    this.scrollToBottom();
    this.connectSocket();
    this.getUser();
    if (this.context.call === "accepted" || this.context.call === "incoming") {
      this.setState({ audioCall: true, session: this.context.session });
    }
    // this.readChats()
  }

  getUser = () => {
    window.QB.users.get({ login: this.props.userChat.username }, (error, qbUser) => {
      if (!error) this.setState({ qbUser });
    });
  };

  endCall = () => {
    if (this.state.session) {
      this.state.session.stop({});
    }
    this.setState({ session: null, audioCall: false });
    this.outgoingRingtone();
  };

  call = () => {
    if (this.state.qbUser) {
      const calleesIds = [this.state.qbUser.id]; // Users' ids
      const sessionType = window.QB.webrtc.CallType.AUDIO; // AUDIO is also possible
      const additionalOptions = {};
      const session = window.QB.webrtc.createNewSession(calleesIds, sessionType, null, additionalOptions);
      const mediaParams = {
        audio: true,
        video: false,
      };
      session.getUserMedia(mediaParams, (err, stream) => {
        if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          return window.QB.webrtc.SessionConnectionState.CLOSED;
        } else {
          const { userChat } = this.props;
          const userId = this.props.userChat.senderId === +this.state.userId ? userChat.userId : userChat.senderId;
          addCall({ fromUserId: this.state.userId, toUserId: userId })
            .then((response) => {
              const id = response.data.data.id;
              session.call({ name: this.state.firstName, id }, (error) => {
                call({ userId, uuid: session.ID, name: this.state.firstName });
                this.setState({ audioCall: true, session });
                this.outgoingRingtone();
                callHistory({ userId: this.state.userId })
                  .then((response) => {
                    this.props.callHistorys(response.data.data);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    } else {
      toast.error("This user is not Online!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  outgoingRingtone = () => {
    if (this.state.audioCall && this.state.session && !this.context.call) {
      audio.loop = true;
      audio.play();
    } else {
      audio.pause();
    }
  };

  connectSocket = () => {
    console.log("socket");
    this.socket = io(URLS.SOCKET_URL);
    const { userId } = this.state;
    const { userChat } = this.props;
    this.socket.on("typingStart", (data) => {
      console.log("typing");
      this.setState({ typing: true });
    });
    this.socket.on("typingStop", (data) => {
      console.log("stop typing");
      this.setState({ typing: false });
    });
    this.socket.on("connect", () => {
      this.socket.emit("login", { chatId: userChat.id, userId: +userId });
      console.log("connected");
    });
  };

  showAlert = () => {
    if (this.state.counter % 2 === 0) {
      notify.show("Call declined!", "error", 3000);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.context.call === "rejected") {
      this.state.counter = this.state.counter + 1;
      this.showAlert();
    }

    if (this.state.audioCall && this.context.call && this.context.call !== "accepted") {
      this.setState({ audioCall: false, session: null });
    }
    this.outgoingRingtone();
    if (prevState.typing !== this.state.typing) this.scrollToBottom();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextContext.chats.length !== this.context.chats.length) {
      this.scrollToBottom();
    }
    if (nextProps.userChat.id && nextProps.userChat.id !== this.props.userChat.id) {
      this.scrollToBottom();
      this.connectSocket(nextProps.userChat.id);
      this.setState({ chatId: nextProps.userChat.id, senderId: nextProps.userChat.senderId });
    }
  }

  sendChatHandler = () => {
    const { userChat } = this.props;
    const { text, userId } = this.state;
    sendChats({
      text: text,
      chatId: this.props.userChat.id,
      senderId: +userId,
      userId: this.props.userChat.senderId === +userId ? userId : this.props.userChat.senderId,
    })
      .then((Response) => {
        this.socket.emit("stopTyping", { userId: +userId, chatId: userChat.id });
        this.setState({ text: "" });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  inputHandler = (e) => {
    const { userId } = this.state;
    const { userChat } = this.props;
    if (e.target.value.length > 0) {
      console.log("typinggg");
      this.socket.emit("startTyping", { userId: +userId, chatId: userChat.id });
    } else {
      this.socket.emit("stopTyping", { userId: +userId, chatId: userChat.id });
    }
    this.setState({ text: e.target.value });
  };

  keyPress = (e) => {
    if (e.keyCode == 13 && e.target.value) {
      this.sendChatHandler();
    }
  };

  scrollToBottom = () => {
    if (this.messagesEndRef.current) {
      this.props.readChats(this.state.callHistory);
      this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      setTimeout(this.scrollToBottom, 1e3);
    }
  };

  userAlert = () => {
    const err = "user is not Online!";
    toast.error(err, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  render() {
    const { profile, typing, qbUser, audioCall } = this.state;
    const { userChat, chats, mobileView } = this.props;
    return (
      <>
        <Notification options={{ zIndex: 200, top: "50px" }} />
        <audio id="audioCall"></audio>
        <div className="col-12 col-lg-7 col-xl-9 pl-md-0 pl-lg-0 pl-xl-0 ">
          {chats.length >= 0 && userChat.status === "accepted" && (
            <div>
              <div className="header d-flex  align-items-center">
                <div className="row">
                  <img src={userChat.profile ? image + userChat.profile : Logo2} alt="" className="img-fluid profile-img ml-4" />
                  <div className="d-flex justify-content-start align-items-center">
                    <span className="pl-2 font-weight-bold chat-main-head-text">{userChat.name}</span>
                  </div>
                </div>
                {mobileView === false && (
                  <div className="ml-auto">
                    <button className="btn btn-link mr-10" type="button" onClick={() => (audioCall ? this.endCall() : this.call())}>
                      {audioCall ? (
                        <i className="fa fa-phone pr-3" style={{ transform: "rotate(136deg)" }} aria-hidden="true" />
                      ) : (
                        <i className="fa fa-phone pr-3" aria-hidden="true" />
                      )}
                    </button>
                    <button className="btn btn-link" type="button">
                      <i className="fa fa-times-circle pr-3" aria-hidden="true" onClick={() => this.props.chatClose()} />
                    </button>
                  </div>
                )}
              </div>

              <div class="row message " id="conversation">
                <div className="row  message-previous">
                  {chats.map((p) => {
                    return (
                      <>
                        {p.user._id !== parseInt(this.state.userId) && (
                          <>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                              <>
                                <div className="chat-message" id="id_div_1">
                                  <span className="chat-text">{p.text}</span>
                                  <div>
                                    <div class="remove_link">
                                      <a href="#id_X_0">
                                        <icon class="fa fa-times-circle icon_size16" onClick={() => this.props.deleteChats(p._id)}></icon>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <span className="date-style">{moment(p.createdAt).format("hh:mm")}</span>
                                </div>
                              </>
                            </div>
                            <div className="offset-6"></div>
                          </>
                        )}
                        {p.user._id === parseInt(this.state.userId) && (
                          <>
                            <div className="offset-6" />
                            <div div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                              <div className="d-flex justify-content-end">
                                <div className="chat-message-1" id="id_div_1">
                                  <span className="chat-text">{p.text}</span>
                                  <div>
                                    <div class="remove_link">
                                      <a href="#id_X_0">
                                        <icon class="fa fa-times-circle icon_size16" onClick={() => this.props.deleteChats(p._id)}></icon>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end">
                                <span className="date-style">{moment(p.createdAt).format("hh:mm")}</span>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    );
                  })}

                  <div ref={this.messagesEndRef} />

                  {typing && (
                    <div className="d-flex align-items-end pl-3">
                      <h6>typing...</h6>
                    </div>
                  )}
                </div>
              </div>
              <div class="row reply">
                <div className="col-10 d-flex justify-content-center align-items-center pr-0 pl-0">
                  <input
                    type="text"
                    value={this.state.text}
                    onKeyDown={this.keyPress}
                    class="form-control mx-lg-3 mx-xl-3"
                    rows="1"
                    id="comment"
                    placeholder="Write your message…"
                    onChange={this.inputHandler}
                  />
                </div>
                <div className="col-1 d-flex  align-items-center pl-0">
                  <button className="btn p-0" onClick={this.sendChatHandler} disabled={this.state.text === ""}>
                    <i class="fa fa-paper-plane send-icon" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
