import React, { Component } from "react";
import {
    Button,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
} from "reactstrap";
import DatePicker from "react-datepicker";
import PhoneInput from 'react-intl-tel-input';
import { isValidNumber } from 'libphonenumber-js';
import "./Login.css";
import logo from "../assets/4.png";
import { errandRequest } from "../api/auth";
import { Formik } from "formik";
import * as Yup from "yup";
import { ClipLoader } from "react-spinners";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { Helmet } from "react-helmet";
const { REACT_APP_ERRAND_EMAIL } = process.env;

const ErrandSchema = Yup.object().shape({
    firstName: Yup.string()
        .required('First Name is required'),
    lastName: Yup.string()
        .required('Last Name is required'),
    phoneNumber: Yup.string()
        .required('Phone Number is required'),
    email: Yup.string()
        .email('Invalid email format')
        .required('Email is required'),
    address: Yup.string()
        .required('Address is required'),
    state: Yup.string()
        .required('State is required'),
    zipCode: Yup.string()
        .required('Zip Code is required'),
    errandStartDate: Yup.date()
        .required('Errand Start Date is required'),
    errandEndDate: Yup.date()
        .required('Errand End Date is required'),
    errandStartTime: Yup.string()
        .required('Errand Start Time is required'),
    errandEndTime: Yup.string()
        .required('Errand End Time is required'),
    description: Yup.string()
        .required('Description is required')
});

export default class RequestErrand extends Component {
    state = {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        address: "",
        state: "",
        zipCode: "",
        errandStartDate: "",
        errandEndDate: "",
        errandStartTime: "",
        errandEndTime: "",
        description: ""
    };



    async componentDidMount() {
        const auth = await localStorage.getItem("auth");
        const userId = await localStorage.getItem("userId");
        const adCodes = await localStorage.getItem("adCodes");

        const userData = JSON.parse(auth);

        if (auth && userId && adCodes) {
            this.setState({
                firstName: userData.firstName,
                lastName: userData.lastName,
                email: userData.email,
                phoneNumber: userData.phoneNumber
            });
        }
    }

    handleStartTimeChange = (time) => {
        const hours = time.getHours().toString().padStart(2, '0');
        const minutes = time.getMinutes().toString().padStart(2, '0');
        const seconds = time.getSeconds().toString().padStart(2, '0');
        const formattedTime = `${hours}:${minutes}:${seconds}`;
        return formattedTime;
    };


    submitHandler = (values) => {

        const formData = {
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
            email: values.email,
            address: values.address,
            state: values.state,
            zipCode: values.zipCode,
            errandStartDate: values.errandStartDate + " " + this.handleStartTimeChange(values.errandStartTime),
            errandEndDate: values.errandEndDate + " " + this.handleStartTimeChange(values.errandEndTime),
            description: values.description
        }

        errandRequest(formData)
            .then(res => {
                if (res?.status === 200) {
                    toast.success("Request Submitted successfully", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    this.props.history.push("/");
                } else {
                    toast.success("Something went wrong", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }

            }).catch(err => {
                console.log(err)
            })


    };

    handlePhoneNumberChange = (isEdited, phoneNumber, selectedCountry, setFieldValue, fieldName) => {


        const countryDialCode = `+${selectedCountry.dialCode}`;
        // Remove the country code if the user manually entered it
        if (phoneNumber.startsWith(countryDialCode)) {
            phoneNumber = phoneNumber.slice(countryDialCode.length);
        }
        const isValid = isValidNumber(phoneNumber, [selectedCountry.iso2.toUpperCase()]);
        this.setState({ [`${fieldName}IsValidPhoneNo`]: isValid });

        const number = `${countryDialCode}${phoneNumber}`;
        setFieldValue(fieldName, number);
    };

    formatDate(dateString) {

        if (!dateString) {
            return
        }
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Request Errand - Postkodes</title>
                    <meta name="description" content="Submit request to complete an errand" />
                </Helmet>
                <Formik
                    isInitialValid={false}
                    enableReinitialize
                    initialValues={{
                        firstName: this.state.firstName,
                        lastName: this.state.lastName,
                        phoneNumber: this.state.phoneNumber,
                        email: this.state.email,
                        address: this.state.address,
                        state: this.state.state,
                        zipCode: this.state.zipCode,
                        errandStartDate: this.state.errandStartDate,
                        errandEndDate: this.state.errandEndDate,
                        errandStartTime: this.state.errandStartTime,
                        errandEndTime: this.state.errandEndTime,
                        description: this.state.description
                    }}
                    validationSchema={ErrandSchema}
                    onSubmit={(values, { setErrors }) => {
                        this.setErrors = setErrors;
                        this.submitHandler(values);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        handleBlur,
                        setFieldValue,
                    }) => (

                        <div className=" w-100 h-100 overflow-hidden login-autonomous">
                            <div className="row">
                                <div className="col-lg-4 col-md-5 postkod-description pr-3">
                                    <div className=" d-md-block d-lg-block d-xl-block login-bg-style login-errand">
                                        <div className="d-flex justify-content-center">
                                            <Link to="/">
                                                <img src={logo} className="img-fluid" alt="" />
                                            </Link>
                                        </div>
                                        <h1 className="pb-3 pt-3 text-center login-title">
                                            <b>Service Description</b>
                                        </h1>
                                        <p className="text-justify login-description">
                                            Use this form to request our errand service.
                                            Errand can include but is not limited to,
                                            picking up and delivering any item from one address to another address,
                                            picking up and delivering shopping,
                                            picking up medication from the pharmacy,
                                            collecting or delivering mail/parcels from or to the post office or any other place.
                                            Literally, any local errands.
                                        </p>

                                        <h1 className="pb-3 pt-3 text-center login-title">
                                            <b>How It Works</b>
                                        </h1>
                                        <ul className="login-description-ul">
                                            <li className="login-description m-0 p-0"> Submit request to complete an errand </li>
                                            <li className="login-description m-0 p-0"> We receive your request and advice the cost </li>
                                            <li className="login-description m-0 p-0"> You accept cost and make payment</li>
                                            <li className="login-description m-0 p-0"> We complete the errand. </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-7 pl-0">
                                    <div className="login-form requestErrand-form">

                                        <div className="d-none d-md-block d-lg-block d-xl-block">
                                            <h1 className="text-center mb-5" style={{ color: "#123abc" }}><b>Request Errand</b> </h1>
                                        </div>
                                        <Form>
                                            <FormGroup>
                                                <div className='row'>
                                                    <div className="col-lg-6 col-xs-12 col-sm-12 ">
                                                        <Label>First name</Label>
                                                        <Input
                                                            type="text"
                                                            name="firstName"
                                                            invalid={errors.firstName && touched.firstName}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.firstName}
                                                            placeholder="Enter first name" />
                                                        <FormFeedback>
                                                            {errors.firstName && touched.firstName && errors.firstName}
                                                        </FormFeedback>
                                                    </div>
                                                    <div className="col-lg-6 col-xs-12 col-sm-12 ">
                                                        <Label>Last name</Label>
                                                        <Input
                                                            type="text"
                                                            name="lastName"
                                                            invalid={errors.lastName && touched.lastName}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.lastName}
                                                            placeholder="Enter last name" />
                                                        <FormFeedback>
                                                            {errors.lastName && touched.lastName && errors.lastName}
                                                        </FormFeedback>
                                                    </div>
                                                </div>
                                            </FormGroup>

                                            <FormGroup>
                                                <div className='row'>
                                                    <div className="col-lg-6 col-xs-12 col-sm-12 ">
                                                        <Label>Phone number</Label>
                                                        {/* <Input
                                                        type="text"
                                                        name="phoneNumber"
                                                        invalid={errors.phoneNumber && touched.phoneNumber}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.phoneNumber}
                                                        placeholder="Enter phone number" /> */}
                                                        <PhoneInput
                                                            containerClassName="intl-tel-input"
                                                            inputClassName="form-control"
                                                            defaultValue={this.state.phoneNumber}
                                                            onPhoneNumberChange={(isEdited, phoneNumber, selectedCountry) => this.handlePhoneNumberChange(isEdited, phoneNumber, selectedCountry, setFieldValue, 'phoneNumber')}
                                                        />
                                                        <h5 className='error-display'>

                                                            {errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}
                                                        </h5>
                                                        {/* <FormFeedback>
                                                        {errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}
                                                    </FormFeedback> */}
                                                    </div>
                                                    <div className="col-lg-6 col-xs-12 col-sm-12  ">
                                                        <Label>Email</Label>
                                                        <Input
                                                            type="text"
                                                            name="email"
                                                            invalid={errors.email && touched.email}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.email}
                                                            placeholder="Enter email" />
                                                        <FormFeedback>
                                                            {errors.email && touched.email && errors.email}
                                                        </FormFeedback>
                                                    </div>
                                                </div>
                                            </FormGroup>

                                            <FormGroup>
                                                <div className='row'>
                                                    <div className="col-lg-12 col-xs-12 col-sm-12">
                                                        <Label>Errand Location/Address</Label>
                                                        <Input
                                                            type="text"
                                                            name="address"
                                                            invalid={errors.address && touched.address}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.address}
                                                            placeholder="Enter address" />
                                                        <FormFeedback>
                                                            {errors.address && touched.address && errors.address}
                                                        </FormFeedback>
                                                    </div>
                                                </div>
                                            </FormGroup>

                                            <FormGroup>
                                                <div className='row'>
                                                    <div className="col-lg-6 col-xs-12 col-sm-12 mt-2">

                                                        <Input
                                                            type="text"
                                                            name="state"
                                                            invalid={errors.state && touched.state}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.state}
                                                            placeholder="Enter state" />
                                                        <FormFeedback>
                                                            {errors.state && touched.state && errors.state}
                                                        </FormFeedback>
                                                    </div>
                                                    <div className="col-lg-6 col-xs-12 col-sm-12 mt-2">

                                                        <Input
                                                            type="text"
                                                            name="zipCode"
                                                            invalid={errors.zipCode && touched.zipCode}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.zipCode}
                                                            placeholder="Enter zip/post code" />
                                                        <FormFeedback>
                                                            {errors.zipCode && touched.zipCode && errors.zipCode}
                                                        </FormFeedback>
                                                    </div>
                                                </div>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label>Start Errand</Label>
                                                <div className="row">

                                                    <div className="col-lg-6 col-xs-12 col-sm-12 ">
                                                        <FormGroup>

                                                            <DatePicker
                                                                name="errandStartDate"
                                                                placeholderText="Select Date"
                                                                className="is-invalid"
                                                                // minDate={moment().toDate()}
                                                                selected={values.errandStartDate ? new Date(values.errandStartDate) : ''}
                                                                onChange={val => {
                                                                    setFieldValue('errandStartDate', this.formatDate(val));
                                                                }}
                                                            />
                                                            <Input type="hidden" name="errandStartDate" placeholder="Select Date" value={values.errandStartDate} className="is-invalid" />
                                                            <FormFeedback>
                                                                {errors.errandStartDate && touched.errandStartDate && errors.errandStartDate}
                                                            </FormFeedback>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-lg-6 col-xs-12 col-sm-12 ">
                                                        <FormGroup>

                                                            <DatePicker
                                                                selected={values.errandStartTime}
                                                                onChange={val => { setFieldValue('errandStartTime', val) }}
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                timeIntervals={15}
                                                                timeCaption="Time"
                                                                dateFormat="h:mm aa"
                                                                placeholderText="Select time"
                                                            />
                                                            <Input type="hidden" name="errandStartTime" placeholder="Select Date" value={values.errandStartTime} className="is-invalid" />
                                                            <FormFeedback>
                                                                {errors.errandStartTime && touched.errandStartTime && errors.errandStartTime}
                                                            </FormFeedback>
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label>End Errand</Label>
                                                <div className="row">
                                                    <div className="col-lg-6 col-xs-12 col-sm-12  ">
                                                        <FormGroup>

                                                            <DatePicker
                                                                name="errandEndDate"
                                                                placeholderText="Select Date"
                                                                className="is-invalid"
                                                                // minDate={moment().toDate()}
                                                                selected={values.errandEndDate ? new Date(values.errandEndDate) : ''}
                                                                onChange={val => {
                                                                    setFieldValue('errandEndDate', this.formatDate(val));
                                                                }}
                                                            />
                                                            <Input type="hidden" name="errandEndDate" placeholder="Select Date" value={values.errandEndDate} className="is-invalid" />
                                                            <FormFeedback>
                                                                {errors.errandEndDate && touched.errandEndDate && errors.errandEndDate}
                                                            </FormFeedback>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-lg-6 col-xs-12 col-sm-12   ">
                                                        <FormGroup>

                                                            <DatePicker
                                                                selected={values.errandEndTime}
                                                                onChange={val => { setFieldValue('errandEndTime', val) }}
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                timeIntervals={15}
                                                                timeCaption="Time"
                                                                dateFormat="h:mm aa"
                                                                placeholderText="Select time"
                                                            />
                                                            <Input type="hidden" name="errandEndTime" placeholder="Select Date" value={values.errandEndTime} className="is-invalid" />
                                                            <FormFeedback>
                                                                {errors.errandEndTime && touched.errandEndTime && errors.errandEndTime}
                                                            </FormFeedback>
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </FormGroup>

                                            <FormGroup>
                                                <div className='row'>
                                                    <div className="col-lg-12 col-xs-12 col-sm-12">
                                                        <Label>Errand Description</Label>
                                                        <Input
                                                            type="textarea"
                                                            name="description"
                                                            invalid={errors.description && touched.description}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.description}
                                                            placeholder="Enter Description" />

                                                        <FormFeedback>
                                                            {errors.description && touched.description && errors.description}
                                                        </FormFeedback>
                                                    </div>
                                                </div>
                                            </FormGroup>

                                            <div className="text-center pt-3">
                                                <Button
                                                    className="btn btn-login "
                                                    onClick={handleSubmit}
                                                >
                                                    {this.state.loading ? (
                                                        <ClipLoader
                                                            size={15}
                                                            color={"#123abc"}
                                                            loading={true}
                                                        />
                                                    ) : (
                                                        "Submit"
                                                    )}
                                                </Button>
                                            </div>
                                        </Form>

                                        <div className="more-info-btn text-center mt-2" >
                                            <span >For More Information</span>
                                            <p className="text-black">
                                                Get in touch: <a href={`mailto:${REACT_APP_ERRAND_EMAIL}`} className="text-blue-500 underline">{REACT_APP_ERRAND_EMAIL}</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-offset-none offset-sm-none offset-md-2 offset-lg-2 offset-xl-2"></div>
                            </div>
                        </div>
                    )}
                </Formik>
            </>
        );
    }
}
