import React, { Component } from 'react'
import "./pagenation.css"
export default class PaginationPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
        };
    }

    handlePageChange = (pageNumber) => {
        this.setState({ currentPage: pageNumber });
    }

    render() {
        const { currentPage } = this.state;
        const { totalPages } = this.props;

        const pageNumbers = Array.from(Array(totalPages).keys()).map((num) => num + 1);

        return (
            <div>
                <ul className="pagination">
                    {pageNumbers.map((pageNumber) => (
                        <li
                            key={pageNumber}
                            className={pageNumber === currentPage ? 'active' : ''}
                            onClick={() => this.handlePageChange(pageNumber)}
                        >
                            {pageNumber}
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}
