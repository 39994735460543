import React, { Component } from 'react';
import GroupImg from '../../assets/Group.png';
import VectorImg from '../../assets/Vector.png';
import teamImg from '../../assets/fluent_people-team-20-regular.png';
import partnerImg1 from '../../assets/partnerImg1.webp';
import partnerImg2 from '../../assets/partnerImg2.webp';
import partnerImg3 from '../../assets/partnerImg3.webp';
import partnerImg4 from '../../assets/partnerImg4.webp';
import partnerImg5 from '../../assets/partnerImg5.webp';
import TestimonialCarousel from './TestimonialsCarousel';
import { requestDemo } from '../../api/Form';
import { toast } from 'react-toastify';

export default class PartnersAndTestimonialSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fullName: '',
            email: '',
            company: '',
            country: '',
            companySize: '',
            designation: '',
            errors: {} // Add this line
        };
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const errors = {};

        if (!this.state.fullName) errors.fullName = "Full Name is required";
        if (!this.state.email) errors.email = "Email Address is required";
        if (!this.state.company) errors.company = "Company Name is required";
        if (!this.state.country) errors.country = "Country/Region is required";
        if (!this.state.companySize) errors.companySize = "Company Size is required";
        if (!this.state.designation) errors.designation = "Designation is required";

        if (Object.keys(errors).length > 0) {
            this.setState({ errors });
            return;
        }

        requestDemo(this.state)
            .then((res) => {
                if (res.status === 200) {
                    toast.success('Your request sent successfully.')
                }
                else {
                    toast.error('Something went wrong')
                }
            })
            .catch((err) => {
                toast.error(err.message)
            });

        // clear all fields
        this.setState({
            fullName: '',
            email: '',
            company: '',
            country: '',
            companySize: '',
            designation: '',
            errors: {} // Clear errors after successful submission
        });
    }

    render() {
        const { fullName, email, company, country, companySize, designation, errors } = this.state;
        return (
            <>
                <TestimonialCarousel />

                <section className="quote-request-section">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <h2>Request a Demo</h2>
                                <h5>Let's talk about how you can transform your logistics operation with Postkodes.</h5>
                            </div>
                            <div className='col-md-8'>
                                <h4>Leave your details here and we'll be in touch</h4>
                                <form className="request-form" onSubmit={this.handleSubmit}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Full Name*"
                                        name="fullName"
                                        value={fullName}
                                        onChange={this.handleChange}
                                        required
                                    />
                                    {errors.fullName && <span className="text-danger">{errors.fullName}</span>}

                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Email Address*"
                                        name="email"
                                        value={email}
                                        onChange={this.handleChange}
                                        required
                                    />
                                    {errors.email && <span className="text-danger">{errors.email}</span>}

                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Company Name*"
                                        name="company"
                                        value={company}
                                        onChange={this.handleChange}
                                        required
                                    />
                                    {errors.company && <span className="text-danger">{errors.company}</span>}

                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Country/Region*"
                                        name="country"
                                        value={country}
                                        onChange={this.handleChange}
                                        required
                                    />
                                    {errors.country && <span className="text-danger">{errors.country}</span>}

                                    <select
                                        className="form-control"
                                        name="companySize"
                                        value={companySize}
                                        onChange={this.handleChange}
                                        required
                                    >
                                        <option value="">Company Size*</option>
                                        <option value="1-10">1-10</option>
                                        <option value="11-50">11-50</option>
                                        <option value="51-200">51-200</option>
                                        <option value="201-500">201-500</option>
                                        <option value="500+">500+</option>
                                    </select>
                                    {errors.companySize && <span className="text-danger">{errors.companySize}</span>}

                                    <select
                                        className="form-control"
                                        name="designation"
                                        value={designation}
                                        onChange={this.handleChange}
                                        required
                                    >
                                        <option value="">I am a..*</option>
                                        <option value="Business Owner">Business Owner</option>
                                        <option value="Logistics Manager">Logistics Manager</option>
                                        <option value="Supply Chain Specialist">Supply Chain Specialist</option>
                                        <option value="Other">Other</option>
                                    </select>
                                    {errors.designation && <span className="text-danger">{errors.designation}</span>}

                                    <button type="submit" className="btn btn-danger btn-block">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="partners-section my-5">
                    <div className='container'>
                        <div className="row">
                            <div className='col-md-12'>
                                <h2>Partners Moving Their Logistics On Postkodes</h2>
                                <p>
                                    In Africa, our customers are using POSTKODES to lead logistics and supply chain across the continent and are at the forefront of tech driven logistics. Reducing trade barriers and increasing visibility across the supply chain. The result is increase in their productivity, reduction in costs and risk mitigation.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-md-12 partner-list'>
                                <img src={partnerImg4} alt="Trans Atlantic Container Lines" className="img-fluid" />
                                <img src={partnerImg5} alt="Buan" className="img-fluid" />
                                <img src={partnerImg3} alt="Buan" className="img-fluid" />
                                <img src={partnerImg2} alt="Rachans Logistics" className="img-fluid" />
                                <img src={partnerImg1} alt="Mahild Freight" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="stats-section">
                    <div className='container'>
                        <div className="row">
                            <div className='col-md-12 stat-counter'>
                                <div className="count-detail">
                                    <img width={70} height={70} alt='Group' src={GroupImg}></img>
                                    <div>
                                        <h4 className="mb-0">24600+</h4>
                                        <p className="mb-0">Deliveries Done</p>
                                    </div>
                                </div>
                                <div className="count-detail">
                                    <img width={70} height={70} alt='Vector' src={VectorImg}></img>
                                    <div>
                                        <h4 className="mb-0">540+</h4>
                                        <p className="mb-0">Clients Worldwide</p>
                                    </div>
                                </div>
                                <div className="count-detail">
                                    <img width={70} height={70} alt='Team' src={teamImg}></img>
                                    <div>
                                        <h4 className="mb-0 mt-1">22+</h4>
                                        <p className="mb-0">People in Team</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
