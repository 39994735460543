import React, { Component } from 'react'
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import scan from '../../assets/noun_scan_3165200.svg'
import './Modal.css'
import {srRequest} from '../../api/Form';
import { tsid } from '../../utils/utils';

export default class Modals extends Component {
  state = {
    modal: true,
    camScan: false,
    delay: 100,
    scan: 'Scan QR Code',
    receiverType: 'Normal',
    nfcADcode: '',
    phoneNumber: '',
    deviceADcode: ''
  }

  async componentDidMount() {
    const data = await localStorage.getItem('auth');
    const { ADCode } = JSON.parse(data);
    this.setState({ deviceADcode: ADCode });
  }

  inputChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  scanHandler = () => {
    this.addParcelComponentHandler({nfcADcode: this.state.nfcADcode, phoneNumber: this.state.phoneNumber, nfcserialno: tsid(10)});
  }
  
  toggleCloseHandler = () => {
    this.props.close.push('/admin/index')
  }

  addOverrideHandler = () => {
    this.props.overrideHandler();
  }
  
  receiverTypeHandler = (e) => {
    this.setState({ receiverType: e })
  }
  
  addParcelComponentHandler = (values) => {
    if (this.state.receiverType === 'SR') {
      this.setState({ isLoading: true});
      srRequest({ nfcADcode: values.nfcADcode, phoneNumber: values.phoneNumber})
      .then(response => {
               this.props.scan({nfcADcode: values.nfcADcode, type: this.state.receiverType, phoneNumber: this.state.phoneNumber, nfcserialno: values.nfcserialno});
                this.setState({ isLoading: false });
            })
            .catch(err => {
                console.log(err.response, 'err');
                this.setState({ isLoading: false });
            })
    } else {
      this.props.scan({nfcADcode: values.nfcADcode, type: this.state.receiverType, phoneNumber: this.state.phoneNumber, nfcserialno: values.nfcserialno});

    }
};
  render() {
    return (
      <div>
        <Modal isOpen={this.state.modal} >
          <ModalBody>
            <>
              <div className="row pt-3 d-flex justify-content-center">
                <div className="col-8 py-2 d-flex justify-content-center">
                  <p>{this.props.overrideMessage}</p>
                </div>
              <div className="col-12 d-flex justify-content-center">
                <div className="row">
                    <button className="btn btn btn-submit mr-2" onClick={this.addOverrideHandler} >Yes</button>
                    <button className="btn btn btn-secondary" onClick={this.toggleCloseHandler}>No</button>
                </div>
              </div>
            </div>
            </>
          </ModalBody>
        </Modal>
      </div >
    )
  }
}
