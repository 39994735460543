import React, { Component } from 'react'
import { Button, Card, CardBody, CardHeader } from 'reactstrap'
import { checkScreenAccess, requestAccess } from '../../api/Form';
import { toast } from 'react-toastify';
import { phoneNoVerifyCheck } from '../../utils/utils';

export default class DeleteShipmentAccessRequest extends Component {
    state = {
        requestAccess: true,
        loading: true,
    }

    async componentDidMount() {
        const historyPush = this.props.history.push
        phoneNoVerifyCheck(historyPush)
        const userId = await localStorage.getItem('userId');
        this.setState({ userId })
        var userType = await localStorage.getItem('accounttypeUsers');
        if (userType !== 'Admin') {
            await checkScreenAccess(
                {
                    'userId': userId,
                    'screenType': 'Delete Shipment'
                }
            ).then((response) => {
                this.setState({ loading: false, requestAccess: true });
            }).catch(err => {
                this.setState({ loading: false, requestAccess: false });
            });
        } else {
            this.setState({ requestAccess: true, loading: false })
        }
    }

    sendRequestAccess = async () => {
        this.setState({ loading: true });
        const info = await localStorage.getItem('auth');
        const sendInfo = JSON.parse(info);
        requestAccess({
            'username': sendInfo.username,
            'firstName': sendInfo.firstName,
            'lastName': sendInfo.lastName,
            'accounttypeUsers': sendInfo.accounttypeUsers,
            'businessname': sendInfo.businessname,
            'ADCode': sendInfo.ADCode,
            'address': sendInfo.address,
            'email': sendInfo.email,
            'phoneNumber': sendInfo.phonenumber,
            'userId': sendInfo.id,
            'screenType': 'Delete Shipment',
        }).then(Response => {
            this.setState({ loading: false });
            toast.success(Response.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }).catch(err => {
            this.setState({ loading: false });
            let message = err.message;
            if (err.response && err.response.data.message) {
                message = err.response.data.message;
            }
            toast.error(message, {
                position: toast.POSITION.TOP_RIGHT
            });
        })
    };
    render() {
        const { requestAccess } = this.state;
        return (
            <>
                {!requestAccess ?
                    <div className="justify-content-center pt-2 main-contain-title">
                        <>
                            <p>This feature is restricted.</p>
                            <Button onClick={() => this.sendRequestAccess()}
                                disabled={this.state.loading}>Request Access for free</Button>
                        </>
                    </div>
                    :
                    <div className="mt-2 style">
                        <Card>
                            <CardHeader className="c-header">Deleted Shipments Access Request</CardHeader>
                            <CardBody>
                                <h3 className='text-info text-center mt-3'>
                                    Your delete shipment access request is approved now you can delete your shipment if you want!
                                </h3>
                                <div className='d-flex justify-content-center mt-4'>
                                    <button className="btn btn-submit" onClick={() => this.props.history.push('/admin/viewShipments')}>Go to Shipment</button>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                }
            </>
        )
    }
}
