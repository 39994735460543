import React, {Component, createRef} from 'react'
import {Button, Form, FormFeedback, FormGroup, Input, Label} from 'reactstrap';
import {Link} from 'react-router-dom';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {lostParcel} from '../../api/Form'
import {ClipLoader} from "react-spinners";

const SignupSchema = Yup.object().shape({
	mailowner_maildescription: Yup.string()
		.required('Required'),
	mailowner_courier: Yup.string()
		.required('Required'),
	mailowner_trackingnumber: Yup.string()
		.required('Required'),
	mailowner_name: Yup.string()
		.required('Required'),
	mailowner_phone: Yup.string()
		.required('Required'),
	mailowner_email: Yup.string()
		.email('Invalid email')
		.required('Required'),
	mailowner_addressonmail: Yup.string()
		.required('Required'),
	mailowner_nameonmail: Yup.string()
		.required('Required'),
});

export default class MissingParcel extends Component {
	form = createRef();
	state = {
		mailowner_maildescription: '',
		mailowner_courier: '',
		mailowner_trackingnumber: '',
		mailowner_name: '',
		mailowner_phone: '',
		mailowner_email: '',
		mailowner_addressonmail: '',
		mailowner_nameonmail: '',
		mailowner_edac: '',
		isLoading: false,
		transactionNumber: ''
	}


	submitHandler = (values) => {
		this.setState({isLoading: true});
		lostParcel(values)
			.then(Response => {
				this.setState({isLoading: false, transactionNumber: Response.data.data.transaction_number}, () => {
					localStorage.setItem('transactionNumber', Response.data.data.transaction_number)
					localStorage.setItem('missingParcel', JSON.stringify(values))
					this.form.current.submit();
				});
			})
			.catch(err => {
				toast.error(err.response.data.message, {
					position: toast.POSITION.TOP_RIGHT
				});
				this.setState({isLoading: false});
			})
	}

	render() {
		console.log(this.props)
		return (
			<div>
				<Formik
					initialValues={{
						mailowner_maildescription: this.state.mailowner_maildescription,
						mailowner_edac: this.state.mailowner_edac,
						mailowner_courier: this.state.mailowner_courier,
						mailowner_trackingnumber: this.state.mailowner_trackingnumber,
						mailowner_name: this.state.mailowner_name,
						mailowner_phone: this.state.mailowner_phone,
						mailowner_email: this.state.mailowner_email,
						mailowner_addressonmail: this.state.mailowner_addressonmail,
						mailowner_nameonmail: this.state.mailowner_nameonmail
					}}
					validationSchema={SignupSchema}
					onSubmit={(values, {setErrors,}) => {
						this.setErrors = setErrors;
						this.submitHandler(values);
					}}
				>{({
					   values,
					   errors,
					   touched,
					   handleChange,
					   handleSubmit,
					   handleBlur,
					   setFieldValue,
				   }) => (
					<div className=" w-100 h-100">
						<div className="container">
							<div className="row pt-5">
								<Link class="btn btn-back" to="/"><i class="fa fa-long-arrow-left pr-3"> </i> Go Back</Link>
								<div className="ml-auto">
									<button class="btn btn-login1 mr-3">Download Template</button>
								</div>
							</div>
							<hr/>
							<div className="main-contain-style ">
								<div>
									<h1 className=" main-contain-title">My mail is either missing or lost</h1>
									<p className="main-contain-description">If your mail is delayed, lost OR missing, you can log it here,
										we will notify you by SMS as
										soon as it has been reported. Cost is USD 3.99 per lost or missing tracking number. 100% refund
										guaranteed no question asked if not satisfied.
										For multiple reporting download and use template provided & email to support@addressdynamic.com.
										For information on how we use the information you provide on our website read our privacy
										policy.</p>
								</div>
							</div>
							<Form>
								<div className="row">
									<div className="col-12">
										<FormGroup className="form13-size-style">
											<Label className="label-style">Describe Mail</Label>
											<Input type="textarea"
											       invalid={errors.mailowner_maildescription && touched.mailowner_maildescription}
											       name="mailowner_maildescription" className="input-style" onChange={handleChange}
											       onBlur={handleBlur}
											       value={values.mailowner_maildescription}
											       placeholder="Briefly describe the mail include track# if available, e.g mail is a letter from abcbank"/>
											<FormFeedback>
												{errors.mailowner_maildescription && touched.mailowner_maildescription && errors.mailowner_maildescription}
											</FormFeedback>
										</FormGroup>
									</div>
									<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
										<FormGroup className="form13-size-style">
											<Label className="label-style">Courier Name</Label>
											<Input type="text"
											       invalid={errors.mailowner_courier && touched.mailowner_courier}
											       name="mailowner_courier" className="input-style" onChange={handleChange}
											       onBlur={handleBlur}
											       value={values.mailowner_courier} placeholder="Enter the courier service provider"/>
											<FormFeedback>
												{errors.mailowner_courier && touched.mailowner_courier && errors.mailowner_courier}
											</FormFeedback>
										</FormGroup>
									</div>
									<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
										<FormGroup className="form13-size-style">
											<Label className="label-style">Track#</Label>
											<Input type="text"
											       invalid={errors.mailowner_trackingnumber && touched.mailowner_trackingnumber}
											       name="mailowner_trackingnumber" className="input-style" onChange={handleChange}
											       onBlur={handleBlur}
											       value={values.mailowner_trackingnumber} placeholder="Enter mail tracking number if any"/>
											<FormFeedback>
												{errors.mailowner_trackingnumber && touched.mailowner_trackingnumber && errors.mailowner_trackingnumber}
											</FormFeedback>
										</FormGroup>
									</div>
									<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
										<FormGroup className="form13-size-style">
											<Label className="label-style">Your Full Name</Label>
											<Input type="text"
											       invalid={errors.mailowner_name && touched.mailowner_name}
											       name="mailowner_name" className="input-style" onChange={handleChange} onBlur={handleBlur}
											       value={values.mailowner_name} placeholder="Enter your full name"/>
											<FormFeedback>
												{errors.mailowner_name && touched.mailowner_name && errors.mailowner_name}
											</FormFeedback>
										</FormGroup>
									</div>
									<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
										<FormGroup className="form13-size-style">
											<Label className="label-style">Your Mobile Phone Intl country code required e.g.
												+12345678912</Label>
											<Input type="text"
											       invalid={errors.mailowner_phone && touched.mailowner_phone}
											       name="mailowner_phone" className="input-style" onChange={handleChange} onBlur={handleBlur}
											       value={values.mailowner_phone} placeholder="Enter your phone, including country code"/>
											<FormFeedback>
												{errors.mailowner_phone && touched.mailowner_phone && errors.mailowner_phone}
											</FormFeedback>
										</FormGroup>
									</div>
									<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
										<FormGroup className="form13-size-style">
											<Label className="label-style">Your Email </Label>
											<Input type="text"
											       invalid={errors.mailowner_email && touched.mailowner_email}
											       name="mailowner_email" className="input-style" onChange={handleChange} onBlur={handleBlur}
											       value={values.mailowner_email} placeholder="Enter your email"/>
											<FormFeedback>
												{errors.mailowner_email && touched.mailowner_email && errors.mailowner_email}
											</FormFeedback>
										</FormGroup>
									</div>
									<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
										<FormGroup className="form13-size-style">
											<Label className="label-style">Address as it appears on mail </Label>
											<Input type="text"
											       invalid={errors.mailowner_addressonmail && touched.mailowner_addressonmail}
											       name="mailowner_addressonmail" className="input-style" onChange={handleChange}
											       onBlur={handleBlur}
											       value={values.mailowner_addressonmail} placeholder="Enter address on the mail"/>
											<FormFeedback>
												{errors.mailowner_addressonmail && touched.mailowner_addressonmail && errors.mailowner_addressonmail}
											</FormFeedback>
										</FormGroup>
									</div>
									<div className="col-12">
										<FormGroup className="form13-size-style">
											<Label className="label-style">Name as it appears on mail</Label>
											<Input type="textarea"
											       invalid={errors.mailowner_nameonmail && touched.mailowner_nameonmail}
											       name="mailowner_nameonmail" className="input-style" onChange={handleChange}
											       onBlur={handleBlur}
											       value={values.mailowner_nameonmail} placeholder="Enter full name as it appears on mail "/>
											<FormFeedback>
												{errors.mailowner_nameonmail && touched.mailowner_nameonmail && errors.mailowner_nameonmail}
											</FormFeedback>
										</FormGroup>
									</div>
								</div>
								<div className="pt-3 d-flex justify-content-center pb-3">
									<Button className="btn btn-login" onClick={handleSubmit}>
										{this.state.isLoading ?
											< ClipLoader
												size={15}
												color={"#123abc"}
												loading={true}
											/> : 'Submit'
										}
									</Button>
								</div>
							</Form>
						</div>

					</div>
				)}
				</Formik>
				<form ref={this.form} action="https://www.paypal.com/cgi-bin/webscr" method="post" name="buyCredits"
				      id="buyCredits">
					<input type="hidden" name="cmd" value="_xclick"/>
					<input type="hidden" name="business" value="support@addressdynamic.com"/>
					<input type="hidden" name="currency_code" value="USD"/>
					<input type="hidden" name="item_name" value="payment for service"/>
					<input type="hidden" name="item_number" value=""/>
					<input type="hidden" name="amount" value="3.99"/>
					<input type="hidden" name="return"
					       value={`https://postkodes.com/paymentSuccess/my-mail-missing-lost-log/payment-success.php?transaction_number=${this.state.transactionNumber}`}/>
				</form>

			</div>
		)
	}
}
