import React, { Component } from 'react'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import './Navbar.css'
import logo from '../assets/new_logo.png'
export default class Navbars extends Component {
  state = {
    isOpen: false,
    adCodes: "",
    userName: ""
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  async componentDidMount() {

    const userName = await localStorage.getItem("firstName");
    const adCodes = await localStorage.getItem("adCodes");

    if (userName && adCodes) {
      this.setState({ userName: userName, adCodes: adCodes })
    }

    window.addEventListener('scroll', this.handleScroll);
  }



  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    // add class conditionally on scroll for sticky header
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
      document.getElementById("headerSticky").classList.add('header-sticky');
    }
    else if (document.body.scrollTop === 0 || document.documentElement.scrollTop === 0) {
      document.getElementById("headerSticky").classList.remove('header-sticky');
    }
  }

  render() {

    return (
      <div id='headerSticky' className='col-md-12'>
        <div className="container custom-container-border px-0">
          <Navbar className='navbar-custom' light expand="md">
            <NavbarBrand href="/" className="d-flex align-items-center">
              <img alt='Postkodes' src={logo} className="img-fluid pr-2 d-none d-sm-none d-md-block d-lg-block d-xl-block" width="50px" height="50px" />
              <span className="logo-style" >Postkod</span><span className="logo-style1">es</span>
            </NavbarBrand>

            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className='nav-custom' >
                <NavItem className='nav-item'>
                  <Link className="active" to="/">
                    Home
                  </Link>
                </NavItem>
                <NavItem className='nav-item'>
                  <Link to="/trackShipment">
                    Track Shipments
                  </Link>
                </NavItem>
                <NavItem className='nav-item'>
                  <Link to="/shipping-calculator">Shipping Calculator</Link>
                </NavItem>
                <NavItem className='nav-item'>
                  <Link to="/requesterrand">Request Errand</Link>
                </NavItem>
                <NavItem className='nav-item'>
                  {this.state.adCodes ?
                    <Link to="/admin/index">{this.state.userName}</Link> :
                    <Link to="/login">Login</Link>
                  }
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>
        </div>
      </div>
    )
  }
}
