import React, { Component } from 'react'
import { CardBody, Card, FormGroup, Label, Input } from 'reactstrap'
import './PrintForm.css'
import QRCode from "react-qr-code";
import Barcode from 'react-barcode';
import { ClipLoader } from "react-spinners";
import { getShipment } from '../../api/Form';
import { toast } from 'react-toastify';

export default class PrintForm2 extends Component {
    state = {
        size: '',
        refno: ''
    }
    componentDidMount() {
        const shipment = this.props.format
        const size = this.props.size
        this.setState({ ...shipment, size })
    }

    checkReference = () => {
        getShipment({ refno: this.state.refno })
            .then(response => {
                this.setState({ refno: response.data.data.refno });
            })
            .catch(err => {
                toast.error('This reference number does not exist!', {
                    position: toast.POSITION.TOP_RIGHT
                })
            })
    }

    render() {
        const { refno, size } = this.state
        return (
            <div className="printform2">
                
                        <div className="d-flex justify-content-end">
                            <span className="logo-style-print" >Postkod</span><span className="logo-style-print-1">es</span>
                        </div>
                        <hr className="border-style my-2" />
                        <>
                            <div className="row">
                                <div className="col-6">
                                    <QRCode size={70} value={refno} />
                                </div>
                        <div className="col-6 barcode-size">
                                    <Barcode  value={refno} />
                                    </div>
                            </div>
                        </>
            </div >
        )
    }
}
