import React, { Component } from 'react'
import { getShipment } from '../../api/Form';
import { image } from '../../api/URLS';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BarcodeReader from 'react-barcode-reader';
import { CardHeader, CardBody, FormGroup, Label, Input, Card, Button } from 'reactstrap';
import beep from '../../assets/audio/beep.mp3';

const Beep = new Audio(beep);

export default class Invoice extends Component {
	state = {
		invoice: '',
		refno: '',
		loading: false
	}

	handlerError = (error) => {
		console.log(error)
	}

	handleScan = (refno) => {
		if (refno && this.state.refno !== refno) {
			Beep.play();
			getShipment({ refno })
				.then(response => {
					const invoice = response.data.data.invoice;
					this.setState({ invoice, refno, loading: false })
				})
				.catch(err => {
					toast.error(err.response.data.message, {
						position: toast.POSITION.TOP_RIGHT
					});
					this.setState({ loading: false });
				})
		}
	}

	render() {
		const { invoice } = this.state;
		const downloadPDF = image + invoice;
		return (
			<div className="mt-2 style">
				<BarcodeReader
					onError={this.handlerError}
					onScan={this.handleScan} />
				<Card>
					<CardHeader className="c-header">Invoice</CardHeader>
					<CardBody>
						<div className="col-6">
							<FormGroup>
								<Label >Ref No</Label>
								<Input type="text" disabled name="refno" value={this.state.refno} placeholder="Enter AD Code" />
							</FormGroup>
							{this.state.invoice && <Button href={downloadPDF} ><i className="fa fa-download" aria-hidden="true"></i> Download</Button>}
						</div>
						{!this.state.invoice && this.state.refno &&
							<div className="d-flex justify-content-center align-items-center">
								<h1>Invoice not exist</h1>
							</div>}
					</CardBody>
				</Card>
			</div >
		)
	}
}
