import React, { Component, Fragment } from 'react'
import { Card, CardBody, CardHeader, FormGroup, Input, Label } from 'reactstrap'
import PrintInvoiceAdhoc from './Components/PrintInvoiceAdhoc';
import Select from 'react-select';
import { checkTrackNumber, checkUniqueNumber, generateAdhocInvoice } from '../../api/Form';
import { toast } from 'react-toastify';
import PhoneInput from 'react-intl-tel-input';
import { isValidNumber } from 'libphonenumber-js';
import 'react-intl-tel-input/dist/main.css';

const options = [
    { value: 'Afghanistan', label: 'Afghanistan' },
    { value: 'Aland Islands', label: 'Aland Islands' },
    { value: 'Albania', label: 'Albania' },
    { value: 'Algeria', label: 'Algeria' },
    { value: 'American Samoa', label: 'American Samoa' },
    { value: 'AndorrA', label: 'AndorrA' },
    { value: 'Angola', label: 'Angola' },
    { value: 'Anguilla', label: 'Anguilla' },
    { value: 'Antarctica', label: 'Antarctica' },
    { value: 'Antigua and Barbuda', label: 'Antigua and Barbuda' },
    { value: 'Argentina', label: 'Argentina' },
    { value: 'Armenia', label: 'Armenia' },
    { value: 'Aruba', label: 'Aruba' },
    { value: 'Australia', label: 'Australia' },
    { value: 'Austria', label: 'Australia' },
    { value: 'Azerbaijan', label: 'Azerbaijan' },
    { value: 'Bahamas', label: 'Bahamas' },
    { value: 'Bahrain', label: 'Bahrain' },
    { value: 'Bangladesh', label: 'Bangladesh' },
    { value: 'Barbados', label: 'Barbados' },
    { value: 'Belarus', label: 'Belarus' },
    { value: 'Belgium', label: 'Belgium' },
    { value: 'Belize', label: 'Belize' },
    { value: 'Benin', label: 'Benin' },
    { value: 'Bermuda', label: 'Bermuda' },
    { value: 'Bhutan', label: 'Bhutan' },
    { value: 'Bolivia', label: 'Bolivia' },
    { value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
    { value: 'Botswana', label: 'Botswana' },
    { value: 'Bouvet Island', label: 'Bouvet Island' },
    { value: 'Brazil', label: 'Brazil' },
    { value: 'British Indian Ocean Territory', label: 'British Indian Ocean Territory' },
    { value: 'Brunei Darussalam', label: 'Brunei Darussalam' },
    { value: 'Bulgaria', label: 'Bulgaria' },
    { value: 'Burkina Faso', label: 'Burkina Faso' },
    { value: 'Burundi', label: 'Burundi' },
    { value: 'Cambodia', label: 'Cambodia' },
    { value: 'Cameroon', label: 'Cameroon' },
    { value: 'Canada', label: 'Canada' },
    { value: 'Cape Verde', label: 'Cape Verde' },
    { value: 'Cayman Islands', label: 'Cayman Islands' },
    { value: 'Central African Republic', label: 'Central African Republic' },
    { value: 'Chad', label: 'Chad' },
    { value: 'Chile', label: 'Chile' },
    { value: 'China', label: 'China' },
    { value: 'Christmas Island', label: 'Christmas Island' },
    { value: 'Cocos (Keeling) Islands', label: 'Cocos (Keeling) Islands' },
    { value: 'Colombia', label: 'Colombia' },
    { value: 'Comoros', label: 'Comoros' },
    { value: 'Congo', label: 'Congo' },
    { value: 'Congo, The Democratic Republic of the', label: 'Congo, The Democratic Republic of the' },
    { value: 'Cook Islands', label: 'Cook Islands' },
    { value: 'Costa Rica', label: 'Costa Rica' },
    { value: "Cote DIvoire", label: "Cote D'Ivoire" },
    { value: 'Croatia', label: 'Croatia' },
    { value: 'Cuba', label: 'Cuba' },
    { value: 'Cyprus', label: 'Cyprus' },
    { value: 'Czech Republic', label: 'Czech Republic' },
    { value: 'Denmark', label: 'Denmark' },
    { value: 'Djibouti', label: 'Djibouti' },
    { value: 'Dominica', label: 'Dominica' },
    { value: 'Dominican Republic', label: 'Dominican Republic' },
    { value: 'Ecuador', label: 'Ecuador' },
    { value: 'Egypt', label: 'Egypt' },
    { value: 'El Salvador', label: 'El Salvador' },
    { value: 'Equatorial Guinea', label: 'Equatorial Guinea' },
    { value: 'Eritrea', label: 'Eritrea' },
    { value: 'Estonia', label: 'Estonia' },
    { value: 'Ethiopia', label: 'Ethiopia' },
    { value: 'Falkland Islands (Malvinas)', label: 'Falkland Islands (Malvinas)' },
    { value: 'Faroe Islands', label: 'Faroe Islands' },
    { value: 'Fiji', label: 'Fiji' },
    { value: 'Finland', label: 'Finland' },
    { value: 'France', label: 'France' },
    { value: 'French Guiana', label: 'French Guiana' },
    { value: 'French Polynesia', label: 'French Polynesia' },
    { value: 'French Southern Territories', label: 'French Southern Territories' },
    { value: 'Gabon', label: 'Gabon' },
    { value: 'Gambia', label: 'Gambia' },
    { value: 'Georgia', label: 'Georgia' },
    { value: 'Germany', label: 'Germany' },
    { value: 'Ghana', label: 'Ghana' },
    { value: 'Gibraltar', label: 'Gibraltar' },
    { value: 'Greece', label: 'Greece' },
    { value: 'Greenland', label: 'Greenland' },
    { value: 'Grenada', label: 'Grenada' },
    { value: 'Guadeloupe', label: 'Guadeloupe' },
    { value: 'Guam', label: 'Guam' },
    { value: 'Guatemala', label: 'Guatemala' },
    { value: 'Guernsey', label: 'Guernsey' },
    { value: 'Guinea', label: 'Guinea' },
    { value: 'Guinea-Bissau', label: 'Guinea-Bissau' },
    { value: 'Guyana', label: 'Guyana' },
    { value: 'Haiti', label: 'Haiti' },
    { value: 'Heard Island and Mcdonald Islands', label: 'Heard Island and Mcdonald Islands' },
    { value: 'Holy See (Vatican City State)', label: 'Holy See (Vatican City State)' },
    { value: 'Honduras', label: 'Honduras' },
    { value: 'Hong Kong', label: 'Hong Kong' },
    { value: 'Hungary', label: 'Hungary' },
    { value: 'Iceland', label: 'Iceland' },
    { value: 'India', label: 'India' },
    { value: 'Indonesia', label: 'Indonesia' },
    { value: 'Iran, Islamic Republic Of', label: 'Iran, Islamic Republic Of' },
    { value: 'Iraq', label: 'Iraq' },
    { value: 'Ireland', label: 'Ireland' },
    { value: 'Isle of Man', label: 'Isle of Man' },
    { value: 'Israel', label: 'Israel' },
    { value: 'Italy', label: 'Italy' },
    { value: 'Jamaica', label: 'Jamaica' },
    { value: 'Japan', label: 'Japan' },
    { value: 'Jersey', label: 'Jersey' },
    { value: 'Jordan', label: 'Jordan' },
    { value: 'Kazakhstan', label: 'Kazakhstan' },
    { value: 'Kenya', label: 'Kenya' },
    { value: 'Kiribati', label: 'Kiribati' },
    { value: "Korea, Democratic PeopleS Republic of", label: "Korea, Democratic People'S Republic of" },
    { value: 'Korea, Republic of', label: 'Korea, Republic of' },
    { value: 'Kuwait', label: 'Kuwait' },
    { value: 'Kyrgyzstan', label: 'Kyrgyzstan' },
    { value: "Lao PeopleS Democratic Republic", label: "Lao People'S Democratic Republic" },
    { value: 'Latvia', label: 'Latvia' },
    { value: 'Lebanon', label: 'Lebanon' },
    { value: 'Lesotho', label: 'Lesotho' },
    { value: 'Liberia', label: 'Liberia' },
    { value: 'Libyan Arab Jamahiriya', label: 'Libyan Arab Jamahiriya' },
    { value: 'Liechtenstein', label: 'Liechtenstein' },
    { value: 'Lithuania', label: 'Lithuania' },
    { value: 'Luxembourg', label: 'Luxembourg' },
    { value: 'Macao', label: 'Macao' },
    { value: 'Macedonia, The Former Yugoslav Republic of', label: 'Macedonia, The Former Yugoslav Republic of' },
    { value: 'Madagascar', label: 'Madagascar' },
    { value: 'Malawi', label: 'Malawi' },
    { value: 'Malaysia', label: 'Malaysia' },
    { value: 'Maldives', label: 'Maldives' },
    { value: 'Mali', label: 'Mali' },
    { value: 'Malta', label: 'Malta' },
    { value: 'Marshall Islands', label: 'Marshall Islands' },
    { value: 'Martinique', label: 'Martinique' },
    { value: 'Mauritania', label: 'Mauritania' },
    { value: 'Mauritius', label: 'Mauritius' },
    { value: 'Mayotte', label: 'Mayotte' },
    { value: 'Mexico', label: 'Mexico' },
    { value: 'Micronesia, Federated States of', label: 'Micronesia, Federated States of' },
    { value: 'Moldova, Republic of', label: 'Moldova, Republic of' },
    { value: 'Monaco', label: 'Monaco' },
    { value: 'Mongolia', label: 'Mongolia' },
    { value: 'Montserrat', label: 'Montserrat' },
    { value: 'Morocco', label: 'Morocco' },
    { value: 'Mozambique', label: 'Mozambique' },
    { value: 'Myanmar', label: 'Myanmar' },
    { value: 'Namibia', label: 'Namibia' },
    { value: 'Nauru', label: 'Nauru' },
    { value: 'Nepal', label: 'Nepal' },
    { value: 'Netherlands', label: 'Netherlands' },
    { value: 'Netherlands Antilles', label: 'Netherlands Antilles' },
    { value: 'New Caledonia', label: 'New Caledonia' },
    { value: 'New Zealand', label: 'New Zealand' },
    { value: 'Nicaragua', label: 'Nicaragua' },
    { value: 'Niger', label: 'Niger' },
    { value: 'Nigeria', label: 'Nigeria' },
    { value: 'Niue', label: 'Niue' },
    { value: 'Norfolk Island', label: 'Norfolk Island' },
    { value: 'Northern Mariana Islands', label: 'Northern Mariana Islands' },
    { value: 'Norway', label: 'Norway' },
    { value: 'Oman', label: 'Oman' },
    { value: 'Pakistan', label: 'Pakistan' },
    { value: 'Palau', label: 'Palau' },
    { value: 'Palestinian Territory, Occupied', label: 'Palestinian Territory, Occupied' },
    { value: 'Panama', label: 'Panama' },
    { value: 'Papua New Guinea', label: 'Papua New Guinea' },
    { value: 'Paraguay', label: 'Paraguay' },
    { value: 'Peru', label: 'Peru' },
    { value: 'Philippines', label: 'Philippines' },
    { value: 'Pitcairn', label: 'Pitcairn' },
    { value: 'Poland', label: 'Poland' },
    { value: 'Portugal', label: 'Portugal' },
    { value: 'Puerto Rico', label: 'Puerto Rico' },
    { value: 'Qatar', label: 'Qatar' },
    { value: 'Reunion', label: 'Reunion' },
    { value: 'Romania', label: 'Romania' },
    { value: 'Russian Federation', label: 'Russian Federation' },
    { value: 'RWANDA', label: 'RWANDA' },
    { value: 'Saint Helena', label: 'Saint Helena' },
    { value: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis' },
    { value: 'Saint Lucia', label: 'Saint Lucia' },
    { value: 'Saint Pierre and Miquelon', label: 'Saint Pierre and Miquelon' },
    { value: 'Saint Vincent and the Grenadines', label: 'Saint Vincent and the Grenadines' },
    { value: 'Samoa', label: 'Samoa' },
    { value: 'San Marino', label: 'San Marino' },
    { value: 'Sao Tome and Principe', label: 'Sao Tome and Principe' },
    { value: 'Saudi Arabia', label: 'Saudi Arabia' },
    { value: 'Senegal', label: 'Senegal' },
    { value: 'Serbia and Montenegro', label: 'Serbia and Montenegro' },
    { value: 'Seychelles', label: 'Seychelles' },
    { value: 'Sierra Leone', label: 'Sierra Leone' },
    { value: 'Singapore', label: 'Singapore' },
    { value: 'Slovakia', label: 'Slovakia' },
    { value: 'Slovenia', label: 'Slovenia' },
    { value: 'Solomon Islands', label: 'Solomon Islands' },
    { value: 'Somalia', label: 'Somalia' },
    { value: 'South Africa', label: 'South Africa' },
    { value: 'South Georgia and the South Sandwich Islands', label: 'South Georgia and the South Sandwich Islands' },
    { value: 'Spain', label: 'Spain' },
    { value: 'Sri Lanka', label: 'Sri Lanka' },
    { value: 'Sudan', label: 'Sudan' },
    { value: 'Suriname', label: 'Suriname' },
    { value: 'Svalbard and Jan Mayen', label: 'Svalbard and Jan Mayen' },
    { value: 'Swaziland', label: 'Swaziland' },
    { value: 'Sweden', label: 'Sweden' },
    { value: 'Switzerland', label: 'Switzerland' },
    { value: 'Syrian Arab Republic', label: 'Syrian Arab Republic' },
    { value: 'Taiwan, Province of China', label: 'Taiwan, Province of China' },
    { value: 'Tajikistan', label: 'Tajikistan' },
    { value: 'Tanzania, United Republic of', label: 'Tanzania' },
    { value: 'Thailand', label: 'Thailand' },
    { value: 'Timor-Leste', label: 'Timor-Leste' },
    { value: 'Togo', label: 'Togo' },
    { value: 'Tokelau', label: 'Tokelau' },
    { value: 'Tonga', label: 'Tonga' },
    { value: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
    { value: 'Tunisia', label: 'Tunisia' },
    { value: 'Turkey', label: 'Turkey' },
    { value: 'Turkmenistan', label: 'Turkmenistan' },
    { value: 'Turks and Caicos Islands', label: 'Turks and Caicos Islands' },
    { value: 'Tuvalu', label: 'Tuvalu' },
    { value: 'Uganda', label: 'Uganda' },
    { value: 'Ukraine', label: 'Ukraine' },
    { value: 'United Arab Emirates', label: 'United Arab Emirates' },
    { value: 'United Kingdom', label: 'United Kingdom' },
    { value: 'United States', label: 'United States' },
    { value: 'United States Minor Outlying Islands', label: 'United States Minor Outlying Islands' },
    { value: 'Uruguay', label: 'Uruguay' },
    { value: 'Uzbekistan', label: 'Uzbekistan' },
    { value: 'Vanuatu', label: 'Vanuatu' },
    { value: 'Venezuela', label: 'Venezuela' },
    { value: 'Viet Nam', label: 'Viet Nam' },
    { value: 'Virgin Islands, British', label: 'Virgin Islands, British' },
    { value: 'Virgin Islands, U.S.', label: 'Virgin Islands, U.S.' },
    { value: 'Wallis and Futuna', label: 'Wallis and Futuna' },
    { value: 'Western Sahara', label: 'Western Sahara' },
    { value: 'Yemen', label: 'Yemen' },
    { value: 'Zambia', label: 'Zambia' },
    { value: 'Zimbabwe', label: 'Zimbabwe' }
];

const categoryOption = [
    { label: 'Created', value: 'created' },
    { label: 'Pickup', value: 'Pickup' },
    { label: 'Transfer', value: 'Transfer' },
    { label: 'Customs', value: 'Customs' },
    { label: 'Delivered', value: 'Delivered' },
    { label: 'Received', value: 'Received' },
    { label: 'Despatched', value: 'Despatched' },
    { label: 'Sorting Facility', value: 'Sorting Facility' },
    { label: 'Onboard to Delivery Address', value: 'Onboard to Delivery Address' },
    { label: 'Held at Customs', value: 'Held at Customs' },
    { label: 'Custom Released', value: 'Custom Released' },
    { label: 'Delivery Missed', value: 'Delivery Missed' },
    { label: 'Delivery Exception', value: 'Delivery Exception' },
    { label: 'Intransit', value: 'Intransit' },
    { label: 'Custom Cleared', value: 'Custom Cleared' },
    { label: 'Airport Transfer', value: 'Airport Transfer' },
    { label: 'Destination Airport', value: 'Destination Airport' },
    { label: 'Onforwarded', value: 'Onforwarded' },
    { label: 'Arrived', value: 'Arrived' },
    { label: 'Departed', value: 'Departed' },
    { label: 'Custom Inspection', value: 'Custom Inspection' },
    { label: 'Collect', value: 'Collect' },
    { label: 'Collected', value: 'Collected' },
    { label: 'Accepted', value: 'Accepted' },
    { label: 'Warehouse Received', value: 'Warehouse Received' },
    { label: 'Outbound Staged', value: 'Outbound Staged' },
    { label: 'Biosecurity Inspection', value: 'Biosecurity Inspection' },
    { label: 'Cancelled', value: 'Cancelled' },
    { label: 'Withdrawn', value: 'Withdrawn' },
    { label: 'Airline Transfer', value: 'Airline Transfer' },
    { label: 'Damaged', value: 'Damaged' },
    { label: 'Lost', value: 'Lost' },
    { label: 'Missing', value: 'Missing' },
    { label: 'Abandoned', value: 'Abandoned' },
    { label: 'Unclaimed', value: 'Unclaimed' },
    { label: 'Biosecurity', value: 'Biosecurity Destruction' },
    { label: 'Destruction', value: 'Destruction' },
    { label: 'Partial Disposal', value: 'Partial Disposal' },
    { label: 'Biosecurity Control', value: 'Biosecurity Control' },
    { label: 'Re-Export', value: 'Re-Export' },
    { label: 'Tran-Shipment', value: 'Tran-Shipment' },
];

const packageTypeOption = [
    { value: 'box', label: 'Box' },
    { value: 'satchel', label: 'Satchel' },
    { value: 'bag', label: 'Bag' },
    { value: 'other', label: 'Other' }
];

let data = {};

export default class GenerateInvoices extends Component {
    state = {
        invoice: '',
        showPopup: false,
        print: false,
        nfcADcode: '',
        deviceADcode: '',
        randomInvoice: "",
        inputValue: '',
        filedOptions: [],
        userId: '',
        tempId: '',
        refValue: "",
        uniqueNoChecked: false,
        showModal: false,
        showAllFiled: false,
        printData: "",
        invoiceName: "",
        valueAll: [],
        refNumber: "",
        selectValues: {},
        valuesRecieved: false,
        isValidPhoneNo: true,
    }

    async componentDidMount() {
        const refno = this.props.location.state;
        const { custom_fields } = refno
        this.setState({ valueAll: refno })
        this.setState({ filedOptions: JSON.parse(custom_fields) })
        this.setState({ tempId: refno.id })
        const data = await localStorage.getItem('auth');
        const { ADCode } = JSON.parse(data);
        this.setState({ deviceADcode: ADCode });
        const userId = await localStorage.getItem('userId');
        this.setState({ userId })
    }

    setFieldValues = (values) => {
        this.setState({ showAllFiled: true, uniqueNoChecked: true, randomInvoice: values.ad_code })
        this.state.filedOptions.forEach(element => {
            if (values[element.key] !== null && values[element.key] !== '0') {
                data[element.key] = values[element.key]
                if (element.type === "select") {
                    if (element.key === "destination_country" || element.key === "origin_country") {
                        let name = element.key
                        this.setState({
                            selectValues: {
                                ...this.state.selectValues,
                                [name]: options.find(o => o.value === values[element.key])
                            }
                        })
                    } else if (element.key === "status") {
                        let name = element.key
                        this.setState({
                            selectValues: {
                                ...this.state.selectValues,
                                [name]: categoryOption.find(o => o.value === values[element.key])
                            }
                        })
                    } else if (element.key === "package_type") {
                        let name = element.key
                        this.setState({
                            selectValues: {
                                ...this.state.selectValues,
                                [name]: packageTypeOption.find(o => o.value === values[element.key])
                            }
                        })
                    }
                } else if (element.type === "text" && (element.key === "shipper_phone" || element.key === "receiver_phone" || element.key === "other_phone")) {
                    let name = element.key
                    this.setState({
                        selectValues: {
                            ...this.state.selectValues,
                            [name]: values[element.key]
                        }
                    })
                } else {
                    if (element.key === "dimension") {
                        ['height', 'width', 'length'].forEach((item) => {
                            if (values[item] !== '0') {
                                data[item] = values[item]
                                setTimeout(() => document.getElementById(item).value = values[item], 1000)
                            }
                        })
                    } else {
                        setTimeout(() => document.getElementById(element.key).value = values[element.key], 1000)
                    }
                }
            }
        })
        this.setState({ valuesRecieved: true })
    }

    handleTitle = (val, code) => {
        data[code] = val;
    };

    generateInvoiceNumber = () => {
        const timestamp = new Date().getTime();
        return this.setState({ randomInvoice: timestamp });
    };

    checkUniqueNumber = async () => {
        if (this.state.randomInvoice !== undefined && this.state.randomInvoice !== "" && this.state.randomInvoice !== null) {
            if (this.state.refValue !== undefined && this.state.refValue !== "" && this.state.refValue !== null) {
                await checkUniqueNumber({
                    ad_code: this.state.randomInvoice,
                    ref_no: this.state.refValue
                }).then((response) => {
                    if (response.data.status === true) {
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        })
                        this.setState({ loading: false, showAllFiled: true, uniqueNoChecked: true })
                    }
                }).catch((err) => {
                    console.log(err, "errorrr")
                    this.setState({ loading: false })
                    toast.error(err.response.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                })
            } else {
                toast.error('Tracking Number is required', {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        } else {
            toast.error('Invoice Number is required', {
                position: toast.POSITION.TOP_RIGHT
            })
        }
    }

    checkRefNumber = async () => {
        if (this.state.refValue !== undefined && this.state.refValue !== "" && this.state.refValue !== null) {
            await checkTrackNumber({
                userId: this.state.userId,
                deviceADcode: this.state.deviceADcode,
                ref_no: this.state.refValue
            }).then((response) => {
                if (response.data.status === true) {
                    if (response.data.data === null) {
                        toast.success(`${response.data.message} Please verify your unique invoice number now.`, {
                            position: toast.POSITION.TOP_RIGHT
                        })
                        this.generateInvoiceNumber()
                        this.setState({ valuesRecieved: true })
                    }
                    else {
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        })
                        this.setFieldValues(response.data.data)
                    }
                }
            }).catch((err) => {
                console.log(err, "errorrr")
                this.setState({ loading: false, refValue: "" })
                toast.error(err.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                })
            })
        } else {
            toast.error('Tracking Number is required', {
                position: toast.POSITION.TOP_RIGHT
            })
        }
    }

    printInvoice = async () => {
        let flag = false
        this.state.filedOptions.forEach(element => {
            if (element.key === 'dimension') {
                if (data['height'] === undefined || data['height'] === '' || data['height'] === null || data['width'] === undefined || data['width'] === '' || data['width'] === null || data['length'] === undefined || data['length'] === '' || data['length'] === null) {
                    flag = true
                    toast.error(`Dimension field values are required`, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
            } else {
                if (data[element.key] === undefined || data[element.key] === '' || data[element.key] === null) {
                    flag = true
                    toast.error(`${element['label_name']} is required`, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
            }
        });
        if (!flag) {
            if (this.state.refValue !== undefined && this.state.refValue !== "" && this.state.refValue !== null) {
                if (this.state.uniqueNoChecked) {
                    data["userId"] = this.state.userId
                    data["ad_code"] = this.state.randomInvoice
                    data["ref_no"] = this.state.refValue
                    data["template_id"] = this.state.valueAll.id
                    await generateAdhocInvoice(data).then((response) => {
                        this.setState({ loading: false, showModal: true })
                        this.setState({ print: true });
                        this.setState({ printData: data });
                    }).catch((err) => {
                        console.log(err, "errorrr")
                        this.setState({ loading: false })
                        toast.error(err.response.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        })
                    })
                } else {
                    toast.error('Unique Invoice Number not verified yet.', {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
            }
            else {
                toast.error('Tracking Number is required', {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        }
    }

    render() {
        const { showModal, filedOptions, refValue, showAllFiled, valueAll } = this.state
        return (
            <div className="mt-2 style">
                <Card>
                    <CardHeader className="c-header">Generate Invoice</CardHeader>
                    <CardBody>
                        <div className=" mb-3">
                            <div>
                                {
                                    this.state.print ?
                                        <PrintInvoiceAdhoc
                                            invoice={this.state.printData}
                                            size={this.state.size}
                                            showPopup={() => this.setState({ showPopup: true })}
                                            reset={() => this.setState({ print: false, printButton: false })}
                                        />
                                        :
                                        <Fragment>
                                            <div className="col-12">
                                                <FormGroup>
                                                    <Label>Name</Label>
                                                    <Input
                                                        type="text"
                                                        name="nfcADcode"
                                                        placeholder="Name"
                                                        value={this.state.invoiceName ? this.state.invoiceName : valueAll.name}
                                                        onChange={(e) => this.setState({ invoiceName: e.target.value })}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-12 form-group">
                                                <Label className="mb-2">Enter Tracking Number</Label>
                                                <div className="input-group unique-check-btn">
                                                    <Input
                                                        className="track-input"
                                                        type="text"
                                                        id="outlined-basic"
                                                        placeholder="Enter Tracking Number"
                                                        variant="outlined"
                                                        value={refValue}
                                                        onChange={(e) => this.setState({ refValue: e.target.value })}
                                                    />
                                                    <button className="btn btn-submit ml-2 unique-check" onClick={this.checkRefNumber}>Search</button>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <FormGroup>
                                                    <Label>Unique Invoice Number</Label>
                                                    <div className='input-group unique-check-btn'>
                                                        <div className='d-flex position-relative invBox'>
                                                            <Input
                                                                type="text"
                                                                className="scan-input"
                                                                name="nfcADcode"
                                                                placeholder="Unique Invoice Number"
                                                                value={this.state.randomInvoice ? this.state.randomInvoice : valueAll.ad_code}
                                                                disabled
                                                            />
                                                            <i className="fa fa-bars refNo-2 d-flex" onClick={this.generateInvoiceNumber}></i>
                                                        </div>
                                                        <button className="btn btn-submit ml-2 unique-check" onClick={this.checkUniqueNumber}>Check Unique Number</button>
                                                    </div>
                                                </FormGroup>
                                            </div>
                                            {
                                                showAllFiled &&
                                                <>
                                                    {
                                                        filedOptions && filedOptions.map((item) => {
                                                            return (
                                                                <Fragment key={item.key}>
                                                                    {/* {
                                                                        item.key === "ref_no" &&
                                                                        <div className="col-12 form-group">
                                                                            <Label className="mb-2">{item.label_name}</Label>
                                                                            <div className="input-group">
                                                                                <Input
                                                                                    type={item.type}
                                                                                    id="outlined-basic"
                                                                                    placeholder={item.label_name}
                                                                                    variant="outlined"
                                                                                    value={valueAll.ref_no ? valueAll.ref_no : refValue}
                                                                                    onChange={(e) => this.setState({ refValue: e.target.value })}
                                                                                />
                                                                                <button className="btn btn-submit ml-2" onClick={this.checkRefNumber}>Search</button>
                                                                            </div>
                                                                        </div>
                                                                    } */}
                                                                    {
                                                                        item.type === "text" && item.key !== "ref_no" && item.key !== "dimension" && item.key !== "shipper_phone" && item.key !== "receiver_phone" && item.key !== "other_phone" &&
                                                                        <div className="col-12 form-group">
                                                                            <Label className="mb-2">{item.label_name} {item.label_name === "Weight" && '(kg)'} </Label>
                                                                            <Input
                                                                                type={item.type}
                                                                                id={item.key}
                                                                                placeholder={item.label_name}
                                                                                variant="outlined"
                                                                                onChange={(e,) => {
                                                                                    this.handleTitle(e.target.value, item.key);
                                                                                }}
                                                                            // disabled={!showModal}
                                                                            />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        item.type === "text" && item.key === "dimension" &&
                                                                        <div className="col-12 form-group">
                                                                            <Label className="mb-2">{item.label_name} (cm)</Label>
                                                                            <div className="row">
                                                                                <div className="col-lg-4 col-xs-12 col-sm-12">
                                                                                    <Input type={item.type}
                                                                                        id="height"
                                                                                        variant="outlined"
                                                                                        placeholder="Enter Height of Dimension"
                                                                                        onChange={(e,) => {
                                                                                            this.handleTitle(e.target.value, 'height');
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-lg-4 col-xs-12 col-sm-12">
                                                                                    <Input type={item.type}
                                                                                        id="width"
                                                                                        className="mt-sm-3 mt-lg-0"
                                                                                        variant="outlined"
                                                                                        placeholder="Enter Width of Dimension"
                                                                                        onChange={(e,) => {
                                                                                            this.handleTitle(e.target.value, 'width');
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-lg-4 col-xs-12 col-sm-12 ">
                                                                                    <Input type={item.type}
                                                                                        id="length"
                                                                                        className="mt-sm-3 mt-lg-0"
                                                                                        variant="outlined"
                                                                                        placeholder="Enter Length of Dimension"
                                                                                        onChange={(e,) => {
                                                                                            this.handleTitle(e.target.value, 'length');
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        item.type === "select" && this.state.valuesRecieved &&
                                                                            item.key === "origin_country" ?
                                                                            <div className="col-12">
                                                                                <FormGroup>
                                                                                    <Label>{item.label_name}</Label>
                                                                                    <Select
                                                                                        id={item.key}
                                                                                        onChange={(e) => {
                                                                                            this.handleTitle(e.value, item.key);
                                                                                        }}
                                                                                        options={options}
                                                                                        disabled={!showModal}
                                                                                        defaultValue={this.state.selectValues[item.key]}
                                                                                    />
                                                                                </FormGroup>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                    {
                                                                        item.type === "select" && this.state.valuesRecieved &&
                                                                            item.key === "destination_country" ?
                                                                            <div className="col-12">
                                                                                <FormGroup>
                                                                                    <Label>{item.label_name}</Label>
                                                                                    <Select
                                                                                        id={item.key}
                                                                                        onChange={(e) => {
                                                                                            this.handleTitle(e.value, item.key);
                                                                                        }}
                                                                                        options={options}
                                                                                        disabled={!showModal}
                                                                                        defaultValue={this.state.selectValues[item.key]}
                                                                                    />
                                                                                </FormGroup>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                    {
                                                                        item.type === "select" &&
                                                                            item.key === "status" && this.state.valuesRecieved ?
                                                                            <div className="col-12">
                                                                                <FormGroup>
                                                                                    <Label>{item.label_name}</Label>
                                                                                    <Select
                                                                                        id={item.key}
                                                                                        onChange={(e) => {
                                                                                            this.handleTitle(e.value, item.key);
                                                                                        }}
                                                                                        options={categoryOption}
                                                                                        disabled={!showModal}
                                                                                        defaultValue={this.state.selectValues[item.key]}
                                                                                    />
                                                                                </FormGroup>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                    {
                                                                        item.type === "select" && this.state.valuesRecieved &&
                                                                            item.key === "package_type" ?
                                                                            <div className="col-12">
                                                                                <FormGroup>
                                                                                    <Label>{item.label_name}</Label>
                                                                                    <Select
                                                                                        id={item.key}
                                                                                        onChange={(e) => {
                                                                                            this.handleTitle(e.value, item.key);
                                                                                        }}
                                                                                        options={packageTypeOption}
                                                                                        disabled={!showModal}
                                                                                        defaultValue={this.state.selectValues[item.key]}
                                                                                    />
                                                                                </FormGroup>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                    {
                                                                        item.type === "text" && this.state.valuesRecieved &&
                                                                            (item.key === "shipper_phone" || item.key === "receiver_phone" || item.key === "other_phone") ?
                                                                            <div className="col-12">
                                                                                <FormGroup>
                                                                                    <Label>{item.label_name}</Label>
                                                                                    <div>
                                                                                    <PhoneInput
                                                                                            containerClassName="intl-tel-input"
                                                                                            inputClassName="form-control"
                                                                                            defaultValue={this.state.selectValues[item.key]}
                                                                                            onPhoneNumberChange={(a, b, c, d) => this.handleTitle(d, item.key)}
                                                                                        />

                                                                                        <br />
                                                                                        <h5 className='error-display'>
                                                                                            {!this.state.isValidPhoneNo && "Please enter a valid phone number"}
                                                                                        </h5>
                                                                                    </div>
                                                                                </FormGroup>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                    <div className="d-flex justify-content-end mt-3">
                                                        <button className="btn btn-submit ml-2 mt-3" onClick={this.printInvoice}>Print Invoice</button>
                                                    </div>
                                                </>
                                            }
                                        </Fragment>
                                }
                            </div>

                        </div>
                    </CardBody>
                </Card>
            </div >
        )
    }
}