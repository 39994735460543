import React, { Component } from "react";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import "./Login.css";
import logo from "../assets/4.png";
import { Formik } from "formik";
import * as Yup from "yup";
import { login } from "../api/auth";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
const { REACT_APP_RECAPTCHA_SITEKEY } = process.env;
const SignupSchema = Yup.object().shape({
  // password: Yup.string()
  //     .min(5, 'Too Short!')
  //     .max(10, 'Too Long!')
  //     .required('Required'),
  // email: Yup.string()
  //     .email('Invalid email')
  //     .required('Required'),
});

export default class Login extends Component {
  state = {
    username: "",
    password: "",
    loading: false,
    captchaIsDone: true,
  };

  async componentDidMount() {
    const auth = await localStorage.getItem("auth");
    const userId = await localStorage.getItem("userId");
    const adCodes = await localStorage.getItem("adCodes");

  }

  submitHandler = (values) => {
    this.setState({ loading: true });
    login(values)
      .then((Response) => {
        localStorage.setItem("token", Response.data.data.token);
        localStorage.setItem(
          "accounttypeUsers",
          Response.data.data.accounttypeUsers
        );
        localStorage.setItem("businesstype", Response.data.data.businesstype);
        localStorage.setItem("adCodes", Response.data.data.ADCode);
        localStorage.setItem("userId", Response.data.data.id);
        localStorage.setItem("profile", Response.data.data.profile);
        localStorage.setItem("firstName", Response.data.data.firstName);
        localStorage.setItem("auth", JSON.stringify(Response.data.data));
        localStorage.setItem(
          "is_mobile_verified",
          Response.data.data.is_mobile_verified
        );
        this.setState(Response);
        this.props.history.push({
          pathname: "/admin/index",
          state: { detail: Response.data },
        });

        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.setErrors({ username: err.response.data.message });
        this.setState({ loading: false });
      });
  };

  captchaHandler = () => {
    console.log("change");
    this.setState({
      captchaIsDone: false,
    });
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Login - Postkodes</title>
          <meta name="description" content="Log in your Account" />
        </Helmet>
        <Formik
          initialValues={{
            username: this.state.email,
            password: this.state.password,
          }}
          validationSchema={SignupSchema}
          onSubmit={(values, { setErrors }) => {
            this.setErrors = setErrors;
            this.submitHandler(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
          }) => (
            <div className=" w-100 h-100 overflow-hidden login-autonomous">
              <div className="row">
                <div className="col-lg-4 col-md-5 postkod-description pr-0">
                  <div className="d-none d-md-block d-lg-block d-xl-block login-bg-style">
                    <div className="d-flex justify-content-center">
                      <Link to="/">
                        <img src={logo} className="img-fluid" alt="" />
                      </Link>
                    </div>
                    <h1 className="pb-3 pt-3 text-center login-title">
                      Postkodes
                    </h1>
                    <p className="text-justify login-description">
                      Perfect for consumers, e-commerce businesses, and small to
                      medium sized couriers. Postkodes is a platform designed to
                      make parcel handling seamless. Embedded with IoT technology,
                      Postkodes enables users to create shipment, print shipping
                      documents & labels, scan parcels, track parcels & set-up sms
                      & email tracking alerts for any parcel. Along with other
                      great features, users can also exchange shipping documents,
                      request a click & collect or courier collect by proxy (COPY)
                      service. Our universal parcel tracking solution indexes more
                      than 1000 carriers worldwide including all major couriers
                      and airlines.
                    </p>
                  </div>
                </div>
                <div className="col-lg-8 col-md-7 pl-0">
                  <div className="login-form">
                    <div className="d-flex justify-content-center d-block  d-md-none d-lg-none d-xl-none pb-3">
                      <a href="/">
                        <img src={logo} alt="" />
                      </a>
                    </div>
                    <div className="d-none d-md-block d-lg-block d-xl-block">
                      <h1 className="title-style">Login</h1>
                    </div>
                    <Form>
                      <div className="d-block d-sm-block d-md-none d-lg-none d-xl-none">
                        <h1 className="title-style pb-0 text-left">Sign in</h1>
                        <p className="pb-2 description-text">
                          Sign in to Continue
                        </p>
                      </div>
                      <FormGroup className="pb-2 has-feedback has-feedback-left ">
                        <Label for="exampleEmail">Email</Label>
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">
                              <i
                                class="fa fa-envelope icon-style"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                          <Input
                            type="email"
                            invalid={errors.username && touched.username}
                            name="username"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.username}
                            placeholder="Username"
                          />
                          <FormFeedback>
                            {errors.username &&
                              touched.username &&
                              errors.username}
                          </FormFeedback>
                        </div>
                      </FormGroup>
                      <FormGroup className="">
                        <Label for="examplePassword">Password</Label>
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">
                              <i
                                class="fa fa-unlock-alt icon-style"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                          <Input
                            type="password"
                            invalid={errors.password && touched.password}
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            placeholder="Password"
                          />
                          <FormFeedback>
                            {errors.password &&
                              touched.password &&
                              errors.password}
                          </FormFeedback>
                        </div>
                      </FormGroup>
                      <ReCAPTCHA
                        sitekey={REACT_APP_RECAPTCHA_SITEKEY}
                        onChange={this.captchaHandler}
                      />
                      <div className="pt-5">
                        <Button
                          disabled={this.state.captchaIsDone}
                          className="btn btn-login btn-block"
                          onClick={handleSubmit}
                        >
                          {this.state.loading ? (
                            <ClipLoader
                              size={15}
                              color={"#123abc"}
                              loading={true}
                            />
                          ) : (
                            "Login"
                          )}
                        </Button>
                      </div>
                    </Form>
                    <div className="mt-3 text-right mr-3">
                      <Link to="/forgotpassword" className="signup-style">
                        Reset Password
                      </Link>
                    </div>
                    <div className="py-4 d-flex justify-content-center text-dark">
                      Don&apos;t you have an account ?
                      <Link to="/verifyEmail" className="signup-style">
                        Sign Up
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-offset-none offset-sm-none offset-md-2 offset-lg-2 offset-xl-2"></div>
              </div>
            </div>
          )}
        </Formik>
      </>
    );
  }
}
