import React, { Component } from 'react'
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import scan from '../../assets/noun_scan_3165200.svg'
import './Modal.css'
import { srRequest } from '../../api/Form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { tsid } from '../../utils/utils';

export default class SelectShipmentModal extends Component {
    state = {
        modal: true,
        camScan: false,
        delay: 100,
        scan: 'Scan QR Code',
        shipmentType: 'International',
        nfcADcode: '',
        phoneNumber: '',
        deviceADcode: ''
    }

    async componentDidMount() {
        const data = await localStorage.getItem('auth');
        const { ADCode } = JSON.parse(data);
        this.setState({ deviceADcode: ADCode });
    }

    scanHandler = () => {
        if (this.state.shipmentType == 'Domestic') {
            this.props.close.push('/admin/createShipmentDomestic');
        } else {
            this.props.scan({ shipmentType: this.state.shipmentTypeHandler });
        }
    }


    toggleCloseHandler = () => {
        this.props.close.push('/admin/index')
    }

    shipmentTypeHandler = (e) => {
        this.setState({ shipmentType: e })
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.state.modal} >
                    {/* <ModalHeader  >Choose ScanType</ModalHeader> */}
                    <ModalBody>
                        <>
                            <div className="d-flex justify-content-center pt-3">
                                <img src={scan} alt="" width="80px" height="80px" />
                            </div>
                            <div className="row pt-3 d-flex justify-content-center">
                                <div className="col-12 py-2 d-flex justify-content-center">
                                    <h1 className="scan-title">Select Shipment for?</h1>
                                </div>
                                <div className="pb-3">
                                    <div class="radio-item">
                                        <input type="radio" id="ritema" name="ritem" value="ropt1" defaultChecked checked={this.state.shipmentType === 'International'} onChange={() => this.shipmentTypeHandler("International")} />
                                        <label for="ritema">International</label>
                                    </div>

                                    <div class="radio-item">
                                        <input type="radio" id="ritemb" name="ritem" value="ropt2" checked={this.state.shipmentType === 'Domestic'} defaultChecked onChange={() => this.shipmentTypeHandler("Domestic")} />
                                        <label for="ritemb">Domestic</label>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <div>
                                    <div className="scan-btn">
                                        <button className="btn btn btn-submit mr-2 btn-block" onClick={this.scanHandler} >Next</button>
                                    </div>
                                    <div className="scan-btn">
                                        {/* <button className="btn btn btn-submit btn-block mt-3" onClick={() => this.selectScanHandler("Barcode")} ><i class="fa fa-barcode pr-3" aria-hidden="true"></i>Scan Barcode</button> */}
                                    </div>
                                </div>
                            </div>
                        </>
                    </ModalBody>
                    <ModalFooter>
                        {/* <Button color="primary" onClick={this.toggle}>Do Something</Button>{' '} */}
                        <Button color="secondary" onClick={this.toggleCloseHandler}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div >
        )
    }
}
