import React, { Component } from 'react'
import Sidebar from '../Sidebar/Sidebar'
import { Route, Switch, Link } from "react-router-dom";
import routes from "../../routes";
import adminRoutes from '../../adminRoutes';
import './Navbar.css'
import '../../main-thems.css'
import icon from '../../assets/menu.svg'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from 'reactstrap';
import Logo from '../../assets/4.png';
import Logo2 from '../../assets/new_logo.png';
import { image } from '../../api/URLS';
import * as qb from "../../utils/qbHelper";
import { userProfile } from '../../api/Form';
import { toast } from 'react-toastify';

export default class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowDimensions: this.getWindowDimensions(),
            Collapse: true,
            deviceADcode: '',
            profile: '',
            firstName: '',
            userType: '',
            profileData: []
        }
    }
    async UNSAFE_componentWillReceiveProps(nextProps) {
        const profile = JSON.parse(await localStorage.getItem('auth'));
        if (profile) {
            this.setState({ ...profile })
        }
        const firstName = await localStorage.getItem('firstName');

        this.setState({ firstName })
    }

    profileDataHandler = async () => {
        const userId = await localStorage.getItem('userId');

        userProfile({ userId })
            .then(Response => {
                if (Response.data.data.completeProfile.is_locked === 1 || Response.data.data.completeProfile.deleted_at !== null) {
                    this.logoutHandler();
                    window.location.reload();
                }
                this.setState({ profileData: Response.data.data.update_profile })
            })
            .catch(err => {
                // toast.error(err.response.data.message, {
                //     position: toast.POSITION.TOP_RIGHT
                // });
            })
    }
    async componentDidMount() {
        // if (this.state.windowDimensions.width < 768) {
        //     this.setState({ Collapse: false });
        // }
        window.addEventListener("resize", this.handleResize);
        var userType = await localStorage.getItem('accounttypeUsers');
        this.setState({ userType })
        const profile = JSON.parse(await localStorage.getItem('auth'));
        if (profile) {
            this.setState({ ...profile })
        }
        const firstName = await localStorage.getItem('firstName');

        this.setState({ firstName })
        this.profileDataHandler()
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height,
        };
    }

    handleResize = () => {
        this.setState({
            windowDimensions: this.getWindowDimensions(),
        });
        if (this.state.windowDimensions.width < 820) {
            this.setState({ Collapse: false });
        } else {
            this.setState({ Collapse: true });
        }
    };

    getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.layout === "/admin") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };

    profileHandler = () => {
        this.props.history.push('./profile')
    }


    CollapseHandler = () => {
        this.setState({ Collapse: !this.state.Collapse });
    }

    logoutHandler = () => {
        qb.disconnect();
        qb.logout();
        // qb.release();
        localStorage.clear();
        this.props.history.push('/login');
    }

    render() {
        const { profile, profileData } = this.state
        return (
            <div style={{ width: '100%' }}>

                <div style={{ paddingBottom: "50px" }}>
                    <nav className="navbar navbar-expand-lg nav-style" style={{ width: '100%', position: "fixed", zIndex: '1' }}>

                        <div className="d-md-block d-lg-block d-xl-block">
                            <div>
                                <img src={icon} onClick={this.CollapseHandler} className="collapse-style-sidebar" sticky="true" />
                            </div>
                        </div>

                        <div>
                            {/* <a class="navbar-brand" href="#" className="title-style1"> <span className="font-weight-bold" style={{ color: "blue" }}>Address</span><span className="font-weight-bold" style={{ color: "red" }}>Dynamic</span> </a> */}
                            <Link to="/" className="navbar-brand ml-2"><span className="font-weight-bold" style={{ color: "blue" }}>Postkod</span><span className="font-weight-bold" style={{ color: "red" }}>es</span></Link>
                        </div>
                        <div className="ml-auto row">
                            <div>
                                <UncontrolledDropdown className="me-2 head-frop-button" direction="down" >
                                    <DropdownToggle className="drop-button-table" >
                                        <div style={{ position: "relative", marginRight: "10px" }}>
                                            <i className='fa fa-bell table-icons nav-link' style={{ fontSize: "20px" }} role='button' id='edit-temp'></i>
                                            <span style={{ position: "absolute", left: "25px", top: "-5px" }}>
                                                <Badge pill color="danger" >{profileData.notification_request ? 1 : 0}</Badge>
                                            </span>
                                        </div>
                                    </DropdownToggle>
                                    {
                                        profileData.notification_request &&
                                        (<DropdownMenu className="menuOfDrop notifiationDropDown">
                                            <h6>Please verify your Phone Number form profile page</h6>
                                        </DropdownMenu>)
                                    }
                                </UncontrolledDropdown>
                            </div>

                            <Link to="/admin/purchaseCredits" className="active nav-link header-menu-link">Purchase Credits</Link>
                            <UncontrolledDropdown className="dropdown-style p-1" >
                                <DropdownToggle className="dropdown-button" >
                                    {profile ?
                                        <img src={image + '/' + profile} alt="" className="profile-img-style" />
                                        :
                                        <img src={Logo2} alt="" className="profile-img-style" />
                                    }
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={this.profileHandler} >{this.state.firstName}</DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={this.logoutHandler} >Logout</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>


                    </nav>
                </div>

                <div className="wrapper" >
                    <div className={this.state.Collapse ? 'sidemenu-wrapper' : ''}>
                        {this.state.Collapse &&
                            <Sidebar
                                {...this.props}
                                routes={this.state.userType === 'Admin' ? adminRoutes : routes}
                                collapseStatus={this.CollapseHandler}
                                showClick={window.innerWidth < 768 ? true : false}
                            />
                        }

                    </div>
                    <div className={this.state.Collapse ? 'main-wrapper' : 'wrapper-full'}>

                        <Switch>{this.getRoutes(this.state.userType === 'Admin' ? adminRoutes : routes)}</Switch>
                    </div>
                </div>

            </div>
        )
    }
}