import React, { Component } from 'react'
import { CardBody, Card, FormGroup, Label, Input } from 'reactstrap'
import './PrintForm.css'
import QRCode from "react-qr-code";
import Barcode from 'react-barcode';
import { getShipment } from '../../api/Form';
import { toast } from 'react-toastify';

export default class PrintForm1 extends Component {
    state = {
        size: '',
        refno: '',
        deliveryAddress: '',
        receiverName: ''
    }
    componentDidMount() {
        const shipment = this.props.format
        const size = this.props.size
        this.setState({ ...shipment, size })
    }

    checkReference = () => {
        getShipment({ refno: this.state.refno })
            .then(response => {
                const { refno, deliveryAddress, receiverName, } = response.data.data;
                this.setState({ refno, deliveryAddress, receiverName, });
            })
            .catch(err => {
                toast.error('This reference number does not exist!', {
                    position: toast.POSITION.TOP_RIGHT
                })
            })
    }

    render() {
        const { refno, receiverName, deliveryAddress, size } = this.state
        return (
            <div className="printform1">
                <Card>
                    <CardBody>
                        <div className="d-flex justify-content-end">
                            <span className="logo-style-print" >Postkod</span><span className="logo-style-print-1">es</span>
                        </div>
                        <hr className="border-style my-2" />
                        <>
                            <div className="row">
                                <div className="col-6">
                                    <h1 className="print-header-title mr-2">Receiver name:</h1>
                                    <h1 className="print-header-description">{receiverName}</h1>
                                </div>
                                <div className="col-6">
                                    <h1 className="print-header-title mr-2">Receiver address:</h1>
                                    <h1 className="print-header-description">{deliveryAddress}</h1>
                                </div>
                            </div>
                            <hr className="border-style my-2" />

                            <div className="row">
                                <div className="col-6 qr-size">
                                    <QRCode size={90} value={refno} />
                                </div>
                                <div className="col-6 barcode-size">
                                    <Barcode  value={refno} />
                                    </div>
                            </div>
                        </>
                    </CardBody>
                </Card>
            </div >
        )
    }
}
